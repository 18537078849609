.opend-30-dialog {
  // width: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
}

.opend-30-dialog .k-scrollview-next,
.opend-30-dialog .k-scrollview-prev {
  display: table;
  position: fixed !important;
  padding: 40px !important;
  height: 60%;
  top: 20%;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.opend-30-dialog .img-scrollview {
  width: 1141px;
  height: 580px;
  overflow: hidden !important;
  object-fit: cover;
}

.dialogimage2 .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .k-scrollview-pageable,
.opend-30-dialog .k-scrollview-nav {
  margin: 0;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
  list-style: none;
  display: inline-flex;
  align-items: center;
  overflow-x: scroll;
  display: none !important;
  overflow-y: hidden;
  pointer-events: initial;
}

.image-list {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  justify-content: center !important;
  height: 134px;
  //width: 165px;
}


.opend-30-dialog .image-list img {
  width: 165px;
  height: 134px;
  margin-right: 30px;
  // margin-top: 36px;
  overflow: hidden;
}

.opend-30-dialog .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .k-scrollview-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 10;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: -50px;
}

.opend-30-dialog .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .close-icon-div {
  position: absolute;
  // right: 8px;
  // top: 10px;
  right: -10px;
  top: -30px;
}

.opend-30-dialog .close-icon {
  // width: 20px;
  // height: 20px;
  // cursor: pointer;
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
  right: 16%;
  top: 13%;
}

.imgItem {
  width: 100%;
  height: 100%;
}


.opend-30-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.opend-30-dialog .vnpt-upload-img img {
  width: 160px;
}

// .opend-30-dialog .item{
//   height: 100%;

// }
.opend-30-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.opend-30-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.opend-30-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }

  25% {
    border-color: yellow;
  }

  50% {
    border-color: blue;
  }

  100% {
    border-color: #34a853;
  }
}

:host ::ng-deep .opend-30-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.opend-30-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.opend-30-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .opend-30-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .opend-30-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}


.opend-30-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.opend-30-dialog .selected-lang {
  border-bottom: 3px red solid;
}


@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .opend-30-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .opend-30-dialog .vnpt-upload-img img {
    width: 99px;
  }

  .opend-30-dialog {
    // width: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    // position: inherit;
  }
}


.opend-30-dialog .row-file {
  margin-bottom: 5px;
}

.opend-30-dialog .float-right {
  float: right;
}

.opend-30-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.opend-30-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.opend-30-dialog .control {
  margin-bottom: 22px;
}

.opend-30-dialog .input-label {
   
  margin: 10px;
  margin-left: 15px;
}

.opend-30-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  //   border: solid 1px #e9e9e9;
  //   border-radius: 0px;
  background-color: white;
}

.opend-30-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-30-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-30-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
   
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}



.opend-30-dialog .padding-col {
  padding: 7px 15px 0;
}

.opend-30-dialog .video-container {
  // overflow: hidden;
  position: relative;
  width: 1141px;
  height: 647px;
}


.opend-30-dialog .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}