.org-tree {
  width: 100%;
  min-height: 504px;
  //background-color: white !important;
  // .container-fluid{
  //   display: block;
  // }
  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: flex;
    justify-content: space-between;
    color: #00396b;
     
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
  }

  .title-text {
    // padding-left: 10px;
    margin-bottom: 0 !important;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #d6a022;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 4px;
    a {
      color: #d6a022;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    line-height: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: #292621;
    margin-bottom: 20px;
  }

  .post-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .post-image-default{
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    //display: table;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img {
    height: 312px;
    // max-width: 500px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
  }

  .img-figure-2{
    height: 260px !important;
  }

  .unit-group{
    //margin-top:30px
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    // justify-content: space-between;
    .k-pager-info, .k-pager-sizes {
      display: none;
    }
    .k-pager {
      // padding-left: auto;
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }
      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }
    .top-part {
      display: flex;
      flex-direction: column;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .top-new-item {
    margin: 10px 0;
    // padding-left: 10px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title:hover {
      color: #00396b;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .org-tree-dialog, .description-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
       
      color: #006CB5;
      margin-bottom: 20px;
      // text-align: center;
      // margin: 30px;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }
  .dialog-width{
    max-width: 50%;
    height: 80%;
  }
  .break-line{
    border: 1px dashed #7C7C7C;
    margin: 40px 0px;
  }
  .items-box, .items-box-border {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    padding: 20px;
  }
  .items-box-border{
    border: 1px dashed #000000;
  }
  .item-blue, .item-blue-margin{
    background: #0099DC;
    width: 311px;
    height: 98px;
     
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-blue-margin{
    margin: 20px 10px !important;
  }

  .item-header-unit-member {
    width: auto !important;
    padding: 10px;
  }
  .straight-line{
    height: 105px;
    border-right: 1px dashed #000000;
  }
  .center-box{
    display: flex;
    justify-content: center;
  }
  .margin-items{
    margin: 0px 20px;
  }
  .box-blue-border{
    display: flex;
    background: #FFFFFF;
    border: 1px solid #0099DC;
    width: 326px;
    margin: 30px 0px;
    padding: 10px 0px;
  }
  .img-small{
    width: 50px;
    height: 50px;
    margin: 5px;
    object-fit: contain;
  }
  .center-text{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-0, .text-1, .text-2{
     
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #454545;
   
  }
  .text-0{
    padding: 11px 13px 11px 0px
  }
  .text-1{
    padding: 0px 5px 0px 0px;
  }
  .text-2{
    margin: 0px 10px;
  }
  .box-blue-border-no-img{
    background: #FFFFFF;
    border: 1px solid #0099DC;
    width: 223px;
    margin: 30px 0px;
    height: 75px;
    display: flex;
    justify-content: center;
  }
  .unit-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
     
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: #525252;
    // margin-bottom: 30px;
    margin-top: 40px;
    padding: 0 30px;
  }
  .no-margin{
    margin: 0 !important;
  }
  .no-padding{
    padding: 0 !important;
  }
  .margin-title{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .margin-box{
    margin: 0 35px;    
    margin-bottom: 40px;
  }
  .margin-box-bottom{
    margin-bottom: 30px;
  }
  .close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .close-div {
    display: flex;
    // margin-top: 3px;
  }
  .k-window-content{
    padding: 16px 30px;
  }
  .description-text{
    font-size: 1rem;
     
    font-style: normal;
    font-weight: 500;
    line-height: 1.6875rem;
  }
  @media screen and (max-width: 768px){
    .item-blue-margin{
      height: auto;
    }
    .center-box{
      width: 100%;
    }
    .items-box-border{
      border: none !important;
      padding: 0px;
    }
    .item-blue-margin{
      margin: 0px 20px 10px !important;
      height: 51px;
      font-size: 12px;
    }
    .straight-line{
      height: 17px;
    }
    .box-blue-border{
      justify-content: center;
    }
    .item-header-unit-member{
      padding: 40px;
    }
    .box-blue-border-no-img{
      width: 326px;
    }
    .item-header-unit-member{
      width: 326px !important;
    }
    .box-blue-border, .box-blue-border-no-img{
      margin: 10px 0px;
    }
    .dialog-width{
      max-width: 80% !important;
      height: 80% !important;
      top: 10% !important;
      left: 10% !important;
    }
  }
  .org-img-width{
    max-width: 100%;
  }
}
