.news-template-30,
.news-template-30-without-time {
  width: 100%;
  min-height: 504px;
  //background-color: white !important;

  .row {
    --bs-gutter-x: 0;
  }



  .small-post {
    display: flex;
    flex-direction: row;
  }

  .k-dialog {
    //height: auto;
    //width: 1141px;
    object-fit: cover;

  }

  .video-container {
    // overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #d6a022;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 4px;

    a {
      color: #d6a022;
    }
  }

  .container-fluid {
    background: url(../../assets/images/backround-library.png);
    background-size: cover;
    width: 100%;
    height: 100%;
    background-color: #004A9A;
  }

  .desktop-width {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }

  .content-width {
    margin: 0 auto;
  }

  .content-width-mainpage {
    width: auto;
  }

  .content-width-media {
    width: calc(100% - 250px);
  }



  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }


  .width-first {
    width: 536px;
  }






  .post-image,
  .post-image-radius {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .post-image-radius {
    border-radius: none;
  }

  .post-image-default {
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    //line-height: 16px;
  }

  .main-item {
    display: table;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: none;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .padding {
    padding-left: 15px;
  }

  .img-first {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .post-img-first {
    position: relative;
    width: 100%;
    // height: 287px;
    margin: 0px;
  }

  .img-figure-first {
    width: 100%;
    height: 100%;
  }

  .img-figure,
  .img-figure-no-img,
  .img-figure-template-type-2 {
    width: 100%;
    height: 100%;
    //max-width: 273px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 0px;
    object-fit: cover;
    border-radius: 3px;
  }

  .img-figure-other {
    position: relative;
    width: 100%;
    margin: 0;
    // height: 165px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    object-fit: cover;
  }

  .media-hot {
    position: relative;
    margin-bottom: 0px;

  }

  .img-figure-template-type-2 {
    height: 153px;
  }

  .img-figure-2 {
    height: 260px !important;
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }

  .post-list {
    display: flex;
    //flex-wrap: wrap;
    margin-right: -20px;
    //width: 278px;
    //flex-direction: column;

    // justify-content: space-between;
    .k-pager-info,
    .k-pager-sizes {
      display: none;
    }

    .k-pager {

      // padding-left: auto;
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }

      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }

    .top-part {
      display: flex;
      flex-direction: column;
      margin-right: 36px;
    }
  }

  .margin-right {
    margin-right: 36px;
  }

  // .uppercase {
  //   text-transform: uppercase;
  // }

  .top-new-item {
    margin: 10px 0;
    // padding-left: 10px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title:hover {
      color: #00396b;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .description-text {
    text-align: justify;

    figure {
      width: 100% !important;
      height: 500px;

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    p {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      color: #000 !important;
    }

    blockquote {
      border-left: none solid #d9d9d9;
      padding-left: 15px;
      font-style: italic;

    }
  }

  .center {
    position: fixed;
    bottom: 57px;
    width: 50vw;
    left: 10px;
    right: 10px;
    /* margin-left: 53px; */
    margin: 0 auto;
    z-index: 10000000000;
  }

  .news-template-30-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .close-icon-div {
    position: absolute;
    right: 10px;
  }

  .input-label {
     
    color: #004A9A;
    //margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: none;
    background-color: #1c8d43;
    border: none;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: none;
    background-color: #6c757d;
    border: none;
    color: white;
    font-size: 14px;
     
  }

  .dialog-width {
    max-width: 400px;
  }

  .content {
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

  .dialog-opened {
    position: relative;
    overflow: hidden !important;
  }

  .image-list {
    /* Căn giữa danh sách hình ảnh */
    display: flex;
    justify-content: center;

  }

  .smallImg {
    width: 165px;
    height: 134px;
    padding-top: 37px;
    padding-right: 30px;
  }

  .dialog-width-images {
    //width: 40%;
    height: fit-content !important;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    //margin-top: 130px;
  }

  .k-dialog .dialog2 {
    height: 70% !important;
    width: 78% !important;
    top: 0px;
  }

  .dialogimage2 .k-window-content,
  .k-prompt-container {
    padding: 0px !important;
    border-width: none;
    border-color: none;
    color: inherit;
    background: none;
    outline: 0;
    overflow: auto;
    position: relative;
    flex: 1 1 auto;
  }

  .koverley2 .k-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: transparent;
  }

  .opend-dialog {
    // width: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .dialog-container {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-div2 {
    display: flex;
    // margin-top: 3px;
  }

  .dialog-opened {
    position: relative;
    overflow: hidden !important;
  }


  .title,
  .template-title,
  .post-title,
  .description-text {
     
    font-style: normal;
    font-weight: 700;
    text-decoration: none;

    color: #FFFFFF;
  }

  .template-title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    line-height: 30px;
    padding-top: 36px;
  }

  .title-template-type-2 {
    line-height: 20px;
    font-style: normal;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #2B2B2B;
    margin-bottom: 20px;
  }

  .post-title {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .title-first {
    font-size: 16px;
    line-height: 30px;
  }

  .title-other {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 22px;
  }

  .description-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.03em;
    margin-bottom: 32px;
  }

  .aaa {
    flex: 0 0 auto;
    width: 35.5%;
  }

  .bbb {
    flex: 0 0 auto;
    width: 62.1%;
  }

  .d-grid {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px 36px;
    object-fit: cover;
  }
  .dialog-container {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialogimage2 .k-window-content, .k-prompt-container {
    padding: 0px !important;
     border-width: 0;
     border-color: inherit;
     color: inherit;
     background: none;
     outline: 0;
     overflow: hidden;
     position: relative;
     flex: 1 1 auto;
   }
   .width-full{
      width: 100%;
      height: 100%;
   }
   .opend-dialog-images .video-container{
    height: 700px;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .container-fluid {
      margin-top: 10px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .img-figure-other {
      width: 100%;
      height: 287px;
    }
    
  }
  @media screen and (max-width: 770px) {
    .content-width{
      margin: 10px 0px !important;    
      width: 80%;
    }
    .post-img-first{
      height: 150px !important;
    }
    .img-figure-other{
      height:  150px !important;
    }
    .dialog-width-images {
      max-height: 317px;
    }
    .opend-dialog-images .video-container{
      height: 300px;
    }
  }
  @media screen and (max-width: 576px) {
    .padding-box{
      padding: 0 20px;
    }
  }
}