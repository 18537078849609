.login-page {
  .panel-login {
    .description {
      h6 {
        color: #606060;
        font-size: 20px;
      }
      .content {
        color: #1F1F1F;
        font-size: 16px;
      }
    }
  }
}