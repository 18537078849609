.question-search{

       .title-question{
          font-weight: bold;
          font-size: 16px;
          margin-top: 20px;
          margin-bottom: 5px;
          border-bottom: #c60000 solid 2px;
          width: fit-content;
      }
      
       .title-answer{
        margin-top: 0px !important;
      }
       .custom-text{
        color: black;
      }
      
       .file-container{
        padding: 20px;
        background: #EAEAEA;
        margin-top: 10px;
      }
       .title{
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 5px;
          
      }
       .contents{      
        .contents_label {
                color: rgb(159, 161, 159);
        }
        font-family: inherit !important;
        white-space: inherit;
        font-size: inherit !important;      
        margin-left: 20px;
      }
      .custom-color{
        color: #c60000;
      }
      
       .custom-row{
        background-color: white;
        cursor: pointer;
      }      
      
      .margin30{
        margin: 30px 0px 32px 0px
      }      
      
    .btn-search{
        background: #194f8b;
        color: white;
        width: 130px;
        white-space: nowrap;
        padding: 4px 8px;
        font-size: 16px!important;
        font-family: inherit;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        overflow: visible;
    }
    
    .total-question{
        font-weight: bold;
        font-size: 16px;
      }
    .hr-style{
        margin-top: 2px;
        margin-bottom: 15px;
    }
      
    .xbutton-dialog-question {
        padding: 0;
        color: inherit;
        background: none;
        border-width: 0;
        box-shadow: none;
        float: right;
        color: black !important;
        font-size: 12px !important;
    }      

    .control-search {
        input {
            font-size: 16px!important;
            margin-left: 2px;
        }

        input::placeholder { 
            color: rgb(187, 186, 186);
        }
        .btn-search {
            width: 35px;
            height: 34px;
            float: right;
            border-radius: 0;
            margin-top: -34px;
            border: 0;
            background-color: #985d1a !important;
        }

        .btn-remove {
            background-color: transparent !important;
            right: 40px;
            color: black !important;
            width: 30px;
            height: 32px;
            float: right;
            border-radius: 0;
            margin-top: -33px;
            border: 0;
            font-size: 20px;
            z-index: 1;
            position: sticky;
        }
        .btn-remove-replace-btn-search {
            right: 0 !important;
        }
    }   

    .control>* {
        width: 100%;
    }
            
    .list-question{
        cursor: pointer;
        aside{
            color: #515460;

        }
        article:hover{
        h3{
            color:#2569b8
        }

        aside{
            color:#2569b8;
            .contents_label {
                color: #4e7ed8;
                opacity: 0.8;
            }
           
        }
    }
    }

    
    .k-pager-numbers .k-link {
        color: black;
        border-width: 1px;
        margin: 4px;        
    }
    .k-pager-wrap {
        border: none;
        color: #424242;
        background-color: transparent;
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    .k-pager-numbers .k-link.k-state-selected {
        background-color: #c60000;
        color: white !important;
    }

    .k-pager-nav {
        border: solid 1px;
        margin: 4px;
    }
    

}

@media screen and (max-width: 770px) {
    .question-search-detail.x-dialog {
        width: 95% !important;
        height: 95% !important;
    }
}

.question-search-detail.x-dialog{
        max-width: 800px;
        max-height: 800px;
    }
    
.question-search-detail{  

    .title-question{
        font-weight: bold;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: solid #c60000;
        width: fit-content;
    }
    .title-answer{
        margin-top: 0px !important;
    }
    .custom-text{
        color: black;
        font-size: 16px;
        
    }
    .file-container{
        padding: 20px;
        background: #EAEAEA;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .title{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .contents{
        font-family: inherit !important;
        white-space: pre-wrap;
        font-size: inherit;
    }
    .custom-color{
       color: #c60000;
    }
    a {
      text-decoration: none;
    }
    .custom-text-file{
        font-weight: bold;
        color: black;
        margin-bottom: 8px !important;
    } 
    .custom-text-file:hover{
        color: #194f8b;        
    }   

    .xbutton-dialog {
        padding: 0;
        color: inherit;
        background: none;
        border-width: 0;
        box-shadow: none;
        position: absolute;
        right: 10px;
        top: 1px;
        color: black !important;
        font-size: 30px !important;
    }

    .title-info-req {
        text-align: center;
        font-size: 18px;
        color: black;
        font-weight: bold;
        margin-top: 20px;
    }
}

