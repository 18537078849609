.post-detail {

  // padding-left: 1.5rem !important;
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: rgb(202, 202, 202);
    border-width: 1px;
  }

  table {
    // --bs-table-bg: transparent;
    // --bs-table-accent-bg: transparent;
    // --bs-table-striped-color: #212529;
    // --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    // --bs-table-active-color: #212529;
    // --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    // --bs-table-hover-color: #212529;
    // --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: rgb(202, 202, 202);
  }

  .text-tiny {
    font-size: 0.7em;
  }

  .text-small {
    font-size: 0.85em;
  }

  .text-medium {
    font-size: 1em;
  }

  .text-big {
    font-size: 1.4em;
    // border-bottom: solid #006CB5 5px;
    // padding-bottom: 5px;
  }

  .text-huge {
    font-size: 2em;
  }

  // .subject {
  //     margin-top: 14px;
  //    
  // }
  // .row {
  //   margin-left: 1.5rem + calc(var(--bs-gutter-x) * -.5);
  // }

  a .categories {
    // margin-top: 15px;
    color: #4e7ed8;
    // font-size: 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bolder;
    //  font-family: 'Times New Roman', Times, serif;
    //padding-right: 12px;
    text-decoration: none !important;
    z-index: 6;
  }

  a:hover span {
    color: rgb(255, 153, 0)
  }

  .title-post {
     
    font-style: normal;
    margin-top: 47px !important;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #006CB5;
    //padding-right: 12px;
  }

  .summary {
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;

    color: #565656;
  }

  .created-post {
    font-weight: bolder;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
     
    font-style: italic;
    font-weight: 300;
    line-height: 20px;
    color: #D52929;
  }

  hr.line-title {
    border-top: 5px solid #aeafb3;
  }

  .img-present {
    // overflow: hidden;
    position: relative;
    max-width: 100% !important;
    font-size: 10px;

    .video-view {
      height: 390px !important;

    }
  }

  .media {
    position: relative;
    width: 100% !important;
    height: 500px !important;
    max-height: none !important;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .title-center {
    text-align: center !important;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .post-detail-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  // .sumary {
  //      :host ::ng-deep .text-tiny {
  //         font-size: 0.7em;
  //     }
  //      :host ::ng-deep .text-small {
  //         font-size: 0.85em;
  //     }
  //      :host ::ng-deep .text-big {
  //         font-size: 1.4em;
  //     }
  // }
  .description {
    text-align: justify;

    img {
      width: 100% !important;
    }

    figure {
      width: 100% !important;

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    p {
       
      font-style: normal;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 30px !important;
      color: #000000 !important;
    }

    blockquote {
      border-left: 5px solid #d9d9d9;
      padding-left: 15px;
      //font-style: italic;

    }
  }

  .reference-link-box {
    margin-top: 10px;
  }

  .sapned {
    margin-top: 20px;
  }

  .reference-link {
    text-decoration: none;
  }

  .reference-link:hover {
    text-decoration: underline;
  }

  .file-container {
    padding: 20px;
    background: #EAEAEA;
    margin-top: 10px;
  }

  .custom-text {
    color: black;
  }

  .custom-text-file {
    font-weight: bold;
    color: black;
    text-decoration: none;
    word-break: break-word;


  }

  .file-div {
    margin-top: 5px;
    margin-bottom: 5px;

    a {
      text-decoration: none;
    }

    :hover {
      color: #4e7ed8;
    }
  }

  .categories-link {
    text-decoration: none;
  }

  .margin-box {
    margin: auto;

  }

  .detail-body {
    width: calc(100% - 250px);
    min-width: 80%;
    max-width: 1550px;

    .btn-fl {
      padding: 0;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
    }
  }

  .detail-body-full {
    width: 100%;
  }

  .keyword-container {
    display: inline-flex;
     
  }

  .keyword-title {
    font-weight: 600;
  }

  .keywords {
    padding: 5px 8px;
    margin: 0px 5px;
    background-color: #d9d9d9;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }

  .keywords:hover {
    color: #FFFFFF;
    background-color: #004A9A;
  }
  .top-new-item {
    margin: 15px 0 0 0;
    display: flex;
    justify-content: center;
  }
  .back-to-top {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    overflow: hidden;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
  }
  .post-image {
    height: 25px;
    width: 25px;
    position:absolute;
    top: 50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.fixed-item{
  //stick to body
  position: fixed;
  padding: 0px 0px 0 0;
  left: calc(100% - 65px);
  top: 90%;
  transform: translateX(-50%);
  z-index: 1001;
}
.main-item {
  display: table;
  margin: 0;
}

.responsible-container {
  display: inline-flex;
  font-size: 14px;
  margin: 15px 0;
  float: right;
   
}

.responsible-title {
  font-weight: 600;
  margin-right: 5px;
}

@media(max-width: 768px){
  .fixed-item{
    display: none;
      // padding: 0px 100px 0 0;
      top: 90%;
      right: 0px;
      //left: auto;
      left: calc(100% - 30px) !important;

  }
}

  @media screen and (max-width: 576px) {
    .detail-body {
      width: 100% !important;
    }
    .box {
      .categories {
        // margin-top: 15px;
        // color: #4e7ed8;
        // font-size: 16px;
        //  cursor: pointer;
        font-size: 14px;
        //  font-weight: bolder;
        //  font-family: 'Times New Roman', Times, serif;
        //padding-right: 12px;
        //  text-decoration: none;
      }

      .title-post {
        //@at-root margin-top: 0px;
        font-weight: bolder;
        font-size: 20px;
      }

      .img-present {
        // overflow: hidden;
        position: relative;
        max-width: 100% !important;
        font-size: 10px;

        .video-view {
          height: 190px !important;

        }
      }
    }

    .description {
      text-align: justify;

      img {
        width: 100% !important;
      }

      figure {
        width: 100% !important;

        // height: 300px !important;
        iframe {
          height: 100%;
          width: 100%;
        }
      }

      .media {
        position: relative;
        width: 100% !important;
        height: 260px !important;
        max-height: none !important;
      }
    }

    // .post-detail {
    // }
    // padding-right: 15px;// padding-left: 15px;
  }

  @media screen and (max-width: 1025px) and (min-width: 576px) {
    // .row {//   margin-right: 3px !important;
    // }

    .detail-body {
      width: calc(100% - 50px) !important;
    }

    .media {
      position: relative;
      width: 100% !important;
      height: 400px !important;
      max-height: none !important;
    }
  }
}
