.video-conponent-videotemplate {
    width: 700px;
    height: 400px;
}

.video-conponent-template {
    z-index: 99998;
    position: relative;
}

.video-conponent-template-close {
    position: absolute;
    font-size: 30px;
    z-index: 99999;
    background-color: #9a9a9a;
    color: #c6c6c6;
    font-weight: bold;
    height: 45px;
    width: 45px;
    top: 0;
    right: 0;
    box-shadow: 0px 0px 15px 3px #313131;
    border: none;
    text-align: center;
    border-radius: 3px;
}

.mat-dialog-container {
    padding: 5px !important;
    background-color: #2e2e2e;
    box-shadow: 0px 0px 45px 20px #313131;
}

.video-view {
    .img-err{
        z-index: 800;
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .video-js .vjs-control-bar {
        background-image: linear-gradient(#666565 10%, #dbdbdb);
    }
    .vjs-big-play-centered .vjs-big-play-button {
        top: 50%;
        left: 50%;
        margin-top: -1em;
        margin-left: -1em;
    }
    .vjs-default-skin .vjs-big-play-button {
        width: 1.9em;
        height: 1.9em;
        border-radius: 100px;
        border: none;
        line-height: 1.95em;
    }
    .video {
        width: 100%;
        height: 100%;
        z-index: 800;
        position: absolute;
        top: 0;
    }
    .mask-video{
      z-index: 802;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .play-button {
        z-index: 801;
        height: 61px;
        width: 60px;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        margin-left: -1em;
        margin-top: -1.2em;
    }
    .gg-play-button-o {
        width: 2.5em;
        height: 2.5em;
        border-radius: 45px;
        background-color: #2c2a2a;
        position: relative;
        display: block;
        opacity: 0.7;
    }
    .gg-play-button-o::before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 10px;
        border-top: 0.7em solid transparent;
        border-bottom: 0.7em solid transparent;
        border-left: 1em solid white;
        top: 24%;
        left: 38%;
    }
    .zoom-view {
        border: 2px solid white;
        font-size: 10px;
    }
}
