.org-tree-dialog {
    // width: 100%;
    max-width: 500px !important;
    max-height: 75% !important;
    margin-left: 0px !important;
}

.org-tree-dialog .vnpt-upload-img .k-upload {
    display: none;
}

.org-tree-dialog .vnpt-upload-img img {
    width: 160px;
}

.org-tree-dialog .err-text {
    color: #ff6358;
    margin-left: 15px;
    font-style: italic;
}

.org-tree-dialog .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
}
.org-tree-dialog .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
}

@keyframes myerr {
    0% {
        border-color: #ea4335;
    }
    25% {
        border-color: yellow;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: #34a853;
    }
}

:host ::ng-deep .org-tree-dialog.x-dialog {
    max-width: 768px;
    max-height: 565px;
}

.org-tree-dialog .custom-btn-addNew {
    margin-top: 10px !important;
}

.org-tree-dialog .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
}

:host ::ng-deep .org-tree-dialog .btn-custom {
    width: 100%;
}
:host ::ng-deep .org-tree-dialog .btn-custom button {
    background: #cd330d;
    color: white;
    width: 100%;
}


.org-tree-dialog .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}
.org-tree-dialog .selected-lang {
    border-bottom: 3px red solid;
}


@media (max-width: 600px) {
    :host ::ng-deep .k-window {
        width: 90%;
        height: 90%;
    }
    :host ::ng-deep .org-tree-dialog.x-dialog {
        max-width: 768px;
        max-height: none;
    }

    .org-tree-dialog .vnpt-upload-img img {
        width: 99px;
    }
}


.org-tree-dialog .row-file {
    margin-bottom: 5px;
}

.org-tree-dialog .float-right {
    float: right ;
}

.org-tree-dialog .border-infor{
    background: #FFFFFF;
    border: 0.6px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 11px;
}

.org-tree-dialog .label-infor{
    color: #407BFF;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

.org-tree-dialog .control {
    margin-bottom: 22px;
}

.org-tree-dialog .input-label {
   
  margin: 10px;
  margin-left: 15px;
}

.org-tree-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
//   border: solid 1px #e9e9e9;
//   border-radius: 0px;
  background-color: white;
}

.org-tree-dialog .k-input{
  height: 38px !important;
  margin-top: -1px !important;
}

.org-tree-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.org-tree-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
   
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

// .org-tree-dialog .btn-save{
//     color: #fff;
//     background-color: #34a853;
//     border: hidden;
//     padding: 5px 20px;
//     font-size: 14px!important;
//     width: 200px;
//     height: 40px;
// }

.org-tree-dialog .padding-col{
    padding: 7px 15px 0;
}
