.achivement-item {
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 100%;
  
  .image-frame {
    width: 100%;
    height: 180px;
    text-align: center;
    .image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  p.title {
    text-align: center;
    font-weight: bold;
    margin: 0;
    margin-top: 20px;
  }
}