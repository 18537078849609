.login-page {
  .panel-login {
    .change-password-button {
      width: 100%;
      background-color: #00396b;
      border: none;
      color: white;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
}