.routerLink {
  background: white;
  margin-top: 20px;
  // height: 100vh;



}
.routerLink .img{
  margin: 2px 12px 6px 12px;
}
.routerLink .mb-routerLink {
  display: none;
}

.routerLink .mb-routerLink span.value {
  color: #3b3b3b;
  font-weight: 500;
}

.routerLink .g-btn {
  margin-top: 5px;
}

.routerLink .g-btn kendo-dropdownbutton button {
  height: 100%;
  display: inline-block;
}

.routerLink .btn-custom button {
  background: #34a853;
  color: white;
}

.routerLink .mb-routerLink .function {
  text-align: right;
}

.routerLink.x-dialog-shared {
  max-height: 600px;
  max-width: 1300px;
}

.routerLink.x-dialog {
  max-height: 200px;
  max-width: 600px;
}

.routerLink .popup-width {
  width: 140px;
}

.routerLink .btn-fr {
  float: right;
}

.routerLink .btn-fl {
  float: left;
  margin: 20px 0px 20px 0px;
  // padding-left: 0;
}

.routerLink .margin {
  margin-left: 0px;
}

.routerLink .align-center-bt {
  text-align: center;
}

.routerLink .headerOfficeStyle {
  text-align: center;
  color: #675f5f;
  background: #f6f6f6;
  text-overflow: inherit;
  vertical-align: middle;
  padding: 0px;
}

.routerLink .block-nm {
  display: inline;
}

.routerLink .btn-show {
  width: 15px;
  display: inline-table;
  border: none;
  background-color: transparent;
}

.routerLink .mr-r15 {
  margin-right: 15px;
}

.routerLink .icon-mouse {
  cursor: pointer;
}


@media (max-width: 768px) {
  .routerLink .g-routerLink {
    display: none;
  }
  .routerLink{
    justify-content: center;
    // padding: 0 20px;

  }
  .routerLink .is-folder-link {

    padding: 0 !important;
  }
  .routerLink .margin {
    margin-left: 0 !important;
  }

  .routerLink .mb-routerLink {
    display: initial;
  }
}

.routerLink .is-first-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.routerLink .obj-item {
  border: 1px solid;
  border-radius: 25px;
  padding: 2px 6px 2px 6px;
  margin: 1px;
  font-size: 11px;
  word-break: break-all;
}

.routerLink .seen-obj {
  color: #1d35ae;
  background-color: #e6ebff;
}

.routerLink .remove-member-style {
  cursor: pointer;
  margin-left: 5px;
}

.routerLink .block-info {
  margin-top: 5px;
  margin-bottom: 5px;
}

.routerLink .obj-item-1 {
  display: inline-block;
}

.routerLink .not-first-item {
  border-top: 1px transparent;
  border-bottom: 1px solid;
}

.routerLink .custom-header-grid {
  background-color: white;
  color: black;
}

.routerLink .nopad-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.routerLink .is-item {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.routerLink .change {
  font-weight: 700;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  color: #575757 !important;
}

.routerLink .is-folder-link {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  // cursor: pointer;
  color: #575757;
}
.routerLink .is-current-folder1 {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
   
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #575757;
}

.routerLink .is-current-folder {
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #025ACD;
}

.routerLink .file-icon {
  width: 30px;
  height: 33px;
  margin-right: 10px;
}

.routerLink .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.routerLink .btn-group-list {
  float: right;
  margin-right: 15px;
}

.routerLink .is-cursor {
  cursor: pointer;
}

.routerLink .left-icon {
  padding-right: 40px;
}

.routerLink .file-type {
  width: 60%;
}

.routerLink .align-center {
  text-align: center;
}

.routerLink .routerLink-item {
  width: 100%;
  height: 100%;
  border: 0.2px solid rgb(194, 194, 194);
  padding: 5px;
  border-radius: 5%;
}

.routerLink .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.routerLink .custom-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.routerLink .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
}

.btn-elipsis button {
  border: none;
  background-color: transparent;
  color: #919191;
  background-image: none;
}

.routerLink .display-content {
  position: relative;
  background-color: white;
  padding: 15px;
}

.routerLink .upload-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.routerLink .header {
  // color: white;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .gallery {
    .dlt-img {
      visibility: hidden;
      padding: 0;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .gallery:hover {
    .dlt-img {
      visibility: visible;
    }
  }

  img {
    width: 100%;
    height: 100%;
    word-break: break-all;
    cursor: pointer;
  }
}

.routerLink .disabled {
  background-color: #6c757d;
}

.routerLink .search-breadcrumb {
   
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #00396b;
}
