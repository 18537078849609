.intro-page {
  width: 100%;
  position: relative;

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
}

  .d-flex {
    visibility: visible !important;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
}

.reward-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
        position: absolute;
        right: 10px;
    }

    .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
}

  .background-img {
    position: relative;

    img {
      width: 100%;
    }
  }

  .col-12 {
    padding: 0;
  }

  .content {
    padding: 0.25rem;
  }

  .menu-bar {
    position: relative;
    margin-top: -84px !important;
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
    justify-content: space-around;
    text-align: left;
    // padding-left: 20px;
    width: 100%;
    // white-space: nowrap;
    flex-wrap: nowrap;

    .tab {
      display: flex;
      flex-direction: row;
    }
  }

  .tab-scroll {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin: 0 1rem;
    cursor: pointer;
  }

  .tab-item {
    padding: 15px;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    height: 5rem;
    color: black;
    background-color: white;
    bottom: 0px;
    transition: all 0.5s ease;
  }

  .tab-des {
    margin: 30px 0 0;
    display: block;

    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: #00396b;
    }

    font-weight: bold;
    color: #00396b;
  }

  .tab-active {
    height: 6rem !important;
    padding-top: 25px;
    color: white;
    background-color: #d6a022;
    text-shadow: 2px 0px 4px #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .history {
    width: 100%;
    margin: 0 0 100px 0;

    img {
      width: 100%;
    }
  }

  .news {
    border-bottom: 1px solid black;
  }
  .dialog-width{
    max-width: 400px;
  }
}

@media screen and (max-width: 1200px) {
  .intro-page {
    .tab-item {
      padding: 0.5rem;
      font-size: 0.64rem;
      line-height: 0.8rem;
      height: 3.438rem;
    }

    .tab-active {
      height: 4.688rem !important;
    }

    .tab-scroll {
      margin: 0 0.375rem;
    }

    .tab-des {
      padding: 0 15px;
    }
  }
}

@media screen and (max-width: 576px) {
  .intro-page {
    .tab-item {
      padding: 0.236rem;
      font-size: 0.5rem;
      line-height: 0.688rem;
      height: 3rem;
    }

    .tab-active {
      height: 4rem !important;
    }

    .tab-scroll {
      margin: 0 0.375rem;
    }

    .tab-des {
      p {
        font-size: 23px;
        line-height: 26px;
      }
    }
  }
}

app-intro-page {
  position: relative;
  top: -6px;
}
