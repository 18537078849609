.register-document{
    font-weight: normal;
    table {
        table-layout: fixed;
    }
    .break-word {
        word-break: break-all;
    }
    table, th, td {
        border: 1px solid;
        font-weight: normal;
      }
      th,td {
          padding-left: 15px;
          padding-top: 7px;
          padding-bottom: 7px;
      }
  .err-text,.required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
}
.text-center {
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

 .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
}

.is-display-flex {
    display: flex;
}

.is-display-grid {
    display: grid;
}

.scoll-x {
    overflow-x: auto;
}


.title-info-req {
    text-align: center;
    font-size: 18px;
    color: black;
    font-weight: bold;
    margin-top: 20px;
 }

 .btn-dialog {
    border-radius: 7px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    float: right;
}
.btn-dialog-wrapper {
    border-width: 0 !important;
    width: 100%;
    margin-top: 10px;
}

.btn-regist-cv {
    color: white;
    background-color: #194f8b;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important
}

.column-tb-1 {
    width: 5%;
    min-width: 50px;
}

.column-tb-2 {
    width: 57%;
    min-width: 150px;
}

.column-tb-3 {
    width: 20%;
    min-width: 150px;
}

.column-tb-4 {
    width: 15%;
    min-width: 135px;
}

.column-tb-5 {
    width: 13%;
    min-width: 110px;
}

.btn-scanning-img {
    color: white;
    background-color: #616161;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    cursor: no-drop !important;
}
.disable-link {
    cursor: no-drop;
    color: gray;
    text-decoration: none;
    font-weight: normal;
}



@keyframes myerr {
    0% {
        border-color: #ea4335;
    }
    25% {
        border-color: yellow;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: #34a853;
    }
}

.x-dialog {
    max-width: 1550px;
    max-height: 700px;
    /* margin-left: 80px; */
    align-self: center;
}

.x-dialog-agree {
    max-width: 500px;
    max-height: 360px;
}

.x-dialog-agree .row {
    line-height: 35px;
}

 .content-margin {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 30px;
}

 .red-text {
    color: red;
}

 .custom-btn-addNew {
    margin-top: 10px !important;
}

 .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
}

 .btn-custom {
    text-align: right;
    margin-right: 15px;
    margin-bottom: 5px;
}

 .btn-custom button {
    background: #cd330d;
    color: white;
}

 .icon-format {
    max-width: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/*  .k-button.k-upload-button {
    display: none;
} */

 .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: rgb(202, 202, 202) !important;
    border-width:  1px;
    vertical-align: middle;
}
th{
    background-color: rgb(243, 242, 242);
}
th,
td {
    padding: 8px 12px;
    border-style: solid;
    text-overflow: ellipsis;
}
td {
    display: table-cell;
}

table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: rgb(202, 202, 202)
}

.is-link {
    cursor: pointer;
    color: #0645AD;
    text-decoration: none;
    font-weight: normal;
}

 .selected-lang {
    border-bottom: 3px red solid;
}
.required {
    color: #ff6358;
    padding-left: 3px;
}
.control {
    margin-bottom: 7px;
}
.control label {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
}

@media screen and (max-width: 770px) {
    .k-window {
        width: 90%;
        height: 90%;
    }
    .x-dialog {
        max-width: 768px;
        max-height: none;
    }

    .btn-dialog-wrapper {
        margin-top: 5px !important;
        padding-left: 0px !important;
    }
}
}

 