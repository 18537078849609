.library-page {
  .background-img {
    position: relative;
    img {
      width: 100%;
      height: 181px;
    }
  }
  .menu-bar {
    position: relative;
    top: -100px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
  }

  .tab-scroll {
    height: 126px;
    width: 160px;
    margin: 0 14px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  .tab-item {
    padding: 5px 10px 5px 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    height: 104px;
    color: black;
    background-color: white;
    bottom: 0px;
    transition: all 0.5s ease;
  }

  .tab-des {
    margin: 30px 0;
    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: #00396b;
    }
    font-weight: bold;
    color: #00396b;
  }

  .active {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #d6a022;
    text-shadow: 2px 0px 4px #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

app-library {
  position: relative;
  top: -6px;
}
