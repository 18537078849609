.news-template-11 {
  width: 100%;
  background-color: #ffffff !important;

  .row {
    --bs-gutter-x: 0;
  }

  .post-title {
    font-weight: bold;
    border-left: 7px solid #D8A750;
    margin-bottom: 20px;
    min-height: 60px;
    font-size: 24px;
    padding-left: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #007FAE;
    text-transform: uppercase;
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    min-width: 14px;
    min-height: 14px;
    background-color: #007FAE;
  }

  .title {
    margin: 0;
    margin-left: 20px;
    font-weight: 500;
    font-size: 20px;
  }

  .post-image {
    width: 100%;
    border: 1px solid #DCDCDC;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    display: flex;
    justify-content: center;
  }

  .three-dot {
    width: 5px;
    height: 20px;
  }

  .main-item {
    display: table;
    margin: 0;
  }

  .list-news {
    border: 1px solid #efefef;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22px;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: fit-content;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .top-new-item {
    margin-top: 20px;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-11-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .text-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .title-first {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .content-detail {
    font-size: 16px;
  }

  .first-news {
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-bottom: 1px solid #DDDDDD;
    margin-top: 0px;
    padding-bottom: 20px;
  }

  .top-news-detail {
    width: 100%;
  }
  .left,
  .right {
    width: 50%;
  }
  @media (max-width: 768px) {
    .left,
    .right {
      width: 100%;
    }
    .first-news {
      flex-direction: column;
    }
  }
}
