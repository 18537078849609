.news-template-23 {
  width: 100%;
  background-color: #ffffff !important;
  height: 100%;
  .container-fluid{
    align-items: baseline !important;
  }
  .row {
    --bs-gutter-x: 0;
  }
  .scroll-area{
    // max-height: 690px;
    // overflow: hidden;
    // overflow-y: auto;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scroll-area::-webkit-scrollbar{
    display: none;
  }
  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: flex;

    // justify-content: center;
    color: #0b68a4;
     
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    margin-top: 20px;
    margin-bottom: 15px;
    background-color: #00396b;
    position: relative;
    height: 40px;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #eb2e35;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;

    a {
      color: #eb2e35;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    min-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    letter-spacing: -0.01em;
    color: #1f1f1f;
    margin-left: 16px;
    margin-bottom: 0;
  }

  .post-image {
    height: 198px;
    width: 100%;
    object-fit: cover;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
    .row {
      flex-wrap: nowrap;
    }
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .d-flex {
    visibility: visible !important;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img {
    height: 198px;
    max-width: 372px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }
  .uppercase {
    color: #ffffff;
    padding-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .top-new-item {
    margin: 10px 0;
    width: 100%;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-23-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .flower-img {
    width: 19px;
    height: 19px;
    margin: 10px;
  }

  .first-news-deco {
    position: absolute;
    right: 0px;
    height: 40px;
    width: 49px;
  }

  .text-section {
    display: flex;
    min-height: 30px;
    margin-top: 10px;
  }

  .title-first {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #00396b;
  }

  .new-detail {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #1f1f1f;
    height: 66px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .nested-menu {
    // border: 1px solid #e4e4e4;
    .list-group-items {
      margin-bottom: 10px;
      //margin-top: 30px;
       
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 46px;
      color: #006CB5;
      padding: 0px 0px 0px 20px;
      border: none;
    }
    .list-submenu {
      // padding: 0 10px;
      padding-left: 10px;
      //height: 430px;
      overflow: hidden;
      overflow-y: auto;

      :last-child {
        border: none;
      }
    }
    .submenu {
      text-decoration: none;
      cursor: pointer;
      list-style-type: none;
      height: auto;
      color: #4a4a4a;
      //padding: 0 10px;

      font-weight: 500;
      display: block;
  
      .sub-item{
        display: flex;
        font-size: 14px;
        border-bottom: 1px dashed #848484;
      }
    } 
    .submenu:first-child{
      padding-top: 0px;
    }
      .img-first{
        width: 100%;
        object-fit: cover;
      }
      .sub-item{
        display: flex;
        font-size: 14px;      
      }
      .sub-number{
         
        font-size: 48px;
        width: 50px;
        padding-right: 10px;
        margin-left: 15px;
      }
  }
  .water-icon{
    width: 20px;
    height: 20px;
    margin-top: 18px;
    margin-left: 17px;
  }
  .list-title{
    display: flex;
  }
  .item-title{
     
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #2B2B2B;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
  }
  .item-title-first{
     
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2B2B2B;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .item-keyword{
     
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #027DC3;
  }
  .item-time{
     
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A8A8A8;
    margin-left: 15px;
  }
  .item-info{
    margin-top: 3px;
  }
  .sidebar{
    border-left: 0.7px solid #D8D8D8;
    margin-left: 10px;
    margin-top: 30px;
  }
  .desktop-width {
    width: 100vw;
    left: calc(-50vw + 50%);
    background: #F9F9F9;
  }
  .content-width-media {
      //width: calc(100% - 250px);
      width: calc(75% - 220px);
      margin: auto;
  }
  // ::-webkit-scrollbar {display:none;}
  ::-webkit-scrollbar {
    width: 3px; 
  }

  ::-webkit-scrollbar-thumb {
    background: #676262;
  }
  ::-webkit-scrollbar-track {
    padding-top: 1px;
    padding-bottom: 1px;
    background: rgb(255, 255, 255);
  }
  @media(max-width: 768px){
    .sidebar{
      border-left: none !important;
      margin-left: 0px !important;
      margin-top: 0px !important;
    }
    .list-group-items{
      padding: 0px !important;
    }
    .list-submenu{
      padding-left: 0px !important;
      height: auto !important;
    }
    .scroll-area{
      background: #F9F9F9;
    }
    .img-first{
      height: auto !important;
    }
  }
}
