.news-template-50 {
    width: 100%;
    background-color: #ffffff !important;

    .row {
      --bs-gutter-x: 0;
    }

    .k-dialog {
      // height: auto !important;
      // width: auto !important;
      // width: 400px !important;
      height: 100%;
      width: 100%;
    }

    .pd-l-10 {
      padding-left: 18px;
    }
    .menu-col {
      padding-right: 10px;
    }
    .post-title {
      align-items: center;

      position: relative;
      min-height: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;

      color: #ee4623;
      position: relative;

      .nav-scroll {
        position: relative;
        width: fit-content;
        align-items: center;
        // display: flex;
        margin-right: 20px;
        cursor: pointer;
        margin-bottom: 6px;
      }

      .nav-item,
      .nav-item-selected {
        box-sizing: border-box;
        padding: 8px 0px 8px 0px;
        font-weight: 400;
        font-size: 16px;
        border: none;
        background-color: #ffffff;
        text-transform: uppercase;
      }

      .nav-item-selected {
        border: none;
        //border-bottom: 4px solid #007fae;
        font-weight: 700;
        color: #007fae;
      }

      @keyframes mykey {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
      .nav-scroll-selected::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        background-color: #007fae;
        left: 0;
        bottom: 0;
        margin-bottom: 15px;
        animation: mykey 0.3s ease-out;
      }
      .menu-bar {
        background-color: #ffffff;
        align-items: center;
        color: #494949;
        text-align: left;
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
        padding: 0 12px;
        padding-left: 0px;
        padding-top: 14px;
        width: 100%;
        height: 50px;
        margin-bottom: 35px;
      }
      .menu-bar:hover {
        overflow-x: auto;
      }
      .menu-bar::-webkit-scrollbar {
        height: 4px;
      }

      .menu-bar::-webkit-scrollbar-thumb {
        background: #d9d9d9;
      }
      .menu-bar::-webkit-scrollbar-track {
        padding-top: 1px;
        padding-bottom: 1px;
        background: rgb(255, 255, 255);
      }
    }

    .post-more {
      display: flex;
      justify-content: center;
      color: #eb2e35;

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      background-color: white;
      padding-top: 20px;
      padding-bottom: 20px;

      a {
        color: #eb2e35;
      }
    }

    .pointer {
      cursor: pointer;
    }

    .icon-clock {
      width: 8px;
      height: 8px;
      margin-top: 5px;
      //margin-left: 7px;
    }
    .icon-arrow {
      width: 16px;
      height: 16px;
      margin-top: -4px;
      margin-left: 8px;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: justify;
      color: #1f1f1f;
      margin-bottom: 0;
    }

    .post-image,
    .post-image-cover {
      height: 200px;
      width: 100%;
      border-radius: 5px;
    }
    .post-image-cover {
      object-fit: cover;
    }
    .post-right-image {
      height: 200px;
      width: 100%;
      // border-radius: 5px;
      object-fit: cover;
    }

    .post-image-org {
      height: 60px;
      width: 60px;
      position: absolute;
      top: 40%;
    }

    .time-clock {
      display: flex;
      align-items: center;
    }

    .clock {
      width: 15px;
      margin-right: 5px;
    }

    .time-text {
      // font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #5f717b;
      padding: 0;
      margin: 0;
    }

    .main-item {
      //display: table;
      margin: 0;
      width: 100%;
    }

    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color: white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;
    }

    .d-flex {
      visibility: visible !important;
    }

    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .img-figure,
    .img-figure-no-img {
      // height: 198px;
      height: 100%;
      // max-width: 372px;
      display: flex;
      //justify-content: center;
      position: relative;
      margin-bottom: 10px;
    }

    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
    .k-scrollview-wrap {
      border: none;
    }

    .close-div {
      display: flex;
      margin-top: 3px;
    }

    .news-template-50-dialog {
      display: flex;
      justify-content: center;
      position: relative;

      .close-icon-div {
        position: absolute;
        right: 10px;
      }

      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .title-info-req {
        // font-size: 20px;
        font-weight: 500;

        color: #004a9a;
        // text-align: center;
        // margin: 30px;
      }
    }

    .input-label {
      color: #004a9a;
      margin: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      font-size: 15px;
      text-align: center;
    }

    .btn-delete {
      margin: 15px;
      width: 100px;
      height: 40px;

      text-align: center;
      border-radius: 5px;
      background-color: #1c8d43;
      border: hidden;
      color: white;
      font-size: 14px;
    }
    .btn-detail {
      margin-top: 26px;
      width: 100px;
      height: 40px;

      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #ee4623;
      border-radius: 3px;
    }

    .btn-cancel {
      margin: 15px;
      width: 100px;
      height: 40px;

      text-align: center;
      border-radius: 5px;
      background-color: #6c757d;
      border: hidden;
      color: white;
      font-size: 14px;
    }

    .flower-img {
      width: 19px;
      height: 19px;
      margin: 10px;
    }

    .first-news-deco {
      position: absolute;
      right: 0px;
      height: 100%;
      width: 49px;
    }

    .text-section {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      :hover {
        color: #00396b;
      }
    }

    .title-first {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #000000;
      margin-bottom: 0px !important;
    }

    .new-detail {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #454545;
      height: 66px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 0px;
    }
    .button-group {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content-right {
      padding-top: 25px;
      padding-left: 30px;
    }
    .detail-btn {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #4d4d4d;
      padding-left: 3px;
    }
    .top-new-first-item {
      margin-bottom: 15px;
      // padding-left: 10px;
      //padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title:hover {
        color: #00396b;
      }
    }
    .menu-title {
      .uppercase {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #212529;
        text-transform: uppercase;
      }
      hr {
        height: 4px;
        width: 92px;
        color: #DD4806;
        opacity: 1;
        margin: 0;
      }
    }
    .description {
      display: flex;
      margin-top: 30px;
      .mobile-view {
        display: none;
      }
      .image {
        img {
          border-radius: 4px;
        }
        .main-img {
          width: 100%;
          min-width: 100%;
          margin-bottom: 10px;
          // height: 420px;
          object-fit: fill;
        }
        .sub-img-1,
        .sub-img-2 {
          width: 49%;
          min-width: 49%;
          margin-top: 10px;
          // height: 225px;
          object-fit: fill;
        }
        .sub-img-1 {
          margin-right: 1%;
        }
        .sub-img-2 {
          margin-left: 1%;
        }
      }
      .content {
        padding-left: 50px;
      }
    }
    .process {
      margin-top: 30px;
      border-radius: 4px;
      background: rgba(41, 233, 226, 0.60);
      padding: 80px;
      .title {
        text-align: center;
        .uppercase {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #212529;
          text-transform: uppercase;
        }
      }
      .content {
        padding: 0 120px;
        margin-top: 40px;
      }
    }
    .student-list {
      margin-top: 30px;
      border-radius: 4px;
      background: #FFFDEC;
      padding: 80px;
      .title {
        text-align: center;
        .uppercase {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #212529;
          text-transform: uppercase;
        }
      }
      .content {
        padding: 0 100px;
        margin-top: 40px;
        .student-content {
          .student-title {
            padding: 20px;
            display: flex;
            gap: 12px;
            cursor: pointer;
            .arrow i {
              font-size: 20px;
            }
            .name {
              color: #212529;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
          .student-info {
            display: flex;
            margin-bottom: 20px;
            .info-image {
              padding-left: 20px;
              img {
                width: 100%;
                // height: 280px;
                object-fit: fill;
              }
            }
            .info-content {
              padding-left: 30px;
            }
          }
        }
        .content-border {
          border-bottom: 1px solid #E3E7EA;
        }
      }
    }
    .report {
      margin: 50px 0 30px;
      .title {
        margin-bottom: 15px;
        .uppercase {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #212529;
          text-transform: uppercase;
        }
      }
      .content {
        margin: -15px;
        .report-content {
          padding: 15px;
          text-decoration: none;
          .image {
            img {
              width: 100%;
              margin-bottom: 10px;
              // height: 280px;
              object-fit: fill;
            }
          }
          .item-title {
            overflow: hidden;
            color: #212529;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            span {
              position: relative;
              display: -webkit-box;
              text-overflow: ellipsis;
              white-space: normal;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
        .report-content:hover {
          .item-title {
            color: #006CB5;
          }
        }
      }
    }
    .activities {
      margin: 50px 0 30px;
      .title {
        margin-bottom: 15px;
        .uppercase {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
          color: #212529;
          text-transform: uppercase;
        }
      }
      .content {
        margin: -15px;
        .activity-content {
          display: flex;
          flex-direction: column;
          padding: 15px;
          text-decoration: none;
          .image {
            img {
              width: 100%;
              margin-bottom: 10px;
              // height: 280px;
              object-fit: fill;
            }
          }
          .item-title {
            overflow: hidden;
            color: #212529;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            margin-top: 20px;
            span {
              position: relative;
              display: -webkit-box;
              text-overflow: ellipsis;
              white-space: normal;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .time-clock {
            display: flex;
            align-items: center;
            margin-top: 12px;
            .clock {
              width: 15px;
              margin-right: 5px;
            }

            .time-text {
              // font-style: italic;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.02em;
              color: #5f717b;
              padding: 0;
              margin: 0;
            }
          }
        }
        .activity-content:hover {
          .item-title {
            color: #006CB5;
          }
        }
      }
    }
    .top-new-item {
      margin-bottom: 47px;
      // padding-left: 10px;
      //display: flex;
      justify-content: space-between;
      text-decoration: none;
      .title:hover {
        color: #00396b;
      }
    }
    .post-list {
      margin-right: -20px;
      // justify-content: space-between;
      .k-pager-info,
      .k-pager-sizes {
        display: none;
      }
      .k-pager {
        // padding-left: auto;
        kendo-datapager-prev-buttons {
          margin-left: auto;
        }
        .k-state-selected {
          background-color: #d6a022;
          border: none;
        }
      }
      .top-part {
        display: flex;
        flex-direction: row;
        // box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
        margin-right: 30px;
        height: 100%;
      }
    }
    .post-image {
      height: 189px;
      width: 100%;
      object-fit: cover;
    }
    .post-image-default {
      height: 271px;
      width: 100%;
    }
    .first-post-image-default {
      height: 271px;
      width: 100%;
    }

    .post-image-org {
      height: 60px;
      width: 60px;
      position: absolute;
      top: 40%;
    }
    .hide-item {
      display: none;
    }
    .line {
      border: 1px solid #dc7e10;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;
    }
    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      // line-height: 23px;
      letter-spacing: -0.02em;
      color: #000000;
      height: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 0px;

      margin-bottom: 16px;
      min-height: 80px;
    }
    .summary {
      font-weight: 400;
      font-size: 16px;
      // line-height: 20px;

      color: #696969;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      // min-height: 100px;
      height: 70px;
    }
    .news-title {
      width: fit-content;
    }
    .left-box {
      margin-right: 20px;
    }
    .right-item-detail {
      // padding-left: 31px;
      // padding-right: 30px;
      // padding-bottom: 32px;
      height: 50%;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background-color: transparent;
    }
    .pointer {
      cursor: pointer;
    }

    .video-title {
      font-weight: 700;
      font-size: 16px;
      // line-height: 26px;

      margin-top: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .dialog-width {
      max-width: 400px;
      max-height: 354px;
    }

    .dialog-width-video {
      width: 1040px;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      margin: auto;
    }

    .width-full {
      width: 100%;
      // height: 100%;
      position: relative;
    }

    .d-grid {
      display: grid !important;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px 20px;
    }

    .download-radio {
      width: 32px;
      height: 32px;
      min-width: 32px;
      position: relative;
      border-radius: 32px;
      background: var(--1, linear-gradient(180deg, #006CB5 0%, #00AEEF 100%));
      border: none;
    }
    .download-radio-icon {
      width: 24px;
      height: 24px;
      position:absolute;
      top: 50%;
      left:50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .radio-container{
      align-items: center;
      display: flex;
      margin: 10px 0;
    }

    .radio-info{
      margin-right: 6%;
      max-width: 42%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .album-name {

      font-style: normal;
      font-weight: 700;
      color: #3F3F3F;
      font-size: 16px;
      font-weight: 700;
    }

    .author-name {
      max-width: 4.5%;
      width: 100%;
      color: #000;
      font-size: 16px;

      font-weight: 600;
    }

    .radio-order{
      color: #006CB5;
      font-size: 16px;

      font-weight: 700;
      width: 2%;
    }

    .radio-duration {
      color: #000;
      font-size: 16px;

      font-weight: 700;
    }

    .view-count-icon {
      max-width: 1.2%;
      margin: 0 0.7% 0 7%;
      color: #C2C2C2;
    }

    .view-count {
      color: #C2C2C2;
      font-size: 16px;

      font-weight: 700;
      margin-right: 6%;
      max-width: 3%;
      width: 100%;
    }

    .media {
      position: relative;
      width: 6%;
      margin: 0 2% 0 1%;
      border: #E3E7EA 1px solid;
      border-radius: 5px;
    }

    .post-image-cover {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      padding: 5px;
    }

    audio {
      height: 40px;
      width: 350px;
    }

    @media screen and (max-width: 1200px) {
      .pd-l-10 {
          padding-left: 0px;
      }
      .d-grid {
          grid-template-columns: repeat(3, 1fr);
      }

      .video-title {
          font-size: 18px !important;
      }

      .post-more {
          font-size: 16px !important;
      }
    }
    @media screen and (max-width: 768px) {
      .dialog-width-video {
          max-width: 90% !important;
          max-height: 317px !important;
        }

        .k-dialog {
          height: 100% !important;
          width: 100% !important;
          //margin-top: 70px;
        }

        .close-icon-div {
          position: relative;
          float: right;
          top: -30px;
        }

        .k-window-content {
          padding: 0px !important;
          border-width: 0;
          border-color: inherit;
          color: inherit;
          background: none;
          outline: 0;
          overflow: hidden;
          position: absolute !important;
          flex: 1 1 auto;
        }

        .k-dialog {
          position: initial !important;
        }
      .line {
        border: 1px solid #dc7e10;
        margin-top: 5px;
        margin-bottom: 10px;
        min-width: auto;
      }
      .post-list {
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
        .top-part {
          display: flex;
          flex-direction: column;
          margin-right: 0px;
        }
      }
      .top-new-item {
      //   margin-left: 5px;
        padding-right: 10px;
        display: block;
      }
      .content-right {
        padding-top: 0px;
      }
      .title-text {
        min-height: fit-content;
      }

      .d-grid {
          grid-template-columns: repeat(2, 1fr);
      }

      .post-title {
        .arrow-box {
          position: relative;
        }
        .title-right {
          position: relative;
          left: 0;
          top: 0;
          width: 20%;
        }
        .menu-bar {
          width: 90%;
        }
      }

      .radio-container{
        display: block;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        margin: 20px 0;
        padding: 5px;
        font-size: 14px;
      }
      audio {
        margin-top: 0px;
        width: 100%;
        height: 30px;
        padding: 0 5px;
      }
      .menu-title {
        .uppercase {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .process,
      .student-list,
      .report,
      .activities {
        .title {
          .uppercase {
            font-size: 20px;
          }
        }
      }
      .description {
        .web-view {
          display: none;
        }
        .mobile-view {
          display: block;
          .img-scrollview {
            width: 100%;
            object-fit: fill;
          }
          .k-scrollview-wrap {
            overflow: visible;
            .k-scrollview-pageable > .k-button {
              background: #E3E7EA;
            }
            .k-scrollview-pageable > .k-button.k-primary {
              background: #025ACD;
            }
            kendo-scrollview-pager {
              bottom: -50px;
            }
          }
        }
        .content {
          margin-top: 70px;
        }
      }
      .process {
        padding: 30px;
        .content {
          padding: 0 20px;
          margin-top: 30px;
        }
      }
      .student-list {
        padding: 30px;
        .content {
          padding: 0 20px;
          margin-top: 30px;
          .info-content {
            margin-top: 15px;
          }
        }
      }
      .report {
        .content {
          margin: 0;
          .item-title {
            padding-left: 10px !important;
            margin: auto 0;
            span {
              -webkit-line-clamp: 3 !important;
            }
          }
          img {
            // height: 100px !important;
            margin-bottom: 0 !important;
          }
        }
      }
      .activities {
        .content {
          margin: 0;
          .image {
            img {
              // height: 100px !important;
              margin-bottom: 0 !important;
            }
          }
          .item-title {
            margin-top: 0 !important;
            // margin: auto 0;
            span {
              -webkit-line-clamp: 3 !important;
            }
          }
          .item-title,
          .time-clock {
            padding-left: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 576px) {
      .d-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;    }

      iframe {
          width: 100%;
          height: 100%;
      }

      .dialog-width-video {
          max-width: 90%;
          max-height: 317px;
        }

        .k-dialog {
          height: 100% !important;
          width: 100% !important;
          //margin-top: 70px;
        }

        .close-icon-div {
          position: relative;
          right: 35px !important;
          top: -30px;
        }

        .k-window-content {
          padding: 0px !important;
          border-width: 0;
          border-color: inherit;
          color: inherit;
          background: none;
          outline: 0;
          overflow: hidden;
          position: absolute;
          flex: 1 1 auto;
        }

        .k-dialog {
          position: initial;
        }

      .top-part {
          margin-right: 0px;
      }
    }
  }
