.archive {
  background: white;
  height: 90%;
  min-width: 794px;
  min-height: 500px;
  // height: 100vh;
}

.archive .mb-archive {
  display: none;
}

.archive .mb-archive span.value {
  color: #3b3b3b;
  font-weight: 500;
}

.archive .g-btn {
  margin-top: 5px;
}

.archive .g-btn kendo-dropdownbutton button {
  height: 100%;
  display: inline-block;
}

.archive .input-label {
   
  margin: 10px;
  margin-left: 15px;
  margin-bottom: 0px;
}

.archive .input-box {
  // height: 40px;
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
  // .k-input{
  //   height: 40px;
  // }
  // .k-clear-value {
  //   height: 40px;
  //   margin-top: -2px;
  // }
}

.archive .btn-custom button {
  background: #34a853;
  color: white;
  color: white;
  width: 140px;
  height: 40px;
  border-radius: 5px;
}

.archive .btn-dialog-wrapper {
  padding: 20px 10px;
  position: relative;
}
.archive .btn-save{
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.archive .mb-archive .function {
  text-align: right;
}

.archive.x-dialog-shared {
  max-height: 600px;
  max-width: 1300px;
}

.archive.x-dialog {
  max-height: 200px;
  max-width: 600px;
}

.archive .popup-width {
  width: 140px;
}

.archive .btn-fr {
  float: right;
}

.archive .btn-fl {
  display: flex;
  float: left;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  span {
    margin: 0 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  span:first-of-type {
    margin-left: 0;
  }

  span:last-of-type {
    margin-right: 0;
  }
}

.archive .align-center-bt {
  text-align: center;
}

.archive .headerOfficeStyle {
  text-align: center;
  color: #675f5f;
  background: #f6f6f6;
  text-overflow: inherit;
  vertical-align: middle;
  padding: 0px;
}

.archive .block-nm {
  display: inline;
}

.archive .btn-show {
  width: 15px;
  display: inline-table;
  border: none;
  background-color: transparent;
}

.archive .mr-r15 {
  margin-right: 15px;
}

.archive .icon-mouse {
  cursor: pointer;
}

@media (max-width: 768px) {
  .archive .g-archive {
    display: none;
  }

  .archive .mb-archive {
    display: initial;
  }
}

.archive .is-first-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.archive .obj-item {
  border: 1px solid;
  border-radius: 25px;
  padding: 2px 6px 2px 6px;
  margin: 1px;
  font-size: 11px;
  word-break: break-all;
}

.archive .seen-obj {
  color: #1D35AE;
  background-color: #E6EBFF;
}

.archive .remove-member-style {
  cursor: pointer;
  margin-left: 5px;
}

.archive .block-info {
  margin-top: 5px;
  margin-bottom: 5px;
}

.archive .obj-item-1 {
  display: inline-block;
}

.archive .not-first-item {
  border-top: 1px transparent;
  border-bottom: 1px solid;
}

.archive .custom-header-grid {
  background-color: white;
  color: black;
}

.archive .nopad-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.archive .is-item {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.archive .is-folder-link {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #9F9F9F;
}

.archive .is-current-folder {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  font-weight: bold;
}

.archive .file-icon {
  width: 30px;
  height: 33px;
  margin-right: 10px;
}

.archive .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.archive .btn-group-list {
  float: right;
  margin-right: 15px;
}

.archive .is-cursor {
  cursor: pointer;
}

.archive .left-icon {
  padding-right: 40px;
}

.archive .file-type {
  width: 168px;
  height: 108px;
  object-fit: cover;
  
}
.archive .file-type-folder {
  width: 54px;
  height: 45px;
  margin-top: 15px;
}

.archive .align-center {
  text-align: center;
  padding: 0;
}
.archive .align-center-top {
  text-align: center;
  padding: 0;
  margin-top: 26px;
}

.archive .archive-item {
  width: 100%;
  height: 100%;
  border: 1px solid #DFDFDF;
  padding: 5px;
  //border-radius: 5%;
}

.archive .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.archive .custom-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 15px;
}

.archive .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
}

.btn-elipsis button {
  border: none;
  background-color: transparent;
  color: #919191;
  background-image: none;
}

.archive .display-content {
  position: relative;
  background-color: white;
  padding: 15px;

  .archive-item.image {
    border: 0;
    padding: 0;
  }

  .folder:hover {
    background-color: #dee2e6;
  }
}

.archive .upload-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.archive .header {
  // color: white;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 200px;
    position: relative;

    .dlt-img {
      visibility: visible;
      position: absolute;
      text-shadow: none;
      background: rgba(32, 28, 28, 0.4);
      height: 18px;
      width: 18px;
      color: white;
      cursor: pointer;
      top: 5px;
      right: 40px;

      i {
        font-size: 14px;
      }
      .close-btn-white{
        width: 12px;
        height: 12px;
      }
    }
  }

  .gallery:hover {
    .dlt-img {
      visibility: visible;
    }
  }

  img {
    width: 100%;
    word-break: break-all;
    cursor: pointer;
  }
  .picked-img{
    width: 318px;
    object-fit: cover;
  }

  .add-new {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 30%;
    }

    .custom-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-top: 9px;
      margin-bottom: 26px;
      color: #838383;
    }
  }

  .border:hover {
    background-color: #dee2e6;
  }
}

.archive .go-back {
  margin-bottom: 15px;
  cursor: pointer;

  i {
    font-size: xx-large;
  }
}

.archive .image {
  img {
    width: 100%;
    height: 108px;
  }
}

.archive .disabled {
  background-color: #6c757d;
}
.archive .border{
  border: 1px dashed #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 317px;
  .border-height{
    height: 100%;
  }
}
.archive .picked-img{

}
@media (max-width: 600px){
  .archive{
    min-width: 100%;
  }
}
.archive .vertical-dot-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color:white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  left: 40px;

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu{
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 130px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;
    display: none;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  
}
.archive .title-info-req {
  // font-size: 20px;
  font-weight: 500;
   
  color: #004A9A;
  // text-align: center;
  // margin: 30px;
}
.archive .size-dialog{
  position: absolute;
  // .k-dialog{
  //   top: 30%;
  // }
  .reward-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
        position: absolute;
        right: 10px;
    }

    .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }
  .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
  }
  .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
  }
  .err-text {
    color: #ff6358;
    margin-left: 15px;
    font-style: italic;
  }
}
