.customer-care {

  margin-left: 200px;

  input.k-input {
    width: 100% !important;
    height: 50px !important;
    border-radius: 3px !important;
    // border: 1px solid #C8C8C8 !important;
    // padding-top: 13px!important;
    // padding-bottom: 13px!important;
    padding-left: 19px !important;
  }

  .auto {
    margin: auto;
    ;
  }

  input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: 20px;
    width: 20px;
}
  .checkboxFour {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    border: 1px solid #006CB5;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .checkboxFour label {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background: white;
  }

  .checkboxFour input[type=checkbox]:checked+label {
    background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
    ;

  }

  .checkboxFour1 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    border: 1px solid #006CB5;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .checkboxFour1 label {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background: white;
  }

  .checkboxFour1 input[type=checkbox1]:checked+label {
    background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
    ;

  }

  .checkboxFour2 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    border: 1px solid #006CB5;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  }

  .checkboxFour2 label {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background: white;
  }

  .checkboxFour2 input[type=checkbox2]:checked+label {
    background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
    ;

  }

  .checkboxFive label {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background: #eee;
    border: 1px solid #ddd;

  }

  .checkboxFive label:after {
    opacity: 0.2;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 6px;
    left: 7px;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);

  }

  .checkboxFive label:hover::after {
    opacity: 0.5;
  }

  .checkboxFive input[type=checkbox]:checked+label:after {
    opacity: 1;
  }

  .k-input::placeholder,
  .k-input::placeholder {
    color: #B9B9B9;
    // text-transform: uppercase;
  }

  .k-i-close {
    height: 100%;
  }

  .text-image {
    margin-top: 20px;
    ;
    align-content: center;
    align-items: center;
     
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    display: flex;
    line-height: 19px;
    color: #565656;
  }

  .titile {
     
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #343434;
  }

  .quiz-title {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #000000;
    margin-left: 30px;
     
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  .quiz-wrapper {
    display: flex;
  }

  .quiz-drop {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px
  }

  .round-checkbox {
    // display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .button {
    width: 249px;
    height: 5px;
    left: 60px;
    top: 109px;
    background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
  }

  hr {
    margin: auto;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
  }

  .round-checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #006CB5;
  }

  .text-not-title {
    height: 40px;
     
    position: relative;
    top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    line-height: 19px;
    color: #FF0000;
  }

  .round-checkbox input:checked+.checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .round-checkbox input:checked+.checkmark:after {
    display: block;
  }

  .round-checkbox .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .checkmark-text {
    height: 20px;
    width: 20px;
    border: 1px solid #C2C2C2;
  }

  .round-checkbox input:checked+.checkmark {
    background-color: #2196f3;
  }

  .checkmark-text:after {
    content: "";
    position: absolute;
    display: none;
  }

  .round-checkbox input:checked+.checkmark-text:after {
    display: block;
  }

  .round-checkbox .checkmark-text:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    // border-radius: 50%;
    background: white;
  }

  .k-combobox {
    border: 1px solid #C8C8C8 !important;
    width: 100%;
    height: 50px;
    border-width: 0;
    box-sizing: border-box;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    text-align: left;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .d-flex {
    visibility: visible !important;
  }


  .quiz-inputs {
    width: 33%;
    margin-right: 68px;
  }

  .quiz-input-item,
  .quiz-input-item-captcha {
    margin-bottom: 15px;
    // width: 590px;
  }

  .quiz-input-item-captcha {
    display: flex;
  }

  .quiz-textarea-item,
  .quiz-button-item {
    width: 100%;
    // margin-bottom: 26px;
    // display: flex;
    flex-wrap: wrap;
    height: 70px;

    .k-upload {
      display: none;
    }
  }

  .quiz-button-item {
    margin-top: 10px;
  }

  .button-image {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    height: 40px;
    background: #006CB5;
    flex: none;
    order: 0;
     
    font-style: normal;
    font-weight: 600;
    color: white;
    font-size: 16px;
    width: auto;
    line-height: 20px;
    display: flex;
    flex-grow: 0;
    border: none;
    margin-left: 20px;

  }

  .k-textbox {
    width: 100%;
    height: 50px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px;
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .k-textbox::placeholder,
  .k-textarea::placeholder {
    color: #B9B9B9;
    // text-transform: uppercase;
  }

  .k-textarea {
    width: 100%;
    height: 197px;
    resize: none;
    border: 1px solid #C8C8C8;
    padding-left: 19px;
    padding-top: 19px;
  }


  .quiz-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .quiz-form-button {
    min-width: 169px;
    font-size: 18px;
    padding: 8px;
    border: none;
    background-color: #00396B;
    color: #FFFFFF;
    border-radius: 3px;
    text-transform: uppercase;
  }

  .quiz-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .quiz-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    margin-right: 13px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #D6A022;

  }

  .quiz-info {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #5E5E5E;

  }

  .quiz-error {
    color: #d51923;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-top: 4px;
  }

  .send-background {
    background: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  }

  .k-tabstrip-items {
    background-color: #027DC3;
    justify-content: normal;
  }

  .k-tabstrip-items .k-item {
    color: white;
    border: none;
  }

  .k-tabstrip-items .k-item.k-state-active {
    color: white;
    background-color: #03598A;
    border: none;
  }

  .k-tabstrip-items .k-link {
    padding: 14px 26px;
  }

  .k-tabstrip>.k-content {
    padding: 0;
  }

  .label-text {
    display: flex;
    align-items: center;
    margin: 0px 10px 5px;
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-left: 25px;
    color: #000000;
   
  }
  .label-text1 {
    display: flex;
    align-items: center;
    margin: 0px 10px 5px;
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-left: -30px;
  }
  .form-row-checkbox {
    display: flex;
    width: 100%;
    margin: 15px 0px 15px 0px;
    // justify-content: space-between;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .quiz-form {
    padding-left: 15px;
  }

  .data-title {
    color: #414141;
    display: flex;
    align-content: center;
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353535;
    align-items: center;
    height: 40px;
  }

  .required {
    color: #EE2323;
    padding-left: 3px;
  }

  .add-image-text {
     
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #EE4623;
    height: 70px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  .image-upload,
  .add-image {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .add-image {
    border: 1px solid #C8C8C8;
  }

  .captcha-item {
    background-color: white;
    height: 50px;
    margin-left: 40px;
    border: 1px solid #C8C8C8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background: #E3E3E3;
  }

  .reload-captcha-btn {
    width: 50px;
    height: 50px;
    object-fit: cover;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  .reload-icon {
    height: 16px;
    width: 16px;
  }

  .quiz-submit-button {
    min-width: 169px;
    border: none;
    float: right;
    padding: 11px 10px;
    // width: 233px;
    height: 50px;
    text-transform: uppercase;
    background: #006CB5;
     
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 3px;
  }

  .quiz-reset-button {
    min-width: 169px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 8px;
    border: 1px solid #BEBEBE;
    background: #FFFFFF;
    color: #727272;
    border-radius: 3px;
    margin-left: 20px;
  }

  .k-content.k-state-active {
    //display: flex;
    border: none;
    overflow: hidden;
  }

  .categories-box {
    width: 100%;
    height: 140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(107, 144, 200, 0.84);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .image-categories {
    width: 100%;
    height: 140px;
    object-fit: cover;
  }

  .categories-text {
    position: absolute;
    text-align: center;
     
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
  }

  .categories-area {
    padding-left: 20px;
    margin-top: 20px;
  }

  .header-area,
  .footer-area {
    padding: 0 !important;
  }

  .wrapper-area-flex {
    display: flex;
    justify-content: center;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 48%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 73%;
  }

  .wrapper {
    // width: 1140px;
  }

  @media screen and (max-width: 600px) {
    .col-md-6 {
      flex: 0 0 auto;
      width: 47%;
      margin-right: 10px;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 47%;
    }
    .quiz-form {
      padding: 0 15px;
    }

    .form-row {
      display: block;
    }

    .quiz-input-item {
      width: 100%;
    }

    .quiz-textarea-item {
      width: 100%;
      display: block;
    }

    .quiz-input-item-captcha {
      display: block;
    }

    .captcha-item {
      margin-left: 0;
      margin-top: 10px;
    }

    .reload-captcha-btn {
      margin-left: 0;
      margin-top: 10px;
    }

    .quiz-button-item {
      flex-direction: column;
      width: 100%;
    }

    .quiz-reset-button {
      margin-left: 0;
      margin-top: 10px;
    }

  }

  @media screen and (max-width: 770px) {
    .row {
      margin-left: 0px !important;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 47%;
    }
    .categories-area {

      margin-top: 20px;
    }

    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin-top: var(--bs-gutter-y);
    }

    .k-combobox {
      border: 1px solid #C8C8C8 !important;
      width: 100%;
      height: 50px;
      border-width: 0;
      box-sizing: border-box;
      outline: 0;
      background: none;
      font-family: inherit;
      font-size: 14px;
      line-height: 1.4285714286;
      text-align: left;
      white-space: nowrap;
      display: inline-flex;
      vertical-align: middle;
      position: relative;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .col-md-9 {
      flex: 0 0 auto;
      width: 97% !important;
    }

    .text-not-title {
      height: 40px;
       
      position: relative;
      top: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 15px;
      line-height: 19px;
      margin-bottom: 15px;
      color: #FF0000;
    }

    .form-row {
      display: flex;
      width: 96%;
      justify-content: space-between;
    }

    .titile {
       
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      margin-top: 20px;
      line-height: 29px;
      color: #343434;
    }

    .text-image {
      margin-top: 20px;
      align-content: center;
      align-items: center;
       
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      display: flex;
      line-height: 19px;
      margin-left: 0px;
      color: #565656;
      margin-right: 0px;
    }

    .quiz-title {
      margin-top: 30px;
      margin-bottom: 40px;
      color: #000000;
      margin-left: 14px;
       
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }

  .form-row-mb {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }

  .quiz-submit-button {
    min-width: 169px;
    border: none;
    float: right;
    padding: 11px 10px;
    height: 50px;
    text-transform: uppercase;
    background: #006CB5;
     
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 3px;
  }
}