.reward-and-achivement {
  color: black;
  font-weight: normal;

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
}

  .d-flex {
    visibility: visible !important;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
}

.reward-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
        position: absolute;
        right: 10px;
    }

    .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
}

  .rGaps>* {
    padding: 0 10px;
    margin-bottom: 20px;
    min-height: 300px;
  }

  .description,
  .item {
    p {
      font-size: 16px;
      font-weight: normal;
      color: black;
    }
  }

}

@media screen and (max-width: 576px) {
  .reward-and-achivement {
    padding: 0 10px;

    .item {
      margin: 0;
      border: none;
    }
  }
}
