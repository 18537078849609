.search-document  {
    .err-text,
    .required {
        color: #ff6358;
        padding-left: 3px;
        font-style: italic;
    }

    .err {
        border: 1px solid #ea4335;
        animation: myerr 1s infinite;
    }

    @keyframes myerr {
        0% {
            border-color: #ea4335;
        }
        25% {
            border-color: yellow;
        }
        50% {
            border-color: blue;
        }
        100% {
            border-color: #34a853;
        }
    }

    .custom-btn-addNew {
        margin-top: 10px !important;
    }

    .btn-addNew {
        text-align: right;
        display: inherit;
        margin-right: 15px;
        margin-bottom: 5px;
    }

    .btn-custom {
        width: 100%;
    }

    .btn-custom button {
        background: #cd330d;
        color: white;
        width: 100%;
    }

    .btn-search {
        /* text-align: right; */
        /* margin-right: 15px;
        margin-bottom: 5px; */
        /* float: right; */
        background: RGB(25, 79, 139);
        color: white;
        /* position: relative; */
        float: right;
        /* border-radius: -5px; */
        border: none;
        margin-right: 0px;
        margin-top: 40px;
        margin-bottom: 5px;
        text-align: center;
        padding: 2px 23px;
        font-size: medium;
        width: auto;
        white-space: nowrap;
        display: inline;
    }


    .button-div {
        position: relative;
        float: right;
        /* display: block; */
        display: contents;
        width: fit-content;
    }

    .lineSpace {
        line-height: 35px;

    }
    /* .search-document input::-webkit-input-placeholder {
        font-size: 10px !important;
    } */

    .default-lang {
        border-bottom: none;
        float: left;
        margin-right: 5px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 15px;
    }

    .selected-lang {
        border-bottom: 3px red solid;
    }
}
.submit-document .red-icon {
    color: #d0401d;
    margin-right: 15px;
    font-size: 20px;
    
}

.submit-document .custom-document {
    margin-bottom: 5px;
    font-size: 18px;
}

@media screen and (max-width: 770px){
    .search-document
    { 
        .k-window {
        width: 100%;
        height: 100%;
    }
}
}