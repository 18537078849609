.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .panel-login {
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 48px 64px;
    padding-bottom: 24px;
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-input {
      width: 100%;
      padding: 0 15px;
      border-radius: 5px;
      border: 1px solid #eeeeee;
      font-weight: 600;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      input {
        width: 100%;
        border: none;
        margin: 10px 0;
        padding: 5px 0;
      }
      input:focus {
        outline: none;
      }
      .btn-showpass {
        border: none;
        background-color: #fff;
        color: gray;
      }
    }
    .logo {
      width: 60%;
    }

    .sub-item {
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
    }

    .login-button {
      width: 100%;
      background-color: #00396b;
      border: none;
      color: white;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .remember,
    .contact {
      color: gray;
    }
    .remember > input {
      margin-right: 4px;
    }
    .forgot-pwd {
      font-weight: 500;
      color: #00396b;
      cursor: pointer;
    }
    .contact-link {
      color: #d6a022;
      font-weight: 500;
      cursor: pointer;
    }
    .action > button {
      padding: 0;
    }
    .action {
      color: gray;
      cursor: pointer;
    }
    .invalid {
      border: 1px solid red;
    }
    .focus {
      border-color: #00396b;
    }
    .error-text {
      color: red;
      text-align: left;
      font-size: 13px;
      min-height: 20px;
    }
    a {
      text-decoration: none;
    }

    .login-failed-error {
      min-height: 30px;
      background-color: #f9e8e8;
      color: #e02d2d;
      padding: 15px;
      width: 100%;
      border-radius: 5px;
    }
    .input {
      position: relative;
      width: 100%;
      &__label {
        position: absolute;
        left: 0;
        top: 0;
        color: #c5c5c5;
        padding: calc(0.5rem * 0.75) 0.5rem;
        margin: calc(0.5rem * 0.75 + 3px) 0;
        white-space: nowrap;
        background-color: white;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        line-height: auto;
        text-align: left;
      }
      
      &__field {
        box-sizing: border-box;
        width: 100%;
        padding: calc(.5rem * 1.5) .5rem;
        border-radius: 5px;
        background-color: white;
        
        &:focus,
        &:not(:placeholder-shown) {
          & + .input__label {
            transform: translate(-.5rem, -80%) scale(.8);
            color: #00396b;
            padding: 0 .5rem;
          }
        }
      }

    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
}
