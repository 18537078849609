.organization-structure {
  width: 100%;
  height: 100%;
   
  font-style: normal;

  .director,
  .sub-director {
    display: block;
    height: 5rem;
    margin: 15px auto;

    span {
      height: 100%;
      width: 100%;
      background-color: #E02D2D;
    }
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
}

  .d-flex {
    visibility: visible !important;
  }

  .title-center {
    text-align: center !important;
  }

  .all-unit {
    width: 100%;
    margin: 30px auto;

    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #000;
    }

    .unit {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      width: 102%;
      margin: 15px -10px;

      span {
        background-color: #00396B;
        margin: 15px 10px;
        height: 80px;
        padding: 0 30px;
      }
    }
  }

  .director,
  .sub-director,
  .unit {
    span {
      display: flex;
      color: white;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      justify-content: center;
      text-align: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .employe-detail {
    padding: 10px;
  }

  .info-list {
    width: 100%;
    height: 150px;
    margin: 25px 0;

    .avatar {
      height: 100%;
      padding: 0 12px 0 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .personal-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #DEDEDE;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

      .list {
        display: flex;
        // flex-direction: column;
        // justify-content: center;
        height: 30%;
        padding-left: 10px;
        font-size: 16px;
        line-height: 19px;

        .row {
          display: flex;
          align-items: center;
        }

        i {
          color: #D6A022;
          font-size: 18px;
        }

        span {
          color: #666666;
          font-weight: 400;
        }

        .content {
          font-weight: 500;
          color: #292929;
        }
      }
    }
  }

  .employee-list {
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 19px;
    padding: 15px;
    margin-bottom: 20px;

    table,
    td,
    th {
      border: 1px solid #DFDFDF;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    tr {
      color: #000000;
    }

    th,
    td {
      padding: 10px;
    }

    th {
      font-weight: 600;
    }

    .index {
      text-align: center;
    }

    td {
      font-weight: 400;
    }
  }

  .paginator {
    float: right;

    :first-child a {
      span {
        display: none;
      }
    }

    .active {
      height: fit-content !important;
    }

    .page-item {
      margin: 0 5px;
      font-size: x-small;

      :focus {
        box-shadow: none;
      }
    }

    .hide {
      display: none;
    }

    .page-link {
      color: #000;
      border: 1px solid #E7E7E7;
    }

    .active {
      .page-link {
        background-color: #D6A022;
        color: #fff;
      }
    }
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black;
    font-size: 20px;
  }
  .k-dialog{
    height: auto !important;
  }
  .info.x-dialog{
    width: 700px ;
    height: 90%;
  }
  .dialog-employee{
    width: 90% !important;
  }
  .list .title{
    white-space: nowrap;
    width: 100px;
  }
  .title-info-req {
    text-align: left;
    color: #00396B;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .title-info-req.employee {
    color: #000000;
  }
}

@media screen and(max-width: 1024px) {
  .organization-structure {
    .employee-list {
      font-size: 13px !important;
      line-height: 16px !important;

      th,
      td {
        padding: 8px;
      }
    }
  }
}

@media screen and(max-width: 576px) {
  .organization-structure {
    .all-unit {
      width: 95% !important;

      .unit {
        width: 70% !important;
      }
    }

    .info-list {
      height: 5rem;

      .avatar,
      .list {
        font-size: 9px !important;
        line-height: 12px !important;
      }

      .list {
        padding-left: 5px;

        i {
          font-size: 12px !important;
        }

        .title,
        .content {
          padding: 0.1rem;
        }
      }
    }

    .employee-list {
      font-size: 8px !important;
      line-height: 11px !important;

      th,
      td {
        padding: 6px;
      }
    }
  }
}
