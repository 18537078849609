header-template4 {
  padding: 0;
  margin: 0;
  position: relative;

  nav ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
  }

  #BannerBackGround {
    background-size: cover;
  }
  .row-height{
    height: 40px;
  }

  .nav-bar {
    height: 42px;
  }

  .nav-menu-box {
    background-color: #0467BC;
    width: 100%;
    //height: 10% !important;
    display: inline-block;
    position: relative;
    z-index: 999;
    margin: auto;
    box-shadow: 0px 5px 0px 0px #DC7E10;

    .nav-content {
      width: 100%;
      height: 100%;
      margin: auto;
      position: relative;
      display: inline-flex;
    }

    .home-icon {
      margin-top: 5px;
    }

    .home-icon,
    .menu-icon {
      color: white;
      cursor: pointer;
    }
  }

  .menu-icon-box {
    display: none;
  }

  .nav-menu {
    width: 100%;
    height: auto;
    font-size: 95%;
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    margin: 0 auto;

    .dropdown {
      // margin: 0 auto;
      height: fit-content;
      text-align: center;
      display: inline-block;
      // width: 100%;

      .material-icons {
        color: white;
        padding-top: 5px;
        font-size: 18px;
      }

      .dropdown-home {
        margin: auto;
      }

      .play-arrow {
        color: #656565;
        font-size: 12px;
        vertical-align: middle;
        position: absolute;
        right: 0;
        padding-top: 6px;
      }

      .item-link {
        color: white;
        text-decoration: none;
        display: inline-block;
        flex-direction: column;
        font-weight: 600;
        font-size: 16px;
        // line-height: 19px;
        height: 100%;
        width: fit-content;
        padding: 8px 10px;
        cursor: pointer;
      }

      .item-link:hover,
      .item-link:active,
      .focus {
        background-color: white !important;
        color: #00396B;
        height: 100%;
        border-radius: 0;
      }

      .dropdown-menu {
        background-color: inherit;
        position: relative;
        border: none;
        min-width: 0;
      }

      .dropdown-submenu {
        display: none;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 35px;
        left: 0;

        a {
          display: block;
        }
      }

      .dropdown-item:hover {
        background-color: #dddddd;
      }

      // .dropdown-submenu:hover {
      //   background-color: #dddddd;
      // }
      .dropdown-item {
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        border-top: 1px solid #DEDEDE;
        padding: 0.6rem 1rem;
      }

      .dropdown:hover {
        background-color: #133d6c;
      }
    }
  }

  .disable-click {
    pointer-events: none;
    cursor: default;
  }

  .nav-top {
    border-bottom: 1px solid #D6D6D6;
    max-width: 1140px;
    margin: 0 auto;

    .contact {
      // width: var(--outlet-content-width);
      margin: auto;
      background-color: white;
      position: relative;
      padding-top: 1px;

      .search {
        margin: auto 0;
        height: 40px;     

        .form-control {
          border: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #373737;
          margin-top: 5px;
        }
      }

      .language {
        font-size: 13px;
        border-left: 1px solid #ECECEC;
        right: 15px;
        color: white;
        margin: auto 0;
        text-align: center;
        cursor: pointer;
        height: 40px;

        .mat-form-field {
          max-width: 50px;
          margin-top: -5px;
        }

        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }

  .banner {
    // width: var(--outlet-content-width);

    // margin: auto;
    position: relative;
    margin: 20px auto;
    max-width: 1140px;
    margin: 0 auto;

    .row {
      height: 100%;
    }

    .img1 {
      max-width: 117px;
      height: 105px;
      z-index: 5;
      object-fit: contain;
      cursor: pointer;
      margin: auto;
    }

    .name {
      // width: 50%;
      // z-index: 6;
      // left: 150px;
      // top: 30px;
      margin: auto 0;
      display: flex;
      flex-direction: row;

      a {
        text-decoration: none;
      }
    }

    .name-main,
    .name-detail {
      display: inline !important;

      .edit-logo {
        position: absolute;
      }
    }

    .name-main  {
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 37px;
      color: #028CD4;
      //border: 0.3px solid #FFFFFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.37);
      display: block;
    }

    .name-detail a {
      color: #D7A123;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;

    }

    .search:focus {
      border: 1px solid #f3efef !important;
    }

    .search-icon {
      color: #9a9a9a;
      position: absolute;
      right: 15px;
      top: 52px;
      z-index: 999;
      cursor: pointer;
    }

    .search-text:focus {
      border: 1px solid white !important;
    }

    .banner-link {
      cursor: pointer;
      z-index: 997;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .banner-background {
      cursor: default;
      z-index: 1;
      position: absolute;
      background-color: white;
      width: 100%;
      height: 100%;
    }

    .color-text-selected {
      color: #363842;
      cursor: default;
    }

    .edit-logo {
      visibility: hidden;
    }

    .logo-container:hover,
    .name:hover {
      .edit-logo {
        visibility: visible;
      }
    }

    .logo-container {
      display: flex;
      flex-direction: row;

      .btn-custom {
        width: fit-content;
      }

      .k-upload {
        display: none;
      }
    }

    .material-icons {
      font-size: 18px;
    }

    .menu {
      .menus {
        display: inline !important;
        list-style-type: none;
        margin: 0;
        padding: 0;

        .menu-item {
          display: inline;
          width: 33%;
          padding-right: 10px;
        }

        button {
          display: inline;
        }
      }
    }

    .login {
      margin: 45px 0px;
    }

    .login-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      height: 44px;
      background-color: #D6A022;
      border-radius: 4px;
      color: white;
      width: 146px;
      border: none;
    }

    .btn-custom {
      background: initial;
      color: inherit;
    }

    .btn-custom button {
      background-color: inherit;
      width: fit-content;
      color: inherit;
      border: none;
    }

    .btn-custom .k-button {
      padding: 0 !important;
    }

    .k-popup .k-list {
      height: auto !important;
      width: fit-content;
      padding: 0;
      margin: 0;
    }

    .k-popup .k-item {
      width: 100%;
    }

    .btn-add {
      background-color: inherit;

      // width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      border-radius: 2px;

      // height: 2.5em;

    }

    .btn-add:hover {
      background-color: #f3efef;
    }

    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown-bg {
      padding-top: 20px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      right: 10px;
      background: #FFFFFF;
      border: 1px solid #EDEDED;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      min-width: 230px;
      z-index: 1000;

      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        border-top: 1px solid #DBDBDB;
        font-size: 14px;
        color: #4B4B4B;
        box-sizing: border-box;
        width: 100%;
        cursor: pointer;
      }

      a:hover {
        background-color: #f1f1f1;
      }

      a:first-child {
        border-top: none;
      }

      .avatar {
        margin: 0;
        margin-right: 10px;
        padding: 0;
        max-width: 60px;
        max-height: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .user-info {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .role {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 12px;
        }

        .btn-edit-info {
          background-color: #946711;
          color: white;
          border: none;
          border-radius: 3px;
          font-size: 14px;
          padding: 5px 10px;
        }
      }
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    .dropdown:hover .dropbtn {
      background-color: #3e8e41;
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    margin: auto 0;
    padding: 0;
    justify-content: flex-end;

    .change-btn {
      margin: 0 10px;
      cursor: pointer;
    }

    .avatar {
      margin: 0 10px;
      cursor: pointer;

      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .change-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      min-width: 3rem;
      height: 3rem;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .open-menu {
    display: block !important;
  }

  .close-menu {
    display: none !important;
  }

  .login-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    height: 44px;
    background-color: #D6A022;
    border-radius: 4px;
    color: white;
    width: 146px;
    border: none;
  }

  .edit {

    .required,
    .err-text {
      color: #ff6358;
      padding-left: 3px;
      font-style: italic;
    }
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black;
    font-size: 20px;
  }

  .title-info-req {
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .btn-save {
    color: white;
    background-color: #0b68a4;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    margin-top: 10px;
    margin-right: 16px;
  }

  kendo-dialog {
    .btn-save {
      color: white;
      background-color: #34a853;
      border: hidden;
      padding: 5px 20px;
      font-size: 14px !important;
    }
  }
  .cskh{
    display: flex;
    flex-direction: column;
    height: 60px !important;
    width: 199px;
    background: #FFFFFF;
    border: 1px solid #027DC3;
    border-radius: 8px;
  }
  .phone-box{
    padding: 0 !important;
    width: fit-content;
  }
  .phone-icon{
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-top: 17px;
    margin-left: 18px;
  }
  .phone-top{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 8px;
  }
  .phone-bottom{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #08659A;
    padding-top: 4px;
  }
}

@media screen and (max-width: 576px) {
  header-template4 {

    .desktop,
    .desktop-icon {
      display: none !important;
    }

    .mobile {
      display: block !important;
    }

    nav ul {
      width: auto;
    }

    .nav-menu-box {
      height: 1px !important;
    }

    .nav-top {
      border-bottom: 1px solid #D6D6D6;

      .contact {
        // width: var(--outlet-content-width);
        margin: auto;
        background-color: white;
        position: relative;
        padding-top: 1px;

        .search {
          margin: auto 0;

        }

        .language {
          .mat-form-field {
            width: 65%;
          }        
        }
      }
    }   

    .banner {
      width: 100% !important;
      min-height: auto;
      // overflow: hidden;

      .img1 {
        height: 50px;
        top: 6px;
        left: 50%;
        position: relative;
      }

      .logo-container {
        display: block;
      }

      .name {
        padding: 0;
        margin: 0 auto;

        hgroup {
          text-align: center;
        }
      }

      .user {
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding-right: 10px;
      }
    }

    .nav-menu {
      font-size: 95% !important;

      .dropdown,
      .item-link {
        display: block;
        text-align: left;
        width: 100% !important;
      }

      .dropdown-submenu {
        border-top: 1px solid #4569cf;
        width: 100%;
      }

      .dropdown {
        padding-left: 0;
        height: auto;
        width: 100%;
        z-index: 9989;
        height: auto !important;
        padding: 0;
        font-weight: bold;
      }

      .dropdown+.dropdown {
        border-top: 1px solid #4569cf;
      }

      .dropdown-submenu>.dropdown-menu {
        left: 0px;
        margin-top: 0;
      }

      .dropdown:hover {
        background-color: var(--main-color);
      }

      .dropdown .dropdown-menu {
        z-index: 9990;
        margin-top: 0px;
      }

      .dropdown-menu .dropdown-submenu {
        z-index: 9991;
        top: 10px !important;
      }

      .dropdown-menu {
        display: none;
        width: 100% !important;
        border-top: 1px solid #4569cf;
      }

      .button-expand {
        color: white;
        margin-top: 5px;
        position: absolute;
        right: 0;
        font-size: 17px;
        font-weight: normal;
        top: 8px;
        width: auto;
      }

      .dropdown-item:hover {
        background-color: var(--main-color);
      }

      .show {
        display: block !important;
      }

      .dropdown-menu,
      .dropdown-submenu {
        position: relative !important;
        background-color: var(--main-color);
        color: white;
        border: none;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }

    .pading-0 {
      padding: 10px 0 !important;
    }

    .pading-1 {
      padding: 10px 0 !important;
      padding-left: 15px !important;
    }

    .pading-2 {
      padding: 10px 0 !important;
      padding-left: 25px !important;
    }

    .pading-3 {
      padding: 10px 0 !important;
      padding-left: 35px !important;
    }

    .pading-4 {
      padding: 10px 0 !important;
      padding-left: 45px !important;
    }

    .dropdown-item {
      color: #000;
      font-size: 0.75rem !important;
      line-height: 1rem !important;
      padding: 0.5rem 0.7rem !important;
      font-weight: bold;
    }

    .home-icon {
      z-index: 9980 !important;
      font-size: 25px;
      margin-top: 0 !important;
    }

    .menu-icon {
      font-size: 30px;
      margin-left: 4px;
    }


    .menu-icon-box {
      display: inline-block !important;
      position: absolute;
      margin-top: 1px !important;
      width: 40px;
      height: 30px;
      z-index: 999 !important;
      border-radius: 5px;
      color: #8a8a8a;
      top: 7px;
      left: 15px;
    }

    .close-button-box {
      text-align: end;

      // padding-right: 15px;
      padding-top: 10px;
    }

    .close-button {
      color: #ff8659;

      // font-size: 30px;
      font-weight: bold;
    }

    .navbar-display {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      top: 0px;
      margin-left: 0%;
      width: 65%;
      background: var(--main-color);
      height: 4000px !important;
      position: fixed;
      z-index: 998 !important;
      padding: 0px 15px;
      box-shadow: -5px 5px 5px #878794;
    }

    .search-cover-shadow,
    .navbar-cover-shadow {
      width: 100%;
      top: 0;
      background: var(--main-color);
      height: 4000px;
      position: fixed;
      z-index: 997 !important;
      background-color: #969494;
      opacity: 0.4;
    }

    .search-cover-shadow {
      margin-top: 145px;
      background-color: #363636;
      opacity: 0.5;
    }

    .navbar-hidden {
      display: none;
    }

    #navbardropdownMenuLink {
      text-align: left !important;
    }

    .login-btn {
      position: relative;
      margin-left: 10px !important;
      margin-right: 10px !important;
      width: calc(100vw - 20px) !important;
    }

    .left-block {
      width: 100%;
      position: relative !important;
    }
  }
  
}

@media screen and (min-width: 575px) {
  header-template4 {
    .desktop {
      display: block !important;
    }

    .mobile {
      display: none !important;
    }

    // .name {
    //   position: absolute;
    // }
    .top-right-menu {
      display: inline !important;
      position: absolute;
      font-size: 13px;
      top: 15px;
      right: 15px;
    }

    .dropdown-menu:hover {
      .dropdown-submenu {
        display: block;
      }
    }

    // .login-btn {
    //   position: absolute;
    //   top: 38px;
    //   right: 10px;
    // }

  }
}

// @media screen and (max-width: 1200px) {
//   header-template4 {
//     .nav-top {
//       .contact {
//         .mat-form-field {
//           width: 60%;
//         }
//       }
//     }

//     .nav-menu {
//       .item-link {
//         font-size: 85% !important;
//         line-height: 85% !important;

//         .dropdown-submenu {
//           top: 30px;
//         }
//       }
//     }

//     .banner {
//       margin: 10px auto;

//       .name-main a,
//       .name-detail a {
//         font-size: 98%;
//         line-height: 98%;
//       }
//     }

//     .user {

//       .change-btn {
//         min-width: 3rem !important;
//         height: 3rem !important;

//         img {
//           width: 1.5rem !important;
//           height: 1.5rem !important;
//         }
//       }

//       .avatar {
//         img {
//           width: 3rem !important;
//           height: 3rem !important;
//         }
//       }
//     }

//     .edit-logo {
//       visibility: visible !important;
//     }
//   }
// }

// @media screen and (max-width: 768px) {
//   header-template4 {
//     .nav-top {
//       .contact {
//         .mat-form-field {
//           width: 50%;
//         }
//       }
//     }

//     .user {
//       .change-btn {
//         min-width: 2rem !important;
//         height: 2rem !important;

//         img {
//           width: 1rem !important;header-template4
//           height: 1rem !important;
//         }
//       }

//       .avatar {
//         img {
//           width: 2rem !important;
//           height: 2rem !important;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 992px) {
//   header-template4 {
//     .nav-top {
//       .contact {
//         .mat-form-field {
//           width: 80%;
//         }
//       }
//     }

//     .nav-menu {
//       .item-link {
//         font-size: 70% !important;
//         line-height: 70% !important;

//         .dropdown-submenu {
//           top: 30px;
//         }
//       }
//     }

//     .user {

//       .change-btn {
//         min-width: 2.5rem !important;
//         height: 2.5rem !important;

//         img {
//           width: 1.5rem !important;
//           height: 1.5rem !important;
//         }
//       }

//       .avatar {
//         img {
//           width: 2.5rem !important;
//           height: 2.5rem !important;
//         }
//       }
//     }
//   }
// }

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }

  25% {
    border-color: yellow;
  }

  50% {
    border-color: blue;
  }

  100% {
    border-color: #34a853;
  }
}