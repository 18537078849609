.receive-questions {
    .page-name {
        color: #194F8B;
        font-size: 16px;
        margin-bottom: 30px;
        margin-top: 20px;
        font-weight: 600;
    }

    .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active {
        border-bottom-color: #c60000;
    }
    
    .k-tabstrip>.k-content {
        border: none;
        overflow-x: hidden;
    }
    
    .k-tabstrip-items .k-item {
        color: #000000;
        border-color: #d7d7d7;
        font-weight: bold;
        border-width: 0px 0px 4px 0px;
    }
    
    .k-tabstrip-items .k-item.k-state-active,
    .k-tabstrip-items .k-item.k-state-selected {    
        border-color: #c60000;
        color: #c60000;
        font-weight: bold;
        border-width: 0px 0px 4px 0px;
    }
    
    
    .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item{
        border-color: #EEEEEE ;
        font-weight: bold ;
        border-width: 0px 0px 4px 0px ;  
    }

    .k-tabstrip-content, .k-tabstrip > .k-content {
        padding: 6px 0px;        
    }

    .k-tabstrip-items {        
        border-bottom: solid 1px #d7d7d7;     
    }

    @media screen and (max-width: 770px) {
        overflow-x: hidden;
        .k-tabstrip-top>.k-tabstrip-items {
            overflow-y: hidden;
            width: 100%;
        }   
        .k-tabstrip-content, .k-tabstrip > .k-content {
            padding: 6px 12px;        
        }
        .page-name {
            margin-left: 10px;
        }
    }
}