.main-page {
   
  //max-width: 1140px;
  margin: 0 auto;

  // .background-gray{
  //   background-color: #F6F6F6 !important;
  // }
  height: 100%;
  .nav-title {
    width: 100%;
    background-color: #F5F5F5;
    height: 70px !important;
    margin: top;
    /* position: relative; */
    /* margin-top: 15px; */
    z-index: 999;
  }
  .uppercase-black {
     
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #EE4623;
    padding-left: 74px;
    margin-bottom: 10px;
    /* vertical-align: middle; */
    margin-top: 10px;
    /* height: 50px; */
    line-height: 70px;
    text-transform: uppercase;
  }
  .row1 {

    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}
  .image-slide-home {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .image-slider-title{
    padding-top: 20px;
  }
  .news-template-1 {
    padding: 7px 0px ;
  }
  .news-template-1-without-time{
    padding: 7px 0px ;
  }
  .news-template-2 {
    padding: 7px 0px ;
  }
  .news-template-3 {
    padding: 7px 0px ;
    margin-left: 20px;
  }
  .news-template-4 {
    padding: 20px 0px 0px 20px ;
    // margin-left: 20px;
  }
  .news-template-5 {
    padding: 7px 0px ;
  }
  .news-template-6 {
    padding: 7px 0px ;
    margin-left: 20px;
  }
  .news-template-a {
    padding: 7px 0px ;
  }
  .news-template-b {
    padding: 20px 0px 0px 0px ;
    margin-left: 20px;
  }
  .news-template-c {
    padding: 20px 0px 0px 0px ;
    margin-left: 20px;
  }
  .news-template-d {
    padding: 7px 0px ;
  }
  .news-template-11 {
    padding: 7px 0px ;
  }
  .news-template-21{
    padding-top: 20px;
  }
  .news-template-34 {
    padding: 20px 0px ;
  }
  .router-box{
    width: 100%;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #eb2e35;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  .pointer {
    cursor: pointer;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .category-detail {
    margin-top: 15px;
    padding-right: 20px !important;
  }

  .banner-right-side {
    width: 100%;
    margin: auto;
    overflow: hidden;
  }

  .banner-right-side img {
    width: 100%;
    height: auto;
    font-size: 10px;
    object-fit: contain;
  }

  .banner-right-side figure {
    margin: 0;
    text-align: left;
  }

  .top-news-box {
    padding-right: 12px !important;
  }

  .container-box {
    padding-right: 0px !important;
    padding-left: 12px !important;
  }

  .news-other-box {
    border-top: 1px solid #e5e5e5;
    border-top-style: solid;
    padding: 10px 0;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .title-child-other {
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      padding-left: 10px;
    }
  }

  .category-news-other-box {
    border-top: 1px solid #e5e5e5;
    border-top-style: solid;
    padding: 15px 0;

    // margin-left: 5px !important;
    // margin-right: 5px !important;
    height: 100px;
    overflow-y: hidden;
  }

  .banner-right-side-box {
    padding: 0 12px 0 24px !important;
  }

  .banner-right-side-box .row {
    --bs-gutter-x: 0;
  }

  .banner-right-side-box-fit {
    box-shadow: 0px 0px 8px -3px #323232;
  }

  .top-news-main {
    .banner-main {
      width: 100%;
      height: 300px;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        position: absolute;
        font-size: 10px;
      }

      .title-main {
        font-size: 20px;
        z-index: 800;
        color: white;
        text-align: left;
        padding: 0 10px 0 25px;
        cursor: pointer;
        font-weight: 500;
        bottom: 30px;
        position: absolute;
        line-height: 24px;
        text-shadow: 0px 2px 11px #000000;
      }

      .title-main:hover {
        color: rgb(0, 195, 255);
        text-shadow: 0px 2px 11px rgb(20, 0, 204);
      }
    }
  }

  .disable-click {
    pointer-events: none;
    cursor: default;
  }

  .top-news-child {
    cursor: pointer;
    margin-top: 24px;

    .banner-child {
      width: 100%;
      height: 143px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        width: 100%;
        height: 143px;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .category-news {
    cursor: pointer;

    .banner-child {
      width: 100%;
      height: 141px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        width: 100%;
        height: 141px;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .category {
    .row {
      --bs-gutter-x: 0;
    }
  }

  .category-news-container {
    cursor: pointer;
    height: 238px;

    // padding-bottom: 10px;
    .banner-child {
      width: 100%;
      height: 139px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        width: 100%;
        height: 139px;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .container-news {
    cursor: pointer;

    .banner-child {
      width: 100%;
      height: 141px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        width: 100%;
        height: 141px;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .category-news-other {
    cursor: pointer;

    .banner-child-other {
      height: 70px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        height: 70px;
        width: 100%;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .container-news-other {
    cursor: pointer;

    .banner-child-other {
      // background-size: 100% 100%;
      height: 70px;
      margin: auto;
      overflow: hidden;
      position: relative;

      .image {
        height: 70px;
        width: 100%;
        object-fit: cover;
        font-size: 10px;
      }
    }
  }

  .container-fluid {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-fluid {
    width: 1140px;
  }
  .layout-box{
    margin-left: auto;
    margin-right: auto;
    .news-template-31{
      .content-width-media{
        width: calc(84vw - 28px);
      }
    }
  }
  .layout-box-left{
    // margin-left: auto;
    //margin-right: 10px;
  }
  .layout-box-right{
    // margin-right: auto;
    //margin-left: 10px;
  }
  .layout-box-center{
    // padding: 0 10px;
  }
  .bottom-news {
    margin-top: 10px;
  }

  .title-child-other {
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    margin-top: -3px;
  }

  .title-child-other:hover {
    color: #06c;

    .time {
      color: #06c;
    }
  }

  .title-child {
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }

  .title-child:hover {
    color: #06c;

    .time {
      color: #06c;
    }
  }

  .category-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--main-color);
    border-bottom: 3px solid var(--main-color);
    text-transform: uppercase;
  }

  .time {
    color: #c5b3b3;
    font-size: 11px;
    font-weight: normal;
  }

  .body-list {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    justify-content: center;
    width: calc(100% - 250px);
    min-width: 80%;
    max-width: 1550px;
    margin-top: 10px;
  }
  .main-row{
    align-items: center;
    //width: 1140px;
    --bs-gutter-x: 0;
    height: 100%;
    align-content: space-between;
  }
  .body-box {
    // display: flex;
    // justify-content: center;
  }
  .body-box-gray{
    // display: flex;
    // justify-content: center;
    // background-color: #F5F5F5;
  }
  .body-item{
    // width: 1140px;
  }
  .body-item-gray{
    width: 1140px;
    background-color: #F5F5F5;
  }
  .layout-list {
    //height: 100%;
  }
  .body-box {
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .main-page {
    padding-left: 15px !important;
    padding-right: 15px !important;

    .banner-right-side-box {
      padding-top: 20px !important;
      padding-bottom: 10px !important;
    }
    .news-template-3 {
      padding: 20px 0px 10px 0px;
    }
    .news-template-4 {
      padding: 10px 0px 10px 0px;
    }
    .news-template-b {
      padding: 10px 0px 10px 0px;
    }
    .news-template-c {
      padding: 10px 0px 10px 0px;
    }

    .body-list {
      width: calc(100%);
    }

    .top-news-main {
      padding: 0 0 10px 0;

      .banner-main {
        background-size: 100% 100%;
        height: 200px;

        .image {
          height: 200px;
        }
      }
    }

    .mobile {
      display: block !important;
    }

    .desktop {
      display: none !important;
    }

    .category-detail {
      margin-top: 0;
    }

    .category {
      margin-top: 5px;
    }

    .category section:nth-child(2) {
      border-top: none;
    }

    .category-news-other-box {
      border-top: 1px solid #e5e5e5;
      border-top-style: solid;
    }

    .top-news-child {
      border-top: 1px solid #d8d8d8;
      padding: 10px 0;
      margin-top: 0;

      .banner-child {
        display: none;
      }
    }

    .time {
      display: none;
    }

    .banner-right-side-box {
      width: 100%;
    }

    .banner-right-side figure {
      text-align: center;
    }

    .top-news-box {
      // padding-bottom: 20px !important;
      border: none;
    }

    .news-other-box {
      border: none;
    }

    .category-news-container {
      padding: 0;
      margin-top: 5px 0;
      height: 275px;
      overflow: hidden;

      .banner-child {
        height: 200px;

        .image {
          height: 200px;
        }
      }

      .title-child {
        margin-top: 13px;
      }
    }

    .container-news {
      display: none;
    }

    .container-news {
      display: block;

      // padding-left: 6px;
      border-top: 11px solid #d8d8d8;
      padding: 20px 0 10px 0;

      .banner-child {
        width: 100%;
        height: 67px;
        margin: auto;
        overflow: hidden;
        position: relative;

        .image {
          width: 100%;
          height: 67px;
          object-fit: cover;
          font-size: 10px;
        }
      }

      .title-child {
        font-size: 15px !important;
        padding: 0;
        margin-top: -5px;
        margin-left: 20px;
      }
    }

    .container-box {
      border: none;
    }

    .category-news-other {
      .banner-child-other {
        height: 67px;

        .image {
          height: 67px;
        }
      }
    }

    .container-news-other {
      .banner-child-other {
        height: 68px;

        .image {
          height: 68px;
        }
      }
    }

    .title-child-other {
      font-size: 15px !important;
      padding: 0 5px 0 5px;
      margin-top: -5px;
      margin-left: 10px;
    }

    .title-main {
      font-size: 17px !important;
      margin-bottom: 0;
      margin-top: 20px;
      position: relative;
      font-weight: 500;
    }

    .title-child {
      font-size: 17px !important;
      padding: 0;
      margin-top: 3px;
    }

    .time {
      font-size: 12px !important;
      padding-left: 12px;
    }

    .category-title {
      font-size: 19px !important;
      font-weight: 500;
      border-bottom: none;
      border-left: 4px solid var(--main-color);
      margin-left: 0;
      padding-left: 15px;
      margin-top: 15px;
    }

    .row {
      margin-right: 0px;
      margin-left: 0px;
    }

    .container-fluid {
      margin-top: 10px;
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .layout-box {
      position: relative;
      // display: inline-block;
      // border: solid 1px #cccccc;
      align-items: flex-start;
      // min-height: 100px;
      // .hidden-dlt {
      //   top: 0 !important;
      //   right: 0 !important;
      //   margin-top: 2px;
      //   margin-right: 2px;
      //   position: relative;
      // }

    }

    .layout-list {
      width: 100%;
      // padding-top: 30px;
      // border: solid 1px #cccccc;
      // min-height: 100px;
      // background: rgb(43, 43, 43);
      // border-radius: 4px;
      overflow: hidden;
      display: block;
      z-index: 100;
    }

    .body-box {
      // background-color: #cccccc;
      // border: solid 1px #cccccc;
      // display: inline-block;
      // padding-right: 5px;
      position: relative;
      width: 1140px;
    }

    .body-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

@media screen and (max-width: 800px) and (min-width: 577px) {
  .main-page {
    .banner-right-side-box {
      padding: 12px !important;
    }

    .top-news-box {
      padding-left: 12px !important;
    }

    .category-title {
      font-size: 15px;
    }

    .banner-right-side img {
      max-height: 100px;
    }

    .banner-right-side-box-fit {
      box-shadow: none;
    }

    .banner-right-side img {
      object-fit: cover;
    }

    .category {
      margin-left: 0;
    }

    .container-box {
      padding-right: 12px !important;
    }

    .body-list {
      width: calc(100%);
    }
  }
}

@media screen and (max-width: 1025px) and (min-width: 801px) {
  .main-page {
    .top-news-box {

      .body-list {
        width: calc(100% - 50px);
      }

      padding-left: 12px !important;
    }

    .banner-right-side-box {
      padding: 12px 10px 12px 12px !important;
    }

    .category-news-container {
      height: 280px;

      .banner-child {
        height: 200px;

        .image {
          height: 200px;
        }
      }
    }

    .banner-right-side img {
      max-height: 100px;
    }

    .top-news-main {
      .banner-main {
        height: 394px;

        .image {
          height: 394px;
        }
      }
    }

    .top-news-child {
      .banner-child {
        height: 190px;

        .image {
          height: 190px;
        }
      }
    }

    .category-news-container {
      .banner-child {
        height: 190px;

        .image {
          height: 190px;
        }
      }
    }

    .container-news {
      .banner-child {
        height: 192px;

        .image {
          height: 192px;
        }
      }
    }

    .category-news-other {
      .banner-child-other {
        height: 92px;

        .image {
          height: 92px;
        }
      }
    }

    .container-news-other {
      .banner-child-other {
        height: 93px;

        .image {
          height: 93px;
        }
      }
    }

    .banner-right-side-box-fit {
      box-shadow: none;
    }

    .banner-right-side img {
      object-fit: cover;
    }

    .category {
      margin-left: 0;
    }

    .container-box {
      padding-right: 12px !important;
    }
  }

}

app-main-page {
  position: relative;
  top: -6px;
}
