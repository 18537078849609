.search-result {
    .container-fluid {
        padding-left: var(--bs-gutter-x, -0.25rem);
        }

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        border-color: rgb(202, 202, 202);
        border-width: 1px;
    }
    table {
        // --bs-table-bg: transparent;
        // --bs-table-accent-bg: transparent;
        // --bs-table-striped-color: #212529;
        // --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        // --bs-table-active-color: #212529;
        // --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        // --bs-table-hover-color: #212529;
        // --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        vertical-align: top;
        border-color: rgb(202, 202, 202);
    }

    .subject {
        margin-top: 14px;
       
    }
    .group-header {
        padding: 0px 0px 0px 10px;
        border-left: 5px solid var(--main-color);
        ;
        font-weight: bolder;
        font-size: 22px;
        //background-color: #f5f5f5;
        text-transform: capitalize;
        line-height: 1.8;
        margin-top: 5px;
        margin-bottom: 10px;
    }
    hr {
        display: none;
    }
    .title-post {
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: bolder;
        font-size: 24px;
        //   font-family: 'Times New Roman', Times, serif;
        //padding-right: 12px;
    }
    .mb {
        display: none;
    }
    .wb {
        a {
            text-decoration: none;
            color: #212529;
        }
    }
    .k-pager-numbers .k-link {
      color: black;
      border-width: 1px;
      margin: 4px;
  }
  .k-pager-wrap {
      border: none;
      color: #424242;
      background-color: transparent;
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: bold;
  }

  .k-pager-numbers .k-link.k-state-selected {
      background-color: #c60000;
      color: white !important;
  }

  .k-pager-nav {
      border: solid 1px;
      margin: 4px;
  }
    .post-part {
        height: 180px;
        cursor: pointer;
        overflow: hidden;
        .img-view {
            overflow: hidden;
            position: relative;
            .image {
                // overflow: hidden;
                // font-size: 10px;
                // width: 100%;
                // height: 160px;
                //padding-right: 12px;
                width: 95%;
                height: 150px;
                display: inline-block;
                object-fit: cover;
            }
            .video-view .video {
                position: relative !important;
                width: 95%;
                height: 150px;
            }
            .video-view .img-err {
                position: relative;
                width: 95%;
                height: 150px;
            }
            .video-view .play-button {
                top: 47% !important;
                left: 47% !important;
            }
        }
        .content-view {
            // margin: 15px 15px;
            margin-left: inherit;
            font-size: 14px;
            font-weight: bolder;
            text-decoration: none;
            overflow: hidden;
            //  color: black;
            .post-date {
                font-size: 10px;
                color: gray;
                font-weight: normal !important;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .post-summary {
                font-size: 12px;
                color: black;
                font-weight: normal !important;
                height: 105px;
                overflow: hidden;
            }
            .post-title {
                font-size: 16px;
                font-weight: 700;
            }
            .title-first {
                padding-left: 15px;
                font-size: 22px;
            }
        }
        .content-view:hover {
          .post-title, .post-date, .post-summary{
            color: #06c !important;
          }
      }
    }
    @media screen and (max-width: 770px) {
        padding-right: 15px;
        padding-left: 15px;
        .box {
            // padding-left: 40px;
            padding-right: 15px;
        }
        .wb {
            display: none;
        }
        .mb {
            display: block;
            // margin-top: -9px;
            // margin-bottom: 9px;
            a {
                text-decoration: none;
                color: #212529;
            }
        }
        .mb-second {
            padding-top: unset;
            margin-bottom: -20px;
        }
        .mb-first{
            margin-bottom: 15px;
        }
        hr {
            border-top: 2px solid rgb(25, 0, 255);
            border-top-style: dotted;
            color: white;
            display: block;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        .post-part {
            height: auto;
            .img-view {
                overflow: hidden;
                position: relative;
                height: 98px;
                .image {
                    width: 100%;
                    height: 98px;
                    display: inline-block;
                    object-fit: cover;
                }
                .video-view .video {
                    position: relative !important;
                    width: 100%;
                    height: 98px;
                }
                .video-view .mask-video{
                  width: 100%;
                  height: 98px;
                }
                .video-view .img-err {
                    position: relative;
                    width: 100%;
                    height: 98px;
                }
                .video-view .play-button {
                    top: 44% !important;
                }
            }
            .img-view-first{
                overflow: hidden;
                object-fit: cover;
                height: 190px;
                position: relative;
                .image {
                    overflow: hidden;
                    font-size: 10px;
                    width: 100%;
                    height: unset;
                    // //padding-right: 12px;
                }
                .video-view .video {
                    position: relative !important;
                    width: 100%;
                    height: 190px;
                }
                .video-view .mask-video{
                  width: 100%;
                  height: 190px;
                }
                .video-view .img-err {
                    position: relative;
                    width: 100%;
                    height: 190px;
                }
                .video-view .play-button {
                    top: 50% !important;
                }
            }
            .content-view {
                .post-summary {
                    height: 55px;
                    overflow: hidden;
                    display: none;
                }
                .post-date {
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 1025px) and (min-width: 770px){
.search-result{
  .box{
    padding-left: 24px;
  }
}
}
