// :host ::ng-deep .text-tiny {
//     font-size: 0.7em;
// }
//  :host ::ng-deep .text-small {
//     font-size: 0.85em;
// }
// :host ::ng-deep .text-medium {
//     font-size: 1em;
// }
//  :host ::ng-deep .text-big {
//     font-size: 1.4em;
// }
// :host ::ng-deep .text-huge {
//     font-size: 2em;
// }
body {
  // font-family: Roboto !important;
  height: 100vh;

}
.title-info-req {
  text-align: center;
  font-size: 24px;
  color: black;
}

.control label {
  color: #656565;
}

.control {
  margin-bottom: 7px;
}

.control>* {
  width: 100%;
}

.control label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 14px;
}

.control .text {
  padding-left: 7px;
  color: #0067b2;
  font-size: 17px;
}

.btn-send {
  color: white;
  background-color: #d5bd46;
  border: hidden !important;
  border-radius: 0px;
  float: left;
  padding: 5px 16px;
  margin-top: 0px !important;
  font-size: 14px !important
}

.control label {
  color: #656565;
}

.control-search input {
  float: left;
}

.control-search .btn-search {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
  background-color: #985d1a !important;
}

.control-search .btn-search-advance {
  width: 35px;
  height: 34px;
  float: right;
  right: 35px;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
}

.control-search .btn-check {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
}

.control-search .btn-remove-replace-btn-search {
  right: 0 !important;
}

.k-panelbar>.k-item>.k-link {
  color: white !important;
  background-color: #194f8b !important; //#1667b0 !important;
  height: 30px;
}

.k-panelbar>.k-item>.k-link.k-state-selected {
  color: #194f8b;
  background-color: #194f8b;
}

.k-panelbar .k-content {
  padding: 7px;
  background: #F1F1F1;
}

.k-panelbar .k-content .func {
  margin-top: 15px;
}

.k-button-icontext span {
  padding-left: 2px;
}

.k-dialog {
  width: 80%;
  height: 80%;

  .k-content {
    width: 100%;
    height: 100%;
  }
}

.in-meeting .k-tabstrip-items .k-item {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
  color: #1667b0;
  margin-right: 5px;
  border: 2px solid;
}

.k-list .k-item:focus,
.k-list .k-item.k-state-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-state-focused {
  box-shadow: inset 0 0 0 0px rgb(0 0 0 / 13%) !important;
}


.k-tabstrip-top>.k-tabstrip-items .k-item {
  border-color: #EEEEEE;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}

.k-panelbar>.k-item>.k-link.k-state-selected:hover,
.k-panelbar>.k-item>.k-link.k-state-selected.k-state-hover {
  background-color: #e7e7e7;
  color: #194f8b //#000;
}

// .k-panelbar .k-icon {
//     background-image: none;
//     color: #194f8b;

//     // font-family: 'Glyphicons Halflings';
//     // font-size: 1em;
//     overflow: visible;
// }

.btn-dialog {
  border-radius: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  float: right;
}

.btn-regist-cv {
  color: white;
  background-color: #194f8b;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important
}

.btn-close-success {
  color: rgb(0, 0, 0);
  background-color: #d4d4d4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  font-weight: bold;
}

.btn-pay-success {
  color: white;
  background-color: #e85d3b;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  font-weight: bold;
}

.btn-scanning-img {
  color: white;
  background-color: #616161;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  cursor: no-drop !important;
}

.btn-dialog-wrapper {
  border-width: 0 !important;
  width: 100%;
  margin-top: 10px;
}

.k-window {
  overflow: auto;
}

.x-dialog {
  width: 100%;
  height: 100%;
}

.window-center {
  left: 0 !important;
  right: 0;
  top: 0 !important;
  bottom: 0;
  margin: auto;
}

.x-dialog-register-document {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-3 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-2 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-1 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-0 {
  max-width: 750px !important;
  max-height: 82% !important;
}

.x-dialog-success {
  max-width: 700px !important;
  max-height: 180px !important;
  top: -40% !important;
}

.x-dialog-payment {
  max-width: 700px !important;
  max-height: 500px !important;
  margin-top: 50px !important;
}

.x-dialog-payment-0 {
  max-width: 700px !important;
  max-height: 370px !important;
  margin-top: 50px !important;
}

@media (max-width: 768px) {
  .k-dialog {
    width: 90% !important;
  }

  .x-dialog-register-document,
  .x-dialog-register-document-3,
  .x-dialog-register-document-2,
  .x-dialog-register-document-1,
  .x-dialog-register-document-0 {
    max-height: 100% !important;
    max-width: 100% !important;
  }

  .x-dialog-success {
    max-width: 100% !important;
    max-height: 280px !important;
    top: 0% !important;
  }

  .btn-dialog-wrapper {
    margin-top: 5px !important;
    padding-left: 0px !important;
  }

  .x-dialog-payment,
  .x-dialog-payment-0 {
    max-width: 100% !important;
    max-height: 600px !important;
    top: 0% !important;
  }

  .x-dialog {
    width: 95% !important;
    height: 95% !important;
  }

  .dialog-info {
    width: 100% !important;
    height: 100% !important;
  }

  .hidden-overlay .ng-trigger-overlayAppear {
    display: none;
  }

  .k-dropdown .k-dropdown-wrap,
  .k-dropdowntree .k-dropdown-wrap {
    background-clip: padding-box;
    border-radius: 2px;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    transition: all .1s ease;
    cursor: pointer;
    outline: 0;
    color: #656565;
    background-color: #f6f6f6;
    background-image: linear-gradient(#f6f6f6, #f1f1f1);
    border: 1px solid rgba(0, 0, 0, .08);
  }
}
