.news-template-16 {
    width: 100%;
    background-color: #FFFFFF !important;
    .row {
      --bs-gutter-x: 0;
    }

    .k-dialog {
        height: auto !important;
        width: auto !important;
    }

    .post-title {
        display: flex;

        // justify-content: center;
        color: #0B68A4;
         
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
        // margin-top: 20px;
        // margin-bottom: 15px;
        background-color: #08659A;
        position: relative;
        height: 50px;
    }

    .post-more {
        display: flex;
        justify-content: center;
        color: #EB2E35;
         
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            color: #EB2E35;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .icon-clock {
      width: 14px;
      height: 14px;
      color: #08659A;
      margin-top: 1px;
    }

    .title {
      font-style: normal;
      font-size: 16px;
      text-align: justify;
      color: #454545;
      margin-left: 9px;
      line-height: 18.5px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .post-image {
        height: 198px;
        width: 100%;
        object-fit: cover;
    }

    .post-image-org {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 40%;
    }

    .time-clock {
        display: flex;
    }

    .time-text {
        color: #979797;
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .main-item {
      display: table;
      //border: solid #ededed 2px;
      box-sizing: border-box;
      .row{
        flex-wrap: nowrap;
      }
    }

    .top-news-list {
      padding: 17px;
      border: 1px solid #ededed;
    }

    .vertical-dot {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #808080;
        color:white;
        z-index: 10;
        margin-top: -14px;
        margin-left: -14px;
        border-radius: 2px;
        visibility: hidden;
    }

    .d-flex {
        visibility: visible !important;
    }

    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .img-figure, .img-figure-no-img {
        height: 198px;
        max-width: 372px;
        display: flex;
        justify-content: center;
        position: relative;
    }

    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
    .uppercase {
        color: #FFFFFF;
        padding-top: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .top-new-item {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .close-div {
        display: flex;
        margin-top: 3px;
    }

    .news-template-16-dialog {
        display: flex;
        justify-content: center;
        position: relative;

        .close-icon-div {
            position: absolute;
            right: 10px;
        }

        .close-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .input-label {
   
        color: #004A9A;
        margin: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 15px;
        text-align: center;
    }

    .btn-delete {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #1c8d43;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .btn-cancel {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #6c757d;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .flower-img {
        width: 19px;
        height: 19px;
        margin: 10px;
    }

    .first-news-deco {
        position: absolute;
        right: 0px;
        height: 40px;
        width: 49px;
    }

    .text-section {
        display: flex;
        flex-direction: row;
        //align-items: center;
        :hover {
            color: #00396B
        }
    }

    .title-first {
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #00396B;
    }

    .new-detail {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #1F1F1F;
        height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    @media screen and (max-width: 576px) {
        .top-new-item{
            padding: 0px 10px;
        }
    }
    .dialog-width{
        max-width: 400px;
    }
}
