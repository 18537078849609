.user-info-container {
  display: flex;
  width: 100%;
  margin-bottom: 200px;
  margin-left: 0;
  margin-right: 0;
   
}
.user-upload-img {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 251px;
  height: 251px;
  margin-right: 30px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .user-image {
    width: 118px;
    height: 118px;
    background-color: grey;
    border-radius: 50%;
  }

  .user-avatar {
    border-radius: 50%;
    width: 118px;
    height: 118px;
    object-fit: contain;
  }

  .upload {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .k-upload {
    border: none;

    .k-dropzone {
      background-color: white;
    }

    .k-button {
      background-color: white;
      background-image: none;
      border: none;

      input {
        cursor: pointer;
      }

      span {
        color: #00396b;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.user-form-container {
  max-width: 759px;
  padding: 25px 20px 30px 30px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  .user-form-title {
    color: #1f1f1f;
     
    font-size: 24px;
  }

  .user-form {
    width: 100%;
    margin-top: 31px;
  }

  .user-input-item {
    margin-bottom: 40px;
  }

  .user-input-item.select-item {
    .k-input,
    .k-input::placeholder {
      color: #c5c5c5;
      font-size: 16px;
      font-weight: 500;
    }

    .k-picker-wrap {
      padding-top: 11px;
    }

    .k-select {
      color: #878787;
    }
  }
  //dropdown
  .k-dropdown {
    width: 100%;
    .k-dropdown-wrap {
      width: 60%;
      border: 1px solid #c5c5c5;
      border-radius: 5px;
      background-color: #ffffff;
      background-image: none;
    }

    .k-input {
      height: 54px;
      padding: 20px 0 17px 19px;
    }

    .k-select {
      padding-right: 23px;
    }
  }

  ////
  .k-textbox {
    width: 60%;
    height: 54px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    padding: 20px 0 17px 19px;
  }
  //datepicker
  .k-datepicker {
    width: 60%;
    .k-select {
      background-color: #ffffff;
      background-image: none;
      padding-right: 23px;
      padding-bottom: 0;
      padding-top: 5px;
    }
  }

  .k-datepicker .k-picker-wrap {
    width: 100%;
    height: 54px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    padding: 13px 0 17px 19px;
  }

  .k-dateinput-wrap .k-input {
    padding: 0;
  }

  .k-textbox::placeholder {
    color: #c5c5c5;
    font-size: 16px;
    font-weight: 500;
  }

  .user-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 109px;
  }

  .user-form-button {
    min-width: 120px;
    font-size: 16px;
    padding: 13px 47px;
    margin-left: 32px;
    border: none;
    background-color: #23bd20;
    color: #ffffff;
    border-radius: 3px;
    font-weight: 600;
  }

  .quit-button {
    color: #616161;
    border: 1px solid #b0b0b0;
    background-color: #ffffff;
  }

  .user-error {
    color: #d51923;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-top: 4px;
  }
}

@media screen and (max-width: 576px) {
  .user-info-container {
    justify-content: center;
  }
  .user-upload-img {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .user-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    box-shadow: none;
    .k-textbox,
    .k-datepicker,
    .k-dropdown .k-dropdown-wrap {
      width: 100%;
    }
    .user-form-button {
      padding: 13px 20px;
    }
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .success-icon {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 106px;
    height: 106px;
  }

  .success-title {
    font-size: 22px;
    font-weight: 600;
    color: #1bb018;
  }

  .success-msg {
    margin-bottom: 56px;
  }
  button {
    background-color: #1bb018;
    width: 209px;
    height: 44px;
    border-radius: 3px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 51px;
  }

  button:hover {
    color: #000000;
  }
}
