

.contact-container {
  margin-bottom: 130px;
  margin-left: 200px;
  input.k-input {
    width: 100% !important;
    height: 44px !important;
    border-radius: 3px!important;
    // border: 1px solid #C8C8C8 !important;
    // padding-top: 13px!important;
    // padding-bottom: 13px!important;
    padding-left: 19px!important;
  }
  .k-input::placeholder, .k-input::placeholder {
    color: #B9B9B9;
    text-transform: uppercase;
  }
  .k-i-close {
    height: 100%;
  }
  .contact-title {
    margin-top: 80px;
    margin-bottom: 40px;
    font-size: 26px;
    font-weight: 700;
    color:#00396B;
    text-transform:uppercase;
  }
  .contact-wrapper {
    display: flex;
  }

  .contact-drop{
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px

  }
  .k-combobox {
    border: 1px solid #C8C8C8 !important;
    width: 100%;
    height: 44px;
    border-width: 0;
    box-sizing: border-box;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    text-align: left;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    
}

.vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color:white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 130px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.d-flex {
  visibility: visible !important;
}


  .contact-inputs {
    width: 33%;
    margin-right: 68px;
  }

  .contact-input-item {
    margin-bottom: 15px;
  }

  .contact-textarea-item {
    margin-bottom: 26px;
  }

  .k-textbox {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px;

  }

  .k-textbox::placeholder, .k-textarea::placeholder {
    color: #B9B9B9;
    text-transform: uppercase;
  }

  .k-textarea {
    width: 100%;
    height: 197px;
    resize: none; 
    border: 1px solid #C8C8C8;
    padding-left: 19px;
    padding-top: 19px;
  }


  .contact-button-wrapper{
    display: flex;
    justify-content: flex-end;
  }

  .contact-form-button {
    min-width: 169px;
    font-size: 18px;
    padding: 8px;
    border: none;
    background-color: #00396B;
    color: #FFFFFF;
    border-radius: 3px;
    text-transform: uppercase;

  }

  .contact-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .contact-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    margin-right: 13px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #D6A022;
    
  }

  .contact-info {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #5E5E5E;

  }

  .contact-error {
    color:#d51923;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-top: 4px;
  }

}
