.news-template-43, .news-template-43-without-time {
  width: 100%;
  margin-bottom: 50px;
  // min-height: 504px;

  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 43px;
    color: #000;
    margin-bottom: 20px;
  }

  .title-text {
    margin-bottom: 0 !important;
  }

  .post-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #FFF;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    background-color: #0084C7;
    padding: 5px 19px;
    border-radius: 20px;
    height: 27px;
    a {
      color: #FFF;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #292621;
    text-decoration: none;

    margin-bottom: 15px !important;
  }
  .title-template-type-2{
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #2B2B2B;
    margin-bottom: 20px;

  }

  .post-image, .post-image-radius {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .post-image-radius{
    border-radius: 4px;
  }
  .post-image-default{
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
  }

  .vertical-dot {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .menu {
    background-color: white;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: center;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      background-color: white;
      color: black;
      -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      text-align: center;
    }
    .menu-item-3 {
      padding: 5px 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-align: center;
    }
  }

  figure {
    margin: 0;
  }

  .img-figure,
  .img-figure-no-img,
  .img-figure-template-type-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
  }

  .img-figure-2{
    height: 260px !important;
  }

  .k-scrollview-wrap {
    border: none;
    overflow: visible;
  }

  .k-scrollview-prev,
  .k-scrollview-next {
    height: auto !important;
    top: 40% !important;
    opacity: 1;
    .k-icon {
      background-color: rgba(2, 90, 205, 1);
      border-radius: 50%;
      font-size: 3.5em !important;
    }
  }

  .k-scrollview-prev {
    left: -5%;
  }

  .k-scrollview-next {
    right: -5%;
  }

  .banner {
    padding: 0 20px;
    img, .info {
      border-radius: 4px;
    }
    .img-1,
    .img-2 {
      width: 50%;
      object-fit: fill;
    }
    .img-1 {
      padding-right: 1%;
    }
    .img-2 {
      padding-left: 1%;
    }
    .main-img {
      width: 100%;
      padding-left: 3%;
      object-fit: fill;
    }
    .info {
      color: #FFF;
      font-style: normal;
      line-height: normal;
      background: rgba(13, 191, 187, 1);
      overflow: hidden;
      position: relative;
      padding: 25px;
      .info-bg {
        opacity: 0.1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .info-content {
        position: relative;
        .menu-title {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 10px;
          white-space: normal;
        }
        .description {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          white-space: normal;
          .inner-des {
            position: relative;
            display: -webkit-box;
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .view-detail {
          font-size: 14px;
          border: 1px solid #fff;
          border-radius: 4px;
          padding: 8px 12px;
          width: fit-content;
        }
      }
    }
  }
  .no-data {
    margin-top: 15px;    
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #006CB5;
  }

  .post-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 20px 20px 20px 60px;
    .k-pager-info, .k-pager-sizes {
      display: none;
    }
    .k-pager {
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }
      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }
    .top-part {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
  }

  .line {
    height: 5px;
    border-top: 1px solid #D9D9D9;
    margin: 20px;
  }

  .info-part {
    width: 700px;
    margin-top: 20px;
  }

  .date {
    font-size: 26px;
    font-weight: 600;
    border-bottom: 1px solid;
    width: 53%;
    margin-bottom: 5px;
  }

  .month {
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  }

  .img-figure {
    height: 200px;
    width: 380px;
    margin-right: 15px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .top-new-item {
    margin: 5px 0;
    padding-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    .title:hover {
      color: #00396b;
    }
  }

  .sub-info {
    position: relative;
  }

  .care {
    width: 85px;
    padding: 15px 8px;
    height: 25px;
    line-height: .2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #FFF;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background-color: #0084C7;
    border-radius: 20px;
    margin-left: calc(100% - 85px);
    position: absolute;
    top: 80px;
    left: 0;
    a {
      color: #FFF;
    }
  }

  .close-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
  }

  .news-template-43-dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      font-weight: 500;

      color: #004A9A;
    }
  }

  .input-label {

    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;

    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;

  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;

    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;

  }
  .dialog-width{
    max-width: 400px;
  }
  .space-box{
    width: 100%;
  }

  @media screen and (max-width: 768px){
    .main-item{
      margin: 10px;
    }
    .img-figure{
      height: auto !important;
      width: 240px;
    }
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin: 0;
    }
    .info-part{
      width: 400px;
    }

    .post-title {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 0 !important;
    }

    .k-scrollview-prev,
    .k-scrollview-next {
      top: -7% !important;
      .k-icon {
        background-color: white;
        color: black;
        font-size: 2.1em !important;
      }
    }

    .k-scrollview-prev {
      left: 80%;
    }

    .k-scrollview-next {
      right: 0px;
    }

    .banner {
      padding: 0 !important;
      .img-1,
      .img-2 {
        width: 100% !important;
      }
      .main-img {
        padding-left: 0 !important;
      }
      .info {
        .info-content {
          padding: 0 15px;
          .description {
            .inner-des {
              -webkit-line-clamp: 4 !important;
            }
          }
        }
        .info-bg {
          left: -50%;
          width: 150%;
        }
      }
    }
  }
  @media screen and (max-width: 576px){
    .date-part{
      display: none;
    }
    .img-figure {
      height: 90px;
      width: 130px;
      margin-right: 10px;
    }
    .main-item{
      margin: 0px;
    }
    .info-part{
      width: 65%;
      margin-top: 0;
      font-size: 12px !important;
    }
    .title {
      font-size: 12px;
      margin-bottom: 0 !important;
    }
    p{
      margin-bottom: 0;
    }
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin: 0;
    }
    .care {
      display: none;
    }
    .post-title {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    .post-more {
      background-color: transparent;
      color: #D9D9D9;
      padding: 0;
    }
  }
}
