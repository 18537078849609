.support-container {
  width: 759px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  color: #1f1f1f;

  .support-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .support-content {
    padding: 0;
  }

  .support-item {
    margin-top: 28px;
    display: flex;
    align-items: center;
  }

  .support-item-icon {
    margin-right: 16px;
  }

  .support-item-title {
    font-weight: 500;
    font-size: 20px;
    color: #d6a022;
  }
}
