.post-detail2 {

    // padding-left: 1.5rem !important;
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      border-color: rgb(202, 202, 202);
      border-width: 1px;
    }

    table {
      // --bs-table-bg: transparent;
      // --bs-table-accent-bg: transparent;
      // --bs-table-striped-color: #212529;
      // --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
      // --bs-table-active-color: #212529;
      // --bs-table-active-bg: rgba(0, 0, 0, 0.1);
      // --bs-table-hover-color: #212529;
      // --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      vertical-align: top;
      border-color: rgb(202, 202, 202);
    }

    .content {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .text-tiny {
      font-size: 0.7em;
    }

    .text-small {
      font-size: 0.85em;
    }

    .text-medium {
      font-size: 1em;
    }

    .text-big {
      font-size: 1.4em;
    }

    .text-huge {
      font-size: 2em;
    }

    .row{
      display: flex;
    }
    // .subject {
    //     margin-top: 14px;
    //    
    // }
    // .row {
    //   margin-left: 1.5rem + calc(var(--bs-gutter-x) * -.5);
    // }

    a .categories {
      // margin-top: 15px;
      color: #4e7ed8;
      // font-size: 16px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bolder;
      //  font-family: 'Times New Roman', Times, serif;
      //padding-right: 12px;
      text-decoration: none !important;
      z-index: 6;
    }

    a:hover span {
      color: rgb(255, 153, 0)
    }

    .title-post {
       
      font-style: normal;
      margin-top: 47px !important;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: #006CB5;
      //padding-right: 12px;
    }

    .summary{
       
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

    color: #565656;
  }

  .margin-template {
    margin: auto;
    width: 75%;
  }

    .created-post {
      font-weight: bolder;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
       
      font-style: italic;
      font-weight: 300;
      line-height: 20px;
      color: #D52929;
    }

    hr.line-title {
      border-top: 5px solid #aeafb3;
    }

    .img-present {
      // overflow: hidden;
      position: relative;
      max-width: 100% !important;
      font-size: 10px;

      .video-view {
        height: 390px !important;

      }
    }

    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 12px;
      border-radius: 2px;
      visibility: hidden;

      .three-dot-area {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .menu {
        // border: 2px solid black;
        // background-color: #fffcfc;
        background-color: white;
        // color: #0079ff;
        // border-radius: 5px;
        cursor: pointer;
        width: 130px;
        top: 43%;
        left: 100%;
        position: absolute;
        z-index: 1;

        .menu-row,
        .menu-row-current,
        .menu-row-current-border {
          cursor: pointer;

          .menu-item-1,
          .menu-item-2 {
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            // border-bottom: 2px solid black;
          }
        }

        .menu-row-current,
        .menu-row-current-border {
          // background-color: #d8d5d5;
          // color: #686868;
          // border-bottom: 2px;
          // border-radius: 0 0 2px 2px;
          background-color: white;
          color: black;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
     
          text-align: center;
        }

        // .menu-row-current-border {
        //   border-radius: 2px 2px 0 0;
        // }

        .menu-item-3 {
          padding: 5px 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
  }

    .d-flex {
      visibility: visible !important;
    }

    .title-center {
      text-align: center !important;
    }

    .close-div {
      display: flex;
      margin-top: 3px;
  }

  .post-detail-dialog {
      display: flex;
      justify-content: center;
      position: relative;

      .close-icon-div {
          position: absolute;
          right: 10px;
      }

      .close-icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
      }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

    // .sumary {
    //      :host ::ng-deep .text-tiny {
    //         font-size: 0.7em;
    //     }
    //      :host ::ng-deep .text-small {
    //         font-size: 0.85em;
    //     }
    //      :host ::ng-deep .text-big {
    //         font-size: 1.4em;
    //     }
    // }
    .description {
      text-align: justify;

      figure {
        width: 100% !important;
        height: 500px;
        iframe {
          height: 100%;
          width: 100%;
        }
      }

      p {
         
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 30px !important;
        color: #000000 !important;
      }

      blockquote {
        border-left: 5px solid #d9d9d9;
        padding-left: 15px;
        //font-style: italic;

      }
    }

    img {
      width: 100% ;
      height: auto;
      //padding-right: 12px;
    }

    .box {
      padding: 0px;
      padding-left: 0px;
    }

    .reference-link-box {
      margin-top: 10px;
    }

    .sapned {
      margin-top: 20px;
    }

    .padding-body {
      margin-left: 10px;
      padding-right: 50px !important;
      max-width: 1140px;
      margin: 0 auto;
    }

    .reference-link {
      text-decoration: none;
    }

    .reference-link:hover {
      text-decoration: underline;
    }

    .file-container {
      padding: 20px;
      background: #EAEAEA;
      margin-top: 10px;
    }

    .custom-text {
      color: black;
    }

    .custom-text-file {
      font-weight: bold;
      color: black;
      text-decoration: none;



    }

    .file-div {
      margin-top: 5px;
      margin-bottom: 5px;

      a {
        text-decoration: none;
      }

      :hover {
        color: #4e7ed8;
      }
    }

    .categories-link {
      text-decoration: none;
    }

  }

  @media screen and (max-width: 770px) {
    .row {
      // margin-left: 10px;
    }

    .box {
      padding-left: 15px;
      padding-right: 30px;

      .categories {
        // margin-top: 15px;
        // color: #4e7ed8;
        // font-size: 16px;
        //  cursor: pointer;
        font-size: 14px;
        //  font-weight: bolder;
        //  font-family: 'Times New Roman', Times, serif;
        //padding-right: 12px;
        //  text-decoration: none;
      }

      .title-post {
        //@at-root margin-top: 0px;
        font-weight: bolder;
        font-size: 20px;
      }

      .img-present {
        // overflow: hidden;
        position: relative;
        max-width: 100% !important;
        font-size: 10px;

        .video-view {
          height: 190px !important;

        }
      }
    }

    .padding-right {
      padding-right: 14px
    }

    .padding-left {
      padding-left: 14px;
    }

    .description {
      text-align: justify;

      figure {
        width: 100% !important;

        // height: 300px !important;
        iframe {
          height: 100%;
          width: 100%;
        }
      }
    }

    // .post-detail {
    // }
    // padding-right: 15px;// padding-left: 15px;
  }

  @media screen and (max-width: 1025px) and (min-width: 770px) {
    // .row {//   margin-right: 3px !important;
    // }

    .box {
      padding-left: 15px !important;
    }
  }
