@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.news-template-34,
.news-template-34-without-time {

  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: flex;
    // justify-content: space-between;
    color: #00396b;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    align-items: center;
  }

  .title-text {
    text-align: left;
    line-height: 26px;
    line-height: 158%;
    font-size: 14px;
    color: #A5A5A5;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #d6a022;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 4px;

    a {
      color: #d6a022;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    line-height: 21px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: #292621;
    margin-bottom: 20px;
    text-decoration: none;
  }

  .title-template-type-2 {
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #2b2b2b;
    margin-bottom: 20px;
     
  }

  .post-image,
  .post-image-radius {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .post-image-radius {
    border-radius: 4px;
  }

  .post-image-default {
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    // display: table;
    border-top: 0.7px solid #CACACA;
    width: 100%;
    min-height: 50px;
    border-bottom: 0.7px solid #CACACA;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 2;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
       
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img,
  .img-figure-template-type-2 {
    height: 312px;
    // max-width: 500px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
  }

  .img-figure-template-type-2 {
    height: 153px;
  }

  .img-figure-2 {
    height: 260px !important;
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;

    // justify-content: space-between;
    .k-pager-info,
    .k-pager-sizes {
      display: none;
    }

    .k-pager {

      // padding-left: auto;
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }

      .k-state-selected {
        background-color: #d6a022;
        border: none;
      }
    }

    .top-part {
      display: flex;
      flex-direction: column;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .top-new-item {
    margin: 10px 0;
    // padding-left: 10px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title:hover {
      color: #00396b;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-34-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
       
      color: #004a9a;
      // text-align: center;
      // margin: 30px;
    }
  }

  .input-label {
     
    color: #004a9a;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .dialog-width {
    max-width: 400px;
  }

  .menus-wrap {
    display: flex;
    align-items: center;
    // margin-top: 7px;

  }

  .item-icon {
    // margin-top: 6px;
    margin-right: 27px;
  }

  .wrapper {
    display: flex;
    // justify-content: center;
  }

  .menus-item {
    // height: 28px;
    // width: 167px;
    background: #FFFFFF;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    //margin-right:20px;
    // margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .menus-text {
     
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #006CB5;
    text-align: center;
    margin: auto;
    width: fit-content;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 6px 15px;
  }

  .item-box {
    width: fit-content;
    margin-left: 25px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .post-image {
    width: 100%;
    max-height: 233px;
    min-height: 233px;
    object-fit: cover;
  }

  .trending-icon {
    width: 22px;
    height: 22px;
  }

  .d-grid {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px 30px;
  }

  .k-tabstrip-content,
  .k-tabstrip>.k-content {
    padding: 0;
    border: unset;
  }

  .k-tabstrip-content.k-state-active,
  .k-tabstrip>.k-content.k-state-active {
    margin-top: 56px;
  }

  .post-name {
    margin-top: 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22.63px;
     
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .k-tabstrip-items .k-link {
    padding: 6px 12px 13px;
    color: #b6b6b6;
    font-weight: 700;
    line-height: 22.63px;
     
    font-size: 18px;
  }

  .k-tabstrip-items .k-item.k-state-active,
  .k-tabstrip-items .k-item.k-state-selected {
    color: #004a9a;
    font-weight: 700;
    border-width: 0 0 3px;
    border-color: #08659a;
    border-style: solid;
  }

  .k-tabstrip-items .k-item.k-state-active,
  .k-tabstrip-items .k-item.k-state-selected {
    color: #004a9a;
    font-weight: 700;
    border-width: 0 0 3px !important;
    border-color: #08659a;
    border-style: solid;
    margin-bottom: 3px;
  }

  .k-tabstrip-items .k-item.k-state-active .k-link {
    color: #004a9a;
  }

  .k-tabstrip-items {
    overflow-x: auto;
  }

  .k-tabstrip-items::-webkit-scrollbar {
    height: 3px;
  }

  .k-tabstrip-items::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .k-tabstrip-items::-webkit-scrollbar-thumb {
    background: #888;
  }

  .k-tabstrip-items::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .k-tabstrip-top>.k-tabstrip-items .k-item {
    border-width: 0px 0px 1px 0px;
  }


  .media {
    position: relative;
  }

  .overlap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 106px;
    background: rgba(0, 0, 0, 0.56);
    display: flex;
    justify-content: center;
    align-items: center;

    .number-img {
      .text {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 24px;
        line-height: 30.17px;
         
      }

      .image {
        height: 24px;
        width: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // .item-image:hover {
  //   .post-name {
  //     transition: 0.4s;
  //     color: #ccc;
  //   }
  // }

  kendo-tabstrip {
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    .d-grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .title-text {
      font-size: 14px !important;
    }

    .post-name {
      font-size: 16px !important;
    }

    .overlap {
      width: 94px;
    }
  }

  @media screen and (max-width: 768px) {
    .d-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .title-text {
      font-size: 26px !important;
    }

    .overlap {
      width: 86px;
    }

    .wrapper {
      display: block !important;
      margin-bottom: 20px;
    }

    .menus-item {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    .d-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
    }

    .post-image {
      max-height: unset !important;
      min-height: unset !important;
    }

    .post-name {
      margin-top: 16px;
    }

    .k-tabstrip-content.k-state-active,
    .k-tabstrip>.k-content.k-state-active {
      margin-top: 44px;
    }

    .title-text {
      margin-bottom: 35px;
    }
  }
  @media (max-width: 770px){
    .title-text {
      margin-bottom: 0px !important;
    }
    .item-box{
      margin-top: 15px;
    }
  }
}