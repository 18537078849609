.news-template-42 {
  width: 100%;
  margin-top: 50px;

  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .k-scrollview-pageable > .k-button.k-primary, .k-scrollview-nav > .k-link.k-primary {
    background: #0084C7;
    border: #0084C7;
  }
  
  .k-scrollview-pageable > .k-button, .k-scrollview-nav > .k-link {
    background: #D9D9D9;
  }

  .post-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #00396b;
     
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
  }

  .title-text {
    margin-bottom: 0 !important;
  }

  .post-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 43px;
    color: #000;
     
    a {
      color: #d6a022;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    line-height: 21px;
    font-style: normal;
    font-size: 14px;
    color: #292621;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;  
    max-width: 55%;
    margin: 10px 0;
  }

  .post-image {
    height: 100px;
    width: 100px;
  }
  .post-image-default{
    height: 100px;
    width: 100px;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
  }

  .vertical-dot {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .menu {
    background-color: white;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: center;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      background-color: white;
      color: black;
      -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    .menu-item-3 {
      padding: 5px 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img {
    height: 100px;
    width: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    margin: 10px;
  }

  .post-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -20px;
    padding: 0px 17px;
    min-width: 80%;
    max-width: 80%;
    .k-pager-info, .k-pager-sizes {
      display: none;
    }
    .k-pager {
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }
      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }
    .top-part {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-wrap: wrap;
      border-radius: 5px;
      border: 1px solid #D9D9D9;
      background: #FFF;
      -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: 100%;
      text-align: center;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .top-new-item {
    margin: 10px 0;
    padding-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    .title:hover {
      color: #00396b;
    }
  }

  .tooltips {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    opacity: 1;
  }
  
  .tooltips .tooltiptext {
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 250px;
    word-wrap: break-word;
    background-color: #0084C7;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 3;
    top: -10px;
    left: 40%;

    p {
      margin: 0;
    }
  }
  a {
    color: #FFF;
  }
  
  .tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #0084C7 transparent transparent;
  }
  .tooltips:hover .tooltiptext {
    visibility: visible;
  }

  .close-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
  }

  .news-template-42-dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      font-weight: 500;
 
      color: #004A9A;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }
  .dialog-width{
    max-width: 400px;
  } 
  .k-scrollview-next, .k-scrollview-prev {
    color: white;
    background-color: #0084C7;
    text-shadow: rgb(0 0 0 / 30%) 0 0 15px;
    opacity: 1;
    outline-width: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 30px;
    top: calc(50% - 210px);
    width: 36px;  
    border-radius: 3px;
  }
  .k-scrollview-next .k-icon, .k-scrollview-prev .k-icon{
    font-size: 1.5em;
    font-weight: normal;
    padding-left: 8px;
  }
  .k-scrollview-wrap{
    border: none;
    height: 215px !important;
  }
  .dash-line{
    background: -webkit-gradient(linear, left top, right top, color-stop(20%, #DC7E10), color-stop(20%, #E1E1E1), color-stop(80%, #E1E1E1), color-stop(80%, #E1E1E1));
    background: -o-linear-gradient(left, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
    background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
    height: 2px;
    margin-bottom: 15px;
  }
  .text-item{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #696969;
    letter-spacing: -0.01em;
    height: 95px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
    white-space: pre-line;

  }
  .k-scrollview-wrap .k-scrollview > li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .k-scrollview-pageable > .k-button{
    width: 12px;
    height: 12px;
  }

  li.ng-tns-c303-1.ng-star-inserted {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mobile-view {
    display: none;
  }
  .web-view {
    display: block;
  }
  @media (max-width: 768px){
    .main-item{
      margin-top: 20px;
    }
    .col-md-4{
      width: 32%;
    }
    .web-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }  
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
      height: 190px;
      min-width: 100%;
      max-width: 100%;
      .top-part {
        border: none;
        background: transparent;
        -webkit-box-shadow: none;
                box-shadow: none;
      }
    }
    .post-more{
      padding: 0px;
      font-size: 18px;
    }
    .post-title{
      display: block;
      margin-bottom: 0;
    }
  }
  @media (max-width: 576px){
    .main-item{
      margin-top: 20px;
    }
    .web-view {
      display: none;
    }
    .mobile-view {
      display: block;
    }  
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
      height: 190px;
      min-width: 100%;
      max-width: 100%;
      .top-part {
        border: none;
        background: transparent;
        -webkit-box-shadow: none;
                box-shadow: none;
      }
    }
    .img-figure, .post-image{
      height: 80px;
      width: 80%;
    }
    .post-more{
      padding: 0px;
      font-size: 18px;
    }
    .post-title{
      display: block;
      margin-bottom: 0;
    }
  }
}
 
