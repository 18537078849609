.electronic-text {

  .k-grid-header,
  .k-grid-header .k-header {
    font-weight: bold;
    padding-right: 0px !important;
  }

  .router {
    margin-top: 15px !important;
    box-sizing: border-box;
  }

  .k-combobox {
    height: 100%;
    .k-dropdown-wrap {
      height: 100%;
    }
  }

  .k-dropdownlist {
    display: none;
  }

  .check-element {
    display: none !important;
  }

  .tab-des {
    margin: 30px 0;
    display: none;

    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: #00396b;
    }

    font-weight: bold;
    color: #00396b;
  }

  .control-search .btn-remove {
    background-color: transparent !important;
    right: 40px;
    color: #000 !important;
    width: 30px;
    height: 36px;
    float: right;
    border-radius: 0;
    margin-top: -36px !important;
    border: 0;
    margin-right: -38px;
  }

  .k-grid {
    border-color: rgba(0, 0, 0, 0.08);
    color: #2c2c2c !important;
    background-color: #ffffff;
  }

  .k-grid {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 16px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-top: 20px;
  }

  .list-member-title {
    font-size: 16px !important;
    color: #ea4335;
    margin-top: 15px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .k-cell-inner>.k-link {
    padding: 8px 12px;
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;
    overflow: hidden;
    background-color: white !important;
  }

  .k-grid td {
    border-width: 0 0 0px 0px !important;
    vertical-align: middle;
    background-color: white;
    border-bottom: 1px solid #e1e1e1 !important;
    vertical-align: top;
    padding-top: 15px;
  }

  .img {
    cursor: pointer;
  }

  .k-pager-info {
    text-align: right;
    justify-content: flex-end;
    flex: 1 1 0%;
    visibility: hidden;
    order: 9;
    overflow: hidden;
    display: none;
  }

  .k-pager-wrap {
    color: #424242;
    background-color: white !important;
    justify-content: right !important;
    padding-top: 30px;
  }

  .k-pager-wrap:focus {
    box-shadow: none;
  }

  .k-grid-header {
    padding: opx !important;
    border-bottom: 1px solid #b6b6b6 !important;
  }

  .k-grid-header-wrap,
  .k-grid-footer-wrap {
    margin-right: -1px;
    width: 100%;
    border-width: 0px 0px 0 0 !important;
    border-style: solid;
    border-color: inherit;
    position: relative;
    overflow: hidden;
  }

  .k-grid-content {
    width: 100%;
    // min-height: 100%;
    overflow: auto;
    overflow-x: auto;
    /* overflow-y: scroll; */
    position: relative;
    flex: 1;
  }

  .k-pager-nav {
    border: 1px solid #e7e7e7;
    margin-left: 10px;
    color: #000;
  }

  .k-state-selected {
    border: none;
  }

  .search-place {
    width: 100% !important;
    margin-top: 15px;
    padding: 4px 10px;
    height: 40px;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      border: none;
      width: 100%;
      font-size: 16px;
    }

    input::placeholder {
      font-weight: 400;
      color: #aaaaaa;
    }

    input:focus {
      outline: none;
    }

    i {
      color: gray;
    }
  }

  .dropdown {
    padding: 0 2px;

    kendo-combobox {
      width: 100% !important;
    }

    .k-dropdown-wrap {
      background-color: white;
      border: none;
    }

    .k-state-focused {
      box-shadow: none;
    }

    .k-select {
      background-color: white;
      background-image: none;
    }

    .k-input {
      font-size: 16px;
      line-height: normal;
      height: auto;
    }
    .k-i-close {
      height: 100%;
    }
  }

  .k-grid th {
    padding: 8px 12px;
    border-width: 0 0 0 0;
    white-space: nowrap;
    background-color: white;
  }

  .btn-search {
    color: #000;
    background-color: #d6a022;
    border: hidden;
    font-weight: 600;
    font-size: 16px !important;
    border-radius: 3px;
    margin-top: 39px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .filter {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;

    .field-name {
      font-size: 16px;
      font-weight: bold;
      color: #5a5a5a;
    }
  }

  .search-input {
    width: 40%;
  }

  .filter-1 {
    width: 60%;

    .filter-item {
      width: 50%;
    }

    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-name {
    color: #00396b;
  }

  .total-member {
    color: black;
  }

  .dialog-info-post-detail {
    max-width: 830px;
  }

  .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 15px;

    .k-button {
      font-size: 14px !important;
    }
  }

  .btn-custom {
    width: 100%;

    button {
      background: #cd330d;
      color: white;
      width: 100%;
    }
  }

  .item {
    border: 1px solid #c4c4c4;
    margin: 10px;
  }

  .index {
    float: left;
    width: calc(100% - 28px);
  }

  .k-pager-numbers .k-state-selected {
    color: #fff;
    background-color: #d6a022;
  }
}

@media (max-width: 768px) {
  .electronic-text {
    .k-dropdownlist {
      display: inline;
      color: #fff;
      background-color: #d6a022;
    }

    .k-pager-numbers-wrap {
      margin: 0;
    }

    .k-dropdown-button button {
      background: none !important;
      color: black !important;
      border: none !important;
      padding-right: 0px;
      width: 28px;
      float: right;
    }

    .btn-addNew {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .router {
      padding: 0 20px;
    }
    
    .btn-search {
      margin-top: 10px;
      height: 35px;
    }

    .search-place {
      height: 35px;
      margin-top: 5px;
    }
  }
}

@media (max-width: 992px) {
  .electronic-text {
    .router-outlet-content {
      padding: 0 10px;
    }
  }
}
