.news-template-47-dialog {
    margin-left: 0px !important;
  }
  
  .news-template-47-dialog .vnpt-upload-img .k-upload {
    display: none;
  }
  
  .news-template-47-dialog .vnpt-upload-img img {
    width: 160px;
  }
  
  .news-template-47-dialog .err-text {
    color: #ff6358;
    margin-left: 15px;
    font-style: italic;
  }
  
  .news-template-47-dialog .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
  }
  
  .news-template-47-dialog .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
  }
  
  @keyframes myerr {
    0% {
      border-color: #ea4335;
    }
  
    25% {
      border-color: yellow;
    }
  
    50% {
      border-color: blue;
    }
  
    100% {
      border-color: #34a853;
    }
  }
  
  :host ::ng-deep .news-template-47-dialog.x-dialog {
    max-width: 768px;
    max-height: 565px;
  }
  
  .news-template-47-dialog .custom-btn-addNew {
    margin-top: 10px !important;
  }
  
  .news-template-47-dialog .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  
  :host ::ng-deep .news-template-47-dialog .btn-custom {
    width: 100%;
  }
  
  :host ::ng-deep .news-template-47-dialog .btn-custom button {
    background: #cd330d;
    color: white;
    width: 100%;
  }
  
  
  .news-template-47-dialog .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  }
  
  .news-template-47-dialog .selected-lang {
    border-bottom: 3px red solid;
  }
  
  
  @media (max-width: 600px) {
    :host ::ng-deep .k-window {
      width: 90%;
      height: 90%;
    }
  
    :host ::ng-deep .news-template-47-dialog.x-dialog {
      max-width: 768px;
      max-height: none;
    }
  
    .news-template-47-dialog .vnpt-upload-img img {
      width: 99px;
    }
  }
  
  
  .news-template-47-dialog .row-file {
    margin-bottom: 5px;
  }
  
  .news-template-47-dialog .float-right {
    float: right;
  }
  
  .news-template-47-dialog .border-infor {
    background: #FFFFFF;
    border: 0.6px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 11px;
  }
  
  .news-template-47-dialog .label-infor {
    color: #407BFF;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }
  
  .news-template-47-dialog .control {
    margin-bottom: 22px;
  }
  
  .news-template-47-dialog .input-label {
     
    margin: 10px;
    margin-left: 15px;
  }
  
  .news-template-47-dialog .input-box {
    height: 40px;
    margin: 10px 15px;
    width: calc(100% - 30px);
    border: solid 1px #e9e9e9;
    border-radius: 0px;
    background-color: white;
  
    .k-input {
      height: 38px;
      margin-top: -1px;
    }
  
    .k-clear-value {
      height: 38px;
      margin-top: -1px;
    }
  }
  
  .news-template-47-dialog .btn-save {
    margin: 15px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
  }
  
  .news-template-47-dialog .padding-col {
    padding: 7px 15px 0;
  }
  