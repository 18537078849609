.news-template-16-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-16-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-16-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-16-dialog .err-text,
.news-template-16-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.news-template-16-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
      border-color: #ea4335;
  }
  25% {
      border-color: yellow;
  }
  50% {
      border-color: blue;
  }
  100% {
      border-color: #34a853;
  }
}

:host ::ng-deep .news-template-16-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-16-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-16-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-16-dialog .btn-custom {
  width: 100%;
}
:host ::ng-deep .news-template-16-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}


.news-template-16-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.news-template-16-dialog .selected-lang {
  border-bottom: 3px red solid;
}


@media (max-width: 600px) {
  :host ::ng-deep .k-window {
      width: 90%;
      height: 90%;
  }
  :host ::ng-deep .news-template-16-dialog.x-dialog {
      max-width: 768px;
      max-height: none;
  }

  .news-template-16-dialog .vnpt-upload-img img {
      width: 99px;
  }
}


.news-template-16-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-16-dialog .float-right {
  float: right ;
}

.news-template-16-dialog .border-infor{
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-16-dialog .label-infor{
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-16-dialog .btn-save{
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px!important;
  width: 200px;
  height: 40px;
}

.news-template-16-dialog .padding-col{
  padding: 7px 15px 0;
}
