.images {
     
}

.images .image-grid {
    display: flex;
    justify-content: space-between;
}



.images .err-text,
.images .required {
   color: #ff6358;
   padding-left: 3px;
   font-style: italic;
}

.images .selected-lang {
    border-bottom: 3px red solid;
}

.images .default-lang {
    /* border-bottom: none; */
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}
.images .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
.images .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
}
.images .k-pager-wrap {
    border-color: white !important;
    color: #424242;
    background-color: white !important;
}
.images .err-image {
    border: 1px solid #ea4335;
    border-radius: 0.5;
    animation: myerr 1s infinite;
}

.images .btn-dialog-cus {
    float: left;
    margin-top: 25px;
}
.images .btn-search{
    color: white;
    background-color:#985D1A;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important
}
@media (max-width: 768px) {
    .g-archive {
        display: none;
    }

    .mb-archive {
        display: initial;
    }

}

@keyframes myerr {
    0% {
        border-color: #ea4335;
    }
    25% {
        border-color: yellow;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: #34a853;
    }
}

.images .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 15px;
}

.images .btn-group-list {
    float: right;
    margin-right: 15px;
}

.images .is-cursor {
    cursor: pointer;
}

.images .file-type {
    width: 100%;
    height: 200px;
    display: inline-block;
    object-fit: cover;
    cursor: pointer;
}

.images .file-type-upload {
    width: 20%;
    /* width: 170px;
    height: 170px; */
}

.images .file-type-upload-success {
    width: 20%;
}
.images .align-center {
    text-align: center;
}

.images .item {
    width: 100%;
    height: 100%;
    border: 0.2px solid rgb(194, 194, 194);
    padding: 5px;
    border-radius: 5%;
}
.images .align-center-webkit {
    text-align: -webkit-center;
    margin-bottom: 25px;
}


.images .custom-text {
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
    height: 35px;
}

.images .btn-elipsis {
    border: none;
    background-color: transparent;
    color: black;
    background-image: none;
    position: absolute;
    right: 24px;
    top: 4px;
    z-index: 999;
}

.images .btn-elipsis button {
    /* border: none;
    background-color: transparent;
    color: black;
    background-image: none; */

    background: none;
    border: none;
    /* border-style: groove;
    border: 2px solid darkgray; */
}


:host ::ng-deep .k-grid td {
    border-width: 0;
}
:host ::ng-deep .k-grid th {
    border-width: 0;
}

.images.pd-for-scroll{
    padding: 0px 0px 0px 0px !important;
    margin: 15px 3px 0px 3px !important;
  }

.images .img-slide {
    // width: 100%;
    width: 40px;
    height: 40px;
    object-fit: contain;
    padding: 10px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.images .video-slide {
    width: 100%;
    height: 490px;
    object-fit: fill;
    padding: 10px;
  }


/* .images .k-dialog{
    background: none;
} */
.images .carousel-control-next,
.images .carousel-control-prev{
    // margin: 10% 0 10% 0;
}
.images .carousel-control-next-icon,
.images .carousel-control-prev-icon {
    background-color: darkgrey;
    border-radius: 50%;
    background-size: 16px;
    padding: 20px;
    top: 50%;
    /* left: 50%; */
}


.images .xbutton-detail-media{
    border-radius: 50%;
    background: darkgrey !important;
    float: right;
}

.images .k-window-content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-content: center;
}

.images.crystal-detail.k-dialog-wrapper .k-dialog {
    background: none !important;
    box-shadow: none !important;
    width: 90%;

}

.images .upload-image{
    float: left;
    /* margin-left: 25px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.images .upload-image .top{
    margin-bottom: 5px;
    white-space: nowrap;
}

.images .archive-item{
    position: relative;
    margin-bottom: 28px;
    /* bottom: 0;  */
}

.images .img-title {
    margin-top: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #494949;
    text-transform: uppercase;
}

.images .input-image{
    float: left;
    /* margin-left: -100px; */
    max-width: 1000px;
}

.images .btn-remove{
    right: 0px;
}



.images .k-pager-wrap {
  border-color: white;
  color: #424242;
  background-color: white;
}

.popup-upload.x-dialog {
    max-width: 768px;
    /* max-height: 565px; */
    max-height: fit-content !important;
}

:host ::ng-deep .popup-upload .k-window-content, .k-prompt-container {
    overflow: hidden !important;
}

.images .image-upload{
    display: none;
}

.images.popup-upload .k-window{
    max-height: 700px;
}

.images .vnpt-upload-img .k-upload{
    display: none;
}

.images .mb-drbtn-func .k-dropdown-button {
    height: 0px !important;
    background: none;
}

.images .bookmark-show{
    border: none;
    background-color: transparent;
    position: absolute;
    top: 4px;
    z-index: 1;
    float: left;
    left: 24px;
    font-size: 27px;
    color: red;
}
.images .xbutton-dialog .k-icon {
  font-size: 20px !important;
}
.images .k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
  color: inherit;
  align-self: center;
  position: relative;
}
.images .k-icon {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "WebComponentsIcons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;

  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.images .image-slide {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.images .carousel-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    
}

.images .carousel-prev, .images .carousel-next {
    display: flex;
    align-items: center;
}

.images .carousel-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 70%;
}

.images .thumbnail-images-container {
    margin-top: 36px;
    padding-left: -5px;
    padding-right: -5px;

    width: 100%;
    display: flex;
}

.images .image-slide-thumbnail {
    width: 20%;
    padding:0 5px;
    height: 80px;
    img {
        width: 100%;
        height: 100%;
    object-fit: cover;}

}

@media (min-width: 768px) {
    .images .col-md-2 {
      flex: 0 0 auto;
      width: 261px  !important;
      height: 270px !important;
  }
  }

@media (max-width: 768px) {
    .images .image-slide,  .images .image-slide-thumbnail {
        
        height: auto;
        
    }
}