footer.ver1 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  // margin-bottom: 1.5em;
  background-color: #0B68A4;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .footer {
    width: var(--outlet-content-width);
    height: 240px;
    color: white;
    margin: auto;
    padding: 20px 0 10px 0;

    .row {
      display: flex;
      flex-direction: row;
    }

    span.title {
      display: inline !important;
      width: fit-content;
      border-bottom: 2px solid #E4C97E;
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 22px;
      padding-bottom: 10px;
    }

    ul {
      padding: 10px 12px;
      margin-top: 10px;
      margin-bottom: 0;
      list-style-type: square;
    }

    .info ul {
      list-style-type: none;
      padding: 10px 0;
    }

    .menu ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 13em;
      margin-top: 0;
      padding: 0;
    }

    .menu li {
      width: 50%;
    }

    li {
      padding-bottom: 15px;
    }

    li:hover {
      .btn-func {
        visibility: visible;
      }
    }

    .info:hover {
      .btn-func {
        visibility: visible;
      }
    }

    li a {
      color: white;
      font-weight: 500;
      text-decoration: none;
    }

    .btn-func {
      visibility: hidden;
    }

    .btn-custom {
      width: fit-content;
      background: initial;
      color: white;
    }

    .btn-custom button {
      background-color: inherit;
      width: fit-content;
      color: white;
      border: none;
    }

    .btn-custom .k-button {
      padding: 0 !important;
    }

    .k-popup .k-list {
      height: auto !important;
      width: fit-content;
      padding: 0;
      margin: 0;
    }

    .k-popup .k-item {
      width: 100%;
    }

    .handle:hover {
      cursor: pointer;
    }

    .btn-add {
      background-color: inherit;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: 1px solid white;
      border-radius: 2px;
      height: 2.5em;
    }

    .btn-add:hover {
      background-color: #074973;
    }
  }

  .copyright {
    background-color: #074973;
    width: 100%;
    color: white;
    margin: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 0;

    div {
      margin: auto;
      width: var(--outlet-content-width);
    }
  }

  .edit {

    .required,
    .err-text {
      color: #ff6358;
      padding-left: 3px;
      font-style: italic;
    }
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black;
    font-size: 20px;
  }

  .title-info-req {
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .btn-save {
    color: white;
    background-color: #0B68A4;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    margin-top: 10px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 576px) {
  footer {
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .btn-add {
      width: 25% !important;
    }

    width: 100% !important;
  }
}

@media screen and (max-width: 800px) {
  footer {
    width: 100% !important;

    .add-menu.x-dialog {
      width: 95% !important;
      height: 95% !important;
    }
  }
}
