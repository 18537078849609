.library-text {

  .k-grid-header,
  .k-grid-header .k-header {
    font-weight: bold;
    padding-right: 0px !important;
  }
  .field-name {
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353535;
  }
  .router {
    margin-top: 15px !important;
    box-sizing: border-box;
  }

  .k-cell-inner {
    margin: 0px 0px -5px -15px !important;
    float: left;
  }

  .wb-library-text {
    display: block !important;
  }

  .mb-library-text {
    display: none !important;
  }

  .k-dropdownlist {
    display: none;
  }

  .label-text-name {
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #00396B;
  }

  .check-element {
    display: none !important;
  }

  .tab-des {
    margin: 30px 0;
    display: none;

    p {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      color: #00396b;
    }

    font-weight: bold;
    color: #00396b;
  }

  .control-search .btn-remove {
    background-color: transparent !important;
    right: 40px;
    color: #000 !important;
    width: 30px;
    height: 36px;
    float: right;
    border-radius: 0;
    margin-top: -36px !important;
    border: 0;
    margin-right: -38px;
  }

  .k-grid {
    border-color: rgba(0, 0, 0, 0.08);
    color: #2c2c2c !important;
    background-color: #ffffff;
  }
  .k-column-title {
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    overflow: hidden;
     
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #111111;
}
  .k-grid {
    border-width: 0px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 16px;
    line-height: 1.4285714286;
    display: flex;
    flex-direction: column;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-top: 20px;
  }

  .list-member-title {
    font-size: 16px !important;
    color: #ea4335;
    margin-top: 15px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .k-combobox {
    height: 100%;

    .k-dropdown-wrap {
      height: 100%;
    }
  }

  .k-cell-inner>.k-link {
    padding: 8px 12px;
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;
    overflow: hidden;
    background-color: white !important;
    justify-content: center;
  }

  .k-grid td {
    border-width: 0 0 0px 0px !important;
    vertical-align: middle;
    background-color: white;
    border-bottom: 1px solid #e1e1e1 !important;
    vertical-align: top;
    padding-top: 15px;

    dd {
      padding-left: 5px;
    }
  }

  .img {
    cursor: pointer;
  }

  .k-pager-info {
    text-align: right;
    justify-content: flex-end;
    flex: 1 1 0%;
    visibility: hidden;
    order: 9;
    overflow: hidden;
    display: none;
  }

  .k-pager-wrap {
    color: #424242;
    background-color: white !important;
    justify-content: right !important;
    padding-top: 30px;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .k-pager-wrap:focus {
    box-shadow: none;
  }

  .k-grid-header {
    padding: 0px !important;
    border-bottom: 1px solid #b6b6b6 !important;
  }

  .k-grid-header-wrap,
  .k-grid-footer-wrap {
    margin-right: -1px;
    width: 100%;
    border-width: 0px 0px 0 0 !important;
    border-style: solid;
    border-color: inherit;
    position: relative;
    overflow: hidden;
  }

  .k-grid-content {
    width: 100%;
    // min-height: 100%;
    overflow: auto;
    overflow-x: auto;
    /* overflow-y: scroll; */
    position: relative;
    flex: 1;
  }

  .k-pager-nav {
    border: 1px solid white;
    margin-left: 10px;
    color: #000;
  }

  .k-state-selected {
    border: none;
  }

  .search-place {
    width: 100% !important;
    margin-top: 15px;
    padding: 4px 10px;
    height: 40px;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      border: none;
      width: 100%;
       
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #AFAFAF;
    }

    input::placeholder {
      font-weight: 400;
      color: #aaaaaa;
    }

    input:focus {
      outline: none;
    }

    i {
      color: gray;
    }
  }

  .dropdown {
    padding: 0 2px;

    kendo-combobox {
      width: 100% !important;
    }

    .k-dropdown-wrap {
      background-color: white;
      border: none;
    }

    .k-state-focused {
      box-shadow: none;
    }

    .k-select {
      background-color: white;
      background-image: none;
    }

    .k-input {
      font-size: 16px;
      line-height: normal;
      height: 100%;
    }

    .k-i-close {
      height: 100%;
    }
  }

  .k-grid th {
    padding: 8px 12px;
    border-width: 0 0 0 0;
    white-space: nowrap;
    background-color: white;
  }

  .btn-search {
    border: hidden;
    background: #006CB5;
    font-size: 16px;
    border-radius: 3px;
    margin-top: 39px;
    height: 40px;
    display: flex;
     
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: white;
    flex-direction: row;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .filter {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;


  }

  .search-input {
    width: 40%;
  }

  .filter-1 {
    width: 60%;

    .filter-item {
      width: 50%;
    }

    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .label-text {
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2C2C2C;
  }

  .label-text0-name {
    justify-content: center !important;
    display: flex !important;

  }

  .text-name {
    color: #00396b;
  }

  .total-member {
    color: black;
  }

  .dialog-info-post-detail {
    max-width: 830px;
  }

  .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 15px;

    .k-button {
      font-size: 14px !important;
    }
  }

  .btn-custom {
    width: 100%;

    button {
      background: #cd330d;
      color: white;
      width: 100%;
    }
  }

  .item {
    border: 1px solid #c4c4c4;
    margin: 10px;
  }

  .index {
    // float: left;
    // width: calc(100% - 28px);
    justify-content: center;
    display: flex;
    text-align: center;
    float: left;
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  .k-pager-numbers .k-state-selected {
    color: #fff;
    background-color: #d6a022;
  }

  .library-text-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .dialog-width {
    max-width: 400px;
  }

  .download-box {
    display: flex;
    justify-content: center;
  }
  .pointer{
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .library-text {
    .k-dropdownlist {
      display: inline;
      color: #fff;
      background-color: #d6a022;
    }

    .k-pager-numbers-wrap {
      margin: 0;
    }

    .item-menu {
      margin: 10px 0;
      padding: 5px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      width: 100%;
    }

    .wb-library-text {
      display: none !important;
    }

    .mb-library-text {
      display: block !important;
      padding: 0px;
    }

    .k-dropdown-button button {
      background: none !important;
      color: black !important;
      border: none !important;
      padding-right: 0px;
      width: 28px;
      float: right;
    }

    .btn-addNew {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .router {
      padding: 0 20px;
    }

    .btn-search {
      margin-top: 10px;
      height: 35px;
    }

    .search-place {
      height: 35px;
      margin-top: 5px;
    }
  }
}

@media (max-width: 992px) {
  .library-text {
    .router-outlet-content {
      padding: 0 10px;
    }
  }
}
