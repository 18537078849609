.member-unit {
  //   margin: 0 200px;
  margin-bottom: 96px;

  .lb-name {
    font-size: 30px;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
}

  .d-flex {
    visibility: visible !important;
  }

  .title-center {
    text-align: center !important;
  }
  
  .image-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
    justify-content: space-between;
  }

  .image-column {
    flex: 33%;
    max-width: 33%;
  }

  .image-item {
    vertical-align: middle;
    width: 100%;
    margin-top: 20px;
    padding: 0 7px;
    cursor: pointer;
  }

  .image-container {
    display: block;
    position: relative;
    text-decoration: none;
  }

  .image-overlay {
    position: absolute;
    width: 100%;
    height: 104px;
    transition: 0.3s ease-out;
    opacity: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.69);
    text-decoration: none;
    cursor: pointer;
    h3 {
       
      font-weight: 700;
      font-size: 31px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      margin: 16px 10px;
    }
  }

  img {
    vertical-align: middle;
    width: 100%;
  }

  .image-container:hover .image-overlay {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .image-row {
      flex-direction: column;
      align-items: center;
      margin-left: 4px;
      margin-right: 4px;
    }
    .image-column {
      flex: 100%;
      max-width: 100%;
    }
  }
}
