.news-template-d {
  margin-bottom: 45px;
  background-color: #fff;

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22px;
  }
  .three-dot-area {
    cursor: pointer;
    width: 20px;
    display: flex;
    justify-content: center;
  }

  .three-dot {
    width: 5px;
    height: 20px;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: fit-content;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .news-d-content {
    display: flex;
  }

  .news-d-large-item {
    text-decoration: none;
    width: 50%;
  }

  .news-d-large-item-info {
    padding: 12px 22px 18px 22px;
    border: 1px solid #eeeeee;
    border-top: none;
  }

  .news-d-info-title {
    margin-bottom: 12px;
    font-size: 24px;
    color: #292621;
    font-weight: 500;
    cursor: pointer;
  }

  .news-d-list-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    justify-content: space-between;
  }

  .news-d-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      margin: 0;
      margin-left: 16px;
      color: #007fae;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .news-d-large-item-img {
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  .news-d-details {
    margin: 0;
    color: #292621;
  }

  .news-d-small-item {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .news-d-small-item-img {
    width: 44%;

    margin-right: 20px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .news-d-small-title {
    width: 50%;
    font-weight: 500;
    color: #292621;
    font-size: 16px;
    cursor: pointer;
  }

  .news-template-d-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  @media only screen and (max-width: 576px) {
    .news-d-content {
      display: flex;
      flex-direction: column;
    }

    .news-d-large-item {
      width: 100%;
      margin-bottom: 30px;
    }

    .news-d-info-title {
      font-weight: 500;
      color: #292621;
      font-size: 20px;
    }

    .news-d-details {
      display: none;
    }

    .news-d-list-item {
      margin: 0;
    }

    .news-d-small-item {
      flex-direction: column;
      margin-bottom: 30px;
    }

    .news-d-small-item-img {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .news-d-small-title {
      width: 100%;
      padding: 12px 22px 18px 22px;
      border: 1px solid #eeeeee;
      border-top: none;
    }
  }
}
