.opend-dialog-images {
  max-width: 100% !important;
  max-height: 100% !important;

  .slider-container {
    position: relative;
  }
  .category{
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    animation-duration: 1s;
    animation-timing-function: linear;
  }
  .title-right{
    //position: absolute;
    display: flex;
    overflow: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
.category-box{
  min-width: fit-content;
  margin-right: 10px;
}
.category-name{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3E3E3E;
}
.padingimg{
  // margin-top: 10px;
}
  .show {
    display: block;
  }
  .close-icon-div {
    position: absolute;
    right: 25px;
    top: -30px;
}
.close-icon {
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
}
  .slider {
    display: flex;
    overflow: hidden;
  }

  .demo {
    opacity: 0.6;
  }

  .slider img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }

  .thumbnail-container {
    margin-top: 10px;
    text-align: center;
    display: flex;
  }

  .thumbnails img {
    width: 60px;
    height: auto;
    cursor: pointer;
    margin-right: 5px;
    transition: opacity 0.3s ease-in-out;
  }

  .thumbnails img:hover {
    opacity: 0.7;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  button:nth-of-type(1) {
    left: 10px;
  }

  button:nth-of-type(2) {
    right: 10px;
  }

  * {
    box-sizing: border-box;
  }

  .img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    opacity: 0.6;
  }

  .cursor {
    cursor: pointer;
  }

  .prev {
    cursor: pointer;
    position: fixed;
    top: 55%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    height: 60px;
    color: white;
    left: 50px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  .next {
    cursor: pointer;
    position: fixed;
    top: 55%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    height: 60px;
    color: white;
    right: 50px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  .next {
    // right: 0;
    // border-radius: 3px 0 0 3px;
  }
  .title-right::-webkit-scrollbar {
    height: 10px;
  }

  .title-right::-webkit-scrollbar-thumb {
    background: #000000;
  }
  .title-right::-webkit-scrollbar-track {
    padding-top: 1px;
    padding-bottom: 1px;
    background: rgb(255, 255, 255);
  }

  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  .caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .column {
    float: left;
    width: 16.66%;
  }

  .demo {
    opacity: 0.6;
  }

  .active,
  .demo:hover {
    opacity: 1;
  }

  .opend-dialog-images .close-icon-div {
    position: absolute;
    right: 25px;
    top: -30px;
  }

  .opend-dialog-images .close-icon {
    width: 30px;
    height: 30px;
    position: fixed;
    cursor: pointer;
    z-index: 555555;
  }

  .opend-dialog-images .vnpt-upload-img .k-upload {
    display: none;
  }

  .opend-dialog-images .vnpt-upload-img img {
    width: 160px;
  }

  .opend-dialog-images .err-text {
    color: #ff6358;
    margin-left: 15px;
    font-style: italic;
  }

  .opend-dialog-images .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
  }

  .opend-dialog-images .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
  }

  @keyframes myerr {
    0% {
      border-color: #ea4335;
    }

    25% {
      border-color: yellow;
    }

    50% {
      border-color: blue;
    }

    100% {
      border-color: #34a853;
    }
  }

  :host ::ng-deep .opend-dialog-images.x-dialog {
    max-width: 768px;
    max-height: 565px;
  }

  .opend-dialog-images .custom-btn-addNew {
    margin-top: 10px !important;
  }

  .opend-dialog-images .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
  }

  :host ::ng-deep .opend-dialog-images .btn-custom {
    width: 100%;
  }

  :host ::ng-deep .opend-dialog-images .btn-custom button {
    background: #cd330d;
    color: white;
    width: 100%;
  }

  .opend-dialog-images .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  }

  .opend-dialog-images .selected-lang {
    border-bottom: 3px red solid;
  }

  @media (max-width: 600px) {
    :host ::ng-deep .k-window {
      width: 90%;
      height: 90%;
    }

    :host ::ng-deep .opend-dialog-images.x-dialog {
      max-width: 768px;
      max-height: none;
    }

    .opend-dialog-images .vnpt-upload-img img {
      width: 99px;
    }

    .opend-dialog-images {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  .opend-dialog-images .row-file {
    margin-bottom: 5px;
  }

  .opend-dialog-images .float-right {
    float: right;
  }

  .opend-dialog-images .border-infor {
    background: #ffffff;
    border: 0.6px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 11px;
  }

  .opend-dialog-images .label-infor {
    color: #407bff;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }

  .opend-dialog-images .control {
    margin-bottom: 22px;
  }

  .opend-dialog-images .input-label {
     
    margin: 10px;
    margin-left: 15px;
  }

  .opend-dialog-images .input-box {
    height: 40px;
    margin: 10px 15px;
    width: calc(100% - 30px);
    background-color: white;
  }

  .opend-dialog-images .k-input {
    height: 38px !important;
    margin-top: -1px !important;
  }

  .opend-dialog-images .k-clear-value {
    height: 38px !important;
    margin-top: -1px !important;
  }

  .opend-dialog-images .btn-save {
    margin: 15px;
    width: 200px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
  }

  .opend-dialog-images .padding-col {
    padding: 7px 15px 0;
  }
  .opend-dialog-images .video-container {
    // overflow: hidden;
    position: relative;
    width: 100%;
    height: 600px;
  }

  .opend-dialog-images .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .slideshow-container{
    height: 350px;
  }
  @media screen and (max-width: 770px) {
    .slideshow-container{
      height: 160px;
    }
  }
}
