.news-template-45 {
    width: 100%;
    background-color: #FFFFFF !important;

    .row {
      --bs-gutter-x: 0;
    }
    
    .k-dialog {
      height: auto !important;
      width: auto !important;
    }

    .pd-l-10 {
      padding-left: 18px;
    }

    .post-title {
        align-items: center;
        color: #00396b;
         
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
        // margin-bottom: 15px;
        //background-color: #00396B;
        position: relative;
        min-height: 40px;
        width: 100%;
    }

    .post-more {
        display: flex;
        justify-content: center;
        color: #EB2E35;
         
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            color: #EB2E35;
        }
    }

    .pointer {
        cursor: pointer;       
    }

    .icon-clock {
        width: 8px;
        height: 8px;
        margin-top: 5px;
        //margin-left: 7px;
    }

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: justify;
        color: #1F1F1F;
        margin-left: 16px;
        margin-bottom: 0;
         
    }

    .post-image {
        // height: 198px;
        height: 198px;
        width: 100%;
        object-fit: cover;
    }

    .post-image-org {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 40%;
    }

    .time-clock {
        display: flex;
    }

    .time-text {
        color: #979797;
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .main-item {
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
    }
    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;
    }

    .d-flex {
        visibility: visible !important;
    }

    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }

    .three-dot-area {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    .dialog-width{
      max-width: 400px;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .img-figure, .img-figure-no-img {
        // height: 198px;
        height: 100%;
        // max-width: 372px;
        display: flex;
        //justify-content: center;
        position: relative;
        margin-bottom: 10px;
    }

    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
    .uppercase {
        color: #FFFFFF;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }

    .top-new-item {
        margin: 15px 0 0 0;
    }

    .close-div {
        display: flex;
        margin-top: 3px;
    }

    .news-template-45-dialog {
        display: flex;
        justify-content: center;
        position: relative;

        .close-icon-div {
            position: absolute;
            right: 10px;
        }

        .close-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .title-info-req {
          // font-size: 20px;
          font-weight: 500;
     
          color: #004A9A;
          // text-align: center;
          // margin: 30px;
        }
    }

    .input-label {
   
        color: #004A9A;
        margin: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 15px;
        text-align: center;
    }
    
    .btn-delete {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #1c8d43;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }
    
    .btn-cancel {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #6c757d;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .flower-img {
        width: 19px;
        height: 19px;
        margin: 10px;
    }

    .first-news-deco {
        position: absolute;
        right: 0px;
        height: 100%;
        width: 49px;
    }

    .text-section {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        :hover {
            color: #00396B
        }
    }

    .title-first {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #00396B;
        margin-bottom: 9px !important;
    }

    .new-detail {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        // line-height: 16px;
        letter-spacing: -0.01em;
        color: #1F1F1F;
        height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 0px;
    }

    .is-current-folder1 {
        font-weight: 600;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
         
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #575757;
    }

    .is-current-folder {
        font-weight: 600;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #00396b;
         
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #006CB5;
    }

    .img{
        margin: 2px 12px 6px 12px;
    }
    
    .btn-fl {
        float: left;
        margin: 20px 0px 20px 0px;
    }

    .btn-search{
      color: white;
      background-color: #006CB5;
      border: hidden;
      padding: 9px 20px;
      margin-left: 0 !important;
      font-size: 14px !important;
      font-weight: 600;
      width: 100%;
    }

    .control-search .btn-remove {
      background-color: transparent!important;
      right: 40px;
      color: #000!important;
      width: 30px;
      height: 23px;
      float: right;
      border-radius: 0;
      margin-top: -33px;
      border: 0;
    }

    .search-container{
      display: flex;
      align-items: flex-end;
      width: 80vw;
      .group {
        margin-right: 1rem;
      }
    }

    .btn-dialog {
      float: left;
    }

    a.field-detail {
      text-decoration: none;
    }
    .control label {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .control input {
      height: 40px;
    }

    .control {
      margin-bottom: 0;
    }
    // p{
    //   text-align: right;
    // }

    @media (max-width: 1200px) {
        .pd-l-10{
            padding-left: 0px;
        }
    }
    .uppercase-black{
         
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;

        color: #00396B;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .intro-des, .intro-des-reserve {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2vw;
        padding: 30px 0;
        border-bottom: 1px solid #D9D9D9;
      }
      .intro-des-reserve{
        flex-direction: row-reverse;
        justify-content: end;
      }
    
      .intro-text, .intro-text-reverse {
        flex: 1;
        margin-top: 10px;
        .field-name {
          color: #000000 !important;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          text-align: left;
          margin-top: 40px;
        }
        .field-name:hover {
          color: #00396b  !important;
        }
        .letter {
          color: #494949 !important;
           
          font-style: normal;
          font-weight: 400 !important;
          font-size: 18px !important;
          line-height: 21px !important;
          text-align: left;
          margin-top: 10px;
        }
        .letter:hover {
          color: #00396b  !important;
        }
      }

      .intro-text-reverse{
        width: fit-content;
        flex: none;
      }
    
      .p3 {
        padding: 3.2rem;
      }
    
      .pt3 {
        padding-top: 3.2rem;
      }
    
      .pr3 {
        padding-right: 3.2rem;
      }
    
      .pb3 {
        padding-bottom: 3.2rem;
      }
    
      .pl3 {
        padding-left: 3.2rem;
      }
      .image-circle, .image-circle-no-border{
        width: 300px;
        height: 300px;
        border-radius: 50%;
      }
      .image-circle-no-border{
        border-radius: unset;
      }
      .center-img{
        display: flex;
        justify-content: center;
      }

      @media (max-width: 576px){
        .intro-des, .intro-des-reserve {
          display: block;
        }
        .image-circle, .image-circle-no-border{
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
        .search-container{
          width: 100%;
        }
        .btn-dialog {
          margin-top: 20px;
          margin-left: 0;
        }
      }
}
