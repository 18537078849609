.news-template-22, .news-template-22-without-time {
  //width: 714px;
  //height: 210px;
  //background-color: white !important;

  .row {
    --bs-gutter-x: 0;
  }

  .padding {
    padding: 0%;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: flex;
    justify-content: space-between;
    color: #00396b;
     
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
  }

  .title-text {
    // padding-left: 10px;
    margin-bottom: 0 !important;
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #d6a022;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 4px;
    a {
      color: #d6a022;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    // line-height: 20px;
     
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    // letter-spacing: -0.01em;
    text-align: left;
    color: #2B2B2B;
    // margin-bottom: 20px;
    text-decoration: none;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .title-template-type-2{
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #2B2B2B;
    margin-bottom: 20px;
     
  }

  .post-image, .post-image-radius {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .post-image-radius{
    border-radius: 4px;
  }
  .post-image-default{
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  // .three-dot-area {
  //   cursor: pointer;
  //   width: 20px;
  //   height: 20px;
  //   display: flex;
  //   justify-content: center;
  //   text-align: center;
  //   align-items: center;
  // }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img,
  .img-figure-template-type-2 {
    // height: 141px;
    //width: 218px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
  }
  .img-figure-template-type-2{
    // height: 153px;
  }

  .img-figure-2{
    // height: 141px !important;
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //align-items: stretch;
    // justify-content: space-between;
    .k-pager-info, .k-pager-sizes {
      display: none;
    }
    .k-pager {
      // padding-left: auto;
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }
      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }
    .top-part {
      display: flex;
      flex-direction: column;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
  
  .top-new-item {
    margin: 15px 0px 0px 0;
    // padding-left: 10px;
    //padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title:hover {
      color: #00396b;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-22-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;
 
      color: #004A9A;
      // text-align: center;
      // margin: 30px;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }
  .dialog-width{
    max-width: 400px;
  }
  @media (max-width: 770px){
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
    .post-image{
      height: auto !important;
    }
    .top-new-item {
      margin: 0px !important
    }
  }
  @media (min-width: 770px){
    .col-md-4{
      width: 32%;
    }
  }
}
