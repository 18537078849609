.news-template-48 {
  width: 100%;
  position: relative;

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 12px;
    border-radius: 2px;
    visibility: hidden;

    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 130px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .reward-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;

      color: #004a9a;
      // text-align: center;
      // margin: 30px;
    }
  }

  .background-img {
    position: relative;

    img {
      width: 100%;
    }
  }

  .history {
    width: 100%;
    margin: 0 0 100px 0;

    img {
      width: 100%;
    }
  }

  .news {
    border-bottom: 1px solid black;
  }
  .dialog-width {
    max-width: 400px;
  }

  .uppercase {
    height: 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #006CB5;
    text-transform: uppercase;
  }

  .news-title{
    width: fit-content;
  }

  .tab-container {
    display: grid;
    border: 1px solid var(--neutral-05, #E3E7EA);
    margin: 15px 0;
    cursor: pointer;
  }
  .item-image {
    justify-self: center;
    margin: 20px;
  }
  .item-name {
    justify-self: center;
    margin-top: 15px;
    text-align: center;
    font-weight: 500;
  }
}

@media screen and (max-width: 1200px) {
  .news-template-48 {
    
  }
}

@media screen and (max-width: 576px) {
  .news-template-48 {
    .uppercase {
      margin-top: 15px;
      font-size: 25px;
    }
    .tab-container {
      height: 90%;
      margin: 10px;
    }
    .item-name {
      font-size: 15px;
    }
  }
}

app-news-template-48 {
  position: relative;
  top: -6px;
}
