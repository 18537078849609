@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.images-slider {
  // display: table;
  // min-width: 100vw;
  .img-scrollview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .post-more {
    display: flex;
    justify-content: center;
  }
  .primary-title {
    cursor: pointer;
    color: #000000;
    margin-top: 48px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30.17px;
     
    display: flex;
    justify-content: center;
    span {
      color: #006cb5;
      margin-left: 6px;
    }
  }
  .sub-title {
    margin-top: 36px;
    margin-bottom: 75px;
    max-width: 815px;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #323232;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
     
    p {
      margin-bottom: 0;
    }
  }
  .k-scrollview-wrap .k-scrollview > li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 0px 17px;
    gap: 0px 144px;
    min-height: 601px;
    align-items: center;
    max-width: 100%;
    margin-bottom: 156px;
    min-width: 100%;
    position: relative;
    justify-content: center;
    .arrow {
      display: flex;
      position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translateX(-50%, -50%);
      z-index: 200;
      user-select: none;
      // gap: 0 144px;
      .next-arrow,
      .prev-arrow {
        transition: 0.6s;
        height: 41px;
        width: 41px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .next-arrow:hover,
      .prev-arrow:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      .next-arrow {
        margin-left: 519px;
      }
    }
    .slide {
      display: flex;
      gap: 0 144px;
      align-items: center;
      justify-content: center;
      // overflow: hidden;item-list
    }
    .item {
      display: flex;
      flex-direction: column;
      width: 367px;
      height: 422px;
      max-width: 100%;
      max-height: 100%;
      background-color: #ffffff;
      box-shadow: 0px 0px 15px 6px rgba(0, 0, 0, 0.1);
      padding: 91px 36px 44px 37px;
       
      transition: 0.7s ease;
      z-index: 1;
      overflow: hidden;
      .title {
        h4 {
          line-height: 25px;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #292621;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          overflow-wrap: break-word;
          -webkit-box-orient: vertical;
          color: #006cb5;
          margin-top: 72px;
          margin-bottom: 9px;
           
        }
      }
      .text-item {
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          color: #353535;
          letter-spacing: -0.5px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: justify;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: pre-line;
           
        }
      }
      .image {
        display: flex;
        justify-content: center;
      }
      .btn {
        display: none;
      }
      &.active {
        padding: 91px 86px 40px 85px;
        background: linear-gradient(
          180deg,
          #006cb5 8.33%,
          #0098da 38.54%,
          #00aeef 91.6%
        );
        width: 416px;
        height: 601px;
        max-width: 100%;
        max-height: 100%;
        box-sizing: border-box;
        .post-image {
          filter: brightness(0) invert(1);
        }
        .title {
          h4 {
            color: #ffffff;
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            margin-bottom: 16px;
            margin-top: 88px;
          }
        }
        .text-item {
          overflow: hidden;
          margin-bottom: 81px;
          p {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .btn {
          display: flex;
          justify-content: flex-start;
          padding: 0;
          // margin-top: 81px;
        }
        .btn-detail {
          display: inline-block;
          padding: 12px 41px;
          border: 1px solid #ffffff;
          text-decoration: none;
          color: #ffffff;
          max-width: 159px;
          max-height: 50px;
          text-align: center;
          font-weight: 700;
          font-size: 17px;
          line-height: 26px;
          letter-spacing: -0.5px;
          cursor: pointer;
          transition: 0.6s;
          &:hover {
            opacity: 0.8;
          }
        }
        .post-image {
          max-width: 98px;
          max-height: 122px;
        }
      }
    }
  }

  .banner {
    width: 100% !important;
  }
  .image-name {
    position: absolute;
    bottom: 0;
    //text-align: center;
    width: 100%;
     
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #ffffff;
    padding: 25px 55px 0px 55px;
    height: 115px;
    background-image: url("../../assets/images/slider-title-background.png") !important;
    white-space: pre-line;
    border-radius: 0px 0px 4px 0px;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  kendo-scrollview.k-scrollview-wrap {
    border: none;
  }
  kendo-scrollview-pager {
    display: none;
  }

  .k-scrollview-next,
  .k-scrollview-prev {
    text-shadow: none;
    background: rgba(32, 28, 28, 0.24);
    height: 36px;
    width: 36px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    visibility: hidden;
  }

  .k-scrollview-next {
    right: 2%;
  }
  .k-scrollview-prev {
    left: 2%;
  }

  .k-scrollview-next .k-icon,
  .k-scrollview-prev .k-icon {
    font-size: 36px;
    text-align: center;
  }

  .vertical-dot {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
    border-radius: 2px;
    background-color: rgba(32, 28, 28, 0.1);
    .three-dot-area {
      cursor: pointer;
      padding: 10px 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .three-dot {
      width: 5px;
      height: 20px;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: fit-content;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;
      white-space: nowrap;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: fit-content;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .banner-container:hover .vertical-dot {
    display: flex;
  }

  .router-outlet-content {
    width: 695px !important;
    height: 90%;
  }

  .router-outlet-content-gallery {
    width: 794px !important;
    height: 90%;
  }

  .title-info-req {
    color: #004a9a;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  .input-label {
     
    color: #004a9a;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .employe-detail {
    margin-top: 10px;
  }
  .employe-detail-mb {
    padding: 10px 0px;
    margin-bottom: 18px;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .slider-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 0px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .k-scrollview {
    position: relative;
  }
  @media (max-width: 1024px) {
    .router-outlet-content {
      width: 100% !important;
    }
    .router-outlet-content-gallery {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 992px) {
    .post-more {
      padding: 0 16px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .primary-title {
      font-size: 18px !important;
      line-height: 24px;
    }
    .sub-title {
      font-size: 16px !important;
      line-height: 28px;
    }
    .title {
      h4 {
        font-size: 16px !important;
      }
    }
    .text-item {
      p {
        font-size: 14px !important;
      }
    }
  }
  
  @media screen and (max-width: 610px) {
    .next-arrow {
      margin-left: 460px !important;
    }
  }
  
  @media screen and (max-width: 576px) {
    .arrow {
      display: flex;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      .next-arrow {
        margin-left: 40px !important;
      }
      .next-arrow,
      .prev-arrow {
        background: rgba(0, 0, 0, 0.11);
      }
    }
  }
}

.images-slider:hover {
  .vertical-dot {
    visibility: visible;
  }
  .k-scrollview-next,
  .k-scrollview-prev {
    visibility: visible;
  }
}

