.news-template-28 {
  width: 100%;
  //background-color: white !important;

  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .no-wrap{
    display: flex;
    flex-wrap: nowrap !important;
  }

  .title {
    // height: 48px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // line-clamp: 3;
    // -webkit-box-orient: vertical;
    // font-style: normal;
    // font-weight: 400;
    // font-size: 14px;
    // line-height: 16px;
    // text-align: justify;
    // letter-spacing: -0.01em;
    // color: #1f1f1f;
    // margin-left: 16px;
    text-align: left;
    padding: 22px 0px 22px 15px;
    // background-color: #f1f1f1 !important;
    // border: solid #e6e6e6 2px;
    font-weight: 400;
    font-size: 13px;
     
    color: #FFFFFF;
    width: 70%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-item {
    display: table;
    margin: 10px 0px;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .d-flex {
    visibility: visible !important;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-28-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .web-link{
    box-sizing: border-box;
    background-color: #006CB5;
    display: flex;
  }

  .dropdown-content {
    display: none;
    position:absolute;
    width: calc(100% - 30px);
    background-color: #f6f6f6;
    overflow: auto;
    border: 1px solid #b7b7b7;
    z-index: 1;
    top: 45px;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .drop-menu {
    position: relative;
    // display: inline-block;
    width: 100%;
    padding: 10px 10px 10px 0px;
    justify-content: center;
    display: flex;
  }

  .dropbtn{
    width: 100%;
    min-height: 30px;
    border: none;
    display: flex;
    // background-color: #d0d0d0;
    align-items: center;
    font-size: 13px;
    justify-content: space-between;
    text-align: left;
    background: #fff;
  }

  .drop-item-list {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 210px !important;
  }

  .drop-item-search {
    width: calc(100% - 14px);
    padding: 7px;
    margin: 7px;
  }

  .drop-item {
    min-height: 30px;
    // white-space: nowrap;
    // overflow: hidden !important;
    border: none;
    display: flex;
    background-color: #f6f6f6;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }

  .active {
    background-color: #d0d0d0;
  }

  .show {
    display: block;
    z-index: 1;
  }

}
