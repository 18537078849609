.news-template-29 {
    width: 100%;
    background-color: #FFFFFF !important;

    .row {
      --bs-gutter-x: 0;
    }

    .k-dialog {
      height: auto !important;
      // width: auto !important;
      width: 400px !important;
    }

    .pd-l-10 {
      padding-left: 18px;
    }
    .menu-col{
        padding-right: 10px
    }
    .post-title {
        align-items: center;
         
        position: relative;
        min-height: 40px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;

        color: #EE4623;
        position: relative;

        .nav-scroll {
            position: relative;
            width: fit-content;
            align-items: center;
            // display: flex;
            margin-right: 20px;
            cursor: pointer;
            margin-bottom: 6px;
          }

          .nav-item,
          .nav-item-selected {
            box-sizing: border-box;
            padding: 8px 0px 8px 0px;
            font-weight: 400;
            font-size: 16px;
            border: none;
            background-color: #ffffff;
            text-transform: uppercase;
          }

          .nav-item-selected {
            border: none;
            //border-bottom: 4px solid #007fae;
            font-weight: 700;
            color: #007fae;
          }

          @keyframes mykey {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }
          .nav-scroll-selected::after {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background-color: #007fae;
            left: 0;
            bottom: 0;
            margin-bottom: 15px;
            animation: mykey 0.3s ease-out;
          }
          .menu-bar {
            background-color: #ffffff;
            align-items: center;
            color: #494949;
            text-align: left;
            display: flex;
            overflow: hidden;
            white-space: nowrap;
            flex-wrap: nowrap;
            padding: 0 12px;
            padding-left: 0px;
            padding-top: 14px;
            width: 100%;
            height: 50px;
            margin-bottom: 35px;
          }
          .menu-bar:hover {
            overflow-x: auto;
          }
          .menu-bar::-webkit-scrollbar {
            height: 4px;
          }

          .menu-bar::-webkit-scrollbar-thumb {
            background: #d9d9d9;
          }
          .menu-bar::-webkit-scrollbar-track {
            padding-top: 1px;
            padding-bottom: 1px;
            background: rgb(255, 255, 255);
          }
    }

    .post-more {
        display: flex;
        justify-content: center;
        color: #EB2E35;
         
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            color: #EB2E35;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .icon-clock {
        width: 8px;
        height: 8px;
        margin-top: 5px;
        //margin-left: 7px;
    }
    .icon-arrow{
        width: 16px;
        height: 16px;
        margin-top: -4px;
        margin-left: 8px;
    }

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: justify;
        color: #1F1F1F;
        margin-bottom: 0;
         
    }

    .post-image, .post-image-cover {
        height: 271px;
        width: 100%;
        border-radius: 5px;
    }
    .post-image-cover {
        object-fit: cover;
    }
    .post-right-image{
        height: 271px;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
    }


    .post-image-org {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 40%;
    }

    .time-clock {
        display: flex;
    }

    .time-text {
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #D52929;
        padding-top: 20px;
        margin-bottom: 16px;
    }

    .main-item {
        //display: table;
        margin: 0;
        width: 100%;
    }

    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;
    }

    .d-flex {
        visibility: visible !important;
    }

    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }

    .three-dot-area {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .img-figure, .img-figure-no-img {
        // height: 198px;
        height: 100%;
        // max-width: 372px;
        display: flex;
        //justify-content: center;
        position: relative;
        margin-bottom: 10px;
    }

    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
    .uppercase {
        // margin-top: 47px;
        // margin-bottom: 47px;
        height: 45px;
         
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        // line-height: 45px;
        color: #006CB5;
        text-transform: uppercase;
    }
    .k-scrollview-wrap{
        border: none;
    }

    .close-div {
        display: flex;
        margin-top: 3px;
    }

    .news-template-29-dialog {
        display: flex;
        justify-content: center;
        position: relative;

        .close-icon-div {
            position: absolute;
            right: 10px;
        }

        .close-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .title-info-req {
          // font-size: 20px;
          font-weight: 500;
     
          color: #004A9A;
          // text-align: center;
          // margin: 30px;
        }
    }

    .input-label {
   
        color: #004A9A;
        margin: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 15px;
        text-align: center;
    }

    .btn-delete {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #1c8d43;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }
    .btn-detail {
        margin-top: 26px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: white;
        border: 1px solid #EE4623;
        border-radius: 3px;
    }

    .btn-cancel {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #6c757d;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .flower-img {
        width: 19px;
        height: 19px;
        margin: 10px;
    }

    .first-news-deco {
        position: absolute;
        right: 0px;
        height: 100%;
        width: 49px;
    }

    .text-section {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        :hover {
            color: #00396B
        }
    }

    .title-first {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 0px !important;
    }

    .new-detail {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
        color: #454545;
        height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0px;
    }
    @media (max-width: 1200px) {
        .pd-l-10{
            padding-left: 0px;
        }
    }
    .button-group{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-right{
        padding-top: 25px;
        padding-left: 30px;
    }
    .detail-btn{
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #4D4D4D;
        padding-left: 3px;
    }
    .top-new-first-item {
        margin-bottom: 15px;
        // padding-left: 10px;
        //padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title:hover {
          color: #00396b;
        }
    }
    .top-new-item {
        margin-bottom: 47px;
        // padding-left: 10px;
        //display: flex;
        justify-content: space-between;
        text-decoration: none;
        .title:hover {
          color: #00396b;
        }
    }
    .post-list {
        margin-right: -20px;
        // justify-content: space-between;
        .k-pager-info, .k-pager-sizes {
        display: none;
        }
        .k-pager {
        // padding-left: auto;
        kendo-datapager-prev-buttons {
            margin-left: auto;
        }
        .k-state-selected {
            background-color: #D6A022;
            border: none;
        }
        }
        .top-part {
            display: flex;
            flex-direction: row;
            box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
            margin-right: 30px;
            height: 100%;

        }
    }
    .post-image {
        height: 189px;
        width: 100%;
        object-fit: cover;
      }
      .post-image-default{
        height: 271px;
        width: 100%;
      }
      .first-post-image-default{
        height: 271px;
        width: 100%;
      }

      .post-image-org {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 40%;
      }
    .hide-item{
        display: none;
    }
    .line{
        border: 1px solid #DC7E10;
        margin-top: 5px;
        margin-bottom: 10px;
        width: 100%;
    }
    .title-text{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        // line-height: 23px;
        letter-spacing: -0.02em;
        color: #000000;
        height: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0px;
         
        margin-bottom: 16px;
        min-height: 80px;
    }
    .summary{
        font-weight: 400;
        font-size: 16px;
        // line-height: 20px;
         
        color: #696969;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        // min-height: 100px;
        height: 70px;
    }
    .news-title{
        width: fit-content;
    }
    .left-box{
        margin-right: 20px;
    }
    .right-item-detail{
        padding-left: 31px;
        padding-right: 30px;
        padding-bottom: 32px;
        height: 50%;
    }
    @media (max-width: 768px) {
        .line{
            border: 1px solid #DC7E10;
            margin-top: 5px;
            margin-bottom: 10px;
            min-width: auto;
        }
        .post-list{
            padding-right: 0px;
            padding-left: 0px;
            margin-right: 0px;
            .top-part {
                display: flex;
                flex-direction: column;
            }
        }
        .top-new-item{
            margin-left: 5px;
            padding-right: 10px;
            display: block;
        }.content-right{
            padding-top: 0px;
        }
        .container-fluid{
            margin-left: 5px;
        }

    }
    @media (max-width: 768px) {
        .post-title{
            .arrow-box{
                position: relative;
            }
            .title-right{
                position: relative;
                left: 0;
                top: 0;
                width: 20%;
            }
            .menu-bar{
                width: 90%;
            }
        }

    }
}
