

.quiz-container {

  margin-left: 200px;
  input.k-input {
    width: 100% !important;
    height: 44px !important;
    border-radius: 3px!important;
    // border: 1px solid #C8C8C8 !important;
    // padding-top: 13px!important;
    // padding-bottom: 13px!important;
    padding-left: 19px!important;
  }
  .k-input::placeholder, .k-input::placeholder {
    color: #B9B9B9;
    text-transform: uppercase;
  }
  .k-i-close {
    height: 100%;
  }
  .quiz-title {
    margin-top: 30px;
    margin-bottom: 40px;
     
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;

    color: #404040;
    text-transform:uppercase;
    margin-left: 30px;
  }
  .quiz-wrapper {
    display: flex;
  }

  .quiz-drop{
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px

  }
  .k-combobox {
    border: 1px solid #C8C8C8 !important;
    width: 100%;
    height: 44px;
    border-width: 0;
    box-sizing: border-box;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    text-align: left;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


}

.vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color:white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 130px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.d-flex {
  visibility: visible !important;
}


  .quiz-inputs {
    width: 33%;
    margin-right: 68px;
  }

  .quiz-input-item, .quiz-input-item-captcha {
    margin-bottom: 15px;
    width: 590px;
  }
  .quiz-input-item-captcha{
    display: flex;
  }

  .quiz-textarea-item, .quiz-button-item {
    width: 590px;
    margin-bottom: 26px;
    display: flex;
    flex-wrap: wrap;
    .k-upload{
      display: none;
    }
  }
  .quiz-button-item{
    margin-top: 10px;
  }

  .k-textbox {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #C8C8C8;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 19px;

  }

  .k-textbox::placeholder, .k-textarea::placeholder {
    color: #B9B9B9;
    text-transform: uppercase;
  }

  .k-textarea {
    width: 100%;
    height: 197px;
    resize: none;
    border: 1px solid #C8C8C8;
    padding-left: 19px;
    padding-top: 19px;
  }


  .quiz-button-wrapper{
    display: flex;
    justify-content: flex-end;
  }

  .quiz-form-button {
    min-width: 169px;
    font-size: 18px;
    padding: 8px;
    border: none;
    background-color: #00396B;
    color: #FFFFFF;
    border-radius: 3px;
    text-transform: uppercase;
  }

  .quiz-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  .quiz-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    margin-right: 13px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #D6A022;

  }

  .quiz-info {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #5E5E5E;

  }

  .quiz-error {
    color:#d51923;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    margin-top: 4px;
  }
  .send-background{
    background: #F9F9F9;
    margin-top: 20px;
  }

  .k-tabstrip-items {
    background-color: #027DC3;
    justify-content: normal;
  }
  .k-tabstrip-items .k-item{
    color: white;
    border: none;
  }
  .k-tabstrip-items .k-item.k-state-active{
    color: white;
    background-color: #03598A;
    border: none;
  }
  .k-tabstrip-items .k-link{
    padding: 14px 26px;
  }
  .k-tabstrip > .k-content{
    padding: 0;
  }
  .form-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .quiz-form{
    padding-left: 15px;
  }
  .data-title{
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #414141;

    display: flex;
    align-content: center;
    align-items: center;
    height: 40px;
  }
  .required{
    color: #EE2323;
    padding-left: 3px;
  }

  .add-image-text{
     
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #EE4623;
    height: 130px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .image-upload, .add-image{
    width: 200px;
    height: 130px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .add-image{
    border: 1px dashed #EE4623;
  }
  .captcha-item{
    background-color: white;
    height: 40px;
    width: 222px;
    margin-left: 40px;
    display: flex;
    justify-content: center;
  }
  .reload-captcha-btn{
    width: 40px;
    height: 40px;
    object-fit: cover;
    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .reload-icon{
    height: 16px;
    width: 16px;
  }
  .quiz-submit-button{
    min-width: 169px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 8px;
    border: none;
    background: #EE4623;
    color: #FFFFFF;
    border-radius: 3px;
  }
  .quiz-reset-button{
    min-width: 169px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 8px;
    border: 1px solid #BEBEBE;
    background: #FFFFFF;
    color: #727272;
    border-radius: 3px;
    margin-left: 20px;
  }
  .k-content.k-state-active{
    //display: flex;
    border: none;
    overflow: hidden;
  }
  .categories-box{
    width: 100%;
    height: 140px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(107, 144, 200, 0.84);
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .image-categories{
    width: 100%;
    height: 140px;
    object-fit: cover;
  }
  .categories-text{
    position: absolute;
    text-align: center;
     
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0 20px;
  }
  .categories-area{
    padding-left: 20px;
  }
  .header-area, .footer-area{
    padding: 0 !important;
  }
  .wrapper-area-flex{
    display: flex;
    justify-content: center;
  }
  .wrapper{
    width: 1140px;
  }

  @media screen and (max-width: 600px) {
    .quiz-form{
      padding: 0 15px;
    }
    .form-row{
      display: block;
    }
    .quiz-input-item{
      width: 100%;
    }
    .quiz-textarea-item{
      width: 100%;
      display: block;
    }
    .quiz-input-item-captcha{
      display: block;
    }
    .captcha-item{
      margin-left: 0;
      margin-top: 10px;
    }
    .reload-captcha-btn{
      margin-left: 0;
      margin-top: 10px;
    }
    .quiz-button-item{
      flex-direction: column;
      width: 100%;
    }
    .quiz-reset-button{
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
