.submit-document {
  font-weight: 550;

  .k-pager-numbers .k-link {
    color: black;
    border-width: 1px;
    margin: 4px;
  }

  .page-name {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .k-pager-wrap {
    border: none;
    color: #424242;
    background-color: transparent;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .k-pager-numbers .k-link.k-state-selected {
    background-color: #c60000;
    color: white !important;
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black !important;
    font-size: 30px !important;
  }

  .title-info-req {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }



  .k-pager-nav {
    border: solid 1px;
    margin: 4px;
  }


  .is-pointer {
    cursor: pointer;
  }

  .is-pointer:hover {
    color: #06c !important;
  }

  .document-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-dialog {
    border-radius: 7px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 10px;
    float: right;
  }

  .btn-dialog-wrapper {
    border-width: 0 !important;
    width: 100%;
    margin-top: 10px;
  }

  .btn-close-success {
    color: rgb(0, 0, 0);
    background-color: #d4d4d4;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    font-weight: bold;
  }

  @media (max-width: 770px) {

    .x-dialog-register-document,
    .x-dialog-register-document-3,
    .x-dialog-register-document-2,
    .x-dialog-register-document-1,
    .x-dialog-register-document-0 {
      max-height: 100% !important;
      max-width: 100% !important;
    }

    .x-dialog-success {
      max-width: 100% !important;
      max-height: 280px !important;
      top: 0% !important;
    }

    .btn-dialog-wrapper {
      margin-top: 5px !important;
      padding-left: 0px !important;
    }
  }
}
