@import url("https://fonts.googleapis.com/css2?family=Myriad Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.news-template-27 {
  width: 100%;
  background-color: #ffffff !important;
  margin-bottom: 30px;

  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    // width: auto !important;
    width: 400px !important;
  }

  .pd-l-10 {
    padding-left: 18px;
  }

  .post-title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 37px;
    color: #006CB5;
    position: relative;
    min-height: 40px;
    justify-content: center;

    padding: 40px 25px 43px;
    //color: #006cb5;
    text-transform: uppercase;
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
  .title-blue {
    font-style: normal;
    position: relative;
    color: #006cb5;
    text-transform: uppercase;
  }

  .sub-post-title {

    font-size: 20px;
    line-height: 33px;
    font-weight: 400;
    display: flex;
    margin-top: 31px;
    margin-bottom: 66px;
    justify-content: center;
    text-align: center;
    p {
      margin: 0;
      max-width: 815px;
    }
  }

  .post-more {
    display: flex;
    justify-content: center;
    color: #eb2e35;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;

    a {
      color: #eb2e35;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 8px;
    height: 8px;
    margin-top: 5px;
    //margin-left: 7px;
  }

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    color: #1f1f1f;
    margin-left: 16px;
    margin-bottom: 0;

  }

  .post-image,
  .post-image-cover {
    height: 60px !important;
    width: 60px !important;
  }
  .post-image-cover {
    object-fit: contain;
    margin-top: 6px;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
    margin: 0;
  }

  .item-a {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 16;
  }

  .vertical-dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color: white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .d-flex {
    visibility: visible !important;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .menu {
    // border: 2px solid black;
    // background-color: #fffcfc;
    background-color: white;
    // color: #0079ff;
    // border-radius: 5px;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // border-bottom: 2px solid black;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      // background-color: #d8d5d5;
      // color: #686868;
      // border-bottom: 2px;
      // border-radius: 0 0 2px 2px;
      background-color: white;
      color: black;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

      text-align: center;
    }

    // .menu-row-current-border {
    //   border-radius: 2px 2px 0 0;
    // }

    .menu-item-3 {
      padding: 5px 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img {
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
  }

  // .img-figure-no-img{
  //     border: #afa6a6 dashed 2px;
  // }
  .uppercase {
    color: #ffffff;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .column {
    margin: 110px 0;
  }
  .post-detail-mb {
    // box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    padding: 20px 28px 20px 28px;
    border-radius: 5px;
    border: 1px solid #2868ae;
  }
  .top-new-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 20px;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .news-template-27-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      // font-size: 20px;
      font-weight: 500;

      color: #004a9a;
      // text-align: center;
      // margin: 30px;
    }
  }

  .input-label {

    color: #004a9a;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;

    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;

  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;

    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;

  }

  .flower-img {
    width: 19px;
    height: 19px;
    margin: 10px;
  }

  .first-news-deco {
    position: absolute;
    right: 0px;
    height: 100%;
    width: 49px;
  }

  .text-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    :hover {
      color: #00396b;
    }
  }
  .post-infor {
    margin-left: 16px;
  }
  .title-first {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    color: #2868ae;

    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 7px;
    // height: 63px;
  }

  .new-detail {
    font-style: normal;
    font-size: 16px;
    // line-height: 30px;
    color: #a0a0a0;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 5;
    // line-clamp: 5;
    // -webkit-box-orient: vertical;
    margin-bottom: 0px;

    margin-right: 11px;
  }
  @media (max-width: 1200px) {
    .pd-l-10 {
      padding-left: 0px;
    }
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .post-row {
    margin-top: 50px;
  }
  .dl-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-gap: 48px 32px;
    grid-gap: 31px;
    // margin-top: 44px;
    margin-bottom: 10px;
    place-items: center;
  }
  .post-detail {
    // box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    border: 1px solid #2868ae;
    padding: 20px 25px 25px;
    width: 100%;
    // min-height: 263px;
    max-height: 263px;
    max-width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    .section-box {
      display: flex;
    }
  }
  .view-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08659a;
  }
  .line {
    border: 1px solid #dc7e10;
    margin: 10px 70px;
  }
  .info-box{
    display: flex;
  }
  .text-box{
    margin-left: 20px;
  }
  @media(max-width: 768px){
    .post-title{
      padding: 0px !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .dl-3 {
      grid-template-columns: repeat(2, 1fr) !important;
    }
    .post-detail {
      max-width: unset !important;
    }
  }

  @media screen and (max-width: 768px) {
    .img-figure,
    .img-figure-no-img {
      margin-bottom: 16px;
    }

    .column {
      margin: 10px 0px !important;
    }
    .title-first {
      font-size: 16px !important;
    }

    .new-detail {
      font-size: 14px !important;
      color: #666161 !important;
    }
    .post-title {
      font-size: 18px !important;
      p {
        margin-bottom: 28px;
      }
    }

    .sub-post-title {
      font-size: 16px !important;
      margin-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 576px) {
    .column {
      margin: 10px 0 !important;
    }
    .top-new-item {
      grid-template-columns: repeat(1, 1fr) !important;
      grid-gap: 20px 0px !important;
    }
  }
}

