.data-menu {

  .err-text,
  .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
  }

  .err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
  }

  @keyframes myerr {
    0% {
      border-color: #ea4335;
    }

    25% {
      border-color: yellow;
    }

    50% {
      border-color: blue;
    }

    100% {
      border-color: #34a853;
    }
  }

  .filter {
    border-bottom: solid 1px #ebebeb;
    box-sizing: border-box;
    .k-textbox {
      border: none;
    }
    .k-input-inner::placeholder {
 
      font-size: 16px;
      font-weight: 300;
      color:#808080;
    }
    position: relative;
  }

  .img3 {
    margin-left: 10px;
    margin-right: 10px;
    z-index: 999;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 12.5px;
  }

  .img4 {
    position: absolute !important;
    top: 5px !important;
  }

  .img5 {
    cursor: pointer;
  }


  .control-search .btn-remove {
    width: 29px;
    height: 29px;
    right: 38px;
    float: right;
    border-radius: 0;
    margin-top: -30px;
    border: 0;
  }

  .control-search input {
    float: left;
    padding-left: 45px;
    height: 45px;
  }

  .control-search {
    position: relative;
  }

  .control-search .k-button {
    z-index: 999;
    position: absolute;
    top: 35px;
    right: 0px;
  }

  .control-search .btn-search {
    position: relative;
    width: 38px;
    height: 29px;
    float: left;
    border-radius: 0;
    margin-top: -30px;
    border: 0;
    background-color: #985d1a !important;
  }

  .xbutton-dialog {
    padding: 0;
    /* border-radius: 50%;
      opacity: .8; */
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 5px;
    top: 5px;
    color: black !important;
    /* background-color: #bdbdbd !important; */
    font-size: 12px !important;
  }

  .x-dialog {
    max-width: 768px !important;
    max-height: 75% !important;
  }

  // .custom-btn-addNew {
  //   margin-top: 10px !important;
  // }

  .btn-addNew {
    height: 102px;
    width: calc(25% - 16px);
    position: fixed;
    background-color: white;
    bottom: 0px;
    right: 9px;
    padding: 15px;
    text-align: right;
    display: inherit;
    box-sizing: border-box;
    // margin-right: 15px;
    // margin-bottom: 5px;
  }

  .btn-addNew .k-button {
    border-radius: 7px;
    background: #df7242;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pop-up-title {
    font-size: 20px;
    font-weight: 500;
     
    color: #004A9A;
    text-align: center;
    margin: 30px;
  }

  .input-label {
     
    margin: 10px;
    margin-left: 15px;
  }

  .input-box {
    height: 40px;
    margin: 15px;
    margin-top: 10px;
    width: calc(100% - 30px);
    border: solid 1px #e9e9e9;
    border-radius: 0px;
    background-color: white;
  }

  .btn-save {
    margin: 15px;
    height: 41px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
  }

  .btn-custom {
    width: 100%;
  }

  .btn-custom button {
    background: none;
    color: gray;
    width: 100%;

  }

  .wb-data-menu {
    display: none;
  }

  .mb-data-menu {
    display: contents !important;
  }

  .mb-data-menu .item-menu {
     
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0px 0px 12px !important;
    margin: 7px 0;
    padding: 12px;
    padding-right: 15px;
    border: 0px solid #c4c4c4;
    border-radius: -2px;
    width: 100%;
  }

  .mb-data-menu .active{
    color:#4a4a4a;
  }

  .mb-data-menu .passive{
    color: #b9b9b9;
  }

  .mb-data-menu .mb-drbtn-func {
    padding-right: 5px;
    .k-item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 34px;
    }
  }

  .mb-data-menu .btn-custom button {
    background: none;
    color: gray;
    width: 100%;
    border: none;
  }

  .k-window {
    width: 500px;
  }

  .x-dialog {
    max-width: 500px;
    max-height: none;
  }

  .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .selected-lang {
    border-bottom: 3px red solid;
    cursor: pointer;
  }

  @media (max-width: 386px) {
    .btn-addNew {
      text-align: right;
      display: inherit;
      margin-right: 15px;
      margin-bottom: 5px;
    }

    .k-window {
      width: 90%;
      height: 90%;
    }

    .x-dialog {
      max-width: 500px;
      max-height: none;
    }
  }

  @media (max-width: 386px) {
    .btn-addNew {
      text-align: right;
      display: inherit;
      margin-right: 15px;
      margin-bottom: 5px;
    }

    .wb-data-menu {
      display: none;
    }

    .mb-data-menu {
      display: contents !important;
    }

    .mb-data-menu .item-menu {
 
      font-size: 16px;
      font-weight: 500;
      color:#4a4a4a;
      margin: 10px 15px 10px 15px !important;
      margin: 7px;
      padding: 5px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      width: 100%;
    }

    .mb-data-menu .mb-drbtn-func {
      padding-right: 5px;
      .k-item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 34px;
      }
    }

    .mb-data-menu .btn-custom button {
      background: none;
      color: gray;
      width: 100%;
      border: none;
    }

    .k-dropdown-button button {
      background: none !important;
      color: black !important;
      border: none !important;
    }

    .x-dialog.data-menu-info {
      width: 100% !important;
    }
  }

  .mb-data-menu {
    display: contents;
  }

  .mb-data-menu .k-list {
    width: 105px;
  }

  .check-box {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    margin-right: 5px;
  }

  .col-10 {
    flex: 0 0 auto !important;
    width: 80.33333333% !important;
  }

  .k-tabstrip {
    border-width: 0;
    border-color: transparent;
    box-sizing: border-box;
    outline: 0;
    font-family: inherit;
    font-size: 16px !important;
    line-height: 1.4285714286;
    color: inherit;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .router-outlet-content {
    width: 1024px;
    width: 100%;
    // height: 685px !important;

    max-width: 1024px;
    margin: auto;
  }

  .k-dropdown .k-dropdown-wrap,
  .k-dropdowntree .k-dropdown-wrap {
    background-clip: padding-box;
    border-radius: 2px;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    transition: all .1s ease;
    cursor: pointer;
    outline: 0;
    color: #656565;
    background-color: white;
    // background-image: linear-gradient(#f6f6f6, #f1f1f1);
    border: 1px solid rgba(0, 0, 0, .08);
  }

  .k-button .k-icon,
  .k-button .k-image,
  .k-button .k-sprite {
    color: inherit;
    align-self: center;
    position: relative;
  }

  // .k-animation-container {
  //   left: 1989.78px !important;
  // }
  .control-search .btn-remove {
    background-color: transparent !important;
    right: 40px;
    color: #000 !important;
    width: 30px;
    height: 32px;
    float: right;
    border-radius: 0;
    margin-top: 2px !important;
    border: 0;
    margin-right: -38px;
  }

  .k-button .k-icon,
  .k-button .k-image,
  .k-button .k-sprite {
    color: inherit;
    align-self: center;
    position: relative;
  }

  .k-icon {
    width: 1em;
    height: 1em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-family: WebComponentsIcons;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
  }

  .k-button-outline.k-primary,
  .k-primary.k-button.k-outline {
    border-color: currentColor;
    color: black !important;
    background: none;
    box-shadow: none;
  }

  .k-tabstrip-top>.k-content,
  .k-tabstrip-top>.k-tabstrip-content {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-width: 1px !important;
    height: 700px;
  }

}

.cdk-drag-preview {
  .item-menu-drag {
    display: flex;
    flex-wrap: nowrap;
     
    font-size: 16px;
    font-weight: 500;
    color:#4a4a4a;
    // margin: 5px 11px -6px 13px !important;
    margin: 7px;
    // padding: 15px 15px 15px 0;
    // border: 1px solid #c4c4c4;
    border-radius: -2px;
    width: 100%;
  }
  .btn-custom button {
    background: none;
    color: gray;
    width: 100%;
    border: none;
  }
}