.news-template-31 {
    width: 100%;
    background-color: #FFFFFF !important;

    .row {
      --bs-gutter-x: 0;
    }

    .k-dialog {
      height: auto !important;
      // width: auto !important;
      width: 400px !important;
    }

    .pd-l-10 {
      padding-left: 18px;
    }

    .post-title {
        display: flex;
        align-items: center;
        color: #00396b;
         
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
        // margin-bottom: 15px;
        position: relative;
        min-height: 40px;
    }

    .post-more {
        display: flex;
        justify-content: center;
        color: #EB2E35;
         
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        background-color: white;
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            color: #EB2E35;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .icon-clock {
        width: 8px;
        height: 8px;
        margin-top: 5px;
        //margin-left: 7px;
    }
    a:link{
        text-decoration: none;  
    }
    .title {
         
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        // line-height: 25px;
        color: #2B2B2B;
        // margin-left: 20px;
        margin-bottom: 0;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }


    .post-image, .post-image-cover {
        // height: 198px;
        // height: 129px;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        margin-right: 20px;
    }
    .post-image-cover {
        object-fit: cover;
    }

    .post-image-first, .post-image-cover-first {
        // height: 198px;
        // height: 369px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    .post-image-cover-first {
        object-fit: cover;
    }

    .post-image-org {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 40%;
    }

    .time-clock {
        display: flex;
    }

    .time-text {
         
        color: #797979;
        // margin-left: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 2rem;
        // line-height: 30px;
        // letter-spacing: -0.02EM;
    }
    .time-text-first{
         
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        // line-height: 30px;
        // letter-spacing: -0.02em;
        color: #797979;
        margin-bottom: 7px !important;
    }

    .main-item {
        display: table;
        margin: 0;
    }

    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;
    }

    .d-flex {
        visibility: visible !important;
    }

    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }

    .three-dot-area {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }

    .img-figure, .img-figure-no-img {
        // height: 198px;
        height: 100%;
        // max-width: 372px;
        display: flex;
        //justify-content: center;
        position: relative;
        margin-bottom: 10px;
    }

    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
    .uppercase {
        color: #006cb5;
        margin-top: 30px;
        
        margin-right: 10px;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
         
        line-height: 46px;
    }

    .top-new-item-first {
        // margin: 15px 0 0 0;
    }
    .top-new-item {
        // margin: 15px 0 0 0;
        padding-left: 20px;
    }

    .close-div {
        display: flex;
        margin-top: 3px;
    }

    .news-template-31-dialog {
        display: flex;
        justify-content: center;
        position: relative;

        .close-icon-div {
            position: absolute;
            right: 10px;
        }

        .close-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .title-info-req {
          // font-size: 20px;
          font-weight: 500;
     
          color: #004A9A;
          // text-align: center;
          // margin: 30px;
        }
    }

    .input-label {
   
        color: #004A9A;
        margin: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 15px;
        text-align: center;
    }

    .btn-delete {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #1c8d43;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .btn-cancel {
        margin: 15px;
        width: 100px;
        height: 40px;
   
        text-align: center;
        border-radius: 5px;
        background-color: #6c757d;
        border: hidden;
        color: white;
        font-size: 14px;
   
    }

    .flower-img {
        width: 19px;
        height: 19px;
        margin: 10px;
    }

    .first-news-deco {
        position: absolute;
        right: 0px;
        height: 100%;
        width: 49px;
    }

    .text-section {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        :hover {
            color: #00396B
        }
    }

    .title-first {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #2B2B2B;
        margin-bottom: 0px !important;
        text-decoration: none;
         
    }

    .new-detail {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        //  line-height: 30px;
        // letter-spacing: -0.02em;
        color: #313131;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0px;
         
    }
    .desktop-width {
        // width: 125vw;
        position: relative;
        // left: calc(-63vw + 50%);
        // background: #F9F9F9;
        padding-bottom: 20px;
    }
    .content-width-media {
        //width: calc(100% - 250px);
        // width: calc(75% - 220px);
        // margin: auto;
        width: calc(62vw - 28px);
        margin: auto;
    }
    @media (max-width: 1200px) {
        .pd-l-10{
            padding-left: 0px;
        }
    }
    .button-group{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 768px){
        .content-width-media {
            width: calc(100%);
            margin: auto;
        }
        .text-section{
            display: block;
        }
        .title{
            margin-top: 30px;
            margin-left: 0px;
        }
        .time-text{
            margin-left:0px;
            margin-top: 20px;
        }
        .desktop-width{
            width: auto !important;
            left: 0px !important;
        }
    }
}
