.settings-container {
   
  display: flex;
  margin-left: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
  min-height: 512px;

  .setting-navbar {
    width: 251px;
    height: 125px;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-right: 30px;
  }

  .setting-item {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-left: 28px;
    cursor: pointer;
  }

  .setting-item-title {
    margin-top: 3px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4b4b4b;
  }
  @media screen and (max-width: 992px) {
    .setting-navbar {
      width: 100%;
      max-width: 759px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    app-change-password {
      width: 100%;
      max-width: 759px;
      .change-password-container {
        width: 100%;
        max-width: 759px;
      }
    }

    app-support {
      width: 100%;
      max-width: 759px;
      .support-container {
        width: 100%;
        max-width: 759px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .settings-container {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
  }
}
