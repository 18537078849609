header {
    padding: 0;
    margin: 0;
    position: relative;
    .desktop {
        display: block !important;
    }
    .mobile {
        display: none !important;
    }
    nav ul {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
        list-style-type: none;
        width: var(--outlet-content-width);
    }
    .nav-menu-box {
        background-color: var(--main-color);
        width: 100%;
        display: inline-flex;
        position: relative;
        z-index: 999;
        margin: auto;
        .nav-content {
            width: var(--outlet-content-width);
            margin: auto;
            position: relative;
            display: inline-flex;
        }
        .home-icon {
            margin-top: 5px;
        }
        .home-icon,
        .menu-icon {
            color: white;
            cursor: pointer;
        }
    }
    .menu-icon-box {
        display: none;
    }
    .nav-menu {
        width: 100%;
        height: auto;
        font-size: 95%;
        display: flex;
        justify-content: center;
        .dropdown {
            margin: auto;
            height: 100%;
            text-align: center;
        }
        .dropdown-home {
            margin: auto;
        }
        .play-arrow {
            color: #656565;
            font-size: 12px;
            vertical-align: middle;
            position: absolute;
            right: 0;
            padding-top: 6px;
        }
        .item-link {
            color: white;
            text-decoration: none;
            display: inline-table;
            height: 100%;
            width: 100%;
            padding: 7px;
        }
        .dropdown:hover>.dropdown-menu {
            display: block;
        }


        .dropdown-item:hover {
            background-color: #dddddd;
        }
        .dropdown-submenu:hover {
            background-color: #dddddd;
        }
        .dropdown-item {
            font-size: 95%;
            margin-right: 20px;
            padding: 0.6rem 1rem;
        }
        .dropdown:hover {
            background-color: #133d6c;
        }
        .dropdown-menu {
            left: 0;
            padding: 0;
            border-radius: 0;
        }
        .dropdown-submenu:hover>.dropdown-menu {
            display: block;
        }
        .dropdown-submenu {
            position: relative;
            border-top: 1px solid #d8d8d8;
        }
        .dropdown-menu :first-child {
            border-top: none;
        }
        .dropdown-submenu>.dropdown-menu {
            top: 0;
            margin-top: 5px;
            left: 100%;
        }
    }
    .disable-click {
        pointer-events: none;
        cursor: default;
    }
    .banner {
        width: var(--outlet-content-width);
        height: 100px;
        margin: auto;
        position: relative;
        .img1 {
            max-width: 100%;
            height: 80%;
            z-index: 5;
            position: absolute;
            object-fit: cover;
            top: 10px;
            bottom: 10px;
            cursor: pointer;
        }
        .img2 {
            width: 550px;
            height: 550px;
            top: -227px;
            left: -221px;
            z-index: 2;
            position: absolute;
            object-fit: cover;
            opacity: 0.3;
            clip: rect(210px, 575px, 328px, 219px);
            cursor: default;
        }
        .search {
            border-radius: 25px;
            border: 1px solid #9f9da0;
            position: absolute;
            right: 10px;
            top: 51px;
            padding-left: 10px;
            font-size: 13px;
            height: 25px;
            z-index: 998;
            width: 200px;
        }
        .search:focus {
            border: 1px solid #f3efef !important;
        }
        .search-icon {
            color: #9a9a9a;
            position: absolute;
            right: 15px;
            top: 52px;
            z-index: 999;
            cursor: pointer;
        }
        .search-text:focus {
            border: 1px solid white !important;
        }
        .banner-link {
            cursor: pointer;
            z-index: 997;
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .banner-background {
            cursor: default;
            z-index: 1;
            position: absolute;
            background-color: white;
            width: 100%;
            height: 100%;
        }
        .language {
            font-size: 13px;
            position: absolute;
            right: 15px;
            top: 15px;
            display: inline;
            z-index: 999;
            color: #363842 !important;
            border: none !important;
            cursor: pointer;
        }
        .language-text {
            color: var(--main-color);
            text-decoration: none;
            font-size: 15px;
            font-weight: bold;
        }
        .color-text-selected {
            color: #363842;
            cursor: default;
        }
        .name {
            position: absolute;
            z-index: 6;
            left: 108px;
            top: 20px;
            font-size: 18px;
       
            font-weight: 900;
            text-shadow: 0px 6px 10px #a2a2a2;
        }
        .name-main {
            color: red;
        }
        .name-detail {
            color: var(--main-color);
            margin-top: 4px;
        }
    }
    .open-menu {
        display: block !important;
    }
    .close-menu {
        display: none !important;
    }
}

@media screen and (max-width: 576px) {
    header {
        .desktop,
        .desktop-icon {
            display: none !important;
        }
        .mobile {
            display: block !important;
        }
        nav ul {
            width: auto;
        }
        .banner {
            width: 100% !important;
            height: 100px;
            .img1 {
                max-width: 100%;
                height: 50px;
                top: 6px;
                left: 50%;
                position: absolute;
                margin-left: -2em;
            }
            .img2 {
                width: 114px;
                height: 114px;
                top: 0;
                left: 50%;
                object-fit: cover;
                clip: auto;
                position: absolute;
                margin-left: -3.4em;
                opacity: 0.2;
            }
            .language {
                top: 12px;
                right: 70px;
                width: 25px;
                height: 25px;
                border-radius: 34px;
                background-color: var(--main-color);
                padding-top: 0.82%;
                color: white !important;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
            }
            .search {
                border-radius: 0;
                right: 0;
                top: 116px;
                font-size: 17px;
                height: 36px;
                z-index: 998;
                width: 100%;
                padding-left: 10px;
                border: none;
            }
            .search-icon {
                top: 8px;
                font-size: 34px;
                right: 30px;
            }
            .name {
                left: 0;
                top: 54px;
                font-size: 14px;
                text-align: center;
                width: 100%;
                text-align: center;
            }
            .name-detail {
                margin-top: 0;
            }
        }
        .nav-menu-box {
            height: 1px !important;
        }
        .nav-menu {
            width: 84%;
            height: 100%;
            display: block;
            justify-content: center;
            font-size: 95% !important;
            .dropdown,
            .item-link {
                display: block;
                text-align: left;
            }
            .dropdown-submenu:hover {
                background-color: var(--main-color);
            }
            .dropdown-submenu {
                border-top: 1px solid #4569cf;
                width: 100%;
            }
            .dropdown {
                padding-left: 0;
                height: auto;
                width: 100%;
                z-index: 9989;
                height: auto !important;
                padding: 0;
                font-weight: bold;
            }
            .dropdown+.dropdown {
                border-top: 1px solid #4569cf;
            }
            .dropdown-submenu>.dropdown-menu {
                left: 0px;
                margin-top: 0;
            }
            .dropdown:hover {
                background-color: var(--main-color);
            }
            .dropdown .dropdown-menu {
                z-index: 9990;
                margin-top: 0px;
            }
            .dropdown-menu .dropdown-submenu {
                z-index: 9991;
            }
            .dropdown-menu {
                display: none;
                width: 100%;
                border-top: 1px solid #4569cf;
            }
            .button-expand {
                color: white;
                margin-top: 5px;
                position: absolute;
                right: 0;
                font-size: 17px;
                font-weight: normal;
                top: 8px;
                width: auto;
            }
            .dropdown-item:hover {
                background-color: var(--main-color);
            }
        }
        .dropdown-menu,
        .dropdown-submenu {
            position: relative !important;
            background-color: var(--main-color);
            color: white;
            border: none;
            left: 0;
            margin: 0;
            padding: 0;
            width: 100%;
        }
        .pading-0 {
            padding: 10px 0 !important;
        }
        .pading-1 {
            padding: 10px 0 !important;
            padding-left: 15px !important;
        }
        .pading-2 {
            padding: 10px 0 !important;
            padding-left: 25px !important;
        }
        .pading-3 {
            padding: 10px 0 !important;
            padding-left: 35px !important;
        }
        .pading-4 {
            padding: 10px 0 !important;
            padding-left: 45px !important;
        }
        .dropdown-item {
            color: white;
            padding: 5px 0px;
            font-weight: bold;
        }
        .home-icon {
            z-index: 9980 !important;
            font-size: 25px;
            margin-top: 0 !important;
        }
        .menu-icon {
            font-size: 30px;
            margin-left: 4px;
        }
        .nav-menu-box {
            position: relative;
            background-color: #b0bbf6;
        }
        .menu-icon-box {
            display: inline-block !important;
            position: absolute;
            margin-top: 1px !important;
            width: 40px;
            height: 30px;
            z-index: 999 !important;
            border-radius: 5px;
            color: #8a8a8a;
            top: 7px;
            left: 15px;
        }
        .close-button-box {
            text-align: end;
            // padding-right: 15px;
            padding-top: 10px;
        }
        .close-button {
            color: #ff8659;
            // font-size: 30px;
            font-weight: bold;
        }
        .navbar-display {
            margin-top: -135px;
            margin-left: 0%;
            width: 70%;
            background: var(--main-color);
            height: 4000px !important;
            position: absolute;
            z-index: 998 !important;
            padding: 0px 15px;
            box-shadow: -5px 5px 5px #878794;
        }
        .search-cover-shadow,
        .navbar-cover-shadow {
            margin-top: -135px;
            width: 100%;
            background: var(--main-color);
            height: 4000px;
            position: absolute;
            z-index: 997 !important;
            background-color: #969494;
            opacity: 0.4;
        }
        .search-cover-shadow {
            margin-top: 145px;
            background-color: #363636;
            opacity: 0.5;
        }
        .navbar-hidden {
            display: none;
        }
        #navbardropdownMenuLink {
            text-align: left !important;
        }
    }
}

@media screen and (max-width: 1025px) {
    .header {
        .nav-menu {
            font-size: 85%;
        }
    }
}
