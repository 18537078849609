.change-password-container {
  width: 759px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-top: 25px;
  padding-left: 30px;

  .change-password-form-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #1f1f1f;
  }

  .change-password-form {
    margin-top: 30px;
  }

  .input-item {
    position: relative;
    margin-bottom: 32px;
  }

  .input-item-label {
    position: absolute;
    top: -6px;
    left: 22px;
    padding: 0 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    background-color: #ffffff;
    color: #1f1f1f;
    z-index: 1;
  }

  .k-textbox {
    width: 55%;
    height: 54px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    padding: 24px 22px;
  }

  .change-password-error {
    color: #f44336;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin-top: 4px;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 109px;
    margin-bottom: 40px;
    margin-right: 17px;
  }

  .form-button {
    min-width: 120px;
    font-size: 16px;
    padding: 13px 47px;
    margin-left: 32px;
    border: none;
    background-color: #23bd20;
    color: #ffffff;
    border-radius: 3px;
    font-weight: 600;
  }

  .quit-button {
    color: #616161;
    border: 1px solid #b0b0b0;
    background-color: #ffffff;
  }
  @media screen and (max-width: 992px) {
    .form-button {
      font-size: 12px;
      padding: 13px 26px;
    }
  }
}
