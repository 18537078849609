.most-view {
  margin-left: 15px;

  figure {
    margin: 0 !important
  }

  .margin {
    margin-bottom: 8rem !important;
  }

  .title-view {
    margin-top: 0px;
    // background-color: RGB(25, 79, 139);
    background-color: white;
    color: #353535;
    width: 100%;

    // padding: 7px 15px 7px 15px;
    //margin-bottom: 18px;
    h5 {
      font-size: 17px !important;
      margin-bottom: 0px;
    }
  }

  .header {
    font-weight: bold;
     
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 46px;
    /* identical to box height, or 209% */
    color: #006CB5;
    margin-bottom: -7px;
    margin-top: 10px;
  }

  .img-first {
    //width: 466px;
    //min-height: 200px;
    object-fit: cover;
    margin-top: 10px;
  }

  .img-next {
    // width: 219px;
    // height: 152px;
    object-fit: cover;
    max-height: 152px;

    // width: 200px;
    img.img-next {
      width: 100%;
      height: 100% !important;
    }
  }
  .img-first {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    max-height: 152px;
  }

  .post-fra {
    cursor: pointer;
    overflow: hidden;

    .view-img {
      overflow: hidden;
      position: relative;
      margin-right: auto !important;
      padding-right: 0px !important;
      height: 100%;

      img {
        font-size: 10px;
        margin-right: 0px !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .most-view-time {
      color: #7D7D7D;
      font-weight: 400;
      font-size: 16px;
      margin-left: 15px;
    }

    .caption-view {
      // margin: 15px 15px;
      //margin-left: inherit;
      //display: flex;
      font-size: 18px;
      font-weight: bolder;
      text-decoration: none;
      margin-top: 24px;
      color: #0b68a4;
      overflow: hidden;

      //  color: black;
      figcaption:hover {
        color: #0b68a4 !important;
      }

      .caption-first {
         
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        // line-height: 30px;
        margin: 24px 0px;
        color: #2B2B2B;
      }
    }

    .caption-view-next {
      display: flex;
      font-size: 18px;
      font-weight: bolder;
      text-decoration: none;
      margin-top: 24px;
      margin-bottom: 24px;
      color: #0b68a4;
      overflow: hidden;
      width: 100%;

      //  color: black;
      figcaption:hover {
        color: #0b68a4 !important;
      }

      .caption-next {
         
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: #4F4F4F;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        max-height: calc(1.2rem * 3);
        line-height: 1.2rem;
      }
    }
  }

  hr {
    //   color: 2px solid rgb(25, 0, 255);
    border-top: 1px solid #e5e5e5;
    border-top-style: groove;
    // color: white;
    display: block !important;
  }

  .content-box {
    border-top: 1px dashed #C5C5C5;
    padding: 0;
    margin: 0px 15px;
    //width: fit-content;
  }

  .text-no-post {
     
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    color: #000000 !important;
  }
}

@media screen and (max-width: 770px) {

  .most-view {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0;

    .post-fra {
      .view-img {
        overflow: hidden;
        position: relative;
        margin-right: auto !important;
        padding-right: 0px !important;
        height: 100%;

        img {
          font-size: 10px;
          margin-right: 0px !important;
          width: 100%;
          max-height: 100%;
        }
      }
    }

    .caption-next {
      margin-top: 20px;
    }

    .img-next {
      height: 152px !important;
    }

    .img-first {
      height: 152px !important;
    }
  }
}