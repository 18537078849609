.image-slider-a {
  position: relative;
  margin-bottom: 10px;
  min-height: 445px;
  .img-scrollview {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  kendo-scrollview.k-scrollview-wrap {
      border: none;
  }

  .k-scrollview-next, .k-scrollview-prev {
      text-shadow: none;
      background: rgba(32, 28, 28, 0.24);
      height: 97px;
      width: 47px;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  }

  .k-scrollview-next .k-icon, .k-scrollview-prev .k-icon {
      font-size: 30px;
      text-align: center;
  }

  .k-scrollview-pageable {
    .k-button {
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0);
      border: white solid 1px;
      height: 10px !important;
      width: 10px !important;
      margin: 3px;
    }
    .k-button::before {
      height: 10px !important;
      width: 10px !important;
    }
    .k-primary {
      background-color: white;
      border: white;
    }
  }

  .statistic {
    width: 250px;
    height: 350px;
    background-color:  rgba(255, 255, 255, .5);
    z-index: 10;
    margin-top: -375px;
    right: 50px;
    position: absolute;
    padding: 15px;
    .stat-box {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      .stat-box-title {
        background-color: #d8a64e;
        color: white;
        font-weight: bold;
        text-align: center;
        height: 30px;
        line-height: 30px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
      .stat-box-info {
        height: calc(100%-106px);
        width: 100%;
        position: relative;
        .stat-description {
          padding: 15px;
          padding-left: 25px;
          padding-right: 25px;
          text-align: center;
          color: #535353;
          font-size: 14px;
        }
        .stat-number {
          text-align: center;
          font-weight: bold;
          font-size: 66px;
          line-height: 60px;
          color: #007fae;
        }
        .stat-unit {
          padding: 15px;
          text-align: center;
          color: #535353;
          font-size: 14px;
        }
      }
      .hotline {
        bottom: 0;
        padding: 15px;
        box-sizing: border-box;
        position:absolute;
        border-top: solid 2px #f2f2f2;
        width: 100%;
        .hotline-title {
          color: #808080;
          font-weight: bold;
          font-size: 14px;
        }
        .hotline-number {
          color: red;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;

      .three-dot-area {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .menu {
        // border: 2px solid black;
        // background-color: #fffcfc;
        background-color: white;
        // color: #0079ff;
        // border-radius: 5px;
        cursor: pointer;
        width: 130px;
        top: 43%;
        left: 100%;
        position: absolute;
        z-index: 1;

        .menu-row,
        .menu-row-current,
        .menu-row-current-border {
          cursor: pointer;

          .menu-item-1,
          .menu-item-2 {
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            // border-bottom: 2px solid black;
          }
        }

        .menu-row-current,
        .menu-row-current-border {
          // background-color: #d8d5d5;
          // color: #686868;
          // border-bottom: 2px;
          // border-radius: 0 0 2px 2px;
          background-color: white;
          color: black;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
     
          text-align: center;
        }

        // .menu-row-current-border {
        //   border-radius: 2px 2px 0 0;
        // }

        .menu-item-3 {
          padding: 5px 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
  }

  .banner {
      width: 100%;
  }

  .title-info-req {
      font-weight: bold;
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  // .k-content {
  //     width: 900px;
  //     min-height: 600px;
  // }

  // .employe-detail {
  //     padding: 10px;
  // }

  .close-div {
      display: flex;
      margin-top: 3px;
  }

  .slider-dialog {
      display: flex;
      justify-content: center;
      position: relative;
      padding: 10px 0px;
      margin-bottom: 18px;

      .close-icon-div {
          position: absolute;
          right: 10px;
      }

      .close-icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
      }
  }
}

.image-slider-a:hover {
  .vertical-dot {
    visibility: visible;
  }
}
