.working-schedule {
   

  .title {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #474747;
  }

  .k-header {
    background-color: #00396b;
    border-color: #ffffff;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    padding: 14px;
    text-align: center;
  }

  .k-grid {
    border: 1px solid #afafaf;
    border-bottom-width: 0;
  }

  .k-grid td {
    vertical-align: top;
    border-color: #afafaf;
    border-bottom-width: 1px;
    background-color: #ffffff;
    padding-top: 30px;
  }

  .schedule-table {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }

  .working-container {
    padding-left: 15px;
    padding-right: 18px;
    height: 100%;
    min-height: 47px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #494949;
  }

  .working {
    margin-bottom: 13px;
  }
  .working:last-child {
    margin-bottom: 62px;
  }

  .day-container {
    padding-left: 6px;
    padding-right: 2px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #494949;
  }
}
