.horizontal-timeline{
     .timeline-wrap {
    /* margin: 10% 10%; */
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
    /* top: 100; */
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

 .timeline {
    height: 5px;
    width: 106%;
    margin-left: -3%;
    background-color: #fdecb5;
    position: relative;
}

 .marker {
    z-index: 1000;
    color: transparent;
    width: 30px;
    height: 30px;
    line-height: 10px;
    /* font-size: 1em; */
    text-align: center;
    position: absolute;
    background-color: red;
    border: 7px solid #FF9F55;
    border-radius: 50%;
}

 .style-char {
    color: black;
    font-size: 12px;
    width: 63px;
}

.marker:hover {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.timeline-icon.two {
    background-color: rgb(255, 0, 0) !important;
}

.timeline-icon.one {
    background-color: #ffffff !important;
}

 .mfirst {
    top: -11px;
    left: -3%;
}

 .m2 {
    top: -11px;
    left: 32.5%
}

 .m3 {
    top: -11px;
    left: 66%;
}

 .mlast {
    top: -11px;
    left: 100%
}

@media screen and (max-width: 770px) {
    max-width: 100%;
    margin-left: -10px !important;
    margin-right: 50px  !important;
    // .timeline {
    //     height: 5px;
    //     width: 95%;
    //     margin-left: -3%;
    //     background-color: #fdecb5;
    //     position: relative;
    // }
    // .mfirst {
    //     top: -11px;
    //     left: -3%;
    // }
    
    //  .m2 {
    //     top: -11px;
    //     left: 27.5%
    // }
    
    //  .m3 {
    //     top: -11px;
    //     left: 58%;
    // }
    
    //  .mlast {
    //     top: -11px;
    //     left: 84%
    // }
}
}