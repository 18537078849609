@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Library download */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/video.js/dist/video-js.min.css";
/* Import font */
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
/* Source code */
@import "../node_modules/video.js/dist/video-js.min.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~roboto-fontface/css/roboto/roboto-fontface.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Myriad Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "@fancyapps/ui/dist/fancybox.css";
body {
  height: 100vh;
}

.title-info-req {
  text-align: center;
  font-size: 24px;
  color: black;
}

.control label {
  color: #656565;
}

.control {
  margin-bottom: 7px;
}

.control > * {
  width: 100%;
}

.control label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 14px;
}

.control .text {
  padding-left: 7px;
  color: #0067b2;
  font-size: 17px;
}

.btn-send {
  color: white;
  background-color: #d5bd46;
  border: hidden !important;
  border-radius: 0px;
  float: left;
  padding: 5px 16px;
  margin-top: 0px !important;
  font-size: 14px !important;
}

.control label {
  color: #656565;
}

.control-search input {
  float: left;
}

.control-search .btn-search {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
  background-color: #985d1a !important;
}

.control-search .btn-search-advance {
  width: 35px;
  height: 34px;
  float: right;
  right: 35px;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
}

.control-search .btn-check {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
}

.control-search .btn-remove-replace-btn-search {
  right: 0 !important;
}

.k-panelbar > .k-item > .k-link {
  color: white !important;
  background-color: #194f8b !important;
  height: 30px;
}

.k-panelbar > .k-item > .k-link.k-state-selected {
  color: #194f8b;
  background-color: #194f8b;
}

.k-panelbar .k-content {
  padding: 7px;
  background: #F1F1F1;
}

.k-panelbar .k-content .func {
  margin-top: 15px;
}

.k-button-icontext span {
  padding-left: 2px;
}

.k-dialog {
  width: 80%;
  height: 80%;
}
.k-dialog .k-content {
  width: 100%;
  height: 100%;
}

.in-meeting .k-tabstrip-items .k-item {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
  color: #1667b0;
  margin-right: 5px;
  border: 2px solid;
}

.k-list .k-item:focus,
.k-list .k-item.k-state-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-state-focused {
  box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.13) !important;
}

.k-tabstrip-top > .k-tabstrip-items .k-item {
  border-color: #EEEEEE;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}

.k-panelbar > .k-item > .k-link.k-state-selected:hover,
.k-panelbar > .k-item > .k-link.k-state-selected.k-state-hover {
  background-color: #e7e7e7;
  color: #194f8b;
}

.btn-dialog {
  border-radius: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  float: right;
}

.btn-regist-cv {
  color: white;
  background-color: #194f8b;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

.btn-close-success {
  color: black;
  background-color: #d4d4d4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  font-weight: bold;
}

.btn-pay-success {
  color: white;
  background-color: #e85d3b;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  font-weight: bold;
}

.btn-scanning-img {
  color: white;
  background-color: #616161;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  cursor: no-drop !important;
}

.btn-dialog-wrapper {
  border-width: 0 !important;
  width: 100%;
  margin-top: 10px;
}

.k-window {
  overflow: auto;
}

.x-dialog {
  width: 100%;
  height: 100%;
}

.window-center {
  left: 0 !important;
  right: 0;
  top: 0 !important;
  bottom: 0;
  margin: auto;
}

.x-dialog-register-document {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-3 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-2 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-1 {
  max-width: 750px !important;
  max-height: 92% !important;
}

.x-dialog-register-document-0 {
  max-width: 750px !important;
  max-height: 82% !important;
}

.x-dialog-success {
  max-width: 700px !important;
  max-height: 180px !important;
  top: -40% !important;
}

.x-dialog-payment {
  max-width: 700px !important;
  max-height: 500px !important;
  margin-top: 50px !important;
}

.x-dialog-payment-0 {
  max-width: 700px !important;
  max-height: 370px !important;
  margin-top: 50px !important;
}

@media (max-width: 768px) {
  .k-dialog {
    width: 90% !important;
  }

  .x-dialog-register-document,
.x-dialog-register-document-3,
.x-dialog-register-document-2,
.x-dialog-register-document-1,
.x-dialog-register-document-0 {
    max-height: 100% !important;
    max-width: 100% !important;
  }

  .x-dialog-success {
    max-width: 100% !important;
    max-height: 280px !important;
    top: 0% !important;
  }

  .btn-dialog-wrapper {
    margin-top: 5px !important;
    padding-left: 0px !important;
  }

  .x-dialog-payment,
.x-dialog-payment-0 {
    max-width: 100% !important;
    max-height: 600px !important;
    top: 0% !important;
  }

  .x-dialog {
    width: 95% !important;
    height: 95% !important;
  }

  .dialog-info {
    width: 100% !important;
    height: 100% !important;
  }

  .hidden-overlay .ng-trigger-overlayAppear {
    display: none;
  }

  .k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
    background-clip: padding-box;
    border-radius: 2px;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    transition: all 0.1s ease;
    cursor: pointer;
    outline: 0;
    color: #656565;
    background-color: #f6f6f6;
    background-image: linear-gradient(#f6f6f6, #f1f1f1);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
}
.main-page {
  margin: 0 auto;
  height: 100%;
}
.main-page .nav-title {
  width: 100%;
  background-color: #F5F5F5;
  height: 70px !important;
  margin: top;
  /* position: relative; */
  /* margin-top: 15px; */
  z-index: 999;
}
.main-page .uppercase-black {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #EE4623;
  padding-left: 74px;
  margin-bottom: 10px;
  /* vertical-align: middle; */
  margin-top: 10px;
  /* height: 50px; */
  line-height: 70px;
  text-transform: uppercase;
}
.main-page .row1 {
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.main-page .image-slide-home {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.main-page .image-slider-title {
  padding-top: 20px;
}
.main-page .news-template-1 {
  padding: 7px 0px;
}
.main-page .news-template-1-without-time {
  padding: 7px 0px;
}
.main-page .news-template-2 {
  padding: 7px 0px;
}
.main-page .news-template-3 {
  padding: 7px 0px;
  margin-left: 20px;
}
.main-page .news-template-4 {
  padding: 20px 0px 0px 20px;
}
.main-page .news-template-5 {
  padding: 7px 0px;
}
.main-page .news-template-6 {
  padding: 7px 0px;
  margin-left: 20px;
}
.main-page .news-template-a {
  padding: 7px 0px;
}
.main-page .news-template-b {
  padding: 20px 0px 0px 0px;
  margin-left: 20px;
}
.main-page .news-template-c {
  padding: 20px 0px 0px 0px;
  margin-left: 20px;
}
.main-page .news-template-d {
  padding: 7px 0px;
}
.main-page .news-template-11 {
  padding: 7px 0px;
}
.main-page .news-template-21 {
  padding-top: 20px;
}
.main-page .news-template-34 {
  padding: 20px 0px;
}
.main-page .router-box {
  width: 100%;
}
.main-page .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.main-page .pointer {
  cursor: pointer;
}
.main-page .mobile {
  display: none;
}
.main-page .desktop {
  display: block;
}
.main-page .category-detail {
  margin-top: 15px;
  padding-right: 20px !important;
}
.main-page .banner-right-side {
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.main-page .banner-right-side img {
  width: 100%;
  height: auto;
  font-size: 10px;
  object-fit: contain;
}
.main-page .banner-right-side figure {
  margin: 0;
  text-align: left;
}
.main-page .top-news-box {
  padding-right: 12px !important;
}
.main-page .container-box {
  padding-right: 0px !important;
  padding-left: 12px !important;
}
.main-page .news-other-box {
  border-top: 1px solid #e5e5e5;
  border-top-style: solid;
  padding: 10px 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.main-page .news-other-box .title-child-other {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
}
.main-page .category-news-other-box {
  border-top: 1px solid #e5e5e5;
  border-top-style: solid;
  padding: 15px 0;
  height: 100px;
  overflow-y: hidden;
}
.main-page .banner-right-side-box {
  padding: 0 12px 0 24px !important;
}
.main-page .banner-right-side-box .row {
  --bs-gutter-x: 0;
}
.main-page .banner-right-side-box-fit {
  box-shadow: 0px 0px 8px -3px #323232;
}
.main-page .top-news-main .banner-main {
  width: 100%;
  height: 300px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.main-page .top-news-main .banner-main .image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  position: absolute;
  font-size: 10px;
}
.main-page .top-news-main .banner-main .title-main {
  font-size: 20px;
  z-index: 800;
  color: white;
  text-align: left;
  padding: 0 10px 0 25px;
  cursor: pointer;
  font-weight: 500;
  bottom: 30px;
  position: absolute;
  line-height: 24px;
  text-shadow: 0px 2px 11px #000000;
}
.main-page .top-news-main .banner-main .title-main:hover {
  color: #00c3ff;
  text-shadow: 0px 2px 11px #1400cc;
}
.main-page .disable-click {
  pointer-events: none;
  cursor: default;
}
.main-page .top-news-child {
  cursor: pointer;
  margin-top: 24px;
}
.main-page .top-news-child .banner-child {
  width: 100%;
  height: 143px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .top-news-child .banner-child .image {
  width: 100%;
  height: 143px;
  object-fit: cover;
  font-size: 10px;
}
.main-page .category-news {
  cursor: pointer;
}
.main-page .category-news .banner-child {
  width: 100%;
  height: 141px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .category-news .banner-child .image {
  width: 100%;
  height: 141px;
  object-fit: cover;
  font-size: 10px;
}
.main-page .category .row {
  --bs-gutter-x: 0;
}
.main-page .category-news-container {
  cursor: pointer;
  height: 238px;
}
.main-page .category-news-container .banner-child {
  width: 100%;
  height: 139px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .category-news-container .banner-child .image {
  width: 100%;
  height: 139px;
  object-fit: cover;
  font-size: 10px;
}
.main-page .container-news {
  cursor: pointer;
}
.main-page .container-news .banner-child {
  width: 100%;
  height: 141px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .container-news .banner-child .image {
  width: 100%;
  height: 141px;
  object-fit: cover;
  font-size: 10px;
}
.main-page .category-news-other {
  cursor: pointer;
}
.main-page .category-news-other .banner-child-other {
  height: 70px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .category-news-other .banner-child-other .image {
  height: 70px;
  width: 100%;
  object-fit: cover;
  font-size: 10px;
}
.main-page .container-news-other {
  cursor: pointer;
}
.main-page .container-news-other .banner-child-other {
  height: 70px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.main-page .container-news-other .banner-child-other .image {
  height: 70px;
  width: 100%;
  object-fit: cover;
  font-size: 10px;
}
.main-page .container-fluid {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-page .main-fluid {
  width: 1140px;
}
.main-page .layout-box {
  margin-left: auto;
  margin-right: auto;
}
.main-page .layout-box .news-template-31 .content-width-media {
  width: calc(84vw - 28px);
}
.main-page .bottom-news {
  margin-top: 10px;
}
.main-page .title-child-other {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
  margin-top: -3px;
}
.main-page .title-child-other:hover {
  color: #06c;
}
.main-page .title-child-other:hover .time {
  color: #06c;
}
.main-page .title-child {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
}
.main-page .title-child:hover {
  color: #06c;
}
.main-page .title-child:hover .time {
  color: #06c;
}
.main-page .category-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--main-color);
  border-bottom: 3px solid var(--main-color);
  text-transform: uppercase;
}
.main-page .time {
  color: #c5b3b3;
  font-size: 11px;
  font-weight: normal;
}
.main-page .body-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% - 250px);
  min-width: 80%;
  max-width: 1550px;
  margin-top: 10px;
}
.main-page .main-row {
  align-items: center;
  --bs-gutter-x: 0;
  height: 100%;
  align-content: space-between;
}
.main-page .body-item-gray {
  width: 1140px;
  background-color: #F5F5F5;
}
.main-page .body-box {
  height: 100%;
}

@media screen and (max-width: 576px) {
  .main-page {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .main-page .banner-right-side-box {
    padding-top: 20px !important;
    padding-bottom: 10px !important;
  }
  .main-page .news-template-3 {
    padding: 20px 0px 10px 0px;
  }
  .main-page .news-template-4 {
    padding: 10px 0px 10px 0px;
  }
  .main-page .news-template-b {
    padding: 10px 0px 10px 0px;
  }
  .main-page .news-template-c {
    padding: 10px 0px 10px 0px;
  }
  .main-page .body-list {
    width: calc(100%);
  }
  .main-page .top-news-main {
    padding: 0 0 10px 0;
  }
  .main-page .top-news-main .banner-main {
    background-size: 100% 100%;
    height: 200px;
  }
  .main-page .top-news-main .banner-main .image {
    height: 200px;
  }
  .main-page .mobile {
    display: block !important;
  }
  .main-page .desktop {
    display: none !important;
  }
  .main-page .category-detail {
    margin-top: 0;
  }
  .main-page .category {
    margin-top: 5px;
  }
  .main-page .category section:nth-child(2) {
    border-top: none;
  }
  .main-page .category-news-other-box {
    border-top: 1px solid #e5e5e5;
    border-top-style: solid;
  }
  .main-page .top-news-child {
    border-top: 1px solid #d8d8d8;
    padding: 10px 0;
    margin-top: 0;
  }
  .main-page .top-news-child .banner-child {
    display: none;
  }
  .main-page .time {
    display: none;
  }
  .main-page .banner-right-side-box {
    width: 100%;
  }
  .main-page .banner-right-side figure {
    text-align: center;
  }
  .main-page .top-news-box {
    border: none;
  }
  .main-page .news-other-box {
    border: none;
  }
  .main-page .category-news-container {
    padding: 0;
    margin-top: 5px 0;
    height: 275px;
    overflow: hidden;
  }
  .main-page .category-news-container .banner-child {
    height: 200px;
  }
  .main-page .category-news-container .banner-child .image {
    height: 200px;
  }
  .main-page .category-news-container .title-child {
    margin-top: 13px;
  }
  .main-page .container-news {
    display: none;
  }
  .main-page .container-news {
    display: block;
    border-top: 11px solid #d8d8d8;
    padding: 20px 0 10px 0;
  }
  .main-page .container-news .banner-child {
    width: 100%;
    height: 67px;
    margin: auto;
    overflow: hidden;
    position: relative;
  }
  .main-page .container-news .banner-child .image {
    width: 100%;
    height: 67px;
    object-fit: cover;
    font-size: 10px;
  }
  .main-page .container-news .title-child {
    font-size: 15px !important;
    padding: 0;
    margin-top: -5px;
    margin-left: 20px;
  }
  .main-page .container-box {
    border: none;
  }
  .main-page .category-news-other .banner-child-other {
    height: 67px;
  }
  .main-page .category-news-other .banner-child-other .image {
    height: 67px;
  }
  .main-page .container-news-other .banner-child-other {
    height: 68px;
  }
  .main-page .container-news-other .banner-child-other .image {
    height: 68px;
  }
  .main-page .title-child-other {
    font-size: 15px !important;
    padding: 0 5px 0 5px;
    margin-top: -5px;
    margin-left: 10px;
  }
  .main-page .title-main {
    font-size: 17px !important;
    margin-bottom: 0;
    margin-top: 20px;
    position: relative;
    font-weight: 500;
  }
  .main-page .title-child {
    font-size: 17px !important;
    padding: 0;
    margin-top: 3px;
  }
  .main-page .time {
    font-size: 12px !important;
    padding-left: 12px;
  }
  .main-page .category-title {
    font-size: 19px !important;
    font-weight: 500;
    border-bottom: none;
    border-left: 4px solid var(--main-color);
    margin-left: 0;
    padding-left: 15px;
    margin-top: 15px;
  }
  .main-page .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .main-page .container-fluid {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .main-page .container-fluid [class*=col-] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .main-page .layout-box {
    position: relative;
    align-items: flex-start;
  }
  .main-page .layout-list {
    width: 100%;
    overflow: hidden;
    display: block;
    z-index: 100;
  }
  .main-page .body-box {
    position: relative;
    width: 1140px;
  }
  .main-page .body-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
@media screen and (max-width: 800px) and (min-width: 577px) {
  .main-page .banner-right-side-box {
    padding: 12px !important;
  }
  .main-page .top-news-box {
    padding-left: 12px !important;
  }
  .main-page .category-title {
    font-size: 15px;
  }
  .main-page .banner-right-side img {
    max-height: 100px;
  }
  .main-page .banner-right-side-box-fit {
    box-shadow: none;
  }
  .main-page .banner-right-side img {
    object-fit: cover;
  }
  .main-page .category {
    margin-left: 0;
  }
  .main-page .container-box {
    padding-right: 12px !important;
  }
  .main-page .body-list {
    width: calc(100%);
  }
}
@media screen and (max-width: 1025px) and (min-width: 801px) {
  .main-page .top-news-box {
    padding-left: 12px !important;
  }
  .main-page .top-news-box .body-list {
    width: calc(100% - 50px);
  }
  .main-page .banner-right-side-box {
    padding: 12px 10px 12px 12px !important;
  }
  .main-page .category-news-container {
    height: 280px;
  }
  .main-page .category-news-container .banner-child {
    height: 200px;
  }
  .main-page .category-news-container .banner-child .image {
    height: 200px;
  }
  .main-page .banner-right-side img {
    max-height: 100px;
  }
  .main-page .top-news-main .banner-main {
    height: 394px;
  }
  .main-page .top-news-main .banner-main .image {
    height: 394px;
  }
  .main-page .top-news-child .banner-child {
    height: 190px;
  }
  .main-page .top-news-child .banner-child .image {
    height: 190px;
  }
  .main-page .category-news-container .banner-child {
    height: 190px;
  }
  .main-page .category-news-container .banner-child .image {
    height: 190px;
  }
  .main-page .container-news .banner-child {
    height: 192px;
  }
  .main-page .container-news .banner-child .image {
    height: 192px;
  }
  .main-page .category-news-other .banner-child-other {
    height: 92px;
  }
  .main-page .category-news-other .banner-child-other .image {
    height: 92px;
  }
  .main-page .container-news-other .banner-child-other {
    height: 93px;
  }
  .main-page .container-news-other .banner-child-other .image {
    height: 93px;
  }
  .main-page .banner-right-side-box-fit {
    box-shadow: none;
  }
  .main-page .banner-right-side img {
    object-fit: cover;
  }
  .main-page .category {
    margin-left: 0;
  }
  .main-page .container-box {
    padding-right: 12px !important;
  }
}
app-main-page {
  position: relative;
  top: -6px;
}

header {
  padding: 0;
  margin: 0;
  position: relative;
}
header .desktop {
  display: block !important;
}
header .mobile {
  display: none !important;
}
header nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
  width: var(--outlet-content-width);
}
header .nav-menu-box {
  background-color: var(--main-color);
  width: 100%;
  display: inline-flex;
  position: relative;
  z-index: 999;
  margin: auto;
}
header .nav-menu-box .nav-content {
  width: var(--outlet-content-width);
  margin: auto;
  position: relative;
  display: inline-flex;
}
header .nav-menu-box .home-icon {
  margin-top: 5px;
}
header .nav-menu-box .home-icon,
header .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header .menu-icon-box {
  display: none;
}
header .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: center;
}
header .nav-menu .dropdown {
  margin: auto;
  height: 100%;
  text-align: center;
}
header .nav-menu .dropdown-home {
  margin: auto;
}
header .nav-menu .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header .nav-menu .item-link {
  color: white;
  text-decoration: none;
  display: inline-table;
  height: 100%;
  width: 100%;
  padding: 7px;
}
header .nav-menu .dropdown:hover > .dropdown-menu {
  display: block;
}
header .nav-menu .dropdown-item:hover {
  background-color: #dddddd;
}
header .nav-menu .dropdown-submenu:hover {
  background-color: #dddddd;
}
header .nav-menu .dropdown-item {
  font-size: 95%;
  margin-right: 20px;
  padding: 0.6rem 1rem;
}
header .nav-menu .dropdown:hover {
  background-color: #133d6c;
}
header .nav-menu .dropdown-menu {
  left: 0;
  padding: 0;
  border-radius: 0;
}
header .nav-menu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
header .nav-menu .dropdown-submenu {
  position: relative;
  border-top: 1px solid #d8d8d8;
}
header .nav-menu .dropdown-menu :first-child {
  border-top: none;
}
header .nav-menu .dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: 5px;
  left: 100%;
}
header .disable-click {
  pointer-events: none;
  cursor: default;
}
header .banner {
  width: var(--outlet-content-width);
  height: 100px;
  margin: auto;
  position: relative;
}
header .banner .img1 {
  max-width: 100%;
  height: 80%;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  top: 10px;
  bottom: 10px;
  cursor: pointer;
}
header .banner .img2 {
  width: 550px;
  height: 550px;
  top: -227px;
  left: -221px;
  z-index: 2;
  position: absolute;
  object-fit: cover;
  opacity: 0.3;
  clip: rect(210px, 575px, 328px, 219px);
  cursor: default;
}
header .banner .search {
  border-radius: 25px;
  border: 1px solid #9f9da0;
  position: absolute;
  right: 10px;
  top: 51px;
  padding-left: 10px;
  font-size: 13px;
  height: 25px;
  z-index: 998;
  width: 200px;
}
header .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header .banner .search-text:focus {
  border: 1px solid white !important;
}
header .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header .banner .language {
  font-size: 13px;
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline;
  z-index: 999;
  color: #363842 !important;
  border: none !important;
  cursor: pointer;
}
header .banner .language-text {
  color: var(--main-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
header .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header .banner .name {
  position: absolute;
  z-index: 6;
  left: 108px;
  top: 20px;
  font-size: 18px;
  font-weight: 900;
  text-shadow: 0px 6px 10px #a2a2a2;
}
header .banner .name-main {
  color: red;
}
header .banner .name-detail {
  color: var(--main-color);
  margin-top: 4px;
}
header .open-menu {
  display: block !important;
}
header .close-menu {
  display: none !important;
}

@media screen and (max-width: 576px) {
  header .desktop,
header .desktop-icon {
    display: none !important;
  }
  header .mobile {
    display: block !important;
  }
  header nav ul {
    width: auto;
  }
  header .banner {
    width: 100% !important;
    height: 100px;
  }
  header .banner .img1 {
    max-width: 100%;
    height: 50px;
    top: 6px;
    left: 50%;
    position: absolute;
    margin-left: -2em;
  }
  header .banner .img2 {
    width: 114px;
    height: 114px;
    top: 0;
    left: 50%;
    object-fit: cover;
    clip: auto;
    position: absolute;
    margin-left: -3.4em;
    opacity: 0.2;
  }
  header .banner .language {
    top: 12px;
    right: 70px;
    width: 25px;
    height: 25px;
    border-radius: 34px;
    background-color: var(--main-color);
    padding-top: 0.82%;
    color: white !important;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  header .banner .search {
    border-radius: 0;
    right: 0;
    top: 116px;
    font-size: 17px;
    height: 36px;
    z-index: 998;
    width: 100%;
    padding-left: 10px;
    border: none;
  }
  header .banner .search-icon {
    top: 8px;
    font-size: 34px;
    right: 30px;
  }
  header .banner .name {
    left: 0;
    top: 54px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    text-align: center;
  }
  header .banner .name-detail {
    margin-top: 0;
  }
  header .nav-menu-box {
    height: 1px !important;
  }
  header .nav-menu {
    width: 84%;
    height: 100%;
    display: block;
    justify-content: center;
    font-size: 95% !important;
  }
  header .nav-menu .dropdown,
header .nav-menu .item-link {
    display: block;
    text-align: left;
  }
  header .nav-menu .dropdown-submenu:hover {
    background-color: var(--main-color);
  }
  header .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
  }
  header .nav-menu .dropdown-menu {
    display: none;
    width: 100%;
    border-top: 1px solid #4569cf;
  }
  header .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header .dropdown-menu,
header .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header .pading-0 {
    padding: 10px 0 !important;
  }
  header .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header .dropdown-item {
    color: white;
    padding: 5px 0px;
    font-weight: bold;
  }
  header .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header .nav-menu-box {
    position: relative;
    background-color: #b0bbf6;
  }
  header .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header .navbar-display {
    margin-top: -135px;
    margin-left: 0%;
    width: 70%;
    background: var(--main-color);
    height: 4000px !important;
    position: absolute;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header .search-cover-shadow,
header .navbar-cover-shadow {
    margin-top: -135px;
    width: 100%;
    background: var(--main-color);
    height: 4000px;
    position: absolute;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header .navbar-hidden {
    display: none;
  }
  header #navbardropdownMenuLink {
    text-align: left !important;
  }
}
@media screen and (max-width: 1025px) {
  .header .nav-menu {
    font-size: 85%;
  }
}
footer.ver1 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  background-color: #0B68A4;
}
footer.ver1 .mobile {
  display: none;
}
footer.ver1 .desktop {
  display: block;
}
footer.ver1 .footer {
  width: var(--outlet-content-width);
  height: 240px;
  color: white;
  margin: auto;
  padding: 20px 0 10px 0;
}
footer.ver1 .footer .row {
  display: flex;
  flex-direction: row;
}
footer.ver1 .footer span.title {
  display: inline !important;
  width: fit-content;
  border-bottom: 2px solid #E4C97E;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  padding-bottom: 10px;
}
footer.ver1 .footer ul {
  padding: 10px 12px;
  margin-top: 10px;
  margin-bottom: 0;
  list-style-type: square;
}
footer.ver1 .footer .info ul {
  list-style-type: none;
  padding: 10px 0;
}
footer.ver1 .footer .menu ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 13em;
  margin-top: 0;
  padding: 0;
}
footer.ver1 .footer .menu li {
  width: 50%;
}
footer.ver1 .footer li {
  padding-bottom: 15px;
}
footer.ver1 .footer li:hover .btn-func {
  visibility: visible;
}
footer.ver1 .footer .info:hover .btn-func {
  visibility: visible;
}
footer.ver1 .footer li a {
  color: white;
  font-weight: 500;
  text-decoration: none;
}
footer.ver1 .footer .btn-func {
  visibility: hidden;
}
footer.ver1 .footer .btn-custom {
  width: fit-content;
  background: initial;
  color: white;
}
footer.ver1 .footer .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: white;
  border: none;
}
footer.ver1 .footer .btn-custom .k-button {
  padding: 0 !important;
}
footer.ver1 .footer .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
footer.ver1 .footer .k-popup .k-item {
  width: 100%;
}
footer.ver1 .footer .handle:hover {
  cursor: pointer;
}
footer.ver1 .footer .btn-add {
  background-color: inherit;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 2.5em;
}
footer.ver1 .footer .btn-add:hover {
  background-color: #074973;
}
footer.ver1 .copyright {
  background-color: #074973;
  width: 100%;
  color: white;
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 0;
}
footer.ver1 .copyright div {
  margin: auto;
  width: var(--outlet-content-width);
}
footer.ver1 .edit .required,
footer.ver1 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
footer.ver1 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
footer.ver1 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
footer.ver1 .btn-save {
  color: white;
  background-color: #0B68A4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}

@media screen and (max-width: 576px) {
  footer {
    width: 100% !important;
  }
  footer .mobile {
    display: block;
  }
  footer .desktop {
    display: none;
  }
  footer .btn-add {
    width: 25% !important;
  }
}
@media screen and (max-width: 800px) {
  footer {
    width: 100% !important;
  }
  footer .add-menu.x-dialog {
    width: 95% !important;
    height: 95% !important;
  }
}
.search-result .container-fluid {
  padding-left: var(--bs-gutter-x, -0.25rem);
}
.search-result tbody,
.search-result td,
.search-result tfoot,
.search-result th,
.search-result thead,
.search-result tr {
  border-color: #cacaca;
  border-width: 1px;
}
.search-result table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.search-result .subject {
  margin-top: 14px;
}
.search-result .group-header {
  padding: 0px 0px 0px 10px;
  border-left: 5px solid var(--main-color);
  font-weight: bolder;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 1.8;
  margin-top: 5px;
  margin-bottom: 10px;
}
.search-result hr {
  display: none;
}
.search-result .title-post {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 24px;
}
.search-result .mb {
  display: none;
}
.search-result .wb a {
  text-decoration: none;
  color: #212529;
}
.search-result .k-pager-numbers .k-link {
  color: black;
  border-width: 1px;
  margin: 4px;
}
.search-result .k-pager-wrap {
  border: none;
  color: #424242;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.search-result .k-pager-numbers .k-link.k-state-selected {
  background-color: #c60000;
  color: white !important;
}
.search-result .k-pager-nav {
  border: solid 1px;
  margin: 4px;
}
.search-result .post-part {
  height: 180px;
  cursor: pointer;
  overflow: hidden;
}
.search-result .post-part .img-view {
  overflow: hidden;
  position: relative;
}
.search-result .post-part .img-view .image {
  width: 95%;
  height: 150px;
  display: inline-block;
  object-fit: cover;
}
.search-result .post-part .img-view .video-view .video {
  position: relative !important;
  width: 95%;
  height: 150px;
}
.search-result .post-part .img-view .video-view .img-err {
  position: relative;
  width: 95%;
  height: 150px;
}
.search-result .post-part .img-view .video-view .play-button {
  top: 47% !important;
  left: 47% !important;
}
.search-result .post-part .content-view {
  margin-left: inherit;
  font-size: 14px;
  font-weight: bolder;
  text-decoration: none;
  overflow: hidden;
}
.search-result .post-part .content-view .post-date {
  font-size: 10px;
  color: gray;
  font-weight: normal !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
.search-result .post-part .content-view .post-summary {
  font-size: 12px;
  color: black;
  font-weight: normal !important;
  height: 105px;
  overflow: hidden;
}
.search-result .post-part .content-view .post-title {
  font-size: 16px;
  font-weight: 700;
}
.search-result .post-part .content-view .title-first {
  padding-left: 15px;
  font-size: 22px;
}
.search-result .post-part .content-view:hover .post-title, .search-result .post-part .content-view:hover .post-date, .search-result .post-part .content-view:hover .post-summary {
  color: #06c !important;
}
@media screen and (max-width: 770px) {
  .search-result {
    padding-right: 15px;
    padding-left: 15px;
  }
  .search-result .box {
    padding-right: 15px;
  }
  .search-result .wb {
    display: none;
  }
  .search-result .mb {
    display: block;
  }
  .search-result .mb a {
    text-decoration: none;
    color: #212529;
  }
  .search-result .mb-second {
    padding-top: unset;
    margin-bottom: -20px;
  }
  .search-result .mb-first {
    margin-bottom: 15px;
  }
  .search-result hr {
    border-top: 2px solid #1900ff;
    border-top-style: dotted;
    color: white;
    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .search-result .post-part {
    height: auto;
  }
  .search-result .post-part .img-view {
    overflow: hidden;
    position: relative;
    height: 98px;
  }
  .search-result .post-part .img-view .image {
    width: 100%;
    height: 98px;
    display: inline-block;
    object-fit: cover;
  }
  .search-result .post-part .img-view .video-view .video {
    position: relative !important;
    width: 100%;
    height: 98px;
  }
  .search-result .post-part .img-view .video-view .mask-video {
    width: 100%;
    height: 98px;
  }
  .search-result .post-part .img-view .video-view .img-err {
    position: relative;
    width: 100%;
    height: 98px;
  }
  .search-result .post-part .img-view .video-view .play-button {
    top: 44% !important;
  }
  .search-result .post-part .img-view-first {
    overflow: hidden;
    object-fit: cover;
    height: 190px;
    position: relative;
  }
  .search-result .post-part .img-view-first .image {
    overflow: hidden;
    font-size: 10px;
    width: 100%;
    height: unset;
  }
  .search-result .post-part .img-view-first .video-view .video {
    position: relative !important;
    width: 100%;
    height: 190px;
  }
  .search-result .post-part .img-view-first .video-view .mask-video {
    width: 100%;
    height: 190px;
  }
  .search-result .post-part .img-view-first .video-view .img-err {
    position: relative;
    width: 100%;
    height: 190px;
  }
  .search-result .post-part .img-view-first .video-view .play-button {
    top: 50% !important;
  }
  .search-result .post-part .content-view .post-summary {
    height: 55px;
    overflow: hidden;
    display: none;
  }
  .search-result .post-part .content-view .post-date {
    display: none;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .search-result .box {
    padding-left: 24px;
  }
}
.embed-page iframe {
  height: 800px;
}
.embed-page .box {
  width: 100%;
  height: 100%;
}

.video-conponent-videotemplate {
  width: 700px;
  height: 400px;
}

.video-conponent-template {
  z-index: 99998;
  position: relative;
}

.video-conponent-template-close {
  position: absolute;
  font-size: 30px;
  z-index: 99999;
  background-color: #9a9a9a;
  color: #c6c6c6;
  font-weight: bold;
  height: 45px;
  width: 45px;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 15px 3px #313131;
  border: none;
  text-align: center;
  border-radius: 3px;
}

.mat-dialog-container {
  padding: 5px !important;
  background-color: #2e2e2e;
  box-shadow: 0px 0px 45px 20px #313131;
}

.video-view .img-err {
  z-index: 800;
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-view .video-js .vjs-control-bar {
  background-image: linear-gradient(#666565 10%, #dbdbdb);
}
.video-view .vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -1em;
  margin-left: -1em;
}
.video-view .vjs-default-skin .vjs-big-play-button {
  width: 1.9em;
  height: 1.9em;
  border-radius: 100px;
  border: none;
  line-height: 1.95em;
}
.video-view .video {
  width: 100%;
  height: 100%;
  z-index: 800;
  position: absolute;
  top: 0;
}
.video-view .mask-video {
  z-index: 802;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.video-view .play-button {
  z-index: 801;
  height: 61px;
  width: 60px;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  margin-left: -1em;
  margin-top: -1.2em;
}
.video-view .gg-play-button-o {
  width: 2.5em;
  height: 2.5em;
  border-radius: 45px;
  background-color: #2c2a2a;
  position: relative;
  display: block;
  opacity: 0.7;
}
.video-view .gg-play-button-o::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 0.7em solid transparent;
  border-bottom: 0.7em solid transparent;
  border-left: 1em solid white;
  top: 24%;
  left: 38%;
}
.video-view .zoom-view {
  border: 2px solid white;
  font-size: 10px;
}

.most-view {
  margin-left: 15px;
}
.most-view figure {
  margin: 0 !important;
}
.most-view .margin {
  margin-bottom: 8rem !important;
}
.most-view .title-view {
  margin-top: 0px;
  background-color: white;
  color: #353535;
  width: 100%;
}
.most-view .title-view h5 {
  font-size: 17px !important;
  margin-bottom: 0px;
}
.most-view .header {
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 46px;
  /* identical to box height, or 209% */
  color: #006CB5;
  margin-bottom: -7px;
  margin-top: 10px;
}
.most-view .img-first {
  object-fit: cover;
  margin-top: 10px;
}
.most-view .img-next {
  object-fit: cover;
  max-height: 152px;
}
.most-view .img-next img.img-next {
  width: 100%;
  height: 100% !important;
}
.most-view .img-first {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  max-height: 152px;
}
.most-view .post-fra {
  cursor: pointer;
  overflow: hidden;
}
.most-view .post-fra .view-img {
  overflow: hidden;
  position: relative;
  margin-right: auto !important;
  padding-right: 0px !important;
  height: 100%;
}
.most-view .post-fra .view-img img {
  font-size: 10px;
  margin-right: 0px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.most-view .post-fra .most-view-time {
  color: #7D7D7D;
  font-weight: 400;
  font-size: 16px;
  margin-left: 15px;
}
.most-view .post-fra .caption-view {
  font-size: 18px;
  font-weight: bolder;
  text-decoration: none;
  margin-top: 24px;
  color: #0b68a4;
  overflow: hidden;
}
.most-view .post-fra .caption-view figcaption:hover {
  color: #0b68a4 !important;
}
.most-view .post-fra .caption-view .caption-first {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 24px 0px;
  color: #2B2B2B;
}
.most-view .post-fra .caption-view-next {
  display: flex;
  font-size: 18px;
  font-weight: bolder;
  text-decoration: none;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #0b68a4;
  overflow: hidden;
  width: 100%;
}
.most-view .post-fra .caption-view-next figcaption:hover {
  color: #0b68a4 !important;
}
.most-view .post-fra .caption-view-next .caption-next {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #4F4F4F;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: calc(1.2rem * 3);
  line-height: 1.2rem;
}
.most-view hr {
  border-top: 1px solid #e5e5e5;
  border-top-style: groove;
  display: block !important;
}
.most-view .content-box {
  border-top: 1px dashed #C5C5C5;
  padding: 0;
  margin: 0px 15px;
}
.most-view .text-no-post {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

@media screen and (max-width: 770px) {
  .most-view {
    padding-right: 0px;
    padding-left: 0px;
    margin: 0;
  }
  .most-view .post-fra .view-img {
    overflow: hidden;
    position: relative;
    margin-right: auto !important;
    padding-right: 0px !important;
    height: 100%;
  }
  .most-view .post-fra .view-img img {
    font-size: 10px;
    margin-right: 0px !important;
    width: 100%;
    max-height: 100%;
  }
  .most-view .caption-next {
    margin-top: 20px;
  }
  .most-view .img-next {
    height: 152px !important;
  }
  .most-view .img-first {
    height: 152px !important;
  }
}
.post-detail tbody,
.post-detail td,
.post-detail tfoot,
.post-detail th,
.post-detail thead,
.post-detail tr {
  border-color: #cacaca;
  border-width: 1px;
}
.post-detail table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.post-detail .text-tiny {
  font-size: 0.7em;
}
.post-detail .text-small {
  font-size: 0.85em;
}
.post-detail .text-medium {
  font-size: 1em;
}
.post-detail .text-big {
  font-size: 1.4em;
}
.post-detail .text-huge {
  font-size: 2em;
}
.post-detail a .categories {
  color: #4e7ed8;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none !important;
  z-index: 6;
}
.post-detail a:hover span {
  color: #ff9900;
}
.post-detail .title-post {
  font-style: normal;
  margin-top: 47px !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #006CB5;
}
.post-detail .summary {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #565656;
}
.post-detail .created-post {
  font-weight: bolder;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
  color: #D52929;
}
.post-detail hr.line-title {
  border-top: 5px solid #aeafb3;
}
.post-detail .img-present {
  position: relative;
  max-width: 100% !important;
  font-size: 10px;
}
.post-detail .img-present .video-view {
  height: 390px !important;
}
.post-detail .media {
  position: relative;
  width: 100% !important;
  height: 500px !important;
  max-height: none !important;
}
.post-detail .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.post-detail .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.post-detail .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.post-detail .vertical-dot .menu .menu-row,
.post-detail .vertical-dot .menu .menu-row-current,
.post-detail .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.post-detail .vertical-dot .menu .menu-row .menu-item-1,
.post-detail .vertical-dot .menu .menu-row .menu-item-2,
.post-detail .vertical-dot .menu .menu-row-current .menu-item-1,
.post-detail .vertical-dot .menu .menu-row-current .menu-item-2,
.post-detail .vertical-dot .menu .menu-row-current-border .menu-item-1,
.post-detail .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-detail .vertical-dot .menu .menu-row-current,
.post-detail .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.post-detail .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.post-detail .d-flex {
  visibility: visible !important;
}
.post-detail .title-center {
  text-align: center !important;
}
.post-detail .close-div {
  display: flex;
  margin-top: 3px;
}
.post-detail .post-detail-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.post-detail .post-detail-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.post-detail .post-detail-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.post-detail .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.post-detail .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.post-detail .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.post-detail .description {
  text-align: justify;
}
.post-detail .description img {
  width: 100% !important;
}
.post-detail .description figure {
  width: 100% !important;
}
.post-detail .description figure iframe {
  height: 100%;
  width: 100%;
}
.post-detail .description p {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.post-detail .description blockquote {
  border-left: 5px solid #d9d9d9;
  padding-left: 15px;
}
.post-detail .reference-link-box {
  margin-top: 10px;
}
.post-detail .sapned {
  margin-top: 20px;
}
.post-detail .reference-link {
  text-decoration: none;
}
.post-detail .reference-link:hover {
  text-decoration: underline;
}
.post-detail .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
}
.post-detail .custom-text {
  color: black;
}
.post-detail .custom-text-file {
  font-weight: bold;
  color: black;
  text-decoration: none;
  word-break: break-word;
}
.post-detail .file-div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.post-detail .file-div a {
  text-decoration: none;
}
.post-detail .file-div :hover {
  color: #4e7ed8;
}
.post-detail .categories-link {
  text-decoration: none;
}
.post-detail .margin-box {
  margin: auto;
}
.post-detail .detail-body {
  width: calc(100% - 250px);
  min-width: 80%;
  max-width: 1550px;
}
.post-detail .detail-body .btn-fl {
  padding: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
}
.post-detail .detail-body-full {
  width: 100%;
}
.post-detail .keyword-container {
  display: inline-flex;
}
.post-detail .keyword-title {
  font-weight: 600;
}
.post-detail .keywords {
  padding: 5px 8px;
  margin: 0px 5px;
  background-color: #d9d9d9;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}
.post-detail .keywords:hover {
  color: #FFFFFF;
  background-color: #004A9A;
}
.post-detail .top-new-item {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: center;
}
.post-detail .back-to-top {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
}
.post-detail .post-image {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.post-detail .fixed-item {
  position: fixed;
  padding: 0px 0px 0 0;
  left: calc(100% - 65px);
  top: 90%;
  transform: translateX(-50%);
  z-index: 1001;
}
.post-detail .main-item {
  display: table;
  margin: 0;
}
.post-detail .responsible-container {
  display: inline-flex;
  font-size: 14px;
  margin: 15px 0;
  float: right;
}
.post-detail .responsible-title {
  font-weight: 600;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .post-detail .fixed-item {
    display: none;
    top: 90%;
    right: 0px;
    left: calc(100% - 30px) !important;
  }
}
@media screen and (max-width: 576px) {
  .post-detail .detail-body {
    width: 100% !important;
  }
  .post-detail .box .categories {
    font-size: 14px;
  }
  .post-detail .box .title-post {
    font-weight: bolder;
    font-size: 20px;
  }
  .post-detail .box .img-present {
    position: relative;
    max-width: 100% !important;
    font-size: 10px;
  }
  .post-detail .box .img-present .video-view {
    height: 190px !important;
  }
  .post-detail .description {
    text-align: justify;
  }
  .post-detail .description img {
    width: 100% !important;
  }
  .post-detail .description figure {
    width: 100% !important;
  }
  .post-detail .description figure iframe {
    height: 100%;
    width: 100%;
  }
  .post-detail .description .media {
    position: relative;
    width: 100% !important;
    height: 260px !important;
    max-height: none !important;
  }
}
@media screen and (max-width: 1025px) and (min-width: 576px) {
  .post-detail .detail-body {
    width: calc(100% - 50px) !important;
  }
  .post-detail .media {
    position: relative;
    width: 100% !important;
    height: 400px !important;
    max-height: none !important;
  }
}

.post-list tbody,
.post-list td,
.post-list tfoot,
.post-list th,
.post-list thead,
.post-list tr {
  border-color: #cacaca;
  border-width: 1px;
}
.post-list table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.post-list .subject {
  margin-top: 14px;
}
.post-list .group-header {
  padding: 0px 0px 0px 10px;
  border-left: 5px solid var(--main-color);
  font-weight: bolder;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 1.8;
  margin-top: 5px;
  margin-bottom: 10px;
}
.post-list hr {
  display: none;
}
.post-list .title-post {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 24px;
}
.post-list .mb {
  display: none;
}
.post-list .wb a {
  text-decoration: none;
  color: #212529;
}
.post-list .k-pager-numbers .k-link {
  color: black;
  border-width: 1px;
  margin: 4px;
}
.post-list .k-pager-wrap {
  border: none;
  color: #424242;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.post-list .k-pager-numbers .k-link.k-state-selected {
  background-color: #c60000;
  color: white !important;
}
.post-list .k-pager-nav {
  border: solid 1px;
  margin: 4px;
}
.post-list .post-part {
  height: 180px;
  cursor: pointer;
  overflow: hidden;
}
.post-list .post-part .img-view {
  overflow: hidden;
  position: relative;
}
.post-list .post-part .img-view .image {
  width: 95%;
  height: 150px;
  display: inline-block;
  object-fit: cover;
}
.post-list .post-part .img-view .video-view .video {
  position: relative !important;
  width: 95%;
  height: 150px;
}
.post-list .post-part .img-view .video-view .img-err {
  position: relative;
  width: 95%;
  height: 150px;
}
.post-list .post-part .img-view .video-view .play-button {
  top: 47% !important;
  left: 47% !important;
}
.post-list .post-part .content-view {
  margin-left: inherit;
  font-size: 14px;
  font-weight: bolder;
  text-decoration: none;
  overflow: hidden;
}
.post-list .post-part .content-view .post-date {
  font-size: 11px;
  color: gray;
  font-weight: normal !important;
  margin-top: 5px;
  margin-bottom: 5px;
}
.post-list .post-part .content-view .post-summary {
  font-size: 12px;
  color: black;
  font-weight: normal !important;
  height: 105px;
  overflow: hidden;
  margin-top: 3px;
}
.post-list .post-part .content-view .post-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 3px 0;
}
.post-list .post-part .content-view .title-first {
  padding-left: 15px;
  font-size: 22px;
}
.post-list .post-part .content-view:hover .post-title, .post-list .post-part .content-view:hover .post-date, .post-list .post-part .content-view:hover .post-summary {
  color: #06c !important;
}
@media (max-width: 770px) {
  .post-list {
    padding-right: 15px;
    padding-left: 15px;
  }
  .post-list .box {
    padding-right: 15px;
  }
  .post-list .wb {
    display: none;
  }
  .post-list .mb {
    display: block;
  }
  .post-list .mb a {
    text-decoration: none;
    color: #212529;
  }
  .post-list .mb-second {
    padding-top: unset;
    margin-bottom: -15px;
  }
  .post-list .mb-first {
    margin-bottom: 15px;
  }
  .post-list hr {
    border-top: 1px solid #e5e5e5;
    border-top-style: groove;
    color: white;
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .post-list .post-part {
    height: auto;
  }
  .post-list .post-part .img-view {
    overflow: hidden;
    position: relative;
    height: 98px;
  }
  .post-list .post-part .img-view .image {
    width: 100%;
    height: 98px;
    display: inline-block;
    object-fit: cover;
  }
  .post-list .post-part .img-view .video-view .video {
    position: relative !important;
    width: 100%;
    height: 98px;
  }
  .post-list .post-part .img-view .video-view .mask-video {
    width: 100%;
    height: 98px;
  }
  .post-list .post-part .img-view .video-view .img-err {
    position: relative;
    width: 100%;
    height: 98px;
  }
  .post-list .post-part .img-view .video-view .play-button {
    top: 44% !important;
  }
  .post-list .post-part .img-view-first {
    overflow: hidden;
    object-fit: cover;
    height: 205px;
    position: relative;
  }
  .post-list .post-part .img-view-first .image {
    overflow: hidden;
    font-size: 10px;
    width: 100%;
    height: unset;
  }
  .post-list .post-part .img-view-first .video-view .video {
    position: relative !important;
    width: 100%;
    height: 205px;
  }
  .post-list .post-part .img-view-first .video-view .mask-video {
    width: 100%;
    height: 205px;
  }
  .post-list .post-part .img-view-first .video-view .img-err {
    position: relative;
    width: 100%;
    height: 205px;
  }
  .post-list .post-part .img-view-first .video-view .play-button {
    top: 50% !important;
  }
  .post-list .post-part .content-view .post-summary {
    height: 55px;
    overflow: hidden;
    display: none;
  }
  .post-list .post-part .content-view .post-date {
    display: none;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .post-list figure {
    padding: 0;
  }
  .post-list .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.question-search .title-question {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
  border-bottom: #c60000 solid 2px;
  width: fit-content;
}
.question-search .title-answer {
  margin-top: 0px !important;
}
.question-search .custom-text {
  color: black;
}
.question-search .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
}
.question-search .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.question-search .contents {
  font-family: inherit !important;
  white-space: inherit;
  font-size: inherit !important;
  margin-left: 20px;
}
.question-search .contents .contents_label {
  color: #9fa19f;
}
.question-search .custom-color {
  color: #c60000;
}
.question-search .custom-row {
  background-color: white;
  cursor: pointer;
}
.question-search .margin30 {
  margin: 30px 0px 32px 0px;
}
.question-search .btn-search {
  background: #194f8b;
  color: white;
  width: 130px;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 16px !important;
  font-family: inherit;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  overflow: visible;
}
.question-search .total-question {
  font-weight: bold;
  font-size: 16px;
}
.question-search .hr-style {
  margin-top: 2px;
  margin-bottom: 15px;
}
.question-search .xbutton-dialog-question {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  float: right;
  color: black !important;
  font-size: 12px !important;
}
.question-search .control-search input {
  font-size: 16px !important;
  margin-left: 2px;
}
.question-search .control-search input::placeholder {
  color: #bbbaba;
}
.question-search .control-search .btn-search {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
  background-color: #985d1a !important;
}
.question-search .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: black !important;
  width: 30px;
  height: 32px;
  float: right;
  border-radius: 0;
  margin-top: -33px;
  border: 0;
  font-size: 20px;
  z-index: 1;
  position: sticky;
}
.question-search .control-search .btn-remove-replace-btn-search {
  right: 0 !important;
}
.question-search .control > * {
  width: 100%;
}
.question-search .list-question {
  cursor: pointer;
}
.question-search .list-question aside {
  color: #515460;
}
.question-search .list-question article:hover h3 {
  color: #2569b8;
}
.question-search .list-question article:hover aside {
  color: #2569b8;
}
.question-search .list-question article:hover aside .contents_label {
  color: #4e7ed8;
  opacity: 0.8;
}
.question-search .k-pager-numbers .k-link {
  color: black;
  border-width: 1px;
  margin: 4px;
}
.question-search .k-pager-wrap {
  border: none;
  color: #424242;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.question-search .k-pager-numbers .k-link.k-state-selected {
  background-color: #c60000;
  color: white !important;
}
.question-search .k-pager-nav {
  border: solid 1px;
  margin: 4px;
}

@media screen and (max-width: 770px) {
  .question-search-detail.x-dialog {
    width: 95% !important;
    height: 95% !important;
  }
}
.question-search-detail.x-dialog {
  max-width: 800px;
  max-height: 800px;
}

.question-search-detail .title-question {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: solid #c60000;
  width: fit-content;
}
.question-search-detail .title-answer {
  margin-top: 0px !important;
}
.question-search-detail .custom-text {
  color: black;
  font-size: 16px;
}
.question-search-detail .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
  margin-bottom: 20px;
}
.question-search-detail .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.question-search-detail .contents {
  font-family: inherit !important;
  white-space: pre-wrap;
  font-size: inherit;
}
.question-search-detail .custom-color {
  color: #c60000;
}
.question-search-detail a {
  text-decoration: none;
}
.question-search-detail .custom-text-file {
  font-weight: bold;
  color: black;
  margin-bottom: 8px !important;
}
.question-search-detail .custom-text-file:hover {
  color: #194f8b;
}
.question-search-detail .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black !important;
  font-size: 30px !important;
}
.question-search-detail .title-info-req {
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
}

.receive-questions .page-name {
  color: #194F8B;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 20px;
  font-weight: 600;
}
.receive-questions .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-bottom-color: #c60000;
}
.receive-questions .k-tabstrip > .k-content {
  border: none;
  overflow-x: hidden;
}
.receive-questions .k-tabstrip-items .k-item {
  color: #000000;
  border-color: #d7d7d7;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-questions .k-tabstrip-items .k-item.k-state-active,
.receive-questions .k-tabstrip-items .k-item.k-state-selected {
  border-color: #c60000;
  color: #c60000;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-questions .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-color: #EEEEEE;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-questions .k-tabstrip-content, .receive-questions .k-tabstrip > .k-content {
  padding: 6px 0px;
}
.receive-questions .k-tabstrip-items {
  border-bottom: solid 1px #d7d7d7;
}
@media screen and (max-width: 770px) {
  .receive-questions {
    overflow-x: hidden;
  }
  .receive-questions .k-tabstrip-top > .k-tabstrip-items {
    overflow-y: hidden;
    width: 100%;
  }
  .receive-questions .k-tabstrip-content, .receive-questions .k-tabstrip > .k-content {
    padding: 6px 12px;
  }
  .receive-questions .page-name {
    margin-left: 10px;
  }
}

.question-info .err-text, .question-info .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.question-info .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.question-info .btn-custom {
  text-align: right;
  margin-right: 15px;
  margin-bottom: 5px;
}
.question-info .btn-custom button {
  background: #cd330d;
  color: white;
}
.question-info .btn-send {
  background: #c60000;
  color: white;
  position: relative;
  float: right;
  /* border-radius: -5px; */
  border: none;
  margin-right: 0px;
  margin-top: 35px;
  margin-bottom: 5px;
  text-align: center;
  padding: 1px 24px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 8px;
  font-weight: 500;
}
.question-info .button-div {
  position: relative;
  float: right;
  margin-top: 15px;
  /* display: block; */
  display: inline-flex;
}
.question-info .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.question-info .selected-lang {
  border-bottom: 3px red solid;
}
.question-info .employe-detail {
  margin-top: 15px;
  margin-left: 10px;
}
@media screen and (max-width: 770px) {
  .question-info .employe-detail {
    margin: 0;
  }
}

.content-document .x-dialog {
  max-width: 500px;
  max-height: 600px;
}
.content-document .err-text, .content-document .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.content-document .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.content-document .panelbar-wrapper {
  margin: 0 auto;
}
.content-document .k-panelbar > .k-item > .k-link .k-icon {
  color: #194f8b;
}
.content-document .k-content {
  background-color: white !important;
}
.content-document tbody, .content-document td, .content-document tfoot, .content-document th, .content-document thead, .content-document tr {
  border-color: #cacaca;
  border-width: 1px;
  vertical-align: middle;
}
.content-document th {
  background-color: #f3f2f2;
}
.content-document th,
.content-document td {
  padding: 8px 12px;
  border-style: solid;
  text-overflow: ellipsis;
}
.content-document td {
  display: table-cell;
}
.content-document table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.content-document .no-record {
  text-align: center;
}
.content-document .x-dialog-agree .row {
  line-height: 35px;
}
.content-document .content-margin {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 30px;
}
.content-document .custom-btn-addNew {
  margin-top: 10px !important;
}
.content-document .btn-addNew {
  text-align: right;
  display: block;
  margin-right: 15px;
  margin-bottom: 5px;
}
.content-document .btn-custom {
  text-align: right;
  margin-right: 15px;
  margin-bottom: 5px;
}
.content-document .btn-custom button {
  background: #cd330d;
  color: white;
}
.content-document .btn-search-content {
  background: RGB(25, 79, 139);
  color: white;
  position: relative;
  float: left;
  border: none;
  margin-right: 0px;
  margin-top: 40px;
  margin-bottom: 5px;
  text-align: center;
  padding: 2px 23px;
  font-size: medium;
}
.content-document .btn-delete {
  background: red;
  color: white;
  position: relative;
  float: left;
  border: none;
  margin-right: 0px;
  margin-top: 40px;
  margin-bottom: 5px;
  text-align: center;
  padding: 2px 23px;
  font-size: small;
}
.content-document .btn-continue {
  background: #34a853;
  color: white;
  position: relative;
  float: right;
  border-radius: 0px;
  border: none;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
  padding: 1px 23px;
}
.content-document .PayButton-div {
  position: relative;
  float: left;
  width: auto;
  max-width: 100%;
}
.content-document .button-div {
  position: relative;
  float: right;
  display: block;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}
.content-document .red-status {
  color: red;
  font-weight: lighter;
}
.content-document .link-up {
  color: cornflowerblue;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
}
.content-document .icon-format {
  max-width: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-document .text-center {
  text-align: center;
}
.content-document .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.content-document .selected-lang {
  border-bottom: 3px red solid;
}
.content-document .div-method {
  justify-content: center;
}
.content-document .method-border {
  border: 2px solid #b7b7b7;
  margin-right: 10px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  width: auto !important;
}
.content-document .mar-bot-20 {
  margin-bottom: 20px;
}
.content-document .div-icon {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #e4e4e4;
  padding-top: 15px;
}
.content-document .icon-img {
  width: 75px !important;
  height: 75px;
}
.content-document .payment-div {
  margin-top: 20px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  padding-top: 15px;
  padding-bottom: 8px;
  margin-bottom: 25px;
  text-align: right;
}
.content-document .is-fee {
  color: #e85d3b;
  font-size: 28px;
  padding-left: 20px;
  padding-right: 60px;
  vertical-align: sub;
}
.content-document .is-fee-dot {
  display: inline-flex;
}
.content-document .selected-payment {
  color: #e85d3b !important;
  border-color: #e85d3b !important;
}
.content-document .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black !important;
  font-size: 30px !important;
}
.content-document .selected-payment {
  color: #e85d3b !important;
  border-color: #e85d3b !important;
}

@media screen and (max-width: 770px) {
  .content-document table {
    table-layout: fixed;
  }
  .content-document table td {
    word-wrap: break-word;
    /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;
    overflow: hidden;
  }
  .content-document .btn-cancel-document {
    margin-top: 5px;
  }
  .content-document .btn-ticket-appointment {
    margin-top: 5px;
  }
}
.content-document .title-info-req {
  font-size: 15px;
  font-weight: 650;
  margin-top: 5px;
}
.content-document .appointment-label {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}
.content-document .orderidx {
  font-size: 80px;
  color: lightgray;
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
}
.content-document .content-label {
  margin-bottom: 10px;
  display: inline-block;
  margin-left: 5px;
  font-weight: bold;
}
.content-document .content-label-hr {
  margin-bottom: 0px;
}
.content-document .center-me {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.content-document .btn-cancel-document {
  background: red;
  width: 150px;
}
.content-document .btn-ticket-appointment {
  margin-right: 20px;
  width: 150px;
}

/*CSS Dialog Huỷ đăng kí*/
.cancel-appointment.x-dialog-cancel {
  max-width: 450px;
  max-height: 300px;
  align-self: center;
}

.cancel-appointment .center-me {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cancel-appointment .k-window-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-appointment .cancel-label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.cancel-appointment .btn-cancel {
  margin-top: 20px;
  float: right;
  width: 130px;
  background: white;
  color: black;
  border: 1px solid black;
}
.cancel-appointment .btn-accept-cancel {
  margin-top: 20px;
  float: left;
  width: 130px;
  background: red;
  color: white;
  border: 1px solid red;
}
.cancel-appointment .areyousure-label {
  padding: 0px 40px;
}

/* Hết CSS Dialog Huỷ đăng kí */
.horizontal-timeline .timeline-wrap {
  /* margin: 10% 10%; */
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 20px;
  /* top: 100; */
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.horizontal-timeline .timeline {
  height: 5px;
  width: 106%;
  margin-left: -3%;
  background-color: #fdecb5;
  position: relative;
}
.horizontal-timeline .marker {
  z-index: 1000;
  color: transparent;
  width: 30px;
  height: 30px;
  line-height: 10px;
  /* font-size: 1em; */
  text-align: center;
  position: absolute;
  background-color: red;
  border: 7px solid #FF9F55;
  border-radius: 50%;
}
.horizontal-timeline .style-char {
  color: black;
  font-size: 12px;
  width: 63px;
}
.horizontal-timeline .marker:hover {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.horizontal-timeline .timeline-icon.two {
  background-color: red !important;
}
.horizontal-timeline .timeline-icon.one {
  background-color: #ffffff !important;
}
.horizontal-timeline .mfirst {
  top: -11px;
  left: -3%;
}
.horizontal-timeline .m2 {
  top: -11px;
  left: 32.5%;
}
.horizontal-timeline .m3 {
  top: -11px;
  left: 66%;
}
.horizontal-timeline .mlast {
  top: -11px;
  left: 100%;
}
@media screen and (max-width: 770px) {
  .horizontal-timeline {
    max-width: 100%;
    margin-left: -10px !important;
    margin-right: 50px !important;
  }
}

.search-document {
  /* .search-document input::-webkit-input-placeholder {
      font-size: 10px !important;
  } */
}
.search-document .err-text,
.search-document .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.search-document .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.search-document .custom-btn-addNew {
  margin-top: 10px !important;
}
.search-document .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}
.search-document .btn-custom {
  width: 100%;
}
.search-document .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}
.search-document .btn-search {
  /* text-align: right; */
  /* margin-right: 15px;
  margin-bottom: 5px; */
  /* float: right; */
  background: RGB(25, 79, 139);
  color: white;
  /* position: relative; */
  float: right;
  /* border-radius: -5px; */
  border: none;
  margin-right: 0px;
  margin-top: 40px;
  margin-bottom: 5px;
  text-align: center;
  padding: 2px 23px;
  font-size: medium;
  width: auto;
  white-space: nowrap;
  display: inline;
}
.search-document .button-div {
  position: relative;
  float: right;
  /* display: block; */
  display: contents;
  width: fit-content;
}
.search-document .lineSpace {
  line-height: 35px;
}
.search-document .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.search-document .selected-lang {
  border-bottom: 3px red solid;
}

.submit-document .red-icon {
  color: #d0401d;
  margin-right: 15px;
  font-size: 20px;
}

.submit-document .custom-document {
  margin-bottom: 5px;
  font-size: 18px;
}

@media screen and (max-width: 770px) {
  .search-document .k-window {
    width: 100%;
    height: 100%;
  }
}
.receive-document .page-name {
  color: #194F8B;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 20px;
  font-weight: 600;
}
.receive-document .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-bottom-color: #c60000;
}
.receive-document .k-tabstrip > .k-content {
  border: none;
  overflow: visible !important;
}
.receive-document .k-tabstrip-items .k-item {
  color: #000000;
  border-color: #d7d7d7;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-document .k-tabstrip-items .k-item.k-state-active,
.receive-document .k-tabstrip-items .k-item.k-state-selected {
  border-color: #c60000;
  color: #c60000;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-document .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-color: #EEEEEE;
  font-weight: bold;
  border-width: 0px 0px 4px 0px;
}
.receive-document .k-tabstrip-content, .receive-document .k-tabstrip > .k-content {
  padding: 6px 0px;
}
.receive-document .k-tabstrip-items {
  border-bottom: solid 1px #d7d7d7;
}
@media screen and (max-width: 770px) {
  .receive-document {
    overflow-x: hidden;
  }
  .receive-document .k-tabstrip-top > .k-tabstrip-items {
    overflow-y: hidden;
    width: 100% !important;
  }
  .receive-document .k-tabstrip-content, .receive-document .k-tabstrip > .k-content {
    padding: 6px 12px;
  }
  .receive-document .page-name {
    margin-left: 10px;
  }
}

.register-document {
  font-weight: normal;
  /*  .k-button.k-upload-button {
      display: none;
  } */
}
.register-document table {
  table-layout: fixed;
}
.register-document .break-word {
  word-break: break-all;
}
.register-document table, .register-document th, .register-document td {
  border: 1px solid;
  font-weight: normal;
}
.register-document th, .register-document td {
  padding-left: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.register-document .err-text, .register-document .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.register-document .text-center {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.register-document .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
.register-document .is-display-flex {
  display: flex;
}
.register-document .is-display-grid {
  display: grid;
}
.register-document .scoll-x {
  overflow-x: auto;
}
.register-document .title-info-req {
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
}
.register-document .btn-dialog {
  border-radius: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  float: right;
}
.register-document .btn-dialog-wrapper {
  border-width: 0 !important;
  width: 100%;
  margin-top: 10px;
}
.register-document .btn-regist-cv {
  color: white;
  background-color: #194f8b;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}
.register-document .column-tb-1 {
  width: 5%;
  min-width: 50px;
}
.register-document .column-tb-2 {
  width: 57%;
  min-width: 150px;
}
.register-document .column-tb-3 {
  width: 20%;
  min-width: 150px;
}
.register-document .column-tb-4 {
  width: 15%;
  min-width: 135px;
}
.register-document .column-tb-5 {
  width: 13%;
  min-width: 110px;
}
.register-document .btn-scanning-img {
  color: white;
  background-color: #616161;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  cursor: no-drop !important;
}
.register-document .disable-link {
  cursor: no-drop;
  color: gray;
  text-decoration: none;
  font-weight: normal;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.register-document .x-dialog {
  max-width: 1550px;
  max-height: 700px;
  /* margin-left: 80px; */
  align-self: center;
}
.register-document .x-dialog-agree {
  max-width: 500px;
  max-height: 360px;
}
.register-document .x-dialog-agree .row {
  line-height: 35px;
}
.register-document .content-margin {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 30px;
}
.register-document .red-text {
  color: red;
}
.register-document .custom-btn-addNew {
  margin-top: 10px !important;
}
.register-document .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}
.register-document .btn-custom {
  text-align: right;
  margin-right: 15px;
  margin-bottom: 5px;
}
.register-document .btn-custom button {
  background: #cd330d;
  color: white;
}
.register-document .icon-format {
  max-width: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.register-document .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.register-document tbody,
.register-document td,
.register-document tfoot,
.register-document th,
.register-document thead,
.register-document tr {
  border-color: #cacaca !important;
  border-width: 1px;
  vertical-align: middle;
}
.register-document th {
  background-color: #f3f2f2;
}
.register-document th,
.register-document td {
  padding: 8px 12px;
  border-style: solid;
  text-overflow: ellipsis;
}
.register-document td {
  display: table-cell;
}
.register-document table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.register-document .is-link {
  cursor: pointer;
  color: #0645AD;
  text-decoration: none;
  font-weight: normal;
}
.register-document .selected-lang {
  border-bottom: 3px red solid;
}
.register-document .required {
  color: #ff6358;
  padding-left: 3px;
}
.register-document .control {
  margin-bottom: 7px;
}
.register-document .control label {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 770px) {
  .register-document .k-window {
    width: 90%;
    height: 90%;
  }
  .register-document .x-dialog {
    max-width: 768px;
    max-height: none;
  }
  .register-document .btn-dialog-wrapper {
    margin-top: 5px !important;
    padding-left: 0px !important;
  }
}

.payment-result .name {
  position: absolute;
  z-index: 6;
  left: 108px;
  top: 20px;
  font-size: 18px;
  font-weight: 900;
  text-shadow: 0px 6px 10px #a2a2a2;
}
.payment-result .name-main {
  color: red;
}
.payment-result .name-detail {
  color: var(--main-color);
  margin-top: 4px;
}
.payment-result .loading {
  display: none;
}
.payment-result .payment-status {
  top: 210px;
  position: relative;
  font-weight: bolder;
  text-align: center;
}
.payment-result .payment-msg {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.payment-result .payment-back {
  text-align: center;
}
.payment-result .btn-back {
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 25px;
  margin-top: 30px;
}
.payment-result .banner {
  width: 100% !important;
  height: 240px;
}
.payment-result .banner .img1 {
  max-width: 100%;
  height: 50px;
  top: 6px;
  left: 50%;
  position: absolute;
  margin-left: -2em;
}
.payment-result .banner .name {
  left: 0;
  top: 54px;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-align: center;
}
.payment-result .banner .img3 {
  max-width: 100%;
  height: 190px;
  top: 10px;
  left: 50%;
  position: absolute;
  margin-left: -6em;
}

.submit-document {
  font-weight: 550;
}
.submit-document .k-pager-numbers .k-link {
  color: black;
  border-width: 1px;
  margin: 4px;
}
.submit-document .page-name {
  margin-bottom: 15px;
  margin-top: 15px;
}
.submit-document .k-pager-wrap {
  border: none;
  color: #424242;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.submit-document .k-pager-numbers .k-link.k-state-selected {
  background-color: #c60000;
  color: white !important;
}
.submit-document .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black !important;
  font-size: 30px !important;
}
.submit-document .title-info-req {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.submit-document .k-pager-nav {
  border: solid 1px;
  margin: 4px;
}
.submit-document .is-pointer {
  cursor: pointer;
}
.submit-document .is-pointer:hover {
  color: #06c !important;
}
.submit-document .document-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.submit-document .btn-dialog {
  border-radius: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  float: right;
}
.submit-document .btn-dialog-wrapper {
  border-width: 0 !important;
  width: 100%;
  margin-top: 10px;
}
.submit-document .btn-close-success {
  color: black;
  background-color: #d4d4d4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  font-weight: bold;
}
@media (max-width: 770px) {
  .submit-document .x-dialog-register-document,
.submit-document .x-dialog-register-document-3,
.submit-document .x-dialog-register-document-2,
.submit-document .x-dialog-register-document-1,
.submit-document .x-dialog-register-document-0 {
    max-height: 100% !important;
    max-width: 100% !important;
  }
  .submit-document .x-dialog-success {
    max-width: 100% !important;
    max-height: 280px !important;
    top: 0% !important;
  }
  .submit-document .btn-dialog-wrapper {
    margin-top: 5px !important;
    padding-left: 0px !important;
  }
}

header-template1 {
  padding: 0;
  margin: 0;
  position: relative;
}
header-template1 nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
  width: var(--outlet-content-width);
}
header-template1 .nav-menu-box {
  background-color: var(--main-color);
  width: 100%;
  display: inline-flex;
  position: relative;
  z-index: 999;
  margin: auto;
  box-shadow: 0px 3px 0px 0px #dbbe7c;
}
header-template1 .nav-menu-box .nav-content {
  width: var(--outlet-content-width);
  margin: auto;
  position: relative;
  display: inline-flex;
}
header-template1 .nav-menu-box .home-icon {
  margin-top: 5px;
}
header-template1 .nav-menu-box .home-icon,
header-template1 .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header-template1 .menu-icon-box {
  display: none;
}
header-template1 .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: center;
}
header-template1 .nav-menu .dropdown {
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
}
header-template1 .nav-menu .dropdown .material-icons {
  color: white;
  padding-top: 5px;
  font-size: 18px;
}
header-template1 .nav-menu .dropdown-home {
  margin: auto;
}
header-template1 .nav-menu .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header-template1 .nav-menu .item-link {
  color: white;
  text-decoration: none;
  display: inline-table;
  height: 100%;
  width: 100%;
  padding: 7px;
}
header-template1 .nav-menu .dropdown:hover > .dropdown-menu {
  display: block;
}
header-template1 .nav-menu .dropdown-item:hover {
  background-color: #dddddd;
}
header-template1 .nav-menu .dropdown-submenu:hover {
  background-color: #dddddd;
}
header-template1 .nav-menu .dropdown-item {
  font-size: 95%;
  margin-right: 20px;
  padding: 0.6rem 1rem;
}
header-template1 .nav-menu .dropdown:hover {
  background-color: #133d6c;
}
header-template1 .nav-menu .dropdown-menu {
  left: 0;
  padding: 0;
  border-radius: 0;
}
header-template1 .nav-menu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
header-template1 .nav-menu .dropdown-submenu {
  position: relative;
  border-top: 1px solid #d8d8d8;
}
header-template1 .nav-menu .dropdown-menu :first-child {
  border-top: none;
}
header-template1 .nav-menu .dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-top: 5px;
  left: 100%;
}
header-template1 .disable-click {
  pointer-events: none;
  cursor: default;
}
header-template1 .banner {
  width: var(--outlet-content-width);
  margin: auto;
  position: relative;
}
header-template1 .banner img {
  width: 120px;
}
header-template1 .banner .img1 {
  max-width: 100%;
  height: 80%;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  top: 10px;
  bottom: 10px;
  cursor: pointer;
}
header-template1 .banner .img2 {
  width: 550px;
  height: 550px;
  top: -227px;
  left: -221px;
  z-index: 2;
  position: absolute;
  object-fit: cover;
  opacity: 0.3;
  clip: rect(210px, 575px, 328px, 219px);
  cursor: default;
}
header-template1 .banner .search {
  position: absolute;
  right: 122px;
  top: 51px;
  padding-left: 10px;
  font-size: 14px;
  height: 35px;
  z-index: 998;
  width: 25%;
  background-color: #fafafa;
}
header-template1 .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header-template1 .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header-template1 .banner .search-text:focus {
  border: 1px solid white !important;
}
header-template1 .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header-template1 .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header-template1 .banner .language {
  font-size: 13px;
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline;
  z-index: 999;
  color: #363842 !important;
  border: none !important;
  cursor: pointer;
}
header-template1 .banner .language-text {
  color: var(--main-color);
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
header-template1 .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header-template1 .banner .name {
  z-index: 6;
  left: 125px;
  top: 20px;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
header-template1 .banner .name .star-5-left {
  font-size: 8px;
  padding-right: 16px;
  color: #eb2e35;
}
header-template1 .banner .name .star-5-right {
  font-size: 8px;
  padding-left: 16px;
  color: #eb2e35;
}
header-template1 .banner .name a {
  text-decoration: none;
}
header-template1 .banner .name-main,
header-template1 .banner .name-detail {
  display: inline !important;
}
header-template1 .banner .name-main {
  color: #0b68a4;
  font-size: 25px;
  font-weight: 700;
}
header-template1 .banner .name-detail {
  color: #eb2e35;
  margin-top: 4px;
  font-weight: 700;
}
header-template1 .banner .logo {
  width: fit-content;
  visibility: hidden;
}
header-template1 .banner .logo-container:hover .logo,
header-template1 .banner .name-main:hover .logo,
header-template1 .banner .name-detail:hover .logo,
header-template1 .banner li:hover .logo {
  visibility: visible;
}
header-template1 .banner .logo-container {
  width: fit-content;
  padding-top: 10px;
  display: flex;
  position: relative;
  z-index: 10;
}
header-template1 .banner .logo-container .k-upload {
  display: none;
}
header-template1 .banner .material-icons {
  font-size: 18px;
}
header-template1 .banner .top-right-menu {
  z-index: 999;
  color: #363842 !important;
  border: none !important;
  cursor: pointer;
}
header-template1 .banner .top-right-menu-text {
  color: #0b68a4;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}
header-template1 .banner .menu .menus {
  display: inline !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header-template1 .banner .menu .menus .menu-item {
  display: inline;
  width: 33%;
  padding-right: 10px;
}
header-template1 .banner .menu .menus button {
  display: inline;
}
header-template1 .banner .btn-custom {
  background: initial;
  color: inherit;
}
header-template1 .banner .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: inherit;
  border: none;
}
header-template1 .banner .btn-custom .k-button {
  padding: 0 !important;
}
header-template1 .banner .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
header-template1 .banner .k-popup .k-item {
  width: 100%;
}
header-template1 .banner .btn-add {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
}
header-template1 .banner .btn-add:hover {
  background-color: #f3efef;
}
header-template1 .open-menu {
  display: block !important;
}
header-template1 .close-menu {
  display: none !important;
}
header-template1 .login-btn {
  font-size: 14px;
  height: 35px;
  background-color: #e4c97e;
  width: 106px;
  border: none;
}
header-template1 .edit .required,
header-template1 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
header-template1 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
header-template1 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
header-template1 .btn-save {
  color: white;
  background-color: #0b68a4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}

@media screen and (max-width: 576px) {
  header-template1 .desktop,
header-template1 .desktop-icon {
    display: none !important;
  }
  header-template1 .mobile {
    display: block !important;
  }
  header-template1 nav ul {
    width: auto;
  }
  header-template1 .banner {
    width: 100% !important;
    min-height: auto;
    overflow: hidden;
  }
  header-template1 .banner .img1 {
    max-width: 100%;
    height: 50px;
    top: 6px;
    left: 50%;
    position: relative;
    margin-left: -2em;
  }
  header-template1 .banner .img2 {
    width: 114px;
    height: 114px;
    top: 0;
    left: 50%;
    object-fit: cover;
    clip: auto;
    position: relative;
    margin-left: -3.4em;
    opacity: 0.2;
  }
  header-template1 .banner .language {
    top: 12px;
    right: 70px;
    width: 25px;
    height: 25px;
    border-radius: 34px;
    background-color: var(--main-color);
    padding-top: 0.82%;
    color: white !important;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: none !important;
  }
  header-template1 .banner .search {
    border-radius: 0;
    right: 122px;
    top: 116px;
    font-size: 17px;
    height: 36px;
    z-index: 998;
    width: 100%;
    padding-left: 10px;
    border: none;
  }
  header-template1 .banner .search-icon {
    top: 8px;
    font-size: 34px;
    right: 30px;
  }
  header-template1 .banner .name {
    left: 0;
    top: 54px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    text-align: center;
  }
  header-template1 .banner .name-detail {
    margin-top: 0;
  }
  header-template1 .nav-menu-box {
    height: 1px !important;
  }
  header-template1 .nav-menu {
    width: 84%;
    height: 100%;
    display: block;
    justify-content: center;
    font-size: 95% !important;
  }
  header-template1 .nav-menu .dropdown,
header-template1 .nav-menu .item-link {
    display: block;
    text-align: left;
  }
  header-template1 .nav-menu .dropdown-submenu:hover {
    background-color: var(--main-color);
  }
  header-template1 .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header-template1 .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header-template1 .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header-template1 .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header-template1 .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header-template1 .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header-template1 .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
  }
  header-template1 .nav-menu .dropdown-menu {
    display: none;
    width: 100%;
    border-top: 1px solid #4569cf;
  }
  header-template1 .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header-template1 .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header-template1 .dropdown-menu,
header-template1 .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header-template1 .pading-0 {
    padding: 10px 0 !important;
  }
  header-template1 .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header-template1 .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header-template1 .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header-template1 .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header-template1 .dropdown-item {
    color: white;
    padding: 5px 0px;
    font-weight: bold;
  }
  header-template1 .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header-template1 .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header-template1 .nav-menu-box {
    position: relative;
    background-color: #b0bbf6;
  }
  header-template1 .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header-template1 .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header-template1 .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header-template1 .navbar-display {
    top: 0px;
    margin-left: 0%;
    width: 70%;
    background: var(--main-color);
    height: 4000px !important;
    position: fixed;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header-template1 .search-cover-shadow,
header-template1 .navbar-cover-shadow {
    width: 100%;
    top: 0;
    background: var(--main-color);
    height: 4000px;
    position: fixed;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header-template1 .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header-template1 .navbar-hidden {
    display: none;
  }
  header-template1 #navbardropdownMenuLink {
    text-align: left !important;
  }
  header-template1 .top-right-menu {
    display: inline-flex !important;
    list-style-type: none;
    position: relative;
    width: 100%;
    justify-content: flex-end;
  }
  header-template1 .login-btn {
    position: relative;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100vw - 20px) !important;
  }
  header-template1 .name-main,
header-template1 .name-detail {
    position: relative !important;
  }
  header-template1 .left-block {
    width: 100%;
    position: relative !important;
  }
}
@media screen and (min-width: 575px) {
  header-template1 .desktop {
    display: block !important;
  }
  header-template1 .mobile {
    display: none !important;
  }
  header-template1 .banner {
    height: 120px;
  }
  header-template1 .name {
    position: absolute;
  }
  header-template1 .top-right-menu {
    display: inline !important;
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 15px;
  }
  header-template1 .login-btn {
    position: absolute;
    float: right;
    top: 51px;
    right: 10px;
  }
}
@media screen and (max-width: 1025px) {
  header-template1 .nav-menu {
    font-size: 85%;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
kendo-dialog .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

.image-slider-home .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-slider-home .banner {
  width: 100% !important;
}
.image-slider-home .k-dialog {
  height: auto !important;
  width: auto !important;
}
.image-slider-home kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.image-slider-home .k-scrollview-next,
.image-slider-home .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 97px;
  width: 47px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-slider-home .k-scrollview-next .k-icon,
.image-slider-home .k-scrollview-prev .k-icon {
  font-size: 30px;
  text-align: center;
}
.image-slider-home .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.image-slider-home .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-slider-home .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.image-slider-home .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.image-slider-home .vertical-dot .menu .menu-row,
.image-slider-home .vertical-dot .menu .menu-row-current,
.image-slider-home .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.image-slider-home .vertical-dot .menu .menu-row .menu-item-1,
.image-slider-home .vertical-dot .menu .menu-row .menu-item-2,
.image-slider-home .vertical-dot .menu .menu-row-current .menu-item-1,
.image-slider-home .vertical-dot .menu .menu-row-current .menu-item-2,
.image-slider-home .vertical-dot .menu .menu-row-current-border .menu-item-1,
.image-slider-home .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.image-slider-home .vertical-dot .menu .menu-row-current,
.image-slider-home .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.image-slider-home .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-slider-home .banner-container:hover .vertical-dot {
  display: flex;
}
.image-slider-home .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.image-slider-home .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.image-slider-home .title-info-req {
  color: #004A9A;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.image-slider-home .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.image-slider-home .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-home .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-home .employe-detail {
  margin-top: 10px;
}
.image-slider-home .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.image-slider-home .close-div {
  display: flex;
  margin-top: 3px;
}
.image-slider-home .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.image-slider-home .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.image-slider-home .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .image-slider-home .router-outlet-content {
    width: 100% !important;
  }
  .image-slider-home .router-outlet-content-gallery {
    width: 100% !important;
  }
}

.image-slider-home:hover .vertical-dot {
  visibility: visible;
}

.template-page {
  overflow: hidden;
}
.template-page .news-template-1 {
  margin-bottom: 20px;
}
.template-page .news-template-2 {
  margin-bottom: 20px;
}
.template-page .news-template-3 {
  margin-bottom: 20px;
}
.template-page .news-template-4 {
  margin-bottom: 20px;
}
.template-page .news-template-5 {
  margin-bottom: 20px;
}
.template-page .news-template-6 {
  margin-bottom: 20px;
}
.template-page .news-template-a {
  margin-bottom: 20px;
}
.template-page .news-template-b {
  margin-bottom: 20px;
}
.template-page .news-template-c {
  margin-bottom: 20px;
}
.template-page .body-list {
  max-width: 100%;
  border: none;
  min-height: 591px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 12px 0px 12px;
  background-color: #ffffff;
}
.template-page .body-empty-container {
  width: 100%;
  background-color: #ffffff;
  border: 1px dashed #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}
.template-page .page-list-background {
  background-color: #f5f5f5;
  padding: 20px 16px;
  height: 100vh;
  overflow: scroll;
}
.template-page .page-list-background::-webkit-scrollbar {
  width: 10px;
}
.template-page .page-list-background::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.template-page .page-list-background::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 2px;
}
.template-page .page-list-container {
  background-color: #ffffff;
  padding-top: 16px;
}
.template-page .page-lists {
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}
.template-page .page-lists::-webkit-scrollbar {
  width: 10px;
}
.template-page .page-lists::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.template-page .page-lists::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 2px;
}
.template-page .layout-box {
  position: relative;
  display: flex;
  border: solid 1px #cccccc;
  align-items: flex-start;
  min-height: 100px;
  width: 100%;
  justify-content: space-between;
}
.template-page .layout-box .hidden-dlt {
  top: 0 !important;
  right: 0 !important;
  margin-top: 2px;
  margin-right: 2px;
  position: absolute;
  z-index: 10;
}
.template-page .layout-box .hidden-move {
  top: 0 !important;
  right: 0 !important;
  margin-top: 26px;
  margin-right: 2px;
  position: absolute;
  z-index: 10;
  cursor: all-scroll;
}
.template-page .layout-list {
  width: 100%;
  min-height: 100px;
  background: #2b2b2b;
  border-radius: 4px;
  display: block;
  z-index: 100;
}
.template-page .layout-group {
  display: inline-block;
  border: solid 1px #cccccc;
  box-sizing: border-box;
}
.template-page .layout-box:hover .hidden-dlt {
  visibility: visible;
}
.template-page .layout-box:hover .hidden-move {
  visibility: visible;
}
.template-page .body-box {
  background-color: white;
  display: inline-block;
  position: relative;
}
.template-page .header-list {
  display: block;
  max-width: 100%;
  margin: 20px 12px 0px 12px;
  min-height: 138px;
  overflow: hidden;
  border: none;
}
.template-page .header-empty-container,
.template-page .footer-empty-container {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: 1px dashed #000000;
}
.template-page .header-empty,
.template-page .footer-empty {
  height: 110px;
  position: relative;
  margin: 14px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.template-page .header-empty h3,
.template-page .footer-empty h3 {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
}
.template-page .instruction-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.template-page .instruction-container img {
  width: 31px;
  height: 31px;
}
.template-page .instruction {
  margin-left: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.template-page .header-box {
  overflow-x: hidden;
  position: relative;
}
.template-page .header-box:hover .hidden-dlt {
  visibility: visible;
}
.template-page .body-empty {
  height: 563px;
  position: relative;
  margin: 14px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.template-page .body-empty h3 {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.6);
}
.template-page .footer-list {
  max-width: 100%;
  margin: 20px 12px;
  min-height: 60px;
  background: white;
  overflow: hidden;
  display: block;
}
.template-page .footer-box {
  margin-top: -14px;
  overflow-x: hidden;
  position: relative;
}
.template-page .footer-box .hidden-dlt {
  margin-top: 16px;
}
.template-page .footer-box:hover .hidden-dlt {
  visibility: visible;
}
.template-page .hidden-dlt {
  margin-top: 2px;
  margin-right: 2px;
  top: 0;
  right: 0;
  height: 22px;
  width: 22px;
  font-size: 18px;
  visibility: hidden;
  border: none;
  border-radius: 2px;
  color: white;
  background-color: #808080;
  position: absolute;
  z-index: 10;
}
.template-page .hidden-move {
  margin-top: 26px;
  margin-right: 2px;
  top: 0;
  right: 0;
  height: 22px;
  width: 22px;
  font-size: 18px;
  visibility: hidden;
  border: none;
  border-radius: 2px;
  color: white;
  background-color: #808080;
  position: absolute;
  z-index: 10;
  cursor: all-scroll;
}
.template-page .k-dialog {
  height: auto !important;
  width: auto !important;
}
.template-page .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.template-page .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.template-page .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.template-page .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.template-page .item-list {
  width: 100%;
  padding: 10px;
  padding-bottom: 25px;
  border-bottom: solid 1px #cccccc;
  min-height: 60px;
  background: white;
  overflow: hidden;
  display: block;
}
.template-page .item-list-body {
  width: 100%;
  padding: 10px;
  padding-bottom: 25px;
  border-bottom: solid 1px #cccccc;
  min-height: 60px;
  background: white;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.template-page .item-list-body-col {
  width: calc((100% - 10px) / 2);
  display: block;
}
.template-page .item-list-label {
  width: 100%;
}
.template-page .cdk-drag-placeholder {
  opacity: 0.5;
}
.template-page .body-box:last-child {
  border: none;
}
.template-page .body-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.template-page .menu-bar {
  background-color: #ffffff;
  align-items: center;
  color: #494949;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 12px;
  padding-top: 14px;
}
.template-page .menu-bar:hover {
  overflow-x: auto;
}
.template-page .menu-bar::-webkit-scrollbar {
  height: 4px;
}
.template-page .menu-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.template-page .menu-bar::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.template-page .right-block {
  overflow: hidden;
  padding-right: 10px !important;
}
.template-page .right-block .k-content {
  padding-top: 0px !important;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.template-page .right-block .page-save {
  height: 80px;
  border: solid 1px #ebebeb;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.template-page .right-block .page-save .page-save-icon {
  font-size: 16px;
  cursor: pointer;
}
.template-page .right-block .page-save .page-publish {
  height: 100%;
  border: none;
  border-radius: 5px;
  color: black;
  background-color: #d8a750;
  font-weight: bold;
  width: 45%;
  padding: 0 20px;
}
.template-page .right-block .page-save .preview-button {
  width: 45%;
  height: 100%;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  background-color: #5350D8;
  font-weight: bold;
  padding: 0 20px;
}
.template-page .right-block .k-content::-webkit-scrollbar {
  width: 10px;
}
.template-page .right-block .k-content::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.template-page .right-block .k-content::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 2px;
}
.template-page iframe .container-fuild {
  display: none !important;
}
.template-page .box {
  height: calc(100vh - 100px) !important;
  width: 100%;
  overflow: hidden !important;
}
.template-page .box .container-fuild {
  display: none !important;
}
.template-page .k-tabstrip-items {
  border: solid 1px #ebebeb;
  border-top: none;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.template-page .k-content.k-dialog-content {
  padding-top: 16px !important;
}
.template-page .k-tabstrip-items .k-item {
  color: #8b8b8b;
  border: none;
}
.template-page .k-tabstrip-items .k-item.k-state-active,
.template-page .k-tabstrip-items .k-item.k-state-selected {
  color: #004A9A;
  font-weight: 700;
  border-width: 0 0 4px;
  border-bottom: solid 2px #004A9A;
}

.item-box-header {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-box-footer {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-box-layout {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-box-body {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-img-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-footer {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-body {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-layout {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.cdk-drag-preview {
  display: flex;
  flex-wrap: nowrap !important;
  padding: 0px !important;
  margin: 0px !important;
}
.cdk-drag-preview .item-img {
  margin: -15px;
  width: calc(100% + 30px);
}
.cdk-drag-preview .item-img-footer {
  margin: -15px;
  width: calc(100% + 30px);
}
.cdk-drag-preview .item-img-body {
  margin: -15px;
  width: calc(100% + 30px);
}
.cdk-drag-preview .layout-group {
  box-sizing: border-box;
  border: solid 1px #cccccc;
}
.cdk-drag-preview .hidden-dlt {
  visibility: hidden;
}
.cdk-drag-preview .hidden-move {
  visibility: hidden;
}
.cdk-drag-preview .body-box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.header-preview {
  top: -125px !important;
  left: -450px !important;
}

.layout_100-preview {
  top: -50px !important;
  left: -450px !important;
}

.layout_80_20-preview {
  top: -50px !important;
  left: -450px !important;
}

.layout_70_30-preview {
  top: -50px !important;
  left: -450px !important;
}

.template-1-preview {
  top: -240px !important;
  left: -450px !important;
}

.template-2-preview {
  top: -220px !important;
  left: -320px !important;
}

.template-3-preview {
  top: -200px !important;
  left: -135px !important;
}

.template-4-preview {
  top: -100px !important;
  left: -135px !important;
}

.template-5-preview {
  top: -195px !important;
  left: -450px !important;
}

.template-7-preview {
  top: -195px !important;
  left: -450px !important;
}

.template-8-preview {
  top: -67px !important;
  left: -135px !important;
}

.template-9-preview {
  top: -112px !important;
  left: -135px !important;
}

.banner-1-preview {
  top: -250px !important;
  left: -450px !important;
}

.banner-2-preview {
  top: -222px !important;
  left: -450px !important;
}

.template-13-preview {
  top: -332px !important;
  left: -135px !important;
}

.footer-preview {
  top: -133px !important;
  left: -450px !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
}

.nav-item,
.nav-item-selected {
  box-sizing: border-box;
  padding: 8px 20px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}

.nav-item-selected {
  border: none;
  font-weight: 700;
  color: #007fae;
}

.field-name {
  margin-top: 10px;
  font-size: 15px;
}

.search-place {
  width: 100% !important;
  margin-top: 15px;
  padding: 4px 10px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.search-place input {
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.search-place input::placeholder {
  font-weight: 400;
  color: #000000;
}
.search-place input:focus {
  outline: none;
}
.search-place i {
  color: gray;
}

.dropdown {
  padding: 0 2px;
}
.dropdown kendo-combobox {
  width: 100% !important;
}
.dropdown .k-dropdown-wrap {
  background-color: white;
  border: none;
}
.dropdown .k-state-focused {
  box-shadow: none;
}
.dropdown .k-select {
  background-color: white;
  background-image: none;
}
.dropdown .k-input {
  font-size: 16px;
  line-height: normal;
  height: 100%;
}
.dropdown .k-i-close {
  height: 100%;
}

@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  animation: mykey 0.3s ease-out;
}

.data-menu .err-text,
.data-menu .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.data-menu .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.data-menu .filter {
  border-bottom: solid 1px #ebebeb;
  box-sizing: border-box;
  position: relative;
}
.data-menu .filter .k-textbox {
  border: none;
}
.data-menu .filter .k-input-inner::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: #808080;
}
.data-menu .img3 {
  margin-left: 10px;
  margin-right: 10px;
  z-index: 999;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12.5px;
}
.data-menu .img4 {
  position: absolute !important;
  top: 5px !important;
}
.data-menu .img5 {
  cursor: pointer;
}
.data-menu .control-search .btn-remove {
  width: 29px;
  height: 29px;
  right: 38px;
  float: right;
  border-radius: 0;
  margin-top: -30px;
  border: 0;
}
.data-menu .control-search input {
  float: left;
  padding-left: 45px;
  height: 45px;
}
.data-menu .control-search {
  position: relative;
}
.data-menu .control-search .k-button {
  z-index: 999;
  position: absolute;
  top: 35px;
  right: 0px;
}
.data-menu .control-search .btn-search {
  position: relative;
  width: 38px;
  height: 29px;
  float: left;
  border-radius: 0;
  margin-top: -30px;
  border: 0;
  background-color: #985d1a !important;
}
.data-menu .xbutton-dialog {
  padding: 0;
  /* border-radius: 50%;
    opacity: .8; */
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 5px;
  top: 5px;
  color: black !important;
  /* background-color: #bdbdbd !important; */
  font-size: 12px !important;
}
.data-menu .x-dialog {
  max-width: 768px !important;
  max-height: 75% !important;
}
.data-menu .btn-addNew {
  height: 102px;
  width: calc(25% - 16px);
  position: fixed;
  background-color: white;
  bottom: 0px;
  right: 9px;
  padding: 15px;
  text-align: right;
  display: inherit;
  box-sizing: border-box;
}
.data-menu .btn-addNew .k-button {
  border-radius: 7px;
  background: #df7242;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}
.data-menu .pop-up-title {
  font-size: 20px;
  font-weight: 500;
  color: #004A9A;
  text-align: center;
  margin: 30px;
}
.data-menu .input-label {
  margin: 10px;
  margin-left: 15px;
}
.data-menu .input-box {
  height: 40px;
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.data-menu .btn-save {
  margin: 15px;
  height: 41px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}
.data-menu .btn-custom {
  width: 100%;
}
.data-menu .btn-custom button {
  background: none;
  color: gray;
  width: 100%;
}
.data-menu .wb-data-menu {
  display: none;
}
.data-menu .mb-data-menu {
  display: contents !important;
}
.data-menu .mb-data-menu .item-menu {
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0px 0px 12px !important;
  margin: 7px 0;
  padding: 12px;
  padding-right: 15px;
  border: 0px solid #c4c4c4;
  border-radius: -2px;
  width: 100%;
}
.data-menu .mb-data-menu .active {
  color: #4a4a4a;
}
.data-menu .mb-data-menu .passive {
  color: #b9b9b9;
}
.data-menu .mb-data-menu .mb-drbtn-func {
  padding-right: 5px;
}
.data-menu .mb-data-menu .mb-drbtn-func .k-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 34px;
}
.data-menu .mb-data-menu .btn-custom button {
  background: none;
  color: gray;
  width: 100%;
  border: none;
}
.data-menu .k-window {
  width: 500px;
}
.data-menu .x-dialog {
  max-width: 500px;
  max-height: none;
}
.data-menu .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.data-menu .selected-lang {
  border-bottom: 3px red solid;
  cursor: pointer;
}
@media (max-width: 386px) {
  .data-menu .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .data-menu .k-window {
    width: 90%;
    height: 90%;
  }
  .data-menu .x-dialog {
    max-width: 500px;
    max-height: none;
  }
}
@media (max-width: 386px) {
  .data-menu .btn-addNew {
    text-align: right;
    display: inherit;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .data-menu .wb-data-menu {
    display: none;
  }
  .data-menu .mb-data-menu {
    display: contents !important;
  }
  .data-menu .mb-data-menu .item-menu {
    font-size: 16px;
    font-weight: 500;
    color: #4a4a4a;
    margin: 10px 15px 10px 15px !important;
    margin: 7px;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
  }
  .data-menu .mb-data-menu .mb-drbtn-func {
    padding-right: 5px;
  }
  .data-menu .mb-data-menu .mb-drbtn-func .k-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 34px;
  }
  .data-menu .mb-data-menu .btn-custom button {
    background: none;
    color: gray;
    width: 100%;
    border: none;
  }
  .data-menu .k-dropdown-button button {
    background: none !important;
    color: black !important;
    border: none !important;
  }
  .data-menu .x-dialog.data-menu-info {
    width: 100% !important;
  }
}
.data-menu .mb-data-menu {
  display: contents;
}
.data-menu .mb-data-menu .k-list {
  width: 105px;
}
.data-menu .check-box {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin-right: 5px;
}
.data-menu .col-10 {
  flex: 0 0 auto !important;
  width: 80.33333333% !important;
}
.data-menu .k-tabstrip {
  border-width: 0;
  border-color: transparent;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 16px !important;
  line-height: 1.4285714286;
  color: inherit;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.data-menu .router-outlet-content {
  width: 1024px;
  width: 100%;
  max-width: 1024px;
  margin: auto;
}
.data-menu .k-dropdown .k-dropdown-wrap,
.data-menu .k-dropdowntree .k-dropdown-wrap {
  background-clip: padding-box;
  border-radius: 2px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: 0;
  color: #656565;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.data-menu .k-button .k-icon,
.data-menu .k-button .k-image,
.data-menu .k-button .k-sprite {
  color: inherit;
  align-self: center;
  position: relative;
}
.data-menu .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 32px;
  float: right;
  border-radius: 0;
  margin-top: 2px !important;
  border: 0;
  margin-right: -38px;
}
.data-menu .k-button .k-icon,
.data-menu .k-button .k-image,
.data-menu .k-button .k-sprite {
  color: inherit;
  align-self: center;
  position: relative;
}
.data-menu .k-icon {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: WebComponentsIcons;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}
.data-menu .k-button-outline.k-primary,
.data-menu .k-primary.k-button.k-outline {
  border-color: currentColor;
  color: black !important;
  background: none;
  box-shadow: none;
}
.data-menu .k-tabstrip-top > .k-content,
.data-menu .k-tabstrip-top > .k-tabstrip-content {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-width: 1px !important;
  height: 700px;
}

.cdk-drag-preview .item-menu-drag {
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  margin: 7px;
  border-radius: -2px;
  width: 100%;
}
.cdk-drag-preview .btn-custom button {
  background: none;
  color: gray;
  width: 100%;
  border: none;
}

footer.ver3 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  background-color: #00396B;
}
footer.ver3 .mobile {
  display: none;
}
footer.ver3 .desktop {
  display: block;
}
footer.ver3 .pointer {
  cursor: pointer;
}
footer.ver3 .footer2 {
  color: white;
  padding: 20px 0 10px 0;
}
footer.ver3 .footer2 .row {
  display: flex;
  flex-direction: row;
}
footer.ver3 .footer2 .title span {
  display: inline !important;
  width: fit-content;
  font-style: normal;
  padding-bottom: 10px;
}
footer.ver3 .footer2 .info .title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
footer.ver3 .footer2 .info ul {
  list-style-type: none;
}
footer.ver3 .footer2 .info ul i {
  font-size: 1rem;
}
footer.ver3 .footer2 .description {
  margin: 15px 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}
footer.ver3 .footer2 ul {
  padding: 0;
  margin-bottom: 0;
}
footer.ver3 .footer2 .info {
  padding-right: 1em;
}
footer.ver3 .footer2 .menu ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 9em;
  list-style-type: disc;
  margin-left: 10px;
}
footer.ver3 .footer2 .menu li {
  width: 50%;
}
footer.ver3 .footer2 li {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}
footer.ver3 .footer2 li:hover .btn-func,
footer.ver3 .footer2 .info:hover .btn-func {
  visibility: visible;
}
footer.ver3 .footer2 li a {
  color: white;
  text-decoration: none;
}
footer.ver3 .footer2 .btn-func {
  visibility: hidden;
}
footer.ver3 .footer2 .btn-custom {
  width: fit-content;
  background: initial;
  color: white;
}
footer.ver3 .footer2 .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: white;
  border: none;
}
footer.ver3 .footer2 .btn-custom .k-button {
  padding: 0 !important;
}
footer.ver3 .footer2 .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
footer.ver3 .footer2 .k-popup .k-item {
  width: 100%;
  padding: 5px;
}
footer.ver3 .footer2 .handle:hover {
  cursor: pointer;
}
footer.ver3 .footer2 .btn-add {
  background-color: inherit;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid white;
  border-radius: 2px;
  height: 2.5em;
}
footer.ver3 .footer2 .btn-add:hover {
  background-color: #074973;
}
footer.ver3 .footer2 .menu,
footer.ver3 .footer2 .contact {
  height: 80px;
  border-left: 1px solid #FFFFFF;
  padding-left: 2em;
}
footer.ver3 .footer2 .contact .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
footer.ver3 .footer2 .contact .social-icon {
  margin-top: 15px;
}
footer.ver3 .footer2 .contact .social-icon a {
  margin: 0 12px;
}
footer.ver3 .footer2 .contact .social-icon a img {
  height: 20px;
  width: 20px;
}
footer.ver3 .footer2 .contact .social-icon i {
  color: white;
}
footer.ver3 .copyright {
  background-color: #08192B;
  width: 100%;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 15px 0;
}
footer.ver3 .edit .required,
footer.ver3 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
footer.ver3 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
footer.ver3 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
footer.ver3 .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}

@media screen and (max-width: 1024px) {
  footer.ver3 .footer2 {
    padding: 0 10px;
  }
  footer.ver3 .footer2 .info,
footer.ver3 .footer2 .menu,
footer.ver3 .footer2 .contact {
    border-bottom: 1px solid white;
    margin: 10px 0 0;
  }
  footer.ver3 .footer2 .menu {
    height: auto;
  }
  footer.ver3 .footer2 .btn-func {
    visibility: visible;
  }
}
@media screen and (max-width: 576px) {
  footer.ver3 {
    width: 100% !important;
  }
  footer.ver3 .mobile {
    display: block;
  }
  footer.ver3 .desktop {
    display: none;
  }
  footer.ver3 .btn-add {
    width: 25% !important;
  }
  footer.ver3 .footer2 {
    padding: 0 5px;
  }
  footer.ver3 .footer2 .info,
footer.ver3 .footer2 .menu,
footer.ver3 .footer2 .contact {
    border-bottom: 1px solid white;
    margin: 5px 0 0;
    padding: 0 1.5rem;
  }
  footer.ver3 .footer2 .menu {
    height: auto;
  }
}
@media screen and (max-width: 800px) {
  footer.ver3 {
    width: 100% !important;
  }
  footer.ver3 .add-menu.x-dialog {
    width: 95% !important;
    height: 95% !important;
  }
}
header-template2 {
  padding: 0;
  margin: 0;
  position: relative;
}
header-template2 nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
header-template2 #BannerBackGround {
  background-size: cover;
}
header-template2 .row-height {
  height: 40px;
}
header-template2 .nav-bar {
  margin: auto;
  width: calc(100% - 250px);
  min-width: 80%;
  max-width: 1550px;
}
header-template2 .nav-menu-box {
  background-color: #00396B;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 999;
  margin: auto;
  box-shadow: 0px 2px 0px 0px #D6A022;
}
header-template2 .nav-menu-box .nav-content {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: inline-flex;
}
header-template2 .nav-menu-box .padding-0 {
  padding: 0px;
}
header-template2 .nav-menu-box .home-icon {
  margin-top: 5px;
}
header-template2 .nav-menu-box .home-icon,
header-template2 .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header-template2 .menu-icon-box {
  display: none;
}
header-template2 .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: space-between;
}
header-template2 .nav-menu .dropdown {
  height: fit-content;
  text-align: center;
  display: inline-block;
}
header-template2 .nav-menu .dropdown .material-icons {
  color: white;
  padding-top: 5px;
  font-size: 18px;
}
header-template2 .nav-menu .dropdown .dropdown-home {
  margin: auto;
}
header-template2 .nav-menu .dropdown .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header-template2 .nav-menu .dropdown .item-link {
  color: white;
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  height: 100%;
  width: fit-content;
  padding: 10px 10px;
  cursor: pointer;
}
header-template2 .nav-menu .dropdown .item-link:hover,
header-template2 .nav-menu .dropdown .item-link:active,
header-template2 .nav-menu .dropdown .focus {
  background-color: white !important;
  color: #00396B;
  height: 100%;
  border-radius: 0;
}
header-template2 .nav-menu .dropdown .dropdown-menu {
  background-color: inherit;
  position: relative;
  border: none;
  min-width: 0;
}
header-template2 .nav-menu .dropdown .dropdown-submenu {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 35px;
  left: 0;
}
header-template2 .nav-menu .dropdown .dropdown-submenu a {
  display: block;
}
header-template2 .nav-menu .dropdown .dropdown-item:hover {
  background-color: #dddddd;
}
header-template2 .nav-menu .dropdown .dropdown-item {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border-top: 1px solid #DEDEDE;
  padding: 0.6rem 1rem;
}
header-template2 .nav-menu .dropdown .dropdown:hover {
  background-color: #133d6c;
}
header-template2 .disable-click {
  pointer-events: none;
  cursor: default;
}
header-template2 .nav-top {
  border-bottom: 1px solid #D6D6D6;
  width: calc(100% - 250px);
  margin: 0 auto;
  max-width: 1550px;
}
header-template2 .nav-top .contact {
  margin: auto;
  background-color: white;
  position: relative;
  padding-top: 1px;
}
header-template2 .nav-top .contact .search {
  margin: auto 0;
  height: 40px;
}
header-template2 .nav-top .contact .search .form-control {
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #373737;
  margin-top: 5px;
}
header-template2 .nav-top .contact .language {
  font-size: 13px;
  border-left: 1px solid #ECECEC;
  right: 15px;
  color: white;
  margin: auto 0;
  text-align: center;
  cursor: pointer;
  height: 40px;
}
header-template2 .nav-top .contact .language .mat-form-field {
  max-width: 50px;
  margin-top: -5px;
}
header-template2 .nav-top .contact .language .mat-form-field-underline {
  display: none;
}
header-template2 .banner {
  position: relative;
  margin: 20px auto;
  width: calc(100% - 250px);
  max-width: 1550px;
  margin: 0 auto;
}
header-template2 .banner .row {
  height: 100%;
}
header-template2 .banner .img1 {
  max-width: 100%;
  height: 100%;
  z-index: 5;
  object-fit: contain;
  cursor: pointer;
  padding: 10px 0px;
}
header-template2 .banner .name {
  margin: auto 0;
}
header-template2 .banner .name a {
  text-decoration: none;
}
header-template2 .banner .name-main,
header-template2 .banner .name-detail {
  display: inline !important;
}
header-template2 .banner .name-main .edit-logo,
header-template2 .banner .name-detail .edit-logo {
  position: absolute;
}
header-template2 .banner .name-main a {
  color: #00396B;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.06em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
header-template2 .banner .name-detail a {
  color: #D7A123;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
header-template2 .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header-template2 .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header-template2 .banner .search-text:focus {
  border: 1px solid white !important;
}
header-template2 .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header-template2 .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header-template2 .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header-template2 .banner .edit-logo {
  visibility: hidden;
}
header-template2 .banner .logo-container:hover .edit-logo,
header-template2 .banner .name:hover .edit-logo {
  visibility: visible;
}
header-template2 .banner .logo-container {
  display: flex;
  flex-direction: row;
}
header-template2 .banner .logo-container .btn-custom {
  width: fit-content;
}
header-template2 .banner .logo-container .k-upload {
  display: none;
}
header-template2 .banner .material-icons {
  font-size: 18px;
}
header-template2 .banner .menu .menus {
  display: inline !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header-template2 .banner .menu .menus .menu-item {
  display: inline;
  width: 33%;
  padding-right: 10px;
}
header-template2 .banner .menu .menus button {
  display: inline;
}
header-template2 .banner .login {
  margin: auto 0;
}
header-template2 .banner .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template2 .banner .btn-custom {
  background: initial;
  color: inherit;
}
header-template2 .banner .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: inherit;
  border: none;
}
header-template2 .banner .btn-custom .k-button {
  padding: 0 !important;
}
header-template2 .banner .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
header-template2 .banner .k-popup .k-item {
  width: 100%;
}
header-template2 .banner .btn-add {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
}
header-template2 .banner .btn-add:hover {
  background-color: #f3efef;
}
header-template2 .banner .dropdown {
  position: relative;
  display: inline-block;
}
header-template2 .banner .dropdown-bg {
  padding-top: 20px;
}
header-template2 .banner .dropdown-content {
  display: none;
  position: absolute;
  right: 10px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 230px;
  z-index: 1000;
}
header-template2 .banner .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #DBDBDB;
  font-size: 14px;
  color: #4B4B4B;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
header-template2 .banner .dropdown-content a:hover {
  background-color: #f1f1f1;
}
header-template2 .banner .dropdown-content a:first-child {
  border-top: none;
}
header-template2 .banner .dropdown-content .avatar {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  max-width: 60px;
  max-height: 60px;
}
header-template2 .banner .dropdown-content .avatar img {
  width: 100%;
  height: 100%;
}
header-template2 .banner .dropdown-content .user-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header-template2 .banner .dropdown-content .user-info .role {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}
header-template2 .banner .dropdown-content .user-info .btn-edit-info {
  background-color: #946711;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
}
header-template2 .banner .dropdown:hover .dropdown-content {
  display: block;
}
header-template2 .banner .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
header-template2 .user {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0;
  justify-content: flex-end;
}
header-template2 .user .change-btn {
  margin: 0 10px;
  cursor: pointer;
}
header-template2 .user .avatar {
  margin: 0 10px;
  cursor: pointer;
}
header-template2 .user .avatar img {
  width: 3rem;
  height: 3rem;
}
header-template2 .user .change-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  min-width: 3rem;
  height: 3rem;
}
header-template2 .user .change-btn img {
  width: 1.5rem;
  height: 1.5rem;
}
header-template2 .pointer {
  cursor: pointer;
}
header-template2 .open-menu {
  display: block !important;
}
header-template2 .close-menu {
  display: none !important;
}
header-template2 .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template2 .edit .required,
header-template2 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
header-template2 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
header-template2 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
header-template2 .btn-save {
  color: white;
  background-color: #0b68a4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}
header-template2 kendo-dialog .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

@media screen and (max-width: 576px) {
  header-template2 .desktop,
header-template2 .desktop-icon {
    display: none !important;
  }
  header-template2 .mobile {
    display: block !important;
  }
  header-template2 nav ul {
    width: auto;
  }
  header-template2 .nav-menu-box {
    height: 1px !important;
  }
  header-template2 .nav-top {
    border-bottom: 1px solid #D6D6D6;
  }
  header-template2 .nav-top .contact {
    margin: auto;
    background-color: white;
    position: relative;
    padding-top: 1px;
  }
  header-template2 .nav-top .contact .search {
    margin: auto 0;
  }
  header-template2 .nav-top .contact .language .mat-form-field {
    width: 65%;
  }
  header-template2 .banner {
    width: 100% !important;
    min-height: auto;
  }
  header-template2 .banner .img1 {
    height: 50px;
    top: 6px;
    left: 50%;
    position: relative;
  }
  header-template2 .banner .logo-container {
    display: block;
  }
  header-template2 .banner .name {
    padding: 0;
    margin: 0 auto;
  }
  header-template2 .banner .name hgroup {
    text-align: center;
  }
  header-template2 .banner .user {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 10px;
  }
  header-template2 .nav-menu {
    font-size: 95% !important;
  }
  header-template2 .nav-menu .dropdown,
header-template2 .nav-menu .item-link {
    display: block;
    text-align: left;
    width: 100% !important;
  }
  header-template2 .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header-template2 .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header-template2 .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header-template2 .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header-template2 .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header-template2 .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header-template2 .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
    top: 10px !important;
  }
  header-template2 .nav-menu .dropdown-menu {
    display: none;
    width: 100% !important;
    border-top: 1px solid #4569cf;
  }
  header-template2 .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header-template2 .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header-template2 .nav-menu .show {
    display: block !important;
  }
  header-template2 .nav-menu .dropdown-menu,
header-template2 .nav-menu .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header-template2 .pading-0 {
    padding: 10px 0 !important;
  }
  header-template2 .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header-template2 .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header-template2 .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header-template2 .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header-template2 .dropdown-item {
    color: #000;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    padding: 0.5rem 0.7rem !important;
    font-weight: bold;
  }
  header-template2 .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header-template2 .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header-template2 .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header-template2 .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header-template2 .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header-template2 .navbar-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0px;
    margin-left: 0%;
    width: 65%;
    background: var(--main-color);
    height: 4000px !important;
    position: fixed;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header-template2 .search-cover-shadow,
header-template2 .navbar-cover-shadow {
    width: 100%;
    top: 0;
    background: var(--main-color);
    height: 4000px;
    position: fixed;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header-template2 .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header-template2 .navbar-hidden {
    display: none;
  }
  header-template2 #navbardropdownMenuLink {
    text-align: left !important;
  }
  header-template2 .login-btn {
    position: relative;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100vw - 20px) !important;
  }
  header-template2 .left-block {
    width: 100%;
    position: relative !important;
  }
}
@media screen and (min-width: 575px) {
  header-template2 .desktop {
    display: block !important;
  }
  header-template2 .mobile {
    display: none !important;
  }
  header-template2 .top-right-menu {
    display: inline !important;
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 15px;
  }
  header-template2 .dropdown-menu:hover .dropdown-submenu {
    display: block;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
header-template3 {
  padding: 0;
  margin: 0;
  position: relative;
}
header-template3 nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
header-template3 .nav-menu-box {
  background-color: #007FAE;
  width: 100%;
  height: 40px !important;
  display: inline-block;
  position: relative;
  z-index: 999;
  margin: auto;
  box-shadow: 0px 2px 0px 0px #D6A022;
}
header-template3 .nav-menu-box .nav-content {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: inline-flex;
}
header-template3 .nav-menu-box .home-icon {
  margin-top: 5px;
}
header-template3 .nav-menu-box .home-icon,
header-template3 .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header-template3 .nav-menu-box .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: center;
}
header-template3 .nav-menu-box .nav-menu .dropdown {
  margin: 0 auto;
  height: fit-content;
  text-align: center;
  display: inline-block;
}
header-template3 .nav-menu-box .nav-menu .dropdown .k-icon {
  font-size: 20px !important;
}
header-template3 .nav-menu-box .nav-menu .dropdown .material-icons {
  color: white;
  padding-top: 5px;
  font-size: 18px;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-home {
  margin: auto;
}
header-template3 .nav-menu-box .nav-menu .dropdown .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header-template3 .nav-menu-box .nav-menu .dropdown .item-link {
  color: white;
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  height: 100%;
  width: fit-content;
  padding: 8px 10px;
  cursor: pointer;
}
header-template3 .nav-menu-box .nav-menu .dropdown .item-link:hover,
header-template3 .nav-menu-box .nav-menu .dropdown .item-link:active,
header-template3 .nav-menu-box .nav-menu .dropdown .focus {
  background-color: white !important;
  color: #007FAE;
  height: 100%;
  border-radius: 0;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-menu {
  background-color: inherit;
  position: relative;
  border: none;
  min-width: 0;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-menu:hover .dropdown-submenu,
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-menu:active .dropdown-submenu {
  display: block;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-submenu {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 42px;
  left: 0;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-submenu a {
  display: block;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-item:hover {
  background-color: #dddddd;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown-item {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid #DEDEDE;
  padding: 0.6rem 1rem;
}
header-template3 .nav-menu-box .nav-menu .dropdown .dropdown:hover {
  background-color: #133d6c;
}
header-template3 .nav-menu-box .login-area {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
}
header-template3 .nav-menu-box .login-area .search {
  font-size: 16px;
  cursor: pointer;
}
header-template3 .nav-menu-box .login-area .login.item-link {
  color: white;
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  height: 100%;
  width: fit-content;
  padding: 8px 10px;
  cursor: pointer;
}
header-template3 .nav-menu-box .login-area .login.item-link:hover {
  background-color: white !important;
  color: #007FAE;
  height: 100%;
  border-radius: 0;
}
header-template3 .menu-icon-box {
  display: none;
}
header-template3 .sub-menu .sub-item-link {
  color: #575757;
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  height: 100%;
  width: fit-content;
  padding: 8px 10px;
  cursor: pointer;
}
header-template3 .sub-menu .item-link:hover,
header-template3 .sub-menu .item-link:active,
header-template3 .sub-menu .focus {
  background-color: white !important;
  color: #00396B;
  height: 100%;
  border-radius: 0;
}
header-template3 .disable-click {
  pointer-events: none;
  cursor: default;
}
header-template3 .banner {
  position: relative;
  margin: 20px auto;
}
header-template3 .banner .row {
  height: 100%;
}
header-template3 .banner .row > * {
  padding: 0;
}
header-template3 .banner .img1 {
  max-width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  object-fit: cover;
  cursor: pointer;
}
header-template3 .banner .name {
  margin: auto 0;
}
header-template3 .banner .name a {
  text-decoration: none;
}
header-template3 .banner .name-main,
header-template3 .banner .name-detail {
  display: inline !important;
}
header-template3 .banner .name-main .edit-logo,
header-template3 .banner .name-detail .edit-logo {
  position: absolute;
}
header-template3 .banner .name-main a {
  color: #007FAE;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -0.06em;
  text-shadow: -2px -2px 3px rgba(0, 0, 0, 0.23);
}
header-template3 .banner .name-detail a {
  color: #4F5355;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
}
header-template3 .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header-template3 .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header-template3 .banner .search-text:focus {
  border: 1px solid white !important;
}
header-template3 .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header-template3 .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header-template3 .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header-template3 .banner .edit-logo {
  visibility: hidden;
}
header-template3 .banner .logo-container:hover .edit-logo,
header-template3 .banner .name:hover .edit-logo {
  visibility: visible;
}
header-template3 .banner .logo-container {
  display: flex;
  flex-direction: row;
}
header-template3 .banner .logo-container .btn-custom {
  width: fit-content;
}
header-template3 .banner .logo-container .k-upload {
  display: none;
}
header-template3 .banner .material-icons {
  font-size: 18px;
}
header-template3 .banner .menu .menus {
  display: inline !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header-template3 .banner .menu .menus .menu-item {
  display: inline;
  width: 33%;
  padding-right: 10px;
}
header-template3 .banner .menu .menus button {
  display: inline;
}
header-template3 .banner .login {
  margin: auto 0;
}
header-template3 .banner .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template3 .banner .btn-custom {
  background: initial;
  color: inherit;
}
header-template3 .banner .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: inherit;
  border: none;
}
header-template3 .banner .btn-custom .k-button {
  padding: 0 !important;
}
header-template3 .banner .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
header-template3 .banner .k-popup .k-item {
  width: 100%;
}
header-template3 .banner .btn-add {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
}
header-template3 .banner .btn-add:hover {
  background-color: #f3efef;
}
header-template3 .banner .dropdown {
  position: relative;
  display: inline-block;
}
header-template3 .banner .dropdown-bg {
  padding-top: 20px;
}
header-template3 .banner .dropdown-content {
  display: none;
  position: absolute;
  right: 10px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 230px;
  z-index: 1000;
}
header-template3 .banner .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #DBDBDB;
  font-size: 14px;
  color: #4B4B4B;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
header-template3 .banner .dropdown-content a:hover {
  background-color: #f1f1f1;
}
header-template3 .banner .dropdown-content a:first-child {
  border-top: none;
}
header-template3 .banner .dropdown-content .avatar {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  min-width: 60px;
  min-height: 60px;
}
header-template3 .banner .dropdown-content .avatar img {
  width: 100%;
  height: 100%;
}
header-template3 .banner .dropdown-content .user-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header-template3 .banner .dropdown-content .user-info .role {
  font-weight: bold;
  font-size: 16px;
}
header-template3 .banner .dropdown-content .user-info .btn-edit-info {
  background-color: #946711;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
}
header-template3 .banner .dropdown:hover .dropdown-content {
  display: block;
}
header-template3 .banner .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
header-template3 .user {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0;
}
header-template3 .user .change-btn,
header-template3 .user .avatar {
  margin: 0 10px;
  cursor: pointer;
  height: 44px;
  width: 44px;
}
header-template3 .user .change-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  min-width: 44px;
  min-height: 44px;
}
header-template3 .user .change-btn img {
  width: 28px !important;
  height: 28px !important;
}
header-template3 .pointer {
  cursor: pointer;
}
header-template3 .open-menu {
  display: block !important;
}
header-template3 .close-menu {
  display: none !important;
}
header-template3 .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template3 .edit .required,
header-template3 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
header-template3 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
header-template3 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
header-template3 .btn-save {
  color: white;
  background-color: #0b68a4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}

@media screen and (max-width: 576px) {
  header-template3 .desktop,
header-template3 .desktop-icon {
    display: none !important;
  }
  header-template3 .mobile {
    display: block !important;
  }
  header-template3 nav ul {
    width: auto;
  }
  header-template3 .nav-menu-box {
    height: 1px !important;
  }
  header-template3 .nav-top {
    border-bottom: 1px solid #D6D6D6;
  }
  header-template3 .nav-top .contact {
    margin: auto;
    background-color: white;
    position: relative;
    padding-top: 1px;
  }
  header-template3 .nav-top .contact .search {
    margin: auto 0;
  }
  header-template3 .nav-top .contact .language .mat-form-field {
    width: 70%;
  }
  header-template3 .banner {
    width: 100% !important;
    min-height: auto;
  }
  header-template3 .banner .img1 {
    height: 50px;
    top: 6px;
    left: 50%;
    position: relative;
  }
  header-template3 .banner .logo-container {
    display: block;
  }
  header-template3 .banner .name {
    margin: 0;
    padding: 0;
    margin: 5px 0;
  }
  header-template3 .banner .name hgroup {
    text-align: center;
  }
  header-template3 .banner .name hgroup .name-main a {
    font-size: 26px;
  }
  header-template3 .banner .name hgroup .name-detail a {
    font-size: 23px;
  }
  header-template3 .banner .user {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 10px;
  }
  header-template3 .nav-menu {
    font-size: 95% !important;
  }
  header-template3 .nav-menu .dropdown,
header-template3 .nav-menu .item-link {
    display: block;
    text-align: left;
    width: 100% !important;
  }
  header-template3 .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header-template3 .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header-template3 .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header-template3 .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header-template3 .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header-template3 .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header-template3 .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
  }
  header-template3 .nav-menu .dropdown-menu {
    display: none;
    width: 100%;
    border-top: 1px solid #4569cf;
  }
  header-template3 .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header-template3 .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header-template3 .dropdown-menu,
header-template3 .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header-template3 .pading-0 {
    padding: 10px 0 !important;
  }
  header-template3 .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header-template3 .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header-template3 .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header-template3 .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header-template3 .dropdown-item {
    color: #000;
    padding: 5px 0px;
    font-weight: bold;
  }
  header-template3 .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header-template3 .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header-template3 .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header-template3 .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header-template3 .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header-template3 .navbar-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0px;
    margin-left: 0%;
    width: 70%;
    background: var(--main-color);
    height: 4000px !important;
    position: fixed;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header-template3 .search-cover-shadow,
header-template3 .navbar-cover-shadow {
    width: 100%;
    top: 0;
    background: var(--main-color);
    height: 4000px;
    position: fixed;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header-template3 .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header-template3 .navbar-hidden {
    display: none;
  }
  header-template3 #navbardropdownMenuLink {
    text-align: left !important;
  }
  header-template3 .login-btn {
    position: relative;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100vw - 20px) !important;
  }
  header-template3 .left-block {
    width: 100%;
    position: relative !important;
  }
}
@media screen and (min-width: 575px) {
  header-template3 .desktop {
    display: block !important;
  }
  header-template3 .mobile {
    display: none !important;
  }
  header-template3 .banner {
    height: 100px;
  }
  header-template3 .top-right-menu {
    display: inline !important;
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 15px;
  }
}
@media screen and (max-width: 1025px) {
  header-template3 .nav-menu {
    font-size: 85%;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
kendo-dialog .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

.news-template-1-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-1-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-1-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-1-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-1-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-1-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-1-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-1-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-1-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-1-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-1-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-1-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-1-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-1-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-1-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-1-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-1-dialog .float-right {
  float: right;
}

.news-template-1-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-1-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-1-dialog .control {
  margin-bottom: 22px;
}

.news-template-1-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-1-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-1-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-1-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-1-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-1-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-2 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-2 .row {
  --bs-gutter-x: 0;
}
.news-template-2 .full-width {
  width: 100%;
}
.news-template-2 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-2 .pd-l-10 {
  padding-left: 18px;
}
.news-template-2 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  min-height: 40px;
}
.news-template-2 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-2 .post-more a {
  color: #EB2E35;
}
.news-template-2 .pointer {
  cursor: pointer;
}
.news-template-2 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-2 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-template-2 .post-image, .news-template-2 .post-image-cover {
  height: 198px;
  width: 100%;
}
.news-template-2 .post-image-cover {
  object-fit: cover;
}
.news-template-2 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-2 .time-clock {
  display: flex;
}
.news-template-2 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-2 .main-item {
  display: table;
  margin: 0;
}
.news-template-2 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-2 .d-flex {
  visibility: visible !important;
}
.news-template-2 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-2 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-2 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-2 .menu .menu-row,
.news-template-2 .menu .menu-row-current,
.news-template-2 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-2 .menu .menu-row .menu-item-1,
.news-template-2 .menu .menu-row .menu-item-2,
.news-template-2 .menu .menu-row-current .menu-item-1,
.news-template-2 .menu .menu-row-current .menu-item-2,
.news-template-2 .menu .menu-row-current-border .menu-item-1,
.news-template-2 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-2 .menu .menu-row-current,
.news-template-2 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-2 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-2 .img-figure, .news-template-2 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-2 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-2 .top-new-item {
  margin: 10px 0 0 0;
}
.news-template-2 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-2 .news-template-2-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-2 .news-template-2-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-2 .news-template-2-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-2 .news-template-2-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-2 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-2 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-2 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-2 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-2 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-2 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-2 .text-section :hover {
  color: #00396B;
}
.news-template-2 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
  text-decoration: none;
}
.news-template-2 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-2 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-2 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-template-2-dialog {
  margin-left: 0px !important;
}

.news-template-2-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-2-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-2-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-2-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-2-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-2-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-2-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-2-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-2-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-2-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-2-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-2-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-2-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-2-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-2-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-2-dialog .float-right {
  float: right;
}

.news-template-2-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-2-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-2-dialog .control {
  margin-bottom: 22px;
}

.news-template-2-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-2-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-2-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-2-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-2-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-2-dialog .padding-col {
  padding: 7px 15px 0;
}

.intro-page {
  width: 100%;
  position: relative;
}
.intro-page .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.intro-page .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.intro-page .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.intro-page .vertical-dot .menu .menu-row,
.intro-page .vertical-dot .menu .menu-row-current,
.intro-page .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.intro-page .vertical-dot .menu .menu-row .menu-item-1,
.intro-page .vertical-dot .menu .menu-row .menu-item-2,
.intro-page .vertical-dot .menu .menu-row-current .menu-item-1,
.intro-page .vertical-dot .menu .menu-row-current .menu-item-2,
.intro-page .vertical-dot .menu .menu-row-current-border .menu-item-1,
.intro-page .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.intro-page .vertical-dot .menu .menu-row-current,
.intro-page .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.intro-page .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.intro-page .d-flex {
  visibility: visible !important;
}
.intro-page .close-div {
  display: flex;
  margin-top: 3px;
}
.intro-page .reward-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.intro-page .reward-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.intro-page .reward-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.intro-page .reward-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.intro-page .background-img {
  position: relative;
}
.intro-page .background-img img {
  width: 100%;
}
.intro-page .col-12 {
  padding: 0;
}
.intro-page .content {
  padding: 0.25rem;
}
.intro-page .menu-bar {
  position: relative;
  margin-top: -84px !important;
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;
  justify-content: space-around;
  text-align: left;
  width: 100%;
  flex-wrap: nowrap;
}
.intro-page .menu-bar .tab {
  display: flex;
  flex-direction: row;
}
.intro-page .tab-scroll {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin: 0 1rem;
  cursor: pointer;
}
.intro-page .tab-item {
  padding: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  height: 5rem;
  color: black;
  background-color: white;
  bottom: 0px;
  transition: all 0.5s ease;
}
.intro-page .tab-des {
  margin: 30px 0 0;
  display: block;
  font-weight: bold;
  color: #00396b;
}
.intro-page .tab-des p {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396b;
}
.intro-page .tab-active {
  height: 6rem !important;
  padding-top: 25px;
  color: white;
  background-color: #d6a022;
  text-shadow: 2px 0px 4px #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.intro-page .history {
  width: 100%;
  margin: 0 0 100px 0;
}
.intro-page .history img {
  width: 100%;
}
.intro-page .news {
  border-bottom: 1px solid black;
}
.intro-page .dialog-width {
  max-width: 400px;
}

@media screen and (max-width: 1200px) {
  .intro-page .tab-item {
    padding: 0.5rem;
    font-size: 0.64rem;
    line-height: 0.8rem;
    height: 3.438rem;
  }
  .intro-page .tab-active {
    height: 4.688rem !important;
  }
  .intro-page .tab-scroll {
    margin: 0 0.375rem;
  }
  .intro-page .tab-des {
    padding: 0 15px;
  }
}
@media screen and (max-width: 576px) {
  .intro-page .tab-item {
    padding: 0.236rem;
    font-size: 0.5rem;
    line-height: 0.688rem;
    height: 3rem;
  }
  .intro-page .tab-active {
    height: 4rem !important;
  }
  .intro-page .tab-scroll {
    margin: 0 0.375rem;
  }
  .intro-page .tab-des p {
    font-size: 23px;
    line-height: 26px;
  }
}
app-intro-page {
  position: relative;
  top: -6px;
}

.organization-structure {
  width: 100%;
  height: 100%;
  font-style: normal;
}
.organization-structure .director,
.organization-structure .sub-director {
  display: block;
  height: 5rem;
  margin: 15px auto;
}
.organization-structure .director span,
.organization-structure .sub-director span {
  height: 100%;
  width: 100%;
  background-color: #E02D2D;
}
.organization-structure .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.organization-structure .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.organization-structure .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.organization-structure .vertical-dot .menu .menu-row,
.organization-structure .vertical-dot .menu .menu-row-current,
.organization-structure .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.organization-structure .vertical-dot .menu .menu-row .menu-item-1,
.organization-structure .vertical-dot .menu .menu-row .menu-item-2,
.organization-structure .vertical-dot .menu .menu-row-current .menu-item-1,
.organization-structure .vertical-dot .menu .menu-row-current .menu-item-2,
.organization-structure .vertical-dot .menu .menu-row-current-border .menu-item-1,
.organization-structure .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.organization-structure .vertical-dot .menu .menu-row-current,
.organization-structure .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.organization-structure .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.organization-structure .d-flex {
  visibility: visible !important;
}
.organization-structure .title-center {
  text-align: center !important;
}
.organization-structure .all-unit {
  width: 100%;
  margin: 30px auto;
}
.organization-structure .all-unit p {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #000;
}
.organization-structure .all-unit .unit {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 102%;
  margin: 15px -10px;
}
.organization-structure .all-unit .unit span {
  background-color: #00396B;
  margin: 15px 10px;
  height: 80px;
  padding: 0 30px;
}
.organization-structure .director span,
.organization-structure .sub-director span,
.organization-structure .unit span {
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.organization-structure .employe-detail {
  padding: 10px;
}
.organization-structure .info-list {
  width: 100%;
  height: 150px;
  margin: 25px 0;
}
.organization-structure .info-list .avatar {
  height: 100%;
  padding: 0 12px 0 0;
}
.organization-structure .info-list .avatar img {
  width: 100%;
  height: 100%;
}
.organization-structure .info-list .personal-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.organization-structure .info-list .personal-info .list {
  display: flex;
  height: 30%;
  padding-left: 10px;
  font-size: 16px;
  line-height: 19px;
}
.organization-structure .info-list .personal-info .list .row {
  display: flex;
  align-items: center;
}
.organization-structure .info-list .personal-info .list i {
  color: #D6A022;
  font-size: 18px;
}
.organization-structure .info-list .personal-info .list span {
  color: #666666;
  font-weight: 400;
}
.organization-structure .info-list .personal-info .list .content {
  font-weight: 500;
  color: #292929;
}
.organization-structure .employee-list {
  width: 100%;
  height: auto;
  font-size: 16px;
  line-height: 19px;
  padding: 15px;
  margin-bottom: 20px;
}
.organization-structure .employee-list table,
.organization-structure .employee-list td,
.organization-structure .employee-list th {
  border: 1px solid #DFDFDF;
}
.organization-structure .employee-list table {
  width: 100%;
  border-collapse: collapse;
}
.organization-structure .employee-list tr {
  color: #000000;
}
.organization-structure .employee-list th,
.organization-structure .employee-list td {
  padding: 10px;
}
.organization-structure .employee-list th {
  font-weight: 600;
}
.organization-structure .employee-list .index {
  text-align: center;
}
.organization-structure .employee-list td {
  font-weight: 400;
}
.organization-structure .paginator {
  float: right;
}
.organization-structure .paginator :first-child a span {
  display: none;
}
.organization-structure .paginator .active {
  height: fit-content !important;
}
.organization-structure .paginator .page-item {
  margin: 0 5px;
  font-size: x-small;
}
.organization-structure .paginator .page-item :focus {
  box-shadow: none;
}
.organization-structure .paginator .hide {
  display: none;
}
.organization-structure .paginator .page-link {
  color: #000;
  border: 1px solid #E7E7E7;
}
.organization-structure .paginator .active .page-link {
  background-color: #D6A022;
  color: #fff;
}
.organization-structure .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
.organization-structure .k-dialog {
  height: auto !important;
}
.organization-structure .info.x-dialog {
  width: 700px;
  height: 90%;
}
.organization-structure .dialog-employee {
  width: 90% !important;
}
.organization-structure .list .title {
  white-space: nowrap;
  width: 100px;
}
.organization-structure .title-info-req {
  text-align: left;
  color: #00396B;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 16px;
}
.organization-structure .title-info-req.employee {
  color: #000000;
}

@media screen and (max-width: 1024px) {
  .organization-structure .employee-list {
    font-size: 13px !important;
    line-height: 16px !important;
  }
  .organization-structure .employee-list th,
.organization-structure .employee-list td {
    padding: 8px;
  }
}
@media screen and (max-width: 576px) {
  .organization-structure .all-unit {
    width: 95% !important;
  }
  .organization-structure .all-unit .unit {
    width: 70% !important;
  }
  .organization-structure .info-list {
    height: 5rem;
  }
  .organization-structure .info-list .avatar,
.organization-structure .info-list .list {
    font-size: 9px !important;
    line-height: 12px !important;
  }
  .organization-structure .info-list .list {
    padding-left: 5px;
  }
  .organization-structure .info-list .list i {
    font-size: 12px !important;
  }
  .organization-structure .info-list .list .title,
.organization-structure .info-list .list .content {
    padding: 0.1rem;
  }
  .organization-structure .employee-list {
    font-size: 8px !important;
    line-height: 11px !important;
  }
  .organization-structure .employee-list th,
.organization-structure .employee-list td {
    padding: 6px;
  }
}
.reward-and-achivement {
  color: black;
  font-weight: normal;
}
.reward-and-achivement .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.reward-and-achivement .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.reward-and-achivement .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.reward-and-achivement .vertical-dot .menu .menu-row,
.reward-and-achivement .vertical-dot .menu .menu-row-current,
.reward-and-achivement .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.reward-and-achivement .vertical-dot .menu .menu-row .menu-item-1,
.reward-and-achivement .vertical-dot .menu .menu-row .menu-item-2,
.reward-and-achivement .vertical-dot .menu .menu-row-current .menu-item-1,
.reward-and-achivement .vertical-dot .menu .menu-row-current .menu-item-2,
.reward-and-achivement .vertical-dot .menu .menu-row-current-border .menu-item-1,
.reward-and-achivement .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.reward-and-achivement .vertical-dot .menu .menu-row-current,
.reward-and-achivement .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.reward-and-achivement .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.reward-and-achivement .d-flex {
  visibility: visible !important;
}
.reward-and-achivement .close-div {
  display: flex;
  margin-top: 3px;
}
.reward-and-achivement .reward-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.reward-and-achivement .reward-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.reward-and-achivement .reward-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.reward-and-achivement .reward-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.reward-and-achivement .rGaps > * {
  padding: 0 10px;
  margin-bottom: 20px;
  min-height: 300px;
}
.reward-and-achivement .description p,
.reward-and-achivement .item p {
  font-size: 16px;
  font-weight: normal;
  color: black;
}

@media screen and (max-width: 576px) {
  .reward-and-achivement {
    padding: 0 10px;
  }
  .reward-and-achivement .item {
    margin: 0;
    border: none;
  }
}
.achivement-item {
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 100%;
}
.achivement-item .image-frame {
  width: 100%;
  height: 180px;
  text-align: center;
}
.achivement-item .image-frame .image {
  max-width: 100%;
  max-height: 100%;
}
.achivement-item p.title {
  text-align: center;
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
}

.news-template-3 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-3 .row {
  --bs-gutter-x: 0;
  flex-direction: column;
}
.news-template-3 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-3 .post-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0B68A4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  height: 40px;
  width: 30%;
}
.news-template-3 .back-rectangle {
  background-color: #0B68A4;
  position: absolute;
  right: -15%;
  height: 78%;
  bottom: 0.5px;
  width: 15%;
  display: flex;
}
.news-template-3 .line {
  background-color: #d6a022;
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
  margin-top: 4px;
  display: flex;
  position: relative;
}
.news-template-3 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-3 .post-more a {
  color: #EB2E35;
}
.news-template-3 .pointer {
  cursor: pointer;
}
.news-template-3 .icon-clock {
  width: 14px;
  height: 14px;
  color: #00396B;
  margin-top: 1px;
}
.news-template-3 .title {
  font-style: normal;
  font-size: 12px;
  text-align: justify;
  color: #1F1F1F;
  margin-bottom: 0;
  margin-top: 0;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.news-template-3 .new-post-title {
  margin-left: 9px;
  margin-top: -5px;
}
.news-template-3 .new-item {
  background-color: #EB2E35;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  align-items: self-end;
  padding: 3px 10px;
  margin-left: 5px;
}
.news-template-3 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-3 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-3 .time-clock {
  display: flex;
}
.news-template-3 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-3 .main-item {
  display: table;
  box-sizing: border-box;
}
.news-template-3 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-3 .top-news-list {
  padding: 17px;
  border: 1px solid #ededed;
}
.news-template-3 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: -14px;
  margin-left: -14px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-3 .d-flex {
  visibility: visible !important;
}
.news-template-3 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-3 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-3 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-3 .menu .menu-row,
.news-template-3 .menu .menu-row-current,
.news-template-3 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-3 .menu .menu-row .menu-item-1,
.news-template-3 .menu .menu-row .menu-item-2,
.news-template-3 .menu .menu-row-current .menu-item-1,
.news-template-3 .menu .menu-row-current .menu-item-2,
.news-template-3 .menu .menu-row-current-border .menu-item-1,
.news-template-3 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-3 .menu .menu-row-current,
.news-template-3 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-3 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-3 .img-figure, .news-template-3 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-3 .uppercase {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}
.news-template-3 .top-new-item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.news-template-3 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-3 .news-template-3-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-3 .news-template-3-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-3 .news-template-3-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-3 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-3 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-3 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-3 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-3 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-3 .text-section {
  display: flex;
  flex-direction: row;
}
.news-template-3 .text-section :hover {
  color: #00396B;
}
.news-template-3 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
}
.news-template-3 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media (max-width: 1200px) {
  .news-template-3 .post-title {
    width: 45%;
  }
}
@media screen and (max-width: 576px) {
  .news-template-3 .top-new-item {
    padding: 0px 10px;
  }
  .news-template-3 .main-page .news-template-3 {
    margin-left: 0px;
  }
}
.news-template-3 .dialog-width {
  max-width: 400px;
}
.news-template-3 .no-deco {
  text-decoration: none;
}

.news-template-3-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-3-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-3-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-3-dialog .err-text,
.news-template-3-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-3-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-3-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-3-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-3-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-3-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-3-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-3-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-3-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-3-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-3-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-3-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-3-dialog .float-right {
  float: right;
}

.news-template-3-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-3-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-3-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-3-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-4 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-4 .row {
  --bs-gutter-x: 0;
}
.news-template-4 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-4 .post-title {
  display: flex;
  color: #0B68A4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396B;
  position: relative;
  height: 40px;
}
.news-template-4 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-4 .post-more a {
  color: #EB2E35;
}
.news-template-4 .pointer {
  cursor: pointer;
}
.news-template-4 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-4 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-4 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-4 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-4 .time-clock {
  display: flex;
}
.news-template-4 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-4 .main-item {
  display: table;
}
.news-template-4 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-4 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 1px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-4 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-4 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-4 .d-flex {
  visibility: visible !important;
}
.news-template-4 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-4 .menu .menu-row,
.news-template-4 .menu .menu-row-current,
.news-template-4 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-4 .menu .menu-row .menu-item-1,
.news-template-4 .menu .menu-row .menu-item-2,
.news-template-4 .menu .menu-row-current .menu-item-1,
.news-template-4 .menu .menu-row-current .menu-item-2,
.news-template-4 .menu .menu-row-current-border .menu-item-1,
.news-template-4 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-4 .menu .menu-row-current,
.news-template-4 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-4 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-4 .img-figure, .news-template-4 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-4 .uppercase {
  color: #FFFFFF;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-4 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-4 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-4 .news-template-4-dialog {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0px;
  margin-bottom: 18px;
}
.news-template-4 .news-template-4-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-4 .news-template-4-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-4 .news-template-4-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-4 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-4 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-4 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-4 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-4 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-4 .item-image {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: contain;
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}
.news-template-4 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-4 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
}
.news-template-4 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.news-template-4-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-4-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-4-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-4-dialog .err-text,
.news-template-4-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-4-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-4-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-4-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-4-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-4-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-4-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-4-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-4-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-4-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-4-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-4-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-4-dialog .float-right {
  float: right;
}

.news-template-4-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-4-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-4-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-4-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-5 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-5 .row {
  --bs-gutter-x: 0;
}
.news-template-5 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-5 .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-5 .image {
  height: 100%;
  width: 25vw;
  height: 25vw;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.news-template-5 .image-box-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-5 .image-mobile {
  width: 70vw;
  height: 70vw;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.news-template-5 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-5 .post-more a {
  color: #eb2e35;
}
.news-template-5 .pointer {
  cursor: pointer;
}
.news-template-5 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-5 .title {
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
}
.news-template-5 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-5 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-5 .time-clock {
  display: flex;
}
.news-template-5 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-5 .main-item {
  display: table;
}
.news-template-5 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 1px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-5 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-5 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-5 .d-flex {
  visibility: visible !important;
}
.news-template-5 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-5 .menu .menu-row,
.news-template-5 .menu .menu-row-current,
.news-template-5 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-5 .menu .menu-row .menu-item-1,
.news-template-5 .menu .menu-row .menu-item-2,
.news-template-5 .menu .menu-row-current .menu-item-1,
.news-template-5 .menu .menu-row-current .menu-item-2,
.news-template-5 .menu .menu-row-current-border .menu-item-1,
.news-template-5 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-5 .menu .menu-row-current,
.news-template-5 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-5 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-5 .img-figure,
.news-template-5 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-5 .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-5 .top-new-item {
  margin: 10px 0;
}
.news-template-5 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-5 .news-template-5-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-5 .news-template-5-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-5 .news-template-5-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-5 .news-template-5-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-5 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-5 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-5 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-5 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-5 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-5 .text-section {
  display: flex;
  margin-top: 10px;
}
.news-template-5 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-5 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-5 .grid-column {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  padding: 0 1.2rem;
}
.news-template-5 .span-half {
  max-width: 45.8%;
}
.news-template-5 .intro-des {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
}
.news-template-5 .intro-des-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
}
.news-template-5 .intro-text {
  flex: 1;
}
.news-template-5 .intro-text .field-name {
  color: #000000 !important;
  font-size: 24px;
}
.news-template-5 .intro-text .letter {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: justify;
}
.news-template-5 .intro-text-mobile {
  flex: 1;
}
.news-template-5 .intro-text-mobile .field-name-mobile {
  color: #000000 !important;
  font-size: 24px;
}
.news-template-5 .intro-text-mobile .letter-mobile {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: justify;
}
.news-template-5 .p3 {
  padding: 3.2rem;
}
.news-template-5 .pt3 {
  padding-top: 3.2rem;
}
.news-template-5 .pr3 {
  padding-right: 3.2rem;
}
.news-template-5 .pb3 {
  padding-bottom: 3.2rem;
}
.news-template-5 .pl3 {
  padding-left: 3.2rem;
}
@media screen and (max-width: 1000px) {
  .news-template-5 .pattern {
    display: none;
    visibility: hidden;
  }
}
@media screen and (max-width: 40em) {
  .news-template-5 .mobile-m {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.news-template-5-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-5-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-5-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-5-dialog .err-text,
.news-template-5-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-5-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-5-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-5-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-5-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-5-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-5-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-5-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-5-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-5-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-5-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-5-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-5-dialog .float-right {
  float: right;
}

.news-template-5-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-5-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-5-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-5-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-5-dialog .preview {
  position: relative;
  vertical-align: top;
  height: 200px !important;
  width: 200px !important;
  border-radius: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 15px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.news-template-5-dialog .temp5-title {
  height: 30px;
}

.news-template-5-dialog .temp5-des {
  height: 100px;
}

.news-template-5-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-5-dialog .input-box {
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}

.news-template-5-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-5-dialog textarea {
  width: 100%;
  padding: 5px 8px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  font-size: 16px;
  resize: none;
}

.news-template-5-dialog textarea:focus {
  outline: none !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 5px #c4c4c4;
}

.news-template-5-dialog textarea::-webkit-scrollbar {
  display: none;
}

.news-template-6 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-6 .row {
  --bs-gutter-x: 0;
}
.news-template-6 .full-width {
  width: 100%;
}
.news-template-6 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-6 .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-6 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-6 .post-more a {
  color: #eb2e35;
}
.news-template-6 .pointer {
  cursor: pointer;
}
.news-template-6 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-6 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-6 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-6 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-6 .time-clock {
  display: flex;
}
.news-template-6 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-6 .main-item {
  display: table;
}
.news-template-6 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-6 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 3px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-6 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-6 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-6 .d-flex {
  visibility: visible !important;
}
.news-template-6 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-6 .menu .menu-row,
.news-template-6 .menu .menu-row-current,
.news-template-6 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-6 .menu .menu-row .menu-item-1,
.news-template-6 .menu .menu-row .menu-item-2,
.news-template-6 .menu .menu-row-current .menu-item-1,
.news-template-6 .menu .menu-row-current .menu-item-2,
.news-template-6 .menu .menu-row-current-border .menu-item-1,
.news-template-6 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-6 .menu .menu-row-current,
.news-template-6 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-6 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-6 .img-figure,
.news-template-6 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-6 .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-6 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-6 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-6 .news-template-6-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-6 .news-template-6-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-6 .news-template-6-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-6 .news-template-6-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-6 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-6 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-6 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-6 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-6 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-6 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-6 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-6 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-6 .nested-menu {
  border: 1px solid #e4e4e4;
}
.news-template-6 .nested-menu .list-group-item {
  background-color: #d59f22;
  font-size: 20px;
  text-align: center;
  color: white;
  font-weight: 500;
  border: none;
  text-transform: uppercase;
}
.news-template-6 .nested-menu .list-submenu {
  padding: 10px;
}
.news-template-6 .nested-menu .list-submenu :last-child {
  border: none;
}
.news-template-6 .nested-menu .submenu {
  cursor: pointer;
  padding: 0;
  list-style-type: none;
  height: auto;
  border-bottom: 1px solid #cecece;
  color: #4a4a4a;
  padding: 10px;
  font-weight: 500;
  display: block;
}

.news-template-6-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-6-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-6-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-6-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-6-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-6-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-6-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-6-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-6-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-6-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-6-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-6-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-6-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-6-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-6-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-6-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-6-dialog .float-right {
  float: right;
}

.news-template-6-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-6-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-6-dialog .control {
  margin-bottom: 22px;
}

.news-template-6-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-6-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-6-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-6-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-6-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-6-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-a {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-a .row {
  --bs-gutter-x: 0;
}
.news-template-a .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-a .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-a .image {
  height: 100%;
  width: 25vw;
  height: 25vw;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.news-template-a .image-box-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-a .image-mobile {
  width: 70vw;
  height: 70vw;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.news-template-a .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-a .post-more a {
  color: #eb2e35;
}
.news-template-a .pointer {
  cursor: pointer;
}
.news-template-a .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-a .title {
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
}
.news-template-a .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-a .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-a .time-clock {
  display: flex;
}
.news-template-a .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-a .main-item {
  display: table;
}
.news-template-a .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 1px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-a .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-a .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-a .d-flex {
  visibility: visible !important;
}
.news-template-a .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-a .menu .menu-row,
.news-template-a .menu .menu-row-current,
.news-template-a .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-a .menu .menu-row .menu-item-1,
.news-template-a .menu .menu-row .menu-item-2,
.news-template-a .menu .menu-row-current .menu-item-1,
.news-template-a .menu .menu-row-current .menu-item-2,
.news-template-a .menu .menu-row-current-border .menu-item-1,
.news-template-a .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-a .menu .menu-row-current,
.news-template-a .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-a .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-a .img-figure,
.news-template-a .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-a .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-a .top-new-item {
  margin: 10px 0;
}
.news-template-a .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-a .news-template-a-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-a .news-template-a-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-a .news-template-a-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-a .news-template-a-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-a .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-a .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-a .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-a .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-a .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-a .text-section {
  display: flex;
  margin-top: 10px;
}
.news-template-a .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-a .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-a .grid-column {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  padding: 0 1.2rem;
}
.news-template-a .span-half {
  max-width: 45.8%;
}
.news-template-a .intro-des {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
}
.news-template-a .intro-des-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10vw;
  margin-top: 30px;
  margin-bottom: 30px;
}
.news-template-a .intro-text {
  flex: 1;
}
.news-template-a .intro-text .field-name {
  color: #000000 !important;
  font-size: 24px;
}
.news-template-a .intro-text .letter {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: justify;
}
.news-template-a .intro-text-mobile {
  flex: 1;
}
.news-template-a .intro-text-mobile .field-name-mobile {
  color: #000000 !important;
  font-size: 24px;
}
.news-template-a .intro-text-mobile .letter-mobile {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: justify;
}
.news-template-a .p3 {
  padding: 3.2rem;
}
.news-template-a .pt3 {
  padding-top: 3.2rem;
}
.news-template-a .pr3 {
  padding-right: 3.2rem;
}
.news-template-a .pb3 {
  padding-bottom: 3.2rem;
}
.news-template-a .pl3 {
  padding-left: 3.2rem;
}
@media screen and (max-width: 1000px) {
  .news-template-a .pattern {
    display: none;
    visibility: hidden;
  }
}
@media screen and (max-width: 40em) {
  .news-template-a .mobile-m {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.news-template-a-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-a-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-a-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-a-dialog .err-text,
.news-template-a-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-a-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-a-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-a-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-a-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-a-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-a-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-a-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-a-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-a-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-a-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-a-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-a-dialog .float-right {
  float: right;
}

.news-template-a-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-a-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-a-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-a-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-a-dialog .preview {
  position: relative;
  vertical-align: top;
  height: 200px !important;
  width: 200px !important;
  border-radius: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 15px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.news-template-a-dialog .tempA-title {
  height: 30px;
}

.news-template-a-dialog .tempA-des {
  height: 100px;
}

.news-template-a-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-a-dialog .input-box {
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}

.news-template-a-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-a-dialog textarea {
  width: 100%;
  padding: 5px 8px;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  font-size: 16px;
  resize: none;
}

.news-template-a-dialog textarea:focus {
  outline: none !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 5px #c4c4c4;
}

.news-template-a-dialog textarea::-webkit-scrollbar {
  display: none;
}

.news-template-b {
  width: 100%;
}
.news-template-b .container-fluid {
  display: block;
}
.news-template-b .row {
  --bs-gutter-x: 0;
}
.news-template-b .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-b .no-wrap {
  display: flex;
  flex-wrap: nowrap !important;
}
.news-template-b .title {
  text-align: left;
  padding: 5px;
  background-color: #f1f1f1 !important;
  border: solid #e6e6e6 2px;
}
.news-template-b .main-item {
  display: table;
  margin: 0;
}
.news-template-b .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-b .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-b .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-b .d-flex {
  visibility: visible !important;
}
.news-template-b .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-b .menu .menu-row,
.news-template-b .menu .menu-row-current,
.news-template-b .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-b .menu .menu-row .menu-item-1,
.news-template-b .menu .menu-row .menu-item-2,
.news-template-b .menu .menu-row-current .menu-item-1,
.news-template-b .menu .menu-row-current .menu-item-2,
.news-template-b .menu .menu-row-current-border .menu-item-1,
.news-template-b .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-b .menu .menu-row-current,
.news-template-b .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-b .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-b .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-b .news-template-b-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-b .news-template-b-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-b .news-template-b-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-b .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-b .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-b .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-b .web-link {
  box-sizing: border-box;
}
.news-template-b .dropdown-content {
  display: none;
  position: absolute;
  width: calc(100% - 24px);
  background-color: #f6f6f6;
  overflow: auto;
  border: 1px solid #b7b7b7;
  z-index: 1;
}
.news-template-b .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.news-template-b .drop-menu {
  position: relative;
  width: 100%;
  padding: 12px;
  background-color: #f1f1f1 !important;
  border-right: solid #e6e6e6 2px;
  border-left: solid #e6e6e6 2px;
  border-bottom: solid #e6e6e6 2px;
}
.news-template-b .dropbtn {
  width: 100%;
  min-height: 30px;
  border: none;
  display: flex;
  background-color: #d0d0d0;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  text-align: left;
}
.news-template-b .drop-item-list {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 210px !important;
}
.news-template-b .drop-item-search {
  width: calc(100% - 14px);
  padding: 7px;
  margin: 7px;
}
.news-template-b .drop-item {
  min-height: 30px;
  border: none;
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
}
.news-template-b .active {
  background-color: #d0d0d0;
}
.news-template-b .show {
  display: block;
  z-index: 1;
}

.news-template-b-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-b-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-b-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-b-dialog .err-text,
.news-template-b-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-b-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-b-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-b-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-b-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-b-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-b-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-b-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-b-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-b-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-b-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-b-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-b-dialog .float-right {
  float: right;
}

.news-template-b-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-b-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-b-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-b-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-c {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-c .container-fluid {
  display: block;
}
.news-template-c .row {
  --bs-gutter-x: 0;
}
.news-template-c .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-c .post-title {
  display: flex;
  color: #0B68A4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396B;
  position: relative;
  height: 40px;
}
.news-template-c .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-c .post-more a {
  color: #EB2E35;
}
.news-template-c .pointer {
  cursor: pointer;
}
.news-template-c .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-c .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-c .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-c .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-c .time-clock {
  display: flex;
}
.news-template-c .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-c .main-item {
  display: table;
}
.news-template-c .main-item .row {
  flex-wrap: nowrap;
}
.news-template-c .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-c .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-c .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-c .d-flex {
  visibility: visible !important;
}
.news-template-c .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-c .menu .menu-row,
.news-template-c .menu .menu-row-current,
.news-template-c .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-c .menu .menu-row .menu-item-1,
.news-template-c .menu .menu-row .menu-item-2,
.news-template-c .menu .menu-row-current .menu-item-1,
.news-template-c .menu .menu-row-current .menu-item-2,
.news-template-c .menu .menu-row-current-border .menu-item-1,
.news-template-c .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-c .menu .menu-row-current,
.news-template-c .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-c .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-c .img-figure,
.news-template-c .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-c .uppercase {
  color: #FFFFFF;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-c .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-c .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-c .news-template-c-dialog {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0px;
  margin-bottom: 18px;
}
.news-template-c .news-template-c-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-c .news-template-c-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-c .news-template-c-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-c .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-c .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-c .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-c .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-c .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-c .navigation {
  background-color: #f3eddb;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.news-template-c .navigation .label {
  color: #6e6e6e;
  font-weight: 600;
  font-size: 14px;
  margin-top: 3px;
  margin-left: 5px;
}
.news-template-c .navigation .arrow {
  border: none;
  color: #d6a022;
  background-color: #f3eddb;
  display: flex;
}
.news-template-c .navigation .arrow .k-i-arrow-chevron-left {
  margin-right: -2px;
  font-size: 20px;
  cursor: pointer;
}
.news-template-c .navigation .arrow .k-i-arrow-chevron-right {
  margin-left: -2px;
  font-size: 20px;
  cursor: pointer;
}
.news-template-c .item-image {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
}
.news-template-c .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-c .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
}
.news-template-c .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-c .k-dialog .archive .gallery {
  padding: 0 12px;
}

.news-template-c-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-c-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-c-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-c-dialog .err-text,
.news-template-c-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-c-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-c-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-c-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-c-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-c-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-c-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-c-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-c-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-c-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-c-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-c-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-c-dialog .float-right {
  float: right;
}

.news-template-c-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-c-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-c-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-c-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-d {
  margin-bottom: 45px;
  background-color: #fff;
}
.news-template-d .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22px;
}
.news-template-d .three-dot-area {
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
}
.news-template-d .three-dot {
  width: 5px;
  height: 20px;
}
.news-template-d .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-d .menu .menu-row,
.news-template-d .menu .menu-row-current,
.news-template-d .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-d .menu .menu-row .menu-item-1,
.news-template-d .menu .menu-row .menu-item-2,
.news-template-d .menu .menu-row-current .menu-item-1,
.news-template-d .menu .menu-row-current .menu-item-2,
.news-template-d .menu .menu-row-current-border .menu-item-1,
.news-template-d .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-d .menu .menu-row-current,
.news-template-d .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-d .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-d .news-d-content {
  display: flex;
}
.news-template-d .news-d-large-item {
  text-decoration: none;
  width: 50%;
}
.news-template-d .news-d-large-item-info {
  padding: 12px 22px 18px 22px;
  border: 1px solid #eeeeee;
  border-top: none;
}
.news-template-d .news-d-info-title {
  margin-bottom: 12px;
  font-size: 24px;
  color: #292621;
  font-weight: 500;
  cursor: pointer;
}
.news-template-d .news-d-list-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  justify-content: space-between;
}
.news-template-d .news-d-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.news-template-d .news-d-title h3 {
  margin: 0;
  margin-left: 16px;
  color: #007fae;
  font-weight: 700;
  font-size: 24px;
}
.news-template-d .news-d-large-item-img {
  width: 100%;
  cursor: pointer;
}
.news-template-d .news-d-large-item-img img {
  width: 100%;
}
.news-template-d .news-d-details {
  margin: 0;
  color: #292621;
}
.news-template-d .news-d-small-item {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.news-template-d .news-d-small-item-img {
  width: 44%;
  margin-right: 20px;
  cursor: pointer;
}
.news-template-d .news-d-small-item-img img {
  width: 100%;
}
.news-template-d .news-d-small-title {
  width: 50%;
  font-weight: 500;
  color: #292621;
  font-size: 16px;
  cursor: pointer;
}
.news-template-d .news-template-d-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-d .news-template-d-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-d .news-template-d-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-d .news-template-d-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-d .close-div {
  display: flex;
  margin-top: 3px;
}
@media only screen and (max-width: 576px) {
  .news-template-d .news-d-content {
    display: flex;
    flex-direction: column;
  }
  .news-template-d .news-d-large-item {
    width: 100%;
    margin-bottom: 30px;
  }
  .news-template-d .news-d-info-title {
    font-weight: 500;
    color: #292621;
    font-size: 20px;
  }
  .news-template-d .news-d-details {
    display: none;
  }
  .news-template-d .news-d-list-item {
    margin: 0;
  }
  .news-template-d .news-d-small-item {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .news-template-d .news-d-small-item-img {
    width: 100%;
  }
  .news-template-d .news-d-small-item-img img {
    width: 100%;
  }
  .news-template-d .news-d-small-title {
    width: 100%;
    padding: 12px 22px 18px 22px;
    border: 1px solid #eeeeee;
    border-top: none;
  }
}

.news-template-d-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-d-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-d-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-d-dialog .err-text,
.news-template-d-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-d-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-d-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-d-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-d-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-d-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-d-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-d-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-d-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-d-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-d-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-d-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-d-dialog .float-right {
  float: right;
}

.news-template-d-dialog .border-infor {
  background: #ffffff;
  border: 0.6px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-d-dialog .label-infor {
  color: #407bff;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-d-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-d-dialog .input-box {
  height: 40px;
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-d-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-d-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-d-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-d-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-12 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-12 .row {
  --bs-gutter-x: 0;
}
.news-template-12 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-12 .pd-l-10 {
  padding-left: 18px;
}
.news-template-12 .post-title {
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  position: relative;
  min-height: 40px;
}
.news-template-12 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-12 .post-more a {
  color: #EB2E35;
}
.news-template-12 .pointer {
  cursor: pointer;
}
.news-template-12 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-12 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-12 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-12 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-12 .time-clock {
  display: flex;
}
.news-template-12 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-12 .main-item {
  display: table;
  margin: 0;
}
.news-template-12 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-12 .d-flex {
  visibility: visible !important;
}
.news-template-12 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-12 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-12 .dialog-width {
  max-width: 400px;
}
.news-template-12 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-12 .menu .menu-row,
.news-template-12 .menu .menu-row-current,
.news-template-12 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-12 .menu .menu-row .menu-item-1,
.news-template-12 .menu .menu-row .menu-item-2,
.news-template-12 .menu .menu-row-current .menu-item-1,
.news-template-12 .menu .menu-row-current .menu-item-2,
.news-template-12 .menu .menu-row-current-border .menu-item-1,
.news-template-12 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-12 .menu .menu-row-current,
.news-template-12 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-12 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-12 .img-figure, .news-template-12 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-12 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-12 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-12 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-12 .news-template-12-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-12 .news-template-12-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-12 .news-template-12-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-12 .news-template-12-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-12 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-12 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-12 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-12 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-12 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-12 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-12 .text-section :hover {
  color: #00396B;
}
.news-template-12 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
}
.news-template-12 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-12 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-12 .uppercase-black {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396B;
  margin-bottom: 10px;
  margin-top: 10px;
}
.news-template-12 .intro-des, .news-template-12 .intro-des-reserve {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6vw;
  margin-top: 30px;
  margin-bottom: 30px;
}
.news-template-12 .intro-des-reserve {
  flex-direction: row-reverse;
}
.news-template-12 .intro-text {
  flex: 1;
  margin-top: 10px;
}
.news-template-12 .intro-text .field-name {
  color: #000000 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
}
.news-template-12 .intro-text .field-name:hover {
  color: #00396b !important;
}
.news-template-12 .intro-text .letter {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: justify;
  margin-top: 10px;
}
.news-template-12 .intro-text .letter:hover {
  color: #00396b !important;
}
.news-template-12 .p3 {
  padding: 3.2rem;
}
.news-template-12 .pt3 {
  padding-top: 3.2rem;
}
.news-template-12 .pr3 {
  padding-right: 3.2rem;
}
.news-template-12 .pb3 {
  padding-bottom: 3.2rem;
}
.news-template-12 .pl3 {
  padding-left: 3.2rem;
}
.news-template-12 .image-circle, .news-template-12 .image-circle-no-border {
  width: 422px;
  height: 422px;
  border-radius: 50%;
}
.news-template-12 .image-circle-no-border {
  border-radius: unset;
}
.news-template-12 .center-img {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .news-template-12 .image-circle, .news-template-12 .image-circle-no-border {
    width: 222px;
    height: 222px;
    border-radius: 50%;
    object-fit: cover;
  }
  .news-template-12 .image-circle-no-border {
    border-radius: unset;
  }
  .news-template-12 .intro-des, .news-template-12 .intro-des-reserve {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .news-template-12 .intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .news-template-12 .pattern {
    display: none;
    visibility: hidden;
  }
}
@media screen and (max-width: 40em) {
  .news-template-12 .mobile-m {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
.news-template-12 .field-detail {
  text-decoration: none;
}

.news-template-12-dialog {
  margin-left: 0px !important;
}

.news-template-12-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-12-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-12-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-12-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-12-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-12-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-12-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-12-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-12-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-12-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-12-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-12-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-12-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-12-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-12-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-12-dialog .float-right {
  float: right;
}

.news-template-12-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-12-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-12-dialog .control {
  margin-bottom: 22px;
}

.news-template-12-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-12-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-12-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-12-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-12-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-12-dialog .padding-col {
  padding: 7px 15px 0;
}

.field .k-grid-header,
.field .k-grid-header .k-header {
  font-weight: bold;
  padding-right: 0px !important;
}

.field .control > * {
  width: 40% !important;
}

.field .router {
  width: 100%;
  margin: auto;
  margin-top: 15px !important;
}

.field .k-i-close {
  margin-top: 7px !important;
}

.field .k-dropdownlist {
  display: none;
}

.field .k-pager-numbers .k-link.k-state-selected,
.k-i-arrow-double-60-right .k-i-forward-sm {
  color: #fff;
  background-color: #d6a022;
}

.field .col-md-3 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.field .check-element {
  display: none !important;
}

.field .tab-des {
  margin: 30px 0;
  display: none;
  font-weight: bold;
  color: #00396b;
}
.field .tab-des p {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396b;
}

.field .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 33px;
  float: right;
  border-radius: 0;
  margin-top: -32px !important;
  border: 0;
  margin-right: -38px;
}

.field .k-grid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #2c2c2c !important;
  background-color: #ffffff;
}

.field .list-member-title {
  font-size: 16px !important;
  color: #ea4335;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.field .k-grid {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 20px;
}

.field .k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: white !important;
}

.field .k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: white !important;
}

.field .k-grid td {
  border-width: 0 0 0px 0px !important;
  vertical-align: middle;
  background-color: white;
  border-bottom: 1px solid #e1e1e1 !important;
  vertical-align: top;
  padding-top: 15px;
}

.field .img {
  cursor: pointer;
}

.field .k-pager-info {
  text-align: right;
  justify-content: flex-end;
  flex: 1 1 0%;
  visibility: hidden;
  order: 9;
  overflow: hidden;
  display: none;
}

.field .k-pager-wrap {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: white !important;
  justify-content: right !important;
  border-top: 1px solid #e1e1e1 !important;
  margin-top: 30px;
}

.field .k-grid-header {
  padding: opx !important;
  border-bottom: 1px solid #b6b6b6 !important;
}

.field .k-grid-header-wrap,
.k-grid-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0px 0px 0 0 !important;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}

.field .k-grid-content {
  width: 100%;
  min-height: 0;
  overflow: auto;
  overflow-x: auto;
  /* overflow-y: scroll; */
  position: relative;
  flex: 1;
}

.field .search-place {
  width: 100% !important;
  margin-top: 20px;
}

.field .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 0 0;
  white-space: nowrap;
  background-color: white;
}

.field .btn-search {
  color: white;
  background-color: #d6a022;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  position: absolute;
  border-radius: 3px;
}

.field .btn-dialog-cus {
  float: left;
  margin-top: 30px;
}

.field .total-member {
  color: black;
}

.field .dialog-info-post-detail {
  max-width: 830px;
}

@media (max-width: 600px) {
  :host ::ng-deep .field .dialog-info {
    width: 100% !important;
    height: 100% !important;
    z-index: 1060;
  }
}
.field .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.field .btn-addNew .k-button {
  font-size: 14px !important;
}

.field .btn-custom {
  width: 100%;
}

.field .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.field .item {
  border: 1px solid #c4c4c4;
  margin: 10px;
}

.field .index {
  float: left;
  width: calc(100% - 28px);
}

@media (max-width: 768px) {
  .field .k-dropdown-button button {
    background: none !important;
    color: black !important;
    border: none !important;
    padding-right: 0px;
    width: 28px;
    float: right;
  }

  .field .btn-addNew {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
.archive {
  background: white;
  height: 90%;
  min-width: 794px;
  min-height: 500px;
}

.archive .mb-archive {
  display: none;
}

.archive .mb-archive span.value {
  color: #3b3b3b;
  font-weight: 500;
}

.archive .g-btn {
  margin-top: 5px;
}

.archive .g-btn kendo-dropdownbutton button {
  height: 100%;
  display: inline-block;
}

.archive .input-label {
  margin: 10px;
  margin-left: 15px;
  margin-bottom: 0px;
}

.archive .input-box {
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}

.archive .btn-custom button {
  background: #34a853;
  color: white;
  color: white;
  width: 140px;
  height: 40px;
  border-radius: 5px;
}

.archive .btn-dialog-wrapper {
  padding: 20px 10px;
  position: relative;
}

.archive .btn-save {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.archive .mb-archive .function {
  text-align: right;
}

.archive.x-dialog-shared {
  max-height: 600px;
  max-width: 1300px;
}

.archive.x-dialog {
  max-height: 200px;
  max-width: 600px;
}

.archive .popup-width {
  width: 140px;
}

.archive .btn-fr {
  float: right;
}

.archive .btn-fl {
  display: flex;
  float: left;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.archive .btn-fl span {
  margin: 0 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.archive .btn-fl span:first-of-type {
  margin-left: 0;
}
.archive .btn-fl span:last-of-type {
  margin-right: 0;
}

.archive .align-center-bt {
  text-align: center;
}

.archive .headerOfficeStyle {
  text-align: center;
  color: #675f5f;
  background: #f6f6f6;
  text-overflow: inherit;
  vertical-align: middle;
  padding: 0px;
}

.archive .block-nm {
  display: inline;
}

.archive .btn-show {
  width: 15px;
  display: inline-table;
  border: none;
  background-color: transparent;
}

.archive .mr-r15 {
  margin-right: 15px;
}

.archive .icon-mouse {
  cursor: pointer;
}

@media (max-width: 768px) {
  .archive .g-archive {
    display: none;
  }

  .archive .mb-archive {
    display: initial;
  }
}
.archive .is-first-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.archive .obj-item {
  border: 1px solid;
  border-radius: 25px;
  padding: 2px 6px 2px 6px;
  margin: 1px;
  font-size: 11px;
  word-break: break-all;
}

.archive .seen-obj {
  color: #1D35AE;
  background-color: #E6EBFF;
}

.archive .remove-member-style {
  cursor: pointer;
  margin-left: 5px;
}

.archive .block-info {
  margin-top: 5px;
  margin-bottom: 5px;
}

.archive .obj-item-1 {
  display: inline-block;
}

.archive .not-first-item {
  border-top: 1px transparent;
  border-bottom: 1px solid;
}

.archive .custom-header-grid {
  background-color: white;
  color: black;
}

.archive .nopad-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.archive .is-item {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.archive .is-folder-link {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #9F9F9F;
}

.archive .is-current-folder {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  font-weight: bold;
}

.archive .file-icon {
  width: 30px;
  height: 33px;
  margin-right: 10px;
}

.archive .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.archive .btn-group-list {
  float: right;
  margin-right: 15px;
}

.archive .is-cursor {
  cursor: pointer;
}

.archive .left-icon {
  padding-right: 40px;
}

.archive .file-type {
  width: 168px;
  height: 108px;
  object-fit: cover;
}

.archive .file-type-folder {
  width: 54px;
  height: 45px;
  margin-top: 15px;
}

.archive .align-center {
  text-align: center;
  padding: 0;
}

.archive .align-center-top {
  text-align: center;
  padding: 0;
  margin-top: 26px;
}

.archive .archive-item {
  width: 100%;
  height: 100%;
  border: 1px solid #DFDFDF;
  padding: 5px;
}

.archive .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.archive .custom-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 7px;
  margin-bottom: 15px;
}

.archive .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
}

.btn-elipsis button {
  border: none;
  background-color: transparent;
  color: #919191;
  background-image: none;
}

.archive .display-content {
  position: relative;
  background-color: white;
  padding: 15px;
}
.archive .display-content .archive-item.image {
  border: 0;
  padding: 0;
}
.archive .display-content .folder:hover {
  background-color: #dee2e6;
}

.archive .upload-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.archive .header {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.archive .header .gallery {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 200px;
  position: relative;
}
.archive .header .gallery .dlt-img {
  visibility: visible;
  position: absolute;
  text-shadow: none;
  background: rgba(32, 28, 28, 0.4);
  height: 18px;
  width: 18px;
  color: white;
  cursor: pointer;
  top: 5px;
  right: 40px;
}
.archive .header .gallery .dlt-img i {
  font-size: 14px;
}
.archive .header .gallery .dlt-img .close-btn-white {
  width: 12px;
  height: 12px;
}
.archive .header .gallery:hover .dlt-img {
  visibility: visible;
}
.archive .header img {
  width: 100%;
  word-break: break-all;
  cursor: pointer;
}
.archive .header .picked-img {
  width: 318px;
  object-fit: cover;
}
.archive .header .add-new {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.archive .header .add-new img {
  width: 30%;
}
.archive .header .add-new .custom-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 9px;
  margin-bottom: 26px;
  color: #838383;
}
.archive .header .border:hover {
  background-color: #dee2e6;
}

.archive .go-back {
  margin-bottom: 15px;
  cursor: pointer;
}
.archive .go-back i {
  font-size: xx-large;
}

.archive .image img {
  width: 100%;
  height: 108px;
}

.archive .disabled {
  background-color: #6c757d;
}

.archive .border {
  border: 1px dashed #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 317px;
}
.archive .border .border-height {
  height: 100%;
}

@media (max-width: 600px) {
  .archive {
    min-width: 100%;
  }
}
.archive .vertical-dot-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  left: 40px;
}
.archive .vertical-dot-menu .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.archive .vertical-dot-menu .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  display: none;
}
.archive .vertical-dot-menu .menu .menu-row,
.archive .vertical-dot-menu .menu .menu-row-current,
.archive .vertical-dot-menu .menu .menu-row-current-border {
  cursor: pointer;
}
.archive .vertical-dot-menu .menu .menu-row .menu-item-1,
.archive .vertical-dot-menu .menu .menu-row .menu-item-2,
.archive .vertical-dot-menu .menu .menu-row-current .menu-item-1,
.archive .vertical-dot-menu .menu .menu-row-current .menu-item-2,
.archive .vertical-dot-menu .menu .menu-row-current-border .menu-item-1,
.archive .vertical-dot-menu .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.archive .vertical-dot-menu .menu .menu-row-current,
.archive .vertical-dot-menu .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.archive .vertical-dot-menu .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.archive .title-info-req {
  font-weight: 500;
  color: #004A9A;
}

.archive .size-dialog {
  position: absolute;
}
.archive .size-dialog .reward-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.archive .size-dialog .reward-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.archive .size-dialog .reward-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.archive .size-dialog .reward-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.archive .size-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
.archive .size-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.archive .size-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.user-info-wrapper {
  margin: auto;
  width: 100%;
  margin-top: 40px;
}

.user-info-container {
  display: flex;
  width: 100%;
  margin-bottom: 200px;
  margin-left: 0;
  margin-right: 0;
}

.user-upload-img {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 251px;
  height: 251px;
  margin-right: 30px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.user-upload-img .user-image {
  width: 118px;
  height: 118px;
  background-color: grey;
  border-radius: 50%;
}
.user-upload-img .user-avatar {
  border-radius: 50%;
  width: 118px;
  height: 118px;
  object-fit: contain;
}
.user-upload-img .upload {
  width: 100%;
  display: flex;
  justify-content: center;
}
.user-upload-img .k-upload {
  border: none;
}
.user-upload-img .k-upload .k-dropzone {
  background-color: white;
}
.user-upload-img .k-upload .k-button {
  background-color: white;
  background-image: none;
  border: none;
}
.user-upload-img .k-upload .k-button input {
  cursor: pointer;
}
.user-upload-img .k-upload .k-button span {
  color: #00396b;
  font-size: 16px;
  font-weight: 500;
}

.user-form-container {
  max-width: 759px;
  padding: 25px 20px 30px 30px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.user-form-container .user-form-title {
  color: #1f1f1f;
  font-size: 24px;
}
.user-form-container .user-form {
  width: 100%;
  margin-top: 31px;
}
.user-form-container .user-input-item {
  margin-bottom: 40px;
}
.user-form-container .user-input-item.select-item .k-input,
.user-form-container .user-input-item.select-item .k-input::placeholder {
  color: #c5c5c5;
  font-size: 16px;
  font-weight: 500;
}
.user-form-container .user-input-item.select-item .k-picker-wrap {
  padding-top: 11px;
}
.user-form-container .user-input-item.select-item .k-select {
  color: #878787;
}
.user-form-container .k-dropdown {
  width: 100%;
}
.user-form-container .k-dropdown .k-dropdown-wrap {
  width: 60%;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  background-color: #ffffff;
  background-image: none;
}
.user-form-container .k-dropdown .k-input {
  height: 54px;
  padding: 20px 0 17px 19px;
}
.user-form-container .k-dropdown .k-select {
  padding-right: 23px;
}
.user-form-container .k-textbox {
  width: 60%;
  height: 54px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px 0 17px 19px;
}
.user-form-container .k-datepicker {
  width: 60%;
}
.user-form-container .k-datepicker .k-select {
  background-color: #ffffff;
  background-image: none;
  padding-right: 23px;
  padding-bottom: 0;
  padding-top: 5px;
}
.user-form-container .k-datepicker .k-picker-wrap {
  width: 100%;
  height: 54px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 13px 0 17px 19px;
}
.user-form-container .k-dateinput-wrap .k-input {
  padding: 0;
}
.user-form-container .k-textbox::placeholder {
  color: #c5c5c5;
  font-size: 16px;
  font-weight: 500;
}
.user-form-container .user-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 109px;
}
.user-form-container .user-form-button {
  min-width: 120px;
  font-size: 16px;
  padding: 13px 47px;
  margin-left: 32px;
  border: none;
  background-color: #23bd20;
  color: #ffffff;
  border-radius: 3px;
  font-weight: 600;
}
.user-form-container .quit-button {
  color: #616161;
  border: 1px solid #b0b0b0;
  background-color: #ffffff;
}
.user-form-container .user-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}

@media screen and (max-width: 576px) {
  .user-info-container {
    justify-content: center;
  }

  .user-upload-img {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .user-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    box-shadow: none;
  }
  .user-form-container .k-textbox,
.user-form-container .k-datepicker,
.user-form-container .k-dropdown .k-dropdown-wrap {
    width: 100%;
  }
  .user-form-container .user-form-button {
    padding: 13px 20px;
  }
}
.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success-container .success-icon {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 106px;
  height: 106px;
}
.success-container .success-title {
  font-size: 22px;
  font-weight: 600;
  color: #1bb018;
}
.success-container .success-msg {
  margin-bottom: 56px;
}
.success-container button {
  background-color: #1bb018;
  width: 209px;
  height: 44px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 51px;
}
.success-container button:hover {
  color: #000000;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-page .panel-login {
  background-color: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 48px 64px;
  padding-bottom: 24px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-page .panel-login .text-input {
  width: 100%;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.login-page .panel-login .text-input input {
  width: 100%;
  border: none;
  margin: 10px 0;
  padding: 5px 0;
}
.login-page .panel-login .text-input input:focus {
  outline: none;
}
.login-page .panel-login .text-input .btn-showpass {
  border: none;
  background-color: #fff;
  color: gray;
}
.login-page .panel-login .logo {
  width: 60%;
}
.login-page .panel-login .sub-item {
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}
.login-page .panel-login .login-button {
  width: 100%;
  background-color: #00396b;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.login-page .panel-login .remember,
.login-page .panel-login .contact {
  color: gray;
}
.login-page .panel-login .remember > input {
  margin-right: 4px;
}
.login-page .panel-login .forgot-pwd {
  font-weight: 500;
  color: #00396b;
  cursor: pointer;
}
.login-page .panel-login .contact-link {
  color: #d6a022;
  font-weight: 500;
  cursor: pointer;
}
.login-page .panel-login .action > button {
  padding: 0;
}
.login-page .panel-login .action {
  color: gray;
  cursor: pointer;
}
.login-page .panel-login .invalid {
  border: 1px solid red;
}
.login-page .panel-login .focus {
  border-color: #00396b;
}
.login-page .panel-login .error-text {
  color: red;
  text-align: left;
  font-size: 13px;
  min-height: 20px;
}
.login-page .panel-login a {
  text-decoration: none;
}
.login-page .panel-login .login-failed-error {
  min-height: 30px;
  background-color: #f9e8e8;
  color: #e02d2d;
  padding: 15px;
  width: 100%;
  border-radius: 5px;
}
.login-page .panel-login .input {
  position: relative;
  width: 100%;
}
.login-page .panel-login .input__label {
  position: absolute;
  left: 0;
  top: 0;
  color: #c5c5c5;
  padding: calc(0.5rem * 0.75) 0.5rem;
  margin: calc(0.5rem * 0.75 + 3px) 0;
  white-space: nowrap;
  background-color: white;
  transform: translate(0, 0);
  transform-origin: 0 0;
  transition: transform 120ms ease-in;
  line-height: auto;
  text-align: left;
}
.login-page .panel-login .input__field {
  box-sizing: border-box;
  width: 100%;
  padding: calc(.5rem * 1.5) 0.5rem;
  border-radius: 5px;
  background-color: white;
}
.login-page .panel-login .input__field:focus + .input__label, .login-page .panel-login .input__field:not(:placeholder-shown) + .input__label {
  transform: translate(-0.5rem, -80%) scale(0.8);
  color: #00396b;
  padding: 0 0.5rem;
}
.login-page input:-webkit-autofill,
.login-page input:-webkit-autofill:hover,
.login-page input:-webkit-autofill:focus,
.login-page input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.login-page .panel-login .description h6 {
  color: #606060;
  font-size: 20px;
}
.login-page .panel-login .description .content {
  color: #1F1F1F;
  font-size: 16px;
}
.login-page .panel-login .form {
  width: 100%;
}
.login-page .panel-login .cf-forgot-password-button {
  width: 100%;
  background-color: #00396b;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}
.login-page .panel-login .alert {
  background-color: #E8FFEA;
  width: 100%;
  color: #42AE30;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.login-page .panel-login .alert .icon-alert {
  width: 22px;
  height: 22px;
}
.login-page .panel-login .alert .description {
  font-size: 14px;
  text-align: left;
}

.settings-container {
  display: flex;
  margin-left: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
  min-height: 512px;
}
.settings-container .setting-navbar {
  width: 251px;
  height: 125px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-right: 30px;
}
.settings-container .setting-item {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 28px;
  cursor: pointer;
}
.settings-container .setting-item-title {
  margin-top: 3px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4b4b4b;
}
@media screen and (max-width: 992px) {
  .settings-container .setting-navbar {
    width: 100%;
    max-width: 759px;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .settings-container app-change-password {
    width: 100%;
    max-width: 759px;
  }
  .settings-container app-change-password .change-password-container {
    width: 100%;
    max-width: 759px;
  }
  .settings-container app-support {
    width: 100%;
    max-width: 759px;
  }
  .settings-container app-support .support-container {
    width: 100%;
    max-width: 759px;
  }
}

@media screen and (max-width: 992px) {
  .settings-container {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 10px 10px;
  }
}
.change-password-container {
  width: 759px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-top: 25px;
  padding-left: 30px;
}
.change-password-container .change-password-form-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #1f1f1f;
}
.change-password-container .change-password-form {
  margin-top: 30px;
}
.change-password-container .input-item {
  position: relative;
  margin-bottom: 32px;
}
.change-password-container .input-item-label {
  position: absolute;
  top: -6px;
  left: 22px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background-color: #ffffff;
  color: #1f1f1f;
  z-index: 1;
}
.change-password-container .k-textbox {
  width: 55%;
  height: 54px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 24px 22px;
}
.change-password-container .change-password-error {
  color: #f44336;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.change-password-container .button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 109px;
  margin-bottom: 40px;
  margin-right: 17px;
}
.change-password-container .form-button {
  min-width: 120px;
  font-size: 16px;
  padding: 13px 47px;
  margin-left: 32px;
  border: none;
  background-color: #23bd20;
  color: #ffffff;
  border-radius: 3px;
  font-weight: 600;
}
.change-password-container .quit-button {
  color: #616161;
  border: 1px solid #b0b0b0;
  background-color: #ffffff;
}
@media screen and (max-width: 992px) {
  .change-password-container .form-button {
    font-size: 12px;
    padding: 13px 26px;
  }
}

.support-container {
  width: 759px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  color: #1f1f1f;
}
.support-container .support-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 16px;
}
.support-container .support-content {
  padding: 0;
}
.support-container .support-item {
  margin-top: 28px;
  display: flex;
  align-items: center;
}
.support-container .support-item-icon {
  margin-right: 16px;
}
.support-container .support-item-title {
  font-weight: 500;
  font-size: 20px;
  color: #d6a022;
}

.images .image-grid {
  display: flex;
  justify-content: space-between;
}

.images .err-text,
.images .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.images .selected-lang {
  border-bottom: 3px red solid;
}

.images .default-lang {
  /* border-bottom: none; */
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.images .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}

.images .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

.images .k-pager-wrap {
  border-color: white !important;
  color: #424242;
  background-color: white !important;
}

.images .err-image {
  border: 1px solid #ea4335;
  border-radius: 0.5;
  animation: myerr 1s infinite;
}

.images .btn-dialog-cus {
  float: left;
  margin-top: 25px;
}

.images .btn-search {
  color: white;
  background-color: #985D1A;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .g-archive {
    display: none;
  }

  .mb-archive {
    display: initial;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.images .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.images .btn-group-list {
  float: right;
  margin-right: 15px;
}

.images .is-cursor {
  cursor: pointer;
}

.images .file-type {
  width: 100%;
  height: 200px;
  display: inline-block;
  object-fit: cover;
  cursor: pointer;
}

.images .file-type-upload {
  width: 20%;
  /* width: 170px;
  height: 170px; */
}

.images .file-type-upload-success {
  width: 20%;
}

.images .align-center {
  text-align: center;
}

.images .item {
  width: 100%;
  height: 100%;
  border: 0.2px solid #c2c2c2;
  padding: 5px;
  border-radius: 5%;
}

.images .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.images .custom-text {
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  height: 35px;
}

.images .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 999;
}

.images .btn-elipsis button {
  /* border: none;
  background-color: transparent;
  color: black;
  background-image: none; */
  background: none;
  border: none;
  /* border-style: groove;
  border: 2px solid darkgray; */
}

:host ::ng-deep .k-grid td {
  border-width: 0;
}

:host ::ng-deep .k-grid th {
  border-width: 0;
}

.images.pd-for-scroll {
  padding: 0px 0px 0px 0px !important;
  margin: 15px 3px 0px 3px !important;
}

.images .img-slide {
  width: 40px;
  height: 40px;
  object-fit: contain;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.images .video-slide {
  width: 100%;
  height: 490px;
  object-fit: fill;
  padding: 10px;
}

/* .images .k-dialog{
    background: none;
} */
.images .carousel-control-next-icon,
.images .carousel-control-prev-icon {
  background-color: darkgrey;
  border-radius: 50%;
  background-size: 16px;
  padding: 20px;
  top: 50%;
  /* left: 50%; */
}

.images .xbutton-detail-media {
  border-radius: 50%;
  background: darkgrey !important;
  float: right;
}

.images .k-window-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-content: center;
}

.images.crystal-detail.k-dialog-wrapper .k-dialog {
  background: none !important;
  box-shadow: none !important;
  width: 90%;
}

.images .upload-image {
  float: left;
  /* margin-left: 25px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.images .upload-image .top {
  margin-bottom: 5px;
  white-space: nowrap;
}

.images .archive-item {
  position: relative;
  margin-bottom: 28px;
  /* bottom: 0;  */
}

.images .img-title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #494949;
  text-transform: uppercase;
}

.images .input-image {
  float: left;
  /* margin-left: -100px; */
  max-width: 1000px;
}

.images .btn-remove {
  right: 0px;
}

.images .k-pager-wrap {
  border-color: white;
  color: #424242;
  background-color: white;
}

.popup-upload.x-dialog {
  max-width: 768px;
  /* max-height: 565px; */
  max-height: fit-content !important;
}

:host ::ng-deep .popup-upload .k-window-content, .k-prompt-container {
  overflow: hidden !important;
}

.images .image-upload {
  display: none;
}

.images.popup-upload .k-window {
  max-height: 700px;
}

.images .vnpt-upload-img .k-upload {
  display: none;
}

.images .mb-drbtn-func .k-dropdown-button {
  height: 0px !important;
  background: none;
}

.images .bookmark-show {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 4px;
  z-index: 1;
  float: left;
  left: 24px;
  font-size: 27px;
  color: red;
}

.images .xbutton-dialog .k-icon {
  font-size: 20px !important;
}

.images .k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
  color: inherit;
  align-self: center;
  position: relative;
}

.images .k-icon {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "WebComponentsIcons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.images .image-slide {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.images .carousel-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.images .carousel-prev, .images .carousel-next {
  display: flex;
  align-items: center;
}

.images .carousel-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 70%;
}

.images .thumbnail-images-container {
  margin-top: 36px;
  padding-left: -5px;
  padding-right: -5px;
  width: 100%;
  display: flex;
}

.images .image-slide-thumbnail {
  width: 20%;
  padding: 0 5px;
  height: 80px;
}
.images .image-slide-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .images .col-md-2 {
    flex: 0 0 auto;
    width: 261px !important;
    height: 270px !important;
  }
}
@media (max-width: 768px) {
  .images .image-slide, .images .image-slide-thumbnail {
    height: auto;
  }
}
.videos .image-grid {
  display: flex;
  justify-content: space-between;
}

.videos .err-text,
.videos .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.videos .selected-lang {
  border-bottom: 3px red solid;
}

.videos .default-lang {
  /* border-bottom: none; */
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.videos .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}

.videos .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

.videos .k-pager-wrap {
  border-color: white !important;
  color: #424242;
  background-color: white !important;
}

.videos .err-image {
  border: 1px solid #ea4335;
  border-radius: 0.5;
  animation: myerr 1s infinite;
}

.videos .btn-dialog-cus {
  float: left;
  margin-top: 25px;
}

.videos .btn-search {
  color: white;
  background-color: #985D1A;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}

@media (max-width: 768px) {
  .g-archive {
    display: none;
  }

  .mb-archive {
    display: initial;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.videos .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.videos .btn-group-list {
  float: right;
  margin-right: 15px;
}

.videos .is-cursor {
  cursor: pointer;
}

.videos .file-type {
  width: 100%;
  height: 200px;
  display: inline-block;
  object-fit: cover;
  cursor: pointer;
}

.videos .file-type-upload {
  width: 20%;
  /* width: 170px;
  height: 170px; */
}

.videos .file-type-upload-success {
  width: 20%;
}

.videos .align-center {
  text-align: center;
}

-item {
  width: 100%;
  height: 100%;
  border: 0.2px solid #c2c2c2;
  padding: 5px;
  border-radius: 5%;
}

.videos .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.videos .custom-text {
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  height: 35px;
}

.videos .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 999;
}

.videos .btn-elipsis button {
  /* border: none;
  background-color: transparent;
  color: black;
  background-image: none; */
  background: none;
  border: none;
  /* border-style: groove;
  border: 2px solid darkgray; */
}

:host ::ng-deep .k-grid td {
  border-width: 0;
}

:host ::ng-deep .k-grid th {
  border-width: 0;
}

.videos.pd-for-scroll {
  padding: 0px 0px 0px 0px !important;
  margin: 15px 3px 0px 3px !important;
}

.videos .img-slide {
  width: 40px;
  height: 40px;
  object-fit: contain;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videos .video-slide {
  width: 100%;
  height: 490px;
  object-fit: fill;
  padding: 10px;
}

/* .videos .k-dialog{
    background: none;
} */
.videos .carousel-control-next-icon,
.videos .carousel-control-prev-icon {
  background-color: darkgrey;
  border-radius: 50%;
  background-size: 16px;
  padding: 20px;
  top: 50%;
  /* left: 50%; */
}

.videos .xbutton-detail-media {
  border-radius: 50%;
  background: darkgrey !important;
  float: right;
}

.videos .k-window-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-content: center;
}

.videos.crystal-detail.k-dialog-wrapper .k-dialog {
  background: none !important;
  box-shadow: none !important;
  width: 90%;
}

.videos .upload-image {
  float: left;
  /* margin-left: 25px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.videos .upload-image .top {
  margin-bottom: 5px;
  white-space: nowrap;
}

.videos .archive-item {
  position: relative;
  margin-bottom: 28px;
  /* bottom: 0;  */
}

.videos .img-title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #494949;
  text-transform: uppercase;
}

.videos .input-image {
  float: left;
  /* margin-left: -100px; */
  max-width: 1000px;
}

.videos .btn-remove {
  right: 0px;
}

@media (min-width: 768px) {
  .videos .col-md-2 {
    flex: 0 0 auto;
    width: 261px !important;
    height: 270px !important;
  }
}
.videos .k-pager-wrap {
  border-color: white;
  color: #424242;
  background-color: white;
}

.popup-upload.x-dialog {
  max-width: 768px;
  /* max-height: 565px; */
  max-height: fit-content !important;
}

:host ::ng-deep .popup-upload .k-window-content, .k-prompt-container {
  overflow: hidden !important;
}

.videos .image-upload {
  display: none;
}

.videos.popup-upload .k-window {
  max-height: 700px;
}

.videos .vnpt-upload-img .k-upload {
  display: none;
}

.videos .mb-drbtn-func .k-dropdown-button {
  height: 0px !important;
  background: none;
}

.videos .bookmark-show {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 4px;
  z-index: 1;
  float: left;
  left: 24px;
  font-size: 27px;
  color: red;
}

.videos .xbutton-dialog .k-icon {
  font-size: 20px !important;
}

.videos .k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
  color: inherit;
  align-self: center;
  position: relative;
}

.videos .k-icon {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "WebComponentsIcons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.videos .image-slide {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.videos .carousel-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.videos .carousel-prev, .videos .carousel-next {
  display: flex;
  align-items: center;
}

.videos .carousel-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 70%;
}

.videos .thumbnail-videos-container {
  margin-top: 36px;
  padding-left: -5px;
  padding-right: -5px;
  width: 100%;
  display: flex;
}

.videos .image-slide-thumbnail {
  width: 20%;
  padding: 0 5px;
  height: 80px;
}
.videos .image-slide-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videos .video-wrapper {
  width: 100%;
  height: 600px;
  object-fit: contain;
}

.login-page .panel-login .description h6 {
  color: #606060;
  font-size: 20px;
}
.login-page .panel-login .description .content {
  color: #1F1F1F;
  font-size: 16px;
}

.working-schedule .title {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #474747;
}
.working-schedule .k-header {
  background-color: #00396b;
  border-color: #ffffff;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  padding: 14px;
  text-align: center;
}
.working-schedule .k-grid {
  border: 1px solid #afafaf;
  border-bottom-width: 0;
}
.working-schedule .k-grid td {
  vertical-align: top;
  border-color: #afafaf;
  border-bottom-width: 1px;
  background-color: #ffffff;
  padding-top: 30px;
}
.working-schedule .schedule-table {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.working-schedule .working-container {
  padding-left: 15px;
  padding-right: 18px;
  height: 100%;
  min-height: 47px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #494949;
}
.working-schedule .working {
  margin-bottom: 13px;
}
.working-schedule .working:last-child {
  margin-bottom: 62px;
}
.working-schedule .day-container {
  padding-left: 6px;
  padding-right: 2px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #494949;
}

.electronic-text .k-grid-header,
.electronic-text .k-grid-header .k-header {
  font-weight: bold;
  padding-right: 0px !important;
}
.electronic-text .router {
  margin-top: 15px !important;
  box-sizing: border-box;
}
.electronic-text .k-combobox {
  height: 100%;
}
.electronic-text .k-combobox .k-dropdown-wrap {
  height: 100%;
}
.electronic-text .k-dropdownlist {
  display: none;
}
.electronic-text .check-element {
  display: none !important;
}
.electronic-text .tab-des {
  margin: 30px 0;
  display: none;
  font-weight: bold;
  color: #00396b;
}
.electronic-text .tab-des p {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396b;
}
.electronic-text .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 36px;
  float: right;
  border-radius: 0;
  margin-top: -36px !important;
  border: 0;
  margin-right: -38px;
}
.electronic-text .k-grid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #2c2c2c !important;
  background-color: #ffffff;
}
.electronic-text .k-grid {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 20px;
}
.electronic-text .list-member-title {
  font-size: 16px !important;
  color: #ea4335;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.electronic-text .k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: white !important;
}
.electronic-text .k-grid td {
  border-width: 0 0 0px 0px !important;
  vertical-align: middle;
  background-color: white;
  border-bottom: 1px solid #e1e1e1 !important;
  vertical-align: top;
  padding-top: 15px;
}
.electronic-text .img {
  cursor: pointer;
}
.electronic-text .k-pager-info {
  text-align: right;
  justify-content: flex-end;
  flex: 1 1 0%;
  visibility: hidden;
  order: 9;
  overflow: hidden;
  display: none;
}
.electronic-text .k-pager-wrap {
  color: #424242;
  background-color: white !important;
  justify-content: right !important;
  padding-top: 30px;
}
.electronic-text .k-pager-wrap:focus {
  box-shadow: none;
}
.electronic-text .k-grid-header {
  padding: opx !important;
  border-bottom: 1px solid #b6b6b6 !important;
}
.electronic-text .k-grid-header-wrap,
.electronic-text .k-grid-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0px 0px 0 0 !important;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}
.electronic-text .k-grid-content {
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  /* overflow-y: scroll; */
  position: relative;
  flex: 1;
}
.electronic-text .k-pager-nav {
  border: 1px solid #e7e7e7;
  margin-left: 10px;
  color: #000;
}
.electronic-text .k-state-selected {
  border: none;
}
.electronic-text .search-place {
  width: 100% !important;
  margin-top: 15px;
  padding: 4px 10px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.electronic-text .search-place input {
  border: none;
  width: 100%;
  font-size: 16px;
}
.electronic-text .search-place input::placeholder {
  font-weight: 400;
  color: #aaaaaa;
}
.electronic-text .search-place input:focus {
  outline: none;
}
.electronic-text .search-place i {
  color: gray;
}
.electronic-text .dropdown {
  padding: 0 2px;
}
.electronic-text .dropdown kendo-combobox {
  width: 100% !important;
}
.electronic-text .dropdown .k-dropdown-wrap {
  background-color: white;
  border: none;
}
.electronic-text .dropdown .k-state-focused {
  box-shadow: none;
}
.electronic-text .dropdown .k-select {
  background-color: white;
  background-image: none;
}
.electronic-text .dropdown .k-input {
  font-size: 16px;
  line-height: normal;
  height: auto;
}
.electronic-text .dropdown .k-i-close {
  height: 100%;
}
.electronic-text .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 0 0;
  white-space: nowrap;
  background-color: white;
}
.electronic-text .btn-search {
  color: #000;
  background-color: #d6a022;
  border: hidden;
  font-weight: 600;
  font-size: 16px !important;
  border-radius: 3px;
  margin-top: 39px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.electronic-text .filter {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
}
.electronic-text .filter .field-name {
  font-size: 16px;
  font-weight: bold;
  color: #5a5a5a;
}
.electronic-text .search-input {
  width: 40%;
}
.electronic-text .filter-1 {
  width: 60%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.electronic-text .filter-1 .filter-item {
  width: 50%;
}
.electronic-text .text-name {
  color: #00396b;
}
.electronic-text .total-member {
  color: black;
}
.electronic-text .dialog-info-post-detail {
  max-width: 830px;
}
.electronic-text .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}
.electronic-text .btn-addNew .k-button {
  font-size: 14px !important;
}
.electronic-text .btn-custom {
  width: 100%;
}
.electronic-text .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}
.electronic-text .item {
  border: 1px solid #c4c4c4;
  margin: 10px;
}
.electronic-text .index {
  float: left;
  width: calc(100% - 28px);
}
.electronic-text .k-pager-numbers .k-state-selected {
  color: #fff;
  background-color: #d6a022;
}

@media (max-width: 768px) {
  .electronic-text .k-dropdownlist {
    display: inline;
    color: #fff;
    background-color: #d6a022;
  }
  .electronic-text .k-pager-numbers-wrap {
    margin: 0;
  }
  .electronic-text .k-dropdown-button button {
    background: none !important;
    color: black !important;
    border: none !important;
    padding-right: 0px;
    width: 28px;
    float: right;
  }
  .electronic-text .btn-addNew {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .electronic-text .router {
    padding: 0 20px;
  }
  .electronic-text .btn-search {
    margin-top: 10px;
    height: 35px;
  }
  .electronic-text .search-place {
    height: 35px;
    margin-top: 5px;
  }
}
@media (max-width: 992px) {
  .electronic-text .router-outlet-content {
    padding: 0 10px;
  }
}
.member-unit {
  margin-bottom: 96px;
}
.member-unit .lb-name {
  font-size: 30px;
}
.member-unit .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.member-unit .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.member-unit .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.member-unit .vertical-dot .menu .menu-row,
.member-unit .vertical-dot .menu .menu-row-current,
.member-unit .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.member-unit .vertical-dot .menu .menu-row .menu-item-1,
.member-unit .vertical-dot .menu .menu-row .menu-item-2,
.member-unit .vertical-dot .menu .menu-row-current .menu-item-1,
.member-unit .vertical-dot .menu .menu-row-current .menu-item-2,
.member-unit .vertical-dot .menu .menu-row-current-border .menu-item-1,
.member-unit .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.member-unit .vertical-dot .menu .menu-row-current,
.member-unit .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.member-unit .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.member-unit .d-flex {
  visibility: visible !important;
}
.member-unit .title-center {
  text-align: center !important;
}
.member-unit .image-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  justify-content: space-between;
}
.member-unit .image-column {
  flex: 33%;
  max-width: 33%;
}
.member-unit .image-item {
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  padding: 0 7px;
  cursor: pointer;
}
.member-unit .image-container {
  display: block;
  position: relative;
  text-decoration: none;
}
.member-unit .image-overlay {
  position: absolute;
  width: 100%;
  height: 104px;
  transition: 0.3s ease-out;
  opacity: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.69);
  text-decoration: none;
  cursor: pointer;
}
.member-unit .image-overlay h3 {
  font-weight: 700;
  font-size: 31px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin: 16px 10px;
}
.member-unit img {
  vertical-align: middle;
  width: 100%;
}
.member-unit .image-container:hover .image-overlay {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .member-unit .image-row {
    flex-direction: column;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
  }
  .member-unit .image-column {
    flex: 100%;
    max-width: 100%;
  }
}

.library-text .k-grid-header,
.library-text .k-grid-header .k-header {
  font-weight: bold;
  padding-right: 0px !important;
}
.library-text .field-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353535;
}
.library-text .router {
  margin-top: 15px !important;
  box-sizing: border-box;
}
.library-text .k-cell-inner {
  margin: 0px 0px -5px -15px !important;
  float: left;
}
.library-text .wb-library-text {
  display: block !important;
}
.library-text .mb-library-text {
  display: none !important;
}
.library-text .k-dropdownlist {
  display: none;
}
.library-text .label-text-name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #00396B;
}
.library-text .check-element {
  display: none !important;
}
.library-text .tab-des {
  margin: 30px 0;
  display: none;
  font-weight: bold;
  color: #00396b;
}
.library-text .tab-des p {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396b;
}
.library-text .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 36px;
  float: right;
  border-radius: 0;
  margin-top: -36px !important;
  border: 0;
  margin-right: -38px;
}
.library-text .k-grid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #2c2c2c !important;
  background-color: #ffffff;
}
.library-text .k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #111111;
}
.library-text .k-grid {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 20px;
}
.library-text .list-member-title {
  font-size: 16px !important;
  color: #ea4335;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.library-text .k-combobox {
  height: 100%;
}
.library-text .k-combobox .k-dropdown-wrap {
  height: 100%;
}
.library-text .k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: white !important;
  justify-content: center;
}
.library-text .k-grid td {
  border-width: 0 0 0px 0px !important;
  vertical-align: middle;
  background-color: white;
  border-bottom: 1px solid #e1e1e1 !important;
  vertical-align: top;
  padding-top: 15px;
}
.library-text .k-grid td dd {
  padding-left: 5px;
}
.library-text .img {
  cursor: pointer;
}
.library-text .k-pager-info {
  text-align: right;
  justify-content: flex-end;
  flex: 1 1 0%;
  visibility: hidden;
  order: 9;
  overflow: hidden;
  display: none;
}
.library-text .k-pager-wrap {
  color: #424242;
  background-color: white !important;
  justify-content: right !important;
  padding-top: 30px;
}
.library-text .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.library-text .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.library-text .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.library-text .vertical-dot .menu .menu-row,
.library-text .vertical-dot .menu .menu-row-current,
.library-text .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.library-text .vertical-dot .menu .menu-row .menu-item-1,
.library-text .vertical-dot .menu .menu-row .menu-item-2,
.library-text .vertical-dot .menu .menu-row-current .menu-item-1,
.library-text .vertical-dot .menu .menu-row-current .menu-item-2,
.library-text .vertical-dot .menu .menu-row-current-border .menu-item-1,
.library-text .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.library-text .vertical-dot .menu .menu-row-current,
.library-text .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.library-text .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.library-text .d-flex {
  visibility: visible !important;
}
.library-text .k-pager-wrap:focus {
  box-shadow: none;
}
.library-text .k-grid-header {
  padding: 0px !important;
  border-bottom: 1px solid #b6b6b6 !important;
}
.library-text .k-grid-header-wrap,
.library-text .k-grid-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0px 0px 0 0 !important;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}
.library-text .k-grid-content {
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  /* overflow-y: scroll; */
  position: relative;
  flex: 1;
}
.library-text .k-pager-nav {
  border: 1px solid white;
  margin-left: 10px;
  color: #000;
}
.library-text .k-state-selected {
  border: none;
}
.library-text .search-place {
  width: 100% !important;
  margin-top: 15px;
  padding: 4px 10px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.library-text .search-place input {
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #AFAFAF;
}
.library-text .search-place input::placeholder {
  font-weight: 400;
  color: #aaaaaa;
}
.library-text .search-place input:focus {
  outline: none;
}
.library-text .search-place i {
  color: gray;
}
.library-text .dropdown {
  padding: 0 2px;
}
.library-text .dropdown kendo-combobox {
  width: 100% !important;
}
.library-text .dropdown .k-dropdown-wrap {
  background-color: white;
  border: none;
}
.library-text .dropdown .k-state-focused {
  box-shadow: none;
}
.library-text .dropdown .k-select {
  background-color: white;
  background-image: none;
}
.library-text .dropdown .k-input {
  font-size: 16px;
  line-height: normal;
  height: 100%;
}
.library-text .dropdown .k-i-close {
  height: 100%;
}
.library-text .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 0 0;
  white-space: nowrap;
  background-color: white;
}
.library-text .btn-search {
  border: hidden;
  background: #006CB5;
  font-size: 16px;
  border-radius: 3px;
  margin-top: 39px;
  height: 40px;
  display: flex;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: white;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.library-text .filter {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
}
.library-text .search-input {
  width: 40%;
}
.library-text .filter-1 {
  width: 60%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.library-text .filter-1 .filter-item {
  width: 50%;
}
.library-text .label-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2C2C2C;
}
.library-text .label-text0-name {
  justify-content: center !important;
  display: flex !important;
}
.library-text .text-name {
  color: #00396b;
}
.library-text .total-member {
  color: black;
}
.library-text .dialog-info-post-detail {
  max-width: 830px;
}
.library-text .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}
.library-text .btn-addNew .k-button {
  font-size: 14px !important;
}
.library-text .btn-custom {
  width: 100%;
}
.library-text .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}
.library-text .item {
  border: 1px solid #c4c4c4;
  margin: 10px;
}
.library-text .index {
  justify-content: center;
  display: flex;
  text-align: center;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.library-text .k-pager-numbers .k-state-selected {
  color: #fff;
  background-color: #d6a022;
}
.library-text .library-text-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.library-text .library-text-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.library-text .library-text-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.library-text .library-text-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.library-text .dialog-width {
  max-width: 400px;
}
.library-text .download-box {
  display: flex;
  justify-content: center;
}
.library-text .pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .library-text .k-dropdownlist {
    display: inline;
    color: #fff;
    background-color: #d6a022;
  }
  .library-text .k-pager-numbers-wrap {
    margin: 0;
  }
  .library-text .item-menu {
    margin: 10px 0;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
  }
  .library-text .wb-library-text {
    display: none !important;
  }
  .library-text .mb-library-text {
    display: block !important;
    padding: 0px;
  }
  .library-text .k-dropdown-button button {
    background: none !important;
    color: black !important;
    border: none !important;
    padding-right: 0px;
    width: 28px;
    float: right;
  }
  .library-text .btn-addNew {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .library-text .router {
    padding: 0 20px;
  }
  .library-text .btn-search {
    margin-top: 10px;
    height: 35px;
  }
  .library-text .search-place {
    height: 35px;
    margin-top: 5px;
  }
}
@media (max-width: 992px) {
  .library-text .router-outlet-content {
    padding: 0 10px;
  }
}
.news-template-1, .news-template-1-without-time {
  width: 100%;
  min-height: 504px;
}
.news-template-1 .row, .news-template-1-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-1 .k-dialog, .news-template-1-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-1 .post-title, .news-template-1-without-time .post-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
}
.news-template-1 .title-text, .news-template-1-without-time .title-text {
  margin-bottom: 0 !important;
}
.news-template-1 .post-more, .news-template-1-without-time .post-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-1 .post-more a, .news-template-1-without-time .post-more a {
  color: #d6a022;
}
.news-template-1 .pointer, .news-template-1-without-time .pointer {
  cursor: pointer;
}
.news-template-1 .icon-clock, .news-template-1-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-1 .title, .news-template-1-without-time .title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #292621;
  text-decoration: none;
  margin-bottom: 5px !important;
}
.news-template-1 .title-template-type-2, .news-template-1-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-1 .post-image, .news-template-1 .post-image-radius, .news-template-1-without-time .post-image, .news-template-1-without-time .post-image-radius {
  width: 100%;
}
.news-template-1 .post-image-radius, .news-template-1-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-1 .post-image-default, .news-template-1-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-1 .post-image-org, .news-template-1-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-1 .time-clock, .news-template-1-without-time .time-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.news-template-1 .time-text, .news-template-1-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-1 .main-item, .news-template-1-without-time .main-item {
  display: table;
}
.news-template-1 .vertical-dot, .news-template-1-without-time .vertical-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-1 .main-item:hover .vertical-dot, .news-template-1-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-1 .d-flex, .news-template-1-without-time .d-flex {
  visibility: visible !important;
}
.news-template-1 .three-dot-area, .news-template-1-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.news-template-1 .menu, .news-template-1-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-1 .menu .menu-row,
.news-template-1 .menu .menu-row-current,
.news-template-1 .menu .menu-row-current-border, .news-template-1-without-time .menu .menu-row,
.news-template-1-without-time .menu .menu-row-current,
.news-template-1-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-1 .menu .menu-row .menu-item-1,
.news-template-1 .menu .menu-row .menu-item-2,
.news-template-1 .menu .menu-row-current .menu-item-1,
.news-template-1 .menu .menu-row-current .menu-item-2,
.news-template-1 .menu .menu-row-current-border .menu-item-1,
.news-template-1 .menu .menu-row-current-border .menu-item-2, .news-template-1-without-time .menu .menu-row .menu-item-1,
.news-template-1-without-time .menu .menu-row .menu-item-2,
.news-template-1-without-time .menu .menu-row-current .menu-item-1,
.news-template-1-without-time .menu .menu-row-current .menu-item-2,
.news-template-1-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-1-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.news-template-1 .menu .menu-row-current,
.news-template-1 .menu .menu-row-current-border, .news-template-1-without-time .menu .menu-row-current,
.news-template-1-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-1 .menu .menu-item-3, .news-template-1-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.news-template-1 .img-figure,
.news-template-1 .img-figure-no-img,
.news-template-1 .img-figure-template-type-2, .news-template-1-without-time .img-figure,
.news-template-1-without-time .img-figure-no-img,
.news-template-1-without-time .img-figure-template-type-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-1 .img-figure-2, .news-template-1-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-1 .post-list, .news-template-1-without-time .post-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.news-template-1 .post-list .k-pager-info, .news-template-1 .post-list .k-pager-sizes, .news-template-1-without-time .post-list .k-pager-info, .news-template-1-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-1 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-1-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-1 .post-list .k-pager .k-state-selected, .news-template-1-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-1 .post-list .top-part, .news-template-1-without-time .post-list .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.news-template-1 .uppercase, .news-template-1-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-1 .top-new-item, .news-template-1-without-time .top-new-item {
  margin: 5px 0;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.news-template-1 .top-new-item .title:hover, .news-template-1-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-1 .close-div, .news-template-1-without-time .close-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
}
.news-template-1 .news-template-1-dialog, .news-template-1-without-time .news-template-1-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-1 .news-template-1-dialog .close-icon-div, .news-template-1-without-time .news-template-1-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-1 .news-template-1-dialog .close-icon, .news-template-1-without-time .news-template-1-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-1 .news-template-1-dialog .title-info-req, .news-template-1-without-time .news-template-1-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-1 .input-label, .news-template-1-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-1 .btn-delete, .news-template-1-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-1 .btn-cancel, .news-template-1-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-1 .dialog-width, .news-template-1-without-time .dialog-width {
  max-width: 400px;
}
.news-template-1 .space-box, .news-template-1-without-time .space-box {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .news-template-1 .img-figure, .news-template-1-without-time .img-figure {
    height: auto !important;
  }
  .news-template-1 .post-list, .news-template-1-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.search-page {
  width: 100%;
  background-color: #ffffff !important;
}
.search-page .search-result-container {
  margin-top: 30px;
}
.search-page .search-result-list {
  margin-top: 5px;
}
.search-page .search-result-summary {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #4e4e4e;
}
.search-page .search-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.search-page .search-result-item {
  display: flex;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.search-page .search-result-image {
  cursor: pointer;
  flex: 1;
}
.search-page .search-result-image img {
  width: 100%;
  object-fit: cover;
}
.search-page .search-result-info {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 28px;
}
.search-page .search-result-info h3 {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #414142;
  cursor: pointer;
}
.search-page .search-result-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494949;
  margin: 0;
}
.search-page .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.search-page .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.search-page .post-more a {
  color: #eb2e35;
}
.search-page .pointer {
  cursor: pointer;
}
.search-page .icon-clock {
  width: 14px;
  height: 14px;
  margin-top: 5px;
}
.search-page .title {
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-right: 30px;
}
.search-page .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.search-page .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.search-page .time-clock {
  display: flex;
}
.search-page .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.search-page .three-dot-area {
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
}
.search-page .three-dot {
  width: 5px;
  height: 20px;
}
.search-page .main-item {
  display: table;
  margin: 0;
}
.search-page .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22px;
}
.search-page .menu {
  border: 2px solid black;
  background-color: #fffcfc;
  color: #0079ff;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.search-page .menu .menu-row,
.search-page .menu .menu-row-current,
.search-page .menu .menu-row-current-border {
  cursor: pointer;
}
.search-page .menu .menu-row .menu-item-1,
.search-page .menu .menu-row .menu-item-2,
.search-page .menu .menu-row-current .menu-item-1,
.search-page .menu .menu-row-current .menu-item-2,
.search-page .menu .menu-row-current-border .menu-item-1,
.search-page .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid black;
}
.search-page .menu .menu-row-current,
.search-page .menu .menu-row-current-border {
  background-color: #d8d5d5;
  color: #686868;
  border-bottom: 2px;
  border-radius: 0 0 2px 2px;
}
.search-page .menu .menu-row-current-border {
  border-radius: 2px 2px 0 0;
}
.search-page .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
}
.search-page .img-figure,
.search-page .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.search-page .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.search-page .top-new-item {
  margin: 10px 0;
}
.search-page .close-div {
  display: flex;
  margin-top: 3px;
}
.search-page .search-page-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.search-page .search-page-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.search-page .search-page-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.search-page .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.search-page .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.search-page .text-section {
  display: flex;
  margin-top: 10px;
}
.search-page .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.search-page .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.search-page .k-pager-wrap {
  border-color: white !important;
  color: #424242;
  background-color: white !important;
}

.image-slider-a {
  position: relative;
  margin-bottom: 10px;
  min-height: 445px;
}
.image-slider-a .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-slider-a .k-dialog {
  height: auto !important;
  width: auto !important;
}
.image-slider-a kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.image-slider-a .k-scrollview-next, .image-slider-a .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 97px;
  width: 47px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-slider-a .k-scrollview-next .k-icon, .image-slider-a .k-scrollview-prev .k-icon {
  font-size: 30px;
  text-align: center;
}
.image-slider-a .k-scrollview-pageable .k-button {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0);
  border: white solid 1px;
  height: 10px !important;
  width: 10px !important;
  margin: 3px;
}
.image-slider-a .k-scrollview-pageable .k-button::before {
  height: 10px !important;
  width: 10px !important;
}
.image-slider-a .k-scrollview-pageable .k-primary {
  background-color: white;
  border: white;
}
.image-slider-a .statistic {
  width: 250px;
  height: 350px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
  margin-top: -375px;
  right: 50px;
  position: absolute;
  padding: 15px;
}
.image-slider-a .statistic .stat-box {
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}
.image-slider-a .statistic .stat-box .stat-box-title {
  background-color: #d8a64e;
  color: white;
  font-weight: bold;
  text-align: center;
  height: 30px;
  line-height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.image-slider-a .statistic .stat-box .stat-box-info {
  height: calc(100%-106px);
  width: 100%;
  position: relative;
}
.image-slider-a .statistic .stat-box .stat-box-info .stat-description {
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
  color: #535353;
  font-size: 14px;
}
.image-slider-a .statistic .stat-box .stat-box-info .stat-number {
  text-align: center;
  font-weight: bold;
  font-size: 66px;
  line-height: 60px;
  color: #007fae;
}
.image-slider-a .statistic .stat-box .stat-box-info .stat-unit {
  padding: 15px;
  text-align: center;
  color: #535353;
  font-size: 14px;
}
.image-slider-a .statistic .stat-box .hotline {
  bottom: 0;
  padding: 15px;
  box-sizing: border-box;
  position: absolute;
  border-top: solid 2px #f2f2f2;
  width: 100%;
}
.image-slider-a .statistic .stat-box .hotline .hotline-title {
  color: #808080;
  font-weight: bold;
  font-size: 14px;
}
.image-slider-a .statistic .stat-box .hotline .hotline-number {
  color: red;
  font-weight: bold;
  font-size: 16px;
}
.image-slider-a .d-flex {
  visibility: visible !important;
}
.image-slider-a .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.image-slider-a .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.image-slider-a .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.image-slider-a .vertical-dot .menu .menu-row,
.image-slider-a .vertical-dot .menu .menu-row-current,
.image-slider-a .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.image-slider-a .vertical-dot .menu .menu-row .menu-item-1,
.image-slider-a .vertical-dot .menu .menu-row .menu-item-2,
.image-slider-a .vertical-dot .menu .menu-row-current .menu-item-1,
.image-slider-a .vertical-dot .menu .menu-row-current .menu-item-2,
.image-slider-a .vertical-dot .menu .menu-row-current-border .menu-item-1,
.image-slider-a .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.image-slider-a .vertical-dot .menu .menu-row-current,
.image-slider-a .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.image-slider-a .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-slider-a .banner {
  width: 100%;
}
.image-slider-a .title-info-req {
  font-weight: bold;
}
.image-slider-a .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.image-slider-a .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-a .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-a .close-div {
  display: flex;
  margin-top: 3px;
}
.image-slider-a .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0px;
  margin-bottom: 18px;
}
.image-slider-a .slider-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.image-slider-a .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.image-slider-a:hover .vertical-dot {
  visibility: visible;
}

.news-template-11 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-11 .row {
  --bs-gutter-x: 0;
}
.news-template-11 .post-title {
  font-weight: bold;
  border-left: 7px solid #D8A750;
  margin-bottom: 20px;
  min-height: 60px;
  font-size: 24px;
  padding-left: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #007FAE;
  text-transform: uppercase;
}
.news-template-11 .pointer {
  cursor: pointer;
}
.news-template-11 .icon-clock {
  min-width: 14px;
  min-height: 14px;
  background-color: #007FAE;
}
.news-template-11 .title {
  margin: 0;
  margin-left: 20px;
  font-weight: 500;
  font-size: 20px;
}
.news-template-11 .post-image {
  width: 100%;
  border: 1px solid #DCDCDC;
}
.news-template-11 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-11 .three-dot-area {
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
}
.news-template-11 .three-dot {
  width: 5px;
  height: 20px;
}
.news-template-11 .main-item {
  display: table;
  margin: 0;
}
.news-template-11 .list-news {
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.news-template-11 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22px;
}
.news-template-11 .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-11 .menu .menu-row,
.news-template-11 .menu .menu-row-current,
.news-template-11 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-11 .menu .menu-row .menu-item-1,
.news-template-11 .menu .menu-row .menu-item-2,
.news-template-11 .menu .menu-row-current .menu-item-1,
.news-template-11 .menu .menu-row-current .menu-item-2,
.news-template-11 .menu .menu-row-current-border .menu-item-1,
.news-template-11 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-11 .menu .menu-row-current,
.news-template-11 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-11 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-11 .img-figure,
.news-template-11 .img-figure-no-img {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-11 .top-new-item {
  margin-top: 20px;
}
.news-template-11 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-11 .news-template-11-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-11 .news-template-11-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-11 .news-template-11-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-11 .news-template-11-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-11 .text-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.news-template-11 .title-first {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
.news-template-11 .content-detail {
  font-size: 16px;
}
.news-template-11 .first-news {
  display: flex;
  flex-direction: row;
  gap: 24px;
  border-bottom: 1px solid #DDDDDD;
  margin-top: 0px;
  padding-bottom: 20px;
}
.news-template-11 .top-news-detail {
  width: 100%;
}
.news-template-11 .left,
.news-template-11 .right {
  width: 50%;
}
@media (max-width: 768px) {
  .news-template-11 .left,
.news-template-11 .right {
    width: 100%;
  }
  .news-template-11 .first-news {
    flex-direction: column;
  }
}

.news-template-11-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-11-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-11-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-11-dialog .err-text,
.news-template-11-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-11-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-11-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-11-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-11-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-11-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-11-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-11-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-11-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-11-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-11-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-11-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-11-dialog .float-right {
  float: right;
}

.news-template-11-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-11-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-11-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-11-dialog .input-box {
  height: 40px;
  margin: 15px;
  margin-top: 10px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-11-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-11-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-11-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-11-dialog .padding-col {
  padding: 7px 15px 0;
}

.login-page .panel-login .change-password-button {
  width: 100%;
  background-color: #00396b;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}

.login-page .panel-login .cf-forgot-password-button {
  width: 100%;
  background-color: #00396b;
  border: none;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}

.library-page .background-img {
  position: relative;
}
.library-page .background-img img {
  width: 100%;
  height: 181px;
}
.library-page .menu-bar {
  position: relative;
  top: -100px;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
}
.library-page .tab-scroll {
  height: 126px;
  width: 160px;
  margin: 0 14px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}
.library-page .tab-item {
  padding: 5px 10px 5px 10px;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 104px;
  color: black;
  background-color: white;
  bottom: 0px;
  transition: all 0.5s ease;
}
.library-page .tab-des {
  margin: 30px 0;
  font-weight: bold;
  color: #00396b;
}
.library-page .tab-des p {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396b;
}
.library-page .active {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #d6a022;
  text-shadow: 2px 0px 4px #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

app-library {
  position: relative;
  top: -6px;
}

.contact-container {
  margin-bottom: 130px;
  margin-left: 200px;
}
.contact-container input.k-input {
  width: 100% !important;
  height: 44px !important;
  border-radius: 3px !important;
  padding-left: 19px !important;
}
.contact-container .k-input::placeholder, .contact-container .k-input::placeholder {
  color: #B9B9B9;
  text-transform: uppercase;
}
.contact-container .k-i-close {
  height: 100%;
}
.contact-container .contact-title {
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 700;
  color: #00396B;
  text-transform: uppercase;
}
.contact-container .contact-wrapper {
  display: flex;
}
.contact-container .contact-drop {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.contact-container .k-combobox {
  border: 1px solid #C8C8C8 !important;
  width: 100%;
  height: 44px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.contact-container .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.contact-container .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.contact-container .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.contact-container .vertical-dot .menu .menu-row,
.contact-container .vertical-dot .menu .menu-row-current,
.contact-container .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.contact-container .vertical-dot .menu .menu-row .menu-item-1,
.contact-container .vertical-dot .menu .menu-row .menu-item-2,
.contact-container .vertical-dot .menu .menu-row-current .menu-item-1,
.contact-container .vertical-dot .menu .menu-row-current .menu-item-2,
.contact-container .vertical-dot .menu .menu-row-current-border .menu-item-1,
.contact-container .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.contact-container .vertical-dot .menu .menu-row-current,
.contact-container .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.contact-container .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contact-container .d-flex {
  visibility: visible !important;
}
.contact-container .contact-inputs {
  width: 33%;
  margin-right: 68px;
}
.contact-container .contact-input-item {
  margin-bottom: 15px;
}
.contact-container .contact-textarea-item {
  margin-bottom: 26px;
}
.contact-container .k-textbox {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.contact-container .k-textbox::placeholder, .contact-container .k-textarea::placeholder {
  color: #B9B9B9;
  text-transform: uppercase;
}
.contact-container .k-textarea {
  width: 100%;
  height: 197px;
  resize: none;
  border: 1px solid #C8C8C8;
  padding-left: 19px;
  padding-top: 19px;
}
.contact-container .contact-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.contact-container .contact-form-button {
  min-width: 169px;
  font-size: 18px;
  padding: 8px;
  border: none;
  background-color: #00396B;
  color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}
.contact-container .contact-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.contact-container .contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-right: 13px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #D6A022;
}
.contact-container .contact-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #5E5E5E;
}
.contact-container .contact-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}

.routerLink {
  background: white;
  margin-top: 20px;
}

.routerLink .img {
  margin: 2px 12px 6px 12px;
}

.routerLink .mb-routerLink {
  display: none;
}

.routerLink .mb-routerLink span.value {
  color: #3b3b3b;
  font-weight: 500;
}

.routerLink .g-btn {
  margin-top: 5px;
}

.routerLink .g-btn kendo-dropdownbutton button {
  height: 100%;
  display: inline-block;
}

.routerLink .btn-custom button {
  background: #34a853;
  color: white;
}

.routerLink .mb-routerLink .function {
  text-align: right;
}

.routerLink.x-dialog-shared {
  max-height: 600px;
  max-width: 1300px;
}

.routerLink.x-dialog {
  max-height: 200px;
  max-width: 600px;
}

.routerLink .popup-width {
  width: 140px;
}

.routerLink .btn-fr {
  float: right;
}

.routerLink .btn-fl {
  float: left;
  margin: 20px 0px 20px 0px;
}

.routerLink .margin {
  margin-left: 0px;
}

.routerLink .align-center-bt {
  text-align: center;
}

.routerLink .headerOfficeStyle {
  text-align: center;
  color: #675f5f;
  background: #f6f6f6;
  text-overflow: inherit;
  vertical-align: middle;
  padding: 0px;
}

.routerLink .block-nm {
  display: inline;
}

.routerLink .btn-show {
  width: 15px;
  display: inline-table;
  border: none;
  background-color: transparent;
}

.routerLink .mr-r15 {
  margin-right: 15px;
}

.routerLink .icon-mouse {
  cursor: pointer;
}

@media (max-width: 768px) {
  .routerLink .g-routerLink {
    display: none;
  }

  .routerLink {
    justify-content: center;
  }

  .routerLink .is-folder-link {
    padding: 0 !important;
  }

  .routerLink .margin {
    margin-left: 0 !important;
  }

  .routerLink .mb-routerLink {
    display: initial;
  }
}
.routerLink .is-first-item {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.routerLink .obj-item {
  border: 1px solid;
  border-radius: 25px;
  padding: 2px 6px 2px 6px;
  margin: 1px;
  font-size: 11px;
  word-break: break-all;
}

.routerLink .seen-obj {
  color: #1d35ae;
  background-color: #e6ebff;
}

.routerLink .remove-member-style {
  cursor: pointer;
  margin-left: 5px;
}

.routerLink .block-info {
  margin-top: 5px;
  margin-bottom: 5px;
}

.routerLink .obj-item-1 {
  display: inline-block;
}

.routerLink .not-first-item {
  border-top: 1px transparent;
  border-bottom: 1px solid;
}

.routerLink .custom-header-grid {
  background-color: white;
  color: black;
}

.routerLink .nopad-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.routerLink .is-item {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.routerLink .change {
  font-weight: 700;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  cursor: pointer;
  color: #575757 !important;
}

.routerLink .is-folder-link {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  color: #575757;
}

.routerLink .is-current-folder1 {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #575757;
}

.routerLink .is-current-folder {
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #025ACD;
}

.routerLink .file-icon {
  width: 30px;
  height: 33px;
  margin-right: 10px;
}

.routerLink .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.routerLink .btn-group-list {
  float: right;
  margin-right: 15px;
}

.routerLink .is-cursor {
  cursor: pointer;
}

.routerLink .left-icon {
  padding-right: 40px;
}

.routerLink .file-type {
  width: 60%;
}

.routerLink .align-center {
  text-align: center;
}

.routerLink .routerLink-item {
  width: 100%;
  height: 100%;
  border: 0.2px solid #c2c2c2;
  padding: 5px;
  border-radius: 5%;
}

.routerLink .align-center-webkit {
  text-align: -webkit-center;
  margin-bottom: 25px;
}

.routerLink .custom-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.routerLink .btn-elipsis {
  border: none;
  background-color: transparent;
  color: black;
  background-image: none;
}

.btn-elipsis button {
  border: none;
  background-color: transparent;
  color: #919191;
  background-image: none;
}

.routerLink .display-content {
  position: relative;
  background-color: white;
  padding: 15px;
}

.routerLink .upload-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.routerLink .header {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.routerLink .header .gallery .dlt-img {
  visibility: hidden;
  padding: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.routerLink .header .gallery:hover .dlt-img {
  visibility: visible;
}
.routerLink .header img {
  width: 100%;
  height: 100%;
  word-break: break-all;
  cursor: pointer;
}

.routerLink .disabled {
  background-color: #6c757d;
}

.routerLink .search-breadcrumb {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #00396b;
}

.intro-page-dialog {
  margin-left: 0px !important;
}

.intro-page-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.intro-page-dialog .vnpt-upload-img img {
  width: 160px;
}

.intro-page-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.intro-page-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.intro-page-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .intro-page-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.intro-page-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.intro-page-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .intro-page-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .intro-page-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.intro-page-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.intro-page-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .intro-page-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .intro-page-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.intro-page-dialog .row-file {
  margin-bottom: 5px;
}

.intro-page-dialog .float-right {
  float: right;
}

.intro-page-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.intro-page-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.intro-page-dialog .control {
  margin-bottom: 22px;
}

.intro-page-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.intro-page-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.intro-page-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.intro-page-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.intro-page-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-13 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-13 .row {
  --bs-gutter-x: 0;
}
.news-template-13 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-13 .pd-l-10 {
  padding-left: 18px;
}
.news-template-13 .post-title {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #EE4623;
}
.news-template-13 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-13 .post-more a {
  color: #EB2E35;
}
.news-template-13 .pointer {
  cursor: pointer;
}
.news-template-13 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-13 .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-13 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-bottom: 0;
}
.news-template-13 .post-image, .news-template-13 .post-image-cover {
  height: 259px;
  width: 100%;
}
.news-template-13 .post-image-cover {
  object-fit: cover;
}
.news-template-13 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-13 .time-clock {
  display: flex;
}
.news-template-13 .time-text {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #D52929;
}
.news-template-13 .main-item {
  margin: 0;
}
.news-template-13 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-13 .d-flex {
  visibility: visible !important;
}
.news-template-13 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-13 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-13 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-13 .menu .menu-row,
.news-template-13 .menu .menu-row-current,
.news-template-13 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-13 .menu .menu-row .menu-item-1,
.news-template-13 .menu .menu-row .menu-item-2,
.news-template-13 .menu .menu-row-current .menu-item-1,
.news-template-13 .menu .menu-row-current .menu-item-2,
.news-template-13 .menu .menu-row-current-border .menu-item-1,
.news-template-13 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-13 .menu .menu-row-current,
.news-template-13 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-13 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-13 .img-figure, .news-template-13 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-13 .uppercase {
  color: #EE4623;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.news-template-13 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-13 .news-template-13-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-13 .news-template-13-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-13 .news-template-13-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-13 .news-template-13-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-13 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-13 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-13 .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #EE4623;
  border-radius: 3px;
}
.news-template-13 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-13 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-13 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-13 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-13 .text-section :hover {
  color: #00396B;
}
.news-template-13 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #535353;
  letter-spacing: 0.01em;
  margin-bottom: 9px !important;
}
.news-template-13 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
  letter-spacing: -0.01em;
  height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-13 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-13 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-13 .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-13 .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4D4D4D;
  padding-left: 3px;
}
.news-template-13 .top-new-first-item {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-13 .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-13 .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  justify-content: space-between;
}
.news-template-13 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-13 .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-13 .post-list .k-pager-info, .news-template-13 .post-list .k-pager-sizes {
  display: none;
}
.news-template-13 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-13 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-13 .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-13 .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-13 .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-13 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-13 .hide-item {
  display: none;
}
.news-template-13 .line {
  border: 1px solid #DC7E10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-13 .title-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  letter-spacing: -0.01em;
  height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 600px) {
  .news-template-13 .line {
    border: 1px solid #DC7E10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-13 .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-13 .top-new-item {
    padding-right: 10px;
    display: block;
  }
  .news-template-13 .content-right {
    padding-top: 0px;
  }
  .news-template-13 .container-fluid {
    margin-left: 5px;
  }
}

.news-template-13-dialog {
  margin-left: 0px !important;
}

.news-template-13-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-13-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-13-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-13-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-13-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-13-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-13-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-13-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-13-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-13-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-13-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-13-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-13-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-13-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-13-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-13-dialog .float-right {
  float: right;
}

.news-template-13-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-13-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-13-dialog .control {
  margin-bottom: 22px;
}

.news-template-13-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-13-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-13-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-13-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-13-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-13-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-14 {
  width: 100%;
  min-height: 504px;
}
.news-template-14 .row {
  --bs-gutter-x: 0;
}
.news-template-14 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-14 .post-title {
  display: flex;
  justify-content: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-14 .title-text {
  margin-bottom: 0 !important;
}
.news-template-14 .post-more {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #EE4623;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.news-template-14 .post-more a {
  color: #d6a022;
}
.news-template-14 .pointer {
  cursor: pointer;
}
.news-template-14 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-14 .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #292621;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
}
.news-template-14 .post-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-14 .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-14 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-14 .time-clock {
  display: flex;
}
.news-template-14 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-14 .main-item {
  display: table;
}
.news-template-14 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-14 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-14 .d-flex {
  visibility: visible !important;
}
.news-template-14 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-14 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-14 .menu .menu-row,
.news-template-14 .menu .menu-row-current,
.news-template-14 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-14 .menu .menu-row .menu-item-1,
.news-template-14 .menu .menu-row .menu-item-2,
.news-template-14 .menu .menu-row-current .menu-item-1,
.news-template-14 .menu .menu-row-current .menu-item-2,
.news-template-14 .menu .menu-row-current-border .menu-item-1,
.news-template-14 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-14 .menu .menu-row-current,
.news-template-14 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-14 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-14 .img-figure,
.news-template-14 .img-figure-no-img {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-14 .img-figure-2 {
  height: 260px !important;
}
.news-template-14 .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  padding: 0px 17px;
}
.news-template-14 .post-list .k-pager-info, .news-template-14 .post-list .k-pager-sizes {
  display: none;
}
.news-template-14 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-14 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-14 .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-14 .uppercase {
  text-transform: uppercase;
}
.news-template-14 .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-14 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-14 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-14 .news-template-14-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-14 .news-template-14-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-14 .news-template-14-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-14 .news-template-14-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-14 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-14 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-14 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-14 .dialog-width {
  max-width: 400px;
}
.news-template-14 .k-scrollview-next, .news-template-14 .k-scrollview-prev {
  color: white;
  background-color: #EE4623;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 1;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 30px;
  top: calc(50% - 110px);
  width: 36px;
  border-radius: 3px;
}
.news-template-14 .k-scrollview-next .k-icon, .news-template-14 .k-scrollview-prev .k-icon {
  font-size: 1.5em;
  font-weight: normal;
  padding-left: 8px;
}
.news-template-14 .k-scrollview-wrap {
  border: none;
  height: 540px !important;
}
.news-template-14 .dash-line {
  background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  height: 2px;
  margin-bottom: 15px;
}
.news-template-14 .text-item {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
  letter-spacing: -0.01em;
  height: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  white-space: pre-line;
}
.news-template-14 .k-scrollview-wrap .k-scrollview > li {
  height: 530px !important;
}
.news-template-14 .k-scrollview-pageable > .k-button {
  width: 12px;
  height: 12px;
}
@media (max-width: 768px) {
  .news-template-14 .post-list {
    margin-right: 0px;
  }
  .news-template-14 .k-scrollview-wrap {
    border: none;
    height: 485px !important;
  }
}

.news-template-14-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-14-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-14-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-14-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-14-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-14-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-14-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-14-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-14-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-14-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-14-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-14-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-14-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-14-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-14-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-14-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-14-dialog .float-right {
  float: right;
}

.news-template-14-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-14-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-14-dialog .control {
  margin-bottom: 22px;
}

.news-template-14-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-14-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-14-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-14-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-14-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-14-dialog .padding-col {
  padding: 7px 15px 0;
}

.org-tree {
  width: 100%;
  min-height: 504px;
}
.org-tree .row {
  --bs-gutter-x: 0;
}
.org-tree .k-dialog {
  height: auto !important;
  width: auto !important;
}
.org-tree .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.org-tree .title-text {
  margin-bottom: 0 !important;
}
.org-tree .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.org-tree .post-more a {
  color: #d6a022;
}
.org-tree .pointer {
  cursor: pointer;
}
.org-tree .icon-clock {
  width: 14px;
  height: 14px;
}
.org-tree .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #292621;
  margin-bottom: 20px;
}
.org-tree .post-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.org-tree .post-image-default {
  height: 100%;
  width: 100%;
}
.org-tree .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.org-tree .time-clock {
  display: flex;
}
.org-tree .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.org-tree .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.org-tree .main-item:hover .vertical-dot {
  visibility: visible;
}
.org-tree .d-flex {
  visibility: visible !important;
}
.org-tree .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.org-tree .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.org-tree .menu .menu-row,
.org-tree .menu .menu-row-current,
.org-tree .menu .menu-row-current-border {
  cursor: pointer;
}
.org-tree .menu .menu-row .menu-item-1,
.org-tree .menu .menu-row .menu-item-2,
.org-tree .menu .menu-row-current .menu-item-1,
.org-tree .menu .menu-row-current .menu-item-2,
.org-tree .menu .menu-row-current-border .menu-item-1,
.org-tree .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.org-tree .menu .menu-row-current,
.org-tree .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.org-tree .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.org-tree .img-figure,
.org-tree .img-figure-no-img {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.org-tree .img-figure-2 {
  height: 260px !important;
}
.org-tree .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.org-tree .post-list .k-pager-info, .org-tree .post-list .k-pager-sizes {
  display: none;
}
.org-tree .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.org-tree .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.org-tree .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.org-tree .uppercase {
  text-transform: uppercase;
}
.org-tree .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.org-tree .top-new-item .title:hover {
  color: #00396b;
}
.org-tree .close-div {
  display: flex;
  margin-top: 3px;
}
.org-tree .org-tree-dialog, .org-tree .description-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.org-tree .org-tree-dialog .close-icon-div, .org-tree .description-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.org-tree .org-tree-dialog .close-icon, .org-tree .description-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.org-tree .org-tree-dialog .title-info-req, .org-tree .description-dialog .title-info-req {
  font-weight: 500;
  color: #006CB5;
  margin-bottom: 20px;
}
.org-tree .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.org-tree .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.org-tree .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.org-tree .dialog-width {
  max-width: 50%;
  height: 80%;
}
.org-tree .break-line {
  border: 1px dashed #7C7C7C;
  margin: 40px 0px;
}
.org-tree .items-box, .org-tree .items-box-border {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 20px;
}
.org-tree .items-box-border {
  border: 1px dashed #000000;
}
.org-tree .item-blue, .org-tree .item-blue-margin {
  background: #0099DC;
  width: 311px;
  height: 98px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.org-tree .item-blue-margin {
  margin: 20px 10px !important;
}
.org-tree .item-header-unit-member {
  width: auto !important;
  padding: 10px;
}
.org-tree .straight-line {
  height: 105px;
  border-right: 1px dashed #000000;
}
.org-tree .center-box {
  display: flex;
  justify-content: center;
}
.org-tree .margin-items {
  margin: 0px 20px;
}
.org-tree .box-blue-border {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #0099DC;
  width: 326px;
  margin: 30px 0px;
  padding: 10px 0px;
}
.org-tree .img-small {
  width: 50px;
  height: 50px;
  margin: 5px;
  object-fit: contain;
}
.org-tree .center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.org-tree .text-0, .org-tree .text-1, .org-tree .text-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #454545;
}
.org-tree .text-0 {
  padding: 11px 13px 11px 0px;
}
.org-tree .text-1 {
  padding: 0px 5px 0px 0px;
}
.org-tree .text-2 {
  margin: 0px 10px;
}
.org-tree .box-blue-border-no-img {
  background: #FFFFFF;
  border: 1px solid #0099DC;
  width: 223px;
  margin: 30px 0px;
  height: 75px;
  display: flex;
  justify-content: center;
}
.org-tree .unit-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #525252;
  margin-top: 40px;
  padding: 0 30px;
}
.org-tree .no-margin {
  margin: 0 !important;
}
.org-tree .no-padding {
  padding: 0 !important;
}
.org-tree .margin-title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.org-tree .margin-box {
  margin: 0 35px;
  margin-bottom: 40px;
}
.org-tree .margin-box-bottom {
  margin-bottom: 30px;
}
.org-tree .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.org-tree .close-div {
  display: flex;
}
.org-tree .k-window-content {
  padding: 16px 30px;
}
.org-tree .description-text {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6875rem;
}
@media screen and (max-width: 768px) {
  .org-tree .item-blue-margin {
    height: auto;
  }
  .org-tree .center-box {
    width: 100%;
  }
  .org-tree .items-box-border {
    border: none !important;
    padding: 0px;
  }
  .org-tree .item-blue-margin {
    margin: 0px 20px 10px !important;
    height: 51px;
    font-size: 12px;
  }
  .org-tree .straight-line {
    height: 17px;
  }
  .org-tree .box-blue-border {
    justify-content: center;
  }
  .org-tree .item-header-unit-member {
    padding: 40px;
  }
  .org-tree .box-blue-border-no-img {
    width: 326px;
  }
  .org-tree .item-header-unit-member {
    width: 326px !important;
  }
  .org-tree .box-blue-border, .org-tree .box-blue-border-no-img {
    margin: 10px 0px;
  }
  .org-tree .dialog-width {
    max-width: 80% !important;
    height: 80% !important;
    top: 10% !important;
    left: 10% !important;
  }
}
.org-tree .org-img-width {
  max-width: 100%;
}

.org-tree-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.org-tree-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.org-tree-dialog .vnpt-upload-img img {
  width: 160px;
}

.org-tree-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.org-tree-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.org-tree-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .org-tree-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.org-tree-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.org-tree-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .org-tree-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .org-tree-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.org-tree-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.org-tree-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .org-tree-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .org-tree-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.org-tree-dialog .row-file {
  margin-bottom: 5px;
}

.org-tree-dialog .float-right {
  float: right;
}

.org-tree-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.org-tree-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.org-tree-dialog .control {
  margin-bottom: 22px;
}

.org-tree-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.org-tree-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.org-tree-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.org-tree-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.org-tree-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.org-tree-dialog .padding-col {
  padding: 7px 15px 0;
}

header-template4 {
  padding: 0;
  margin: 0;
  position: relative;
}
header-template4 nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
header-template4 #BannerBackGround {
  background-size: cover;
}
header-template4 .row-height {
  height: 40px;
}
header-template4 .nav-bar {
  height: 42px;
}
header-template4 .nav-menu-box {
  background-color: #0467BC;
  width: 100%;
  display: inline-block;
  position: relative;
  z-index: 999;
  margin: auto;
  box-shadow: 0px 5px 0px 0px #DC7E10;
}
header-template4 .nav-menu-box .nav-content {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: inline-flex;
}
header-template4 .nav-menu-box .home-icon {
  margin-top: 5px;
}
header-template4 .nav-menu-box .home-icon,
header-template4 .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header-template4 .menu-icon-box {
  display: none;
}
header-template4 .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
}
header-template4 .nav-menu .dropdown {
  height: fit-content;
  text-align: center;
  display: inline-block;
}
header-template4 .nav-menu .dropdown .material-icons {
  color: white;
  padding-top: 5px;
  font-size: 18px;
}
header-template4 .nav-menu .dropdown .dropdown-home {
  margin: auto;
}
header-template4 .nav-menu .dropdown .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header-template4 .nav-menu .dropdown .item-link {
  color: white;
  text-decoration: none;
  display: inline-block;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  height: 100%;
  width: fit-content;
  padding: 8px 10px;
  cursor: pointer;
}
header-template4 .nav-menu .dropdown .item-link:hover,
header-template4 .nav-menu .dropdown .item-link:active,
header-template4 .nav-menu .dropdown .focus {
  background-color: white !important;
  color: #00396B;
  height: 100%;
  border-radius: 0;
}
header-template4 .nav-menu .dropdown .dropdown-menu {
  background-color: inherit;
  position: relative;
  border: none;
  min-width: 0;
}
header-template4 .nav-menu .dropdown .dropdown-submenu {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 35px;
  left: 0;
}
header-template4 .nav-menu .dropdown .dropdown-submenu a {
  display: block;
}
header-template4 .nav-menu .dropdown .dropdown-item:hover {
  background-color: #dddddd;
}
header-template4 .nav-menu .dropdown .dropdown-item {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid #DEDEDE;
  padding: 0.6rem 1rem;
}
header-template4 .nav-menu .dropdown .dropdown:hover {
  background-color: #133d6c;
}
header-template4 .disable-click {
  pointer-events: none;
  cursor: default;
}
header-template4 .nav-top {
  border-bottom: 1px solid #D6D6D6;
  max-width: 1140px;
  margin: 0 auto;
}
header-template4 .nav-top .contact {
  margin: auto;
  background-color: white;
  position: relative;
  padding-top: 1px;
}
header-template4 .nav-top .contact .search {
  margin: auto 0;
  height: 40px;
}
header-template4 .nav-top .contact .search .form-control {
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #373737;
  margin-top: 5px;
}
header-template4 .nav-top .contact .language {
  font-size: 13px;
  border-left: 1px solid #ECECEC;
  right: 15px;
  color: white;
  margin: auto 0;
  text-align: center;
  cursor: pointer;
  height: 40px;
}
header-template4 .nav-top .contact .language .mat-form-field {
  max-width: 50px;
  margin-top: -5px;
}
header-template4 .nav-top .contact .language .mat-form-field-underline {
  display: none;
}
header-template4 .banner {
  position: relative;
  margin: 20px auto;
  max-width: 1140px;
  margin: 0 auto;
}
header-template4 .banner .row {
  height: 100%;
}
header-template4 .banner .img1 {
  max-width: 117px;
  height: 105px;
  z-index: 5;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
}
header-template4 .banner .name {
  margin: auto 0;
  display: flex;
  flex-direction: row;
}
header-template4 .banner .name a {
  text-decoration: none;
}
header-template4 .banner .name-main,
header-template4 .banner .name-detail {
  display: inline !important;
}
header-template4 .banner .name-main .edit-logo,
header-template4 .banner .name-detail .edit-logo {
  position: absolute;
}
header-template4 .banner .name-main {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 37px;
  color: #028CD4;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.37);
  display: block;
}
header-template4 .banner .name-detail a {
  color: #D7A123;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
header-template4 .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header-template4 .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header-template4 .banner .search-text:focus {
  border: 1px solid white !important;
}
header-template4 .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header-template4 .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header-template4 .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header-template4 .banner .edit-logo {
  visibility: hidden;
}
header-template4 .banner .logo-container:hover .edit-logo,
header-template4 .banner .name:hover .edit-logo {
  visibility: visible;
}
header-template4 .banner .logo-container {
  display: flex;
  flex-direction: row;
}
header-template4 .banner .logo-container .btn-custom {
  width: fit-content;
}
header-template4 .banner .logo-container .k-upload {
  display: none;
}
header-template4 .banner .material-icons {
  font-size: 18px;
}
header-template4 .banner .menu .menus {
  display: inline !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header-template4 .banner .menu .menus .menu-item {
  display: inline;
  width: 33%;
  padding-right: 10px;
}
header-template4 .banner .menu .menus button {
  display: inline;
}
header-template4 .banner .login {
  margin: 45px 0px;
}
header-template4 .banner .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template4 .banner .btn-custom {
  background: initial;
  color: inherit;
}
header-template4 .banner .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: inherit;
  border: none;
}
header-template4 .banner .btn-custom .k-button {
  padding: 0 !important;
}
header-template4 .banner .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
header-template4 .banner .k-popup .k-item {
  width: 100%;
}
header-template4 .banner .btn-add {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
}
header-template4 .banner .btn-add:hover {
  background-color: #f3efef;
}
header-template4 .banner .dropdown {
  position: relative;
  display: inline-block;
}
header-template4 .banner .dropdown-bg {
  padding-top: 20px;
}
header-template4 .banner .dropdown-content {
  display: none;
  position: absolute;
  right: 10px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 230px;
  z-index: 1000;
}
header-template4 .banner .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #DBDBDB;
  font-size: 14px;
  color: #4B4B4B;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
header-template4 .banner .dropdown-content a:hover {
  background-color: #f1f1f1;
}
header-template4 .banner .dropdown-content a:first-child {
  border-top: none;
}
header-template4 .banner .dropdown-content .avatar {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  max-width: 60px;
  max-height: 60px;
}
header-template4 .banner .dropdown-content .avatar img {
  width: 100%;
  height: 100%;
}
header-template4 .banner .dropdown-content .user-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header-template4 .banner .dropdown-content .user-info .role {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}
header-template4 .banner .dropdown-content .user-info .btn-edit-info {
  background-color: #946711;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
}
header-template4 .banner .dropdown:hover .dropdown-content {
  display: block;
}
header-template4 .banner .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
header-template4 .user {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0;
  justify-content: flex-end;
}
header-template4 .user .change-btn {
  margin: 0 10px;
  cursor: pointer;
}
header-template4 .user .avatar {
  margin: 0 10px;
  cursor: pointer;
}
header-template4 .user .avatar img {
  width: 3rem;
  height: 3rem;
}
header-template4 .user .change-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  min-width: 3rem;
  height: 3rem;
}
header-template4 .user .change-btn img {
  width: 1.5rem;
  height: 1.5rem;
}
header-template4 .pointer {
  cursor: pointer;
}
header-template4 .open-menu {
  display: block !important;
}
header-template4 .close-menu {
  display: none !important;
}
header-template4 .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template4 .edit .required,
header-template4 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
header-template4 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
header-template4 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
header-template4 .btn-save {
  color: white;
  background-color: #0b68a4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}
header-template4 kendo-dialog .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}
header-template4 .cskh {
  display: flex;
  flex-direction: column;
  height: 60px !important;
  width: 199px;
  background: #FFFFFF;
  border: 1px solid #027DC3;
  border-radius: 8px;
}
header-template4 .phone-box {
  padding: 0 !important;
  width: fit-content;
}
header-template4 .phone-icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-top: 17px;
  margin-left: 18px;
}
header-template4 .phone-top {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  padding-top: 8px;
}
header-template4 .phone-bottom {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #08659A;
  padding-top: 4px;
}

@media screen and (max-width: 576px) {
  header-template4 .desktop,
header-template4 .desktop-icon {
    display: none !important;
  }
  header-template4 .mobile {
    display: block !important;
  }
  header-template4 nav ul {
    width: auto;
  }
  header-template4 .nav-menu-box {
    height: 1px !important;
  }
  header-template4 .nav-top {
    border-bottom: 1px solid #D6D6D6;
  }
  header-template4 .nav-top .contact {
    margin: auto;
    background-color: white;
    position: relative;
    padding-top: 1px;
  }
  header-template4 .nav-top .contact .search {
    margin: auto 0;
  }
  header-template4 .nav-top .contact .language .mat-form-field {
    width: 65%;
  }
  header-template4 .banner {
    width: 100% !important;
    min-height: auto;
  }
  header-template4 .banner .img1 {
    height: 50px;
    top: 6px;
    left: 50%;
    position: relative;
  }
  header-template4 .banner .logo-container {
    display: block;
  }
  header-template4 .banner .name {
    padding: 0;
    margin: 0 auto;
  }
  header-template4 .banner .name hgroup {
    text-align: center;
  }
  header-template4 .banner .user {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 10px;
  }
  header-template4 .nav-menu {
    font-size: 95% !important;
  }
  header-template4 .nav-menu .dropdown,
header-template4 .nav-menu .item-link {
    display: block;
    text-align: left;
    width: 100% !important;
  }
  header-template4 .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header-template4 .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header-template4 .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header-template4 .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header-template4 .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header-template4 .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header-template4 .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
    top: 10px !important;
  }
  header-template4 .nav-menu .dropdown-menu {
    display: none;
    width: 100% !important;
    border-top: 1px solid #4569cf;
  }
  header-template4 .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header-template4 .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header-template4 .nav-menu .show {
    display: block !important;
  }
  header-template4 .nav-menu .dropdown-menu,
header-template4 .nav-menu .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header-template4 .pading-0 {
    padding: 10px 0 !important;
  }
  header-template4 .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header-template4 .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header-template4 .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header-template4 .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header-template4 .dropdown-item {
    color: #000;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    padding: 0.5rem 0.7rem !important;
    font-weight: bold;
  }
  header-template4 .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header-template4 .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header-template4 .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header-template4 .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header-template4 .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header-template4 .navbar-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0px;
    margin-left: 0%;
    width: 65%;
    background: var(--main-color);
    height: 4000px !important;
    position: fixed;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header-template4 .search-cover-shadow,
header-template4 .navbar-cover-shadow {
    width: 100%;
    top: 0;
    background: var(--main-color);
    height: 4000px;
    position: fixed;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header-template4 .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header-template4 .navbar-hidden {
    display: none;
  }
  header-template4 #navbardropdownMenuLink {
    text-align: left !important;
  }
  header-template4 .login-btn {
    position: relative;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100vw - 20px) !important;
  }
  header-template4 .left-block {
    width: 100%;
    position: relative !important;
  }
}
@media screen and (min-width: 575px) {
  header-template4 .desktop {
    display: block !important;
  }
  header-template4 .mobile {
    display: none !important;
  }
  header-template4 .top-right-menu {
    display: inline !important;
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 15px;
  }
  header-template4 .dropdown-menu:hover .dropdown-submenu {
    display: block;
  }
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.news-template-19 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-19 .row {
  --bs-gutter-x: 0;
}
.news-template-19 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-19 .pd-l-10 {
  padding-left: 18px;
}
.news-template-19 .post-title {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #EE4623;
  position: relative;
}
.news-template-19 .post-title .category {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  animation-duration: 1s;
  animation-timing-function: linear;
}
.news-template-19 .post-title .arrow-icon, .news-template-19 .post-title .arrow-icon-left {
  height: 13px;
  width: 8px;
}
.news-template-19 .post-title .arrow-icon-left {
  margin-right: 10px;
}
.news-template-19 .post-title .arrow-box {
  position: absolute;
  right: 0;
  top: -2px;
}
.news-template-19 .post-title .title-right {
  position: absolute;
  left: 50%;
  width: 50%;
  top: 22px;
  overflow: hidden;
}
.news-template-19 .post-title .category-box {
  min-width: fit-content;
  margin-right: 20px;
}
.news-template-19 .post-title .category-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3E3E3E;
}
.news-template-19 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-19 .post-more a {
  color: #EB2E35;
}
.news-template-19 .pointer {
  cursor: pointer;
}
.news-template-19 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-19 .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-19 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-bottom: 0;
}
.news-template-19 .post-image, .news-template-19 .post-image-cover {
  height: 280px;
  width: 100%;
  border-radius: 5px;
}
.news-template-19 .post-image-cover {
  object-fit: cover;
}
.news-template-19 .post-right-image {
  height: 142px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.news-template-19 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-19 .time-clock {
  display: flex;
}
.news-template-19 .time-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #797979;
  padding-top: 10px;
  margin-bottom: 10px;
}
.news-template-19 .main-item {
  margin: 0;
}
.news-template-19 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-19 .d-flex {
  visibility: visible !important;
}
.news-template-19 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-19 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-19 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-19 .menu .menu-row,
.news-template-19 .menu .menu-row-current,
.news-template-19 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-19 .menu .menu-row .menu-item-1,
.news-template-19 .menu .menu-row .menu-item-2,
.news-template-19 .menu .menu-row-current .menu-item-1,
.news-template-19 .menu .menu-row-current .menu-item-2,
.news-template-19 .menu .menu-row-current-border .menu-item-1,
.news-template-19 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-19 .menu .menu-row-current,
.news-template-19 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-19 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-19 .img-figure, .news-template-19 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-19 .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 30px;
}
.news-template-19 .k-scrollview-wrap {
  border: none;
}
.news-template-19 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-19 .news-template-19-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-19 .news-template-19-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-19 .news-template-19-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-19 .news-template-19-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-19 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-19 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-19 .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #EE4623;
  border-radius: 3px;
}
.news-template-19 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-19 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-19 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-19 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-19 .text-section :hover {
  color: #00396B;
}
.news-template-19 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 0px !important;
}
.news-template-19 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #454545;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-19 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-19 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-19 .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-19 .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4D4D4D;
  padding-left: 3px;
}
.news-template-19 .top-new-first-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-19 .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-19 .top-new-item {
  margin-bottom: 15px;
  padding-right: 20px;
  justify-content: space-between;
}
.news-template-19 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-19 .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-19 .post-list .k-pager-info, .news-template-19 .post-list .k-pager-sizes {
  display: none;
}
.news-template-19 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-19 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-19 .post-list .top-part {
  display: flex;
  flex-direction: row;
}
.news-template-19 .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-19 .post-image-default {
  height: 142px;
  width: 100%;
}
.news-template-19 .first-post-image-default {
  height: 280px;
  width: 100%;
}
.news-template-19 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-19 .hide-item {
  display: none;
}
.news-template-19 .line {
  border: 1px solid #DC7E10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-19 .title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #000000;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-19 .news-title {
  width: fit-content;
}
.news-template-19 .left-box {
  margin-right: 20px;
}
.news-template-19 .right-item-detail {
  padding-left: 10px;
}
@media (max-width: 600px) {
  .news-template-19 .line {
    border: 1px solid #DC7E10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-19 .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-19 .post-list .top-part {
    display: flex;
    flex-direction: column;
  }
  .news-template-19 .top-new-item {
    padding-right: 10px;
    display: block;
  }
  .news-template-19 .content-right {
    padding-top: 0px;
  }
  .news-template-19 .container-fluid {
    margin-left: 5px;
  }
}
@media (max-width: 600px) {
  .news-template-19 .post-title .arrow-box {
    position: relative;
  }
  .news-template-19 .post-title .title-right {
    position: relative;
    left: 0;
    top: 0;
    width: 20%;
  }
}

.news-template-19-dialog {
  margin-left: 0px !important;
}

.news-template-19-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-19-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-19-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-19-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-19-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-19-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-19-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-19-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-19-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-19-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-19-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-19-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-19-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-19-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-19-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-19-dialog .float-right {
  float: right;
}

.news-template-19-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-19-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-19-dialog .control {
  margin-bottom: 22px;
}

.news-template-19-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-19-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-19-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-19-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-19-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-19-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-15 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-15 .row {
  --bs-gutter-x: 0;
}
.news-template-15 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-15 .pd-l-10 {
  padding-left: 18px;
}
.news-template-15 .post-title {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #027DC3;
  position: relative;
  min-height: 40px;
}
.news-template-15 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-15 .post-more a {
  color: #EB2E35;
}
.news-template-15 .pointer {
  cursor: pointer;
}
.news-template-15 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-15 title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  color: #000000;
  margin-left: 14px;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.news-template-15 .post-image, .news-template-15 .post-image-cover {
  height: 284px;
  width: 100%;
}
.news-template-15 .post-image-cover {
  object-fit: cover;
}
.news-template-15 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-15 .time-clock {
  display: flex;
}
.news-template-15 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-15 .main-item {
  display: table;
  margin: 0;
}
.news-template-15 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-15 .d-flex {
  visibility: visible !important;
}
.news-template-15 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-15 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-15 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-15 .menu .menu-row,
.news-template-15 .menu .menu-row-current,
.news-template-15 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-15 .menu .menu-row .menu-item-1,
.news-template-15 .menu .menu-row .menu-item-2,
.news-template-15 .menu .menu-row-current .menu-item-1,
.news-template-15 .menu .menu-row-current .menu-item-2,
.news-template-15 .menu .menu-row-current-border .menu-item-1,
.news-template-15 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-15 .menu .menu-row-current,
.news-template-15 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-15 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-15 .img-figure, .news-template-15 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-15 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-15 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-15 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-15 .news-template-15-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-15 .news-template-15-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-15 .news-template-15-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-15 .news-template-15-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-15 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-15 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-15 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-15 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-15 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-15 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-15 .text-section :hover {
  color: #00396B;
}
.news-template-15 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #535353;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
}
.news-template-15 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #696969;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-15 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-15 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-template-15-dialog {
  margin-left: 0px !important;
}

.news-template-15-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-15-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-15-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-15-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-15-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-15-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-15-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-15-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-15-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-15-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-15-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-15-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-15-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-15-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-15-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-15-dialog .float-right {
  float: right;
}

.news-template-15-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-15-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-15-dialog .control {
  margin-bottom: 22px;
}

.news-template-15-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-15-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-15-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-15-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-15-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-15-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-16 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-16 .row {
  --bs-gutter-x: 0;
}
.news-template-16 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-16 .post-title {
  display: flex;
  color: #0B68A4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #08659A;
  position: relative;
  height: 50px;
}
.news-template-16 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-16 .post-more a {
  color: #EB2E35;
}
.news-template-16 .pointer {
  cursor: pointer;
}
.news-template-16 .icon-clock {
  width: 14px;
  height: 14px;
  color: #08659A;
  margin-top: 1px;
}
.news-template-16 .title {
  font-style: normal;
  font-size: 16px;
  text-align: justify;
  color: #454545;
  margin-left: 9px;
  line-height: 18.5px;
  font-weight: 400;
  margin-bottom: 0;
}
.news-template-16 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-16 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-16 .time-clock {
  display: flex;
}
.news-template-16 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-16 .main-item {
  display: table;
  box-sizing: border-box;
}
.news-template-16 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-16 .top-news-list {
  padding: 17px;
  border: 1px solid #ededed;
}
.news-template-16 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: -14px;
  margin-left: -14px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-16 .d-flex {
  visibility: visible !important;
}
.news-template-16 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-16 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-16 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-16 .menu .menu-row,
.news-template-16 .menu .menu-row-current,
.news-template-16 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-16 .menu .menu-row .menu-item-1,
.news-template-16 .menu .menu-row .menu-item-2,
.news-template-16 .menu .menu-row-current .menu-item-1,
.news-template-16 .menu .menu-row-current .menu-item-2,
.news-template-16 .menu .menu-row-current-border .menu-item-1,
.news-template-16 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-16 .menu .menu-row-current,
.news-template-16 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-16 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-16 .img-figure, .news-template-16 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-16 .uppercase {
  color: #FFFFFF;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-16 .top-new-item {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.news-template-16 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-16 .news-template-16-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-16 .news-template-16-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-16 .news-template-16-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-16 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-16 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-16 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-16 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-16 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-16 .text-section {
  display: flex;
  flex-direction: row;
}
.news-template-16 .text-section :hover {
  color: #00396B;
}
.news-template-16 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
}
.news-template-16 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 576px) {
  .news-template-16 .top-new-item {
    padding: 0px 10px;
  }
}
.news-template-16 .dialog-width {
  max-width: 400px;
}

.news-template-16-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-16-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-16-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-16-dialog .err-text,
.news-template-16-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-16-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-16-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-16-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-16-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-16-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-16-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-16-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-16-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-16-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-16-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-16-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-16-dialog .float-right {
  float: right;
}

.news-template-16-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-16-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-16-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-16-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-17 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-17 .row {
  --bs-gutter-x: 0;
}
.news-template-17 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-17 .pd-l-10 {
  padding-left: 18px;
}
.news-template-17 .post-title {
  display: block;
  align-items: center;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  left: 0;
  /* position: absolute; */
  /* display: block; */
  width: 5px;
  background-color: #EE4623;
  /* position: relative; */
  font-size: 25px;
  line-height: 29px;
  /* background-color: #027DC3; */
  /* position: absolute; */
  min-height: 40px;
}
.news-template-17 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-17 .post-more a {
  color: #EB2E35;
}
.news-template-17 .pointer {
  cursor: pointer;
}
.news-template-17 .icon-clock {
  width: 14px;
  height: 17px;
  margin-top: 3px;
}
.news-template-17 title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  color: #000000;
  margin-left: 14px;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.news-template-17 .post-image,
.news-template-17 .post-image-cover {
  height: 284px;
  width: 100%;
}
.news-template-17 .post-image-cover {
  object-fit: cover;
}
.news-template-17 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-17 .time-clock {
  display: flex;
  margin-left: 10px;
}
.news-template-17 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-17 .main-item {
  display: table;
  margin: 0;
}
.news-template-17 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-17 .d-flex {
  visibility: visible !important;
}
.news-template-17 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-17 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-17 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-17 .menu .menu-row,
.news-template-17 .menu .menu-row-current,
.news-template-17 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-17 .menu .menu-row .menu-item-1,
.news-template-17 .menu .menu-row .menu-item-2,
.news-template-17 .menu .menu-row-current .menu-item-1,
.news-template-17 .menu .menu-row-current .menu-item-2,
.news-template-17 .menu .menu-row-current-border .menu-item-1,
.news-template-17 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-17 .menu .menu-row-current,
.news-template-17 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-17 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-17 .img-figure,
.news-template-17 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-17 .uppercase {
  color: #333333;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  line-height: 19px;
}
.news-template-17 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-17 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-17 .news-template-17-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-17 .news-template-17-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-17 .news-template-17-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-17 .news-template-17-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-17 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-17 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-17 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-17 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-17 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-17 .text-section {
  display: flex;
  flex-direction: row;
}
.news-template-17 .text-section :hover {
  color: #00396B;
}
.news-template-17 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #535353;
  margin-bottom: 15px !important;
  margin-top: 10px !important;
  margin-left: 10px;
  font-weight: 500;
  text-transform: uppercase;
}
.news-template-17 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #696969;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-17 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-17 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-17 p {
  margin-top: 0;
  margin-bottom: 0.3rem !important;
}
.news-template-17 .title2 {
  font-weight: 400;
  font-size: 18px;
  margin-left: 15px;
  color: #4A4A4A;
  line-height: 21px;
}

.news-template-17-dialog {
  margin-left: 0px !important;
}

.news-template-17-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-17-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-17-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-17-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-17-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-17-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-17-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-17-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-17-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-17-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-17-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-17-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-17-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-17-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-17-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-17-dialog .float-right {
  float: right;
}

.news-template-17-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-17-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-17-dialog .control {
  margin-bottom: 22px;
}

.news-template-17-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-17-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-17-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-17-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-17-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-17-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-18 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-18 .row {
  --bs-gutter-x: 0;
}
.news-template-18 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-18 .pd-l-10 {
  padding-left: 18px;
}
.news-template-18 .post-title {
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  position: relative;
  min-height: 40px;
}
.news-template-18 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-18 .post-more a {
  color: #EB2E35;
}
.news-template-18 .pointer {
  cursor: pointer;
}
.news-template-18 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-18 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-18 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-18 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-18 .time-clock {
  display: flex;
}
.news-template-18 .boddy1 {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 1140px; */
}
.news-template-18 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-18 .main-item {
  display: table;
  margin: 0;
}
.news-template-18 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-18 .d-flex {
  visibility: visible !important;
}
.news-template-18 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-18 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-18 .dialog-width {
  max-width: 400px;
}
.news-template-18 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-18 .menu .menu-row,
.news-template-18 .menu .menu-row-current,
.news-template-18 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-18 .menu .menu-row .menu-item-1,
.news-template-18 .menu .menu-row .menu-item-2,
.news-template-18 .menu .menu-row-current .menu-item-1,
.news-template-18 .menu .menu-row-current .menu-item-2,
.news-template-18 .menu .menu-row-current-border .menu-item-1,
.news-template-18 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-18 .menu .menu-row-current,
.news-template-18 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-18 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-18 .img-figure,
.news-template-18 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-18 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-18 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-18 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-18 .news-template-18-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-18 .news-template-18-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-18 .news-template-18-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-18 .news-template-18-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-18 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-18 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-18 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-18 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-18 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-18 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-18 .text-section :hover {
  color: #00396B;
}
.news-template-18 c .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
}
.news-template-18 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-18 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-18 .uppercase-black {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #EE4623;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}
.news-template-18 .intro-des,
.news-template-18 .intro-des-reserve {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6vw;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 1140px;
}
.news-template-18 .intro-des-reserve {
  flex-direction: row-reverse;
}
.news-template-18 .intro-text {
  flex: 1;
  margin: auto;
}
.news-template-18 .intro-text .field-name {
  color: #027DC3 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
  text-transform: uppercase;
}
.news-template-18 .intro-text .field-name:hover {
  color: #00396b !important;
}
.news-template-18 .intro-text .letter {
  color: #323232 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  text-align: justify;
  margin-top: 20px;
}
.news-template-18 .intro-text .letter:hover {
  color: #00396b !important;
}
.news-template-18 .p3 {
  padding: 3.2rem;
}
.news-template-18 .pt3 {
  padding-top: 3.2rem;
}
.news-template-18 .pr3 {
  padding-right: 3.2rem;
}
.news-template-18 .pb3 {
  padding-bottom: 3.2rem;
}
.news-template-18 .pl3 {
  padding-left: 3.2rem;
}
.news-template-18 .image-circle,
.news-template-18 .image-circle-no-border {
  width: 550px;
  height: 400px;
}
.news-template-18 .image-circle-no-border {
  border-radius: unset;
}
.news-template-18 .center-img {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .news-template-18 .image-circle,
.news-template-18 .image-circle-no-border {
    width: 222px;
    height: 222px;
    border-radius: 50%;
    object-fit: cover;
  }
  .news-template-18 .image-circle-no-border {
    border-radius: unset;
  }
  .news-template-18 .intro-des,
.news-template-18 .intro-des-reserve {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2vw;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .news-template-18 .intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .news-template-18 .pattern {
    display: none;
    visibility: hidden;
  }
}
@media screen and (max-width: 40em) {
  .news-template-18 .mobile-m {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

.news-template-18-dialog {
  margin-left: 0px !important;
}

.news-template-18-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-18-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-18-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-18-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-18-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-18-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-18-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-18-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-18-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-18-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-18-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-18-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-18-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-18-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-18-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-18-dialog .float-right {
  float: right;
}

.news-template-18-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-18-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-18-dialog .control {
  margin-bottom: 22px;
}

.news-template-18-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-18-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-18-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-18-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-18-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-18-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-20 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-20 .row {
  --bs-gutter-x: 0;
}
.news-template-20 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-20 .pd-l-10 {
  padding-left: 18px;
}
.news-template-20 .post-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 25px;
  position: relative;
  min-height: 40px;
  justify-content: center;
  padding-top: 30px;
}
.news-template-20 .title-blue {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 25px;
  position: relative;
  color: #0098DA;
}
.news-template-20 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-20 .post-more a {
  color: #EB2E35;
}
.news-template-20 .pointer {
  cursor: pointer;
}
.news-template-20 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-20 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-20 .post-image, .news-template-20 .post-image-cover {
  height: 84px !important;
  width: 84px !important;
}
.news-template-20 .post-image-cover {
  object-fit: cover;
}
.news-template-20 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-20 .time-clock {
  display: flex;
}
.news-template-20 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-20 .main-item {
  display: table;
  margin: 0;
}
.news-template-20 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-20 .d-flex {
  visibility: visible !important;
}
.news-template-20 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-20 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-20 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-20 .menu .menu-row,
.news-template-20 .menu .menu-row-current,
.news-template-20 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-20 .menu .menu-row .menu-item-1,
.news-template-20 .menu .menu-row .menu-item-2,
.news-template-20 .menu .menu-row-current .menu-item-1,
.news-template-20 .menu .menu-row-current .menu-item-2,
.news-template-20 .menu .menu-row-current-border .menu-item-1,
.news-template-20 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-20 .menu .menu-row-current,
.news-template-20 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-20 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-20 .img-figure, .news-template-20 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
  justify-content: center;
}
.news-template-20 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-20 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-20 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-20 .news-template-20-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-20 .news-template-20-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-20 .news-template-20-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-20 .news-template-20-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-20 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-20 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-20 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-20 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-20 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-20 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-20 .text-section :hover {
  color: #00396B;
}
.news-template-20 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 9px !important;
  text-align: center;
  margin-top: 30px;
  height: 60px;
}
.news-template-20 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #454545;
  height: 78px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  text-align: center;
}
@media (max-width: 1200px) {
  .news-template-20 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-20 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-20 .post-row {
  margin-top: 50px;
}
.news-template-20 .post-detail {
  padding: 0 80px;
  margin-top: 50px;
}
.news-template-20 .view-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08659A;
}
.news-template-20 .description-row {
  margin-top: 20px;
}
.news-template-20 .line {
  border: 1px solid #DC7E10;
  margin: 10px 70px;
}

.news-template-20-dialog {
  margin-left: 0px !important;
}

.news-template-20-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-20-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-20-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-20-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-20-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-20-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-20-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-20-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-20-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-20-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-20-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-20-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-20-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-20-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-20-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-20-dialog .float-right {
  float: right;
}

.news-template-20-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-20-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-20-dialog .control {
  margin-bottom: 22px;
}

.news-template-20-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-20-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-20-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-20-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-20-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-20-dialog .padding-col {
  padding: 7px 15px 0;
}

.image-slider-title .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.image-slider-title .banner {
  width: 100% !important;
}
.image-slider-title .image-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #FFFFFF;
  padding: 25px 55px 0px 55px;
  height: 115px;
  background-image: url("../../assets/images/slider-title-background.png") !important;
  white-space: pre-line;
  border-radius: 0px 0px 4px 0px;
}
.image-slider-title .k-dialog {
  height: auto !important;
  width: auto !important;
}
.image-slider-title kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.image-slider-title kendo-scrollview-pager {
  display: none;
}
.image-slider-title .k-scrollview-next,
.image-slider-title .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 36px;
  width: 36px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
}
.image-slider-title .k-scrollview-next {
  right: 2%;
}
.image-slider-title .k-scrollview-prev {
  left: 2%;
}
.image-slider-title .k-scrollview-next .k-icon,
.image-slider-title .k-scrollview-prev .k-icon {
  font-size: 36px;
  text-align: center;
}
.image-slider-title .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.image-slider-title .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-slider-title .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.image-slider-title .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.image-slider-title .vertical-dot .menu .menu-row,
.image-slider-title .vertical-dot .menu .menu-row-current,
.image-slider-title .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.image-slider-title .vertical-dot .menu .menu-row .menu-item-1,
.image-slider-title .vertical-dot .menu .menu-row .menu-item-2,
.image-slider-title .vertical-dot .menu .menu-row-current .menu-item-1,
.image-slider-title .vertical-dot .menu .menu-row-current .menu-item-2,
.image-slider-title .vertical-dot .menu .menu-row-current-border .menu-item-1,
.image-slider-title .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.image-slider-title .vertical-dot .menu .menu-row-current,
.image-slider-title .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.image-slider-title .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-slider-title .banner-container:hover .vertical-dot {
  display: flex;
}
.image-slider-title .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.image-slider-title .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.image-slider-title .title-info-req {
  color: #004A9A;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.image-slider-title .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.image-slider-title .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-title .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-title .employe-detail {
  margin-top: 10px;
}
.image-slider-title .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.image-slider-title .close-div {
  display: flex;
  margin-top: 3px;
}
.image-slider-title .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.image-slider-title .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.image-slider-title .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.image-slider-title .k-scrollview {
  position: relative;
}
@media screen and (max-width: 1200px) {
  .image-slider-title .img-scrollview {
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .image-slider-title .router-outlet-content {
    width: 100% !important;
  }
  .image-slider-title .router-outlet-content-gallery {
    width: 100% !important;
  }
  .image-slider-title .img-scrollview {
    height: 100%;
  }
}
@media screen and (max-width: 770px) {
  .image-slider-title .img-scrollview {
    height: 100%;
  }
  .image-slider-title .image-name {
    font-size: 5vw;
    padding: 20px 50px 0px 50px;
  }
}

.image-slider-title:hover .vertical-dot {
  visibility: visible;
}
.image-slider-title:hover .k-scrollview-next,
.image-slider-title:hover .k-scrollview-prev {
  visibility: visible;
}

.news-template-21 {
  width: 100%;
  background-color: #ffffff !important;
  padding: 0px 10px;
}
.news-template-21 .container-fluid {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.news-template-21 .row {
  --bs-gutter-x: 0;
}
.news-template-21 .scroll-area {
  max-height: 645px;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.news-template-21 .scroll-area::-webkit-scrollbar {
  display: none;
}
.news-template-21 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-21 .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-21 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-21 .post-more a {
  color: #eb2e35;
}
.news-template-21 .pointer {
  cursor: pointer;
}
.news-template-21 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-21 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-21 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-21 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-21 .time-clock {
  display: flex;
}
.news-template-21 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-21 .main-item {
  display: flex;
  background: white;
  flex-direction: column;
}
.news-template-21 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-21 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 3px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-21 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-21 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-21 .d-flex {
  visibility: visible !important;
}
.news-template-21 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-21 .menu .menu-row,
.news-template-21 .menu .menu-row-current,
.news-template-21 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-21 .menu .menu-row .menu-item-1,
.news-template-21 .menu .menu-row .menu-item-2,
.news-template-21 .menu .menu-row-current .menu-item-1,
.news-template-21 .menu .menu-row-current .menu-item-2,
.news-template-21 .menu .menu-row-current-border .menu-item-1,
.news-template-21 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-21 .menu .menu-row-current,
.news-template-21 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-21 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-21 .img-figure,
.news-template-21 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-21 .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-21 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-21 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-21 .news-template-21-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-21 .news-template-21-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-21 .news-template-21-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-21 .news-template-21-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-21 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-21 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-21 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-21 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-21 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-21 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-21 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-21 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-21 .title-holder {
  margin-bottom: 10px;
}
.news-template-21 .list-title {
  width: 153px;
  height: 43px;
  background-color: #006CB5;
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 78% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
  z-index: 1;
  position: relative;
}
.news-template-21 .list-group-item {
  z-index: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 46px;
  color: #FFFFFF;
  padding: 0px 0px 0px 10px;
  border: none;
  background-color: #006CB5;
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 80% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
}
.news-template-21 .title-decorate {
  content: "";
  margin-top: -30px;
  margin-left: 5px;
  width: 164px;
  height: 29px;
  background: rgba(8, 174, 239, 0.49);
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 86% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
}
.news-template-21 .title-bar {
  height: 4px;
  background-color: #006CB5;
}
.news-template-21 .nested-menu .list-submenu {
  padding: 0 10px;
  background-color: #F5F5F5;
}
.news-template-21 .nested-menu .list-submenu :last-child {
  border: none;
}
.news-template-21 .nested-menu .submenu {
  text-decoration: none;
  cursor: pointer;
  list-style-type: none;
  height: auto;
  color: #4a4a4a;
  padding: 0px 0px;
  font-weight: 500;
  display: block;
}
.news-template-21 .nested-menu .submenu .sub-item {
  border-bottom: 1px dashed #848484;
}
.news-template-21 .nested-menu .submenu:first-child {
  padding-top: 0px;
  padding-bottom: 0px;
}
.news-template-21 .nested-menu .item-title-icon {
  display: flex;
}
.news-template-21 .water-icon {
  width: 15px;
  height: 17px;
  margin-top: 7px;
  margin-right: 7px;
}
.news-template-21 .item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #2B2B2B;
  margin-top: 7px;
}
.news-template-21 .item-title-news {
  margin-left: 15px;
  width: 38px;
  height: 17px;
  background-color: #E81818;
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
  color: #FFFFFF;
  display: inline-block;
}
.news-template-21 .item-keyword {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #027DC3;
}
.news-template-21 .item-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #A8A8A8;
}
.news-template-21 .item-info {
  margin-bottom: 7px;
  margin-top: 3px;
  margin-left: 22px;
}
.news-template-21 .sidebar {
  border-left: solid 0.7px #E7E7E7;
  padding-left: 10px;
}
@media (max-width: 770px) {
  .news-template-21 {
    padding: 0px !important;
  }
  .news-template-21 .sidebar {
    border-left: none !important;
    padding-left: 0px !important;
  }
}

.news-template-21-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-21-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-21-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-21-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-21-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-21-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-21-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-21-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-21-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-21-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-21-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-21-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-21-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-21-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-21-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-21-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-21-dialog .float-right {
  float: right;
}

.news-template-21-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-21-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-21-dialog .control {
  margin-bottom: 22px;
}

.news-template-21-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-21-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-21-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-21-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-21-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-21-dialog .padding-col {
  padding: 7px 15px 0;
}

.quiz-container {
  margin-left: 200px;
}
.quiz-container input.k-input {
  width: 100% !important;
  height: 44px !important;
  border-radius: 3px !important;
  padding-left: 19px !important;
}
.quiz-container .k-input::placeholder, .quiz-container .k-input::placeholder {
  color: #B9B9B9;
  text-transform: uppercase;
}
.quiz-container .k-i-close {
  height: 100%;
}
.quiz-container .quiz-title {
  margin-top: 30px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #404040;
  text-transform: uppercase;
  margin-left: 30px;
}
.quiz-container .quiz-wrapper {
  display: flex;
}
.quiz-container .quiz-drop {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.quiz-container .k-combobox {
  border: 1px solid #C8C8C8 !important;
  width: 100%;
  height: 44px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.quiz-container .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.quiz-container .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.quiz-container .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.quiz-container .vertical-dot .menu .menu-row,
.quiz-container .vertical-dot .menu .menu-row-current,
.quiz-container .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.quiz-container .vertical-dot .menu .menu-row .menu-item-1,
.quiz-container .vertical-dot .menu .menu-row .menu-item-2,
.quiz-container .vertical-dot .menu .menu-row-current .menu-item-1,
.quiz-container .vertical-dot .menu .menu-row-current .menu-item-2,
.quiz-container .vertical-dot .menu .menu-row-current-border .menu-item-1,
.quiz-container .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quiz-container .vertical-dot .menu .menu-row-current,
.quiz-container .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.quiz-container .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.quiz-container .d-flex {
  visibility: visible !important;
}
.quiz-container .quiz-inputs {
  width: 33%;
  margin-right: 68px;
}
.quiz-container .quiz-input-item, .quiz-container .quiz-input-item-captcha {
  margin-bottom: 15px;
  width: 590px;
}
.quiz-container .quiz-input-item-captcha {
  display: flex;
}
.quiz-container .quiz-textarea-item, .quiz-container .quiz-button-item {
  width: 590px;
  margin-bottom: 26px;
  display: flex;
  flex-wrap: wrap;
}
.quiz-container .quiz-textarea-item .k-upload, .quiz-container .quiz-button-item .k-upload {
  display: none;
}
.quiz-container .quiz-button-item {
  margin-top: 10px;
}
.quiz-container .k-textbox {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.quiz-container .k-textbox::placeholder, .quiz-container .k-textarea::placeholder {
  color: #B9B9B9;
  text-transform: uppercase;
}
.quiz-container .k-textarea {
  width: 100%;
  height: 197px;
  resize: none;
  border: 1px solid #C8C8C8;
  padding-left: 19px;
  padding-top: 19px;
}
.quiz-container .quiz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.quiz-container .quiz-form-button {
  min-width: 169px;
  font-size: 18px;
  padding: 8px;
  border: none;
  background-color: #00396B;
  color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}
.quiz-container .quiz-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.quiz-container .quiz-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-right: 13px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #D6A022;
}
.quiz-container .quiz-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #5E5E5E;
}
.quiz-container .quiz-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.quiz-container .send-background {
  background: #F9F9F9;
  margin-top: 20px;
}
.quiz-container .k-tabstrip-items {
  background-color: #027DC3;
  justify-content: normal;
}
.quiz-container .k-tabstrip-items .k-item {
  color: white;
  border: none;
}
.quiz-container .k-tabstrip-items .k-item.k-state-active {
  color: white;
  background-color: #03598A;
  border: none;
}
.quiz-container .k-tabstrip-items .k-link {
  padding: 14px 26px;
}
.quiz-container .k-tabstrip > .k-content {
  padding: 0;
}
.quiz-container .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.quiz-container .quiz-form {
  padding-left: 15px;
}
.quiz-container .data-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #414141;
  display: flex;
  align-content: center;
  align-items: center;
  height: 40px;
}
.quiz-container .required {
  color: #EE2323;
  padding-left: 3px;
}
.quiz-container .add-image-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #EE4623;
  height: 130px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.quiz-container .image-upload, .quiz-container .add-image {
  width: 200px;
  height: 130px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.quiz-container .add-image {
  border: 1px dashed #EE4623;
}
.quiz-container .captcha-item {
  background-color: white;
  height: 40px;
  width: 222px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
}
.quiz-container .reload-captcha-btn {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.quiz-container .reload-icon {
  height: 16px;
  width: 16px;
}
.quiz-container .quiz-submit-button {
  min-width: 169px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  border: none;
  background: #EE4623;
  color: #FFFFFF;
  border-radius: 3px;
}
.quiz-container .quiz-reset-button {
  min-width: 169px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  color: #727272;
  border-radius: 3px;
  margin-left: 20px;
}
.quiz-container .k-content.k-state-active {
  border: none;
  overflow: hidden;
}
.quiz-container .categories-box {
  width: 100%;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 144, 200, 0.84);
  margin-bottom: 20px;
  margin-top: 20px;
}
.quiz-container .image-categories {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.quiz-container .categories-text {
  position: absolute;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
}
.quiz-container .categories-area {
  padding-left: 20px;
}
.quiz-container .header-area, .quiz-container .footer-area {
  padding: 0 !important;
}
.quiz-container .wrapper-area-flex {
  display: flex;
  justify-content: center;
}
.quiz-container .wrapper {
  width: 1140px;
}
@media screen and (max-width: 600px) {
  .quiz-container .quiz-form {
    padding: 0 15px;
  }
  .quiz-container .form-row {
    display: block;
  }
  .quiz-container .quiz-input-item {
    width: 100%;
  }
  .quiz-container .quiz-textarea-item {
    width: 100%;
    display: block;
  }
  .quiz-container .quiz-input-item-captcha {
    display: block;
  }
  .quiz-container .captcha-item {
    margin-left: 0;
    margin-top: 10px;
  }
  .quiz-container .reload-captcha-btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .quiz-container .quiz-button-item {
    flex-direction: column;
    width: 100%;
  }
  .quiz-container .quiz-reset-button {
    margin-left: 0;
    margin-top: 10px;
  }
}

.quiz-search .title-quiz {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
  border-bottom: #c60000 solid 2px;
  width: fit-content;
}
.quiz-search .title-answer {
  margin-top: 0px !important;
}
.quiz-search .custom-text {
  color: black;
}
.quiz-search .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
}
.quiz-search .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.quiz-search .contents {
  font-family: inherit !important;
  white-space: inherit;
  font-size: inherit !important;
  margin-left: 20px;
}
.quiz-search .contents .contents_label {
  color: #9fa19f;
}
.quiz-search .custom-color {
  color: #c60000;
}
.quiz-search .custom-row {
  background-color: white;
  cursor: pointer;
}
.quiz-search .margin30 {
  margin: 30px 0px 32px 0px;
}
.quiz-search .btn-search {
  color: white;
  width: 130px;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 16px !important;
  font-family: inherit;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  overflow: visible;
  background: #EE4623;
  border-radius: 3px;
  border: none;
  height: 40px;
}
.quiz-search .total-quiz {
  font-weight: bold;
  font-size: 16px;
}
.quiz-search .hr-style {
  margin-top: 2px;
  margin-bottom: 15px;
}
.quiz-search .xbutton-dialog-quiz {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  float: right;
  color: black !important;
  font-size: 12px !important;
}
.quiz-search .control-search input {
  font-size: 16px !important;
  margin-left: 2px;
}
.quiz-search .control-search input::placeholder {
  color: #bbbaba;
}
.quiz-search .control-search .btn-search {
  width: 35px;
  height: 34px;
  float: right;
  border-radius: 0;
  margin-top: -34px;
  border: 0;
  background-color: #985d1a !important;
}
.quiz-search .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: black !important;
  width: 30px;
  height: 32px;
  float: right;
  border-radius: 0;
  margin-top: -39px;
  border: 0;
  font-size: 20px;
  z-index: 1;
  position: sticky;
}
.quiz-search .control-search .btn-remove-replace-btn-search {
  right: 0 !important;
}
.quiz-search .control > * {
  width: 100%;
}
.quiz-search .list-quiz {
  cursor: pointer;
}
.quiz-search .list-quiz aside {
  color: #515460;
}
.quiz-search .list-quiz article:hover h3 {
  color: #2569b8;
}
.quiz-search .list-quiz article:hover aside {
  color: #2569b8;
}
.quiz-search .list-quiz article:hover aside .contents_label {
  color: #4e7ed8;
  opacity: 0.8;
}
.quiz-search .k-pager-numbers .k-link {
  color: black;
  border-width: 1px;
  margin: 4px;
  border-color: #DADADA;
}
.quiz-search .k-pager-wrap {
  border: none;
  color: #424242;
  background-color: transparent;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  justify-content: end;
}
.quiz-search .k-pager-numbers .k-link.k-state-selected {
  background-color: #08659A;
  color: white !important;
}
.quiz-search .k-pager-nav {
  border: solid 1px;
  margin: 4px;
  border-color: #DADADA;
}
.quiz-search .float-right {
  justify-content: end;
  display: flex;
}
.quiz-search .search-place {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  height: 44px;
  padding-left: 10px;
}
.quiz-search .water-icon {
  width: 20px;
  height: 20px;
}
.quiz-search .question-row {
  flex-direction: row;
  --bs-gutter-x: 0;
  margin-bottom: 20px;
}
.quiz-search .icon-box {
  width: fit-content;
}
.quiz-search .question-name {
  width: fit-content;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #3C3C3C;
}
.quiz-search .break-line {
  border: 1px solid #E5E5E5;
  margin-bottom: 30px;
}
.quiz-search .category-title1 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #EE4623;
  margin-bottom: 30px;
}
.quiz-search .view-more-box {
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
}
.quiz-search .btn-view-more {
  margin-top: 10px;
}

@media screen and (max-width: 770px) {
  .quiz-search-detail.x-dialog {
    width: 95% !important;
    height: 95% !important;
  }
}
.quiz-search-detail.x-dialog {
  max-width: 800px;
  max-height: 800px;
}

.quiz-search-detail .title-quiz {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: solid #c60000;
  width: fit-content;
}
.quiz-search-detail .title-answer {
  margin-top: 0px !important;
}
.quiz-search-detail .custom-text {
  color: black;
  font-size: 16px;
}
.quiz-search-detail .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
  margin-bottom: 20px;
}
.quiz-search-detail .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.quiz-search-detail .contents {
  font-family: inherit !important;
  white-space: pre-wrap;
  font-size: inherit;
}
.quiz-search-detail .custom-color {
  color: #c60000;
}
.quiz-search-detail a {
  text-decoration: none;
}
.quiz-search-detail .custom-text-file {
  font-weight: bold;
  color: black;
  margin-bottom: 8px !important;
}
.quiz-search-detail .custom-text-file:hover {
  color: #194f8b;
}
.quiz-search-detail .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black !important;
  font-size: 30px !important;
}
.quiz-search-detail .title-info-req {
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
}
.quiz-search-detail .question-icon-box, .quiz-search-detail .answer-icon-box {
  background-color: #DC7E10;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.quiz-search-detail .answer-icon-box {
  background-color: #027DC3;
}
.quiz-search-detail .question-icon {
  height: 40px;
  width: 36px;
}
.quiz-search-detail .quiz-box {
  display: flex;
}
.quiz-search-detail .question-box {
  margin-left: 40px;
}
.quiz-search-detail .white-question-icon {
  width: 32px;
  height: 40px;
}
.quiz-search-detail .file-attach {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #767676;
}
.quiz-search-detail .attach-file-icon {
  width: 24px;
  height: 24px;
}

.images-list {
  height: auto;
  margin-bottom: 20px;
}
.images-list .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
@media (min-width: 576px) {
  .images-list .img-scrollview .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
    padding: 0px 5px 0px 5px;
  }
}
.images-list .img-scrollview-last {
  padding: 0px;
}
.images-list .banner {
  width: 100% !important;
  height: 100%;
  justify-content: space-between;
  --bs-gutter-x: 0;
}
.images-list .image-name {
  position: absolute;
  text-align: center;
  height: fit-content;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #FFFFFF;
  padding: 25px 55px 0px 55px;
  height: 115px;
  white-space: pre-line;
  border-radius: 0px 0px 4px 0px;
}
.images-list .k-dialog {
  height: auto !important;
  width: auto !important;
}
.images-list kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.images-list kendo-scrollview-pager {
  display: none;
}
.images-list .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.images-list .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.images-list .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.images-list .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.images-list .vertical-dot .menu .menu-row,
.images-list .vertical-dot .menu .menu-row-current,
.images-list .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.images-list .vertical-dot .menu .menu-row .menu-item-1,
.images-list .vertical-dot .menu .menu-row .menu-item-2,
.images-list .vertical-dot .menu .menu-row-current .menu-item-1,
.images-list .vertical-dot .menu .menu-row-current .menu-item-2,
.images-list .vertical-dot .menu .menu-row-current-border .menu-item-1,
.images-list .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.images-list .vertical-dot .menu .menu-row-current,
.images-list .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.images-list .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.images-list .banner-container:hover .vertical-dot {
  display: flex;
}
.images-list .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.images-list .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.images-list .title-info-req {
  color: #004A9A;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.images-list .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.images-list .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.images-list .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.images-list .employe-detail {
  margin-top: 10px;
}
.images-list .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.images-list .close-div {
  display: flex;
  margin-top: 3px;
}
.images-list .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.images-list .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.images-list .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.images-list .k-scrollview {
  position: relative;
}
@media (max-width: 1024px) {
  .images-list .router-outlet-content {
    width: 100% !important;
  }
  .images-list .router-outlet-content-gallery {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .images-list .banner {
    flex-wrap: nowrap;
  }
}
@media (min-width: 768) {
  .images-list .banner {
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .images-list .img-scrollview {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .images-list .img-scrollview-last {
    margin-right: 16px;
  }
}
@media (min-width: 768px) {
  .images-list .col-md-3 {
    width: 24% !important;
  }
}

.images-list:hover .vertical-dot {
  visibility: visible;
}
.images-list:hover .k-scrollview-next,
.images-list:hover .k-scrollview-prev {
  visibility: visible;
}

.images-slider .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.images-slider .post-more {
  display: flex;
  justify-content: center;
}
.images-slider .primary-title {
  cursor: pointer;
  color: #000000;
  margin-top: 48px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30.17px;
  display: flex;
  justify-content: center;
}
.images-slider .primary-title span {
  color: #006cb5;
  margin-left: 6px;
}
.images-slider .sub-title {
  margin-top: 36px;
  margin-bottom: 75px;
  max-width: 815px;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: #323232;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.images-slider .sub-title p {
  margin-bottom: 0;
}
.images-slider .k-scrollview-wrap .k-scrollview > li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.images-slider .item-list {
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 17px;
  gap: 0px 144px;
  min-height: 601px;
  align-items: center;
  max-width: 100%;
  margin-bottom: 156px;
  min-width: 100%;
  position: relative;
  justify-content: center;
}
.images-slider .item-list .arrow {
  display: flex;
  position: absolute;
  z-index: 200;
  user-select: none;
}
.images-slider .item-list .arrow .next-arrow,
.images-slider .item-list .arrow .prev-arrow {
  transition: 0.6s;
  height: 41px;
  width: 41px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.images-slider .item-list .arrow .next-arrow:hover,
.images-slider .item-list .arrow .prev-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.images-slider .item-list .arrow .next-arrow {
  margin-left: 519px;
}
.images-slider .item-list .slide {
  display: flex;
  gap: 0 144px;
  align-items: center;
  justify-content: center;
}
.images-slider .item-list .item {
  display: flex;
  flex-direction: column;
  width: 367px;
  height: 422px;
  max-width: 100%;
  max-height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 6px rgba(0, 0, 0, 0.1);
  padding: 91px 36px 44px 37px;
  transition: 0.7s ease;
  z-index: 1;
  overflow: hidden;
}
.images-slider .item-list .item .title h4 {
  line-height: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #292621;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  color: #006cb5;
  margin-top: 72px;
  margin-bottom: 9px;
}
.images-slider .item-list .item .text-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #353535;
  letter-spacing: -0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}
.images-slider .item-list .item .image {
  display: flex;
  justify-content: center;
}
.images-slider .item-list .item .btn {
  display: none;
}
.images-slider .item-list .item.active {
  padding: 91px 86px 40px 85px;
  background: linear-gradient(180deg, #006cb5 8.33%, #0098da 38.54%, #00aeef 91.6%);
  width: 416px;
  height: 601px;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}
.images-slider .item-list .item.active .post-image {
  filter: brightness(0) invert(1);
}
.images-slider .item-list .item.active .title h4 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 88px;
}
.images-slider .item-list .item.active .text-item {
  overflow: hidden;
  margin-bottom: 81px;
}
.images-slider .item-list .item.active .text-item p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.images-slider .item-list .item.active .btn {
  display: flex;
  justify-content: flex-start;
  padding: 0;
}
.images-slider .item-list .item.active .btn-detail {
  display: inline-block;
  padding: 12px 41px;
  border: 1px solid #ffffff;
  text-decoration: none;
  color: #ffffff;
  max-width: 159px;
  max-height: 50px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.5px;
  cursor: pointer;
  transition: 0.6s;
}
.images-slider .item-list .item.active .btn-detail:hover {
  opacity: 0.8;
}
.images-slider .item-list .item.active .post-image {
  max-width: 98px;
  max-height: 122px;
}
.images-slider .banner {
  width: 100% !important;
}
.images-slider .image-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #ffffff;
  padding: 25px 55px 0px 55px;
  height: 115px;
  background-image: url("../../assets/images/slider-title-background.png") !important;
  white-space: pre-line;
  border-radius: 0px 0px 4px 0px;
}
.images-slider .k-dialog {
  height: auto !important;
  width: auto !important;
}
.images-slider kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.images-slider kendo-scrollview-pager {
  display: none;
}
.images-slider .k-scrollview-next,
.images-slider .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 36px;
  width: 36px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
}
.images-slider .k-scrollview-next {
  right: 2%;
}
.images-slider .k-scrollview-prev {
  left: 2%;
}
.images-slider .k-scrollview-next .k-icon,
.images-slider .k-scrollview-prev .k-icon {
  font-size: 36px;
  text-align: center;
}
.images-slider .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.images-slider .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.images-slider .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.images-slider .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.images-slider .vertical-dot .menu .menu-row,
.images-slider .vertical-dot .menu .menu-row-current,
.images-slider .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.images-slider .vertical-dot .menu .menu-row .menu-item-1,
.images-slider .vertical-dot .menu .menu-row .menu-item-2,
.images-slider .vertical-dot .menu .menu-row-current .menu-item-1,
.images-slider .vertical-dot .menu .menu-row-current .menu-item-2,
.images-slider .vertical-dot .menu .menu-row-current-border .menu-item-1,
.images-slider .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.images-slider .vertical-dot .menu .menu-row-current,
.images-slider .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.images-slider .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.images-slider .banner-container:hover .vertical-dot {
  display: flex;
}
.images-slider .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.images-slider .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.images-slider .title-info-req {
  color: #004a9a;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.images-slider .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.images-slider .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.images-slider .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.images-slider .employe-detail {
  margin-top: 10px;
}
.images-slider .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.images-slider .close-div {
  display: flex;
  margin-top: 3px;
}
.images-slider .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.images-slider .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.images-slider .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.images-slider .k-scrollview {
  position: relative;
}
@media (max-width: 1024px) {
  .images-slider .router-outlet-content {
    width: 100% !important;
  }
  .images-slider .router-outlet-content-gallery {
    width: 100% !important;
  }
}
@media screen and (max-width: 992px) {
  .images-slider .post-more {
    padding: 0 16px;
  }
}
@media screen and (max-width: 768px) {
  .images-slider .primary-title {
    font-size: 18px !important;
    line-height: 24px;
  }
  .images-slider .sub-title {
    font-size: 16px !important;
    line-height: 28px;
  }
  .images-slider .title h4 {
    font-size: 16px !important;
  }
  .images-slider .text-item p {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 610px) {
  .images-slider .next-arrow {
    margin-left: 460px !important;
  }
}
@media screen and (max-width: 576px) {
  .images-slider .arrow {
    display: flex;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .images-slider .arrow .next-arrow {
    margin-left: 40px !important;
  }
  .images-slider .arrow .next-arrow,
.images-slider .arrow .prev-arrow {
    background: rgba(0, 0, 0, 0.11);
  }
}

.images-slider:hover .vertical-dot {
  visibility: visible;
}
.images-slider:hover .k-scrollview-next,
.images-slider:hover .k-scrollview-prev {
  visibility: visible;
}

header-template5 {
  padding: 0;
  margin: 0;
  position: relative;
}
header-template5 .pointer {
  cursor: pointer;
  margin-right: 20px;
}
header-template5 .navbar-collapse-custom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1002;
}
header-template5 .nav-item {
  color: #006CB5 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  padding: 0.5rem 0.7rem !important;
  font-weight: bold;
}
header-template5 nav ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}
header-template5 #BannerBackGround {
  background-size: cover;
}
header-template5 .row-height {
  height: 40px;
}
header-template5 .nav-bar {
  margin: auto;
  width: calc(100% - 250px);
  min-width: 80%;
  max-width: 1550px;
}
header-template5 .nav-menu-box {
  background-color: white;
  width: 100%;
  display: flex;
  position: relative;
  z-index: 10000;
  margin: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
}
header-template5 .nav-menu-box .nav-content {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: inline-flex;
  display: flex;
  justify-content: center;
}
header-template5 .nav-menu-box .home-icon {
  margin-top: 5px;
}
header-template5 .nav-menu-box .home-icon,
header-template5 .nav-menu-box .menu-icon {
  color: white;
  cursor: pointer;
}
header-template5 .menu-icon-box {
  display: none;
}
header-template5 .nav-menu {
  width: 100%;
  height: auto;
  font-size: 95%;
  display: flex;
  justify-content: space-between;
}
header-template5 .nav-menu .dropdown {
  height: 100%;
  text-align: center;
  display: inline-block;
}
header-template5 .nav-menu .dropdown .material-icons {
  color: white;
  padding-top: 5px;
  font-size: 15px;
}
header-template5 .nav-menu .dropdown .dropdown-home {
  margin: auto;
}
header-template5 .nav-menu .dropdown .play-arrow {
  color: #656565;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  right: 0;
  padding-top: 6px;
}
header-template5 .nav-menu .dropdown .item-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-transform: uppercase;
  height: 100%;
  width: fit-content;
  padding: 15px 10px;
  justify-content: center;
  cursor: pointer;
  color: #025ACD;
}
header-template5 .nav-menu .dropdown .item-link .menu-name {
  display: flex;
  align-items: center;
}
header-template5 .nav-menu .dropdown .item-link:hover,
header-template5 .nav-menu .dropdown .item-link:active,
header-template5 .nav-menu .dropdown .focus {
  background-color: #006CB5 !important;
  color: white;
  height: 100%;
  border-radius: 0;
}
header-template5 .nav-menu .dropdown .dropdown-menu {
  background-color: inherit;
  position: relative;
  border: none;
  min-width: 0;
}
header-template5 .nav-menu .dropdown .dropdown-submenu {
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 100%;
  left: 0;
}
header-template5 .nav-menu .dropdown .dropdown-submenu a {
  display: block;
}
header-template5 .nav-menu .dropdown .dropdown-item:hover {
  background-color: #dddddd;
}
header-template5 .nav-menu .dropdown .dropdown-item {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid #DEDEDE;
  padding: 0.6rem 1rem;
  color: #006CB5;
}
header-template5 .nav-menu .dropdown .dropdown:hover {
  background-color: #133d6c;
}
header-template5 .disable-click {
  pointer-events: none;
  cursor: default;
}
header-template5 .nav-top {
  border-bottom: 1px solid #D6D6D6;
  max-width: 1140px;
  margin: 0 auto;
}
header-template5 .nav-top .contact {
  margin: auto;
  background-color: white;
  position: relative;
  padding-top: 1px;
}
header-template5 .nav-top .contact .search {
  margin: auto 0;
  height: 40px;
}
header-template5 .nav-top .contact .search .form-control {
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #373737;
  margin-top: 5px;
}
header-template5 .nav-top .contact .language {
  font-size: 13px;
  border-left: 1px solid #ECECEC;
  right: 15px;
  color: white;
  margin: auto 0;
  text-align: center;
  cursor: pointer;
  height: 40px;
}
header-template5 .nav-top .contact .language .mat-form-field {
  max-width: 50px;
  margin-top: -5px;
}
header-template5 .nav-top .contact .language .mat-form-field-underline {
  display: none;
}
header-template5 .banner {
  position: relative;
  margin: 20px auto;
  max-width: 1140px;
  margin: 0 auto;
}
header-template5 .banner .row {
  height: 100%;
}
header-template5 .banner .img1 {
  max-width: 117px;
  height: 105px;
  z-index: 5;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
}
header-template5 .banner .name {
  margin: auto 0;
  display: flex;
  flex-direction: row;
}
header-template5 .banner .name a {
  text-decoration: none;
}
header-template5 .banner .name-main,
header-template5 .banner .name-detail {
  display: inline !important;
}
header-template5 .banner .name-main .edit-logo,
header-template5 .banner .name-detail .edit-logo {
  position: absolute;
}
header-template5 .banner .name-main {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  color: #FFFFFF;
  display: block;
}
header-template5 .banner .name-detail a {
  color: #D7A123;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
header-template5 .banner .search:focus {
  border: 1px solid #f3efef !important;
}
header-template5 .banner .search-icon {
  color: #9a9a9a;
  position: absolute;
  right: 15px;
  top: 52px;
  z-index: 999;
  cursor: pointer;
}
header-template5 .banner .search-text:focus {
  border: 1px solid white !important;
}
header-template5 .banner .banner-link {
  cursor: pointer;
  z-index: 997;
  position: absolute;
  width: 100%;
  height: 100%;
}
header-template5 .banner .banner-background {
  cursor: default;
  z-index: 1;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
}
header-template5 .banner .color-text-selected {
  color: #363842;
  cursor: default;
}
header-template5 .banner .edit-logo {
  visibility: hidden;
}
header-template5 .banner .logo-container:hover .edit-logo,
header-template5 .banner .name:hover .edit-logo {
  visibility: visible;
}
header-template5 .banner .logo-container {
  display: flex;
  flex-direction: row;
}
header-template5 .banner .logo-container .btn-custom {
  width: fit-content;
}
header-template5 .banner .logo-container .k-upload {
  display: none;
}
header-template5 .banner .material-icons {
  font-size: 18px;
}
header-template5 .banner .menu .menus {
  display: inline !important;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header-template5 .banner .menu .menus .menu-item {
  display: inline;
  width: 33%;
  padding-right: 10px;
}
header-template5 .banner .menu .menus button {
  display: inline;
}
header-template5 .banner .login {
  margin: 45px 0px;
}
header-template5 .banner .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template5 .banner .btn-custom {
  background: initial;
  color: inherit;
}
header-template5 .banner .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: inherit;
  border: none;
}
header-template5 .banner .btn-custom .k-button {
  padding: 0 !important;
}
header-template5 .banner .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
header-template5 .banner .k-popup .k-item {
  width: 100%;
}
header-template5 .banner .btn-add {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
}
header-template5 .banner .btn-add:hover {
  background-color: #f3efef;
}
header-template5 .banner .dropdown {
  position: relative;
  display: inline-block;
}
header-template5 .banner .dropdown-bg {
  padding-top: 20px;
}
header-template5 .banner .dropdown-content {
  display: none;
  position: absolute;
  right: 10px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 230px;
  z-index: 1000;
}
header-template5 .banner .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #DBDBDB;
  font-size: 14px;
  color: #4B4B4B;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
}
header-template5 .banner .dropdown-content a:hover {
  background-color: #f1f1f1;
}
header-template5 .banner .dropdown-content a:first-child {
  border-top: none;
}
header-template5 .banner .dropdown-content .avatar {
  margin: 0;
  margin-right: 10px;
  padding: 0;
  max-width: 60px;
  max-height: 60px;
}
header-template5 .banner .dropdown-content .avatar img {
  width: 100%;
  height: 100%;
}
header-template5 .banner .dropdown-content .user-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header-template5 .banner .dropdown-content .user-info .role {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}
header-template5 .banner .dropdown-content .user-info .btn-edit-info {
  background-color: #946711;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
}
header-template5 .banner .dropdown:hover .dropdown-content {
  display: block;
}
header-template5 .banner .dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
header-template5 .user {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0;
  justify-content: flex-end;
}
header-template5 .user .change-btn {
  margin: 0 10px;
  cursor: pointer;
}
header-template5 .user .avatar {
  margin: 0 10px;
  cursor: pointer;
}
header-template5 .user .avatar img {
  width: 3rem;
  height: 3rem;
}
header-template5 .user .change-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  min-width: 3rem;
  height: 3rem;
}
header-template5 .user .change-btn img {
  width: 1.5rem;
  height: 1.5rem;
}
header-template5 .pointer {
  cursor: pointer;
}
header-template5 .open-menu {
  display: block !important;
}
header-template5 .close-menu {
  display: none !important;
}
header-template5 .login-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 44px;
  background-color: #D6A022;
  border-radius: 4px;
  color: white;
  width: 146px;
  border: none;
}
header-template5 .edit .required,
header-template5 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
header-template5 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
header-template5 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
header-template5 .btn-save {
  color: white;
  background-color: #0b68a4;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}
header-template5 kendo-dialog .btn-save {
  color: white;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
}
header-template5 .cskh {
  display: flex;
  flex-direction: column;
  height: 60px !important;
  width: 199px;
  background: #FFFFFF;
  border: 1px solid #027DC3;
  border-radius: 8px;
}
header-template5 .phone-box {
  padding: 0 !important;
  width: fit-content;
}
header-template5 .phone-icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-top: 17px;
  margin-left: 18px;
}
header-template5 .phone-top {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #000000;
  padding-top: 8px;
}
header-template5 .phone-bottom {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #08659A;
  padding-top: 4px;
}
header-template5 .home-icon-blue {
  width: 20px;
  height: 17px;
}
header-template5 .home-icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
header-template5 .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
header-template5 .mat-form-field-infix {
  width: 40px;
  padding: 10px 0 !important;
  border-top: none !important;
}
header-template5 .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}
header-template5 .lang-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #006CB5;
}
header-template5 .icon-search {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
header-template5 .search-box {
  height: 100%;
  justify-content: center;
  display: flex;
  /* justify-items: center; */
  flex-direction: column;
}
header-template5 .search-box-dropdown {
  position: absolute;
  top: 50px;
  right: 5px;
}
header-template5 .padding-0 {
  padding: 0px;
}
header-template5 .background-img {
  width: 100%;
  object-fit: contain;
}
header-template5 .display-for-background {
  display: block;
}
header-template5 .display-for-navbar {
  display: none;
}
@media screen and (max-width: 576px) {
  header-template5 .desktop,
header-template5 .desktop-icon {
    display: none !important;
  }
  header-template5 .nav-link {
    color: #006CB5 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    font-weight: bold;
  }
  header-template5 .img-logo {
    width: 12%;
    height: auto;
    object-fit: contain;
  }
  header-template5 .mobile {
    display: block !important;
  }
  header-template5 .display-for-navbar {
    display: flex;
    padding: 43px 15px 20px 15px;
    justify-content: flex-start;
  }
  header-template5 .display-for-background {
    display: none;
  }
  header-template5 nav ul {
    width: auto;
  }
  header-template5 .nav-menu-box {
    height: 1px !important;
  }
  header-template5 .nav-top {
    border-bottom: 1px solid #D6D6D6;
  }
  header-template5 .nav-top .contact {
    margin: auto;
    background-color: white;
    position: relative;
    padding-top: 1px;
  }
  header-template5 .nav-top .contact .search {
    margin: auto 0;
  }
  header-template5 .nav-top .contact .language .mat-form-field {
    width: 65%;
  }
  header-template5 .banner {
    width: 100% !important;
    min-height: auto;
  }
  header-template5 .banner .img1 {
    height: 50px;
    top: 6px;
    left: 50%;
    position: relative;
  }
  header-template5 .banner .logo-container {
    display: block;
  }
  header-template5 .banner .name {
    padding: 0;
    margin: 0 auto;
  }
  header-template5 .banner .name hgroup {
    text-align: center;
  }
  header-template5 .banner .user {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 10px;
  }
  header-template5 .nav-menu {
    font-size: 95% !important;
  }
  header-template5 .nav-menu .dropdown,
header-template5 .nav-menu .item-link {
    display: block;
    text-align: left;
    width: 100% !important;
    height: 100% !important;
  }
  header-template5 .nav-menu .dropdown-submenu {
    border-top: 1px solid #4569cf;
    width: 100%;
  }
  header-template5 .nav-menu .dropdown {
    padding-left: 0;
    height: auto;
    width: 100%;
    z-index: 9989;
    height: auto !important;
    padding: 0;
    font-weight: bold;
  }
  header-template5 .nav-menu .dropdown + .dropdown {
    border-top: 1px solid #4569cf;
  }
  header-template5 .nav-menu .dropdown-submenu > .dropdown-menu {
    left: 0px;
    margin-top: 0;
  }
  header-template5 .nav-menu .dropdown:hover {
    background-color: var(--main-color);
  }
  header-template5 .nav-menu .dropdown .dropdown-menu {
    z-index: 9990;
    margin-top: 0px;
  }
  header-template5 .nav-menu .dropdown-menu .dropdown-submenu {
    z-index: 9991;
    top: 10px !important;
  }
  header-template5 .nav-menu .dropdown-menu {
    display: none;
    width: 100% !important;
    border-top: 1px solid #4569cf;
  }
  header-template5 .nav-menu .button-expand {
    color: white;
    margin-top: 5px;
    position: absolute;
    right: 0;
    font-size: 17px;
    font-weight: normal;
    top: 8px;
    width: auto;
  }
  header-template5 .nav-menu .dropdown-item:hover {
    background-color: var(--main-color);
  }
  header-template5 .nav-menu .show {
    display: block !important;
  }
  header-template5 .nav-menu .dropdown-menu,
header-template5 .nav-menu .dropdown-submenu {
    position: relative !important;
    background-color: var(--main-color);
    color: white;
    border: none;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  header-template5 .nav-menu .menu-name {
    color: #006CB5;
  }
  header-template5 .pading-0 {
    padding: 10px 0 !important;
  }
  header-template5 .pading-1 {
    padding: 10px 0 !important;
    padding-left: 15px !important;
  }
  header-template5 .pading-2 {
    padding: 10px 0 !important;
    padding-left: 25px !important;
  }
  header-template5 .pading-3 {
    padding: 10px 0 !important;
    padding-left: 35px !important;
  }
  header-template5 .pading-4 {
    padding: 10px 0 !important;
    padding-left: 45px !important;
  }
  header-template5 .dropdown-item {
    color: #006CB5;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    padding: 0.5rem 0.7rem !important;
    font-weight: bold;
    white-space: normal;
  }
  header-template5 .home-icon {
    z-index: 9980 !important;
    font-size: 25px;
    margin-top: 0 !important;
  }
  header-template5 .menu-icon {
    font-size: 30px;
    margin-left: 4px;
  }
  header-template5 .menu-icon-box {
    display: inline-block !important;
    position: absolute;
    margin-top: 1px !important;
    width: 40px;
    height: 30px;
    z-index: 999 !important;
    border-radius: 5px;
    color: #8a8a8a;
    top: 7px;
    left: 15px;
  }
  header-template5 .close-button-box {
    text-align: end;
    padding-top: 10px;
  }
  header-template5 .close-button {
    color: #ff8659;
    font-weight: bold;
  }
  header-template5 .navbar-display {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0px;
    margin-left: 0%;
    width: 65%;
    background: var(--main-color);
    height: 4000px !important;
    position: fixed;
    z-index: 998 !important;
    padding: 0px 15px;
    box-shadow: -5px 5px 5px #878794;
  }
  header-template5 .search-cover-shadow,
header-template5 .navbar-cover-shadow {
    width: 100%;
    top: 0;
    background: var(--main-color);
    height: 4000px;
    position: fixed;
    z-index: 997 !important;
    background-color: #969494;
    opacity: 0.4;
  }
  header-template5 .search-cover-shadow {
    margin-top: 145px;
    background-color: #363636;
    opacity: 0.5;
  }
  header-template5 .navbar-hidden {
    display: none;
  }
  header-template5 #navbardropdownMenuLink {
    text-align: left !important;
  }
  header-template5 .login-btn {
    position: relative;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100vw - 20px) !important;
  }
  header-template5 .left-block {
    width: 100%;
    position: relative !important;
  }
}
header-template5 .language {
  display: flex;
  align-items: center;
}
@media (min-width: 1000px) and (max-width: 1440px) {
  header-template5 .item-link {
    padding: 5px 2px !important;
  }
  header-template5 .dropdown-item {
    font-size: 9px !important;
  }
}
@media (min-width: 700px) and (max-width: 1540px) {
  header-template5 .item-link {
    padding: 10px 2px !important;
  }
  header-template5 .dropdown-item {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 575px) {
  header-template5 .desktop {
    display: block !important;
  }
  header-template5 .mobile {
    display: none !important;
  }
  header-template5 .top-right-menu {
    display: inline !important;
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 15px;
  }
  header-template5 .dropdown-menu:hover .dropdown-submenu {
    display: block;
  }
}

.news-template-22 .row, .news-template-22-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-22 .padding, .news-template-22-without-time .padding {
  padding: 0%;
}
.news-template-22 .k-dialog, .news-template-22-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-22 .post-title, .news-template-22-without-time .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-22 .title-text, .news-template-22-without-time .title-text {
  margin-bottom: 0 !important;
}
.news-template-22 .post-more, .news-template-22-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-22 .post-more a, .news-template-22-without-time .post-more a {
  color: #d6a022;
}
.news-template-22 .pointer, .news-template-22-without-time .pointer {
  cursor: pointer;
}
.news-template-22 .icon-clock, .news-template-22-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-22 .title, .news-template-22-without-time .title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
  color: #2B2B2B;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.news-template-22 .title-template-type-2, .news-template-22-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-22 .post-image, .news-template-22 .post-image-radius, .news-template-22-without-time .post-image, .news-template-22-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-22 .post-image-radius, .news-template-22-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-22 .post-image-default, .news-template-22-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-22 .post-image-org, .news-template-22-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-22 .time-clock, .news-template-22-without-time .time-clock {
  display: flex;
}
.news-template-22 .time-text, .news-template-22-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-22 .main-item, .news-template-22-without-time .main-item {
  display: table;
}
.news-template-22 .vertical-dot, .news-template-22-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-22 .main-item:hover .vertical-dot, .news-template-22-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-22 .d-flex, .news-template-22-without-time .d-flex {
  visibility: visible !important;
}
.news-template-22 .menu, .news-template-22-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-22 .menu .menu-row,
.news-template-22 .menu .menu-row-current,
.news-template-22 .menu .menu-row-current-border, .news-template-22-without-time .menu .menu-row,
.news-template-22-without-time .menu .menu-row-current,
.news-template-22-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-22 .menu .menu-row .menu-item-1,
.news-template-22 .menu .menu-row .menu-item-2,
.news-template-22 .menu .menu-row-current .menu-item-1,
.news-template-22 .menu .menu-row-current .menu-item-2,
.news-template-22 .menu .menu-row-current-border .menu-item-1,
.news-template-22 .menu .menu-row-current-border .menu-item-2, .news-template-22-without-time .menu .menu-row .menu-item-1,
.news-template-22-without-time .menu .menu-row .menu-item-2,
.news-template-22-without-time .menu .menu-row-current .menu-item-1,
.news-template-22-without-time .menu .menu-row-current .menu-item-2,
.news-template-22-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-22-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-22 .menu .menu-row-current,
.news-template-22 .menu .menu-row-current-border, .news-template-22-without-time .menu .menu-row-current,
.news-template-22-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-22 .menu .menu-item-3, .news-template-22-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-22 .img-figure,
.news-template-22 .img-figure-no-img,
.news-template-22 .img-figure-template-type-2, .news-template-22-without-time .img-figure,
.news-template-22-without-time .img-figure-no-img,
.news-template-22-without-time .img-figure-template-type-2 {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
.news-template-22 .post-list, .news-template-22-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news-template-22 .post-list .k-pager-info, .news-template-22 .post-list .k-pager-sizes, .news-template-22-without-time .post-list .k-pager-info, .news-template-22-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-22 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-22-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-22 .post-list .k-pager .k-state-selected, .news-template-22-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-22 .post-list .top-part, .news-template-22-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-22 .uppercase, .news-template-22-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-22 .top-new-item, .news-template-22-without-time .top-new-item {
  margin: 15px 0px 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-22 .top-new-item .title:hover, .news-template-22-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-22 .close-div, .news-template-22-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-22 .news-template-22-dialog, .news-template-22-without-time .news-template-22-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-22 .news-template-22-dialog .close-icon-div, .news-template-22-without-time .news-template-22-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-22 .news-template-22-dialog .close-icon, .news-template-22-without-time .news-template-22-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-22 .news-template-22-dialog .title-info-req, .news-template-22-without-time .news-template-22-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-22 .input-label, .news-template-22-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-22 .btn-delete, .news-template-22-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-22 .btn-cancel, .news-template-22-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-22 .dialog-width, .news-template-22-without-time .dialog-width {
  max-width: 400px;
}
@media (max-width: 770px) {
  .news-template-22 .post-list, .news-template-22-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .news-template-22 .post-image, .news-template-22-without-time .post-image {
    height: auto !important;
  }
  .news-template-22 .top-new-item, .news-template-22-without-time .top-new-item {
    margin: 0px !important;
  }
}
@media (min-width: 770px) {
  .news-template-22 .col-md-4, .news-template-22-without-time .col-md-4 {
    width: 32%;
  }
}

.news-template-22-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-22-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-22-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-22-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-22-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-22-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-22-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-22-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-22-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-22-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-22-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-22-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-22-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-22-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-22-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-22-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-22-dialog .float-right {
  float: right;
}

.news-template-22-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-22-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-22-dialog .control {
  margin-bottom: 22px;
}

.news-template-22-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-22-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-22-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-22-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-22-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-22-dialog .padding-col {
  padding: 7px 15px 0;
}

footer.ver4 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  background-color: #FFFFFF;
}
footer.ver4 .mobile {
  display: none;
}
footer.ver4 .desktop {
  display: block;
}
footer.ver4 .pointer {
  cursor: pointer;
}
footer.ver4 .footer3 {
  color: black;
}
footer.ver4 .footer3 .row {
  display: flex;
  flex-direction: row;
}
footer.ver4 .footer3 .title span {
  display: inline !important;
  width: fit-content;
  font-style: normal;
  padding-bottom: 10px;
}
footer.ver4 .footer3 .textapplink {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #848585;
  margin: 15px 0px 15px 0px;
}
footer.ver4 .footer3 .barcodeholder {
  border: 1px solid #000000;
  box-sizing: border-box;
  height: 100px;
  width: 100px;
}
footer.ver4 .footer3 .googleplay {
  padding-left: 0px;
  margin-top: 10px;
}
footer.ver4 .footer3 .appstore {
  margin-top: 5px;
  padding-left: 0px;
}
footer.ver4 .footer3 .applink-holder {
  display: flex;
  justify-content: end;
  flex-direction: column;
  float: right;
  margin-bottom: 15px;
}
footer.ver4 .footer3 .barcodes {
  padding: 7px;
  width: 100%;
}
footer.ver4 .footer3 .image-block {
  justify-content: left;
  display: flex;
}
footer.ver4 .footer3 .info {
  color: #131313;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
footer.ver4 .footer3 .info .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}
footer.ver4 .footer3 .info ul {
  list-style-type: none;
  margin-bottom: 15px;
}
footer.ver4 .footer3 .info ul i {
  font-size: 1rem;
}
footer.ver4 .footer3 .description {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #848585;
}
footer.ver4 .footer3 ul {
  padding: 0;
  margin-bottom: 0;
}
footer.ver4 .footer3 .info {
  padding-right: 1em;
}
footer.ver4 .footer3 .menu ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 9em;
  list-style-type: disc;
  margin-left: 10px;
}
footer.ver4 .footer3 .menu li {
  width: 50%;
}
footer.ver4 .footer3 li {
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}
footer.ver4 .footer3 li:hover .btn-func,
footer.ver4 .footer3 .info:hover .btn-func {
  visibility: visible;
}
footer.ver4 .footer3 li a {
  color: black;
  text-decoration: none;
}
footer.ver4 .footer3 .btn-func {
  visibility: hidden;
}
footer.ver4 .footer3 .btn-custom {
  width: fit-content;
  background: initial;
  color: black;
}
footer.ver4 .footer3 .btn-custom button {
  background-color: inherit;
  width: fit-content;
  color: black;
  border: none;
}
footer.ver4 .footer3 .btn-custom .k-button {
  padding: 0 !important;
}
footer.ver4 .footer3 .k-popup .k-list {
  height: auto !important;
  width: fit-content;
  padding: 0;
  margin: 0;
}
footer.ver4 .footer3 .k-popup .k-item {
  width: 100%;
  padding: 5px;
}
footer.ver4 .footer3 .handle:hover {
  cursor: pointer;
}
footer.ver4 .footer3 .btn-add {
  background-color: inherit;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: 1px solid white;
  border-radius: 2px;
  height: 2.5em;
}
footer.ver4 .footer3 .btn-add:hover {
  background-color: #074973;
}
footer.ver4 .footer3 .menu,
footer.ver4 .footer3 .contact {
  height: 80px;
  border-left: 1px solid #FFFFFF;
  padding-left: 2em;
}
footer.ver4 .footer3 .contact .title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
footer.ver4 .footer3 .contact .social-icon {
  margin-top: 15px;
}
footer.ver4 .footer3 .contact .social-icon a {
  margin: 0 12px;
}
footer.ver4 .footer3 .contact .social-icon a img {
  height: 20px;
  width: 20px;
}
footer.ver4 .footer3 .contact .social-icon i {
  color: black;
}
footer.ver4 .copyright {
  background-color: #08659A;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 0px;
}
footer.ver4 .icon-footer {
  max-height: 107px;
  margin: 30px 0px 20px 0px;
  object-fit: contain;
  width: 100%;
  height: auto;
}
footer.ver4 .edit .required,
footer.ver4 .edit .err-text {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
footer.ver4 .xbutton-dialog {
  padding: 0;
  color: inherit;
  background: none;
  border-width: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 1px;
  color: black;
  font-size: 20px;
}
footer.ver4 .title-info-req {
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 16px;
}
footer.ver4 .btn-save {
  color: black;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  margin-top: 10px;
  margin-right: 16px;
}
footer.ver4 .break-line {
  border-bottom: 1px solid #D2D2D2;
  margin-bottom: 20px;
  margin-top: 20px;
}
footer.ver4 .margin-box {
  margin-bottom: 30px;
}
footer.ver4 .menu-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-bottom: 20px;
  cursor: pointer;
}
footer.ver4 .childmenu-title {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #454545;
  cursor: pointer;
}
footer.ver4 .menu-width {
  margin: auto;
  width: calc(100% - 250px);
  min-width: 80%;
  max-width: 1550px;
}
footer.ver4 .footer-background {
  border: 1px solid #006CB5;
  width: calc(100% - 1px);
  margin-left: 1px;
}
footer.ver4 .margin-background {
  margin: auto;
}
footer.ver4 .title-info {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #006CB5;
  margin: 20px 0px 20px 0px;
}
footer.ver4 .operator-info {
  display: flex;
}
footer.ver4 .icon-copyright {
  height: 27px;
}
footer.ver4 .icon-copyrightholder {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
}
footer.ver4 .logofooterholder {
  justify-content: right;
  display: flex;
}
footer.ver4 .logofooter {
  width: 30px;
  margin-left: 2px;
  margin-right: 1px;
  position: absolute;
  right: 0;
  height: 100%;
}
footer.ver4 .footertext {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
footer.ver4 .online-box {
  margin: 20px 10px 10px 10px;
}
footer.ver4 .copyright-row {
  display: flex;
  flex-wrap: nowrap;
}
footer.ver4 .bold-title {
  font-weight: 700;
}
footer.ver4 .title-text {
  font-weight: 700;
  font-size: 14px !important;
  color: #848585;
}
footer.ver4 .link {
  display: flex;
}
footer.ver4 .download-box {
  margin-left: 10px;
}
footer.ver4 .c-text {
  color: #FFF;
  font-size: 1.3rem;
  letter-spacing: -0.03375rem;
}
@media screen and (max-width: 770px) {
  footer.ver4 .item-box {
    border-bottom: black dashed 0.7px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  footer.ver4 .menu-title {
    margin-bottom: 5px;
  }
  footer.ver4 .margin-box {
    margin-bottom: 0px !important;
  }
}
footer.ver4 .access-title {
  color: #848585;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.0275rem;
}
footer.ver4 .margin-title {
  margin-top: 10px;
}
footer.ver4 .number-box {
  color: #FFF;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.0275rem;
  background: #006CB5;
  width: 1.23rem;
  height: 1.83rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  margin-right: 5px;
}
footer.ver4 .number-arr {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1024px) {
  footer.ver4 .footer3 .info,
footer.ver4 .footer3 .menu,
footer.ver4 .footer3 .contact {
    border-bottom: 1px solid white;
  }
  footer.ver4 .footer3 .menu {
    height: auto;
  }
  footer.ver4 .footer3 .btn-func {
    visibility: visible;
  }
  footer.ver4 .footer3 .operator-info {
    margin-left: 0px;
  }
  footer.ver4 .footer3 .applink-holder {
    float: initial;
  }
}
@media screen and (max-width: 576px) {
  footer.ver4 {
    width: 100% !important;
  }
  footer.ver4 .menu-width {
    width: calc(100%);
  }
  footer.ver4 .mobile {
    display: block;
  }
  footer.ver4 .desktop {
    display: none;
  }
  footer.ver4 .btn-add {
    width: 25% !important;
  }
  footer.ver4 .footer3 .info,
footer.ver4 .footer3 .menu,
footer.ver4 .footer3 .contact {
    border-bottom: 1px solid white;
    margin: 5px 0 0;
  }
  footer.ver4 .footer3 .menu {
    height: auto;
  }
  footer.ver4 .footer3 .applink-holder {
    padding-top: 0px;
  }
}
@media screen and (max-width: 800px) {
  footer.ver4 {
    width: 100% !important;
  }
  footer.ver4 .add-menu.x-dialog {
    width: 95% !important;
    height: 95% !important;
  }

  .applink-holder {
    padding-top: 0px !important;
  }

  .textapplink {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }

  .barcodeholder {
    width: 80px;
  }

  .appimg {
    width: 100px;
  }

  .image-block {
    justify-content: center !important;
  }

  footer.ver4 .icon-footer {
    width: auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1444px) {
  footer.ver4 .link {
    flex-direction: column;
  }

  footer.ver4 .download-box {
    margin-left: 0px !important;
  }
}
.news-template-23 {
  width: 100%;
  background-color: #ffffff !important;
  height: 100%;
}
.news-template-23 .container-fluid {
  align-items: baseline !important;
}
.news-template-23 .row {
  --bs-gutter-x: 0;
}
.news-template-23 .scroll-area {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.news-template-23 .scroll-area::-webkit-scrollbar {
  display: none;
}
.news-template-23 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-23 .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-23 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-23 .post-more a {
  color: #eb2e35;
}
.news-template-23 .pointer {
  cursor: pointer;
}
.news-template-23 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-23 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-23 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-23 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-23 .time-clock {
  display: flex;
}
.news-template-23 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-23 .main-item {
  display: table;
}
.news-template-23 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-23 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 3px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-23 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-23 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-23 .d-flex {
  visibility: visible !important;
}
.news-template-23 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-23 .menu .menu-row,
.news-template-23 .menu .menu-row-current,
.news-template-23 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-23 .menu .menu-row .menu-item-1,
.news-template-23 .menu .menu-row .menu-item-2,
.news-template-23 .menu .menu-row-current .menu-item-1,
.news-template-23 .menu .menu-row-current .menu-item-2,
.news-template-23 .menu .menu-row-current-border .menu-item-1,
.news-template-23 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-23 .menu .menu-row-current,
.news-template-23 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-23 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-23 .img-figure,
.news-template-23 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-23 .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-23 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-23 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-23 .news-template-23-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-23 .news-template-23-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-23 .news-template-23-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-23 .news-template-23-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-23 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-23 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-23 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-23 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-23 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-23 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-23 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-23 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-23 .nested-menu .list-group-items {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  color: #006CB5;
  padding: 0px 0px 0px 20px;
  border: none;
}
.news-template-23 .nested-menu .list-submenu {
  padding-left: 10px;
  overflow: hidden;
  overflow-y: auto;
}
.news-template-23 .nested-menu .list-submenu :last-child {
  border: none;
}
.news-template-23 .nested-menu .submenu {
  text-decoration: none;
  cursor: pointer;
  list-style-type: none;
  height: auto;
  color: #4a4a4a;
  font-weight: 500;
  display: block;
}
.news-template-23 .nested-menu .submenu .sub-item {
  display: flex;
  font-size: 14px;
  border-bottom: 1px dashed #848484;
}
.news-template-23 .nested-menu .submenu:first-child {
  padding-top: 0px;
}
.news-template-23 .nested-menu .img-first {
  width: 100%;
  object-fit: cover;
}
.news-template-23 .nested-menu .sub-item {
  display: flex;
  font-size: 14px;
}
.news-template-23 .nested-menu .sub-number {
  font-size: 48px;
  width: 50px;
  padding-right: 10px;
  margin-left: 15px;
}
.news-template-23 .water-icon {
  width: 20px;
  height: 20px;
  margin-top: 18px;
  margin-left: 17px;
}
.news-template-23 .list-title {
  display: flex;
}
.news-template-23 .item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #2B2B2B;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 20px;
}
.news-template-23 .item-title-first {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2B2B2B;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-template-23 .item-keyword {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #027DC3;
}
.news-template-23 .item-time {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A8A8A8;
  margin-left: 15px;
}
.news-template-23 .item-info {
  margin-top: 3px;
}
.news-template-23 .sidebar {
  border-left: 0.7px solid #D8D8D8;
  margin-left: 10px;
  margin-top: 30px;
}
.news-template-23 .desktop-width {
  width: 100vw;
  left: calc(-50vw + 50%);
  background: #F9F9F9;
}
.news-template-23 .content-width-media {
  width: calc(75% - 220px);
  margin: auto;
}
.news-template-23 ::-webkit-scrollbar {
  width: 3px;
}
.news-template-23 ::-webkit-scrollbar-thumb {
  background: #676262;
}
.news-template-23 ::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
@media (max-width: 768px) {
  .news-template-23 .sidebar {
    border-left: none !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .news-template-23 .list-group-items {
    padding: 0px !important;
  }
  .news-template-23 .list-submenu {
    padding-left: 0px !important;
    height: auto !important;
  }
  .news-template-23 .scroll-area {
    background: #F9F9F9;
  }
  .news-template-23 .img-first {
    height: auto !important;
  }
}

.news-template-23-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-23-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-23-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-23-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-23-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-23-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-23-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-23-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-23-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-23-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-23-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-23-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-23-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-23-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-23-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-23-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-23-dialog .float-right {
  float: right;
}

.news-template-23-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-23-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-23-dialog .control {
  margin-bottom: 22px;
}

.news-template-23-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-23-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-23-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-23-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-23-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-23-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-24 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-24 .row {
  --bs-gutter-x: 0;
  display: flex;
  flex-wrap: wrap;
}
.news-template-24 .padding {
  padding: 0%;
}
.news-template-24 .scroll-area {
  max-height: 1400px;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.news-template-24 .scroll-area::-webkit-scrollbar {
  display: none;
}
.news-template-24 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-24 .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.news-template-24 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-24 .post-more a {
  color: #eb2e35;
}
.news-template-24 .pointer {
  cursor: pointer;
}
.news-template-24 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-24 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-24 .post-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-24 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-24 .time-clock {
  display: flex;
}
.news-template-24 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-24 .main-item {
  display: flex;
  background: white;
  flex-direction: column;
}
.news-template-24 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-24 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 3px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-24 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-24 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-24 .d-flex {
  visibility: visible !important;
}
.news-template-24 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-24 .menu .menu-row,
.news-template-24 .menu .menu-row-current,
.news-template-24 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-24 .menu .menu-row .menu-item-1,
.news-template-24 .menu .menu-row .menu-item-2,
.news-template-24 .menu .menu-row-current .menu-item-1,
.news-template-24 .menu .menu-row-current .menu-item-2,
.news-template-24 .menu .menu-row-current-border .menu-item-1,
.news-template-24 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-24 .menu .menu-row-current,
.news-template-24 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-24 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-24 .img-figure,
.news-template-24 .img-figure-no-img .img-figure-template-type-2 {
  height: 173px;
  width: 218px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 30px;
}
.news-template-24 .img-figure-template-type-2 {
  height: 153px;
}
.news-template-24 .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-24 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-24 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-24 .news-template-24-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-24 .news-template-24-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-24 .news-template-24-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-24 .news-template-24-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-24 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-24 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-24 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-24 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-24 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-24 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-24 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.news-template-24 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-24 .title-holder {
  margin-bottom: 10px;
}
.news-template-24 .list-title {
  width: 153px;
  height: 43px;
  background-color: #006CB5;
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 78% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
  z-index: 1;
  position: relative;
}
.news-template-24 .list-group-item {
  z-index: 1;
  margin-top: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 46px;
  color: #FFFFFF;
  padding: 0px 0px 0px 10px;
  border: none;
  background-color: #006CB5;
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 80% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
}
.news-template-24 .title-decorate {
  content: "";
  margin-top: -30px;
  margin-left: 5px;
  width: 164px;
  height: 29px;
  background: rgba(8, 174, 239, 0.49);
  clip-path: polygon(0 0%, 0% 0%, 0% 0, 86% 0%, 100% 90%, 0% 90%, 0% 100%, 0% 0%, 0% 0%);
}
.news-template-24 .nested-menu .list-submenu {
  padding-right: 15px;
  background-color: #FFFFFF;
}
.news-template-24 .nested-menu .list-submenu :last-child {
  border: none;
}
.news-template-24 .nested-menu .submenu {
  cursor: pointer;
  list-style-type: none;
  height: auto;
  color: #4a4a4a;
  font-weight: 500;
  display: block;
}
.news-template-24 .nested-menu .submenu:first-child {
  padding-top: 0px;
  padding-bottom: 0px;
}
.news-template-24 .nested-menu .item-title-icon {
  display: flex;
}
.news-template-24 .water-icon {
  width: 100%;
  height: 100%;
}
.news-template-24 .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-24 .item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #353535;
  margin-top: 16px;
}
.news-template-24 .item-title-news {
  margin-left: 15px;
  width: 38px;
  height: 17px;
  background-color: #E81818;
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
  color: #FFFFFF;
  display: inline-block;
}
.news-template-24 .item-keyword {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #027DC3;
}
.news-template-24 .item-time {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #A8A8A8;
  margin-left: 15px;
}
.news-template-24 .item-summary {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 12px;
  color: #505050;
}
.news-template-24 .item-info {
  margin-bottom: 12px;
  margin-top: 20px;
  margin-left: 0px;
  flex: 1 1 auto;
}
.news-template-24 .info-post {
  flex-direction: row;
}
@media (max-width: 600px) {
  .news-template-24 .info-post {
    flex-direction: column;
  }
}

.news-template-24-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-24-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-24-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-24-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-24-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-24-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-24-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-24-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-24-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-24-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-24-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-24-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-24-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-24-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-24-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-24-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-24-dialog .float-right {
  float: right;
}

.news-template-24-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-24-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-24-dialog .control {
  margin-bottom: 22px;
}

.news-template-24-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-24-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-24-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-24-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-24-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-24-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-25, .news-template-25-without-time {
  width: 100%;
}
.news-template-25 .row, .news-template-25-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-25 .dot, .news-template-25-without-time .dot {
  height: 10px;
  width: 10px;
  background-color: #006CB5;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}
.news-template-25 .line, .news-template-25-without-time .line {
  border-bottom: 1px dashed #A6A6A6;
}
.news-template-25 .defaults, .news-template-25-without-time .defaults {
  display: flex;
}
.news-template-25 .k-dialog, .news-template-25-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-25 .post-title, .news-template-25-without-time .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 139%);
  margin-bottom: 10px;
}
.news-template-25 .title-text, .news-template-25-without-time .title-text {
  font-size: 16px;
  line-height: 46px;
  font-weight: 700;
  color: #ffffff;
  font-style: normal;
  margin-left: 22px;
  margin-bottom: 0 !important;
}
.news-template-25 .post-more, .news-template-25-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-25 .post-more a, .news-template-25-without-time .post-more a {
  color: #d6a022;
}
.news-template-25 .pointer, .news-template-25-without-time .pointer {
  cursor: pointer;
}
.news-template-25 .icon-clock, .news-template-25-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-25 .title-summary-img, .news-template-25-without-time .title-summary-img {
  border-right: 1px solid #E3E3E3;
}
@media (max-width: 575px) {
  .news-template-25 .title-summary-img, .news-template-25-without-time .title-summary-img {
    border-right: 0px solid #E3E3E3;
    border-bottom: 1px solid #E3E3E3;
  }
  .news-template-25 .post-list, .news-template-25-without-time .post-list {
    padding: 0;
  }
  .news-template-25 .container-fluid, .news-template-25-without-time .container-fluid {
    padding: 0;
  }
}
@media (max-width: 860px) {
  .news-template-25 .title-summary-img, .news-template-25-without-time .title-summary-img {
    border-right: 0px solid #E3E3E3;
  }
}
@media (max-width: 860px) and (min-width: 575px) {
  .news-template-25 .title-summary-img, .news-template-25-without-time .title-summary-img {
    border-right: 1px solid #E3E3E3;
  }
}
.news-template-25 .title, .news-template-25-without-time .title {
  line-height: 27px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #292621;
  margin-bottom: 8px;
  margin-right: 20px;
}
.news-template-25 .title-second, .news-template-25-without-time .title-second {
  line-height: 27px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #292621;
  margin-bottom: 8px;
  margin-right: 20px;
  margin-left: 30px;
}
.news-template-25 .title-a, .news-template-25-without-time .title-a {
  text-decoration: none;
}
.news-template-25 .title-template-type-2, .news-template-25-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-25 .post-image, .news-template-25 .post-image-radius, .news-template-25-without-time .post-image, .news-template-25-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-25 .post-image-radius, .news-template-25-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-25 .post-image-default, .news-template-25-without-time .post-image-default {
  height: 100%;
  width: 100%;
  padding-right: 20px;
}
.news-template-25 .post-image-org, .news-template-25-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-25 .time-clock, .news-template-25-without-time .time-clock {
  display: flex;
}
.news-template-25 .time-text, .news-template-25-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-25 .main-item, .news-template-25-without-time .main-item {
  display: table;
}
.news-template-25 .vertical-dot, .news-template-25-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-25 .main-item:hover .vertical-dot, .news-template-25-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-25 .d-flex, .news-template-25-without-time .d-flex {
  visibility: visible !important;
}
.news-template-25 .three-dot-area, .news-template-25-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-25 .menu, .news-template-25-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-25 .menu .menu-row,
.news-template-25 .menu .menu-row-current,
.news-template-25 .menu .menu-row-current-border, .news-template-25-without-time .menu .menu-row,
.news-template-25-without-time .menu .menu-row-current,
.news-template-25-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-25 .menu .menu-row .menu-item-1,
.news-template-25 .menu .menu-row .menu-item-2,
.news-template-25 .menu .menu-row-current .menu-item-1,
.news-template-25 .menu .menu-row-current .menu-item-2,
.news-template-25 .menu .menu-row-current-border .menu-item-1,
.news-template-25 .menu .menu-row-current-border .menu-item-2, .news-template-25-without-time .menu .menu-row .menu-item-1,
.news-template-25-without-time .menu .menu-row .menu-item-2,
.news-template-25-without-time .menu .menu-row-current .menu-item-1,
.news-template-25-without-time .menu .menu-row-current .menu-item-2,
.news-template-25-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-25-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-25 .menu .menu-row-current,
.news-template-25 .menu .menu-row-current-border, .news-template-25-without-time .menu .menu-row-current,
.news-template-25-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-25 .menu .menu-item-3, .news-template-25-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-25 .img-figure,
.news-template-25 .img-figure-no-img,
.news-template-25 .img-figure-template-type-2, .news-template-25-without-time .img-figure,
.news-template-25-without-time .img-figure-no-img,
.news-template-25-without-time .img-figure-template-type-2 {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-25 .img-figure-template-type-2, .news-template-25-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-25 .img-figure-2, .news-template-25-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-25 .post-list, .news-template-25-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: 17px;
}
.news-template-25 .post-list .k-pager-info, .news-template-25 .post-list .k-pager-sizes, .news-template-25-without-time .post-list .k-pager-info, .news-template-25-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-25 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-25-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-25 .post-list .k-pager .k-state-selected, .news-template-25-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-25 .post-list .img-first, .news-template-25-without-time .post-list .img-first {
  width: 264px;
  height: 173px;
  margin-right: 23px;
  margin-top: 15px;
}
.news-template-25 .post-list .img-firsts, .news-template-25-without-time .post-list .img-firsts {
  width: 264px;
  height: 173px;
}
.news-template-25 .post-list .top-part, .news-template-25-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-25 .post-list .top-part .Summary, .news-template-25-without-time .post-list .top-part .Summary {
  line-height: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: #292621;
  text-decoration: none;
  margin-bottom: 0px;
  margin-right: 20px;
}
.news-template-25 .uppercase, .news-template-25-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-25 .top-new-item, .news-template-25-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-25 .top-new-item .title:hover, .news-template-25-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-25 .close-div, .news-template-25-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-25 .news-template-25-dialog, .news-template-25-without-time .news-template-25-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-25 .news-template-25-dialog .close-icon-div, .news-template-25-without-time .news-template-25-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-25 .news-template-25-dialog .close-icon, .news-template-25-without-time .news-template-25-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-25 .news-template-25-dialog .title-info-req, .news-template-25-without-time .news-template-25-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-25 .input-label, .news-template-25-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-25 .btn-delete, .news-template-25-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-25 .btn-cancel, .news-template-25-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-25 .dialog-width, .news-template-25-without-time .dialog-width {
  max-width: 400px;
}
.news-template-25 .secondarray, .news-template-25-without-time .secondarray {
  border-bottom: 1px solid #E3E3E3;
}

.news-template-25-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-25-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-25-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-25-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-25-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-25-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-25-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-25-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-25-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-25-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-25-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-25-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-25-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-25-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-25-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-25-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-25-dialog .float-right {
  float: right;
}

.news-template-25-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-25-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-25-dialog .control {
  margin-bottom: 22px;
}

.news-template-25-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-25-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-25-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-25-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-25-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-25-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-26,
.news-template-26-without-time {
  width: 100%;
  min-height: 504px;
  overflow: hidden !important;
}
.news-template-26 .k-tabstrip-items .k-link,
.news-template-26-without-time .k-tabstrip-items .k-link {
  padding: 0px 0px 12px 0px;
  color: #b6b6b6;
  font-weight: 700;
  line-height: 22.63px;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 50px;
}
.news-template-26 .k-tabstrip-items .k-item:not(:first-child) .k-link,
.news-template-26-without-time .k-tabstrip-items .k-item:not(:first-child) .k-link {
  padding: 0px 10px 12px 10px;
}
.news-template-26 .k-tabstrip-items .k-item.k-state-active .k-link,
.news-template-26-without-time .k-tabstrip-items .k-item.k-state-active .k-link {
  color: #004a9a;
  font-weight: 700;
  border-width: 0 0 3px !important;
  border-color: #08659a;
  border-style: solid;
}
.news-template-26 .k-tabstrip-top > .k-tabstrip-items .k-item,
.news-template-26-without-time .k-tabstrip-top > .k-tabstrip-items .k-item {
  border-width: 0px 0px 1px 0px;
}
.news-template-26 .row,
.news-template-26-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-26 .k-dialog,
.news-template-26-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-26 .dialog2 .k-dialog,
.news-template-26-without-time .dialog2 .k-dialog {
  height: 100% !important;
  width: 100% !important;
}
.news-template-26 .k-prompt-container .dialog1,
.news-template-26-without-time .k-prompt-container .dialog1 {
  padding: 16px 16px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: hidden !important;
  position: relative;
  flex: 1 1 auto;
}
.news-template-26 kendo-dialog.dialog2 .k-dialog .k-content,
.news-template-26-without-time kendo-dialog.dialog2 .k-dialog .k-content {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: hidden !important;
  position: relative;
  flex: 1 1 auto;
}
.news-template-26 .post-title,
.news-template-26-without-time .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-26 .title-text,
.news-template-26-without-time .title-text {
  margin-bottom: 47px;
  margin-top: 47px;
  line-height: 45.25px;
  font-weight: 700;
  font-size: 36px;
  color: #006cb5;
}
.news-template-26 .post-more,
.news-template-26-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-26 .post-more a,
.news-template-26-without-time .post-more a {
  color: #d6a022;
}
.news-template-26 .koverley2 .k-overlay,
.news-template-26-without-time .koverley2 .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
.news-template-26 .overlay,
.news-template-26-without-time .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
}
.news-template-26 .pointer,
.news-template-26-without-time .pointer {
  cursor: pointer;
}
.news-template-26 .video-title,
.news-template-26-without-time .video-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-template-26 .icon-clock,
.news-template-26-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-26 .title,
.news-template-26-without-time .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #292621;
  margin-bottom: 20px;
  text-decoration: none;
}
.news-template-26 .title-template-type-2,
.news-template-26-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2b2b2b;
  margin-bottom: 20px;
}
.news-template-26 .post-image,
.news-template-26 .post-image-radius,
.news-template-26-without-time .post-image,
.news-template-26-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-26 .post-image-radius,
.news-template-26-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-26 .post-image-default,
.news-template-26-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-26 .post-image-org,
.news-template-26-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-26 .time-clock,
.news-template-26-without-time .time-clock {
  display: flex;
}
.news-template-26 .time-text,
.news-template-26-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-26 .main-item,
.news-template-26-without-time .main-item {
  display: table;
}
.news-template-26 .vertical-dot,
.news-template-26-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-26 .main-item:hover .vertical-dot,
.news-template-26-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-26 .d-flex,
.news-template-26-without-time .d-flex {
  visibility: visible !important;
}
.news-template-26 .three-dot-area,
.news-template-26-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-26 .menu,
.news-template-26-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-26 .menu .menu-row,
.news-template-26 .menu .menu-row-current,
.news-template-26 .menu .menu-row-current-border,
.news-template-26-without-time .menu .menu-row,
.news-template-26-without-time .menu .menu-row-current,
.news-template-26-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-26 .menu .menu-row .menu-item-1,
.news-template-26 .menu .menu-row .menu-item-2,
.news-template-26 .menu .menu-row-current .menu-item-1,
.news-template-26 .menu .menu-row-current .menu-item-2,
.news-template-26 .menu .menu-row-current-border .menu-item-1,
.news-template-26 .menu .menu-row-current-border .menu-item-2,
.news-template-26-without-time .menu .menu-row .menu-item-1,
.news-template-26-without-time .menu .menu-row .menu-item-2,
.news-template-26-without-time .menu .menu-row-current .menu-item-1,
.news-template-26-without-time .menu .menu-row-current .menu-item-2,
.news-template-26-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-26-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-26 .menu .menu-row-current,
.news-template-26 .menu .menu-row-current-border,
.news-template-26-without-time .menu .menu-row-current,
.news-template-26-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-26 .menu .menu-item-3,
.news-template-26-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-26 .img-figure,
.news-template-26 .img-figure-no-img,
.news-template-26 .img-figure-template-type-2,
.news-template-26-without-time .img-figure,
.news-template-26-without-time .img-figure-no-img,
.news-template-26-without-time .img-figure-template-type-2 {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-26 .img-figure-template-type-2,
.news-template-26-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-26 .img-figure-2,
.news-template-26-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-26 .post-list,
.news-template-26-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-26 .post-list .k-pager-info,
.news-template-26 .post-list .k-pager-sizes,
.news-template-26-without-time .post-list .k-pager-info,
.news-template-26-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-26 .post-list .k-pager kendo-datapager-prev-buttons,
.news-template-26-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-26 .post-list .k-pager .k-state-selected,
.news-template-26-without-time .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-26 .post-list .top-part,
.news-template-26-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-26 .uppercase,
.news-template-26-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-26 .top-new-item,
.news-template-26-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-26 .top-new-item .title:hover,
.news-template-26-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-26 .dialog-opened,
.news-template-26-without-time .dialog-opened {
  position: relative;
  overflow: hidden !important;
}
.news-template-26 .close-div,
.news-template-26-without-time .close-div {
  display: flex;
}
.news-template-26 .news-template-26-dialog,
.news-template-26-without-time .news-template-26-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-26 .news-template-26-dialog .close-icon-div,
.news-template-26-without-time .news-template-26-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-26 .news-template-26-dialog .close-icon,
.news-template-26-without-time .news-template-26-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-26 .news-template-26-dialog .title-info-req,
.news-template-26-without-time .news-template-26-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-26 .input-label,
.news-template-26-without-time .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-26 .btn-delete,
.news-template-26-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-26 .btn-cancel,
.news-template-26-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-26 .dialog-width,
.news-template-26-without-time .dialog-width {
  max-width: 400px;
  max-height: 354px;
}
.news-template-26 .dialog-width-video,
.news-template-26-without-time .dialog-width-video {
  width: 1040px;
  height: 647px;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-26 .wrapper,
.news-template-26-without-time .wrapper {
  display: flex;
  margin-bottom: 100px;
}
.news-template-26 .content,
.news-template-26-without-time .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-template-26 img,
.news-template-26-without-time img {
  width: 120px;
  height: 120px;
}
.news-template-26 .k-tabstrip > .k-content.k-state-active,
.news-template-26-without-time .k-tabstrip > .k-content.k-state-active {
  margin-top: 40px;
}
.news-template-26 .k-tabstrip > .k-content,
.news-template-26-without-time .k-tabstrip > .k-content {
  padding: 0;
  border: none !important;
}
.news-template-26 .k-tabstrip-items,
.news-template-26-without-time .k-tabstrip-items {
  overflow-x: auto;
}
.news-template-26 .width-full,
.news-template-26-without-time .width-full {
  width: 100%;
  position: relative;
}
.news-template-26 iframe,
.news-template-26-without-time iframe {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .news-template-26 .dialog-width-video,
.news-template-26-without-time .dialog-width-video {
    max-width: 90%;
    max-height: 317px;
  }
  .news-template-26 .k-dialog,
.news-template-26-without-time .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-26 .close-icon-div,
.news-template-26-without-time .close-icon-div {
    position: relative;
    right: 35px !important;
    top: -30px;
  }
  .news-template-26 .k-window-content,
.news-template-26-without-time .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute;
    flex: 1 1 auto;
  }
  .news-template-26 .k-dialog,
.news-template-26-without-time .k-dialog {
    position: initial;
  }
}
@media screen and (max-width: 768px) {
  .news-template-26 .dialog-width-video,
.news-template-26-without-time .dialog-width-video {
    max-width: 90% !important;
    max-height: 317px !important;
  }
  .news-template-26 .k-dialog,
.news-template-26-without-time .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-26 .close-icon-div,
.news-template-26-without-time .close-icon-div {
    position: relative;
    float: right;
    top: -30px;
  }
  .news-template-26 .k-window-content,
.news-template-26-without-time .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute !important;
    flex: 1 1 auto;
  }
  .news-template-26 .k-dialog,
.news-template-26-without-time .k-dialog {
    position: initial !important;
  }
}
.news-template-26 .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 20px;
}

.news-template-26 .media {
  width: 100%;
  max-height: 233px;
  min-height: 233px;
  position: relative;
  margin-bottom: 0;
}

.news-template-26 .video-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .news-template-26 .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-26 .video-title {
    font-size: 16px !important;
  }
  .news-template-26 .post-more {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .news-template-26 .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-26 .video-title {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 576px) {
  .news-template-26 .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
}
.news-template-26-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-26-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-26-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-26-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-26-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-26-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-26-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-26-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-26-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-26-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-26-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-26-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-26-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-26-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-26-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-26-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-26-dialog .float-right {
  float: right;
}

.news-template-26-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-26-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-26-dialog .control {
  margin-bottom: 22px;
}

.news-template-26-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-26-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-26-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-26-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-26-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-26-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-27 {
  width: 100%;
  background-color: #ffffff !important;
  margin-bottom: 30px;
}
.news-template-27 .row {
  --bs-gutter-x: 0;
}
.news-template-27 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-27 .pd-l-10 {
  padding-left: 18px;
}
.news-template-27 .post-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 37px;
  color: #006CB5;
  position: relative;
  min-height: 40px;
  justify-content: center;
  padding: 40px 25px 43px;
  text-transform: uppercase;
}
.news-template-27 .post-title p {
  margin-bottom: 0;
  text-align: center;
}
.news-template-27 .title-blue {
  font-style: normal;
  position: relative;
  color: #006cb5;
  text-transform: uppercase;
}
.news-template-27 .sub-post-title {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  display: flex;
  margin-top: 31px;
  margin-bottom: 66px;
  justify-content: center;
  text-align: center;
}
.news-template-27 .sub-post-title p {
  margin: 0;
  max-width: 815px;
}
.news-template-27 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-27 .post-more a {
  color: #eb2e35;
}
.news-template-27 .pointer {
  cursor: pointer;
}
.news-template-27 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-27 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1f1f1f;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-27 .post-image,
.news-template-27 .post-image-cover {
  height: 60px !important;
  width: 60px !important;
}
.news-template-27 .post-image-cover {
  object-fit: contain;
  margin-top: 6px;
}
.news-template-27 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-27 .time-clock {
  display: flex;
}
.news-template-27 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-27 .main-item {
  display: table;
  margin: 0;
}
.news-template-27 .item-a {
  text-decoration: none;
  color: #a0a0a0;
  font-size: 16;
}
.news-template-27 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-27 .d-flex {
  visibility: visible !important;
}
.news-template-27 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-27 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-27 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-27 .menu .menu-row,
.news-template-27 .menu .menu-row-current,
.news-template-27 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-27 .menu .menu-row .menu-item-1,
.news-template-27 .menu .menu-row .menu-item-2,
.news-template-27 .menu .menu-row-current .menu-item-1,
.news-template-27 .menu .menu-row-current .menu-item-2,
.news-template-27 .menu .menu-row-current-border .menu-item-1,
.news-template-27 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-27 .menu .menu-row-current,
.news-template-27 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-27 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-27 .img-figure,
.news-template-27 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}
.news-template-27 .uppercase {
  color: #ffffff;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-27 .column {
  margin: 110px 0;
}
.news-template-27 .post-detail-mb {
  padding: 20px 28px 20px 28px;
  border-radius: 5px;
  border: 1px solid #2868ae;
}
.news-template-27 .top-new-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px 20px;
}
.news-template-27 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-27 .news-template-27-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-27 .news-template-27-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-27 .news-template-27-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-27 .news-template-27-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-27 .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-27 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-27 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-27 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-27 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-27 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-27 .text-section :hover {
  color: #00396b;
}
.news-template-27 .post-infor {
  margin-left: 16px;
}
.news-template-27 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  color: #2868ae;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 17px;
  margin-bottom: 7px;
}
.news-template-27 .new-detail {
  font-style: normal;
  font-size: 16px;
  color: #a0a0a0;
  margin-bottom: 0px;
  margin-right: 11px;
}
@media (max-width: 1200px) {
  .news-template-27 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-27 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-27 .post-row {
  margin-top: 50px;
}
.news-template-27 .dl-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 31px;
  margin-bottom: 10px;
  place-items: center;
}
.news-template-27 .post-detail {
  border: 1px solid #2868ae;
  padding: 20px 25px 25px;
  width: 100%;
  max-height: 263px;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.news-template-27 .post-detail .section-box {
  display: flex;
}
.news-template-27 .view-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08659a;
}
.news-template-27 .line {
  border: 1px solid #dc7e10;
  margin: 10px 70px;
}
.news-template-27 .info-box {
  display: flex;
}
.news-template-27 .text-box {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .news-template-27 .post-title {
    padding: 0px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .news-template-27 .dl-3 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .news-template-27 .post-detail {
    max-width: unset !important;
  }
}
@media screen and (max-width: 768px) {
  .news-template-27 .img-figure,
.news-template-27 .img-figure-no-img {
    margin-bottom: 16px;
  }
  .news-template-27 .column {
    margin: 10px 0px !important;
  }
  .news-template-27 .title-first {
    font-size: 16px !important;
  }
  .news-template-27 .new-detail {
    font-size: 14px !important;
    color: #666161 !important;
  }
  .news-template-27 .post-title {
    font-size: 18px !important;
  }
  .news-template-27 .post-title p {
    margin-bottom: 28px;
  }
  .news-template-27 .sub-post-title {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  .news-template-27 .column {
    margin: 10px 0 !important;
  }
  .news-template-27 .top-new-item {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 20px 0px !important;
  }
}

.news-template-27-dialog {
  margin-left: 0px !important;
}

.news-template-27-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-27-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-27-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-27-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-27-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-27-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-27-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-27-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-27-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-27-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-27-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-27-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-27-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-27-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-27-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-27-dialog .float-right {
  float: right;
}

.news-template-27-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-27-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-27-dialog .control {
  margin-bottom: 22px;
}

.news-template-27-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-27-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-27-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-27-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-27-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-27-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-28 {
  width: 100%;
}
.news-template-28 .row {
  --bs-gutter-x: 0;
}
.news-template-28 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-28 .no-wrap {
  display: flex;
  flex-wrap: nowrap !important;
}
.news-template-28 .title {
  text-align: left;
  padding: 22px 0px 22px 15px;
  font-weight: 400;
  font-size: 13px;
  color: #FFFFFF;
  width: 70%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-28 .main-item {
  display: table;
  margin: 10px 0px;
}
.news-template-28 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-28 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-28 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-28 .d-flex {
  visibility: visible !important;
}
.news-template-28 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-28 .menu .menu-row,
.news-template-28 .menu .menu-row-current,
.news-template-28 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-28 .menu .menu-row .menu-item-1,
.news-template-28 .menu .menu-row .menu-item-2,
.news-template-28 .menu .menu-row-current .menu-item-1,
.news-template-28 .menu .menu-row-current .menu-item-2,
.news-template-28 .menu .menu-row-current-border .menu-item-1,
.news-template-28 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-28 .menu .menu-row-current,
.news-template-28 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-28 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-28 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-28 .news-template-28-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-28 .news-template-28-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-28 .news-template-28-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-28 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-28 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-28 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-28 .web-link {
  box-sizing: border-box;
  background-color: #006CB5;
  display: flex;
}
.news-template-28 .dropdown-content {
  display: none;
  position: absolute;
  width: calc(100% - 30px);
  background-color: #f6f6f6;
  overflow: auto;
  border: 1px solid #b7b7b7;
  z-index: 1;
  top: 45px;
}
.news-template-28 .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.news-template-28 .drop-menu {
  position: relative;
  width: 100%;
  padding: 10px 10px 10px 0px;
  justify-content: center;
  display: flex;
}
.news-template-28 .dropbtn {
  width: 100%;
  min-height: 30px;
  border: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  text-align: left;
  background: #fff;
}
.news-template-28 .drop-item-list {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 210px !important;
}
.news-template-28 .drop-item-search {
  width: calc(100% - 14px);
  padding: 7px;
  margin: 7px;
}
.news-template-28 .drop-item {
  min-height: 30px;
  border: none;
  display: flex;
  background-color: #f6f6f6;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
}
.news-template-28 .active {
  background-color: #d0d0d0;
}
.news-template-28 .show {
  display: block;
  z-index: 1;
}

.news-template-28-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-28-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-28-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-28-dialog .err-text,
.news-template-28-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-28-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-28-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-28-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-28-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-28-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-28-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-28-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-28-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-28-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-28-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-28-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-28-dialog .float-right {
  float: right;
}

.news-template-28-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-28-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-28-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-28-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-29 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-29 .row {
  --bs-gutter-x: 0;
}
.news-template-29 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-29 .pd-l-10 {
  padding-left: 18px;
}
.news-template-29 .menu-col {
  padding-right: 10px;
}
.news-template-29 .post-title {
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #EE4623;
  position: relative;
}
.news-template-29 .post-title .nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 6px;
}
.news-template-29 .post-title .nav-item,
.news-template-29 .post-title .nav-item-selected {
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}
.news-template-29 .post-title .nav-item-selected {
  border: none;
  font-weight: 700;
  color: #007fae;
}
@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.news-template-29 .post-title .nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  margin-bottom: 15px;
  animation: mykey 0.3s ease-out;
}
.news-template-29 .post-title .menu-bar {
  background-color: #ffffff;
  align-items: center;
  color: #494949;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 12px;
  padding-left: 0px;
  padding-top: 14px;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
}
.news-template-29 .post-title .menu-bar:hover {
  overflow-x: auto;
}
.news-template-29 .post-title .menu-bar::-webkit-scrollbar {
  height: 4px;
}
.news-template-29 .post-title .menu-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.news-template-29 .post-title .menu-bar::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.news-template-29 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-29 .post-more a {
  color: #EB2E35;
}
.news-template-29 .pointer {
  cursor: pointer;
}
.news-template-29 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-29 .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-29 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-bottom: 0;
}
.news-template-29 .post-image, .news-template-29 .post-image-cover {
  height: 271px;
  width: 100%;
  border-radius: 5px;
}
.news-template-29 .post-image-cover {
  object-fit: cover;
}
.news-template-29 .post-right-image {
  height: 271px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.news-template-29 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-29 .time-clock {
  display: flex;
}
.news-template-29 .time-text {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #D52929;
  padding-top: 20px;
  margin-bottom: 16px;
}
.news-template-29 .main-item {
  margin: 0;
  width: 100%;
}
.news-template-29 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-29 .d-flex {
  visibility: visible !important;
}
.news-template-29 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-29 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-29 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-29 .menu .menu-row,
.news-template-29 .menu .menu-row-current,
.news-template-29 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-29 .menu .menu-row .menu-item-1,
.news-template-29 .menu .menu-row .menu-item-2,
.news-template-29 .menu .menu-row-current .menu-item-1,
.news-template-29 .menu .menu-row-current .menu-item-2,
.news-template-29 .menu .menu-row-current-border .menu-item-1,
.news-template-29 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-29 .menu .menu-row-current,
.news-template-29 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-29 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-29 .img-figure, .news-template-29 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-29 .uppercase {
  height: 45px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #006CB5;
  text-transform: uppercase;
}
.news-template-29 .k-scrollview-wrap {
  border: none;
}
.news-template-29 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-29 .news-template-29-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-29 .news-template-29-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-29 .news-template-29-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-29 .news-template-29-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-29 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-29 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-29 .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #EE4623;
  border-radius: 3px;
}
.news-template-29 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-29 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-29 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-29 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-29 .text-section :hover {
  color: #00396B;
}
.news-template-29 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 0px !important;
}
.news-template-29 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #454545;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-29 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-29 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-29 .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-29 .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4D4D4D;
  padding-left: 3px;
}
.news-template-29 .top-new-first-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-29 .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-29 .top-new-item {
  margin-bottom: 47px;
  justify-content: space-between;
  text-decoration: none;
}
.news-template-29 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-29 .post-list {
  margin-right: -20px;
}
.news-template-29 .post-list .k-pager-info, .news-template-29 .post-list .k-pager-sizes {
  display: none;
}
.news-template-29 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-29 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-29 .post-list .top-part {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 30px;
  height: 100%;
}
.news-template-29 .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-29 .post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-29 .first-post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-29 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-29 .hide-item {
  display: none;
}
.news-template-29 .line {
  border: 1px solid #DC7E10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-29 .title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  margin-bottom: 16px;
  min-height: 80px;
}
.news-template-29 .summary {
  font-weight: 400;
  font-size: 16px;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}
.news-template-29 .news-title {
  width: fit-content;
}
.news-template-29 .left-box {
  margin-right: 20px;
}
.news-template-29 .right-item-detail {
  padding-left: 31px;
  padding-right: 30px;
  padding-bottom: 32px;
  height: 50%;
}
@media (max-width: 768px) {
  .news-template-29 .line {
    border: 1px solid #DC7E10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-29 .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-29 .post-list .top-part {
    display: flex;
    flex-direction: column;
  }
  .news-template-29 .top-new-item {
    margin-left: 5px;
    padding-right: 10px;
    display: block;
  }
  .news-template-29 .content-right {
    padding-top: 0px;
  }
  .news-template-29 .container-fluid {
    margin-left: 5px;
  }
}
@media (max-width: 768px) {
  .news-template-29 .post-title .arrow-box {
    position: relative;
  }
  .news-template-29 .post-title .title-right {
    position: relative;
    left: 0;
    top: 0;
    width: 20%;
  }
  .news-template-29 .post-title .menu-bar {
    width: 90%;
  }
}

.news-template-29-dialog {
  margin-left: 0px !important;
}

.news-template-29-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-29-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-29-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-29-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-29-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-29-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-29-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-29-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-29-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-29-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-29-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-29-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-29-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-29-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-29-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-29-dialog .float-right {
  float: right;
}

.news-template-29-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-29-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-29-dialog .control {
  margin-bottom: 22px;
}

.news-template-29-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-29-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-29-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-29-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-29-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-29-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-30,
.news-template-30-without-time {
  width: 100%;
  min-height: 504px;
}
.news-template-30 .row,
.news-template-30-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-30 .small-post,
.news-template-30-without-time .small-post {
  display: flex;
  flex-direction: row;
}
.news-template-30 .k-dialog,
.news-template-30-without-time .k-dialog {
  object-fit: cover;
}
.news-template-30 .video-container,
.news-template-30-without-time .video-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.news-template-30 .no-pointer-events,
.news-template-30-without-time .no-pointer-events {
  pointer-events: none;
}
.news-template-30 .video-container iframe,
.news-template-30-without-time .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.news-template-30 .post-more,
.news-template-30-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-30 .post-more a,
.news-template-30-without-time .post-more a {
  color: #d6a022;
}
.news-template-30 .container-fluid,
.news-template-30-without-time .container-fluid {
  background: url(../../assets/images/backround-library.png);
  background-size: cover;
  width: 100%;
  height: 100%;
  background-color: #004A9A;
}
.news-template-30 .desktop-width,
.news-template-30-without-time .desktop-width {
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
}
.news-template-30 .content-width,
.news-template-30-without-time .content-width {
  margin: 0 auto;
}
.news-template-30 .content-width-mainpage,
.news-template-30-without-time .content-width-mainpage {
  width: auto;
}
.news-template-30 .content-width-media,
.news-template-30-without-time .content-width-media {
  width: calc(100% - 250px);
}
.news-template-30 .pointer,
.news-template-30-without-time .pointer {
  cursor: pointer;
}
.news-template-30 .icon-clock,
.news-template-30-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-30 .width-first,
.news-template-30-without-time .width-first {
  width: 536px;
}
.news-template-30 .post-image,
.news-template-30 .post-image-radius,
.news-template-30-without-time .post-image,
.news-template-30-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-30 .post-image-radius,
.news-template-30-without-time .post-image-radius {
  border-radius: none;
}
.news-template-30 .post-image-default,
.news-template-30-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-30 .post-image-org,
.news-template-30-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-30 .time-clock,
.news-template-30-without-time .time-clock {
  display: flex;
}
.news-template-30 .time-text,
.news-template-30-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.news-template-30 .main-item,
.news-template-30-without-time .main-item {
  display: table;
}
.news-template-30 .vertical-dot,
.news-template-30-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: none;
  visibility: hidden;
}
.news-template-30 .main-item:hover .vertical-dot,
.news-template-30-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-30 .d-flex,
.news-template-30-without-time .d-flex {
  visibility: visible !important;
}
.news-template-30 .three-dot-area,
.news-template-30-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-30 .menu,
.news-template-30-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-30 .menu .menu-row,
.news-template-30 .menu .menu-row-current,
.news-template-30 .menu .menu-row-current-border,
.news-template-30-without-time .menu .menu-row,
.news-template-30-without-time .menu .menu-row-current,
.news-template-30-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-30 .menu .menu-row .menu-item-1,
.news-template-30 .menu .menu-row .menu-item-2,
.news-template-30 .menu .menu-row-current .menu-item-1,
.news-template-30 .menu .menu-row-current .menu-item-2,
.news-template-30 .menu .menu-row-current-border .menu-item-1,
.news-template-30 .menu .menu-row-current-border .menu-item-2,
.news-template-30-without-time .menu .menu-row .menu-item-1,
.news-template-30-without-time .menu .menu-row .menu-item-2,
.news-template-30-without-time .menu .menu-row-current .menu-item-1,
.news-template-30-without-time .menu .menu-row-current .menu-item-2,
.news-template-30-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-30-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-30 .menu .menu-row-current,
.news-template-30 .menu .menu-row-current-border,
.news-template-30-without-time .menu .menu-row-current,
.news-template-30-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-30 .menu .menu-item-3,
.news-template-30-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-30 .padding,
.news-template-30-without-time .padding {
  padding-left: 15px;
}
.news-template-30 .img-first,
.news-template-30-without-time .img-first {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-template-30 .post-img-first,
.news-template-30-without-time .post-img-first {
  position: relative;
  width: 100%;
  margin: 0px;
}
.news-template-30 .img-figure-first,
.news-template-30-without-time .img-figure-first {
  width: 100%;
  height: 100%;
}
.news-template-30 .img-figure,
.news-template-30 .img-figure-no-img,
.news-template-30 .img-figure-template-type-2,
.news-template-30-without-time .img-figure,
.news-template-30-without-time .img-figure-no-img,
.news-template-30-without-time .img-figure-template-type-2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin: 0px;
  object-fit: cover;
  border-radius: 3px;
}
.news-template-30 .img-figure-other,
.news-template-30-without-time .img-figure-other {
  position: relative;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  position: relative;
  object-fit: cover;
}
.news-template-30 .media-hot,
.news-template-30-without-time .media-hot {
  position: relative;
  margin-bottom: 0px;
}
.news-template-30 .img-figure-template-type-2,
.news-template-30-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-30 .img-figure-2,
.news-template-30-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-30 .post-list,
.news-template-30-without-time .post-list {
  display: flex;
  margin-right: -20px;
}
.news-template-30 .post-list .k-pager-info,
.news-template-30 .post-list .k-pager-sizes,
.news-template-30-without-time .post-list .k-pager-info,
.news-template-30-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-30 .post-list .k-pager kendo-datapager-prev-buttons,
.news-template-30-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-30 .post-list .k-pager .k-state-selected,
.news-template-30-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-30 .post-list .top-part,
.news-template-30-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
  margin-right: 36px;
}
.news-template-30 .margin-right,
.news-template-30-without-time .margin-right {
  margin-right: 36px;
}
.news-template-30 .top-new-item,
.news-template-30-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-30 .top-new-item .title:hover,
.news-template-30-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-30 .close-div,
.news-template-30-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-30 .description-text,
.news-template-30-without-time .description-text {
  text-align: justify;
}
.news-template-30 .description-text figure,
.news-template-30-without-time .description-text figure {
  width: 100% !important;
  height: 500px;
}
.news-template-30 .description-text figure iframe,
.news-template-30-without-time .description-text figure iframe {
  height: 100%;
  width: 100%;
}
.news-template-30 .description-text p,
.news-template-30-without-time .description-text p {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000 !important;
}
.news-template-30 .description-text blockquote,
.news-template-30-without-time .description-text blockquote {
  border-left: none solid #d9d9d9;
  padding-left: 15px;
  font-style: italic;
}
.news-template-30 .center,
.news-template-30-without-time .center {
  position: fixed;
  bottom: 57px;
  width: 50vw;
  left: 10px;
  right: 10px;
  /* margin-left: 53px; */
  margin: 0 auto;
  z-index: 10000000000;
}
.news-template-30 .news-template-30-dialog,
.news-template-30-without-time .news-template-30-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-30 .news-template-30-dialog .close-icon-div,
.news-template-30-without-time .news-template-30-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-30 .news-template-30-dialog .close-icon,
.news-template-30-without-time .news-template-30-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-30 .news-template-30-dialog .title-info-req,
.news-template-30-without-time .news-template-30-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-30 .close-icon-div,
.news-template-30-without-time .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-30 .input-label,
.news-template-30-without-time .input-label {
  color: #004A9A;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-30 .btn-delete,
.news-template-30-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: none;
  background-color: #1c8d43;
  border: none;
  color: white;
  font-size: 14px;
}
.news-template-30 .btn-cancel,
.news-template-30-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: none;
  background-color: #6c757d;
  border: none;
  color: white;
  font-size: 14px;
}
.news-template-30 .dialog-width,
.news-template-30-without-time .dialog-width {
  max-width: 400px;
}
.news-template-30 .content,
.news-template-30-without-time .content {
  display: flex;
  flex-direction: column;
}
.news-template-30 .dialog-opened,
.news-template-30-without-time .dialog-opened {
  position: relative;
  overflow: hidden !important;
}
.news-template-30 .image-list,
.news-template-30-without-time .image-list {
  /* Căn giữa danh sách hình ảnh */
  display: flex;
  justify-content: center;
}
.news-template-30 .smallImg,
.news-template-30-without-time .smallImg {
  width: 165px;
  height: 134px;
  padding-top: 37px;
  padding-right: 30px;
}
.news-template-30 .dialog-width-images,
.news-template-30-without-time .dialog-width-images {
  height: fit-content !important;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-30 .k-dialog .dialog2,
.news-template-30-without-time .k-dialog .dialog2 {
  height: 70% !important;
  width: 78% !important;
  top: 0px;
}
.news-template-30 .dialogimage2 .k-window-content,
.news-template-30 .k-prompt-container,
.news-template-30-without-time .dialogimage2 .k-window-content,
.news-template-30-without-time .k-prompt-container {
  padding: 0px !important;
  border-width: none;
  border-color: none;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}
.news-template-30 .koverley2 .k-overlay,
.news-template-30-without-time .koverley2 .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
.news-template-30 .overlay,
.news-template-30-without-time .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: transparent;
}
.news-template-30 .opend-dialog,
.news-template-30-without-time .opend-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
}
.news-template-30 .dialog-container,
.news-template-30-without-time .dialog-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-30 .close-div2,
.news-template-30-without-time .close-div2 {
  display: flex;
}
.news-template-30 .dialog-opened,
.news-template-30-without-time .dialog-opened {
  position: relative;
  overflow: hidden !important;
}
.news-template-30 .title,
.news-template-30 .template-title,
.news-template-30 .post-title,
.news-template-30 .description-text,
.news-template-30-without-time .title,
.news-template-30-without-time .template-title,
.news-template-30-without-time .post-title,
.news-template-30-without-time .description-text {
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  color: #FFFFFF;
}
.news-template-30 .template-title,
.news-template-30-without-time .template-title {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 30px;
  padding-top: 36px;
}
.news-template-30 .title-template-type-2,
.news-template-30-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-30 .post-title,
.news-template-30-without-time .post-title {
  padding-top: 10px;
  margin-bottom: 10px;
}
.news-template-30 .title-first,
.news-template-30-without-time .title-first {
  font-size: 16px;
  line-height: 30px;
}
.news-template-30 .title-other,
.news-template-30-without-time .title-other {
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 22px;
}
.news-template-30 .description-text,
.news-template-30-without-time .description-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.03em;
  margin-bottom: 32px;
}
.news-template-30 .aaa,
.news-template-30-without-time .aaa {
  flex: 0 0 auto;
  width: 35.5%;
}
.news-template-30 .bbb,
.news-template-30-without-time .bbb {
  flex: 0 0 auto;
  width: 62.1%;
}
.news-template-30 .d-grid,
.news-template-30-without-time .d-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 36px;
  object-fit: cover;
}
.news-template-30 .dialog-container,
.news-template-30-without-time .dialog-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-30 .dialogimage2 .k-window-content, .news-template-30 .k-prompt-container,
.news-template-30-without-time .dialogimage2 .k-window-content,
.news-template-30-without-time .k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
}
.news-template-30 .width-full,
.news-template-30-without-time .width-full {
  width: 100%;
  height: 100%;
}
.news-template-30 .opend-dialog-images .video-container,
.news-template-30-without-time .opend-dialog-images .video-container {
  height: 700px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .news-template-30 .container-fluid,
.news-template-30-without-time .container-fluid {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  .news-template-30 .container-fluid [class*=col-],
.news-template-30-without-time .container-fluid [class*=col-] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .news-template-30 .img-figure-other,
.news-template-30-without-time .img-figure-other {
    width: 100%;
    height: 287px;
  }
}
@media screen and (max-width: 770px) {
  .news-template-30 .content-width,
.news-template-30-without-time .content-width {
    margin: 10px 0px !important;
    width: 80%;
  }
  .news-template-30 .post-img-first,
.news-template-30-without-time .post-img-first {
    height: 150px !important;
  }
  .news-template-30 .img-figure-other,
.news-template-30-without-time .img-figure-other {
    height: 150px !important;
  }
  .news-template-30 .dialog-width-images,
.news-template-30-without-time .dialog-width-images {
    max-height: 317px;
  }
  .news-template-30 .opend-dialog-images .video-container,
.news-template-30-without-time .opend-dialog-images .video-container {
    height: 300px;
  }
}
@media screen and (max-width: 576px) {
  .news-template-30 .padding-box,
.news-template-30-without-time .padding-box {
    padding: 0 20px;
  }
}

.news-template-30-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-30-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-30-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-30-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-30-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-30-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-30-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-30-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-30-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-30-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-30-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-30-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-30-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-30-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-30-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-30-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-30-dialog .float-right {
  float: right;
}

.news-template-30-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-30-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-30-dialog .control {
  margin-bottom: 22px;
}

.news-template-30-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-30-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-30-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-30-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-30-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-30-dialog .padding-col {
  padding: 7px 15px 0;
}

.post-detail2 tbody,
.post-detail2 td,
.post-detail2 tfoot,
.post-detail2 th,
.post-detail2 thead,
.post-detail2 tr {
  border-color: #cacaca;
  border-width: 1px;
}
.post-detail2 table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #cacaca;
}
.post-detail2 .content {
  align-items: center;
  justify-content: center;
  display: flex;
}
.post-detail2 .text-tiny {
  font-size: 0.7em;
}
.post-detail2 .text-small {
  font-size: 0.85em;
}
.post-detail2 .text-medium {
  font-size: 1em;
}
.post-detail2 .text-big {
  font-size: 1.4em;
}
.post-detail2 .text-huge {
  font-size: 2em;
}
.post-detail2 .row {
  display: flex;
}
.post-detail2 a .categories {
  color: #4e7ed8;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none !important;
  z-index: 6;
}
.post-detail2 a:hover span {
  color: #ff9900;
}
.post-detail2 .title-post {
  font-style: normal;
  margin-top: 47px !important;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #006CB5;
}
.post-detail2 .summary {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #565656;
}
.post-detail2 .margin-template {
  margin: auto;
  width: 75%;
}
.post-detail2 .created-post {
  font-weight: bolder;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
  color: #D52929;
}
.post-detail2 hr.line-title {
  border-top: 5px solid #aeafb3;
}
.post-detail2 .img-present {
  position: relative;
  max-width: 100% !important;
  font-size: 10px;
}
.post-detail2 .img-present .video-view {
  height: 390px !important;
}
.post-detail2 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.post-detail2 .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.post-detail2 .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.post-detail2 .vertical-dot .menu .menu-row,
.post-detail2 .vertical-dot .menu .menu-row-current,
.post-detail2 .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.post-detail2 .vertical-dot .menu .menu-row .menu-item-1,
.post-detail2 .vertical-dot .menu .menu-row .menu-item-2,
.post-detail2 .vertical-dot .menu .menu-row-current .menu-item-1,
.post-detail2 .vertical-dot .menu .menu-row-current .menu-item-2,
.post-detail2 .vertical-dot .menu .menu-row-current-border .menu-item-1,
.post-detail2 .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.post-detail2 .vertical-dot .menu .menu-row-current,
.post-detail2 .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.post-detail2 .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.post-detail2 .d-flex {
  visibility: visible !important;
}
.post-detail2 .title-center {
  text-align: center !important;
}
.post-detail2 .close-div {
  display: flex;
  margin-top: 3px;
}
.post-detail2 .post-detail-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.post-detail2 .post-detail-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.post-detail2 .post-detail-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.post-detail2 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.post-detail2 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.post-detail2 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.post-detail2 .description {
  text-align: justify;
}
.post-detail2 .description figure {
  width: 100% !important;
  height: 500px;
}
.post-detail2 .description figure iframe {
  height: 100%;
  width: 100%;
}
.post-detail2 .description p {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #000000 !important;
}
.post-detail2 .description blockquote {
  border-left: 5px solid #d9d9d9;
  padding-left: 15px;
}
.post-detail2 img {
  width: 100%;
  height: auto;
}
.post-detail2 .box {
  padding: 0px;
  padding-left: 0px;
}
.post-detail2 .reference-link-box {
  margin-top: 10px;
}
.post-detail2 .sapned {
  margin-top: 20px;
}
.post-detail2 .padding-body {
  margin-left: 10px;
  padding-right: 50px !important;
  max-width: 1140px;
  margin: 0 auto;
}
.post-detail2 .reference-link {
  text-decoration: none;
}
.post-detail2 .reference-link:hover {
  text-decoration: underline;
}
.post-detail2 .file-container {
  padding: 20px;
  background: #EAEAEA;
  margin-top: 10px;
}
.post-detail2 .custom-text {
  color: black;
}
.post-detail2 .custom-text-file {
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.post-detail2 .file-div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.post-detail2 .file-div a {
  text-decoration: none;
}
.post-detail2 .file-div :hover {
  color: #4e7ed8;
}
.post-detail2 .categories-link {
  text-decoration: none;
}

@media screen and (max-width: 770px) {
  .box {
    padding-left: 15px;
    padding-right: 30px;
  }
  .box .categories {
    font-size: 14px;
  }
  .box .title-post {
    font-weight: bolder;
    font-size: 20px;
  }
  .box .img-present {
    position: relative;
    max-width: 100% !important;
    font-size: 10px;
  }
  .box .img-present .video-view {
    height: 190px !important;
  }

  .padding-right {
    padding-right: 14px;
  }

  .padding-left {
    padding-left: 14px;
  }

  .description {
    text-align: justify;
  }
  .description figure {
    width: 100% !important;
  }
  .description figure iframe {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 1025px) and (min-width: 770px) {
  .box {
    padding-left: 15px !important;
  }
}
.news-template-31 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-31 .row {
  --bs-gutter-x: 0;
}
.news-template-31 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-31 .pd-l-10 {
  padding-left: 18px;
}
.news-template-31 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  position: relative;
  min-height: 40px;
}
.news-template-31 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-31 .post-more a {
  color: #EB2E35;
}
.news-template-31 .pointer {
  cursor: pointer;
}
.news-template-31 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-31 a:link {
  text-decoration: none;
}
.news-template-31 .title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #2B2B2B;
  margin-bottom: 0;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-template-31 .post-image, .news-template-31 .post-image-cover {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  margin-right: 20px;
}
.news-template-31 .post-image-cover {
  object-fit: cover;
}
.news-template-31 .post-image-first, .news-template-31 .post-image-cover-first {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.news-template-31 .post-image-cover-first {
  object-fit: cover;
}
.news-template-31 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-31 .time-clock {
  display: flex;
}
.news-template-31 .time-text {
  color: #797979;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 2rem;
}
.news-template-31 .time-text-first {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #797979;
  margin-bottom: 7px !important;
}
.news-template-31 .main-item {
  display: table;
  margin: 0;
}
.news-template-31 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-31 .d-flex {
  visibility: visible !important;
}
.news-template-31 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-31 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-31 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-31 .menu .menu-row,
.news-template-31 .menu .menu-row-current,
.news-template-31 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-31 .menu .menu-row .menu-item-1,
.news-template-31 .menu .menu-row .menu-item-2,
.news-template-31 .menu .menu-row-current .menu-item-1,
.news-template-31 .menu .menu-row-current .menu-item-2,
.news-template-31 .menu .menu-row-current-border .menu-item-1,
.news-template-31 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-31 .menu .menu-row-current,
.news-template-31 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-31 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-31 .img-figure, .news-template-31 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-31 .uppercase {
  color: #006cb5;
  margin-top: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
}
.news-template-31 .top-new-item {
  padding-left: 20px;
}
.news-template-31 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-31 .news-template-31-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-31 .news-template-31-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-31 .news-template-31-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-31 .news-template-31-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-31 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-31 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-31 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-31 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-31 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-31 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.news-template-31 .text-section :hover {
  color: #00396B;
}
.news-template-31 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #2B2B2B;
  margin-bottom: 0px !important;
  text-decoration: none;
}
.news-template-31 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #313131;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-31 .desktop-width {
  position: relative;
  padding-bottom: 20px;
}
.news-template-31 .content-width-media {
  width: calc(62vw - 28px);
  margin: auto;
}
@media (max-width: 1200px) {
  .news-template-31 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-31 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .news-template-31 .content-width-media {
    width: calc(100%);
    margin: auto;
  }
  .news-template-31 .text-section {
    display: block;
  }
  .news-template-31 .title {
    margin-top: 30px;
    margin-left: 0px;
  }
  .news-template-31 .time-text {
    margin-left: 0px;
    margin-top: 20px;
  }
  .news-template-31 .desktop-width {
    width: auto !important;
    left: 0px !important;
  }
}

.news-template-31-dialog {
  margin-left: 0px !important;
}

.news-template-31-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-31-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-31-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-31-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-31-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-31-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-31-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-31-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-31-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-31-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-31-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-31-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-31-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-31-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-31-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-31-dialog .float-right {
  float: right;
}

.news-template-31-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-31-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-31-dialog .control {
  margin-bottom: 22px;
}

.news-template-31-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-31-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-31-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-31-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-31-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-31-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-32 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-32 .row {
  --bs-gutter-x: 0;
}
.news-template-32 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-32 .pd-l-10 {
  padding-left: 18px;
}
.news-template-32 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  min-height: 40px;
}
.news-template-32 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-32 .post-more a {
  color: #EB2E35;
}
.news-template-32 .pointer {
  cursor: pointer;
}
.news-template-32 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-32 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-template-32 .post-image {
  height: 50%;
  width: 50%;
  display: block;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  object-fit: contain;
}
.news-template-32 .post-image-cover {
  object-fit: cover;
  width: 48px;
}
.news-template-32 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-32 .time-clock {
  display: flex;
}
.news-template-32 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-32 .main-item {
  display: table;
  margin: 0;
}
.news-template-32 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-32 .d-flex {
  visibility: visible !important;
}
.news-template-32 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-32 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-32 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-32 .menu .menu-row,
.news-template-32 .menu .menu-row-current,
.news-template-32 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-32 .menu .menu-row .menu-item-1,
.news-template-32 .menu .menu-row .menu-item-2,
.news-template-32 .menu .menu-row-current .menu-item-1,
.news-template-32 .menu .menu-row-current .menu-item-2,
.news-template-32 .menu .menu-row-current-border .menu-item-1,
.news-template-32 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-32 .menu .menu-row-current,
.news-template-32 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-32 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-32 .img-figure, .news-template-32 .img-figure-no-img {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 48px;
  height: 48px;
  border: 1px solid #D8D8D8;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
}
.news-template-32 .img-figure:hover .post-image, .news-template-32 .img-figure-no-img:hover .post-image {
  width: 60%;
  height: 60%;
  margin-top: 9px;
}
.news-template-32 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-32 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-32 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-32 .news-template-32-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-32 .news-template-32-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-32 .news-template-32-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-32 .news-template-32-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-32 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-32 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-32 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-32 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-32 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-32 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.news-template-32 .text-section :hover {
  color: #00396B;
}
.news-template-32 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
  text-decoration: none;
}
.news-template-32 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-32 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-32 .fixed-item {
  position: fixed;
  padding: 0px 0px 0 0;
  left: calc(100% - 65px);
  top: 45%;
  transform: translateX(-50%);
  z-index: 1001;
}
@media (max-width: 768px) {
  .news-template-32 .fixed-item {
    top: 30%;
    right: 0px;
    left: auto;
  }
}

.news-template-32-dialog {
  margin-left: 0px !important;
}

.news-template-32-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-32-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-32-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-32-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-32-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-32-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-32-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-32-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-32-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-32-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-32-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-32-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-32-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-32-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-32-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-32-dialog .float-right {
  float: right;
}

.news-template-32-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-32-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-32-dialog .control {
  margin-bottom: 22px;
}

.news-template-32-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-32-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-32-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-32-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-32-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-32-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-33,
.news-template-33-without-time {
  width: 100%;
  min-height: 504px;
}
.news-template-33 .row,
.news-template-33-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-33 .k-dialog,
.news-template-33-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-33 .dialog2 .k-dialog,
.news-template-33-without-time .dialog2 .k-dialog {
  height: 100% !important;
  width: 100% !important;
}
.news-template-33 .k-dialog .dialod1,
.news-template-33-without-time .k-dialog .dialod1 {
  height: 100% !important;
  width: 100% !important;
}
.news-template-33 .k-dialog .dialog2,
.news-template-33-without-time .k-dialog .dialog2 {
  height: 70% !important;
  width: 78% !important;
  top: 0px;
}
.news-template-33 .post-title,
.news-template-33-without-time .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-33 .title-text,
.news-template-33-without-time .title-text {
  margin-bottom: 47px;
  margin-top: 47px;
  line-height: 45.25px;
  font-weight: 700;
  font-size: 36px;
  color: #006cb5;
}
.news-template-33 .post-more,
.news-template-33-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-33 .post-more a,
.news-template-33-without-time .post-more a {
  color: #d6a022;
}
.news-template-33 .close-div,
.news-template-33-without-time .close-div {
  display: flex;
}
.news-template-33 .close-icon-div,
.news-template-33-without-time .close-icon-div {
  position: fixed;
  right: 537px;
  top: 150px;
}
.news-template-33 .pointer,
.news-template-33-without-time .pointer {
  cursor: pointer;
}
.news-template-33 .dialogimage2 .k-window-content,
.news-template-33 .k-prompt-container,
.news-template-33-without-time .dialogimage2 .k-window-content,
.news-template-33-without-time .k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
}
.news-template-33 .icon-clock,
.news-template-33-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-33 .title,
.news-template-33-without-time .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #292621;
  margin-bottom: 20px;
  text-decoration: none;
}
.news-template-33 .title-template-type-2,
.news-template-33-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2b2b2b;
  margin-bottom: 20px;
}
.news-template-33 .post-image,
.news-template-33 .post-image-radius,
.news-template-33-without-time .post-image,
.news-template-33-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-33 .post-image-radius,
.news-template-33-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-33 .post-image-default,
.news-template-33-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-33 .post-image-org,
.news-template-33-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-33 .time-clock,
.news-template-33-without-time .time-clock {
  display: flex;
}
.news-template-33 .time-text,
.news-template-33-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-33 .center,
.news-template-33-without-time .center {
  position: fixed;
  bottom: 30px;
  width: 40vw;
  left: 10px;
  right: 10px;
  /* margin-left: 53px; */
  margin: 0 auto;
  z-index: 10000000000;
}
.news-template-33 .vertical-dot,
.news-template-33-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-33 .main-item:hover .vertical-dot,
.news-template-33-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-33 .d-flex,
.news-template-33-without-time .d-flex {
  visibility: visible !important;
}
.news-template-33 .three-dot-area,
.news-template-33-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-33 .menu,
.news-template-33-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-33 .menu .menu-row,
.news-template-33 .menu .menu-row-current,
.news-template-33 .menu .menu-row-current-border,
.news-template-33-without-time .menu .menu-row,
.news-template-33-without-time .menu .menu-row-current,
.news-template-33-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-33 .menu .menu-row .menu-item-1,
.news-template-33 .menu .menu-row .menu-item-2,
.news-template-33 .menu .menu-row-current .menu-item-1,
.news-template-33 .menu .menu-row-current .menu-item-2,
.news-template-33 .menu .menu-row-current-border .menu-item-1,
.news-template-33 .menu .menu-row-current-border .menu-item-2,
.news-template-33-without-time .menu .menu-row .menu-item-1,
.news-template-33-without-time .menu .menu-row .menu-item-2,
.news-template-33-without-time .menu .menu-row-current .menu-item-1,
.news-template-33-without-time .menu .menu-row-current .menu-item-2,
.news-template-33-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-33-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-33 .menu .menu-row-current,
.news-template-33 .menu .menu-row-current-border,
.news-template-33-without-time .menu .menu-row-current,
.news-template-33-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-33 .menu .menu-item-3,
.news-template-33-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-33 .img-figure,
.news-template-33 .img-figure-no-img,
.news-template-33 .img-figure-template-type-2,
.news-template-33-without-time .img-figure,
.news-template-33-without-time .img-figure-no-img,
.news-template-33-without-time .img-figure-template-type-2 {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-33 .img-figure-template-type-2,
.news-template-33-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-33 .img-figure-2,
.news-template-33-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-33 .post-list,
.news-template-33-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-33 .post-list .k-pager-info,
.news-template-33 .post-list .k-pager-sizes,
.news-template-33-without-time .post-list .k-pager-info,
.news-template-33-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-33 .post-list .k-pager kendo-datapager-prev-buttons,
.news-template-33-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-33 .post-list .k-pager .k-state-selected,
.news-template-33-without-time .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-33 .post-list .top-part,
.news-template-33-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-33 .uppercase,
.news-template-33-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-33 .top-new-item,
.news-template-33-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-33 .top-new-item .title:hover,
.news-template-33-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-33 .close-div,
.news-template-33-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-33 .close-div2,
.news-template-33-without-time .close-div2 {
  display: flex;
}
.news-template-33 .news-template-33-dialog,
.news-template-33-without-time .news-template-33-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-33 .news-template-33-dialog .close-icon-div,
.news-template-33-without-time .news-template-33-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-33 .news-template-33-dialog .close-icon,
.news-template-33-without-time .news-template-33-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-33 .news-template-33-dialog .title-info-req,
.news-template-33-without-time .news-template-33-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-33 .input-label,
.news-template-33-without-time .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-33 .btn-delete,
.news-template-33-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-33 .btn-cancel,
.news-template-33-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-33 .dialog-width,
.news-template-33-without-time .dialog-width {
  max-width: 400px;
}
.news-template-33 .koverley2 .k-overlay,
.news-template-33-without-time .koverley2 .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
.news-template-33 .dialog-width-images,
.news-template-33-without-time .dialog-width-images {
  height: fit-content !important;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-33 .wrapper,
.news-template-33-without-time .wrapper {
  display: flex;
}
.news-template-33 .content,
.news-template-33-without-time .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-template-33 .post-image,
.news-template-33-without-time .post-image {
  width: 100%;
  max-height: 233px;
  min-height: 233px;
  object-fit: cover;
}
.news-template-33 .dialog-container,
.news-template-33-without-time .dialog-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-33 .image-list,
.news-template-33-without-time .image-list {
  /* Căn giữa danh sách hình ảnh */
  display: flex;
  justify-content: center;
}
.news-template-33 .album-name,
.news-template-33-without-time .album-name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #363636;
  margin-top: 20px;
}
.news-template-33 .d-grid,
.news-template-33-without-time .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px 30px;
}
.news-template-33 .k-tabstrip-content,
.news-template-33 .k-tabstrip > .k-content,
.news-template-33-without-time .k-tabstrip-content,
.news-template-33-without-time .k-tabstrip > .k-content {
  padding: 0;
  border: unset;
}
.news-template-33 .k-tabstrip-content.k-state-active,
.news-template-33 .k-tabstrip > .k-content.k-state-active,
.news-template-33-without-time .k-tabstrip-content.k-state-active,
.news-template-33-without-time .k-tabstrip > .k-content.k-state-active {
  margin-top: 56px;
}
.news-template-33 .post-name,
.news-template-33-without-time .post-name {
  margin-top: 28px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.63px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-template-33 .k-tabstrip-items .k-link,
.news-template-33-without-time .k-tabstrip-items .k-link {
  padding: 0px 0px 12px 0px;
  color: #b6b6b6;
  font-weight: 700;
  line-height: 22.63px;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 50px;
}
.news-template-33 .k-tabstrip-items .k-item:not(:first-child) .k-link,
.news-template-33-without-time .k-tabstrip-items .k-item:not(:first-child) .k-link {
  padding: 0px 10px 12px 10px;
}
.news-template-33 .k-tabstrip-items .k-item.k-state-active .k-link,
.news-template-33-without-time .k-tabstrip-items .k-item.k-state-active .k-link {
  color: #004a9a;
  font-weight: 700;
  border-width: 0 0 3px !important;
  border-color: #08659a;
  border-style: solid;
}
.news-template-33 .k-tabstrip-items,
.news-template-33-without-time .k-tabstrip-items {
  overflow-x: auto;
}
.news-template-33 .k-tabstrip-items::-webkit-scrollbar,
.news-template-33-without-time .k-tabstrip-items::-webkit-scrollbar {
  height: 3px;
}
.news-template-33 .k-tabstrip-items::-webkit-scrollbar-track,
.news-template-33-without-time .k-tabstrip-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.news-template-33 .k-tabstrip-items::-webkit-scrollbar-thumb,
.news-template-33-without-time .k-tabstrip-items::-webkit-scrollbar-thumb {
  background: #888;
}
.news-template-33 .k-tabstrip-items::-webkit-scrollbar-thumb:hover,
.news-template-33-without-time .k-tabstrip-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.news-template-33 .k-tabstrip-top > .k-tabstrip-items .k-item,
.news-template-33-without-time .k-tabstrip-top > .k-tabstrip-items .k-item {
  border-width: 0px 0px 1px 0px;
}
.news-template-33 .media,
.news-template-33-without-time .media {
  position: relative;
}
.news-template-33 .overlap,
.news-template-33-without-time .overlap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 106px;
  background: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-33 .overlap .number-img .text,
.news-template-33-without-time .overlap .number-img .text {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30.17px;
}
.news-template-33 .overlap .number-img .image,
.news-template-33-without-time .overlap .number-img .image {
  height: 24px;
  width: 24px;
}
.news-template-33 .overlap .number-img .image img,
.news-template-33-without-time .overlap .number-img .image img {
  width: 100%;
  height: 100%;
}
.news-template-33 kendo-tabstrip,
.news-template-33-without-time kendo-tabstrip {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .news-template-33 .d-grid,
.news-template-33-without-time .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-33 .title-text,
.news-template-33-without-time .title-text {
    font-size: 28px !important;
  }
  .news-template-33 .post-name,
.news-template-33-without-time .post-name {
    font-size: 16px !important;
  }
  .news-template-33 .overlap,
.news-template-33-without-time .overlap {
    width: 94px;
  }
}
@media screen and (max-width: 768px) {
  .news-template-33 .d-grid,
.news-template-33-without-time .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-33 .title-text,
.news-template-33-without-time .title-text {
    font-size: 26px !important;
  }
  .news-template-33 .overlap,
.news-template-33-without-time .overlap {
    width: 86px;
  }
  .news-template-33 .dialog-width-images,
.news-template-33-without-time .dialog-width-images {
    width: 40%;
    height: 55%;
    max-width: 90%;
    max-height: 65%;
    top: -120px;
    margin: auto;
  }
}
@media screen and (max-width: 576px) {
  .news-template-33 .d-grid,
.news-template-33-without-time .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .news-template-33 .post-image,
.news-template-33-without-time .post-image {
    max-height: unset !important;
    min-height: unset !important;
  }
  .news-template-33 .post-name,
.news-template-33-without-time .post-name {
    margin-top: 16px;
  }
  .news-template-33 .k-tabstrip-content.k-state-active,
.news-template-33 .k-tabstrip > .k-content.k-state-active,
.news-template-33-without-time .k-tabstrip-content.k-state-active,
.news-template-33-without-time .k-tabstrip > .k-content.k-state-active {
    margin-top: 44px;
  }
  .news-template-33 .title-text,
.news-template-33-without-time .title-text {
    margin-bottom: 35px;
  }
  .news-template-33 .dialog-width-images,
.news-template-33-without-time .dialog-width-images {
    width: 40%;
    height: 55%;
    max-width: 90%;
    max-height: 65% !important;
    top: -120px !important;
    margin: auto;
  }
}

.news-template-33-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-33-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-33-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-33-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-33-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-33-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-33-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-33-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-33-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-33-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-33-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-33-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-33-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-33-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-33-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-33-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-33-dialog .float-right {
  float: right;
}

.news-template-33-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-33-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-33-dialog .control {
  margin-bottom: 22px;
}

.news-template-33-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-33-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-33-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-33-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-33-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-33-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-34 .row,
.news-template-34-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-34 .k-dialog,
.news-template-34-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-34 .post-title,
.news-template-34-without-time .post-title {
  display: flex;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  align-items: center;
}
.news-template-34 .title-text,
.news-template-34-without-time .title-text {
  text-align: left;
  line-height: 26px;
  line-height: 158%;
  font-size: 14px;
  color: #A5A5A5;
  margin-top: 12px;
  margin-bottom: 12px;
}
.news-template-34 .post-more,
.news-template-34-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-34 .post-more a,
.news-template-34-without-time .post-more a {
  color: #d6a022;
}
.news-template-34 .pointer,
.news-template-34-without-time .pointer {
  cursor: pointer;
}
.news-template-34 .icon-clock,
.news-template-34-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-34 .title,
.news-template-34-without-time .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #292621;
  margin-bottom: 20px;
  text-decoration: none;
}
.news-template-34 .title-template-type-2,
.news-template-34-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2b2b2b;
  margin-bottom: 20px;
}
.news-template-34 .post-image,
.news-template-34 .post-image-radius,
.news-template-34-without-time .post-image,
.news-template-34-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-34 .post-image-radius,
.news-template-34-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-34 .post-image-default,
.news-template-34-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-34 .post-image-org,
.news-template-34-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-34 .time-clock,
.news-template-34-without-time .time-clock {
  display: flex;
}
.news-template-34 .time-text,
.news-template-34-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-34 .main-item,
.news-template-34-without-time .main-item {
  border-top: 0.7px solid #CACACA;
  width: 100%;
  min-height: 50px;
  border-bottom: 0.7px solid #CACACA;
}
.news-template-34 .vertical-dot,
.news-template-34-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-34 .main-item:hover .vertical-dot,
.news-template-34-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-34 .d-flex,
.news-template-34-without-time .d-flex {
  visibility: visible !important;
}
.news-template-34 .three-dot-area,
.news-template-34-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-34 .menu,
.news-template-34-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 2;
}
.news-template-34 .menu .menu-row,
.news-template-34 .menu .menu-row-current,
.news-template-34 .menu .menu-row-current-border,
.news-template-34-without-time .menu .menu-row,
.news-template-34-without-time .menu .menu-row-current,
.news-template-34-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-34 .menu .menu-row .menu-item-1,
.news-template-34 .menu .menu-row .menu-item-2,
.news-template-34 .menu .menu-row-current .menu-item-1,
.news-template-34 .menu .menu-row-current .menu-item-2,
.news-template-34 .menu .menu-row-current-border .menu-item-1,
.news-template-34 .menu .menu-row-current-border .menu-item-2,
.news-template-34-without-time .menu .menu-row .menu-item-1,
.news-template-34-without-time .menu .menu-row .menu-item-2,
.news-template-34-without-time .menu .menu-row-current .menu-item-1,
.news-template-34-without-time .menu .menu-row-current .menu-item-2,
.news-template-34-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-34-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-34 .menu .menu-row-current,
.news-template-34 .menu .menu-row-current-border,
.news-template-34-without-time .menu .menu-row-current,
.news-template-34-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-34 .menu .menu-item-3,
.news-template-34-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-34 .img-figure,
.news-template-34 .img-figure-no-img,
.news-template-34 .img-figure-template-type-2,
.news-template-34-without-time .img-figure,
.news-template-34-without-time .img-figure-no-img,
.news-template-34-without-time .img-figure-template-type-2 {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-34 .img-figure-template-type-2,
.news-template-34-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-34 .img-figure-2,
.news-template-34-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-34 .post-list,
.news-template-34-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-34 .post-list .k-pager-info,
.news-template-34 .post-list .k-pager-sizes,
.news-template-34-without-time .post-list .k-pager-info,
.news-template-34-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-34 .post-list .k-pager kendo-datapager-prev-buttons,
.news-template-34-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-34 .post-list .k-pager .k-state-selected,
.news-template-34-without-time .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-34 .post-list .top-part,
.news-template-34-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-34 .uppercase,
.news-template-34-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-34 .top-new-item,
.news-template-34-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-34 .top-new-item .title:hover,
.news-template-34-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-34 .close-div,
.news-template-34-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-34 .news-template-34-dialog,
.news-template-34-without-time .news-template-34-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-34 .news-template-34-dialog .close-icon-div,
.news-template-34-without-time .news-template-34-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-34 .news-template-34-dialog .close-icon,
.news-template-34-without-time .news-template-34-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-34 .news-template-34-dialog .title-info-req,
.news-template-34-without-time .news-template-34-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-34 .input-label,
.news-template-34-without-time .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-34 .btn-delete,
.news-template-34-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-34 .btn-cancel,
.news-template-34-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-34 .dialog-width,
.news-template-34-without-time .dialog-width {
  max-width: 400px;
}
.news-template-34 .menus-wrap,
.news-template-34-without-time .menus-wrap {
  display: flex;
  align-items: center;
}
.news-template-34 .item-icon,
.news-template-34-without-time .item-icon {
  margin-right: 27px;
}
.news-template-34 .wrapper,
.news-template-34-without-time .wrapper {
  display: flex;
}
.news-template-34 .menus-item,
.news-template-34-without-time .menus-item {
  background: #FFFFFF;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.news-template-34 .menus-text,
.news-template-34-without-time .menus-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #006CB5;
  text-align: center;
  margin: auto;
  width: fit-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 6px 15px;
}
.news-template-34 .item-box,
.news-template-34-without-time .item-box {
  width: fit-content;
  margin-left: 25px;
  cursor: pointer;
}
.news-template-34 .content,
.news-template-34-without-time .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-template-34 .post-image,
.news-template-34-without-time .post-image {
  width: 100%;
  max-height: 233px;
  min-height: 233px;
  object-fit: cover;
}
.news-template-34 .trending-icon,
.news-template-34-without-time .trending-icon {
  width: 22px;
  height: 22px;
}
.news-template-34 .d-grid,
.news-template-34-without-time .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px 30px;
}
.news-template-34 .k-tabstrip-content,
.news-template-34 .k-tabstrip > .k-content,
.news-template-34-without-time .k-tabstrip-content,
.news-template-34-without-time .k-tabstrip > .k-content {
  padding: 0;
  border: unset;
}
.news-template-34 .k-tabstrip-content.k-state-active,
.news-template-34 .k-tabstrip > .k-content.k-state-active,
.news-template-34-without-time .k-tabstrip-content.k-state-active,
.news-template-34-without-time .k-tabstrip > .k-content.k-state-active {
  margin-top: 56px;
}
.news-template-34 .post-name,
.news-template-34-without-time .post-name {
  margin-top: 28px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22.63px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-template-34 .k-tabstrip-items .k-link,
.news-template-34-without-time .k-tabstrip-items .k-link {
  padding: 6px 12px 13px;
  color: #b6b6b6;
  font-weight: 700;
  line-height: 22.63px;
  font-size: 18px;
}
.news-template-34 .k-tabstrip-items .k-item.k-state-active,
.news-template-34 .k-tabstrip-items .k-item.k-state-selected,
.news-template-34-without-time .k-tabstrip-items .k-item.k-state-active,
.news-template-34-without-time .k-tabstrip-items .k-item.k-state-selected {
  color: #004a9a;
  font-weight: 700;
  border-width: 0 0 3px;
  border-color: #08659a;
  border-style: solid;
}
.news-template-34 .k-tabstrip-items .k-item.k-state-active,
.news-template-34 .k-tabstrip-items .k-item.k-state-selected,
.news-template-34-without-time .k-tabstrip-items .k-item.k-state-active,
.news-template-34-without-time .k-tabstrip-items .k-item.k-state-selected {
  color: #004a9a;
  font-weight: 700;
  border-width: 0 0 3px !important;
  border-color: #08659a;
  border-style: solid;
  margin-bottom: 3px;
}
.news-template-34 .k-tabstrip-items .k-item.k-state-active .k-link,
.news-template-34-without-time .k-tabstrip-items .k-item.k-state-active .k-link {
  color: #004a9a;
}
.news-template-34 .k-tabstrip-items,
.news-template-34-without-time .k-tabstrip-items {
  overflow-x: auto;
}
.news-template-34 .k-tabstrip-items::-webkit-scrollbar,
.news-template-34-without-time .k-tabstrip-items::-webkit-scrollbar {
  height: 3px;
}
.news-template-34 .k-tabstrip-items::-webkit-scrollbar-track,
.news-template-34-without-time .k-tabstrip-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.news-template-34 .k-tabstrip-items::-webkit-scrollbar-thumb,
.news-template-34-without-time .k-tabstrip-items::-webkit-scrollbar-thumb {
  background: #888;
}
.news-template-34 .k-tabstrip-items::-webkit-scrollbar-thumb:hover,
.news-template-34-without-time .k-tabstrip-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.news-template-34 .k-tabstrip-top > .k-tabstrip-items .k-item,
.news-template-34-without-time .k-tabstrip-top > .k-tabstrip-items .k-item {
  border-width: 0px 0px 1px 0px;
}
.news-template-34 .media,
.news-template-34-without-time .media {
  position: relative;
}
.news-template-34 .overlap,
.news-template-34-without-time .overlap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 106px;
  background: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-34 .overlap .number-img .text,
.news-template-34-without-time .overlap .number-img .text {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30.17px;
}
.news-template-34 .overlap .number-img .image,
.news-template-34-without-time .overlap .number-img .image {
  height: 24px;
  width: 24px;
}
.news-template-34 .overlap .number-img .image img,
.news-template-34-without-time .overlap .number-img .image img {
  width: 100%;
  height: 100%;
}
.news-template-34 kendo-tabstrip,
.news-template-34-without-time kendo-tabstrip {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .news-template-34 .d-grid,
.news-template-34-without-time .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-34 .title-text,
.news-template-34-without-time .title-text {
    font-size: 14px !important;
  }
  .news-template-34 .post-name,
.news-template-34-without-time .post-name {
    font-size: 16px !important;
  }
  .news-template-34 .overlap,
.news-template-34-without-time .overlap {
    width: 94px;
  }
}
@media screen and (max-width: 768px) {
  .news-template-34 .d-grid,
.news-template-34-without-time .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-34 .title-text,
.news-template-34-without-time .title-text {
    font-size: 26px !important;
  }
  .news-template-34 .overlap,
.news-template-34-without-time .overlap {
    width: 86px;
  }
  .news-template-34 .wrapper,
.news-template-34-without-time .wrapper {
    display: block !important;
    margin-bottom: 20px;
  }
  .news-template-34 .menus-item,
.news-template-34-without-time .menus-item {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .news-template-34 .d-grid,
.news-template-34-without-time .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .news-template-34 .post-image,
.news-template-34-without-time .post-image {
    max-height: unset !important;
    min-height: unset !important;
  }
  .news-template-34 .post-name,
.news-template-34-without-time .post-name {
    margin-top: 16px;
  }
  .news-template-34 .k-tabstrip-content.k-state-active,
.news-template-34 .k-tabstrip > .k-content.k-state-active,
.news-template-34-without-time .k-tabstrip-content.k-state-active,
.news-template-34-without-time .k-tabstrip > .k-content.k-state-active {
    margin-top: 44px;
  }
  .news-template-34 .title-text,
.news-template-34-without-time .title-text {
    margin-bottom: 35px;
  }
}
@media (max-width: 770px) {
  .news-template-34 .title-text,
.news-template-34-without-time .title-text {
    margin-bottom: 0px !important;
  }
  .news-template-34 .item-box,
.news-template-34-without-time .item-box {
    margin-top: 15px;
  }
}

.news-template-34-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-34-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-34-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-34-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-34-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-34-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-34-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-34-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-34-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-34-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-34-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-34-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-34-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-34-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-34-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-34-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-34-dialog .float-right {
  float: right;
}

.news-template-34-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-34-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-34-dialog .control {
  margin-bottom: 22px;
}

.news-template-34-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-34-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-34-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-34-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-34-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-34-dialog .padding-col {
  padding: 7px 15px 0;
}

.opend-dialog-video {
  max-width: 100% !important;
  max-height: 100% !important;
}

.opend-dialog-video .k-window-content, .k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-dialog-video .close-icon-div {
  position: absolute;
  right: -10px;
  top: -30px;
}

.opend-dialog-video .close-icon {
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
}

.opend-dialog-video .vnpt-upload-img .k-upload {
  display: none;
}

.opend-dialog-video .vnpt-upload-img img {
  width: 160px;
}

.opend-dialog-video .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.opend-dialog-video .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.opend-dialog-video .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .opend-dialog-video.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.opend-dialog-video .custom-btn-addNew {
  margin-top: 10px !important;
}

.opend-dialog-video .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .opend-dialog-video .btn-custom {
  width: 100%;
}

:host ::ng-deep .opend-dialog-video .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.opend-dialog-video .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.opend-dialog-video .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .opend-dialog-video.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .opend-dialog-video .vnpt-upload-img img {
    width: 99px;
  }

  .opend-dialog-video {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
.opend-dialog-video .row-file {
  margin-bottom: 5px;
}

.opend-dialog-video .float-right {
  float: right;
}

.opend-dialog-video .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.opend-dialog-video .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.opend-dialog-video .control {
  margin-bottom: 22px;
}

.opend-dialog-video .input-label {
  margin: 10px;
  margin-left: 15px;
}

.opend-dialog-video .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.opend-dialog-video .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-dialog-video .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-dialog-video .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.opend-dialog-video .padding-col {
  padding: 7px 15px 0;
}

@media screen and (max-width: 768px) {
  .opend-dialog-video .video-box {
    height: 310px !important;
  }
}
.opend-dialog-images {
  max-width: 100% !important;
  max-height: 100% !important;
}
.opend-dialog-images .slider-container {
  position: relative;
}
.opend-dialog-images .category {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  animation-duration: 1s;
  animation-timing-function: linear;
}
.opend-dialog-images .title-right {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.opend-dialog-images .category-box {
  min-width: fit-content;
  margin-right: 10px;
}
.opend-dialog-images .category-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3E3E3E;
}
.opend-dialog-images .show {
  display: block;
}
.opend-dialog-images .close-icon-div {
  position: absolute;
  right: 25px;
  top: -30px;
}
.opend-dialog-images .close-icon {
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
}
.opend-dialog-images .slider {
  display: flex;
  overflow: hidden;
}
.opend-dialog-images .demo {
  opacity: 0.6;
}
.opend-dialog-images .slider img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}
.opend-dialog-images .thumbnail-container {
  margin-top: 10px;
  text-align: center;
  display: flex;
}
.opend-dialog-images .thumbnails img {
  width: 60px;
  height: auto;
  cursor: pointer;
  margin-right: 5px;
  transition: opacity 0.3s ease-in-out;
}
.opend-dialog-images .thumbnails img:hover {
  opacity: 0.7;
}
.opend-dialog-images button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.opend-dialog-images button:nth-of-type(1) {
  left: 10px;
}
.opend-dialog-images button:nth-of-type(2) {
  right: 10px;
}
.opend-dialog-images * {
  box-sizing: border-box;
}
.opend-dialog-images .img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  opacity: 0.6;
}
.opend-dialog-images .cursor {
  cursor: pointer;
}
.opend-dialog-images .prev {
  cursor: pointer;
  position: fixed;
  top: 55%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  height: 60px;
  color: white;
  left: 50px;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
.opend-dialog-images .next {
  cursor: pointer;
  position: fixed;
  top: 55%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  height: 60px;
  color: white;
  right: 50px;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
.opend-dialog-images .title-right::-webkit-scrollbar {
  height: 10px;
}
.opend-dialog-images .title-right::-webkit-scrollbar-thumb {
  background: #000000;
}
.opend-dialog-images .title-right::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.opend-dialog-images .prev:hover,
.opend-dialog-images .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.opend-dialog-images .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.opend-dialog-images .caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}
.opend-dialog-images .row:after {
  content: "";
  display: table;
  clear: both;
}
.opend-dialog-images .column {
  float: left;
  width: 16.66%;
}
.opend-dialog-images .demo {
  opacity: 0.6;
}
.opend-dialog-images .active,
.opend-dialog-images .demo:hover {
  opacity: 1;
}
.opend-dialog-images .opend-dialog-images .close-icon-div {
  position: absolute;
  right: 25px;
  top: -30px;
}
.opend-dialog-images .opend-dialog-images .close-icon {
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
}
.opend-dialog-images .opend-dialog-images .vnpt-upload-img .k-upload {
  display: none;
}
.opend-dialog-images .opend-dialog-images .vnpt-upload-img img {
  width: 160px;
}
.opend-dialog-images .opend-dialog-images .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}
.opend-dialog-images .opend-dialog-images .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.opend-dialog-images .opend-dialog-images .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}
@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
.opend-dialog-images :host ::ng-deep .opend-dialog-images.x-dialog {
  max-width: 768px;
  max-height: 565px;
}
.opend-dialog-images .opend-dialog-images .custom-btn-addNew {
  margin-top: 10px !important;
}
.opend-dialog-images .opend-dialog-images .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}
.opend-dialog-images :host ::ng-deep .opend-dialog-images .btn-custom {
  width: 100%;
}
.opend-dialog-images :host ::ng-deep .opend-dialog-images .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}
.opend-dialog-images .opend-dialog-images .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.opend-dialog-images .opend-dialog-images .selected-lang {
  border-bottom: 3px red solid;
}
@media (max-width: 600px) {
  .opend-dialog-images :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }
  .opend-dialog-images :host ::ng-deep .opend-dialog-images.x-dialog {
    max-width: 768px;
    max-height: none;
  }
  .opend-dialog-images .opend-dialog-images .vnpt-upload-img img {
    width: 99px;
  }
  .opend-dialog-images .opend-dialog-images {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
.opend-dialog-images .opend-dialog-images .row-file {
  margin-bottom: 5px;
}
.opend-dialog-images .opend-dialog-images .float-right {
  float: right;
}
.opend-dialog-images .opend-dialog-images .border-infor {
  background: #ffffff;
  border: 0.6px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}
.opend-dialog-images .opend-dialog-images .label-infor {
  color: #407bff;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.opend-dialog-images .opend-dialog-images .control {
  margin-bottom: 22px;
}
.opend-dialog-images .opend-dialog-images .input-label {
  margin: 10px;
  margin-left: 15px;
}
.opend-dialog-images .opend-dialog-images .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}
.opend-dialog-images .opend-dialog-images .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}
.opend-dialog-images .opend-dialog-images .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}
.opend-dialog-images .opend-dialog-images .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}
.opend-dialog-images .opend-dialog-images .padding-col {
  padding: 7px 15px 0;
}
.opend-dialog-images .opend-dialog-images .video-container {
  position: relative;
  width: 100%;
  height: 600px;
}
.opend-dialog-images .opend-dialog-images .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.opend-dialog-images .slideshow-container {
  height: 350px;
}
@media screen and (max-width: 770px) {
  .opend-dialog-images .slideshow-container {
    height: 160px;
  }
}

.opend-30-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
}

.opend-30-dialog .k-scrollview-next,
.opend-30-dialog .k-scrollview-prev {
  display: table;
  position: fixed !important;
  padding: 40px !important;
  height: 60%;
  top: 20%;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.opend-30-dialog .img-scrollview {
  width: 1141px;
  height: 580px;
  overflow: hidden !important;
  object-fit: cover;
}

.dialogimage2 .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .k-scrollview-pageable,
.opend-30-dialog .k-scrollview-nav {
  margin: 0;
  padding: 20px;
  max-width: 100%;
  box-sizing: border-box;
  line-height: 0;
  text-align: center;
  white-space: nowrap;
  list-style: none;
  display: inline-flex;
  align-items: center;
  overflow-x: scroll;
  display: none !important;
  overflow-y: hidden;
  pointer-events: initial;
}

.image-list {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  justify-content: center !important;
  height: 134px;
}

.opend-30-dialog .image-list img {
  width: 165px;
  height: 134px;
  margin-right: 30px;
  overflow: hidden;
}

.opend-30-dialog .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .k-scrollview-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 10;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: -50px;
}

.opend-30-dialog .k-window-content,
.k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.opend-30-dialog .close-icon-div {
  position: absolute;
  right: -10px;
  top: -30px;
}

.opend-30-dialog .close-icon {
  width: 30px;
  height: 30px;
  position: fixed;
  cursor: pointer;
  z-index: 555555;
  right: 16%;
  top: 13%;
}

.imgItem {
  width: 100%;
  height: 100%;
}

.opend-30-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.opend-30-dialog .vnpt-upload-img img {
  width: 160px;
}

.opend-30-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.opend-30-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.opend-30-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .opend-30-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.opend-30-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.opend-30-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .opend-30-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .opend-30-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.opend-30-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.opend-30-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .opend-30-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .opend-30-dialog .vnpt-upload-img img {
    width: 99px;
  }

  .opend-30-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
.opend-30-dialog .row-file {
  margin-bottom: 5px;
}

.opend-30-dialog .float-right {
  float: right;
}

.opend-30-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.opend-30-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.opend-30-dialog .control {
  margin-bottom: 22px;
}

.opend-30-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.opend-30-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.opend-30-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-30-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.opend-30-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.opend-30-dialog .padding-col {
  padding: 7px 15px 0;
}

.opend-30-dialog .video-container {
  position: relative;
  width: 1141px;
  height: 647px;
}

.opend-30-dialog .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-slider-customer-service .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-slider-customer-service .banner {
  width: 100% !important;
}
.image-slider-customer-service .k-dialog {
  height: auto !important;
  width: auto !important;
}
.image-slider-customer-service kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.image-slider-customer-service .k-button {
  border-radius: 0%;
  width: 58px;
  height: 6px;
}
.image-slider-customer-service .k-primary {
  background: #006CB5 !important;
  border: #006CB5 !important;
}
.image-slider-customer-service .k-scrollview-next,
.image-slider-customer-service .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute !important;
  padding: 0 !important;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-slider-customer-service .k-scrollview-next .k-icon,
.image-slider-customer-service .k-scrollview-prev .k-icon {
  font-size: 30px;
  text-align: center;
}
.image-slider-customer-service .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.image-slider-customer-service .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-slider-customer-service .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.image-slider-customer-service .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.image-slider-customer-service .vertical-dot .menu .menu-row,
.image-slider-customer-service .vertical-dot .menu .menu-row-current,
.image-slider-customer-service .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.image-slider-customer-service .vertical-dot .menu .menu-row .menu-item-1,
.image-slider-customer-service .vertical-dot .menu .menu-row .menu-item-2,
.image-slider-customer-service .vertical-dot .menu .menu-row-current .menu-item-1,
.image-slider-customer-service .vertical-dot .menu .menu-row-current .menu-item-2,
.image-slider-customer-service .vertical-dot .menu .menu-row-current-border .menu-item-1,
.image-slider-customer-service .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.image-slider-customer-service .vertical-dot .menu .menu-row-current,
.image-slider-customer-service .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.image-slider-customer-service .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.image-slider-customer-service .banner-container:hover .vertical-dot {
  display: flex;
}
.image-slider-customer-service .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.image-slider-customer-service .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.image-slider-customer-service .title-info-req {
  color: #004A9A;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.image-slider-customer-service .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.image-slider-customer-service .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-customer-service .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.image-slider-customer-service .employe-detail {
  margin-top: 10px;
}
.image-slider-customer-service .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.image-slider-customer-service .close-div {
  display: flex;
  margin-top: 3px;
}
.image-slider-customer-service .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.image-slider-customer-service .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.image-slider-customer-service .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .image-slider-customer-service .router-outlet-content {
    width: 100% !important;
  }
  .image-slider-customer-service .router-outlet-content-gallery {
    width: 100% !important;
  }
}

.image-slider-customer-service:hover .vertical-dot {
  visibility: visible;
}

.customer-care {
  margin-left: 200px;
}
.customer-care input.k-input {
  width: 100% !important;
  height: 50px !important;
  border-radius: 3px !important;
  padding-left: 19px !important;
}
.customer-care .auto {
  margin: auto;
}
.customer-care input, .customer-care button, .customer-care select, .customer-care optgroup, .customer-care textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 20px;
  width: 20px;
}
.customer-care .checkboxFour {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.customer-care .checkboxFour label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.customer-care .checkboxFour input[type=checkbox]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.customer-care .checkboxFour1 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.customer-care .checkboxFour1 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.customer-care .checkboxFour1 input[type=checkbox1]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.customer-care .checkboxFour2 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.customer-care .checkboxFour2 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.customer-care .checkboxFour2 input[type=checkbox2]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.customer-care .checkboxFive label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  background: #eee;
  border: 1px solid #ddd;
}
.customer-care .checkboxFive label:after {
  opacity: 0.2;
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 6px;
  left: 7px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.customer-care .checkboxFive label:hover::after {
  opacity: 0.5;
}
.customer-care .checkboxFive input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.customer-care .k-input::placeholder,
.customer-care .k-input::placeholder {
  color: #B9B9B9;
}
.customer-care .k-i-close {
  height: 100%;
}
.customer-care .text-image {
  margin-top: 20px;
  align-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  display: flex;
  line-height: 19px;
  color: #565656;
}
.customer-care .titile {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #343434;
}
.customer-care .quiz-title {
  margin-top: 30px;
  margin-bottom: 40px;
  color: #000000;
  margin-left: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.customer-care .quiz-wrapper {
  display: flex;
}
.customer-care .quiz-drop {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.customer-care .round-checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.customer-care .button {
  width: 249px;
  height: 5px;
  left: 60px;
  top: 109px;
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.customer-care hr {
  margin: auto;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.customer-care .round-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}
.customer-care .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #006CB5;
}
.customer-care .text-not-title {
  height: 40px;
  position: relative;
  top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  line-height: 19px;
  color: #FF0000;
}
.customer-care .round-checkbox input:checked + .checkmark {
  background-color: #2196f3;
}
.customer-care .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.customer-care .round-checkbox input:checked + .checkmark:after {
  display: block;
}
.customer-care .round-checkbox .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.customer-care .checkmark-text {
  height: 20px;
  width: 20px;
  border: 1px solid #C2C2C2;
}
.customer-care .round-checkbox input:checked + .checkmark {
  background-color: #2196f3;
}
.customer-care .checkmark-text:after {
  content: "";
  position: absolute;
  display: none;
}
.customer-care .round-checkbox input:checked + .checkmark-text:after {
  display: block;
}
.customer-care .round-checkbox .checkmark-text:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  background: white;
}
.customer-care .k-combobox {
  border: 1px solid #C8C8C8 !important;
  width: 100%;
  height: 50px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.customer-care .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.customer-care .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.customer-care .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.customer-care .vertical-dot .menu .menu-row,
.customer-care .vertical-dot .menu .menu-row-current,
.customer-care .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.customer-care .vertical-dot .menu .menu-row .menu-item-1,
.customer-care .vertical-dot .menu .menu-row .menu-item-2,
.customer-care .vertical-dot .menu .menu-row-current .menu-item-1,
.customer-care .vertical-dot .menu .menu-row-current .menu-item-2,
.customer-care .vertical-dot .menu .menu-row-current-border .menu-item-1,
.customer-care .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.customer-care .vertical-dot .menu .menu-row-current,
.customer-care .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.customer-care .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.customer-care .d-flex {
  visibility: visible !important;
}
.customer-care .quiz-inputs {
  width: 33%;
  margin-right: 68px;
}
.customer-care .quiz-input-item,
.customer-care .quiz-input-item-captcha {
  margin-bottom: 15px;
}
.customer-care .quiz-input-item-captcha {
  display: flex;
}
.customer-care .quiz-textarea-item,
.customer-care .quiz-button-item {
  width: 100%;
  flex-wrap: wrap;
  height: 70px;
}
.customer-care .quiz-textarea-item .k-upload,
.customer-care .quiz-button-item .k-upload {
  display: none;
}
.customer-care .quiz-button-item {
  margin-top: 10px;
}
.customer-care .button-image {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 40px;
  background: #006CB5;
  flex: none;
  order: 0;
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 16px;
  width: auto;
  line-height: 20px;
  display: flex;
  flex-grow: 0;
  border: none;
  margin-left: 20px;
}
.customer-care .k-textbox {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.customer-care .k-textbox::placeholder,
.customer-care .k-textarea::placeholder {
  color: #B9B9B9;
}
.customer-care .k-textarea {
  width: 100%;
  height: 197px;
  resize: none;
  border: 1px solid #C8C8C8;
  padding-left: 19px;
  padding-top: 19px;
}
.customer-care .quiz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.customer-care .quiz-form-button {
  min-width: 169px;
  font-size: 18px;
  padding: 8px;
  border: none;
  background-color: #00396B;
  color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}
.customer-care .quiz-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.customer-care .quiz-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-right: 13px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #D6A022;
}
.customer-care .quiz-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #5E5E5E;
}
.customer-care .quiz-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.customer-care .send-background {
  background: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
}
.customer-care .k-tabstrip-items {
  background-color: #027DC3;
  justify-content: normal;
}
.customer-care .k-tabstrip-items .k-item {
  color: white;
  border: none;
}
.customer-care .k-tabstrip-items .k-item.k-state-active {
  color: white;
  background-color: #03598A;
  border: none;
}
.customer-care .k-tabstrip-items .k-link {
  padding: 14px 26px;
}
.customer-care .k-tabstrip > .k-content {
  padding: 0;
}
.customer-care .label-text {
  display: flex;
  align-items: center;
  margin: 0px 10px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 25px;
  color: #000000;
}
.customer-care .label-text1 {
  display: flex;
  align-items: center;
  margin: 0px 10px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-left: -30px;
}
.customer-care .form-row-checkbox {
  display: flex;
  width: 100%;
  margin: 15px 0px 15px 0px;
}
.customer-care .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.customer-care .quiz-form {
  padding-left: 15px;
}
.customer-care .data-title {
  color: #414141;
  display: flex;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353535;
  align-items: center;
  height: 40px;
}
.customer-care .required {
  color: #EE2323;
  padding-left: 3px;
}
.customer-care .add-image-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #EE4623;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.customer-care .image-upload,
.customer-care .add-image {
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.customer-care .add-image {
  border: 1px solid #C8C8C8;
}
.customer-care .captcha-item {
  background-color: white;
  height: 50px;
  margin-left: 40px;
  border: 1px solid #C8C8C8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background: #E3E3E3;
}
.customer-care .reload-captcha-btn {
  width: 50px;
  height: 50px;
  object-fit: cover;
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.customer-care .reload-icon {
  height: 16px;
  width: 16px;
}
.customer-care .quiz-submit-button {
  min-width: 169px;
  border: none;
  float: right;
  padding: 11px 10px;
  height: 50px;
  text-transform: uppercase;
  background: #006CB5;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 3px;
}
.customer-care .quiz-reset-button {
  min-width: 169px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  color: #727272;
  border-radius: 3px;
  margin-left: 20px;
}
.customer-care .k-content.k-state-active {
  border: none;
  overflow: hidden;
}
.customer-care .categories-box {
  width: 100%;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 144, 200, 0.84);
  margin-bottom: 20px;
  margin-top: 20px;
}
.customer-care .image-categories {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.customer-care .categories-text {
  position: absolute;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
}
.customer-care .categories-area {
  padding-left: 20px;
  margin-top: 20px;
}
.customer-care .header-area,
.customer-care .footer-area {
  padding: 0 !important;
}
.customer-care .wrapper-area-flex {
  display: flex;
  justify-content: center;
}
.customer-care .col-md-6 {
  flex: 0 0 auto;
  width: 48%;
}
.customer-care .col-md-9 {
  flex: 0 0 auto;
  width: 73%;
}
@media screen and (max-width: 600px) {
  .customer-care .col-md-6 {
    flex: 0 0 auto;
    width: 47%;
    margin-right: 10px;
  }
  .customer-care .col-md-11 {
    flex: 0 0 auto;
    width: 47%;
  }
  .customer-care .quiz-form {
    padding: 0 15px;
  }
  .customer-care .form-row {
    display: block;
  }
  .customer-care .quiz-input-item {
    width: 100%;
  }
  .customer-care .quiz-textarea-item {
    width: 100%;
    display: block;
  }
  .customer-care .quiz-input-item-captcha {
    display: block;
  }
  .customer-care .captcha-item {
    margin-left: 0;
    margin-top: 10px;
  }
  .customer-care .reload-captcha-btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .customer-care .quiz-button-item {
    flex-direction: column;
    width: 100%;
  }
  .customer-care .quiz-reset-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 770px) {
  .customer-care .row {
    margin-left: 0px !important;
  }
  .customer-care .col-md-11 {
    flex: 0 0 auto;
    width: 47%;
  }
  .customer-care .categories-area {
    margin-top: 20px;
  }
  .customer-care .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: var(--bs-gutter-y);
  }
  .customer-care .k-combobox {
    border: 1px solid #C8C8C8 !important;
    width: 100%;
    height: 50px;
    border-width: 0;
    box-sizing: border-box;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    text-align: left;
    white-space: nowrap;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .customer-care .col-md-9 {
    flex: 0 0 auto;
    width: 97% !important;
  }
  .customer-care .text-not-title {
    height: 40px;
    position: relative;
    top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    line-height: 19px;
    margin-bottom: 15px;
    color: #FF0000;
  }
  .customer-care .form-row {
    display: flex;
    width: 96%;
    justify-content: space-between;
  }
  .customer-care .titile {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
    line-height: 29px;
    color: #343434;
  }
  .customer-care .text-image {
    margin-top: 20px;
    align-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    display: flex;
    line-height: 19px;
    margin-left: 0px;
    color: #565656;
    margin-right: 0px;
  }
  .customer-care .quiz-title {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #000000;
    margin-left: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}
.customer-care .form-row-mb {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.customer-care .quiz-submit-button {
  min-width: 169px;
  border: none;
  float: right;
  padding: 11px 10px;
  height: 50px;
  text-transform: uppercase;
  background: #006CB5;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 3px;
}

.water-bill-information {
  margin-left: 200px;
}
.water-bill-information .wb-water-bill-information {
  display: block;
}
.water-bill-information .mb-water-bill-information {
  display: none !important;
}
.water-bill-information input.k-input {
  width: 100% !important;
  height: 50px !important;
  border-radius: 3px !important;
  padding-left: 19px !important;
}
.water-bill-information .checkboxFour {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.water-bill-information .checkboxFour label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.water-bill-information .checkboxFour input[type=checkbox]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.water-bill-information .checkboxFour1 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.water-bill-information .checkboxFour1 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.water-bill-information .checkboxFour1 input[type=checkbox1]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.water-bill-information .checkboxFour2 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.water-bill-information .checkboxFour2 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.water-bill-information .checkboxFour2 input[type=checkbox2]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.water-bill-information .k-input::placeholder,
.water-bill-information .k-input::placeholder {
  color: #B9B9B9;
}
.water-bill-information .auto {
  margin: auto;
}
.water-bill-information .k-i-close {
  height: 100%;
}
.water-bill-information .text-image {
  margin-top: 20px;
  align-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  display: flex;
  line-height: 19px;
  color: #565656;
}
.water-bill-information .titile {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #343434;
}
.water-bill-information .quiz-title {
  margin-top: 30px;
  margin-bottom: 40px;
  color: #000000;
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.water-bill-information .quiz-wrapper {
  display: flex;
}
.water-bill-information .quiz-drop {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.water-bill-information .round-checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.water-bill-information .button {
  width: 249px;
  height: 5px;
  left: 60px;
  top: 109px;
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.water-bill-information hr {
  margin: auto;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.water-bill-information .round-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}
.water-bill-information .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #006CB5;
}
.water-bill-information .text-not-title {
  height: 40px;
  position: relative;
  top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  line-height: 19px;
  color: #FF0000;
}
.water-bill-information .round-checkbox input:checked + .checkmark {
  background-color: #2196f3;
}
.water-bill-information .k-grid td {
  padding: 8px 8px !important;
  border-style: solid;
  border-color: #CFCFCF !important;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.water-bill-information .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.water-bill-information .round-checkbox input:checked + .checkmark:after {
  display: block;
}
.water-bill-information .round-checkbox .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.water-bill-information .checkmark-text {
  height: 20px;
  width: 20px;
  border: 1px solid #C2C2C2;
}
.water-bill-information .round-checkbox input:checked + .checkmark {
  background-color: #2196f3;
}
.water-bill-information .checkmark-text:after {
  content: "";
  position: absolute;
  display: none;
}
.water-bill-information .round-checkbox input:checked + .checkmark-text:after {
  display: block;
}
.water-bill-information .round-checkbox .checkmark-text:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  background: white;
}
.water-bill-information .k-combobox {
  border: 1px solid #C8C8C8 !important;
  width: 100%;
  height: 50px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.water-bill-information .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.water-bill-information .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.water-bill-information .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.water-bill-information .vertical-dot .menu .menu-row,
.water-bill-information .vertical-dot .menu .menu-row-current,
.water-bill-information .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.water-bill-information .vertical-dot .menu .menu-row .menu-item-1,
.water-bill-information .vertical-dot .menu .menu-row .menu-item-2,
.water-bill-information .vertical-dot .menu .menu-row-current .menu-item-1,
.water-bill-information .vertical-dot .menu .menu-row-current .menu-item-2,
.water-bill-information .vertical-dot .menu .menu-row-current-border .menu-item-1,
.water-bill-information .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.water-bill-information .vertical-dot .menu .menu-row-current,
.water-bill-information .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.water-bill-information .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.water-bill-information .d-flex {
  visibility: visible !important;
}
.water-bill-information .quiz-inputs {
  width: 33%;
  margin-right: 68px;
}
.water-bill-information .quiz-input-item,
.water-bill-information .quiz-input-item-captcha {
  margin-bottom: 15px;
}
.water-bill-information .quiz-input-item-captcha {
  display: flex;
}
.water-bill-information .quiz-textarea-item,
.water-bill-information .quiz-button-item {
  width: 100%;
  flex-wrap: wrap;
  height: 70px;
}
.water-bill-information .quiz-textarea-item .k-upload,
.water-bill-information .quiz-button-item .k-upload {
  display: none;
}
.water-bill-information .quiz-button-item {
  margin-top: 40px;
  margin-left: 17px;
}
.water-bill-information .button-image {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 40px;
  background: #006CB5;
  flex: none;
  order: 0;
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-grow: 0;
  border: none;
  margin-left: 20px;
}
.water-bill-information .image-null {
  width: 33%;
  margin-bottom: 50px;
  margin-top: 50px;
}
.water-bill-information .k-textbox {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.water-bill-information .k-textbox::placeholder,
.water-bill-information .k-textarea::placeholder {
  color: #B9B9B9;
}
.water-bill-information .k-textarea {
  width: 100%;
  height: 197px;
  resize: none;
  border: 1px solid #C8C8C8;
  padding-left: 19px;
  padding-top: 19px;
}
.water-bill-information .quiz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.water-bill-information .quiz-form-button {
  min-width: 169px;
  font-size: 18px;
  padding: 8px;
  border: none;
  background-color: #00396B;
  color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}
.water-bill-information .quiz-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.water-bill-information .quiz-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-right: 13px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #D6A022;
}
.water-bill-information .quiz-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #5E5E5E;
}
.water-bill-information .quiz-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.water-bill-information .send-background {
  background: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
}
.water-bill-information .k-tabstrip-items {
  background-color: #027DC3;
  justify-content: normal;
}
.water-bill-information .k-tabstrip-items .k-item {
  color: white;
  border: none;
}
.water-bill-information .k-tabstrip-items .k-item.k-state-active {
  color: white;
  background-color: #03598A;
  border: none;
}
.water-bill-information .k-tabstrip-items .k-link {
  padding: 14px 26px;
}
.water-bill-information .k-tabstrip > .k-content {
  padding: 0;
}
.water-bill-information .label-text {
  display: flex;
  align-items: center;
  margin: 0px 10px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 25px;
  color: #000000;
}
.water-bill-information .form-row-checkbox {
  display: flex;
  width: 100%;
  margin: 15px 0px 15px 0px;
}
.water-bill-information .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.water-bill-information .center {
  text-align: center;
  margin-bottom: 100px;
}
.water-bill-information .text-null {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: #353535;
}
.water-bill-information .data-title {
  color: #414141;
  display: flex;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353535;
  align-items: center;
  height: 40px;
}
.water-bill-information .required {
  color: #EE2323;
  padding-left: 3px;
}
.water-bill-information .k-grid-header,
.water-bill-information .k-grouping-header,
.water-bill-information .k-grid-add-row,
.water-bill-information .k-grid-footer {
  color: #FFFFFF;
  background-color: #006CB5;
}
.water-bill-information .k-grid-header .k-header {
  position: relative;
  vertical-align: bottom;
  cursor: default;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border-color: white;
  text-align: center;
}
.water-bill-information .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 1px 3px;
  white-space: nowrap;
}
.water-bill-information .k-grid th,
.water-bill-information .k-grid td {
  padding: 8px 12px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.water-bill-information .add-image-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #EE4623;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.water-bill-information .padding-top {
  margin-top: 30px;
  margin-bottom: 30px;
}
.water-bill-information .image-upload,
.water-bill-information .add-image {
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.water-bill-information .add-image {
  border: 1px solid #C8C8C8;
}
.water-bill-information .captcha-item {
  background-color: white;
  height: 50px;
  margin-left: 40px;
  border: 1px solid #C8C8C8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background: #E3E3E3;
}
.water-bill-information .reload-captcha-btn {
  width: 50px;
  height: 50px;
  object-fit: cover;
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.water-bill-information .reload-icon {
  height: 16px;
  width: 16px;
}
.water-bill-information .quiz-submit-button {
  min-width: 169px;
  border: none;
  float: right;
  padding: 11px 10px;
  height: 50px;
  text-transform: uppercase;
  background: #006CB5;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 3px;
}
.water-bill-information .quiz-reset-button {
  min-width: 169px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  color: #727272;
  border-radius: 3px;
  margin-left: 20px;
}
.water-bill-information .k-content.k-state-active {
  border: none;
  overflow: hidden;
}
.water-bill-information .text-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 5px 0px 10px 0px;
  color: #006CB5;
}
.water-bill-information .text-header-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 15px 0px 15px 0px;
}
.water-bill-information .text-header-3 {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 15px 0px 15px 5px;
}
.water-bill-information .categories-box {
  width: 100%;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 144, 200, 0.84);
  margin-bottom: 20px;
  margin-top: 20px;
}
.water-bill-information .image-categories {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.water-bill-information .categories-text {
  position: absolute;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
}
.water-bill-information .categories-area {
  padding-left: 20px;
  margin-top: 20px;
}
.water-bill-information .header-area,
.water-bill-information .footer-area {
  padding: 0 !important;
}
.water-bill-information .wrapper-area-flex {
  display: flex;
  justify-content: center;
}
.water-bill-information .col-md-6 {
  flex: 0 0 auto;
  width: 48%;
}
.water-bill-information .col-md-9 {
  flex: 0 0 auto;
  width: 73%;
}
@media screen and (max-width: 600px) {
  .water-bill-information .col-md-6 {
    flex: 0 0 auto;
    width: 48%;
  }
  .water-bill-information .quiz-form {
    padding: 0 15px;
  }
  .water-bill-information .form-row {
    display: block;
  }
  .water-bill-information .quiz-input-item {
    width: 100%;
  }
  .water-bill-information .quiz-textarea-item {
    width: 100%;
    display: block;
  }
  .water-bill-information .quiz-input-item-captcha {
    display: block;
  }
  .water-bill-information .captcha-item {
    margin-left: 0;
    margin-top: 10px;
  }
  .water-bill-information .reload-captcha-btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .water-bill-information .quiz-button-item {
    flex-direction: column;
    width: 100%;
  }
  .water-bill-information .quiz-reset-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 770px) {
  .water-bill-information .row {
    margin-left: 0px !important;
  }
  .water-bill-information .categories-area {
    margin-top: 20px;
  }
  .water-bill-information .row > * {
    flex-shrink: 0;
    width: 99%;
    max-width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: var(--bs-gutter-y);
  }
  .water-bill-information .col-md-9 {
    flex: 0 0 auto;
    width: 97%;
  }
  .water-bill-information .text-not-title {
    height: 40px;
    position: relative;
    top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    line-height: 19px;
    margin-bottom: 15px;
    color: #FF0000;
  }
  .water-bill-information .form-row {
    display: flex;
    width: 96%;
    justify-content: space-between;
  }
  .water-bill-information .titile {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
    line-height: 29px;
    color: #343434;
  }
  .water-bill-information .text-image {
    margin-top: 20px;
    align-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    display: flex;
    line-height: 19px;
    margin-left: 0px;
    color: #565656;
    margin-right: 0px;
  }
  .water-bill-information .quiz-title {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #000000;
    margin-left: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .water-bill-information .form-row-mb {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }
  .water-bill-information .quiz-submit-button {
    min-width: 169px;
    border: none;
    float: right;
    padding: 11px 10px;
    height: 50px;
    text-transform: uppercase;
    background: #006CB5;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 3px;
  }
  .water-bill-information .send-background {
    background: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px !important;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  }
  .water-bill-information .wb-water-bill-information {
    display: none !important;
  }
  .water-bill-information .mb-water-bill-information {
    display: block !important;
  }
  .water-bill-information .mb-water-bill-information .item-menu {
    margin: 10px 15px 10px 15px !important;
    margin: 7px 0;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
  }
  .water-bill-information .mb-water-bill-information .mb-drbtn-func {
    padding-right: 5px;
  }
  .water-bill-information .mb-water-bill-information .btn-custom button {
    background: none;
    color: gray;
    width: 100%;
    border: none;
  }
  .water-bill-information .mb-water-bill-information {
    display: none;
  }
  .water-bill-information .mb-water-bill-information .k-list {
    width: 150px;
  }
  .water-bill-information .mb-water-bill-information .item-menu {
    margin: 10px 0px 10px 0px !important;
    margin: 7px 0;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    width: 100%;
  }
}

.file-settlemen {
  margin-left: 200px;
}
.file-settlemen .wb-water-bill-information {
  display: block;
}
.file-settlemen .mb-water-bill-information {
  display: none !important;
}
.file-settlemen input,
.file-settlemen button,
.file-settlemen select,
.file-settlemen optgroup,
.file-settlemen textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  display: block;
  line-height: inherit;
}
.file-settlemen .checkboxFour {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.file-settlemen .checkboxx {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 20px;
  width: 20px;
}
.file-settlemen .checkboxFour label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.file-settlemen .checkboxFour input[type=checkbox]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.file-settlemen .checkboxFour1 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.file-settlemen .checkboxFour1 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.file-settlemen .checkboxFour1 input[type=checkbox1]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.file-settlemen .checkboxFour2 {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #006CB5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.file-settlemen .checkboxFour2 label {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
}
.file-settlemen .checkboxFour2 input[type=checkbox2]:checked + label {
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.file-settlemen input.k-input {
  width: 100% !important;
  height: 50px !important;
  border-radius: 3px !important;
  padding-left: 19px !important;
}
.file-settlemen .k-input::placeholder,
.file-settlemen .k-input::placeholder {
  color: #B9B9B9;
}
.file-settlemen .auto {
  margin: auto;
}
.file-settlemen .k-i-close {
  height: 100%;
}
.file-settlemen .bodder-settlemen {
  background: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #C8C8C8;
}
.file-settlemen .margin-bodder {
  margin: 20px;
}
.file-settlemen .text-image {
  margin-top: 20px;
  align-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  display: flex;
  line-height: 19px;
  color: #565656;
}
.file-settlemen .titile {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #343434;
}
.file-settlemen .quiz-title {
  margin-top: 30px;
  margin-bottom: 40px;
  color: #000000;
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.file-settlemen .quiz-wrapper {
  display: flex;
}
.file-settlemen .quiz-drop {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
}
.file-settlemen .round-checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.file-settlemen .button {
  width: 249px;
  height: 5px;
  left: 60px;
  top: 109px;
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
}
.file-settlemen hr {
  margin: auto;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.file-settlemen .round-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}
.file-settlemen .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #006CB5;
}
.file-settlemen .text-not-title {
  height: 40px;
  position: relative;
  top: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 15px;
  line-height: 19px;
  color: #FF0000;
}
.file-settlemen .k-grid td {
  padding: 8px 8px !important;
  border-style: solid;
  border-color: #CFCFCF !important;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-settlemen .k-combobox {
  border: 1px solid #C8C8C8 !important;
  width: 100%;
  height: 50px;
  border-width: 0;
  box-sizing: border-box;
  outline: 0;
  background: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  text-align: left;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.file-settlemen .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.file-settlemen .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.file-settlemen .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.file-settlemen .vertical-dot .menu .menu-row,
.file-settlemen .vertical-dot .menu .menu-row-current,
.file-settlemen .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.file-settlemen .vertical-dot .menu .menu-row .menu-item-1,
.file-settlemen .vertical-dot .menu .menu-row .menu-item-2,
.file-settlemen .vertical-dot .menu .menu-row-current .menu-item-1,
.file-settlemen .vertical-dot .menu .menu-row-current .menu-item-2,
.file-settlemen .vertical-dot .menu .menu-row-current-border .menu-item-1,
.file-settlemen .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.file-settlemen .vertical-dot .menu .menu-row-current,
.file-settlemen .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.file-settlemen .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.file-settlemen .d-flex {
  visibility: visible !important;
}
.file-settlemen .quiz-inputs {
  width: 33%;
  margin-right: 68px;
}
.file-settlemen .quiz-input-item,
.file-settlemen .quiz-input-item-captcha {
  margin-bottom: 15px;
}
.file-settlemen .quiz-input-item-captcha {
  display: flex;
}
.file-settlemen .quiz-textarea-item,
.file-settlemen .quiz-button-item {
  width: 100%;
  flex-wrap: wrap;
  height: 70px;
}
.file-settlemen .quiz-textarea-item .k-upload,
.file-settlemen .quiz-button-item .k-upload {
  display: none;
}
.file-settlemen .quiz-button-item {
  margin-top: 40px;
}
.file-settlemen .button-image {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 40px;
  background: #006CB5;
  flex: none;
  order: 0;
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-grow: 0;
  border: none;
  margin-left: 20px;
}
.file-settlemen .image-null {
  width: 33%;
  margin-bottom: 50px;
  margin-top: 50px;
}
.file-settlemen .k-textbox {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 19px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.file-settlemen .k-textbox::placeholder,
.file-settlemen .k-textarea::placeholder {
  color: #B9B9B9;
}
.file-settlemen .k-textarea {
  width: 100%;
  height: 197px;
  resize: none;
  border: 1px solid #C8C8C8;
  padding-left: 19px;
  padding-top: 19px;
}
.file-settlemen .quiz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.file-settlemen .quiz-form-button {
  min-width: 169px;
  font-size: 18px;
  padding: 8px;
  border: none;
  background-color: #00396B;
  color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}
.file-settlemen .quiz-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}
.file-settlemen .quiz-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  margin-right: 13px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #D6A022;
}
.file-settlemen .quiz-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: #5E5E5E;
}
.file-settlemen .quiz-error {
  color: #d51923;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.file-settlemen .send-background {
  background: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
}
.file-settlemen .k-tabstrip-items {
  background-color: #027DC3;
  justify-content: normal;
}
.file-settlemen .k-tabstrip-items .k-item {
  color: white;
  border: none;
}
.file-settlemen .k-tabstrip-items .k-item.k-state-active {
  color: white;
  background-color: #03598A;
  border: none;
}
.file-settlemen .k-tabstrip-items .k-link {
  padding: 14px 26px;
}
.file-settlemen .k-tabstrip > .k-content {
  padding: 0;
}
.file-settlemen .label-text {
  display: flex;
  align-items: center;
  margin: 0px 10px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 25px;
  color: #000000;
}
.file-settlemen .form-row-checkbox {
  display: flex;
  width: 100%;
  margin: 15px 0px 15px 0px;
}
.file-settlemen .form-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.file-settlemen .center {
  text-align: center;
  margin-bottom: 100px;
}
.file-settlemen .text-null {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: #353535;
}
.file-settlemen .data-title {
  color: #414141;
  display: flex;
  align-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353535;
  align-items: center;
  height: 40px;
}
.file-settlemen .required {
  color: #EE2323;
  padding-left: 3px;
}
.file-settlemen .k-grid-header,
.file-settlemen .k-grouping-header,
.file-settlemen .k-grid-add-row,
.file-settlemen .k-grid-footer {
  color: #FFFFFF;
  background-color: #006CB5;
}
.file-settlemen .k-grid-header .k-header {
  position: relative;
  vertical-align: bottom;
  cursor: default;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  border-color: white;
  text-align: center;
}
.file-settlemen .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 1px 3px;
  white-space: nowrap;
}
.file-settlemen .k-grid th,
.file-settlemen .k-grid td {
  padding: 8px 12px;
  border-style: solid;
  border-color: inherit;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-settlemen .add-image-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #EE4623;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.file-settlemen .padding-top {
  margin-top: 30px;
  margin-bottom: 30px;
}
.file-settlemen .image-upload,
.file-settlemen .add-image {
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.file-settlemen .add-image {
  border: 1px solid #C8C8C8;
}
.file-settlemen .captcha-item {
  background-color: white;
  height: 50px;
  border: 1px solid #C8C8C8;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: #E3E3E3;
}
.file-settlemen .reload-captcha-btn {
  height: 50px;
  object-fit: cover;
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-settlemen .reload-icon {
  height: 16px;
  width: 16px;
}
.file-settlemen .quiz-submit-button {
  border: none;
  float: right;
  padding: 11px 10px;
  height: 50px;
  text-transform: uppercase;
  background: #006CB5;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 3px;
}
.file-settlemen .quiz-reset-button {
  min-width: 169px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  border: 1px solid #BEBEBE;
  background: #FFFFFF;
  color: #727272;
  border-radius: 3px;
  margin-left: 20px;
}
.file-settlemen .k-content.k-state-active {
  border: none;
  overflow: hidden;
}
.file-settlemen .text-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #343434;
}
.file-settlemen .text-header-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #006CB5;
  margin: 10px 0px 10px 0px;
}
.file-settlemen .text-header-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 10px 0px 10px 0px;
  color: #383838;
}
.file-settlemen .text-file {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #003EB5;
  margin: 10px 0px 10px 0px;
}
.file-settlemen .text-result {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #039B2D;
  margin: 10px 0px 10px 0px;
}
.file-settlemen .categories-box {
  width: 100%;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 144, 200, 0.84);
  margin-bottom: 20px;
  margin-top: 20px;
}
.file-settlemen .image-categories {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
.file-settlemen .categories-text {
  position: absolute;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px;
}
.file-settlemen .categories-area {
  padding-left: 20px;
  margin-top: 20px;
}
.file-settlemen .header-area,
.file-settlemen .footer-area {
  padding: 0 !important;
}
.file-settlemen .wrapper-area-flex {
  display: flex;
  justify-content: center;
}
.file-settlemen .col-md-6 {
  flex: 0 0 auto;
  width: 48%;
}
.file-settlemen .col-md-9 {
  flex: 0 0 auto;
  width: 73%;
}
@media screen and (max-width: 600px) {
  .file-settlemen .col-md-6 {
    flex: 0 0 auto;
    width: 48%;
  }
  .file-settlemen .quiz-form {
    padding: 0 15px;
  }
  .file-settlemen .form-row {
    display: block;
  }
  .file-settlemen .quiz-input-item {
    width: 100%;
  }
  .file-settlemen .quiz-textarea-item {
    width: 100%;
    display: block;
  }
  .file-settlemen .quiz-input-item-captcha {
    display: block;
  }
  .file-settlemen .captcha-item {
    margin-left: 0;
    margin-top: 10px;
  }
  .file-settlemen .reload-captcha-btn {
    margin-left: 0;
    margin-top: 10px;
  }
  .file-settlemen .quiz-button-item {
    flex-direction: column;
    width: 100%;
  }
  .file-settlemen .quiz-reset-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 770px) {
  .file-settlemen .row {
    margin-left: 0px !important;
  }
  .file-settlemen .categories-area {
    margin-top: 20px;
  }
  .file-settlemen .row > * {
    flex-shrink: 0;
    width: 99%;
    max-width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: var(--bs-gutter-y);
  }
  .file-settlemen .col-md-9 {
    flex: 0 0 auto;
    width: 97%;
  }
  .file-settlemen .text-not-title {
    height: 40px;
    position: relative;
    top: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    line-height: 19px;
    margin-bottom: 15px;
    color: #FF0000;
  }
  .file-settlemen .form-row {
    display: flex;
    width: 96%;
    justify-content: space-between;
  }
  .file-settlemen .titile {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
    line-height: 29px;
    color: #343434;
  }
  .file-settlemen .text-image {
    margin-top: 20px;
    align-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    display: flex;
    line-height: 19px;
    margin-left: 0px;
    color: #565656;
    margin-right: 0px;
  }
  .file-settlemen .quiz-title {
    margin-top: 30px;
    margin-bottom: 40px;
    color: #000000;
    margin-left: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .file-settlemen .form-row-mb {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }
  .file-settlemen .quiz-submit-button {
    min-width: 169px;
    border: none;
    float: right;
    padding: 11px 10px;
    height: 50px;
    text-transform: uppercase;
    background: #006CB5;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 3px;
  }
  .file-settlemen .send-background {
    background: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px !important;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
  }
  .file-settlemen .wb-water-bill-information {
    display: none !important;
  }
  .file-settlemen .mb-water-bill-information {
    display: block !important;
  }
  .file-settlemen .mb-water-bill-information .item-menu {
    margin: 7px 0;
    padding: 5px;
  }
  .file-settlemen .mb-water-bill-information .mb-drbtn-func {
    padding-right: 5px;
  }
  .file-settlemen .mb-water-bill-information .btn-custom button {
    background: none;
    color: gray;
    width: 100%;
    border: none;
  }
  .file-settlemen .mb-water-bill-information {
    display: none;
  }
  .file-settlemen .mb-water-bill-information .k-list {
    width: 150px;
  }
  .file-settlemen .mb-water-bill-information .item-menu {
    margin: 7px 0;
    padding: 5px;
  }
}

.news-template-35 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-35 .row {
  --bs-gutter-x: 0;
}
.news-template-35 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-35 .post-title {
  display: flex;
  color: #0B68A4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #00396B;
  position: relative;
  height: 40px;
}
.news-template-35 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-35 .post-more a {
  color: #EB2E35;
}
.news-template-35 .pointer {
  cursor: pointer;
}
.news-template-35 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-35 .title {
  min-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-35 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-35 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-35 .time-clock {
  display: flex;
}
.news-template-35 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-35 .main-item {
  display: table;
}
.news-template-35 .main-item .row {
  flex-wrap: nowrap;
}
.news-template-35 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 3px;
  margin-left: 1px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-35 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-35 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-35 .d-flex {
  visibility: visible !important;
}
.news-template-35 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-35 .menu .menu-row,
.news-template-35 .menu .menu-row-current,
.news-template-35 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-35 .menu .menu-row .menu-item-1,
.news-template-35 .menu .menu-row .menu-item-2,
.news-template-35 .menu .menu-row-current .menu-item-1,
.news-template-35 .menu .menu-row-current .menu-item-2,
.news-template-35 .menu .menu-row-current-border .menu-item-1,
.news-template-35 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-35 .menu .menu-row-current,
.news-template-35 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-35 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-35 .img-figure, .news-template-35 .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-35 .uppercase {
  color: #FFFFFF;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-35 .top-new-item {
  margin: 10px 0;
  width: 100%;
}
.news-template-35 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-35 .news-template-35-dialog {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0px;
  margin-bottom: 18px;
}
.news-template-35 .news-template-35-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-35 .news-template-35-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-35 .news-template-35-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-35 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-35 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-35 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-35 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-35 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.news-template-35 .item-image {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: fill;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}
.news-template-35 .text-section {
  display: flex;
  min-height: 30px;
  margin-top: 10px;
}
.news-template-35 .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
}
.news-template-35 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-template-35 .info-box {
  background-color: #FFFFFF;
  position: absolute;
  top: 25%;
  right: 2%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 25px;
  width: 36%;
}
.news-template-35 .template-box {
  position: relative;
}
.news-template-35 .pin-location {
  height: 20px;
  width: 16px;
}
.news-template-35 .icon-phone {
  height: 18px;
  width: 18px;
}
.news-template-35 .icon-home-phone {
  height: 24px;
  width: 24px;
}
.news-template-35 .icon-mail {
  height: 24px;
  width: 24px;
}
.news-template-35 .info-text {
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3F3F3F;
}
.news-template-35 .info-row {
  display: flex;
}
.news-template-35 .info-org {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  background: linear-gradient(180deg, #006CB5 0%, #00AEEF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 15px;
}
@media (max-width: 1276px) {
  .news-template-35 .info-box {
    position: relative;
    right: 0%;
    width: 100%;
  }
}
.news-template-35-dialog {
  width: 100%;
  margin-left: 0px !important;
}

.news-template-35-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-35-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-35-dialog .err-text,
.news-template-35-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-35-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-35-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-35-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-35-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-35-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-35-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-35-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-35-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-35-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-35-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-35-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-35-dialog .float-right {
  float: right;
}

.news-template-35-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-35-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-35-dialog .btn-save {
  color: #fff;
  background-color: #34a853;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  width: 200px;
  height: 40px;
}

.news-template-35-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-36,
.news-template-36-without-time {
  width: 100%;
  min-height: 504px;
}
.news-template-36 .row,
.news-template-36-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-36 .k-dialog,
.news-template-36-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-36 .dialog2 .k-dialog,
.news-template-36-without-time .dialog2 .k-dialog {
  height: 100% !important;
  width: 100% !important;
}
.news-template-36 .k-dialog .dialod1,
.news-template-36-without-time .k-dialog .dialod1 {
  height: 100% !important;
  width: 100% !important;
}
.news-template-36 .k-dialog .dialog2,
.news-template-36-without-time .k-dialog .dialog2 {
  height: 70% !important;
  width: 78% !important;
  top: 0px;
}
.news-template-36 .post-title,
.news-template-36-without-time .post-title {
  display: flex;
  justify-content: space-between;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
}
.news-template-36 .title-text,
.news-template-36-without-time .title-text {
  margin-bottom: 47px;
  margin-top: 47px;
  line-height: 45.25px;
  font-weight: 700;
  font-size: 18px;
  color: #006cb5;
}
.news-template-36 .post-more,
.news-template-36-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-36 .post-more a,
.news-template-36-without-time .post-more a {
  color: #d6a022;
}
.news-template-36 .close-div,
.news-template-36-without-time .close-div {
  display: flex;
}
.news-template-36 .close-icon-div,
.news-template-36-without-time .close-icon-div {
  position: fixed;
  right: 537px;
  top: 150px;
}
.news-template-36 .pointer,
.news-template-36-without-time .pointer {
  cursor: pointer;
}
.news-template-36 .dialogimage2 .k-window-content,
.news-template-36 .k-prompt-container,
.news-template-36-without-time .dialogimage2 .k-window-content,
.news-template-36-without-time .k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
}
.news-template-36 .icon-clock,
.news-template-36-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-36 .title,
.news-template-36-without-time .title {
  line-height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #292621;
  margin-bottom: 20px;
  text-decoration: none;
}
.news-template-36 .title-template-type-2,
.news-template-36-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2b2b2b;
  margin-bottom: 20px;
}
.news-template-36 .post-image,
.news-template-36 .post-image-radius,
.news-template-36-without-time .post-image,
.news-template-36-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-36 .post-image-radius,
.news-template-36-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-36 .post-image-default,
.news-template-36-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-36 .post-image-org,
.news-template-36-without-time .post-image-org {
  position: absolute;
  top: 40%;
}
.news-template-36 .time-clock,
.news-template-36-without-time .time-clock {
  display: flex;
}
.news-template-36 .time-text,
.news-template-36-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-36 .center,
.news-template-36-without-time .center {
  position: fixed;
  bottom: 30px;
  width: 40vw;
  left: 10px;
  right: 10px;
  /* margin-left: 53px; */
  margin: 0 auto;
  z-index: 10000000000;
}
.news-template-36 .vertical-dot,
.news-template-36-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-36 .main-item:hover .vertical-dot,
.news-template-36-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-36 .d-flex,
.news-template-36-without-time .d-flex {
  visibility: visible !important;
}
.news-template-36 .three-dot-area,
.news-template-36-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-36 .menu,
.news-template-36-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-36 .menu .menu-row,
.news-template-36 .menu .menu-row-current,
.news-template-36 .menu .menu-row-current-border,
.news-template-36-without-time .menu .menu-row,
.news-template-36-without-time .menu .menu-row-current,
.news-template-36-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-36 .menu .menu-row .menu-item-1,
.news-template-36 .menu .menu-row .menu-item-2,
.news-template-36 .menu .menu-row-current .menu-item-1,
.news-template-36 .menu .menu-row-current .menu-item-2,
.news-template-36 .menu .menu-row-current-border .menu-item-1,
.news-template-36 .menu .menu-row-current-border .menu-item-2,
.news-template-36-without-time .menu .menu-row .menu-item-1,
.news-template-36-without-time .menu .menu-row .menu-item-2,
.news-template-36-without-time .menu .menu-row-current .menu-item-1,
.news-template-36-without-time .menu .menu-row-current .menu-item-2,
.news-template-36-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-36-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-36 .menu .menu-row-current,
.news-template-36 .menu .menu-row-current-border,
.news-template-36-without-time .menu .menu-row-current,
.news-template-36-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-36 .menu .menu-item-3,
.news-template-36-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-36 .img-figure,
.news-template-36 .img-figure-no-img,
.news-template-36 .img-figure-template-type-2,
.news-template-36-without-time .img-figure,
.news-template-36-without-time .img-figure-no-img,
.news-template-36-without-time .img-figure-template-type-2 {
  height: 312px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-36 .img-figure-template-type-2,
.news-template-36-without-time .img-figure-template-type-2 {
  height: 153px;
}
.news-template-36 .img-figure-2,
.news-template-36-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-36 .post-list,
.news-template-36-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}
.news-template-36 .post-list .k-pager-info,
.news-template-36 .post-list .k-pager-sizes,
.news-template-36-without-time .post-list .k-pager-info,
.news-template-36-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-36 .post-list .k-pager kendo-datapager-prev-buttons,
.news-template-36-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-36 .post-list .k-pager .k-state-selected,
.news-template-36-without-time .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-36 .post-list .top-part,
.news-template-36-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-36 .uppercase,
.news-template-36-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-36 .top-new-item,
.news-template-36-without-time .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-36 .top-new-item .title:hover,
.news-template-36-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-36 .close-div,
.news-template-36-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-36 .close-div2,
.news-template-36-without-time .close-div2 {
  display: flex;
}
.news-template-36 .news-template-36-dialog,
.news-template-36-without-time .news-template-36-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-36 .news-template-36-dialog .close-icon-box,
.news-template-36-without-time .news-template-36-dialog .close-icon-box {
  position: absolute;
  right: 10px;
}
.news-template-36 .news-template-36-dialog .close-icon,
.news-template-36-without-time .news-template-36-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-36 .news-template-36-dialog .title-info-req,
.news-template-36-without-time .news-template-36-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-36 .input-label,
.news-template-36-without-time .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-36 .btn-delete,
.news-template-36-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-36 .btn-cancel,
.news-template-36-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-36 .dialog-width,
.news-template-36-without-time .dialog-width {
  max-width: 400px;
}
.news-template-36 .koverley2 .k-overlay,
.news-template-36-without-time .koverley2 .k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}
.news-template-36 .dialog-width-images,
.news-template-36-without-time .dialog-width-images {
  height: fit-content !important;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-36 .wrapper,
.news-template-36-without-time .wrapper {
  display: flex;
}
.news-template-36 .content,
.news-template-36-without-time .content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.news-template-36 .post-image,
.news-template-36-without-time .post-image {
  border-radius: 5px;
  object-fit: cover;
}
.news-template-36 .dialog-container,
.news-template-36-without-time .dialog-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-36 .radio-info,
.news-template-36-without-time .radio-info {
  margin-right: 6%;
  max-width: 42%;
  width: 100%;
}
.news-template-36 .album-name,
.news-template-36-without-time .album-name {
  font-style: normal;
  font-weight: 700;
  color: #3F3F3F;
  font-size: 16px;
  font-weight: 700;
}
.news-template-36 .author-name,
.news-template-36-without-time .author-name {
  max-width: 4.5%;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.news-template-36 .radio-order,
.news-template-36-without-time .radio-order {
  color: #006CB5;
  font-size: 16px;
  font-weight: 700;
  width: 2%;
}
.news-template-36 .radio-duration,
.news-template-36-without-time .radio-duration {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.news-template-36 .view-count-icon,
.news-template-36-without-time .view-count-icon {
  max-width: 1.2%;
  margin: 0 0.7% 0 7%;
  color: #C2C2C2;
}
.news-template-36 .view-count,
.news-template-36-without-time .view-count {
  color: #C2C2C2;
  font-size: 16px;
  font-weight: 700;
  margin-right: 6%;
  max-width: 3%;
  width: 100%;
}
.news-template-36 .d-grid,
.news-template-36-without-time .d-grid {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  width: -webkit-fill-available;
  gap: 30px 0px;
}
.news-template-36 .k-tabstrip-content,
.news-template-36 .k-tabstrip > .k-content,
.news-template-36-without-time .k-tabstrip-content,
.news-template-36-without-time .k-tabstrip > .k-content {
  padding: 0;
  border: unset;
}
.news-template-36 .k-tabstrip-content.k-state-active,
.news-template-36 .k-tabstrip > .k-content.k-state-active,
.news-template-36-without-time .k-tabstrip-content.k-state-active,
.news-template-36-without-time .k-tabstrip > .k-content.k-state-active {
  margin-top: 56px;
}
.news-template-36 .post-name,
.news-template-36-without-time .post-name {
  margin-top: 28px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.63px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-template-36 .k-tabstrip-items .k-link,
.news-template-36-without-time .k-tabstrip-items .k-link {
  padding: 0px 0px 12px 0px;
  color: #b6b6b6;
  font-weight: 700;
  line-height: 22.63px;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 50px;
}
.news-template-36 .k-tabstrip-items .k-item:not(:first-child) .k-link,
.news-template-36-without-time .k-tabstrip-items .k-item:not(:first-child) .k-link {
  padding: 0px 10px 12px 10px;
}
.news-template-36 .k-tabstrip-items .k-item.k-state-active .k-link,
.news-template-36-without-time .k-tabstrip-items .k-item.k-state-active .k-link {
  color: #004a9a;
  font-weight: 700;
  border-width: 0 0 3px !important;
  border-color: #08659a;
  border-style: solid;
}
.news-template-36 .k-tabstrip-items,
.news-template-36-without-time .k-tabstrip-items {
  overflow-x: auto;
}
.news-template-36 .k-tabstrip-items::-webkit-scrollbar,
.news-template-36-without-time .k-tabstrip-items::-webkit-scrollbar {
  height: 3px;
}
.news-template-36 .k-tabstrip-items::-webkit-scrollbar-track,
.news-template-36-without-time .k-tabstrip-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.news-template-36 .k-tabstrip-items::-webkit-scrollbar-thumb,
.news-template-36-without-time .k-tabstrip-items::-webkit-scrollbar-thumb {
  background: #888;
}
.news-template-36 .k-tabstrip-items::-webkit-scrollbar-thumb:hover,
.news-template-36-without-time .k-tabstrip-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.news-template-36 .k-tabstrip-top > .k-tabstrip-items .k-item,
.news-template-36-without-time .k-tabstrip-top > .k-tabstrip-items .k-item {
  border-width: 0px 0px 1px 0px;
}
.news-template-36 .media,
.news-template-36-without-time .media {
  position: relative;
  width: 6%;
  margin: 0 2% 0 1%;
}
.news-template-36 .overlap,
.news-template-36-without-time .overlap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 106px;
  background: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.news-template-36 .overlap .number-img .text,
.news-template-36-without-time .overlap .number-img .text {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 30.17px;
}
.news-template-36 .overlap .number-img .image,
.news-template-36-without-time .overlap .number-img .image {
  height: 24px;
  width: 24px;
}
.news-template-36 .overlap .number-img .image img,
.news-template-36-without-time .overlap .number-img .image img {
  width: 100%;
  height: 100%;
}
.news-template-36 .download-radio,
.news-template-36-without-time .download-radio {
  width: 32px;
  height: 32px;
  min-width: 32px;
  position: relative;
  border-radius: 32px;
  background: var(--1, linear-gradient(180deg, #006CB5 0%, #00AEEF 100%));
  border: none;
}
.news-template-36 .download-radio-icon,
.news-template-36-without-time .download-radio-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-36 .radio-container,
.news-template-36-without-time .radio-container {
  align-items: center;
  display: flex;
}
.news-template-36 .k-pager-wrap,
.news-template-36-without-time .k-pager-wrap {
  border: none;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
}
.news-template-36 .k-pager-numbers .k-link.k-state-selected,
.news-template-36-without-time .k-pager-numbers .k-link.k-state-selected {
  color: #ffffff;
  background-color: #d6a022;
}
.news-template-36 .k-pager-numbers .k-link,
.news-template-36-without-time .k-pager-numbers .k-link {
  color: #d6a022;
}
.news-template-36 .mb,
.news-template-36-without-time .mb {
  display: none !important;
}
.news-template-36 audio,
.news-template-36-without-time audio {
  height: 30px;
  margin-top: 15px;
}
.news-template-36 kendo-tabstrip,
.news-template-36-without-time kendo-tabstrip {
  width: 100%;
}
@media (max-width: 992px) {
  .news-template-36 .d-grid,
.news-template-36-without-time .d-grid {
    width: 120%;
  }
  .news-template-36 .media,
.news-template-36-without-time .media {
    position: relative;
    width: 20%;
    margin: 0 1.6%;
  }
  .news-template-36 .radio-order,
.news-template-36-without-time .radio-order {
    font-size: 14px;
  }
  .news-template-36 .album-name,
.news-template-36-without-time .album-name {
    font-size: 14px;
  }
  .news-template-36 .radio-info,
.news-template-36-without-time .radio-info {
    margin-right: 15%;
    max-width: 50%;
  }
  .news-template-36 .author-name, .news-template-36 .radio-duration, .news-template-36 .view-count,
.news-template-36-without-time .author-name,
.news-template-36-without-time .radio-duration,
.news-template-36-without-time .view-count {
    font-size: 14px;
  }
  .news-template-36 .view-count,
.news-template-36-without-time .view-count {
    margin-right: 4.5%;
  }
  .news-template-36 .view-count-icon,
.news-template-36-without-time .view-count-icon {
    max-width: 2%;
    margin: 0 0.7% 0 5.1%;
  }
  .news-template-36 .download-radio-icon,
.news-template-36-without-time .download-radio-icon {
    width: 20px;
    height: 20px;
  }
  .news-template-36 .download-radio,
.news-template-36-without-time .download-radio {
    width: 28px;
    height: 28px;
    min-width: 28px;
    border-radius: 28px;
  }
}
@media (max-width: 768px) {
  .news-template-36 .wb,
.news-template-36-without-time .wb {
    display: none !important;
  }
  .news-template-36 .mb,
.news-template-36-without-time .mb {
    display: block !important;
    padding: 5px;
  }
  .news-template-36 .radio-container,
.news-template-36-without-time .radio-container {
    display: block;
    border: 1px solid #c4c4c4;
    margin: 10px 0;
    padding: 5px;
    font-size: 14px;
  }
  .news-template-36 audio,
.news-template-36-without-time audio {
    margin-top: 0px;
    height: 15px;
  }
}

.news-template-36-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-36-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-36-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-36-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-36-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-36-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-36-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-36-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-36-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-36-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-36-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-36-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-36-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-36-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-36-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-36-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-36-dialog .float-right {
  float: right;
}

.news-template-36-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-36-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-36-dialog .control {
  margin-bottom: 22px;
}

.news-template-36-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-36-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-36-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-36-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-36-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-36-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-38 .img-scrollview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.news-template-38 .banner {
  width: 100% !important;
}
.news-template-38 .image-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  color: #FFFFFF;
  padding: 25px 55px 0px 55px;
  height: 115px;
  background-image: url("../../assets/images/slider-title-background.png") !important;
  white-space: pre-line;
  border-radius: 0px 0px 4px 0px;
}
.news-template-38 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-38 kendo-scrollview.k-scrollview-wrap {
  border: none;
}
.news-template-38 kendo-scrollview-pager {
  display: none;
}
.news-template-38 .k-scrollview-next,
.news-template-38 .k-scrollview-prev {
  text-shadow: none;
  background: rgba(32, 28, 28, 0.24);
  height: 36px;
  width: 36px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: hidden;
}
.news-template-38 .k-scrollview-next {
  right: 2%;
}
.news-template-38 .k-scrollview-prev {
  left: 2%;
}
.news-template-38 .k-scrollview-next .k-icon,
.news-template-38 .k-scrollview-prev .k-icon {
  font-size: 36px;
  text-align: center;
}
.news-template-38 .vertical-dot {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 2px;
  background-color: rgba(32, 28, 28, 0.1);
}
.news-template-38 .vertical-dot .three-dot-area {
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.news-template-38 .vertical-dot .three-dot {
  width: 5px;
  height: 20px;
}
.news-template-38 .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: fit-content;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
}
.news-template-38 .vertical-dot .menu .menu-row,
.news-template-38 .vertical-dot .menu .menu-row-current,
.news-template-38 .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-38 .vertical-dot .menu .menu-row .menu-item-1,
.news-template-38 .vertical-dot .menu .menu-row .menu-item-2,
.news-template-38 .vertical-dot .menu .menu-row-current .menu-item-1,
.news-template-38 .vertical-dot .menu .menu-row-current .menu-item-2,
.news-template-38 .vertical-dot .menu .menu-row-current-border .menu-item-1,
.news-template-38 .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.news-template-38 .vertical-dot .menu .menu-row-current,
.news-template-38 .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-38 .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-38 .banner-container:hover .vertical-dot {
  display: flex;
}
.news-template-38 .router-outlet-content {
  width: 695px !important;
  height: 90%;
}
.news-template-38 .router-outlet-content-gallery {
  width: 794px !important;
  height: 90%;
}
.news-template-38 .title-info-req {
  color: #004A9A;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.news-template-38 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-38 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-38 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-38 .employe-detail {
  margin-top: 10px;
}
.news-template-38 .employe-detail-mb {
  padding: 10px 0px;
  margin-bottom: 18px;
}
.news-template-38 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-38 .slider-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-38 .slider-dialog .close-icon-div {
  position: absolute;
  right: 0px;
}
.news-template-38 .slider-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-38 .k-scrollview {
  position: relative;
}
@media screen and (max-width: 1200px) {
  .news-template-38 .img-scrollview {
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .news-template-38 .router-outlet-content {
    width: 100% !important;
  }
  .news-template-38 .router-outlet-content-gallery {
    width: 100% !important;
  }
  .news-template-38 .img-scrollview {
    height: 100%;
  }
}
@media screen and (max-width: 770px) {
  .news-template-38 .img-scrollview {
    height: 100%;
  }
  .news-template-38 .image-name {
    font-size: 5vw;
    padding: 20px 50px 0px 50px;
  }
}

.news-template-38:hover .vertical-dot {
  visibility: visible;
}

.news-template-37 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-37 .back-to-top {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
}
.news-template-37 .row {
  --bs-gutter-x: 0;
}
.news-template-37 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-37 .pd-l-10 {
  padding-left: 18px;
}
.news-template-37 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  min-height: 40px;
}
.news-template-37 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-37 .post-more a {
  color: #EB2E35;
}
.news-template-37 .pointer {
  cursor: pointer;
}
.news-template-37 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-37 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-template-37 .post-image {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-37 .post-image-cover {
  object-fit: cover;
  width: 48px;
}
.news-template-37 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-37 .time-clock {
  display: flex;
}
.news-template-37 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-37 .main-item {
  display: table;
  margin: 0;
}
.news-template-37 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-37 .d-flex {
  visibility: visible !important;
}
.news-template-37 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-37 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-37 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-37 .menu .menu-row,
.news-template-37 .menu .menu-row-current,
.news-template-37 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-37 .menu .menu-row .menu-item-1,
.news-template-37 .menu .menu-row .menu-item-2,
.news-template-37 .menu .menu-row-current .menu-item-1,
.news-template-37 .menu .menu-row-current .menu-item-2,
.news-template-37 .menu .menu-row-current-border .menu-item-1,
.news-template-37 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-37 .menu .menu-row-current,
.news-template-37 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-37 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-37 .img-figure, .news-template-37 .img-figure-no-img {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 48px;
  height: 48px;
  border: 1px solid #D8D8D8;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
}
.news-template-37 .img-figure:hover .post-image, .news-template-37 .img-figure-no-img:hover .post-image {
  width: 60%;
  height: 60%;
  margin-top: 9px;
}
.news-template-37 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-37 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-37 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-37 .news-template-37-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-37 .news-template-37-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-37 .news-template-37-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-37 .news-template-37-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-37 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-37 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-37 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-37 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-37 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-37 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.news-template-37 .text-section :hover {
  color: #00396B;
}
.news-template-37 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
  text-decoration: none;
}
.news-template-37 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-37 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-37 .fixed-item {
  position: fixed;
  padding: 0px 0px 0 0;
  left: calc(100% - 65px);
  top: 90%;
  transform: translateX(-50%);
  z-index: 1001;
}
@media (max-width: 768px) {
  .news-template-37 .fixed-item {
    top: 70%;
    right: 0px;
    left: auto;
  }
}

.news-template-37-dialog {
  margin-left: 0px !important;
}

.news-template-37-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-37-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-37-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-37-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-37-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-37-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-37-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-37-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-37-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-37-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-37-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-37-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-37-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-37-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-37-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-37-dialog .float-right {
  float: right;
}

.news-template-37-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-37-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-37-dialog .control {
  margin-bottom: 22px;
}

.news-template-37-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-37-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-37-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-37-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-37-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-37-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-39 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-39 .row {
  --bs-gutter-x: 0;
}
.news-template-39 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-39 .pd-l-10 {
  padding-left: 18px;
}
.news-template-39 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  min-height: 40px;
  justify-content: space-between;
}
.news-template-39 .post-title .category {
  flex-direction: row;
  display: flex;
  animation-duration: 1s;
  animation-timing-function: linear;
  align-items: center;
  height: 100%;
}
.news-template-39 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-39 .post-more a {
  color: #EB2E35;
}
.news-template-39 .pointer {
  cursor: pointer;
}
.news-template-39 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-39 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-template-39 .post-image, .news-template-39 .post-image-cover {
  height: 198px;
  width: 100%;
}
.news-template-39 .post-image-default {
  height: 198px;
  width: 100%;
}
.news-template-39 .post-image-cover {
  object-fit: cover;
}
.news-template-39 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-39 .time-clock {
  display: flex;
}
.news-template-39 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-39 .main-item {
  display: table;
  margin: 0;
}
.news-template-39 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-39 .d-flex {
  visibility: visible !important;
}
.news-template-39 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-39 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-39 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-39 .menu .menu-row,
.news-template-39 .menu .menu-row-current,
.news-template-39 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-39 .menu .menu-row .menu-item-1,
.news-template-39 .menu .menu-row .menu-item-2,
.news-template-39 .menu .menu-row-current .menu-item-1,
.news-template-39 .menu .menu-row-current .menu-item-2,
.news-template-39 .menu .menu-row-current-border .menu-item-1,
.news-template-39 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-39 .menu .menu-row-current,
.news-template-39 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-39 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-39 .img-figure, .news-template-39 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-39 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-39 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-39 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-39 .news-template-39-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-39 .news-template-39-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-39 .news-template-39-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-39 .news-template-39-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-39 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-39 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-39 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-39 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-39 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-39 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-39 .text-section :hover {
  color: #00396B;
}
.news-template-39 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
  text-decoration: none;
}
.news-template-39 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
@media (max-width: 1200px) {
  .news-template-39 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-39 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-39 .arrow-icon, .news-template-39 .arrow-icon-left {
  height: 25px;
  width: 25px;
}
.news-template-39 .arrow-icon-left {
  margin-right: 10px;
}
.news-template-39 .arrow-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.news-template-39 .title-right {
  width: 90%;
  overflow: hidden;
}
.news-template-39 .category-box {
  min-width: fit-content;
  margin-right: 20px;
}
.news-template-39 .category-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #00396B;
  cursor: pointer;
  text-decoration: none;
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
}
.news-template-39 .title-box {
  display: flex;
}
.news-template-39 .menu-box {
  display: flex;
  justify-content: end;
  position: relative;
  max-width: 700px;
}
.news-template-39 .full-width {
  width: 100%;
}
@media screen and (max-width: 400px) {
  .news-template-39 .post-title {
    display: block;
  }
  .news-template-39 .menu-box {
    display: block;
  }
  .news-template-39 .title-right {
    width: 300px;
  }
}

.news-template-39-dialog {
  margin-left: 0px !important;
}

.news-template-39-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-39-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-39-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-39-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-39-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-39-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-39-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-39-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-39-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-39-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-39-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-39-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-39-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-39-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-39-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-39-dialog .float-right {
  float: right;
}

.news-template-39-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-39-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-39-dialog .control {
  margin-bottom: 22px;
}

.news-template-39-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-39-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-39-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-39-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-39-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-39-dialog .padding-col {
  padding: 7px 15px 0;
}

.notify-page {
  width: 100%;
  background-color: #ffffff !important;
}
.notify-page .search-result-container {
  margin-top: 30px;
}
.notify-page .search-result-list {
  margin-top: 5px;
}
.notify-page .search-result-summary {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #4e4e4e;
}
.notify-page .search-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.notify-page .search-result-item {
  display: flex;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.notify-page .search-result-image {
  cursor: pointer;
  flex: 1;
}
.notify-page .search-result-image img {
  width: 100%;
  object-fit: cover;
}
.notify-page .search-result-info {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 28px;
}
.notify-page .search-result-info h3 {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #414142;
  cursor: pointer;
}
.notify-page .search-result-info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494949;
  margin: 0;
}
.notify-page .post-title {
  display: flex;
  color: #0b68a4;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 15px;
  background-color: #00396b;
  position: relative;
  height: 40px;
}
.notify-page .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.notify-page .post-more a {
  color: #eb2e35;
}
.notify-page .pointer {
  cursor: pointer;
}
.notify-page .icon-clock {
  width: 14px;
  height: 14px;
  margin-top: 5px;
}
.notify-page .title {
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  text-align: justify;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  margin-left: 16px;
  margin-right: 30px;
}
.notify-page .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.notify-page .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.notify-page .time-clock {
  display: flex;
}
.notify-page .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.notify-page .three-dot-area {
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
}
.notify-page .three-dot {
  width: 5px;
  height: 20px;
}
.notify-page .main-item {
  display: table;
  margin: 0;
}
.notify-page .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22px;
}
.notify-page .menu {
  border: 2px solid black;
  background-color: #fffcfc;
  color: #0079ff;
  border-radius: 5px;
  cursor: pointer;
  width: 90px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.notify-page .menu .menu-row,
.notify-page .menu .menu-row-current,
.notify-page .menu .menu-row-current-border {
  cursor: pointer;
}
.notify-page .menu .menu-row .menu-item-1,
.notify-page .menu .menu-row .menu-item-2,
.notify-page .menu .menu-row-current .menu-item-1,
.notify-page .menu .menu-row-current .menu-item-2,
.notify-page .menu .menu-row-current-border .menu-item-1,
.notify-page .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid black;
}
.notify-page .menu .menu-row-current,
.notify-page .menu .menu-row-current-border {
  background-color: #d8d5d5;
  color: #686868;
  border-bottom: 2px;
  border-radius: 0 0 2px 2px;
}
.notify-page .menu .menu-row-current-border {
  border-radius: 2px 2px 0 0;
}
.notify-page .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  align-items: center;
}
.notify-page .img-figure,
.notify-page .img-figure-no-img {
  height: 198px;
  max-width: 372px;
  display: flex;
  justify-content: center;
  position: relative;
}
.notify-page .uppercase {
  color: #ffffff;
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.notify-page .top-new-item {
  margin: 10px 0;
}
.notify-page .close-div {
  display: flex;
  margin-top: 3px;
}
.notify-page .notify-page-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.notify-page .notify-page-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.notify-page .notify-page-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.notify-page .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.notify-page .first-news-deco {
  position: absolute;
  right: 0px;
  height: 40px;
  width: 49px;
}
.notify-page .text-section {
  display: flex;
  margin-top: 10px;
}
.notify-page .title-first {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396b;
}
.notify-page .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #1f1f1f;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.notify-page .k-pager-wrap {
  border-color: white !important;
  color: #424242;
  background-color: white !important;
}
.notify-page .title-txt {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  color: #00396b;
}
.notify-page .summary-txt {
  color: #797979;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.notify-page .date-txt {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.news-template-40 .row, .news-template-40-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-40 .padding, .news-template-40-without-time .padding {
  padding: 0%;
}
.news-template-40 .k-dialog, .news-template-40-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-40 .post-title, .news-template-40-without-time .post-title {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
}
.news-template-40 .title-text, .news-template-40-without-time .title-text {
  margin-bottom: 0 !important;
}
.news-template-40 .post-more, .news-template-40-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  background-color: white;
  padding-top: 4px;
}
.news-template-40 .post-more a, .news-template-40-without-time .post-more a {
  color: #d6a022;
}
.news-template-40 .pointer, .news-template-40-without-time .pointer {
  cursor: pointer;
}
.news-template-40 .icon-clock, .news-template-40-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-40 .title-first, .news-template-40-without-time .title-first {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
  color: #2B2B2B;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  position: absolute;
  top: 57vh;
  left: 30px;
  text-wrap: nowrap;
}
.news-template-40 .title-second, .news-template-40-without-time .title-second {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
  color: #2B2B2B;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  position: absolute;
  top: 25vh;
  left: 30px;
  text-wrap: nowrap;
}
.news-template-40 .title, .news-template-40-without-time .title {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
  color: #2B2B2B;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  position: absolute;
  top: 22vh;
  left: 30px;
  text-wrap: nowrap;
}
.news-template-40 .title-template-type-2, .news-template-40-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-40 .post-image, .news-template-40 .post-image-radius, .news-template-40-without-time .post-image, .news-template-40-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-40 .post-image-radius, .news-template-40-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-40 .post-image-default, .news-template-40-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-40 .post-image-org, .news-template-40-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-40 .time-clock, .news-template-40-without-time .time-clock {
  display: flex;
}
.news-template-40 .time-text, .news-template-40-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-40 .main-item, .news-template-40-without-time .main-item {
  display: table;
  margin: 30px 0;
}
.news-template-40 .vertical-dot, .news-template-40-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-40 .main-item:hover .vertical-dot, .news-template-40-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-40 .d-flex, .news-template-40-without-time .d-flex {
  visibility: visible !important;
}
.news-template-40 .menu, .news-template-40-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-40 .menu .menu-row,
.news-template-40 .menu .menu-row-current,
.news-template-40 .menu .menu-row-current-border, .news-template-40-without-time .menu .menu-row,
.news-template-40-without-time .menu .menu-row-current,
.news-template-40-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-40 .menu .menu-row .menu-item-1,
.news-template-40 .menu .menu-row .menu-item-2,
.news-template-40 .menu .menu-row-current .menu-item-1,
.news-template-40 .menu .menu-row-current .menu-item-2,
.news-template-40 .menu .menu-row-current-border .menu-item-1,
.news-template-40 .menu .menu-row-current-border .menu-item-2, .news-template-40-without-time .menu .menu-row .menu-item-1,
.news-template-40-without-time .menu .menu-row .menu-item-2,
.news-template-40-without-time .menu .menu-row-current .menu-item-1,
.news-template-40-without-time .menu .menu-row-current .menu-item-2,
.news-template-40-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-40-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-40 .menu .menu-row-current,
.news-template-40 .menu .menu-row-current-border, .news-template-40-without-time .menu .menu-row-current,
.news-template-40-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-40 .menu .menu-item-3, .news-template-40-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-40 .img-figure,
.news-template-40 .img-figure-no-img,
.news-template-40 .img-figure-template-type-2, .news-template-40-without-time .img-figure,
.news-template-40-without-time .img-figure-no-img,
.news-template-40-without-time .img-figure-template-type-2 {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 5px;
  height: 34.5vh;
}
.news-template-40 .post-list, .news-template-40-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 70vh;
  overflow: hidden;
}
.news-template-40 .post-list .k-pager-info, .news-template-40 .post-list .k-pager-sizes, .news-template-40-without-time .post-list .k-pager-info, .news-template-40-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-40 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-40-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-40 .post-list .k-pager .k-state-selected, .news-template-40-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-40 .post-list .top-part, .news-template-40-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-40 .uppercase, .news-template-40-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-40 .top-new-item, .news-template-40-without-time .top-new-item {
  margin: 15px 0px 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-40 .top-new-item .title:hover, .news-template-40-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-40 .close-div, .news-template-40-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-40 .news-template-40-dialog, .news-template-40-without-time .news-template-40-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-40 .news-template-40-dialog .close-icon-div, .news-template-40-without-time .news-template-40-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-40 .news-template-40-dialog .close-icon, .news-template-40-without-time .news-template-40-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-40 .news-template-40-dialog .title-info-req, .news-template-40-without-time .news-template-40-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-40 .input-label, .news-template-40-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-40 .btn-delete, .news-template-40-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-40 .btn-cancel, .news-template-40-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-40 .dialog-width, .news-template-40-without-time .dialog-width {
  max-width: 400px;
}
.news-template-40 .post-container, .news-template-40-without-time .post-container {
  position: relative;
}
.news-template-40 .first-post, .news-template-40-without-time .first-post {
  height: 70vh;
}
.news-template-40 .menu-name, .news-template-40-without-time .menu-name {
  text-transform: uppercase;
  color: #FFF;
  background-color: rgba(0, 132, 199, 0.75);
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 12px;
  width: fit-content;
  margin-bottom: 7px;
}
.news-template-40 .title-name, .news-template-40-without-time .title-name {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-align: left;
  color: #FFF;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2px 10px;
  border-radius: 5px;
  text-wrap: wrap;
  overflow: hidden;
  margin-right: 10px;
}
.news-template-40 a, .news-template-40-without-time a {
  text-decoration: none;
}
.news-template-40 .mobile-view, .news-template-40-without-time .mobile-view {
  display: none;
}
.news-template-40 .web-view, .news-template-40-without-time .web-view {
  display: block;
}
.news-template-40 .post-more, .news-template-40-without-time .post-more {
  display: none;
}
.news-template-40 p, .news-template-40-without-time p {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .news-template-40 .col-md-4, .news-template-40-without-time .col-md-4 {
    width: 32%;
  }
  .news-template-40 .web-view, .news-template-40-without-time .web-view {
    display: none;
  }
  .news-template-40 .mobile-view, .news-template-40-without-time .mobile-view {
    display: block;
  }
  .news-template-40 .post-list, .news-template-40-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: auto;
  }
  .news-template-40 .img-figure, .news-template-40-without-time .img-figure {
    height: 30vh;
  }
  .news-template-40 .title-first, .news-template-40-without-time .title-first {
    top: 15vh;
    left: 15px;
    width: 95%;
  }
  .news-template-40 .post-more, .news-template-40-without-time .post-more {
    display: block;
  }
}
@media (max-width: 576px) {
  .news-template-40 .web-view, .news-template-40-without-time .web-view {
    display: none;
  }
  .news-template-40 .mobile-view, .news-template-40-without-time .mobile-view {
    display: block;
  }
  .news-template-40 .post-list, .news-template-40-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: auto;
  }
  .news-template-40 .img-figure, .news-template-40-without-time .img-figure {
    height: 30vh;
  }
  .news-template-40 .title-first, .news-template-40-without-time .title-first {
    top: 15vh;
    left: 15px;
    width: 95%;
  }
  .news-template-40 .post-more, .news-template-40-without-time .post-more {
    display: flex;
    color: #D9D9D9;
    padding: 0;
  }
}

.news-template-40-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-40-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-40-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-40-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-40-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-40-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-40-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-40-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-40-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-40-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-40-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-40-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-40-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-40-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-40-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-40-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-40-dialog .float-right {
  float: right;
}

.news-template-40-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-40-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-40-dialog .control {
  margin-bottom: 40px;
}

.news-template-40-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-40-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-40-dialog .k-input {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-40-dialog .k-clear-value {
  height: 38px !important;
  margin-top: -1px !important;
}

.news-template-40-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-40-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-41, .news-template-41-without-time {
  width: 100%;
  min-height: 504px;
}
.news-template-41 .description, .news-template-41-without-time .description {
  text-align: justify;
}
.news-template-41 .description img, .news-template-41-without-time .description img {
  width: 100%;
}
.news-template-41 .description figure, .news-template-41-without-time .description figure {
  width: 100% !important;
}
.news-template-41 .description figure iframe, .news-template-41-without-time .description figure iframe {
  height: 100%;
  width: 100%;
}
.news-template-41 .description p, .news-template-41-without-time .description p {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000 !important;
}
.news-template-41 .description blockquote, .news-template-41-without-time .description blockquote {
  border-left: 5px solid #d9d9d9;
  padding-left: 15px;
  font-style: italic;
}
.news-template-41 img, .news-template-41-without-time img {
  width: 100% !important;
  height: auto;
}
.news-template-41 .row, .news-template-41-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-41 .k-dialog, .news-template-41-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-41 .post-title, .news-template-41-without-time .post-title {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #006CB5;
}
.news-template-41 .title-text, .news-template-41-without-time .title-text {
  margin-bottom: 0 !important;
}
.news-template-41 .post-more, .news-template-41-without-time .post-more {
  display: flex;
  justify-content: center;
  color: #d6a022;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 4px;
}
.news-template-41 .post-more a, .news-template-41-without-time .post-more a {
  color: #d6a022;
}
.news-template-41 .pointer, .news-template-41-without-time .pointer {
  cursor: pointer;
}
.news-template-41 .icon-clock, .news-template-41-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-41 .title, .news-template-41-without-time .title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #292621;
  text-decoration: none;
  margin-bottom: 5px !important;
}
.news-template-41 .title-template-type-2, .news-template-41-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-41 .post-image, .news-template-41 .post-image-radius, .news-template-41-without-time .post-image, .news-template-41-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.news-template-41 .post-image-radius, .news-template-41-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-41 .post-image-default, .news-template-41-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-41 .post-image-org, .news-template-41-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-41 .time-clock, .news-template-41-without-time .time-clock {
  display: flex;
}
.news-template-41 .time-text, .news-template-41-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-41 .main-item, .news-template-41-without-time .main-item {
  display: table;
}
.news-template-41 .vertical-dot, .news-template-41-without-time .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-41 .main-item:hover .vertical-dot, .news-template-41-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-41 .d-flex, .news-template-41-without-time .d-flex {
  visibility: visible !important;
}
.news-template-41 .three-dot-area, .news-template-41-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-41 .menu, .news-template-41-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-41 .menu .menu-row,
.news-template-41 .menu .menu-row-current,
.news-template-41 .menu .menu-row-current-border, .news-template-41-without-time .menu .menu-row,
.news-template-41-without-time .menu .menu-row-current,
.news-template-41-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-41 .menu .menu-row .menu-item-41,
.news-template-41 .menu .menu-row .menu-item-2,
.news-template-41 .menu .menu-row-current .menu-item-41,
.news-template-41 .menu .menu-row-current .menu-item-2,
.news-template-41 .menu .menu-row-current-border .menu-item-41,
.news-template-41 .menu .menu-row-current-border .menu-item-2, .news-template-41-without-time .menu .menu-row .menu-item-41,
.news-template-41-without-time .menu .menu-row .menu-item-2,
.news-template-41-without-time .menu .menu-row-current .menu-item-41,
.news-template-41-without-time .menu .menu-row-current .menu-item-2,
.news-template-41-without-time .menu .menu-row-current-border .menu-item-41,
.news-template-41-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-41 .menu .menu-row-current,
.news-template-41 .menu .menu-row-current-border, .news-template-41-without-time .menu .menu-row-current,
.news-template-41-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-41 .menu .menu-item-3, .news-template-41-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-41 .img-figure,
.news-template-41 .img-figure-no-img,
.news-template-41 .img-figure-template-type-2, .news-template-41-without-time .img-figure,
.news-template-41-without-time .img-figure-no-img,
.news-template-41-without-time .img-figure-template-type-2 {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.news-template-41 .img-figure-2, .news-template-41-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-41 .post-list, .news-template-41-without-time .post-list {
  display: flex;
  flex-wrap: wrap;
}
.news-template-41 .post-list .k-pager-info, .news-template-41 .post-list .k-pager-sizes, .news-template-41-without-time .post-list .k-pager-info, .news-template-41-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-41 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-41-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-41 .post-list .k-pager .k-state-selected, .news-template-41-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-41 .post-list .top-part, .news-template-41-without-time .post-list .top-part {
  display: flex;
  flex-direction: column;
}
.news-template-41 .uppercase, .news-template-41-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-41 .top-new-item, .news-template-41-without-time .top-new-item {
  margin: 5px 0;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-41 .top-new-item .title:hover, .news-template-41-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-41 .close-div, .news-template-41-without-time .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-41 .news-template-41-dialog, .news-template-41-without-time .news-template-41-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-41 .news-template-41-dialog .close-icon-div, .news-template-41-without-time .news-template-41-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-41 .news-template-41-dialog .close-icon, .news-template-41-without-time .news-template-41-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-41 .news-template-41-dialog .title-info-req, .news-template-41-without-time .news-template-41-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-41 .input-label, .news-template-41-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-41 .btn-delete, .news-template-41-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-41 .btn-cancel, .news-template-41-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-41 .dialog-width, .news-template-41-without-time .dialog-width {
  max-width: 400px;
}
.news-template-41 .space-box, .news-template-41-without-time .space-box {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .news-template-41 .img-figure, .news-template-41-without-time .img-figure {
    height: auto !important;
  }
  .news-template-41 .post-list, .news-template-41-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.news-template-42 {
  width: 100%;
  margin-top: 50px;
}
.news-template-42 .row {
  --bs-gutter-x: 0;
}
.news-template-42 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-42 .k-scrollview-pageable > .k-button.k-primary, .news-template-42 .k-scrollview-nav > .k-link.k-primary {
  background: #0084C7;
  border: #0084C7;
}
.news-template-42 .k-scrollview-pageable > .k-button, .news-template-42 .k-scrollview-nav > .k-link {
  background: #D9D9D9;
}
.news-template-42 .post-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-42 .title-text {
  margin-bottom: 0 !important;
}
.news-template-42 .post-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
}
.news-template-42 .post-more a {
  color: #d6a022;
}
.news-template-42 .pointer {
  cursor: pointer;
}
.news-template-42 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-42 .title {
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: #292621;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  max-width: 55%;
  margin: 10px 0;
}
.news-template-42 .post-image {
  height: 100px;
  width: 100px;
}
.news-template-42 .post-image-default {
  height: 100px;
  width: 100px;
}
.news-template-42 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-42 .time-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.news-template-42 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-42 .main-item {
  display: table;
}
.news-template-42 .vertical-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-42 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-42 .d-flex {
  visibility: visible !important;
}
.news-template-42 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.news-template-42 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-42 .menu .menu-row,
.news-template-42 .menu .menu-row-current,
.news-template-42 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-42 .menu .menu-row .menu-item-1,
.news-template-42 .menu .menu-row .menu-item-2,
.news-template-42 .menu .menu-row-current .menu-item-1,
.news-template-42 .menu .menu-row-current .menu-item-2,
.news-template-42 .menu .menu-row-current-border .menu-item-1,
.news-template-42 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.news-template-42 .menu .menu-row-current,
.news-template-42 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-42 .menu .menu-item-3 {
  padding: 5px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.news-template-42 .img-figure,
.news-template-42 .img-figure-no-img {
  height: 100px;
  width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin: 10px;
}
.news-template-42 .post-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
  padding: 0px 17px;
  min-width: 80%;
  max-width: 80%;
}
.news-template-42 .post-list .k-pager-info, .news-template-42 .post-list .k-pager-sizes {
  display: none;
}
.news-template-42 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-42 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-42 .post-list .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-wrap: wrap;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  text-align: center;
}
.news-template-42 .uppercase {
  text-transform: uppercase;
}
.news-template-42 .top-new-item {
  margin: 10px 0;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.news-template-42 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-42 .tooltips {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1;
}
.news-template-42 .tooltips .tooltiptext {
  visibility: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 250px;
  word-wrap: break-word;
  background-color: #0084C7;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 3;
  top: -10px;
  left: 40%;
}
.news-template-42 .tooltips .tooltiptext p {
  margin: 0;
}
.news-template-42 a {
  color: #FFF;
}
.news-template-42 .tooltips .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #0084C7 transparent transparent;
}
.news-template-42 .tooltips:hover .tooltiptext {
  visibility: visible;
}
.news-template-42 .close-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
}
.news-template-42 .news-template-42-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-42 .news-template-42-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-42 .news-template-42-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-42 .news-template-42-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-42 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-42 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-42 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-42 .dialog-width {
  max-width: 400px;
}
.news-template-42 .k-scrollview-next, .news-template-42 .k-scrollview-prev {
  color: white;
  background-color: #0084C7;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 1;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 30px;
  top: calc(50% - 210px);
  width: 36px;
  border-radius: 3px;
}
.news-template-42 .k-scrollview-next .k-icon, .news-template-42 .k-scrollview-prev .k-icon {
  font-size: 1.5em;
  font-weight: normal;
  padding-left: 8px;
}
.news-template-42 .k-scrollview-wrap {
  border: none;
  height: 215px !important;
}
.news-template-42 .dash-line {
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, #DC7E10), color-stop(20%, #E1E1E1), color-stop(80%, #E1E1E1), color-stop(80%, #E1E1E1));
  background: -o-linear-gradient(left, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  height: 2px;
  margin-bottom: 15px;
}
.news-template-42 .text-item {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
  letter-spacing: -0.01em;
  height: 95px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  white-space: pre-line;
}
.news-template-42 .k-scrollview-wrap .k-scrollview > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.news-template-42 .k-scrollview-pageable > .k-button {
  width: 12px;
  height: 12px;
}
.news-template-42 li.ng-tns-c303-1.ng-star-inserted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.news-template-42 .mobile-view {
  display: none;
}
.news-template-42 .web-view {
  display: block;
}
@media (max-width: 768px) {
  .news-template-42 .main-item {
    margin-top: 20px;
  }
  .news-template-42 .col-md-4 {
    width: 32%;
  }
  .news-template-42 .web-view {
    display: none;
  }
  .news-template-42 .mobile-view {
    display: block;
  }
  .news-template-42 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 190px;
    min-width: 100%;
    max-width: 100%;
  }
  .news-template-42 .post-list .top-part {
    border: none;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .news-template-42 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-42 .post-title {
    display: block;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .news-template-42 .main-item {
    margin-top: 20px;
  }
  .news-template-42 .web-view {
    display: none;
  }
  .news-template-42 .mobile-view {
    display: block;
  }
  .news-template-42 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 190px;
    min-width: 100%;
    max-width: 100%;
  }
  .news-template-42 .post-list .top-part {
    border: none;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .news-template-42 .img-figure, .news-template-42 .post-image {
    height: 80px;
    width: 80%;
  }
  .news-template-42 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-42 .post-title {
    display: block;
    margin-bottom: 0;
  }
}

.news-template-43-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-43-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-43-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-43-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-43-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-43-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-43-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-43-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-43-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-43-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-43-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-43-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-43-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-43-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-43-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-43-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-43-dialog .float-right {
  float: right;
}

.news-template-43-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-43-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-43-dialog .control {
  margin-bottom: 22px;
}

.news-template-43-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-43-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-43-dialog .k-input {
  height: 38px !important;
}

.news-template-43-dialog .k-clear-value {
  height: 38px !important;
}

.news-template-43-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-43-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-43, .news-template-43-without-time {
  width: 100%;
  margin-bottom: 50px;
}
.news-template-43 .row, .news-template-43-without-time .row {
  --bs-gutter-x: 0;
}
.news-template-43 .k-dialog, .news-template-43-without-time .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-43 .post-title, .news-template-43-without-time .post-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
  margin-bottom: 20px;
}
.news-template-43 .title-text, .news-template-43-without-time .title-text {
  margin-bottom: 0 !important;
}
.news-template-43 .post-more, .news-template-43-without-time .post-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  background-color: #0084C7;
  padding: 5px 19px;
  border-radius: 20px;
  height: 27px;
}
.news-template-43 .post-more a, .news-template-43-without-time .post-more a {
  color: #FFF;
}
.news-template-43 .pointer, .news-template-43-without-time .pointer {
  cursor: pointer;
}
.news-template-43 .icon-clock, .news-template-43-without-time .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-43 .title, .news-template-43-without-time .title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #292621;
  text-decoration: none;
  margin-bottom: 15px !important;
}
.news-template-43 .title-template-type-2, .news-template-43-without-time .title-template-type-2 {
  line-height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.01em;
  color: #2B2B2B;
  margin-bottom: 20px;
}
.news-template-43 .post-image, .news-template-43 .post-image-radius, .news-template-43-without-time .post-image, .news-template-43-without-time .post-image-radius {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.news-template-43 .post-image-radius, .news-template-43-without-time .post-image-radius {
  border-radius: 4px;
}
.news-template-43 .post-image-default, .news-template-43-without-time .post-image-default {
  height: 100%;
  width: 100%;
}
.news-template-43 .post-image-org, .news-template-43-without-time .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-43 .time-clock, .news-template-43-without-time .time-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.news-template-43 .time-text, .news-template-43-without-time .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-43 .main-item, .news-template-43-without-time .main-item {
  display: table;
}
.news-template-43 .vertical-dot, .news-template-43-without-time .vertical-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-43 .main-item:hover .vertical-dot, .news-template-43-without-time .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-43 .d-flex, .news-template-43-without-time .d-flex {
  visibility: visible !important;
}
.news-template-43 .three-dot-area, .news-template-43-without-time .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.news-template-43 .menu, .news-template-43-without-time .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-43 .menu .menu-row,
.news-template-43 .menu .menu-row-current,
.news-template-43 .menu .menu-row-current-border, .news-template-43-without-time .menu .menu-row,
.news-template-43-without-time .menu .menu-row-current,
.news-template-43-without-time .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-43 .menu .menu-row .menu-item-1,
.news-template-43 .menu .menu-row .menu-item-2,
.news-template-43 .menu .menu-row-current .menu-item-1,
.news-template-43 .menu .menu-row-current .menu-item-2,
.news-template-43 .menu .menu-row-current-border .menu-item-1,
.news-template-43 .menu .menu-row-current-border .menu-item-2, .news-template-43-without-time .menu .menu-row .menu-item-1,
.news-template-43-without-time .menu .menu-row .menu-item-2,
.news-template-43-without-time .menu .menu-row-current .menu-item-1,
.news-template-43-without-time .menu .menu-row-current .menu-item-2,
.news-template-43-without-time .menu .menu-row-current-border .menu-item-1,
.news-template-43-without-time .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.news-template-43 .menu .menu-row-current,
.news-template-43 .menu .menu-row-current-border, .news-template-43-without-time .menu .menu-row-current,
.news-template-43-without-time .menu .menu-row-current-border {
  background-color: white;
  color: black;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-43 .menu .menu-item-3, .news-template-43-without-time .menu .menu-item-3 {
  padding: 5px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.news-template-43 figure, .news-template-43-without-time figure {
  margin: 0;
}
.news-template-43 .img-figure,
.news-template-43 .img-figure-no-img,
.news-template-43 .img-figure-template-type-2, .news-template-43-without-time .img-figure,
.news-template-43-without-time .img-figure-no-img,
.news-template-43-without-time .img-figure-template-type-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-43 .img-figure-2, .news-template-43-without-time .img-figure-2 {
  height: 260px !important;
}
.news-template-43 .k-scrollview-wrap, .news-template-43-without-time .k-scrollview-wrap {
  border: none;
  overflow: visible;
}
.news-template-43 .k-scrollview-prev,
.news-template-43 .k-scrollview-next, .news-template-43-without-time .k-scrollview-prev,
.news-template-43-without-time .k-scrollview-next {
  height: auto !important;
  top: 40% !important;
  opacity: 1;
}
.news-template-43 .k-scrollview-prev .k-icon,
.news-template-43 .k-scrollview-next .k-icon, .news-template-43-without-time .k-scrollview-prev .k-icon,
.news-template-43-without-time .k-scrollview-next .k-icon {
  background-color: #025acd;
  border-radius: 50%;
  font-size: 3.5em !important;
}
.news-template-43 .k-scrollview-prev, .news-template-43-without-time .k-scrollview-prev {
  left: -5%;
}
.news-template-43 .k-scrollview-next, .news-template-43-without-time .k-scrollview-next {
  right: -5%;
}
.news-template-43 .banner, .news-template-43-without-time .banner {
  padding: 0 20px;
}
.news-template-43 .banner img, .news-template-43 .banner .info, .news-template-43-without-time .banner img, .news-template-43-without-time .banner .info {
  border-radius: 4px;
}
.news-template-43 .banner .img-1,
.news-template-43 .banner .img-2, .news-template-43-without-time .banner .img-1,
.news-template-43-without-time .banner .img-2 {
  width: 50%;
  object-fit: fill;
}
.news-template-43 .banner .img-1, .news-template-43-without-time .banner .img-1 {
  padding-right: 1%;
}
.news-template-43 .banner .img-2, .news-template-43-without-time .banner .img-2 {
  padding-left: 1%;
}
.news-template-43 .banner .main-img, .news-template-43-without-time .banner .main-img {
  width: 100%;
  padding-left: 3%;
  object-fit: fill;
}
.news-template-43 .banner .info, .news-template-43-without-time .banner .info {
  color: #FFF;
  font-style: normal;
  line-height: normal;
  background: #0dbfbb;
  overflow: hidden;
  position: relative;
  padding: 25px;
}
.news-template-43 .banner .info .info-bg, .news-template-43-without-time .banner .info .info-bg {
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.news-template-43 .banner .info .info-content, .news-template-43-without-time .banner .info .info-content {
  position: relative;
}
.news-template-43 .banner .info .info-content .menu-title, .news-template-43-without-time .banner .info .info-content .menu-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  white-space: normal;
}
.news-template-43 .banner .info .info-content .description, .news-template-43-without-time .banner .info .info-content .description {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  white-space: normal;
}
.news-template-43 .banner .info .info-content .description .inner-des, .news-template-43-without-time .banner .info .info-content .description .inner-des {
  position: relative;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.news-template-43 .banner .info .info-content .view-detail, .news-template-43-without-time .banner .info .info-content .view-detail {
  font-size: 14px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 8px 12px;
  width: fit-content;
}
.news-template-43 .no-data, .news-template-43-without-time .no-data {
  margin-top: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #006CB5;
}
.news-template-43 .post-list, .news-template-43-without-time .post-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 20px 20px 20px 60px;
}
.news-template-43 .post-list .k-pager-info, .news-template-43 .post-list .k-pager-sizes, .news-template-43-without-time .post-list .k-pager-info, .news-template-43-without-time .post-list .k-pager-sizes {
  display: none;
}
.news-template-43 .post-list .k-pager kendo-datapager-prev-buttons, .news-template-43-without-time .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-43 .post-list .k-pager .k-state-selected, .news-template-43-without-time .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-43 .post-list .top-part, .news-template-43-without-time .post-list .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.news-template-43 .line, .news-template-43-without-time .line {
  height: 5px;
  border-top: 1px solid #D9D9D9;
  margin: 20px;
}
.news-template-43 .info-part, .news-template-43-without-time .info-part {
  width: 700px;
  margin-top: 20px;
}
.news-template-43 .date, .news-template-43-without-time .date {
  font-size: 26px;
  font-weight: 600;
  border-bottom: 1px solid;
  width: 53%;
  margin-bottom: 5px;
}
.news-template-43 .month, .news-template-43-without-time .month {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.news-template-43 .img-figure, .news-template-43-without-time .img-figure {
  height: 200px;
  width: 380px;
  margin-right: 15px;
}
.news-template-43 .uppercase, .news-template-43-without-time .uppercase {
  text-transform: uppercase;
}
.news-template-43 .top-new-item, .news-template-43-without-time .top-new-item {
  margin: 5px 0;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.news-template-43 .top-new-item .title:hover, .news-template-43-without-time .top-new-item .title:hover {
  color: #00396b;
}
.news-template-43 .sub-info, .news-template-43-without-time .sub-info {
  position: relative;
}
.news-template-43 .care, .news-template-43-without-time .care {
  width: 85px;
  padding: 15px 8px;
  height: 25px;
  line-height: 0.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background-color: #0084C7;
  border-radius: 20px;
  margin-left: calc(100% - 85px);
  position: absolute;
  top: 80px;
  left: 0;
}
.news-template-43 .care a, .news-template-43-without-time .care a {
  color: #FFF;
}
.news-template-43 .close-div, .news-template-43-without-time .close-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
}
.news-template-43 .news-template-43-dialog, .news-template-43-without-time .news-template-43-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-43 .news-template-43-dialog .close-icon-div, .news-template-43-without-time .news-template-43-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-43 .news-template-43-dialog .close-icon, .news-template-43-without-time .news-template-43-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-43 .news-template-43-dialog .title-info-req, .news-template-43-without-time .news-template-43-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-43 .input-label, .news-template-43-without-time .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-43 .btn-delete, .news-template-43-without-time .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-43 .btn-cancel, .news-template-43-without-time .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-43 .dialog-width, .news-template-43-without-time .dialog-width {
  max-width: 400px;
}
.news-template-43 .space-box, .news-template-43-without-time .space-box {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .news-template-43 .main-item, .news-template-43-without-time .main-item {
    margin: 10px;
  }
  .news-template-43 .img-figure, .news-template-43-without-time .img-figure {
    height: auto !important;
    width: 240px;
  }
  .news-template-43 .post-list, .news-template-43-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0;
  }
  .news-template-43 .info-part, .news-template-43-without-time .info-part {
    width: 400px;
  }
  .news-template-43 .post-title, .news-template-43-without-time .post-title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0 !important;
  }
  .news-template-43 .k-scrollview-prev,
.news-template-43 .k-scrollview-next, .news-template-43-without-time .k-scrollview-prev,
.news-template-43-without-time .k-scrollview-next {
    top: -7% !important;
  }
  .news-template-43 .k-scrollview-prev .k-icon,
.news-template-43 .k-scrollview-next .k-icon, .news-template-43-without-time .k-scrollview-prev .k-icon,
.news-template-43-without-time .k-scrollview-next .k-icon {
    background-color: white;
    color: black;
    font-size: 2.1em !important;
  }
  .news-template-43 .k-scrollview-prev, .news-template-43-without-time .k-scrollview-prev {
    left: 80%;
  }
  .news-template-43 .k-scrollview-next, .news-template-43-without-time .k-scrollview-next {
    right: 0px;
  }
  .news-template-43 .banner, .news-template-43-without-time .banner {
    padding: 0 !important;
  }
  .news-template-43 .banner .img-1,
.news-template-43 .banner .img-2, .news-template-43-without-time .banner .img-1,
.news-template-43-without-time .banner .img-2 {
    width: 100% !important;
  }
  .news-template-43 .banner .main-img, .news-template-43-without-time .banner .main-img {
    padding-left: 0 !important;
  }
  .news-template-43 .banner .info .info-content, .news-template-43-without-time .banner .info .info-content {
    padding: 0 15px;
  }
  .news-template-43 .banner .info .info-content .description .inner-des, .news-template-43-without-time .banner .info .info-content .description .inner-des {
    -webkit-line-clamp: 4 !important;
  }
  .news-template-43 .banner .info .info-bg, .news-template-43-without-time .banner .info .info-bg {
    left: -50%;
    width: 150%;
  }
}
@media screen and (max-width: 576px) {
  .news-template-43 .date-part, .news-template-43-without-time .date-part {
    display: none;
  }
  .news-template-43 .img-figure, .news-template-43-without-time .img-figure {
    height: 90px;
    width: 130px;
    margin-right: 10px;
  }
  .news-template-43 .main-item, .news-template-43-without-time .main-item {
    margin: 0px;
  }
  .news-template-43 .info-part, .news-template-43-without-time .info-part {
    width: 65%;
    margin-top: 0;
    font-size: 12px !important;
  }
  .news-template-43 .title, .news-template-43-without-time .title {
    font-size: 12px;
    margin-bottom: 0 !important;
  }
  .news-template-43 p, .news-template-43-without-time p {
    margin-bottom: 0;
  }
  .news-template-43 .post-list, .news-template-43-without-time .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 0;
  }
  .news-template-43 .care, .news-template-43-without-time .care {
    display: none;
  }
  .news-template-43 .post-title, .news-template-43-without-time .post-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .news-template-43 .post-more, .news-template-43-without-time .post-more {
    background-color: transparent;
    color: #D9D9D9;
    padding: 0;
  }
}

.news-template-44 {
  width: 100%;
  margin-bottom: 20px;
}
.news-template-44 .row {
  --bs-gutter-x: 0;
}
.news-template-44 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-44 .k-scrollview-wrap {
  background-color: #08D5D0;
  height: 235px;
}
.news-template-44 .post-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 20px;
}
.news-template-44 .title-text {
  margin-bottom: 0 !important;
}
.news-template-44 .unit-name {
  color: #FFF;
  text-align: center;
}
.news-template-44 .post-more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
}
.news-template-44 .post-more a {
  color: #d6a022;
}
.news-template-44 .pointer {
  cursor: pointer;
}
.news-template-44 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-44 .title {
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  max-width: 100%;
  margin: 10px 0;
  text-align: center;
  height: 20px;
}
.news-template-44 .post-image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
}
.news-template-44 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-44 .time-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.news-template-44 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-44 .main-item {
  display: table;
}
.news-template-44 .vertical-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-44 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-44 .d-flex {
  visibility: visible !important;
}
.news-template-44 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.news-template-44 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-44 .menu .menu-row,
.news-template-44 .menu .menu-row-current,
.news-template-44 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-44 .menu .menu-row .menu-item-1,
.news-template-44 .menu .menu-row .menu-item-2,
.news-template-44 .menu .menu-row-current .menu-item-1,
.news-template-44 .menu .menu-row-current .menu-item-2,
.news-template-44 .menu .menu-row-current-border .menu-item-1,
.news-template-44 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.news-template-44 .menu .menu-row-current,
.news-template-44 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-44 .menu .menu-item-3 {
  padding: 5px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.news-template-44 .img-figure,
.news-template-44 .img-figure-no-img {
  height: 100px;
  width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin: 10px;
}
.news-template-44 .img-figure-2 {
  height: 100px;
}
.news-template-44 .post-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 50px;
  background-color: #08D5D0;
  border-radius: 5px;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.news-template-44 .post-list .k-pager-info, .news-template-44 .post-list .k-pager-sizes {
  display: none;
}
.news-template-44 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-44 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-44 .post-list .top-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-wrap: wrap;
  width: 100%;
}
.news-template-44 .uppercase {
  text-transform: uppercase;
}
.news-template-44 .top-new-item {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 20%;
}
.news-template-44 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-44 .close-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
}
.news-template-44 .news-template-44-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-44 .news-template-44-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-44 .news-template-44-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-44 .news-template-44-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-44 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-44 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-44 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-44 .dialog-width {
  max-width: 400px;
}
.news-template-44 .k-scrollview-next, .news-template-44 .k-scrollview-prev {
  color: white;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 1;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 30px;
  top: calc(50% + 10px);
  width: 70px;
  border-radius: 3px;
}
.news-template-44 .k-scrollview-next .k-icon, .news-template-44 .k-scrollview-prev .k-icon {
  font-size: 2.5em;
  font-weight: normal;
  padding-left: 8px;
}
.news-template-44 .k-scrollview-wrap {
  border: none;
}
.news-template-44 ul.k-scrollview-pageable {
  display: none;
}
.news-template-44 .dash-line {
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, #DC7E10), color-stop(20%, #E1E1E1), color-stop(80%, #E1E1E1), color-stop(80%, #E1E1E1));
  background: -o-linear-gradient(left, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  height: 2px;
  margin-bottom: 15px;
}
.news-template-44 .text-item {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
  letter-spacing: -0.01em;
  height: 95px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  white-space: pre-line;
}
.news-template-44 .view-more {
  background-color: #08D5D0;
  text-align: center;
  padding: 10px 10px 30px 10px;
  margin-bottom: 30px;
  border-radius: 0 0 5px 5px;
}
.news-template-44 .view-more-button {
  background-color: #fff;
  width: 110px;
  text-align: center;
  padding: 2px;
  border-radius: 15px;
  margin-left: calc(50% - 48px);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.news-template-44 .k-scrollview-pageable > .k-button {
  width: 12px;
  height: 12px;
}
.news-template-44 li.ng-tns-c303-1.ng-star-inserted {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.news-template-44 .news-template-44-dialog .close-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.news-template-44 .mobile-view {
  display: none;
}
.news-template-44 .web-view {
  display: block;
}
@media (max-width: 768px) {
  .news-template-44 .web-view {
    display: none;
  }
  .news-template-44 .mobile-view {
    display: block;
  }
  .news-template-44 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: auto;
    height: 215px;
    padding-bottom: 10px;
  }
  .news-template-44 .post-list .top-part {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px;
  }
  .news-template-44 .view-more {
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin-bottom: 0px;
    border-radius: 0;
  }
  .news-template-44 .view-more-button {
    position: absolute;
    top: 93%;
    left: 50%;
    margin: -25px 0 0 -50px;
  }
  .news-template-44 .img-figure, .news-template-44 .img-figure-no-img {
    height: 150px;
    width: 150px;
  }
  .news-template-44 .title-first {
    top: 15vh;
    left: 15px;
    width: 95%;
  }
  .news-template-44 .menu-name {
    width: 95%;
  }
  .news-template-44 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-44 .post-title {
    display: block;
    margin-bottom: 0;
  }
  .news-template-44 .post-image {
    height: 150px;
    width: 150px;
  }
  .news-template-44 .title, .news-template-44 .unit-name {
    max-width: 95%;
    text-align: left;
  }
}
@media (max-width: 576px) {
  .news-template-44 .web-view {
    display: none;
  }
  .news-template-44 .mobile-view {
    display: block;
  }
  .news-template-44 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: auto;
    height: 165px;
    padding-bottom: 10px;
  }
  .news-template-44 .post-list .top-part {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 10px;
  }
  .news-template-44 .view-more {
    background-color: transparent;
    text-align: center;
    padding: 0;
    margin-bottom: 0px;
    border-radius: 0;
  }
  .news-template-44 .view-more-button {
    position: absolute;
    top: 93%;
    left: 50%;
    margin: -25px 0 0 -50px;
  }
  .news-template-44 .img-figure {
    height: 100px;
  }
  .news-template-44 .title-first {
    top: 15vh;
    left: 15px;
    width: 95%;
  }
  .news-template-44 .menu-name {
    width: 95%;
  }
  .news-template-44 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-44 .post-title {
    display: block;
    margin-bottom: 0;
  }
  .news-template-44 .post-image {
    height: 100px;
    width: 100px;
  }
  .news-template-44 .title, .news-template-44 .unit-name {
    max-width: 95%;
    text-align: left;
  }
}

.news-template-45 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-45 .row {
  --bs-gutter-x: 0;
}
.news-template-45 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-45 .pd-l-10 {
  padding-left: 18px;
}
.news-template-45 .post-title {
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  position: relative;
  min-height: 40px;
  width: 100%;
}
.news-template-45 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-45 .post-more a {
  color: #EB2E35;
}
.news-template-45 .pointer {
  cursor: pointer;
}
.news-template-45 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-45 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
}
.news-template-45 .post-image {
  height: 198px;
  width: 100%;
  object-fit: cover;
}
.news-template-45 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-45 .time-clock {
  display: flex;
}
.news-template-45 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-45 .main-item {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
}
.news-template-45 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-45 .d-flex {
  visibility: visible !important;
}
.news-template-45 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-45 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-45 .dialog-width {
  max-width: 400px;
}
.news-template-45 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-45 .menu .menu-row,
.news-template-45 .menu .menu-row-current,
.news-template-45 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-45 .menu .menu-row .menu-item-1,
.news-template-45 .menu .menu-row .menu-item-2,
.news-template-45 .menu .menu-row-current .menu-item-1,
.news-template-45 .menu .menu-row-current .menu-item-2,
.news-template-45 .menu .menu-row-current-border .menu-item-1,
.news-template-45 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-45 .menu .menu-row-current,
.news-template-45 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-45 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-45 .img-figure, .news-template-45 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-45 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-45 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-45 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-45 .news-template-45-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-45 .news-template-45-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-45 .news-template-45-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-45 .news-template-45-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-45 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-45 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-45 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-45 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-45 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-45 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-45 .text-section :hover {
  color: #00396B;
}
.news-template-45 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
}
.news-template-45 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-45 .is-current-folder1 {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #575757;
}
.news-template-45 .is-current-folder {
  font-weight: 600;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #00396b;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #006CB5;
}
.news-template-45 .img {
  margin: 2px 12px 6px 12px;
}
.news-template-45 .btn-fl {
  float: left;
  margin: 20px 0px 20px 0px;
}
.news-template-45 .btn-search {
  color: white;
  background-color: #006CB5;
  border: hidden;
  padding: 9px 20px;
  margin-left: 0 !important;
  font-size: 14px !important;
  font-weight: 600;
  width: 100%;
}
.news-template-45 .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 23px;
  float: right;
  border-radius: 0;
  margin-top: -33px;
  border: 0;
}
.news-template-45 .search-container {
  display: flex;
  align-items: flex-end;
  width: 80vw;
}
.news-template-45 .search-container .group {
  margin-right: 1rem;
}
.news-template-45 .btn-dialog {
  float: left;
}
.news-template-45 a.field-detail {
  text-decoration: none;
}
.news-template-45 .control label {
  font-size: 18px;
  margin-bottom: 10px;
}
.news-template-45 .control input {
  height: 40px;
}
.news-template-45 .control {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .news-template-45 .pd-l-10 {
    padding-left: 0px;
  }
}
.news-template-45 .uppercase-black {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #00396B;
  margin-bottom: 10px;
  margin-top: 10px;
}
.news-template-45 .intro-des, .news-template-45 .intro-des-reserve {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2vw;
  padding: 30px 0;
  border-bottom: 1px solid #D9D9D9;
}
.news-template-45 .intro-des-reserve {
  flex-direction: row-reverse;
  justify-content: end;
}
.news-template-45 .intro-text, .news-template-45 .intro-text-reverse {
  flex: 1;
  margin-top: 10px;
}
.news-template-45 .intro-text .field-name, .news-template-45 .intro-text-reverse .field-name {
  color: #000000 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin-top: 40px;
}
.news-template-45 .intro-text .field-name:hover, .news-template-45 .intro-text-reverse .field-name:hover {
  color: #00396b !important;
}
.news-template-45 .intro-text .letter, .news-template-45 .intro-text-reverse .letter {
  color: #494949 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  text-align: left;
  margin-top: 10px;
}
.news-template-45 .intro-text .letter:hover, .news-template-45 .intro-text-reverse .letter:hover {
  color: #00396b !important;
}
.news-template-45 .intro-text-reverse {
  width: fit-content;
  flex: none;
}
.news-template-45 .p3 {
  padding: 3.2rem;
}
.news-template-45 .pt3 {
  padding-top: 3.2rem;
}
.news-template-45 .pr3 {
  padding-right: 3.2rem;
}
.news-template-45 .pb3 {
  padding-bottom: 3.2rem;
}
.news-template-45 .pl3 {
  padding-left: 3.2rem;
}
.news-template-45 .image-circle, .news-template-45 .image-circle-no-border {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.news-template-45 .image-circle-no-border {
  border-radius: unset;
}
.news-template-45 .center-img {
  display: flex;
  justify-content: center;
}
@media (max-width: 576px) {
  .news-template-45 .intro-des, .news-template-45 .intro-des-reserve {
    display: block;
  }
  .news-template-45 .image-circle, .news-template-45 .image-circle-no-border {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .news-template-45 .search-container {
    width: 100%;
  }
  .news-template-45 .btn-dialog {
    margin-top: 20px;
    margin-left: 0;
  }
}

.news-template-46-dialog {
  max-width: 500px !important;
  max-height: 75% !important;
  margin-left: 0px !important;
}

.news-template-46-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-46-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-46-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-46-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-46-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-46-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-46-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-46-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-46-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-46-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-46-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-46-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-46-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-46-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-46-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-46-dialog .float-right {
  float: right;
}

.news-template-46-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-46-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-46-dialog .control {
  margin-bottom: 22px;
}

.news-template-46-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-46-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  background-color: white;
}

.news-template-46-dialog .k-input {
  height: 38px !important;
}

.news-template-46-dialog .k-clear-value {
  height: 38px !important;
}

.news-template-46-dialog .btn-save {
  margin: 15px;
  width: 200px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-46-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-46 {
  width: 100%;
  margin-top: 50px;
}
.news-template-46 .row {
  --bs-gutter-x: 0;
}
.news-template-46 .k-dialog {
  height: auto !important;
  width: auto !important;
}
.news-template-46 .k-scrollview-pageable > .k-button.k-primary, .news-template-46 .k-scrollview-nav > .k-link.k-primary {
  background: #0084C7;
  border: #0084C7;
}
.news-template-46 .k-scrollview-pageable > .k-button, .news-template-46 .k-scrollview-nav > .k-link {
  background: #D9D9D9;
}
.news-template-46 .post-title {
  display: flex;
  justify-content: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
}
.news-template-46 .title-text {
  margin-bottom: 0 !important;
}
.news-template-46 .post-more {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  color: #000;
}
.news-template-46 .post-more a {
  color: #d6a022;
}
.news-template-46 .pointer {
  cursor: pointer;
}
.news-template-46 .icon-clock {
  width: 14px;
  height: 14px;
}
.news-template-46 .title {
  line-height: 21px;
  font-style: normal;
  font-size: 14px;
  color: #292621;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  max-width: 55%;
  margin: 10px 0;
}
.news-template-46 .post-image {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  object-fit: contain;
  border: 1px solid #0084C7;
}
.news-template-46 .post-image-default {
  height: 100px;
  width: 100px;
}
.news-template-46 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-46 .time-clock {
  display: flex;
}
.news-template-46 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-46 .main-item {
  display: table;
}
.news-template-46 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-46 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-46 .d-flex {
  visibility: visible !important;
}
.news-template-46 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-46 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-46 .menu .menu-row,
.news-template-46 .menu .menu-row-current,
.news-template-46 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-46 .menu .menu-row .menu-item-1,
.news-template-46 .menu .menu-row .menu-item-2,
.news-template-46 .menu .menu-row-current .menu-item-1,
.news-template-46 .menu .menu-row-current .menu-item-2,
.news-template-46 .menu .menu-row-current-border .menu-item-1,
.news-template-46 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-46 .menu .menu-row-current,
.news-template-46 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-46 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-46 .img-figure,
.news-template-46 .img-figure-no-img {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 10px;
}
.news-template-46 .post-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 17px;
  min-width: 50%;
  max-width: 80%;
  justify-content: center;
  gap: 50px;
}
.news-template-46 .post-list .k-pager-info, .news-template-46 .post-list .k-pager-sizes {
  display: none;
}
.news-template-46 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-46 .post-list .k-pager .k-state-selected {
  background-color: #D6A022;
  border: none;
}
.news-template-46 .post-list .top-part {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: wrap;
  border-radius: 5px;
  border: none;
  background: #FFF;
  width: 100%;
  text-align: center;
}
.news-template-46 .uppercase {
  text-transform: uppercase;
}
.news-template-46 .top-new-item {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: fit-content;
}
.news-template-46 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-46 .tooltips {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1;
}
.news-template-46 .tooltips .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #0084C7;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 3;
  top: -10px;
  left: 80%;
}
.news-template-46 .tooltips .tooltiptext p {
  margin: 0;
}
.news-template-46 a {
  color: #FFF;
}
.news-template-46 .tooltips .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #0084C7 transparent transparent;
}
.news-template-46 .tooltips:hover .tooltiptext {
  visibility: visible;
}
.news-template-46 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-46 .news-template-46-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-46 .news-template-46-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-46 .news-template-46-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-46 .news-template-46-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-46 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-46 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-46 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-46 .dialog-width {
  max-width: 400px;
}
.news-template-46 .k-scrollview-next, .news-template-46 .k-scrollview-prev {
  color: white;
  background-color: #0084C7;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
  opacity: 1;
  outline-width: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 30px;
  top: calc(50% - 210px);
  width: 36px;
  border-radius: 3px;
}
.news-template-46 .k-scrollview-next .k-icon, .news-template-46 .k-scrollview-prev .k-icon {
  font-size: 1.5em;
  font-weight: normal;
  padding-left: 8px;
}
.news-template-46 .k-scrollview-wrap {
  border: none;
  height: 190px !important;
}
.news-template-46 .dash-line {
  background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
  height: 2px;
  margin-bottom: 15px;
}
.news-template-46 .text-item {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #696969;
  letter-spacing: -0.01em;
  height: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  white-space: pre-line;
}
.news-template-46 .k-scrollview-wrap .k-scrollview > li {
  display: flex;
  justify-content: center;
}
.news-template-46 .k-scrollview-pageable > .k-button {
  width: 12px;
  height: 12px;
}
.news-template-46 li.ng-tns-c303-1.ng-star-inserted {
  display: flex;
  justify-content: center;
}
.news-template-46 .mobile-view {
  display: none;
}
.news-template-46 .web-view {
  display: block;
}
@media (max-width: 768px) {
  .news-template-46 .main-item {
    margin-top: 20px;
  }
  .news-template-46 .col-md-4 {
    width: 32%;
  }
  .news-template-46 .web-view {
    display: none;
  }
  .news-template-46 .mobile-view {
    display: block;
  }
  .news-template-46 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 190px;
    min-width: 100%;
    max-width: 100%;
  }
  .news-template-46 .post-list .top-part {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  .news-template-46 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-46 .post-title {
    display: block;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .news-template-46 .main-item {
    margin-top: 20px;
  }
  .news-template-46 .web-view {
    display: none;
  }
  .news-template-46 .mobile-view {
    display: block;
  }
  .news-template-46 .post-list {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 190px;
    min-width: 100%;
    max-width: 100%;
  }
  .news-template-46 .post-list .top-part {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  .news-template-46 .img-figure, .news-template-46 .post-image {
    height: 80px;
    width: 80%;
  }
  .news-template-46 .post-more {
    padding: 0px;
    font-size: 18px;
  }
  .news-template-46 .post-title {
    display: block;
    margin-bottom: 0;
  }
}

.news-template-47-dialog {
  margin-left: 0px !important;
}

.news-template-47-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-47-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-47-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-47-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-47-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-47-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-47-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-47-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-47-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-47-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-47-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-47-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-47-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-47-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-47-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-47-dialog .float-right {
  float: right;
}

.news-template-47-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-47-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-47-dialog .control {
  margin-bottom: 22px;
}

.news-template-47-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-47-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-47-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-47-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-47-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-47-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-47 {
  width: 100%;
  background-color: #FFFFFF !important;
}
.news-template-47 .button {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
}
.news-template-47 .row {
  --bs-gutter-x: 0;
}
.news-template-47 .k-dialog {
  height: auto !important;
  width: 400px !important;
}
.news-template-47 .pd-l-10 {
  padding-left: 18px;
}
.news-template-47 .post-title {
  display: flex;
  align-items: center;
  color: #00396b;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  background-color: #00396B;
  position: relative;
  min-height: 40px;
}
.news-template-47 .post-more {
  display: flex;
  justify-content: center;
  color: #EB2E35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-47 .post-more a {
  color: #EB2E35;
}
.news-template-47 .pointer {
  cursor: pointer;
}
.news-template-47 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-47 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1F1F1F;
  margin-left: 16px;
  margin-bottom: 0;
  text-decoration: none;
}
.news-template-47 .post-image {
  height: 45px;
  width: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-47 .post-image-cover {
  object-fit: cover;
  width: 48px;
}
.news-template-47 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-47 .time-clock {
  display: flex;
}
.news-template-47 .time-text {
  color: #979797;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.news-template-47 .main-item {
  display: table;
  margin: 0;
}
.news-template-47 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-47 .d-flex {
  visibility: visible !important;
}
.news-template-47 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-47 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-47 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-47 .menu .menu-row,
.news-template-47 .menu .menu-row-current,
.news-template-47 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-47 .menu .menu-row .menu-item-1,
.news-template-47 .menu .menu-row .menu-item-2,
.news-template-47 .menu .menu-row-current .menu-item-1,
.news-template-47 .menu .menu-row-current .menu-item-2,
.news-template-47 .menu .menu-row-current-border .menu-item-1,
.news-template-47 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-47 .menu .menu-row-current,
.news-template-47 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-47 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-47 .img-figure, .news-template-47 .img-figure-no-img {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  width: 48px;
  height: 48px;
  border: 1px solid #D8D8D8;
  border-radius: 100%;
  overflow: hidden;
  background: #FFFFFF;
}
.news-template-47 .img-figure:hover .post-image, .news-template-47 .img-figure-no-img:hover .post-image {
  width: 60%;
  height: 60%;
  margin-top: 9px;
}
.news-template-47 .uppercase {
  color: #FFFFFF;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.news-template-47 .top-new-item {
  margin: 15px 0 0 0;
}
.news-template-47 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-47 .news-template-47-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-47 .news-template-47-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-47 .news-template-47-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-47 .news-template-47-dialog .title-info-req {
  font-weight: 500;
  color: #004A9A;
}
.news-template-47 .input-label {
  color: #004A9A;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-47 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-47 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-47 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-47 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-47 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.news-template-47 .text-section :hover {
  color: #00396B;
}
.news-template-47 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #00396B;
  margin-bottom: 9px !important;
  text-decoration: none;
}
.news-template-47 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-47 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-47 .fixed-item {
  position: fixed;
  padding: 0px 0px 0 0;
  left: calc(100% - 60px);
  top: 65%;
  transform: translateX(-50%);
  z-index: 1001;
}
.news-template-47 .pop-up {
  background-color: #FFFFFF;
  position: absolute;
  min-width: 200px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px);
}
.news-template-47 .pop-up::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
}
.news-template-47 .pop-up-hotline {
  left: -158px;
  top: -89px;
}
.news-template-47 .pop-up-email {
  left: -210px;
  top: -20px;
}
.news-template-47 .pop-up-title {
  background-color: #025acd;
  color: #FFFFFF;
  padding: 5px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news-template-47 .pop-up-content {
  background-color: #FFFFFF;
  display: flex;
  padding: 10px;
}
.news-template-47 .pop-up-content img {
  width: 30px;
  margin-right: 5px;
}
.news-template-47 .hotline {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 700;
}
.news-template-47 .gray-text {
  color: #808080;
  font-weight: 400;
}
.news-template-47 .close-button {
  cursor: pointer;
  margin-right: 3px;
}
.news-template-47 .close-button img {
  width: 10px;
}
.news-template-47 .circles {
  height: 45px;
  width: 45px;
}
.news-template-47 .circles .i {
  animation: growAndFade 3s infinite ease-out;
  background-color: #aaaaaa;
  border-radius: 50%;
  height: 60%;
  opacity: 0;
  position: absolute;
  width: 120%;
  top: 2%;
  left: -23%;
}
.news-template-47 .circles .circle1 {
  animation-delay: 1s;
}
.news-template-47 .circles-1 {
  height: 45px;
  width: 45px;
}
.news-template-47 .circles-1 .i {
  animation: growAndFade 3s infinite ease-out;
  background-color: #aaaaaa;
  border-radius: 50%;
  height: 60%;
  opacity: 0;
  position: absolute;
  width: 120%;
  top: 53%;
  left: -23%;
}
.news-template-47 .circles-1 .circle1-1 {
  animation-delay: 1s;
}
@keyframes growAndFade {
  0% {
    opacity: 0.25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@media (max-width: 768px) {
  .news-template-47 .fixed-item {
    top: 70%;
    right: 0px;
    left: auto;
  }
}

.news-template-48-dialog {
  margin-left: 0px !important;
}

.news-template-48-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-48-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-48-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-48-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-48-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-48-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-48-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-48-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-48-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-48-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-48-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-48-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-48-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-48-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-48-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-48-dialog .float-right {
  float: right;
}

.news-template-48-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-48-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-48-dialog .control {
  margin-bottom: 22px;
}

.news-template-48-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-48-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-48-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-48-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-48-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-48 {
  width: 100%;
  position: relative;
}
.news-template-48 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 12px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-48 .vertical-dot .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-48 .vertical-dot .menu {
  background-color: white;
  cursor: pointer;
  width: 130px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-48 .vertical-dot .menu .menu-row,
.news-template-48 .vertical-dot .menu .menu-row-current,
.news-template-48 .vertical-dot .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-48 .vertical-dot .menu .menu-row .menu-item-1,
.news-template-48 .vertical-dot .menu .menu-row .menu-item-2,
.news-template-48 .vertical-dot .menu .menu-row-current .menu-item-1,
.news-template-48 .vertical-dot .menu .menu-row-current .menu-item-2,
.news-template-48 .vertical-dot .menu .menu-row-current-border .menu-item-1,
.news-template-48 .vertical-dot .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-48 .vertical-dot .menu .menu-row-current,
.news-template-48 .vertical-dot .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-48 .vertical-dot .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-48 .d-flex {
  visibility: visible !important;
}
.news-template-48 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-48 .reward-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-48 .reward-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-48 .reward-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-48 .reward-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-48 .background-img {
  position: relative;
}
.news-template-48 .background-img img {
  width: 100%;
}
.news-template-48 .history {
  width: 100%;
  margin: 0 0 100px 0;
}
.news-template-48 .history img {
  width: 100%;
}
.news-template-48 .news {
  border-bottom: 1px solid black;
}
.news-template-48 .dialog-width {
  max-width: 400px;
}
.news-template-48 .uppercase {
  height: 45px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #006CB5;
  text-transform: uppercase;
}
.news-template-48 .news-title {
  width: fit-content;
}
.news-template-48 .tab-container {
  display: grid;
  border: 1px solid var(--neutral-05, #E3E7EA);
  margin: 15px 0;
  cursor: pointer;
}
.news-template-48 .item-image {
  justify-self: center;
  margin: 20px;
}
.news-template-48 .item-name {
  justify-self: center;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
}

@media screen and (max-width: 576px) {
  .news-template-48 .uppercase {
    margin-top: 15px;
    font-size: 25px;
  }
  .news-template-48 .tab-container {
    height: 90%;
    margin: 10px;
  }
  .news-template-48 .item-name {
    font-size: 15px;
  }
}
app-news-template-48 {
  position: relative;
  top: -6px;
}

.news-template-49-dialog {
  margin-left: 0px !important;
}

.news-template-49-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-49-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-49-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-49-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-49-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-49-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-49-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-49-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-49-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-49-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-49-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-49-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-49-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-49-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-49-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-49-dialog .float-right {
  float: right;
}

.news-template-49-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-49-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-49-dialog .control {
  margin-bottom: 22px;
}

.news-template-49-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-49-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-49-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-49-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-49-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-49-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-49 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-49 .row {
  --bs-gutter-x: 0;
}
.news-template-49 .k-dialog {
  height: 100%;
  width: 100%;
}
.news-template-49 .pd-l-10 {
  padding-left: 18px;
}
.news-template-49 .menu-col {
  padding-right: 10px;
}
.news-template-49 .post-title {
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ee4623;
  position: relative;
}
.news-template-49 .post-title .nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 6px;
}
.news-template-49 .post-title .nav-item,
.news-template-49 .post-title .nav-item-selected {
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}
.news-template-49 .post-title .nav-item-selected {
  border: none;
  font-weight: 700;
  color: #007fae;
}
@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.news-template-49 .post-title .nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  margin-bottom: 15px;
  animation: mykey 0.3s ease-out;
}
.news-template-49 .post-title .menu-bar {
  background-color: #ffffff;
  align-items: center;
  color: #494949;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 12px;
  padding-left: 0px;
  padding-top: 14px;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
}
.news-template-49 .post-title .menu-bar:hover {
  overflow-x: auto;
}
.news-template-49 .post-title .menu-bar::-webkit-scrollbar {
  height: 4px;
}
.news-template-49 .post-title .menu-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.news-template-49 .post-title .menu-bar::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.news-template-49 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-49 .post-more a {
  color: #eb2e35;
}
.news-template-49 .pointer {
  cursor: pointer;
}
.news-template-49 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-49 .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-49 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1f1f1f;
  margin-bottom: 0;
}
.news-template-49 .post-image,
.news-template-49 .post-image-cover {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
.news-template-49 .post-image-cover {
  object-fit: cover;
}
.news-template-49 .post-right-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.news-template-49 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-49 .time-clock {
  display: flex;
  align-items: center;
}
.news-template-49 .clock {
  width: 15px;
  margin-right: 5px;
}
.news-template-49 .time-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5f717b;
  padding: 0;
  margin: 0;
}
.news-template-49 .main-item {
  margin: 0;
  width: 100%;
}
.news-template-49 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-49 .d-flex {
  visibility: visible !important;
}
.news-template-49 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-49 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-49 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-49 .menu .menu-row,
.news-template-49 .menu .menu-row-current,
.news-template-49 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-49 .menu .menu-row .menu-item-1,
.news-template-49 .menu .menu-row .menu-item-2,
.news-template-49 .menu .menu-row-current .menu-item-1,
.news-template-49 .menu .menu-row-current .menu-item-2,
.news-template-49 .menu .menu-row-current-border .menu-item-1,
.news-template-49 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-49 .menu .menu-row-current,
.news-template-49 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-49 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-49 .img-figure,
.news-template-49 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-49 .uppercase {
  height: 45px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #006cb5;
  text-transform: uppercase;
}
.news-template-49 .k-scrollview-wrap {
  border: none;
}
.news-template-49 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-49 .news-template-49-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-49 .news-template-49-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-49 .news-template-49-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-49 .news-template-49-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-49 .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-49 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-49 .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ee4623;
  border-radius: 3px;
}
.news-template-49 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-49 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-49 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-49 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-49 .text-section :hover {
  color: #00396b;
}
.news-template-49 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 0px !important;
}
.news-template-49 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #454545;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-49 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-49 .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-49 .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4d4d4d;
  padding-left: 3px;
}
.news-template-49 .top-new-first-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-49 .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-49 .top-new-item {
  margin-bottom: 47px;
  justify-content: space-between;
  text-decoration: none;
}
.news-template-49 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-49 .post-list {
  margin-right: -20px;
}
.news-template-49 .post-list .k-pager-info,
.news-template-49 .post-list .k-pager-sizes {
  display: none;
}
.news-template-49 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-49 .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-49 .post-list .top-part {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  height: 100%;
}
.news-template-49 .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-49 .post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-49 .first-post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-49 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-49 .hide-item {
  display: none;
}
.news-template-49 .line {
  border: 1px solid #dc7e10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-49 .title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  margin-bottom: 16px;
  min-height: 80px;
}
.news-template-49 .summary {
  font-weight: 400;
  font-size: 16px;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}
.news-template-49 .news-title {
  width: fit-content;
}
.news-template-49 .left-box {
  margin-right: 20px;
}
.news-template-49 .right-item-detail {
  height: 50%;
}
.news-template-49 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
}
.news-template-49 .pointer {
  cursor: pointer;
}
.news-template-49 .video-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-template-49 .dialog-width {
  max-width: 400px;
  max-height: 354px;
}
.news-template-49 .dialog-width-video {
  width: 1040px;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-49 .width-full {
  width: 100%;
  position: relative;
}
.news-template-49 .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 20px;
}
.news-template-49 .download-radio {
  width: 32px;
  height: 32px;
  min-width: 32px;
  position: relative;
  border-radius: 32px;
  background: var(--1, linear-gradient(180deg, #006CB5 0%, #00AEEF 100%));
  border: none;
}
.news-template-49 .download-radio-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-49 .radio-container {
  align-items: center;
  display: flex;
  margin: 10px 0;
}
.news-template-49 .radio-info {
  margin-right: 6%;
  max-width: 42%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-49 .album-name {
  font-style: normal;
  font-weight: 700;
  color: #3F3F3F;
  font-size: 16px;
  font-weight: 700;
}
.news-template-49 .author-name {
  max-width: 4.5%;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.news-template-49 .radio-order {
  color: #006CB5;
  font-size: 16px;
  font-weight: 700;
  width: 2%;
}
.news-template-49 .radio-duration {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.news-template-49 .view-count-icon {
  max-width: 1.2%;
  margin: 0 0.7% 0 7%;
  color: #C2C2C2;
}
.news-template-49 .view-count {
  color: #C2C2C2;
  font-size: 16px;
  font-weight: 700;
  margin-right: 6%;
  max-width: 3%;
  width: 100%;
}
.news-template-49 .media {
  position: relative;
  width: 6%;
  margin: 0 2% 0 1%;
  border: #E3E7EA 1px solid;
  border-radius: 5px;
}
.news-template-49 .post-image-cover {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 5px;
}
.news-template-49 audio {
  height: 40px;
  width: 350px;
}
@media screen and (max-width: 1200px) {
  .news-template-49 .pd-l-10 {
    padding-left: 0px;
  }
  .news-template-49 .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-49 .video-title {
    font-size: 18px !important;
  }
  .news-template-49 .post-more {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .news-template-49 .dialog-width-video {
    max-width: 90% !important;
    max-height: 317px !important;
  }
  .news-template-49 .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-49 .close-icon-div {
    position: relative;
    float: right;
    top: -30px;
  }
  .news-template-49 .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute !important;
    flex: 1 1 auto;
  }
  .news-template-49 .k-dialog {
    position: initial !important;
  }
  .news-template-49 .line {
    border: 1px solid #dc7e10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-49 .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-49 .post-list .top-part {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
  .news-template-49 .top-new-item {
    padding-right: 10px;
    display: block;
  }
  .news-template-49 .content-right {
    padding-top: 0px;
  }
  .news-template-49 .title-text {
    min-height: fit-content;
  }
  .news-template-49 .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-49 .post-title .arrow-box {
    position: relative;
  }
  .news-template-49 .post-title .title-right {
    position: relative;
    left: 0;
    top: 0;
    width: 20%;
  }
  .news-template-49 .post-title .menu-bar {
    width: 90%;
  }
  .news-template-49 .radio-container {
    display: block;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 20px 0;
    padding: 5px;
    font-size: 14px;
  }
  .news-template-49 audio {
    margin-top: 0px;
    width: 100%;
    height: 30px;
    padding: 0 5px;
  }
}
@media screen and (max-width: 576px) {
  .news-template-49 .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .news-template-49 iframe {
    width: 100%;
    height: 100%;
  }
  .news-template-49 .dialog-width-video {
    max-width: 90%;
    max-height: 317px;
  }
  .news-template-49 .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-49 .close-icon-div {
    position: relative;
    right: 35px !important;
    top: -30px;
  }
  .news-template-49 .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute;
    flex: 1 1 auto;
  }
  .news-template-49 .k-dialog {
    position: initial;
  }
  .news-template-49 .top-part {
    margin-right: 0px;
  }
}

.news-template-ctda {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-ctda .row {
  --bs-gutter-x: 0;
}
.news-template-ctda .k-dialog {
  height: 100%;
  width: 100%;
}
.news-template-ctda .pd-l-10 {
  padding-left: 18px;
}
.news-template-ctda .menu-col {
  padding-right: 10px;
}
.news-template-ctda .post-title {
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ee4623;
  position: relative;
}
.news-template-ctda .post-title .nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 6px;
}
.news-template-ctda .post-title .nav-item,
.news-template-ctda .post-title .nav-item-selected {
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}
.news-template-ctda .post-title .nav-item-selected {
  border: none;
  font-weight: 700;
  color: #007fae;
}
@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.news-template-ctda .post-title .nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  margin-bottom: 15px;
  animation: mykey 0.3s ease-out;
}
.news-template-ctda .post-title .menu-bar {
  background-color: #ffffff;
  align-items: center;
  color: #494949;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 12px;
  padding-left: 0px;
  padding-top: 14px;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
}
.news-template-ctda .post-title .menu-bar:hover {
  overflow-x: auto;
}
.news-template-ctda .post-title .menu-bar::-webkit-scrollbar {
  height: 4px;
}
.news-template-ctda .post-title .menu-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.news-template-ctda .post-title .menu-bar::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.news-template-ctda .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-ctda .post-more a {
  color: #eb2e35;
}
.news-template-ctda .pointer {
  cursor: pointer;
}
.news-template-ctda .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-ctda .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-ctda .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1f1f1f;
  margin-bottom: 0;
}
.news-template-ctda .post-image,
.news-template-ctda .post-image-cover {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
.news-template-ctda .post-image-cover {
  object-fit: cover;
}
.news-template-ctda .post-right-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.news-template-ctda .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-ctda .time-clock {
  display: flex;
  align-items: center;
}
.news-template-ctda .clock {
  width: 15px;
  margin-right: 5px;
}
.news-template-ctda .time-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5f717b;
  padding: 0;
  margin: 0;
}
.news-template-ctda .main-item {
  margin: 0;
  width: 100%;
}
.news-template-ctda .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-ctda .d-flex {
  visibility: visible !important;
}
.news-template-ctda .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-ctda .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-ctda .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-ctda .menu .menu-row,
.news-template-ctda .menu .menu-row-current,
.news-template-ctda .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-ctda .menu .menu-row .menu-item-1,
.news-template-ctda .menu .menu-row .menu-item-2,
.news-template-ctda .menu .menu-row-current .menu-item-1,
.news-template-ctda .menu .menu-row-current .menu-item-2,
.news-template-ctda .menu .menu-row-current-border .menu-item-1,
.news-template-ctda .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-ctda .menu .menu-row-current,
.news-template-ctda .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-ctda .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-ctda .img-figure,
.news-template-ctda .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-ctda .k-scrollview-wrap {
  border: none;
}
.news-template-ctda .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-ctda .news-template-ctda-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-ctda .news-template-ctda-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-ctda .news-template-ctda-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-ctda .news-template-ctda-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-ctda .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-ctda .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-ctda .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ee4623;
  border-radius: 3px;
}
.news-template-ctda .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-ctda .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-ctda .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-ctda .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-ctda .text-section :hover {
  color: #00396b;
}
.news-template-ctda .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 0px !important;
}
.news-template-ctda .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #454545;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-ctda .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-ctda .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-ctda .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4d4d4d;
  padding-left: 3px;
}
.news-template-ctda .top-new-first-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-ctda .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-ctda .menu-title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-ctda .menu-title hr {
  height: 4px;
  width: 92px;
  color: #DD4806;
  opacity: 1;
  margin: 0;
}
.news-template-ctda .description {
  display: flex;
  margin-top: 30px;
}
.news-template-ctda .description .mobile-view {
  display: none;
}
.news-template-ctda .description .image img {
  border-radius: 4px;
}
.news-template-ctda .description .image .main-img {
  width: 100%;
  min-width: 100%;
  margin-bottom: 10px;
  height: 420px;
  object-fit: fill;
}
.news-template-ctda .description .image .sub-img-1,
.news-template-ctda .description .image .sub-img-2 {
  width: 49%;
  min-width: 49%;
  margin-top: 10px;
  height: 225px;
  object-fit: fill;
}
.news-template-ctda .description .image .sub-img-1 {
  margin-right: 1%;
}
.news-template-ctda .description .image .sub-img-2 {
  margin-left: 1%;
}
.news-template-ctda .description .content {
  padding-left: 50px;
}
.news-template-ctda .process {
  margin-top: 30px;
  border-radius: 4px;
  background: rgba(41, 233, 226, 0.6);
  padding: 80px;
}
.news-template-ctda .process .title {
  text-align: center;
}
.news-template-ctda .process .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-ctda .process .content {
  padding: 0 120px;
  margin-top: 40px;
}
.news-template-ctda .student-list {
  margin-top: 30px;
  border-radius: 4px;
  background: #FFFDEC;
  padding: 80px;
}
.news-template-ctda .student-list .title {
  text-align: center;
}
.news-template-ctda .student-list .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-ctda .student-list .content {
  padding: 0 100px;
  margin-top: 40px;
}
.news-template-ctda .student-list .content .student-content .student-title {
  padding: 20px;
  display: flex;
  gap: 12px;
  cursor: pointer;
}
.news-template-ctda .student-list .content .student-content .student-title .arrow i {
  font-size: 20px;
}
.news-template-ctda .student-list .content .student-content .student-title .name {
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.news-template-ctda .student-list .content .student-content .student-info {
  display: flex;
  margin-bottom: 20px;
}
.news-template-ctda .student-list .content .student-content .student-info .info-image {
  padding-left: 20px;
}
.news-template-ctda .student-list .content .student-content .student-info .info-image img {
  width: 100%;
  height: 280px;
  object-fit: fill;
}
.news-template-ctda .student-list .content .student-content .student-info .info-content {
  padding-left: 30px;
}
.news-template-ctda .student-list .content .content-border {
  border-bottom: 1px solid #E3E7EA;
}
.news-template-ctda .report {
  margin: 50px 0 30px;
}
.news-template-ctda .report .title {
  margin-bottom: 15px;
}
.news-template-ctda .report .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-ctda .report .content {
  margin: -15px;
}
.news-template-ctda .report .content .report-content {
  padding: 15px;
  text-decoration: none;
}
.news-template-ctda .report .content .report-content .image img {
  width: 100%;
  margin-bottom: 10px;
  height: 280px;
  object-fit: fill;
}
.news-template-ctda .report .content .report-content .item-title {
  overflow: hidden;
  color: #212529;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.news-template-ctda .report .content .report-content:hover .item-title {
  color: #006CB5;
}
.news-template-ctda .activities {
  margin: 50px 0 30px;
}
.news-template-ctda .activities .title {
  margin-bottom: 15px;
}
.news-template-ctda .activities .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-ctda .activities .content {
  margin: -15px;
}
.news-template-ctda .activities .content .activity-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-decoration: none;
}
.news-template-ctda .activities .content .activity-content .image img {
  width: 100%;
  margin-bottom: 10px;
  height: 280px;
  object-fit: fill;
}
.news-template-ctda .activities .content .activity-content .item-title {
  overflow: hidden;
  color: #212529;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 20px;
}
.news-template-ctda .activities .content .activity-content .time-clock {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.news-template-ctda .activities .content .activity-content .time-clock .clock {
  width: 15px;
  margin-right: 5px;
}
.news-template-ctda .activities .content .activity-content .time-clock .time-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5f717b;
  padding: 0;
  margin: 0;
}
.news-template-ctda .activities .content .activity-content:hover .item-title {
  color: #006CB5;
}
.news-template-ctda .top-new-item {
  margin-bottom: 47px;
  justify-content: space-between;
  text-decoration: none;
}
.news-template-ctda .top-new-item .title:hover {
  color: #00396b;
}
.news-template-ctda .post-list {
  margin-right: -20px;
}
.news-template-ctda .post-list .k-pager-info,
.news-template-ctda .post-list .k-pager-sizes {
  display: none;
}
.news-template-ctda .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-ctda .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-ctda .post-list .top-part {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  height: 100%;
}
.news-template-ctda .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-ctda .post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-ctda .first-post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-ctda .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-ctda .hide-item {
  display: none;
}
.news-template-ctda .line {
  border: 1px solid #dc7e10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-ctda .title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  margin-bottom: 16px;
  min-height: 80px;
}
.news-template-ctda .summary {
  font-weight: 400;
  font-size: 16px;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}
.news-template-ctda .news-title {
  width: fit-content;
}
.news-template-ctda .left-box {
  margin-right: 20px;
}
.news-template-ctda .right-item-detail {
  height: 50%;
}
.news-template-ctda .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
}
.news-template-ctda .pointer {
  cursor: pointer;
}
.news-template-ctda .video-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-template-ctda .dialog-width {
  max-width: 400px;
  max-height: 354px;
}
.news-template-ctda .dialog-width-video {
  width: 1040px;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-ctda .width-full {
  width: 100%;
  position: relative;
}
.news-template-ctda .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 20px;
}
.news-template-ctda .download-radio {
  width: 32px;
  height: 32px;
  min-width: 32px;
  position: relative;
  border-radius: 32px;
  background: var(--1, linear-gradient(180deg, #006CB5 0%, #00AEEF 100%));
  border: none;
}
.news-template-ctda .download-radio-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-ctda .radio-container {
  align-items: center;
  display: flex;
  margin: 10px 0;
}
.news-template-ctda .radio-info {
  margin-right: 6%;
  max-width: 42%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-ctda .album-name {
  font-style: normal;
  font-weight: 700;
  color: #3F3F3F;
  font-size: 16px;
  font-weight: 700;
}
.news-template-ctda .author-name {
  max-width: 4.5%;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.news-template-ctda .radio-order {
  color: #006CB5;
  font-size: 16px;
  font-weight: 700;
  width: 2%;
}
.news-template-ctda .radio-duration {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.news-template-ctda .view-count-icon {
  max-width: 1.2%;
  margin: 0 0.7% 0 7%;
  color: #C2C2C2;
}
.news-template-ctda .view-count {
  color: #C2C2C2;
  font-size: 16px;
  font-weight: 700;
  margin-right: 6%;
  max-width: 3%;
  width: 100%;
}
.news-template-ctda .media {
  position: relative;
  width: 6%;
  margin: 0 2% 0 1%;
  border: #E3E7EA 1px solid;
  border-radius: 5px;
}
.news-template-ctda .post-image-cover {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 5px;
}
.news-template-ctda audio {
  height: 40px;
  width: 350px;
}
@media screen and (max-width: 1200px) {
  .news-template-ctda .pd-l-10 {
    padding-left: 0px;
  }
  .news-template-ctda .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-ctda .video-title {
    font-size: 18px !important;
  }
  .news-template-ctda .post-more {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .news-template-ctda .dialog-width-video {
    max-width: 90% !important;
    max-height: 317px !important;
  }
  .news-template-ctda .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-ctda .close-icon-div {
    position: relative;
    float: right;
    top: -30px;
  }
  .news-template-ctda .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute !important;
    flex: 1 1 auto;
  }
  .news-template-ctda .k-dialog {
    position: initial !important;
  }
  .news-template-ctda .line {
    border: 1px solid #dc7e10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-ctda .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-ctda .post-list .top-part {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
  .news-template-ctda .top-new-item {
    padding-right: 10px;
    display: block;
  }
  .news-template-ctda .content-right {
    padding-top: 0px;
  }
  .news-template-ctda .title-text {
    min-height: fit-content;
  }
  .news-template-ctda .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-ctda .post-title .arrow-box {
    position: relative;
  }
  .news-template-ctda .post-title .title-right {
    position: relative;
    left: 0;
    top: 0;
    width: 20%;
  }
  .news-template-ctda .post-title .menu-bar {
    width: 90%;
  }
  .news-template-ctda .radio-container {
    display: block;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 20px 0;
    padding: 5px;
    font-size: 14px;
  }
  .news-template-ctda audio {
    margin-top: 0px;
    width: 100%;
    height: 30px;
    padding: 0 5px;
  }
  .news-template-ctda .menu-title .uppercase {
    font-size: 24px;
    line-height: 30px;
  }
  .news-template-ctda .process .title .uppercase,
.news-template-ctda .student-list .title .uppercase,
.news-template-ctda .report .title .uppercase,
.news-template-ctda .activities .title .uppercase {
    font-size: 20px;
  }
  .news-template-ctda .description .web-view {
    display: none;
  }
  .news-template-ctda .description .mobile-view {
    display: block;
  }
  .news-template-ctda .description .mobile-view .img-scrollview {
    width: 100%;
    object-fit: fill;
  }
  .news-template-ctda .description .mobile-view .k-scrollview-wrap {
    overflow: visible;
  }
  .news-template-ctda .description .mobile-view .k-scrollview-wrap .k-scrollview-pageable > .k-button {
    background: #E3E7EA;
  }
  .news-template-ctda .description .mobile-view .k-scrollview-wrap .k-scrollview-pageable > .k-button.k-primary {
    background: #025ACD;
  }
  .news-template-ctda .description .mobile-view .k-scrollview-wrap kendo-scrollview-pager {
    bottom: -50px;
  }
  .news-template-ctda .description .content {
    margin-top: 70px;
  }
  .news-template-ctda .process {
    padding: 30px;
  }
  .news-template-ctda .process .content {
    padding: 0 20px;
    margin-top: 30px;
  }
  .news-template-ctda .student-list {
    padding: 30px;
  }
  .news-template-ctda .student-list .content {
    padding: 0 20px;
    margin-top: 30px;
  }
  .news-template-ctda .student-list .content .info-content {
    margin-top: 15px;
  }
  .news-template-ctda .report .content {
    margin: 0;
  }
  .news-template-ctda .report .content .item-title {
    padding-left: 10px !important;
  }
  .news-template-ctda .report .content img {
    height: 100px !important;
    margin-bottom: 0 !important;
  }
  .news-template-ctda .report .content .item-title {
    margin: auto 0;
  }
  .news-template-ctda .activities .content {
    margin: 0;
  }
  .news-template-ctda .activities .content .image img {
    height: 100px !important;
    margin-bottom: 0 !important;
  }
  .news-template-ctda .activities .content .item-title {
    margin: auto 0;
  }
  .news-template-ctda .activities .content .item-title,
.news-template-ctda .activities .content .time-clock {
    padding-left: 10px;
  }
  .news-template-ctda .activities .content .item-title {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  .news-template-ctda .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .news-template-ctda iframe {
    width: 100%;
    height: 100%;
  }
  .news-template-ctda .dialog-width-video {
    max-width: 90%;
    max-height: 317px;
  }
  .news-template-ctda .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-ctda .close-icon-div {
    position: relative;
    right: 35px !important;
    top: -30px;
  }
  .news-template-ctda .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute;
    flex: 1 1 auto;
  }
  .news-template-ctda .k-dialog {
    position: initial;
  }
  .news-template-ctda .top-part {
    margin-right: 0px;
  }
}

.news-template-50-dialog {
  margin-left: 0px !important;
}

.news-template-50-dialog .vnpt-upload-img .k-upload {
  display: none;
}

.news-template-50-dialog .vnpt-upload-img img {
  width: 160px;
}

.news-template-50-dialog .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.news-template-50-dialog .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}

.news-template-50-dialog .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
    border-color: #ea4335;
  }
  25% {
    border-color: yellow;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: #34a853;
  }
}
:host ::ng-deep .news-template-50-dialog.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.news-template-50-dialog .custom-btn-addNew {
  margin-top: 10px !important;
}

.news-template-50-dialog .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .news-template-50-dialog .btn-custom {
  width: 100%;
}

:host ::ng-deep .news-template-50-dialog .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}

.news-template-50-dialog .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.news-template-50-dialog .selected-lang {
  border-bottom: 3px red solid;
}

@media (max-width: 600px) {
  :host ::ng-deep .k-window {
    width: 90%;
    height: 90%;
  }

  :host ::ng-deep .news-template-50-dialog.x-dialog {
    max-width: 768px;
    max-height: none;
  }

  .news-template-50-dialog .vnpt-upload-img img {
    width: 99px;
  }
}
.news-template-50-dialog .row-file {
  margin-bottom: 5px;
}

.news-template-50-dialog .float-right {
  float: right;
}

.news-template-50-dialog .border-infor {
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.news-template-50-dialog .label-infor {
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.news-template-50-dialog .control {
  margin-bottom: 22px;
}

.news-template-50-dialog .input-label {
  margin: 10px;
  margin-left: 15px;
}

.news-template-50-dialog .input-box {
  height: 40px;
  margin: 10px 15px;
  width: calc(100% - 30px);
  border: solid 1px #e9e9e9;
  border-radius: 0px;
  background-color: white;
}
.news-template-50-dialog .input-box .k-input {
  height: 38px;
  margin-top: -1px;
}
.news-template-50-dialog .input-box .k-clear-value {
  height: 38px;
  margin-top: -1px;
}

.news-template-50-dialog .btn-save {
  margin: 15px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
}

.news-template-50-dialog .padding-col {
  padding: 7px 15px 0;
}

.news-template-50 {
  width: 100%;
  background-color: #ffffff !important;
}
.news-template-50 .row {
  --bs-gutter-x: 0;
}
.news-template-50 .k-dialog {
  height: 100%;
  width: 100%;
}
.news-template-50 .pd-l-10 {
  padding-left: 18px;
}
.news-template-50 .menu-col {
  padding-right: 10px;
}
.news-template-50 .post-title {
  align-items: center;
  position: relative;
  min-height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ee4623;
  position: relative;
}
.news-template-50 .post-title .nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 6px;
}
.news-template-50 .post-title .nav-item,
.news-template-50 .post-title .nav-item-selected {
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}
.news-template-50 .post-title .nav-item-selected {
  border: none;
  font-weight: 700;
  color: #007fae;
}
@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.news-template-50 .post-title .nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  margin-bottom: 15px;
  animation: mykey 0.3s ease-out;
}
.news-template-50 .post-title .menu-bar {
  background-color: #ffffff;
  align-items: center;
  color: #494949;
  text-align: left;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0 12px;
  padding-left: 0px;
  padding-top: 14px;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
}
.news-template-50 .post-title .menu-bar:hover {
  overflow-x: auto;
}
.news-template-50 .post-title .menu-bar::-webkit-scrollbar {
  height: 4px;
}
.news-template-50 .post-title .menu-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.news-template-50 .post-title .menu-bar::-webkit-scrollbar-track {
  padding-top: 1px;
  padding-bottom: 1px;
  background: white;
}
.news-template-50 .post-more {
  display: flex;
  justify-content: center;
  color: #eb2e35;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-template-50 .post-more a {
  color: #eb2e35;
}
.news-template-50 .pointer {
  cursor: pointer;
}
.news-template-50 .icon-clock {
  width: 8px;
  height: 8px;
  margin-top: 5px;
}
.news-template-50 .icon-arrow {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;
}
.news-template-50 .title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #1f1f1f;
  margin-bottom: 0;
}
.news-template-50 .post-image,
.news-template-50 .post-image-cover {
  height: 200px;
  width: 100%;
  border-radius: 5px;
}
.news-template-50 .post-image-cover {
  object-fit: cover;
}
.news-template-50 .post-right-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.news-template-50 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-50 .time-clock {
  display: flex;
  align-items: center;
}
.news-template-50 .clock {
  width: 15px;
  margin-right: 5px;
}
.news-template-50 .time-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5f717b;
  padding: 0;
  margin: 0;
}
.news-template-50 .main-item {
  margin: 0;
  width: 100%;
}
.news-template-50 .vertical-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #808080;
  color: white;
  z-index: 10;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.news-template-50 .d-flex {
  visibility: visible !important;
}
.news-template-50 .main-item:hover .vertical-dot {
  visibility: visible;
}
.news-template-50 .three-dot-area {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.news-template-50 .menu {
  background-color: white;
  cursor: pointer;
  width: 110px;
  top: 43%;
  left: 100%;
  position: absolute;
  z-index: 1;
}
.news-template-50 .menu .menu-row,
.news-template-50 .menu .menu-row-current,
.news-template-50 .menu .menu-row-current-border {
  cursor: pointer;
}
.news-template-50 .menu .menu-row .menu-item-1,
.news-template-50 .menu .menu-row .menu-item-2,
.news-template-50 .menu .menu-row-current .menu-item-1,
.news-template-50 .menu .menu-row-current .menu-item-2,
.news-template-50 .menu .menu-row-current-border .menu-item-1,
.news-template-50 .menu .menu-row-current-border .menu-item-2 {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.news-template-50 .menu .menu-row-current,
.news-template-50 .menu .menu-row-current-border {
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.news-template-50 .menu .menu-item-3 {
  padding: 5px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.news-template-50 .img-figure,
.news-template-50 .img-figure-no-img {
  height: 100%;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.news-template-50 .k-scrollview-wrap {
  border: none;
}
.news-template-50 .close-div {
  display: flex;
  margin-top: 3px;
}
.news-template-50 .news-template-50-dialog {
  display: flex;
  justify-content: center;
  position: relative;
}
.news-template-50 .news-template-50-dialog .close-icon-div {
  position: absolute;
  right: 10px;
}
.news-template-50 .news-template-50-dialog .close-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.news-template-50 .news-template-50-dialog .title-info-req {
  font-weight: 500;
  color: #004a9a;
}
.news-template-50 .input-label {
  color: #004a9a;
  margin: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 15px;
  text-align: center;
}
.news-template-50 .btn-delete {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #1c8d43;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-50 .btn-detail {
  margin-top: 26px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ee4623;
  border-radius: 3px;
}
.news-template-50 .btn-cancel {
  margin: 15px;
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  background-color: #6c757d;
  border: hidden;
  color: white;
  font-size: 14px;
}
.news-template-50 .flower-img {
  width: 19px;
  height: 19px;
  margin: 10px;
}
.news-template-50 .first-news-deco {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 49px;
}
.news-template-50 .text-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.news-template-50 .text-section :hover {
  color: #00396b;
}
.news-template-50 .title-first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 0px !important;
}
.news-template-50 .new-detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #454545;
  height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.news-template-50 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-template-50 .content-right {
  padding-top: 25px;
  padding-left: 30px;
}
.news-template-50 .detail-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #4d4d4d;
  padding-left: 3px;
}
.news-template-50 .top-new-first-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-50 .top-new-first-item .title:hover {
  color: #00396b;
}
.news-template-50 .menu-title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-50 .menu-title hr {
  height: 4px;
  width: 92px;
  color: #DD4806;
  opacity: 1;
  margin: 0;
}
.news-template-50 .description {
  display: flex;
  margin-top: 30px;
}
.news-template-50 .description .mobile-view {
  display: none;
}
.news-template-50 .description .image img {
  border-radius: 4px;
}
.news-template-50 .description .image .main-img {
  width: 100%;
  min-width: 100%;
  margin-bottom: 10px;
  object-fit: fill;
}
.news-template-50 .description .image .sub-img-1,
.news-template-50 .description .image .sub-img-2 {
  width: 49%;
  min-width: 49%;
  margin-top: 10px;
  object-fit: fill;
}
.news-template-50 .description .image .sub-img-1 {
  margin-right: 1%;
}
.news-template-50 .description .image .sub-img-2 {
  margin-left: 1%;
}
.news-template-50 .description .content {
  padding-left: 50px;
}
.news-template-50 .process {
  margin-top: 30px;
  border-radius: 4px;
  background: rgba(41, 233, 226, 0.6);
  padding: 80px;
}
.news-template-50 .process .title {
  text-align: center;
}
.news-template-50 .process .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-50 .process .content {
  padding: 0 120px;
  margin-top: 40px;
}
.news-template-50 .student-list {
  margin-top: 30px;
  border-radius: 4px;
  background: #FFFDEC;
  padding: 80px;
}
.news-template-50 .student-list .title {
  text-align: center;
}
.news-template-50 .student-list .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-50 .student-list .content {
  padding: 0 100px;
  margin-top: 40px;
}
.news-template-50 .student-list .content .student-content .student-title {
  padding: 20px;
  display: flex;
  gap: 12px;
  cursor: pointer;
}
.news-template-50 .student-list .content .student-content .student-title .arrow i {
  font-size: 20px;
}
.news-template-50 .student-list .content .student-content .student-title .name {
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.news-template-50 .student-list .content .student-content .student-info {
  display: flex;
  margin-bottom: 20px;
}
.news-template-50 .student-list .content .student-content .student-info .info-image {
  padding-left: 20px;
}
.news-template-50 .student-list .content .student-content .student-info .info-image img {
  width: 100%;
  object-fit: fill;
}
.news-template-50 .student-list .content .student-content .student-info .info-content {
  padding-left: 30px;
}
.news-template-50 .student-list .content .content-border {
  border-bottom: 1px solid #E3E7EA;
}
.news-template-50 .report {
  margin: 50px 0 30px;
}
.news-template-50 .report .title {
  margin-bottom: 15px;
}
.news-template-50 .report .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-50 .report .content {
  margin: -15px;
}
.news-template-50 .report .content .report-content {
  padding: 15px;
  text-decoration: none;
}
.news-template-50 .report .content .report-content .image img {
  width: 100%;
  margin-bottom: 10px;
  object-fit: fill;
}
.news-template-50 .report .content .report-content .item-title {
  overflow: hidden;
  color: #212529;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.news-template-50 .report .content .report-content .item-title span {
  position: relative;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.news-template-50 .report .content .report-content:hover .item-title {
  color: #006CB5;
}
.news-template-50 .activities {
  margin: 50px 0 30px;
}
.news-template-50 .activities .title {
  margin-bottom: 15px;
}
.news-template-50 .activities .title .uppercase {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #212529;
  text-transform: uppercase;
}
.news-template-50 .activities .content {
  margin: -15px;
}
.news-template-50 .activities .content .activity-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-decoration: none;
}
.news-template-50 .activities .content .activity-content .image img {
  width: 100%;
  margin-bottom: 10px;
  object-fit: fill;
}
.news-template-50 .activities .content .activity-content .item-title {
  overflow: hidden;
  color: #212529;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 20px;
}
.news-template-50 .activities .content .activity-content .item-title span {
  position: relative;
  display: -webkit-box;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.news-template-50 .activities .content .activity-content .time-clock {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.news-template-50 .activities .content .activity-content .time-clock .clock {
  width: 15px;
  margin-right: 5px;
}
.news-template-50 .activities .content .activity-content .time-clock .time-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #5f717b;
  padding: 0;
  margin: 0;
}
.news-template-50 .activities .content .activity-content:hover .item-title {
  color: #006CB5;
}
.news-template-50 .top-new-item {
  margin-bottom: 47px;
  justify-content: space-between;
  text-decoration: none;
}
.news-template-50 .top-new-item .title:hover {
  color: #00396b;
}
.news-template-50 .post-list {
  margin-right: -20px;
}
.news-template-50 .post-list .k-pager-info,
.news-template-50 .post-list .k-pager-sizes {
  display: none;
}
.news-template-50 .post-list .k-pager kendo-datapager-prev-buttons {
  margin-left: auto;
}
.news-template-50 .post-list .k-pager .k-state-selected {
  background-color: #d6a022;
  border: none;
}
.news-template-50 .post-list .top-part {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  height: 100%;
}
.news-template-50 .post-image {
  height: 189px;
  width: 100%;
  object-fit: cover;
}
.news-template-50 .post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-50 .first-post-image-default {
  height: 271px;
  width: 100%;
}
.news-template-50 .post-image-org {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 40%;
}
.news-template-50 .hide-item {
  display: none;
}
.news-template-50 .line {
  border: 1px solid #dc7e10;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.news-template-50 .title-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
  margin-bottom: 16px;
  min-height: 80px;
}
.news-template-50 .summary {
  font-weight: 400;
  font-size: 16px;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 70px;
}
.news-template-50 .news-title {
  width: fit-content;
}
.news-template-50 .left-box {
  margin-right: 20px;
}
.news-template-50 .right-item-detail {
  height: 50%;
}
.news-template-50 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: transparent;
}
.news-template-50 .pointer {
  cursor: pointer;
}
.news-template-50 .video-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-template-50 .dialog-width {
  max-width: 400px;
  max-height: 354px;
}
.news-template-50 .dialog-width-video {
  width: 1040px;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
}
.news-template-50 .width-full {
  width: 100%;
  position: relative;
}
.news-template-50 .d-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 20px;
}
.news-template-50 .download-radio {
  width: 32px;
  height: 32px;
  min-width: 32px;
  position: relative;
  border-radius: 32px;
  background: var(--1, linear-gradient(180deg, #006CB5 0%, #00AEEF 100%));
  border: none;
}
.news-template-50 .download-radio-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.news-template-50 .radio-container {
  align-items: center;
  display: flex;
  margin: 10px 0;
}
.news-template-50 .radio-info {
  margin-right: 6%;
  max-width: 42%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news-template-50 .album-name {
  font-style: normal;
  font-weight: 700;
  color: #3F3F3F;
  font-size: 16px;
  font-weight: 700;
}
.news-template-50 .author-name {
  max-width: 4.5%;
  width: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.news-template-50 .radio-order {
  color: #006CB5;
  font-size: 16px;
  font-weight: 700;
  width: 2%;
}
.news-template-50 .radio-duration {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.news-template-50 .view-count-icon {
  max-width: 1.2%;
  margin: 0 0.7% 0 7%;
  color: #C2C2C2;
}
.news-template-50 .view-count {
  color: #C2C2C2;
  font-size: 16px;
  font-weight: 700;
  margin-right: 6%;
  max-width: 3%;
  width: 100%;
}
.news-template-50 .media {
  position: relative;
  width: 6%;
  margin: 0 2% 0 1%;
  border: #E3E7EA 1px solid;
  border-radius: 5px;
}
.news-template-50 .post-image-cover {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 5px;
}
.news-template-50 audio {
  height: 40px;
  width: 350px;
}
@media screen and (max-width: 1200px) {
  .news-template-50 .pd-l-10 {
    padding-left: 0px;
  }
  .news-template-50 .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .news-template-50 .video-title {
    font-size: 18px !important;
  }
  .news-template-50 .post-more {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .news-template-50 .dialog-width-video {
    max-width: 90% !important;
    max-height: 317px !important;
  }
  .news-template-50 .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-50 .close-icon-div {
    position: relative;
    float: right;
    top: -30px;
  }
  .news-template-50 .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute !important;
    flex: 1 1 auto;
  }
  .news-template-50 .k-dialog {
    position: initial !important;
  }
  .news-template-50 .line {
    border: 1px solid #dc7e10;
    margin-top: 5px;
    margin-bottom: 10px;
    min-width: auto;
  }
  .news-template-50 .post-list {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .news-template-50 .post-list .top-part {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
  .news-template-50 .top-new-item {
    padding-right: 10px;
    display: block;
  }
  .news-template-50 .content-right {
    padding-top: 0px;
  }
  .news-template-50 .title-text {
    min-height: fit-content;
  }
  .news-template-50 .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-template-50 .post-title .arrow-box {
    position: relative;
  }
  .news-template-50 .post-title .title-right {
    position: relative;
    left: 0;
    top: 0;
    width: 20%;
  }
  .news-template-50 .post-title .menu-bar {
    width: 90%;
  }
  .news-template-50 .radio-container {
    display: block;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 20px 0;
    padding: 5px;
    font-size: 14px;
  }
  .news-template-50 audio {
    margin-top: 0px;
    width: 100%;
    height: 30px;
    padding: 0 5px;
  }
  .news-template-50 .menu-title .uppercase {
    font-size: 24px;
    line-height: 30px;
  }
  .news-template-50 .process .title .uppercase,
.news-template-50 .student-list .title .uppercase,
.news-template-50 .report .title .uppercase,
.news-template-50 .activities .title .uppercase {
    font-size: 20px;
  }
  .news-template-50 .description .web-view {
    display: none;
  }
  .news-template-50 .description .mobile-view {
    display: block;
  }
  .news-template-50 .description .mobile-view .img-scrollview {
    width: 100%;
    object-fit: fill;
  }
  .news-template-50 .description .mobile-view .k-scrollview-wrap {
    overflow: visible;
  }
  .news-template-50 .description .mobile-view .k-scrollview-wrap .k-scrollview-pageable > .k-button {
    background: #E3E7EA;
  }
  .news-template-50 .description .mobile-view .k-scrollview-wrap .k-scrollview-pageable > .k-button.k-primary {
    background: #025ACD;
  }
  .news-template-50 .description .mobile-view .k-scrollview-wrap kendo-scrollview-pager {
    bottom: -50px;
  }
  .news-template-50 .description .content {
    margin-top: 70px;
  }
  .news-template-50 .process {
    padding: 30px;
  }
  .news-template-50 .process .content {
    padding: 0 20px;
    margin-top: 30px;
  }
  .news-template-50 .student-list {
    padding: 30px;
  }
  .news-template-50 .student-list .content {
    padding: 0 20px;
    margin-top: 30px;
  }
  .news-template-50 .student-list .content .info-content {
    margin-top: 15px;
  }
  .news-template-50 .report .content {
    margin: 0;
  }
  .news-template-50 .report .content .item-title {
    padding-left: 10px !important;
    margin: auto 0;
  }
  .news-template-50 .report .content .item-title span {
    -webkit-line-clamp: 3 !important;
  }
  .news-template-50 .report .content img {
    margin-bottom: 0 !important;
  }
  .news-template-50 .activities .content {
    margin: 0;
  }
  .news-template-50 .activities .content .image img {
    margin-bottom: 0 !important;
  }
  .news-template-50 .activities .content .item-title {
    margin-top: 0 !important;
  }
  .news-template-50 .activities .content .item-title span {
    -webkit-line-clamp: 3 !important;
  }
  .news-template-50 .activities .content .item-title,
.news-template-50 .activities .content .time-clock {
    padding-left: 10px;
  }
}
@media screen and (max-width: 576px) {
  .news-template-50 .d-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }
  .news-template-50 iframe {
    width: 100%;
    height: 100%;
  }
  .news-template-50 .dialog-width-video {
    max-width: 90%;
    max-height: 317px;
  }
  .news-template-50 .k-dialog {
    height: 100% !important;
    width: 100% !important;
  }
  .news-template-50 .close-icon-div {
    position: relative;
    right: 35px !important;
    top: -30px;
  }
  .news-template-50 .k-window-content {
    padding: 0px !important;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: none;
    outline: 0;
    overflow: hidden;
    position: absolute;
    flex: 1 1 auto;
  }
  .news-template-50 .k-dialog {
    position: initial;
  }
  .news-template-50 .top-part {
    margin-right: 0px;
  }
}

:root {
  --outlet-content-width: 1024px;
  --main-color: #0b68a4;
}

.router-outlet-content {
  width: 1024px;
  width: 100%;
  max-width: 1040px;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .router-outlet-content {
    width: auto;
    margin: 0px;
  }
}
@media screen and (max-width: 576px) {
  .router-outlet-content {
    width: auto;
    margin: 0px;
  }
}
html {
  background-color: white !important;
}

body {
  margin: 0;
  overflow-x: hidden;
}

.loading {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.loading img {
  max-width: 100px;
}

.plain-container {
  display: none !important;
}

.fancybox__container {
  z-index: 10000;
}