

.payment-result {
    .name {
        position: absolute;
        z-index: 6;
        left: 108px;
        top: 20px;
        font-size: 18px;
   
        font-weight: 900;
        text-shadow: 0px 6px 10px #a2a2a2;
    }
    .name-main {
        color: red;
    }
    .name-detail {
        color: var(--main-color);
        margin-top: 4px;
    }
    .loading {
        display: none;
    }
    .payment-status {
        top: 210px;
position: relative;
font-weight: bolder;
text-align: center;
    }

    .payment-msg {
padding-left: 5px;
padding-right: 5px;
text-align: center;
    }

    .payment-back {
        text-align: center;
    }

    .btn-back {
        padding-left: 35px;
    padding-right: 35px;
    border-radius: 25px;
    margin-top: 30px;
    }
     

    .banner {
        width: 100% !important;
        height: 240px;
        .img1 {
            max-width: 100%;
            height: 50px;
            top: 6px;
            left: 50%;
            position: absolute;
            margin-left: -2em;
        }
        .name {
            left: 0;
            top: 54px;
            font-size: 14px;
            text-align: center;
            width: 100%;
            text-align: center;
        }
        .img3 {
            max-width: 100%;
            height: 190px;
            top: 10px;
            left: 50%;
            position: absolute;
            margin-left: -6em;
        }
        
    }
} 