    .content-document {

        .x-dialog {
            max-width: 500px;
            max-height: 600px;
        }

        .err-text, .required {
            color: #ff6358;
            padding-left: 3px;
            font-style: italic;
        }
        
         .err {
            border: 1px solid #ea4335;
            animation: myerr 1s infinite;
        }
        
        @keyframes myerr {
            0% {
                border-color: #ea4335;
            }
            25% {
                border-color: yellow;
            }
            50% {
                border-color: blue;
            }
            100% {
                border-color: #34a853;
            }
        }
        
        .panelbar-wrapper {
            margin: 0 auto;
        }

    //     .k-panelbar > .k-item > .k-link.k-state-selected .k-panelbar-item-icon {
    //         color: #194f8b !important;
    //     }
    //     .k-panelbar > .k-panelbar-header > .k-link.k-state-selected .k-icon  {
    //         color: #194f8b !important;
    //     }
    //     .k-panelbar > .k-panelbar-header > .k-link.k-state-selected .k-panelbar-item-icon {
    //         color: #194f8b !important;
    //     }
    .k-panelbar > .k-item > .k-link .k-icon {
        color: #194f8b;
    }
       .k-content {
            background-color: white !important;
        }
        tbody, td, tfoot, th, thead,  tr {
            border-color: rgb(202, 202, 202);
            border-width:  1px;
            vertical-align: middle;
        }
        th{
            background-color: rgb(243, 242, 242);
        }
        th,
        td {
            padding: 8px 12px;
            border-style: solid;
            text-overflow: ellipsis;
        }
        td {
            display: table-cell;
        }

        table {
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
            vertical-align: top;
            border-color: rgb(202, 202, 202)
        }

        .no-record{
        text-align: center;
        }

        
        .x-dialog-agree .row {
            line-height: 35px;
        }
        
        .content-margin {
            margin-top: 15px;
            margin-left: 10px;
            margin-right: 10px;
            line-height: 30px;
        }
        
        .custom-btn-addNew {
            margin-top: 10px !important;
        }
        
        .btn-addNew {
            text-align: right;
            display: block;
            margin-right: 15px;
            margin-bottom: 5px;
        }
        
         .btn-custom {
            text-align: right;
            margin-right: 15px;
            margin-bottom: 5px;
        }
        
        .btn-custom button {
            background: #cd330d;
            color: white;
        }
 
        .btn-search-content {
            background: RGB(25, 79, 139);
            color: white;
            position: relative;
            float: left;
            border: none;
            margin-right: 0px;
            margin-top: 40px;
            margin-bottom: 5px;
            text-align: center;
            padding: 2px 23px;
            font-size: medium;
        }
        
        .btn-delete {
            background: red;
            color: white;
            position: relative;
            float: left;
            border: none;
            margin-right: 0px;
            margin-top: 40px;
            margin-bottom: 5px;
            text-align: center;
            padding: 2px 23px;
            font-size: small;
        }
        
        .btn-continue {
            background: #34a853;
            color: white;
            position: relative;
            float: right;
            border-radius: 0px;
            border: none;
            margin-right: 0px;
            margin-top: 15px;
            margin-bottom: 5px;
            text-align: center;
            padding: 1px 23px;
        }
        
         .PayButton-div {
            position: relative;
            float: left;
            width: auto;
            max-width: 100%;
        }
        
         .button-div {
            position: relative;
            float: right;
             display: block; 
            width: auto;
            max-width: 100%;
            overflow: hidden ;

        }
        
        .red-status {
            color: red;
            font-weight: lighter;
        }
        
        .link-up {
            color: cornflowerblue;
            font-size: 12px;
            font-weight: normal;
            position: relative;
            cursor: pointer;
            text-decoration: none !important;

        }
 
        
        .icon-format {
            max-width: 20px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        
      .text-center {
            text-align: center;
        }

        
         .default-lang {
            border-bottom: none;
            float: left;
            margin-right: 5px;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 15px;
        }
        
       .selected-lang {
            border-bottom: 3px red solid;
        }
        
        .div-method {
            justify-content: center;
        }
        
        .method-border {
            border: 2px solid #b7b7b7;
            margin-right: 10px;
            padding: 10px;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
            width: auto !important;
        }
        
        .mar-bot-20 {
            margin-bottom: 20px;
        }
        
        .div-icon {
            margin-top: 15px;
            margin-bottom: 15px;
            border-top: 1px solid rgb(228, 228, 228);
            padding-top: 15px;
        }
        
        .icon-img {
            width: 75px !important;
            height: 75px;
        }
        
       .payment-div {
            margin-top: 20px;
            border-top: 1px solid rgb(228, 228, 228);
            border-bottom: 1px solid rgb(228, 228, 228);
            padding-top: 15px;
            padding-bottom: 8px;
            margin-bottom: 25px;
            text-align: right;
        }
        
        .is-fee {
            color: #e85d3b;
            font-size: 28px;
            padding-left: 20px;
            padding-right: 60px;
            vertical-align: sub;
        }
        
        .is-fee-dot {
            display: inline-flex;
        }
        
         .selected-payment {
            color: #e85d3b !important;
            border-color: #e85d3b !important;
        }
        .xbutton-dialog {
            padding: 0;
            color: inherit;
            background: none;
            border-width: 0;
            box-shadow: none;
            position: absolute;
            right: 10px;
            top: 1px;
            color: black !important;
            font-size: 30px !important;
         }
        .selected-payment {
            color: #e85d3b !important;
            border-color: #e85d3b !important;
        }
        
    }
    @media screen and (max-width: 770px){
            .content-document {

                table {
                    table-layout: fixed;
                }
                table td{
                    word-wrap: break-word;         /* All browsers since IE 5.5+ */
                    overflow-wrap: break-word; 
                    overflow: hidden;
                
                }
                .btn-cancel-document {
                    margin-top: 5px;
                }
                .btn-ticket-appointment {
                    margin-top: 5px;
                }

            }
        }
        
        
        .content-document 
        {
            .title-info-req {
                    font-size: 15px;
                    font-weight: 650;
                    margin-top: 5px;
                }
                
            .appointment-label {
                    font-size: 25px;
                    font-weight: bold;
                    margin-top: 10px;
                }
                
            .orderidx {
                    font-size: 80px;
                    color: lightgray;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    font-weight: bold;
                    
                }
            
            .content-label {
                margin-bottom: 10px;
                display: inline-block;
                margin-left: 5px;
                font-weight: bold;
            }
            
            .content-label-hr {
                margin-bottom: 0px;
            }
            
            .center-me {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
            }
            
            .btn-cancel-document {
                background: red;
                width: 150px;
            }
            
            .btn-ticket-appointment {
                margin-right: 20px;
                width: 150px;
            }
    }
        /*CSS Dialog Huỷ đăng kí*/

        .cancel-appointment.x-dialog-cancel{
            max-width: 450px;
            max-height: 300px;
            align-self: center;
        }

        .cancel-appointment {
            .center-me {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            
            .k-window-content {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .cancel-label {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            
            .btn-cancel {
                margin-top: 20px;
                float: right;
                width: 130px;
                background: white;
                color: black;
                border: 1px solid black;
            }
            
            .btn-accept-cancel {
                margin-top: 20px;
                float: left;
                width: 130px;
                background: red;
                color: white;
                border: 1px solid red;
            }
            
            .areyousure-label {
                padding: 0px 40px;
            }
    }
        /* Hết CSS Dialog Huỷ đăng kí */