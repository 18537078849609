.template-page {
  overflow: hidden;

  .news-template-1 {
    margin-bottom: 20px;
  }
  .news-template-2 {
    margin-bottom: 20px;
  }
  .news-template-3 {
    margin-bottom: 20px;
  }
  .news-template-4 {
    margin-bottom: 20px;
  }
  .news-template-5 {
    margin-bottom: 20px;
  }
  .news-template-6 {
    margin-bottom: 20px;
  }
  .news-template-a {
    margin-bottom: 20px;
  }
  .news-template-b {
    margin-bottom: 20px;
  }
  .news-template-c {
    margin-bottom: 20px;
  }

  .body-list {
    max-width: 100%;
    border: none;
    min-height: 591px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 12px 0px 12px;
    background-color: #ffffff;
  }
  .body-empty-container {
    width: 100%;
    background-color: #ffffff;
    border: 1px dashed #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }
  //   ::-webkit-scrollbar-thumb {
  //     background: #636363;
  //     border-radius: 10px;
  //     opacity: .5;
  //     width: 2px;
  //     height: 2px;
  // }
  //   ::-webkit-scrollbar {
  //     width: 2px !important;
  //     height: 10px;
  //   }

  .page-list-background {
    background-color: #f5f5f5;
    padding: 20px 16px;
    height: 100vh;
    overflow: scroll;
  }
  .page-list-background::-webkit-scrollbar {
    width: 10px;
  }

  .page-list-background::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  .page-list-background::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 2px;
  }

  .page-list-container {
    background-color: #ffffff;
    padding-top: 16px;
  }

  .page-lists {
     
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
  }

  .page-lists::-webkit-scrollbar {
    width: 10px;
  }

  .page-lists::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  .page-lists::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 2px;
  }

  .layout-box {
    position: relative;
    display: flex;
    border: solid 1px #cccccc;
    align-items: flex-start;
    min-height: 100px;
    width: 100%;
    justify-content: space-between;
    .hidden-dlt {
      top: 0 !important;
      right: 0 !important;
      margin-top: 2px;
      margin-right: 2px;
      position: absolute;
      z-index: 10;
    }
    .hidden-move {
      top: 0 !important;
      right: 0 !important;
      margin-top: 26px;
      margin-right: 2px;
      position: absolute;
      z-index: 10;
      cursor: all-scroll;
    }
  }

  .layout-list {
    width: 100%;
    //padding-top: 30px;
    //border: solid 1px #cccccc;
    min-height: 100px;
    background: rgb(43, 43, 43);
    border-radius: 4px;
    //overflow: hidden;
    display: block;
    z-index: 100;
  }

  .layout-group {
    display: inline-block;
    border: solid 1px #cccccc;
    box-sizing: border-box;
  }

  .layout-box:hover {
    .hidden-dlt {
      visibility: visible;
    }
    .hidden-move {
      visibility: visible;
    }
  }

  .body-box {
    background-color: white;
    //border: solid 1px #cccccc;
    display: inline-block;
    // padding-right: 5px;
    position: relative;
  }

  .header-list {
    display: block;
    max-width: 100%;
    margin: 20px 12px 0px 12px;
    min-height: 138px;
    overflow: hidden;
    border: none;
  }

  .header-empty-container,
  .footer-empty-container {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    border: 1px dashed #000000;
  }

  .header-empty,
  .footer-empty {
    height: 110px;
    position: relative;

    margin: 14px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      position: absolute;
      top: 0;
      left: 0;
       
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .instruction-container {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 31px;
      height: 31px;
    }
  }

  .instruction {
    margin-left: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  .header-box {
    overflow-x: hidden;
    position: relative;
  }

  .header-box:hover {
    .hidden-dlt {
      visibility: visible;
    }
  }

  .body-empty {
    height: 563px;
    position: relative;

    margin: 14px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      position: absolute;
      top: 0;
      left: 0;
       
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .footer-list {
    max-width: 100%;
    margin: 20px 12px;
    min-height: 60px;
    background: rgb(255, 255, 255);
    overflow: hidden;
    display: block;
  }

  .footer-box {
    margin-top: -14px;
    overflow-x: hidden;
    position: relative;
    .hidden-dlt {
      margin-top: 16px;
    }
  }

  .footer-box:hover {
    .hidden-dlt {
      visibility: visible;
    }
  }

  .hidden-dlt {
    margin-top: 2px;
    margin-right: 2px;
    top: 0;
    right: 0;
    height: 22px;
    width: 22px;
    font-size: 18px;
    visibility: hidden;
    border: none;
    border-radius: 2px;
    color: rgb(255, 255, 255);
    background-color: #808080;
    position: absolute;
    z-index: 10;
  }

  .hidden-move {
    margin-top: 26px;
    margin-right: 2px;
    top: 0;
    right: 0;
    height: 22px;
    width: 22px;
    font-size: 18px;
    visibility: hidden;
    border: none;
    border-radius: 2px;
    color: rgb(255, 255, 255);
    background-color: #808080;
    position: absolute;
    z-index: 10;
    cursor: all-scroll;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .title-info-req {
    // font-size: 20px;
    font-weight: 500;
     
    color: #004A9A;
    // text-align: center;
    // margin: 30px;
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .item-list {
    width: 100%;
    padding: 10px;
    padding-bottom: 25px;
    border-bottom: solid 1px #cccccc;
    min-height: 60px;
    background: rgb(255, 255, 255);
    overflow: hidden;
    display: block;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
  }

  .item-list-body {
    width: 100%;
    padding: 10px;
    padding-bottom: 25px;
    border-bottom: solid 1px #cccccc;
    min-height: 60px;
    background: rgb(255, 255, 255);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item-list-body-col {
    width: calc((100% - 10px) / 2);
    display: block;
  }

  .item-list-label {
    width: 100%;
     
  }

  .cdk-drag-placeholder {
    opacity: 0.5;
  }

  .body-box:last-child {
    border: none;
  }

  .body-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .menu-bar {
    background-color: #ffffff;
    align-items: center;
    color: #494949;
    text-align: left;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding: 0 12px;
    padding-top: 14px;
  }
  .menu-bar:hover {
    overflow-x: auto;
  }
  .menu-bar::-webkit-scrollbar {
    height: 4px;
  }

  .menu-bar::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
  .menu-bar::-webkit-scrollbar-track {
    padding-top: 1px;
    padding-bottom: 1px;
    background: rgb(255, 255, 255);
  }

  .right-block {
    overflow: hidden;
    padding-right: 10px !important;
    .k-content {
      padding-top: 0px !important;
      height: calc(100vh - 120px);
      overflow-y: auto;
    }
    .page-save {
      height: 80px;
      border: solid 1px #ebebeb;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      .page-save-icon {
        font-size: 16px;
        cursor: pointer;
      }
      // .page-preview {
      //   height: 100%;
      //   border: none;
      //   border-radius: 5px;
      //   color: white;
      //   background-color: #5350D8;
      //   font-weight: bold;
      //    
      //   width: 45%;
      // }
      .page-publish {
        height: 100%;
        border: none;
        border-radius: 5px;
        color: black;
        background-color: #d8a750;
        font-weight: bold;
         
        width: 45%;
        padding: 0 20px;
        // width: fit-content;
      }
      .preview-button {
        width: 45%;
        height: 100%;
        border: none;
        border-radius: 5px;
        color: #ffffff;
        background-color: #5350D8;
         
        font-weight: bold;
        padding: 0 20px;
      }
    }
  }

  .right-block .k-content::-webkit-scrollbar {
    width: 10px;
  }

  .right-block .k-content::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  .right-block .k-content::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 2px;
  }

  iframe {
    .container-fuild {
      display: none !important;
    }
  }

  .box {
    height: calc(100vh - 100px) !important;
    width: 100%;
    // height: 100% !important;
    overflow: hidden !important;

    .container-fuild {
      display: none !important;
    }
  }

  .k-tabstrip-items {
    border: solid 1px #ebebeb;
    border-top: none;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
  }

  .k-content.k-dialog-content {
    padding-top: 16px !important;
  }

  .k-tabstrip-items .k-item {
    color: #8b8b8b;
    border: none;
  }

  .k-tabstrip-items .k-item.k-state-active,
  .k-tabstrip-items .k-item.k-state-selected {
    color: #004A9A;
    font-weight: 700;
    border-width: 0 0 4px;
    border-bottom: solid 2px #004A9A;
  }
}

.item-box-header {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-box-footer {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-box-layout {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
  // border: solid 1px #cccccc;
  // border-radius: 4px;
}

.item-box-body {
  width: 100%;
  // width: calc((100% - 10px) / 2);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  margin-top: 15px;
}

.item-img-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-footer {
  width: 100%;
  height: auto;
  //height: 100%;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-body {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: solid 2px #D3DCDF;
  border-radius: 3px;
}

.item-img-layout {
  width: 100%;
  height: auto;
  object-fit: cover;
  // border: solid 2px #D3DCDF;
  // border-radius: 3px;
}

.cdk-drag-preview {
  display: flex;
  flex-wrap: nowrap !important;
  padding: 0px !important;
  margin: 0px !important;
  .item-img {
    margin: -15px;
    width: calc(100% + 30px);
  }
  .item-img-footer {
    margin: -15px;
    width: calc(100% + 30px);
  }
  .item-img-body {
    margin: -15px;
    width: calc(100% + 30px);
  }
  .layout-group {
    box-sizing: border-box;
    border: solid 1px #cccccc;
  }
  .hidden-dlt {
    visibility: hidden;
  }
  .hidden-move {
    visibility: hidden;
  }
  .body-box {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.header-preview {
  top: -125px !important;
  left: -450px !important;
}
.layout_100-preview {
  top: -50px !important;
  left: -450px !important;
}
.layout_80_20-preview {
  top: -50px !important;
  left: -450px !important;
}
.layout_70_30-preview {
  top: -50px !important;
  left: -450px !important;
}
.template-1-preview {
  top: -240px !important;
  left: -450px !important;
}
.template-2-preview {
  top: -220px !important;
  left: -320px !important;
}
.template-3-preview {
  top: -200px !important;
  left: -135px !important;
}
.template-4-preview {
  top: -100px !important;
  left: -135px !important;
}
.template-5-preview {
  top: -195px !important;
  left: -450px !important;
}
.template-7-preview {
  top: -195px !important;
  left: -450px !important;
}
.template-8-preview {
  top: -67px !important;
  left: -135px !important;
}
.template-9-preview {
  top: -112px !important;
  left: -135px !important;
}
.banner-1-preview {
  top: -250px !important;
  left: -450px !important;
}
.banner-2-preview {
  top: -222px !important;
  left: -450px !important;
}
.template-13-preview {
  top: -332px !important;
  left: -135px !important;
}
.footer-preview {
  top: -133px !important;
  left: -450px !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.nav-scroll {
  position: relative;
  width: fit-content;
  align-items: center;
  // display: flex;
  cursor: pointer;
  margin-bottom: 6px;
}

.nav-item,
.nav-item-selected {
  box-sizing: border-box;

  padding: 8px 20px;
  font-weight: 400;
  font-size: 16px;
  border: none;
  background-color: #ffffff;
  text-transform: uppercase;
}

.nav-item-selected {
  border: none;
  //border-bottom: 4px solid #007fae;
  font-weight: 700;
  color: #007fae;
}

.field-name {
  margin-top: 10px;
  font-size: 15px;
}

.search-place {
  width: 100% !important;
  margin-top: 15px;
  padding: 4px 10px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    border: none;
    width: 100%;
     
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  input::placeholder {
    font-weight: 400;
    color: #000000;
  }

  input:focus {
    outline: none;
  }

  i {
    color: gray;
  }
}

.dropdown {
  padding: 0 2px;

  kendo-combobox {
    width: 100% !important;
  }

  .k-dropdown-wrap {
    background-color: white;
    border: none;
  }

  .k-state-focused {
    box-shadow: none;
  }

  .k-select {
    background-color: white;
    background-image: none;
  }

  .k-input {
    font-size: 16px;
    line-height: normal;
    height: 100%;
  }

  .k-i-close {
    height: 100%;
  }
}

@keyframes mykey {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.nav-scroll-selected::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #007fae;
  left: 0;
  bottom: 0;
  animation: mykey 0.3s ease-out;
}

