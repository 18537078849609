.login-page {
  .panel-login {
    .description {
      h6 {
        color: #606060;
        font-size: 20px;
      }
      .content {
        color: #1F1F1F;
        font-size: 16px;
      }
    }
    .form {
      width: 100%;
    }
    .cf-forgot-password-button {
      width: 100%;
      background-color: #00396b;
      border: none;
      color: white;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    .alert {
      background-color: #E8FFEA;
      width: 100%;
      color: #42AE30;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .icon-alert {
        width: 22px;
        height: 22px;
      }
      .description {
        font-size: 14px;
        text-align: left;
      }
    }
  }
}