.news-template-1, .news-template-1-without-time {
  width: 100%;
  min-height: 504px;
  .row {
    --bs-gutter-x: 0;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  .post-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 43px;   
    color: #000;
         
  }

  .title-text {
    margin-bottom: 0 !important;
  }

  .post-more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #d6a022;
     
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-color: white;
    padding-top: 4px;
    a {
      color: #d6a022;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .icon-clock {
    width: 14px;
    height: 14px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #292621;
    text-decoration: none;
     
    margin-bottom: 5px !important;
  }
  .title-template-type-2{
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #2B2B2B;
    margin-bottom: 20px;

  }

  .post-image, .post-image-radius {
    // height: 100%;
    width: 100%;
    // width: 100px;
    // height: 200px;
    // -o-object-fit: cover;
    //    object-fit: cover;
  }
  .post-image-radius{
    border-radius: 4px;
  }
  .post-image-default{
    height: 100%;
    width: 100%;
  }

  .post-image-org {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 40%;
  }

  .time-clock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .time-text {
    color: #979797;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .main-item {
    display: table;
  }

  .vertical-dot {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #808080;
    color:white;
    z-index: 10;
    margin-top: 2px;
    margin-left: 2px;
    border-radius: 2px;
    visibility: hidden;
  }

  .main-item:hover {
    .vertical-dot {
      visibility: visible;
    }
  }

  .d-flex {
    visibility: visible !important;
  }

  .three-dot-area {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .menu {
    background-color: white;
    cursor: pointer;
    width: 110px;
    top: 43%;
    left: 100%;
    position: absolute;
    z-index: 1;

    .menu-row,
    .menu-row-current,
    .menu-row-current-border {
      cursor: pointer;

      .menu-item-1,
      .menu-item-2 {
        padding: 5px 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: center;
      }
    }

    .menu-row-current,
    .menu-row-current-border {
      background-color: white;
      color: black;
      -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
 
      text-align: center;
    }

    .menu-item-3 {
      padding: 5px 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-align: center;
    }
  }

  .img-figure,
  .img-figure-no-img,
  .img-figure-template-type-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    margin-bottom: 20px;
  }

  .img-figure-2{
    height: 260px !important;
  }


  .post-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    .k-pager-info, .k-pager-sizes {
      display: none;
    }
    .k-pager {
      kendo-datapager-prev-buttons {
        margin-left: auto;
      }
      .k-state-selected {
        background-color: #D6A022;
        border: none;
      }
    }
    .top-part {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .top-new-item {
    margin: 5px 0;
    padding-right: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    .title:hover {
      color: #00396b;
    }
  }

  .close-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
  }

  .news-template-1-dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 10px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title-info-req {
      font-weight: 500;
 
      color: #004A9A;
    }
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }
  .dialog-width{
    max-width: 400px;
  }
  .space-box{
    width: 100%;
  }
  @media screen and (max-width: 768px){
    .img-figure{
      height: auto !important;
    }
    .post-list{
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
}