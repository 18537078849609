.opend-dialog-video {
  // width: 100%;
  max-width: 100%!important;
  max-height: 100%!important;
}

.opend-dialog-video .k-window-content, .k-prompt-container {
  padding: 0px !important;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}
.opend-dialog-video .close-icon-div {
  position: absolute;
  // right: 8px;
  // top: 10px;
  right: -10px;
  top: -30px;
}
.opend-dialog-video .close-icon {
  // width: 20px;
  // height: 20px;
  // cursor: pointer;
  width: 30px;
    height: 30px;
    position: fixed;
    cursor: pointer;
    z-index: 555555;
}


.opend-dialog-video .vnpt-upload-img .k-upload {
  display: none;
}

.opend-dialog-video .vnpt-upload-img img {
  width: 160px;
}

.opend-dialog-video .err-text {
  color: #ff6358;
  margin-left: 15px;
  font-style: italic;
}

.opend-dialog-video .required {
  color: #ff6358;
  padding-left: 3px;
  font-style: italic;
}
.opend-dialog-video .err {
  border: 1px solid #ea4335;
  animation: myerr 1s infinite;
}

@keyframes myerr {
  0% {
      border-color: #ea4335;
  }
  25% {
      border-color: yellow;
  }
  50% {
      border-color: blue;
  }
  100% {
      border-color: #34a853;
  }
}

:host ::ng-deep .opend-dialog-video.x-dialog {
  max-width: 768px;
  max-height: 565px;
}

.opend-dialog-video .custom-btn-addNew {
  margin-top: 10px !important;
}

.opend-dialog-video .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 5px;
}

:host ::ng-deep .opend-dialog-video .btn-custom {
  width: 100%;
}
:host ::ng-deep .opend-dialog-video .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}


.opend-dialog-video .default-lang {
  border-bottom: none;
  float: left;
  margin-right: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}
.opend-dialog-video .selected-lang {
  border-bottom: 3px red solid;
}


@media (max-width: 600px) {
  :host ::ng-deep .k-window {
      width: 90%;
      height: 90%;
  }
  :host ::ng-deep .opend-dialog-video.x-dialog {
      max-width: 768px;
      max-height: none;
  }

  .opend-dialog-video .vnpt-upload-img img {
      width: 99px;
  }
  .opend-dialog-video {
    // width: 100%;
    max-width: 100%!important;
    max-height: 100%!important;
    // position: inherit;
  }
}


.opend-dialog-video .row-file {
  margin-bottom: 5px;
}

.opend-dialog-video .float-right {
  float: right ;
}

.opend-dialog-video .border-infor{
  background: #FFFFFF;
  border: 0.6px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 11px;
}

.opend-dialog-video .label-infor{
  color: #407BFF;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.opend-dialog-video .control {
  margin-bottom: 22px;
}

.opend-dialog-video .input-label {
 
margin: 10px;
margin-left: 15px;
}

.opend-dialog-video .input-box {
height: 40px;
margin: 10px 15px;
width: calc(100% - 30px);
//   border: solid 1px #e9e9e9;
//   border-radius: 0px;
background-color: white;
}

.opend-dialog-video .k-input{
height: 38px !important;
margin-top: -1px !important;
}

.opend-dialog-video .k-clear-value {
height: 38px !important;
margin-top: -1px !important;
}

.opend-dialog-video .btn-save {
margin: 15px;
width: 200px;
height: 40px;
 
text-align: center;
border-radius: 5px;
background-color: #1c8d43;
}

// .opend-dialog-video .btn-save{
//     color: #fff;
//     background-color: #34a853;
//     border: hidden;
//     padding: 5px 20px;
//     font-size: 14px!important;
//     width: 200px;
//     height: 40px;
// }

.opend-dialog-video .padding-col{
  padding: 7px 15px 0;
}
@media screen and (max-width: 768px) {
  .opend-dialog-video .video-box{
    height: 310px !important;
  }
}