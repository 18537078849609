.question-info{
    .err-text, .required {
    color: #ff6358;
    padding-left: 3px;
    font-style: italic;
    
}
.err {
    border: 1px solid #ea4335;
    animation: myerr 1s infinite;
}

@keyframes myerr {
    0% {
        border-color: #ea4335;
    }
    25% {
        border-color: yellow;
    }
    50% {
        border-color: blue;
    }
    100% {
        border-color: #34a853;
    }
}



 .btn-custom {
    text-align: right;
    margin-right: 15px;
    margin-bottom: 5px;
}

 .btn-custom button {
    background: #cd330d;
    color: white;
}

 .btn-send {
    background: #c60000;
    color: white;
    position: relative;
    float: right;
    /* border-radius: -5px; */
    border: none;
    margin-right: 0px;
    margin-top: 35px;
    margin-bottom: 5px;
    text-align: center;
    padding: 1px 24px;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 8px;
    font-weight: 500;
}



 .button-div {
    position: relative;
    float: right;
    margin-top: 15px;
    /* display: block; */
    display: inline-flex;
}




 .default-lang {
    border-bottom: none;
    float: left;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}

 .selected-lang {
    border-bottom: 3px red solid;
}
.employe-detail{
    margin-top: 15px;
    margin-left: 10px;
}
@media screen and (max-width: 770px){    
    .employe-detail{
       margin: 0;
    }
}
}