footer.ver3 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  // margin-bottom: 1.5em;
  background-color: #00396B;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
  .pointer{
    cursor: pointer;
  }

  .footer2 {
    // width: var(--outlet-content-width);
    // margin-right: 5rem;
    // margin-left: 10rem;
    // height: 240px;
    color: white;
    // margin: auto;
    padding: 20px 0 10px 0;

    .row {
      display: flex;
      flex-direction: row;
    }

    .title span {
      display: inline !important;
      width: fit-content;
      font-style: normal;
      padding-bottom: 10px;
    }

    .info {
      .title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      ul {
        list-style-type: none;

        i {
          font-size: 1rem;
        }
      }
    }

    .description {
      margin: 15px 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    ul {
      padding: 0;
      margin-bottom: 0;
    }

    .info {
      padding-right: 1em;
    }

    .menu ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 9em;
      list-style-type: disc;
      margin-left: 10px;
    }

    .menu li {
      width: 50%;
    }

    li {
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    li:hover,
    .info:hover {
      .btn-func {
        visibility: visible;
      }
    }

    li a {
      color: white;
      text-decoration: none;
    }

    .btn-func {
      visibility: hidden;
    }

    .btn-custom {
      width: fit-content;
      background: initial;
      color: white;
    }

    .btn-custom button {
      background-color: inherit;
      width: fit-content;
      color: white;
      border: none;
    }

    .btn-custom .k-button {
      padding: 0 !important;
    }

    .k-popup .k-list {
      height: auto !important;
      width: fit-content;
      padding: 0;
      margin: 0;
    }

    .k-popup .k-item {
      width: 100%;
      padding: 5px;
    }

    .handle:hover {
      cursor: pointer;
    }

    .btn-add {
      background-color: inherit;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: 1px solid white;
      border-radius: 2px;
      height: 2.5em;
    }

    .btn-add:hover {
      background-color: #074973;
    }

    .menu,
    .contact {
      height: 80px;
      border-left: 1px solid #FFFFFF;
      padding-left: 2em;
    }

    .contact {
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      .social-icon {
        margin-top: 15px;

        a {
          margin: 0 12px;

          img {
            height: 20px;
            width: 20px;
          }
        }

        i {
          color: white;
        }
      }
    }
  }

  .copyright {
    background-color: #08192B;
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 0;
  }

  .edit {

    .required,
    .err-text {
      color: #ff6358;
      padding-left: 3px;
      font-style: italic;
    }
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black;
    font-size: 20px;
  }

  .title-info-req {
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .btn-save {
    color: white;
    background-color: #34a853;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    margin-top: 10px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 1024px) {
  footer.ver3 {

    .footer2 {
      padding: 0 10px;

      .info,
      .menu,
      .contact {
        border-bottom: 1px solid white;
        margin: 10px 0 0;
      }

      .menu {
        height: auto;
      }

      .btn-func {
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  footer.ver3 {
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .btn-add {
      width: 25% !important;
    }

    width: 100% !important;

    .footer2 {
      padding: 0 5px;

      .info,
      .menu,
      .contact {
        border-bottom: 1px solid white;
        margin: 5px 0 0;
        padding: 0 1.5rem;
      }

      .menu {
        height: auto;
      }

    }
  }
}

@media screen and (max-width: 800px) {
  footer.ver3 {
    width: 100% !important;

    .add-menu.x-dialog {
      width: 95% !important;
      height: 95% !important;
    }
  }
}
