footer.ver4 {
  font-size: 14px;
  margin: auto;
  margin-top: 1em;
  // margin-bottom: 1.5em;
  background-color: #FFFFFF;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .pointer {
    cursor: pointer;
  }

  .footer3 {
    // width: var(--outlet-content-width);
    // margin-right: 5rem;
    // margin-left: 10rem;
    // height: 240px;
    color: black;
    // margin: auto;
    //padding: 20px 0 10px 0;

    .row {
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
    }

    .title span {
      display: inline !important;
      width: fit-content;
      font-style: normal;
      padding-bottom: 10px;
    }

    .textapplink {
      margin-top: 5px;
      margin-bottom: 5px;
       
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      // line-height: 24px;
      color: #848585;
      margin: 15px 0px 15px 0px;
    }

    .barcodeholder {
      border: 1px solid #000000;
      box-sizing: border-box;
      height: 100px;
      width: 100px;
    }

    .googleplay {
      padding-left: 0px;
      margin-top: 10px;
    }

    .appstore {
      margin-top: 5px;
      padding-left: 0px;
    }

    .applink-holder {
      display: flex;
      justify-content: end;
      flex-direction: column;
      float: right;
      margin-bottom: 15px;
      // padding-top: 60px;
    }

    .barcodes {

      padding: 7px;
      width: 100%;
    }

    .image-block {
      justify-content: left;
      display: flex;
    }

    .info {
      color: #131313;
       
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      // line-height: 27px;

      // display: flex;
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
      }

      ul {
        list-style-type: none;
        margin-bottom: 15px;


        i {
          font-size: 1rem;
        }
      }
    }

    .description {
      margin-bottom: 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      // line-height: 24px;
      color: #848585;
    }



    ul {
      padding: 0;
      margin-bottom: 0;
    }

    .info {
      padding-right: 1em;
    }

    .menu ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 9em;
      list-style-type: disc;
      margin-left: 10px;
    }

    .menu li {
      width: 50%;
    }

    li {
      padding-bottom: 7px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1rem;
    }

    li:hover,
    .info:hover {
      .btn-func {
        visibility: visible;
      }
    }

    li a {
      color: black;
      text-decoration: none;
    }

    .btn-func {
      visibility: hidden;
    }

    .btn-custom {
      width: fit-content;
      background: initial;
      color: black;
    }

    .btn-custom button {
      background-color: inherit;
      width: fit-content;
      color: black;
      border: none;
    }

    .btn-custom .k-button {
      padding: 0 !important;
    }

    .k-popup .k-list {
      height: auto !important;
      width: fit-content;
      padding: 0;
      margin: 0;
    }

    .k-popup .k-item {
      width: 100%;
      padding: 5px;
    }

    .handle:hover {
      cursor: pointer;
    }

    .btn-add {
      background-color: inherit;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      border: 1px solid white;
      border-radius: 2px;
      height: 2.5em;
    }

    .btn-add:hover {
      background-color: #074973;
    }

    .menu,
    .contact {
      height: 80px;
      border-left: 1px solid #FFFFFF;
      padding-left: 2em;
    }

    .contact {
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      .social-icon {
        margin-top: 15px;

        a {
          margin: 0 12px;

          img {
            height: 20px;
            width: 20px;
          }
        }

        i {
          color: black;
        }
      }
    }
  }

  .copyright {
    background-color: #08659A;

    // width: 100%;
    //  

    width: 100%;
     
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 0px;
  }

  .icon-footer {
    // width: 120px;
    max-height: 107px;
    margin: 30px 0px 20px 0px;
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  .edit {

    .required,
    .err-text {
      color: #ff6358;
      padding-left: 3px;
      font-style: italic;
    }
  }

  .xbutton-dialog {
    padding: 0;
    color: inherit;
    background: none;
    border-width: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 1px;
    color: black;
    font-size: 20px;
  }

  .title-info-req {
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .btn-save {
    color: black;
    background-color: #34a853;
    border: hidden;
    padding: 5px 20px;
    font-size: 14px !important;
    margin-top: 10px;
    margin-right: 16px;
  }

  .break-line {
    border-bottom: 1px solid #D2D2D2;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .margin-box {
    margin-bottom: 30px;
  }

  .menu-title {
     
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    // line-height: 26px;
    // letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .childmenu-title {
     
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #454545;
    cursor: pointer;
  }

  .menu-width {
    margin: auto;
    width: calc(100% - 250px);
    min-width: 80%;
    max-width: 1550px;
  }

  .footer-background {
    border: 1px solid #006CB5;
    width: calc(100% - 1px);
    margin-left: 1px;
  }

  .margin-background {
    margin: auto;
  }

  .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    // letter-spacing: -0.02em;
    color: #006CB5;
    margin: 20px 0px 20px 0px;

  }

  .operator-info {
    display: flex;
  }

  .icon-copyright {
    // width: 45px;
    height: 27px;
  }

  .icon-copyrightholder {
    // width: auto;
    // height: 20px;
    //padding: 15px;
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
  }

  .logofooterholder {
    justify-content: right;
    display: flex;
    // width: 24%;
    // margin-left: 2px;
  }

  .logofooter {
    width: 30px;
    margin-left: 2px;
    margin-right: 1px;
    position: absolute;
    right: 0;
    height: 100%;
  }

  .footertext {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .online-box {
    margin: 20px 10px 10px 10px;
  }

  .copyright-row {
    display: flex;
    flex-wrap: nowrap;

  }

  .bold-title {
    font-weight: 700;
  }

  .title-text {
     
    font-weight: 700;
    font-size: 14px !important;
    // line-height: 22px !important;
    color: #848585;
  }

  .link {
    display: flex;
  }

  .download-box {
    margin-left: 10px;
  }
  .c-text{
    color: #FFF;
    font-size: 1.3rem;
     
    letter-spacing: -0.03375rem;
  }
  @media screen and (max-width: 770px) {
    .item-box{
      border-bottom: black dashed 0.7px;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
    .menu-title{
      margin-bottom: 5px;
    }
    .margin-box{
      margin-bottom: 0px !important;
    }
  }
  .access-title{
    color: #848585;
     
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.0275rem;
  }
  .margin-title{
    margin-top: 10px;
  }
  .number-box{
    color: #FFF;
     
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.0275rem;
    background: #006CB5;
    width: 1.23rem;
    height: 1.83rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    margin-right: 5px;
  }
  .number-arr{
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {
  footer.ver4 {
    .menu-width {
      // width: calc(100% - 100px);
    }
    .footer3 {
      // padding: 0 10px;

      .info,
      .menu,
      .contact {
        border-bottom: 1px solid white;
        // margin: 10px 0 0;
      }

      .menu {
        height: auto;
      }

      .btn-func {
        visibility: visible;
      }


      .operator-info {
        margin-left: 0px;
      }
      .applink-holder {
        float: initial;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  footer.ver4 {
    .menu-width {
      width: calc(100%);
    }
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .btn-add {
      width: 25% !important;
    }

    width: 100% !important;

    .footer3 {
      // padding: 0 5px;

      .info,
      .menu,
      .contact {
        border-bottom: 1px solid white;
        margin: 5px 0 0;
        //padding: 0 1.5rem;
      }

      .menu {
        height: auto;
      }

      .applink-holder {
        padding-top: 0px;
      }

    }
  }
}

@media screen and (max-width: 800px) {
  footer.ver4 {
    width: 100% !important;

    .menu-width {
      // width: calc(100%);
    }

    .add-menu.x-dialog {
      width: 95% !important;
      height: 95% !important;
    }
  }

  .applink-holder {
    padding-top: 0px !important;
  }

  .textapplink {
    font-size: 16px !important;
    //margin-left: 20px;
    margin-bottom: 10px !important;
  }

  .barcodeholder {
    width: 80px;
  }

  .appimg {
    width: 100px;
  }

  .barcode {
    //width: 80px;
  }
  .image-block {
    justify-content: center !important;
  }
  footer.ver4 .icon-footer {
    width: auto !important;

}
}
@media screen and (min-width: 768px) and (max-width: 1444px) {
  footer.ver4 .link {
    flex-direction: column;
  }
  footer.ver4 .download-box {
    margin-left: 0px !important;
  }
}
