.news-template-38 {
  .img-scrollview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    // height: 406px;
  }

  .banner {
    width: 100% !important;
    
  }
  .image-name{
    position: absolute;
    bottom: 0;
    //text-align: center;
    width: 100%;
     
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    color: #FFFFFF;
    padding: 25px 55px 0px 55px;
    height: 115px;
    background-image: url('../../assets/images/slider-title-background.png') !important;
    white-space: pre-line;
    border-radius: 0px 0px 4px 0px;
  }

  .k-dialog {
    height: auto !important;
    width: auto !important;
  }

  kendo-scrollview.k-scrollview-wrap {
    border: none;
  }
  kendo-scrollview-pager{
    display: none;
  }

  .k-scrollview-next,
  .k-scrollview-prev {
    text-shadow: none;
    background: rgba(32, 28, 28, 0.24);
    height: 36px;
    width: 36px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    visibility: hidden;
  }
  
  .k-scrollview-next{
    right: 2%;
  }
  .k-scrollview-prev{
    left: 2%;
  }

  .k-scrollview-next .k-icon,
  .k-scrollview-prev .k-icon {
    font-size: 36px;
    text-align: center;
  }

  .vertical-dot {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
    border-radius: 2px;
    background-color: rgba(32, 28, 28, 0.1);
    ;


    .three-dot-area {
      cursor: pointer;
      padding: 10px 20px;
      width: 100%;
      display: flex;
      justify-content: center;

    }

    .three-dot {
      width: 5px;
      height: 20px;
    }

    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: fit-content;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;
      white-space: nowrap;

      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;

        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: fit-content;
          // border-bottom: 2px solid black;
        }
      }

      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }

      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }

      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  .banner-container:hover .vertical-dot {
    display: flex;
  }

  .router-outlet-content {
    width: 695px !important;
    height: 90%;
  }

  .router-outlet-content-gallery {
    width: 794px !important;
    height: 90%;
  }

  .title-info-req {
    color: #004A9A;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  .input-label {
     
    color: #004A9A;
    margin: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    text-align: center;
  }

  .btn-delete {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #1c8d43;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .btn-cancel {
    margin: 15px;
    width: 100px;
    height: 40px;
     
    text-align: center;
    border-radius: 5px;
    background-color: #6c757d;
    border: hidden;
    color: white;
    font-size: 14px;
     
  }

  .employe-detail {
    margin-top: 10px;
  }
  .employe-detail-mb {
    padding: 10px 0px;
    margin-bottom: 18px;
  }

  .close-div {
    display: flex;
    margin-top: 3px;
  }

  .slider-dialog {
    display: flex;
    justify-content: center;
    position: relative;

    .close-icon-div {
      position: absolute;
      right: 0px;
    }

    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .k-scrollview {
    position: relative;
  }
  

  @media screen and (max-width: 1200px) {
    .img-scrollview{
      // height: 300px;
      height: 100%;
    }
  }
  @media (max-width:1024px) {
    .router-outlet-content {
      width: 100% !important;
    }
    .router-outlet-content-gallery{
      width: 100% !important;
    }
    .img-scrollview{
      // height: 300px;
      height: 100%;
    }
  }


  @media screen and (max-width: 770px) {
    .img-scrollview{
      // height: 300px;
      height: 100%;
    }
    .image-name{
      // font-size: 1.2rem;
      font-size: 5vw;
      padding: 20px 50px 0px 50px;
    }
  }
}

.news-template-38:hover {
  .vertical-dot {
    visibility: visible;
  }
  // .k-scrollview-next,
  // .k-scrollview-prev{
  //   visibility: visible;
  // }
}
