.field .k-grid-header,
.field .k-grid-header .k-header {
  font-weight: bold;
  padding-right: 0px !important;
}
.field .control > * {
  width: 40% !important;
}
.field .router {
  width: 100%;
  margin: auto;
  margin-top: 15px !important;
}
.field .k-i-close {
  margin-top: 7px !important;
}
.field .k-dropdownlist {
  display: none;
}
.field .k-pager-numbers .k-link.k-state-selected,
.k-i-arrow-double-60-right .k-i-forward-sm {
  color: #fff;
  background-color: #d6a022;
}
.field .col-md-3 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}
.field .check-element {
  display: none !important;
}


.field .tab-des {
  margin: 30px 0;
  display: none;
  p {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #00396b;
  }
  font-weight: bold;
  color: #00396b;
}
.field .control-search .btn-remove {
  background-color: transparent !important;
  right: 40px;
  color: #000 !important;
  width: 30px;
  height: 33px;
  float: right;
  border-radius: 0;
  margin-top: -32px !important;
  border: 0;
  margin-right: -38px;
}
.field .k-grid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #2c2c2c !important;
  background-color: #ffffff;
}
.field .list-member-title {
  font-size: 16px !important;
  color: #ea4335;
  margin-top: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
.field .k-grid {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-top: 20px;
}

.field .k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: white !important;
}
.field .k-cell-inner > .k-link {
  padding: 8px 12px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: white !important;
}
.field .k-grid td {
  border-width: 0 0 0px 0px !important;
  vertical-align: middle;
  background-color: white;
  border-bottom: 1px solid #e1e1e1 !important;
  vertical-align: top;
  padding-top: 15px;
}
.field .img {
  cursor: pointer;
}
.field .k-pager-info {
  text-align: right;
  justify-content: flex-end;
  flex: 1 1 0%;
  visibility: hidden;
  order: 9;
  overflow: hidden;
  display: none;
}
.field .k-pager-wrap {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: white !important;
  justify-content: right !important;
  border-top: 1px solid #e1e1e1 !important;
  margin-top: 30px;
}

.field .k-grid-header {
  padding: opx !important;
  border-bottom: 1px solid #b6b6b6 !important;
}
.field .k-grid-header-wrap,
.k-grid-footer-wrap {
  margin-right: -1px;
  width: 100%;
  border-width: 0px 0px 0 0 !important;
  border-style: solid;
  border-color: inherit;
  position: relative;
  overflow: hidden;
}
.field .k-grid-content {
  width: 100%;
  min-height: 0;
  overflow: auto;
  overflow-x: auto;
  /* overflow-y: scroll; */
  position: relative;
  flex: 1;
}
.field .search-place {
  width: 100% !important;
  margin-top: 20px;
}
.field .k-grid th {
  padding: 8px 12px;
  border-width: 0 0 0 0;
  white-space: nowrap;
  background-color: white;
}
.field .btn-search {
  color: white;
  background-color: #d6a022;
  border: hidden;
  padding: 5px 20px;
  font-size: 14px !important;
  position: absolute;
  border-radius: 3px;
}

.field .btn-dialog-cus {
  float: left;
  margin-top: 30px;
}

.field .total-member {
  color: black;
}

.field .dialog-info-post-detail {
  max-width: 830px;
}

@media (max-width: 600px) {
  :host ::ng-deep .field .dialog-info {
    width: 100% !important;
    height: 100% !important;
    z-index: 1060;
  }
}
.field .btn-addNew {
  text-align: right;
  display: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}

.field .btn-addNew .k-button {
  font-size: 14px !important;
}

.field .btn-custom {
  width: 100%;
}

.field .btn-custom button {
  background: #cd330d;
  color: white;
  width: 100%;
}
.field .item {
  border: 1px solid #c4c4c4;
  margin: 10px;
}
.field .index {
  float: left;
  width: calc(100% - 28px);
}
@media (max-width: 768px) {
  .field .k-dropdown-button button {
    background: none !important;
    color: black !important;
    border: none !important;
    padding-right: 0px;
    width: 28px;
    float: right;
  }
  .field .btn-addNew {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
