.news-template-46 {
    width: 100%;
    // min-height: 504px;
    //background-color: white !important;
    margin-top: 50px;
  
    .row {
      --bs-gutter-x: 0;
    }
  
    .k-dialog {
      height: auto !important;
      width: auto !important;
    }

    .k-scrollview-pageable > .k-button.k-primary, .k-scrollview-nav > .k-link.k-primary {
      background: #0084C7;
      border: #0084C7;
    }
    
    .k-scrollview-pageable > .k-button, .k-scrollview-nav > .k-link {
      background: #D9D9D9;
    }

    .post-title {
      display: flex;
      justify-content: center;
      color: #00396b;
       
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 29px;
    }
  
    .title-text {
      // padding-left: 10px;
      margin-bottom: 0 !important;
    }
  
    .post-more {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 43px;
      color: #000;
       
      a {
        color: #d6a022;
      }
    }
  
    .pointer {
      cursor: pointer;
    }
  
    .icon-clock {
      width: 14px;
      height: 14px;
    }
  
    .title {
      line-height: 21px;
      font-style: normal;
      font-size: 14px;
      color: #292621;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      overflow-wrap: break-word;
      -webkit-box-orient: vertical;  
      max-width: 55%;
      margin: 10px 0;
    }
  
    .post-image {
      height: 100px;
      width: 100px;
      border-radius: 100%;
      object-fit: contain;
      border: 1px solid #0084C7;
    }
    .post-image-default{
      height: 100px;
      width: 100px;
    }
  
    .post-image-org {
      height: 60px;
      width: 60px;
      position: absolute;
      top: 40%;
    }
  
    .time-clock {
      display: flex;
    }
  
    .time-text {
      color: #979797;
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  
    .main-item {
      display: table;
    }
  
    .vertical-dot {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #808080;
      color:white;
      z-index: 10;
      margin-top: 2px;
      margin-left: 2px;
      border-radius: 2px;
      visibility: hidden;
    }
  
    .main-item:hover {
      .vertical-dot {
        visibility: visible;
      }
    }
  
    .d-flex {
      visibility: visible !important;
    }
  
    .three-dot-area {
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  
    .menu {
      // border: 2px solid black;
      // background-color: #fffcfc;
      background-color: white;
      // color: #0079ff;
      // border-radius: 5px;
      cursor: pointer;
      width: 110px;
      top: 43%;
      left: 100%;
      position: absolute;
      z-index: 1;
  
      .menu-row,
      .menu-row-current,
      .menu-row-current-border {
        cursor: pointer;
  
        .menu-item-1,
        .menu-item-2 {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // border-bottom: 2px solid black;
        }
      }
  
      .menu-row-current,
      .menu-row-current-border {
        // background-color: #d8d5d5;
        // color: #686868;
        // border-bottom: 2px;
        // border-radius: 0 0 2px 2px;
        background-color: white;
        color: black;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   
        text-align: center;
      }
  
      // .menu-row-current-border {
      //   border-radius: 2px 2px 0 0;
      // }
  
      .menu-item-3 {
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  
    .img-figure,
    .img-figure-no-img {
      height: 100px;
      width: 100px;
      // max-width: 500px;
      display: flex;
      justify-content: center;
      position: relative;
      margin: 10px;
    }
  
    .img-figure-2{
      // height: 260px !important;
    }
  
    // .img-figure-no-img{
    //     border: #afa6a6 dashed 2px;
    // }
  
    .post-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 17px;
      min-width: 50%;
      max-width: 80%;
      justify-content: center;
      gap: 50px;
      .k-pager-info, .k-pager-sizes {
        display: none;
      }
      .k-pager {
        // padding-left: auto;
        kendo-datapager-prev-buttons {
          margin-left: auto;
        }
        .k-state-selected {
          background-color: #D6A022;
          border: none;
        }
      }
      .top-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-wrap: wrap;
        border-radius: 5px;
        border: none;
        background: #FFF;
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        text-align: center;
      }
    }
  
    .uppercase {
      text-transform: uppercase;
    }
  
    .top-new-item {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      width: fit-content;
      .title:hover {
        color: #00396b;
      }
    }

    // .detail-part{
    //   position: absolute;
    //   top: 65px;
    //   left: 175px;
    //   background-color: #0084C7;
    //   color: white;
    //   padding: 10px;
    //   z-index: 2;
    //   border-radius: 5px;
    //   // display: none;
    // }

    // .detail-part:hover{
    //   display: block;
    // }

    .tooltips {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
      opacity: 1;
    }
    
    .tooltips .tooltiptext {
      visibility: hidden;
      width: 250px;
      background-color: #0084C7;
      color: #fff;
      text-align: left;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 3;
      top: -10px;
      left: 80%;

      p {
        margin: 0;
      }
    }
    a {
      color: #FFF;
    }
    
    .tooltips .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #0084C7 transparent transparent;
    }
    .tooltips:hover .tooltiptext {
      visibility: visible;
    }
  
    .close-div {
      display: flex;
      margin-top: 3px;
    }
  
    .news-template-46-dialog {
      display: flex;
      justify-content: center;
      position: relative;
  
      .close-icon-div {
        position: absolute;
        right: 10px;
      }
  
      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
  
      .title-info-req {
        // font-size: 20px;
        font-weight: 500;
   
        color: #004A9A;
        // text-align: center;
        // margin: 30px;
      }
    }
  
    .input-label {
 
      color: #004A9A;
      margin: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      font-size: 15px;
      text-align: center;
    }
  
    .btn-delete {
      margin: 15px;
      width: 100px;
      height: 40px;
 
      text-align: center;
      border-radius: 5px;
      background-color: #1c8d43;
      border: hidden;
      color: white;
      font-size: 14px;
 
    }
  
    .btn-cancel {
      margin: 15px;
      width: 100px;
      height: 40px;
 
      text-align: center;
      border-radius: 5px;
      background-color: #6c757d;
      border: hidden;
      color: white;
      font-size: 14px;
 
    }
    .dialog-width{
      max-width: 400px;
    } 
    .k-scrollview-next, .k-scrollview-prev {
      color: white;
      background-color: #0084C7;
      text-shadow: rgb(0 0 0 / 30%) 0 0 15px;
      opacity: 1;
      outline-width: 0;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      height: 30px;
      top: calc(50% - 210px);
      width: 36px;  
      border-radius: 3px;
    }
    .k-scrollview-next .k-icon, .k-scrollview-prev .k-icon{
      font-size: 1.5em;
      font-weight: normal;
      padding-left: 8px;
    }
    .k-scrollview-wrap{
      border: none;
      height: 190px !important;
    }
    .dash-line{
      background: linear-gradient(to right, #DC7E10 20%, #E1E1E1 20%, #E1E1E1 80%, #E1E1E1 80%);
      height: 2px;
      margin-bottom: 15px;
    }
    .text-item{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #696969;
      letter-spacing: -0.01em;
      height: 95px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
      margin-bottom: 0px;
      white-space: pre-line;
  
    }
    .k-scrollview-wrap .k-scrollview > li{
      // height: 530px !important;
      display: flex;
      justify-content: center;
    }
    .k-scrollview-pageable > .k-button{
      width: 12px;
      height: 12px;
    }

    li.ng-tns-c303-1.ng-star-inserted {
      display: flex;
      justify-content: center;
    }
    .mobile-view {
      display: none;
    }
    .web-view {
      display: block;
    }
    @media (max-width: 768px){
      .main-item{
        margin-top: 20px;
      }
      .col-md-4{
        width: 32%;
      }
      .web-view {
        display: none;
      }
      .mobile-view {
        display: block;
      }  
      .post-list{
        padding-right: 0px !important;
        padding-left: 0px !important;
        height: 190px;
        min-width: 100%;
        max-width: 100%;
        .top-part {
          border: none;
          background: transparent;
          box-shadow: none;
        }
      }
      .post-more{
        padding: 0px;
        font-size: 18px;
      }
      .post-title{
        display: block;
        margin-bottom: 0;
      }
    }
    @media (max-width: 576px){
      .main-item{
        margin-top: 20px;
      }
      .web-view {
        display: none;
      }
      .mobile-view {
        display: block;
      }  
      .post-list{
        padding-right: 0px !important;
        padding-left: 0px !important;
        height: 190px;
        min-width: 100%;
        max-width: 100%;
        .top-part {
          border: none;
          background: transparent;
          box-shadow: none;
        }
      }
      .img-figure, .post-image{
        height: 80px;
        width: 80%;
      }
      .post-more{
        padding: 0px;
        font-size: 18px;
      }
      .post-title{
        display: block;
        margin-bottom: 0;
      }
    }
  }
   
  